export const languageText ={
    
    firstNavigation: "เครื่องมือ DW ",
    firstNavigationEn: "DW tools ",
    secondNavigation: " คำนวณราคา DW",
    secondNavigationEn: " DW calculator",

    pageTitle: "คำนวณราคา DW",
    pageTitleEn: "DW calculator",
    pageDescription: "คำนวณราคา DW ในระดับราคาอ้างอิง วันที่ซื้อขาย DW และความผันผวนที่คุณเลือกเอง",
    pageDescriptionEn: "Estimates your future DW price given a change in underlying price, time or implied volatility",

    selectSymbolPlaceholder: "เลือกชื่อย่อ DW",
    selectSymbolPlaceholderEn: "Select DW symbol",

    psTableCurrent: "ราคา DW bid ปัจจุบัน",
    psTableCurrentEn: "Current DW bid price",
    psTableGearing: "Eff. gearing",
    psTableGearingEn: "Eff. gearing",
    psTableSensitivity: "Sensitivity",
    psTableSensitivityEn: "Sensitivity",
    psTableLastTrading: "วันหมดอายุ",
    psTableLastTradingEn: "Last trading day",

    inputField: "เลือกข้อมูลอื่นๆ",
    inputFieldEn: "Input field",
    underlyingBidPrice: "ราคา bid ของหลักทรัพย์อ้างอิง",
    underlyingBidPriceEn: "Underlying bid price",
    impliedVolatility: "Implied volatility",
    impliedVolatilityEn: "Implied volatility",
    inputFieldDate: "วันที่",
    inputFieldDateEn: "Date",

    simulatedResults: "ผลการคำนวณราคา",
    simulatedResultsEn: "Simulated results",
    simulatedUnderlyingBidPrice: "ราคา bid ของหลักทรัพย์อ้างอิง",
    simulatedUnderlyingBidPriceEn: "Underlying bid price",
    simulatedDwBidPrice: "ราคา Bid ของ DW",
    simulatedDwBidPriceEn: "DW Bid price",
    
    importantNote1: "*ราคา Bid ของ DW ด้านบนนี้ เป็นค่าทางทฤษฎี และค่าโดยประมาณเท่านั้น ซึ่งเป็นเพียงการนำข้อมูลพื้นฐานมาคำนวณทางคณิตศาสตร์ และมีวัตถุประสงค์เพื่อใช้เป็นข้อมูลเบื้องต้นประกอบการศึกษาเท่านั้น ไม่ถือเป็นการวิเคราะห์ ชี้นำ บอกถึงคุณค่า รวมทั้งไม่ถือเป็นการให้คำแนะนำหรือให้คำปรึกษาหรือชักชวนให้มีการซื้อขายเกี่ยวกับหลักทรัพย์ที่ปรากฏผ่านเว็บไซต์นี้ บริษัทไม่รับรองความถูกต้องครบถ้วนของเนื้อหาหรือข้อมูลที่ปรากฏ และอาจมีการเปลี่ยนแปลง ปรับปรุง หรือแก้ไขข้อมูลโดยมิต้องแจ้งให้ทราบล่วงหน้า ผู้ลงทุนควรศึกษาทำความเข้าใจถึงความเสี่ยง เงื่อนไข ผลตอบแทนของผลิตภัณฑ์ รวมถึงควรพิจารณาอย่างระมัดระวังก่อนตัดสินใจลงทุนทุกครั้ง",
    importantNote1En: "*The DW bid prices above are theoretical and approximate values ​​only. It includes basic information to calculate mathematics and it is intended for use as a preliminary to education only. It is neither an analysis, suggestive or value and it does not constitute any advice, consultation or solicitation for trading of the warrants displayed throughout this website. The company does not guarantee the correctness and/or completeness of the content or information displayed. The information is subject to change, update or revision without prior notice. All investors should understand the terms and conditions of DW offered, the risk and return factors involved, before invest in the DW.",
    importantNote2: '**ในกรณีที่นักลงทุนต้องการดูราคา Bid ของ DW ที่ผู้ดูแลสภาพคล่องเป็นผู้วาง นักลงทุนควรตรวจสอบหน้า "ตารางราคา" และอ่านคำเตือนในหน้าดังกล่าวประกอบ',
    importantNote2En: "**In case investors desire to find the Bid price of warrants that are provided by the market maker; Investors have to use the price matrix page to be the indicative price and should read the warnings on that page.",
    
    marketDelay: "ข้อมูลตลาดทั้งหมดล่าช้าประมาณ 15 นาที",
    marketDelayEn: "All market data is delayed 15 mins.",
}