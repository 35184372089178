export const languageText ={

    pageTitle: "ทำไมต้องลงทุนใน DW?",
    pageTitleEn: "Why Trade DW?",

    pageName: "ใช้เงินลงทุนต่ำกว่า",
    pageNameEn: "Lower capital outlay",

    pageDescription: "DW ช่วยให้นักลงทุนได้รับโอกาสในการรับผลตอบแทนจากเคลื่อนไหวของราคาหุ้นพื้นฐานดี โดยใช้เงินจำนวนน้อยเมื่อเปรียบเทียบกับราคาหุ้น ฉะนั้นถ้านักลงทุนที่สนใจซื้อหุ้นแต่ติดข้อจำกัดในเรื่องของเงินลงทุน นักลงทุนก็สามารถเลือกลงทุนใน Call DW ซึ่งให้ผลตอบแทนเมื่อราคาหุ้นปรับตัวเพิ่มขึ้น ทั้งนี้จากการที่ใช้เงินลงทุนที่น้อยกว่า ส่งผลให้ค่านายหน้าซื้อขายหลักทรัพย์และต้นทุนในการทำธุรกรรมจึงต่ำกว่าด้วย",
    pageDescriptionEn: "DW enable investors to gain exposure to blue chip shares for a fraction of the share price. An investor interested in purchasing shares who did not have immediate access to funds could purchase call DW to capture the benefits of an anticipated price rise. Because of the significantly lower outlay for DW, brokerage and transaction costs are also lower.",

    firstTrTd: "ซื้อหุ้น",
    firstTrTdEn: "Buy Shares",
    firstTrTd1: "ซื้อ DW ให้ Exposure ใกล้เคียงกับการซื้อหุ้น",
    firstTrTd1En: "Similar exposure through DW",

    secondTrTd: "ต้นทุน",
    secondTrTdEn: "Cost",
    secondTrTd1: "100,000 บาท",
    secondTrTd1En: "THB 100,000",
    secondTrTd2: "20,000 บาท",
    secondTrTd2En: "THB 20,000",

    thirdTrTd: "ค่าธรรมเนียม*",
    thirdTrTdEn: "Brokerage Cost*",
    thirdTrTd1: "250 บาท",
    thirdTrTd1En: "THB 250",
    thirdTrTd2: "50 บาท",
    thirdTrTd2En: "THB 50",

    disclaimer: "*ค่าธรรมเนียมในการซื้อขายหลักทรัพย์ของแต่ละ บ.ล. อาจจะแตกต่างกัน",
    disclaimerEn: "*commission fee rate is varied among brokerage firms",
}