import React, { useEffect, useState } from 'react';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import Timeline from "highcharts/modules/timeline.js"
import Nodata from "highcharts/modules/no-data-to-display";
import { get } from 'lodash';
import moment from 'moment/moment';



Nodata(Highcharts);
Timeline(Highcharts);

const TermsChart = ({period,dataArr}) => {

const  getData =(dataArr)=> {
    var chartData = {
      data1: [],
      data2: [],
      data1Axis: {
        min: null,
        max: null,
      },
      data2Axis: {
        min: null,
        max: null,
      },
    };
    
    if (dataArr !== null) {
      var arr1 = [];
      var arr2 = [];
    
      dataArr && dataArr.data && Array.isArray(dataArr.data) && dataArr.data.map((_,i) => {
        chartData.data1.push([
          moment.utc(moment(new Date(dataArr.data[i]["time"])).format("DD MM YYYY HH:mm"), "DD MM YYYY HH:mm").valueOf(),
          (+dataArr.data[i]["close"]),
        ]);
        arr1.push((+dataArr.data[i]["close"]));
      })
   
        
   
      dataArr && dataArr.data1 && Array.isArray(dataArr.data1) && dataArr.data1.map((_,j) => {
        chartData && chartData.data2.push([
          moment.utc(moment(new Date(dataArr.data1[j]["time"])).format("DD MM YYYY HH:mm"), "DD MM YYYY HH:mm").valueOf(),
          (+dataArr.data1[j]["close"]),
        ]);
        arr2.push((+dataArr.data1[j]["close"]));
      })
      
      chartData.data1Axis.min = Math.min.apply(Math, arr1);
      chartData.data1Axis.max = Math.max.apply(Math, arr1);
      chartData.data2Axis.min = Math.min.apply(Math, arr2);
      chartData.data2Axis.max = Math.max.apply(Math, arr2);
  
      return chartData;
    } else {
      return chartData;
    }
    
  }

  const chartdata = getData(dataArr);

  const options = {
    lang: {
      noData: "no-data-to-display",
    },
    chart: {
      type: "area",
    },
    title: {
      text: "",
    },
    subtitle: {
      text: "",
    },
    credits: {
      text: "",
    },
    xAxis: {
      gridLineColor: "#FAFAFA",
      crosshair: true,
      gridLineWidth: 1,
      type: "datetime",
      title: {
        text: "",
      },
    },
    yAxis: [
      {
        opposite: true,
        labels: {
          formatter:  (value)=> {
            return get(value,'value','')
          },
        },
        title: {
          text: "",
        },
        lineWidth: 1,
        tickWidth: 1,
        gridLineColor: "#FAFAFA",
        min: chartdata.data2Axis.min,
        max: chartdata.data2Axis.max,
      },
      {
        crosshair: true,
        labels: {
          formatter:  (value)=> {
            return get(value,'value','')
          },
        },
        title: {
          text: "",
        },
        lineWidth: 1,
        tickWidth: 1,
        gridLineColor: "#FAFAFA",
        min: chartdata.data1Axis.min,
        max: chartdata.data1Axis.max,
      },
    ],
    exporting: {
      enabled: false,
    },
    legend: {
      enabled: false,
      layout: 'horizontal',
      align: 'center',
      verticalAlign: 'top'
    },
    tooltip: {
      split: true,
    },
    plotOptions: {
      area: {
        marker: {
          enabled: false,
          symbol: "circle",
          radius: 2,
          states: {
            hover: {
              enabled: false,
            },
          },
        },
        series: {
          stacking: "normal",
        },
      },
    },
    series: [
       {
          name: "Underlying price (HKD)",
          yAxis: 1,
          data:chartdata.data1,
          tooltip: {
            pointFormat: "Underlying price (HKD) : <b>{point.y}</b>",
            split: true,
          },
        }, 
        {
          name: "Warrant bid price (MYR)",
          data: chartdata.data2,
          color: "#DFC49F",
          tooltip: {
            pointFormat: "Warrant bid price (MYR) : <b>{point.y}</b>",
            split: true,
          },
        },
        
  ],
}
   
const style = {
  width: "300px",
  height: "370px",
};

    return ( 
      <HighchartsReact 
      highcharts={Highcharts}
      options={options}
      style={style}
  />
     );
}
 
export default TermsChart;
