import React, { Fragment } from 'react';
import DailyChange from './DailyChange';
import "../../common.css";
import "../../investorAcademy.css";


const DailyChanges = (props) => {
    return ( 
        <Fragment> 
            <DailyChange/>
        </Fragment> 
     );
}


export default DailyChanges;