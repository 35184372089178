import React, { Fragment } from 'react';
import "./dwUnderlying.css" 
import DwUnderlying from "./DwUnderlying"


const DwUnderlyings = (props) => {
    return ( 
        <Fragment> 
            <DwUnderlying />
        </Fragment> 
     );
}

export default DwUnderlyings;