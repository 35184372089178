import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import API from "../../../../utils/apiCalling";
import { config } from "../../../../utils/apiUrl";
import LangContext from "../../../../components/common/context/lang-context";
import { useContext } from "react";
import { languageText } from "../../../../languageText/activityData"

const ActivityData = () => {

  const api = new API();
  const location = useLocation();
  let locst = location.search.split("?id=")[1];
  const langContext = useContext(LangContext);
  const [data1, setdata1] = useState("");
  const [data2, setdata2] = useState('');
  const [siteLang , setSiteLang] = useState("")

  useEffect(() => {
    window.scrollTo(0, 0)
    var siteLanguage = $.cookie('applang');
    setSiteLang(siteLanguage)
    if(siteLanguage == "eng"){
      document.querySelector("body").classList.add("eng")
    }else{
      document.querySelector("body").classList.remove("eng")
    }
    getactivityData();
  }, []);
  
  const getactivityData = async () => {
    let activityData = await api.get(config.ActivitesDates, true, {
      id: locst,
      qid: Date.now(),
    });
    setdata1(activityData?.nav[0]);
    setdata2(activityData?.tipsData[0]); 
  }

  const handleClick = () => {
    window.open("https://www.facebook.com/login.php?skip_api_login=1&api_key=966242223397117&signed_next=1&next=https%3A%2F%2Fwww.facebook.com%2Fshare.php%3Fu%3Dhttps%253A%252F%252Ft1www5.trkd-hs.com%252Fmqmy_aws%252Fmarketnews%252Fhighlight%253Fcat%253Dovernightmarkets%2526id%253D5217%26p%255Bsummary%255D%3DEscalating%2Btensions%2Bin%2Bthe%2BMiddle%2BEast&cancel_url=https%3A%2F%2Fwww.facebook.com%2Fdialog%2Fclose_window%2F%3Fapp_id%3D966242223397117%26connect%3D0%23_%3D_&display=popup&locale=en_GB", 'share', 'width=560,height=650');
  };

  return (
    <div id="individualtip" className="pageobj">
      <div className="breadcrumbbar">
        <div className="container-fluid">
          <ol className="breadcrumb">
            <li>
              <Link to="/home?dwnews">
                {siteLang == "eng" ? languageText.firstNavigationEn : languageText.firstNavigation}
              </Link>
            </li>
            <li className="active">
              {siteLang == "eng" ? languageText.secondNavigationEn : languageText.secondNavigation}
            </li>
            <li className="active" id="breadcrumb-label">
              {siteLang == "eng" ? " "+data2 && data2?.en_title : " "+data2 && data2?.thai_title}
            </li>
          </ol>
        </div>
      </div>
      <div className="stripe-bg" />
      <div className="container-fluid">
        <div className="section">
          <div className="page-header">
            <h1 id="page-title">
              {siteLang == "eng" ? data2?.en_title : data2?.thai_title}
            </h1>
            <div className="btn-area">
              <div className="fbSharebtn" onClick={handleClick}>
                <i className="fa fa-facebook" aria-hidden="true" />
                Share on Facebook
              </div>
            </div>
          </div>
          <div id="tip-content">
            <p>
              <div className="topContent"></div>
              <div className="fullContent" dangerouslySetInnerHTML={{__html: siteLang == "eng" ? data2?.engLong : data2?.thaiLong}} />
            </p>
          </div>
          <div className="pnbar">
            <div className="pnbar-prev">
              {(data1 !== null || data1 !== undefined || data1 != "") && data1.prev !== -1 ? 
                <Link id="prev-achor" to={`/marketnews/activitiesdata?id=${data1.prev}`} >
                  &lt; Previous tip
                </Link>
              :
                <Link id="prev-achor" to="/activities/HSIFlight">
                  &lt; Previous tip
                </Link>
              }
            </div>
            <div className="pnbar-vl" />
            <div className="pnbar-next">
              {(data1 !== null || data1 !== undefined || data1 != "") && data1.next !== -1 ?
                <Link id="next-achor" to={`/marketnews/activitiesdata?id=${data1.next}`}>
                  Next tip &gt;
                </Link>
              :
                <Link id="next-achor" to={"/"} className={(data1 == "" || data1.next === -1) ? "disabled" : ""}>
                  Next tip &gt;
                </Link>
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ActivityData;
