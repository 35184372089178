import React, { Fragment } from "react";
import "./Hsidimsum.css"
import Hsidimusm from "./Hsidimusm";

const Hsidimusms = () => {
  return (
    <Fragment>
      <Hsidimusm />
    </Fragment>
  );
};

export default Hsidimusms;
