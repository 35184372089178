import React, { useState, useEffect,Fragment } from 'react';
import BreakevenPrices from './BreakevenPrices';
import "../../common.css";
import "../../investorAcademy.css";


const BreakevenPrice = (props) => {
    return ( 
        <Fragment> 
            <BreakevenPrices/> 
        </Fragment> 
     );
}


export default BreakevenPrice;