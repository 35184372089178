import React, { useEffect, useState } from "react";
import "./about.css"

const PrivacyPolicy = () => {

    const [siteLang , setSiteLang] = useState("")

    useEffect(() => {
        window.scrollTo(0, 0);
        var siteLanguage = $.cookie('applang');
        setSiteLang(siteLanguage)
        if(siteLanguage == "eng"){
          document.querySelector("body").classList.add("eng")
        }else{
          document.querySelector("body").classList.remove("eng")
        }
    }, [])

    return (
        <div id="privacypolicy" className="pageobj">
            <div className="breadcrumbbar">
                <div className="container-fluid">
                <ol className="breadcrumb">
                    <li><a>{siteLang == "eng" ? "Macquarie " : "Macquarie "}</a></li>
                    <li className="active">{siteLang == "eng" ? " Privacy policy" : " นโยบายความเป็นส่วนตัว"}</li>
                </ol>
                </div>
            </div>
            <div className="stripe-bg" />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className="section">
                            <div className="page-header">
                                <h1>{siteLang == "eng" ? "Privacy policy" : "นโยบายความเป็นส่วนตัว"}</h1>
                            </div>
                            <div className="title text-bold">Macquarie Group – Macquarie Securities (Thailand) Limited Privacy Policy</div>
                            <p>The Macquarie Group respects the privacy of the people we deal with and we uphold high standards of privacy practice and security. This policy explains how we handle personal information and how we interact with you online.<br /><br />
                                Whenever we handle personal information, we take steps to ensure that appropriate standards of privacy practice and security are applied.<br /><br />
                                The Macquarie Group Privacy Policy sets out why we need to collect personal information, how we collect it, what we do with it, how it is stored and who we might share it with. The Policy also describes how you can access or correct information we hold about you, how you can ask further questions or make a complaint, and information about our websites and online activities.<br /><br />
                                <strong>Macquarie Securities (Thailand) Limited (the "Company," "we," "us," or "our")</strong> recognizes the importance of the protection of your Personal Information (as defined below). <br /><br />
                                This privacy notice (the <strong>"Privacy Policy"</strong>) applies to the personal information of (1) our prospective, current and former individual customers, and (2) employees, personnel, officers, representatives, shareholders, authorized persons, members of the board of directors, contact persons, agents, and other natural persons in connection with our prospective, current and former corporate customer. <br /><br />
                                Natural/individual persons, together are referred to as <strong>"you"</strong> or <strong>"your"</strong> and the individual client and the corporate client, together are referred to as the <strong>"Client"</strong>. <br /><br />
                                This Privacy Policy describes how we collect, use, disclose and cross-border transfer your personal information. This Privacy Policy applies to our business, websites, mobile applications, call centers, events and exhibitions, online communication channels, other locations and any means where we collect, use disclose, or cross-border transfer your personal information.</p>
                            <div className="title text-bold">The Information We Collect</div>
                            <p>For most of our products and services, it is necessary or desirable for us to collect personal information such as your name, identity details, photos, contact details and transaction information. We may also need to collect other personal details (such as gender, age, marital status and financial information) and other information from which you can be identified.</p>
                            <div className="title text-bold">Employment Applications</div>
                            <p>We collect personal information from you if you apply for a job with the Macquarie Group. Please read the&nbsp;<a href="https://www.macquarie.com/hk/en/disclosures/careers-privacy-policy.html">Career Privacy Policy</a>&nbsp;for more information.</p>
                            <div className="title text-bold">Direct Marketing</div>
                            <p>We may collect your information for the communication of advertising or marketing material which is directed to particular you for the purposes of promoting products or services of Macquarie Group (“Direct Marketing”) with your consent to use such information for Direct Marketing Purposes. This includes informing you of news and publications which may be of interest, events, offering new services, conducting surveys and other marketing communications by email, telephone, letters, and electronic notifications.</p>
                            <div className="title text-bold">Sensitive Information</div>
                            <p>Generally, we do not collect sensitive information about you unless required or permitted by law or where you consent for us to do so. Sensitive information will only be collected if it is relevant to your product or the service or function you are engaging us to provide. If applicable, this will be communicated to you. For example, some forms of biometric data are collected, stored and used to support access and verification procedures.</p>
                            <p>We will not collect sensitive information about you where this is expressly prohibited by local law.</p>
                            <p>Sensitive information includes information relating to:</p>
                            <ul>
                                <li>race</li>
                                <li>political or religious beliefs</li>
                                <li>sexual orientation and sexual life</li>
                                <li>criminal convictions</li>
                                <li>membership of professional or trade associations or unions</li>
                                <li>biometric and health information</li>
                                <li>information about your affiliation with certain organisations, such as professional associations.</li>
                            </ul>
                            <div className="title text-bold">Why we collect personal information</div>
                            <p>We collect personal information about you which is reasonably necessary to:</p>
                            <ul>
                                <li>provide you with quality products, services or transaction opportunities. This may include services, advice or investment activity </li>
                                <li>provide you with marketing communications, special offers, promotional information on our products, services and/or events offered/organised by us, our affiliates, subsidiaries or our business partners</li>
                                <li>communicate with you including responding to your enquiries</li>
                                <li>consider applications and approaches you make to us</li>
                                <li>conduct marketing and social functions</li>
                                <li>maintain your contact details</li>
                                <li>fulfil our legal obligations, such as those relating to taxation and anti-money laundering and counter-terrorism financing; or</li>
                                <li>as otherwise authorized by you.</li>
                            </ul>
                            <p>Without such information, we may not be able to process your application or provide you with an appropriate level of service.&nbsp; In such circumstances, we will endeavor to process your application and provide you with the most appropriate level of service that we can.</p>
                            <div className="title text-bold">How we collect your information </div>
                            <p>We collect personal information about you directly from you — this can be in person, in documents you give us, from telephone calls, emails, competitions you enter, your access to our website or from transactions you make. We take reasonable steps to be transparent about how and why we collect personal information.<br /><br />
                                We may also collect your personal information indirectly from sources such as joint account holder(s) or third parties including public sources, your adviser(s), employer (or its affiliates), agents, introducers, referrers, brokers, our related companies and service providers (including credit reporting bodies, fraud and financial crime information exchanges and other information service providers).<br /><br />
                                In addition, for Direct Marketing purposes we may collect your personal information with your consent from other sources such as social media profiles, third party’s online platforms, and other publicly available sources and through our affiliates, service providers, business partners, official authorities, or third parties (e.g. third-party custodians, sub-custodians, and brokers). Where you have provided consent to collect, use, disclose, and/or transfer your personal information, you have the right to withdraw your consent at any time. This can be done so, by contacting the details at “Contact us” below. We may also collect, use, disclose, and/or transfer your personal information without your consent based on: (1) a contractual basis, for our initiation or fulfillment of a contract with you; (2) a legal obligation; (3) the legitimate interest of ourselves and third parties, to be balanced with your own interest and fundamental rights and freedoms in relation to the protection of your personal information; (4) vital interest, for preventing or suppressing a danger to a person’s life, body or health; or (5) public interest, for the performance of a task carried out in the public interest or for the exercise of official actions.
                            </p>
                            <div className="title text-bold">How we store your information</div>
                            <p>We keep personal information in physical and electronic records, at our premises and the premises of our service providers, which may include processing or storage in the cloud, which may mean in practice that this information is stored outside Thailand. Where this occurs, we take steps to protect the security and integrity of personal information.<br /><br />
                                We also keep records of our interactions with you (including by telephone, email and online) and of your transaction history.</p>
                            <div className="title text-bold">How we use your information</div>
                            <p>We use personal information about you for the purpose for which it was provided to us, including to:</p>
                            <ul>
                                <li>process applications</li>
                                <li>administer and manage our products and services (including monitoring, auditing, and evaluating those products and services)</li>
                                <li>model and test data (in a controlled environment)</li>
                                <li>develop scores, risk assessments and related analytical tools</li>
                                <li>communicate with you and deal with or investigate any complaints or enquiries.</li>
                            </ul>
                            <p>We may also:</p>
                            <ul>
                                <li>anonymize your data for our own purposes including market research and new product development</li>
                                <li>use personal information about you for the prevention and mitigation of fraud and other financial crimes</li>
                                <li>use or permit our affiliates to use your personal information for related purposes to the extent that it is lawful to do so without your express permission. For example, we may from time to time use your personal information to inform you of investment opportunities or to provide information about products and services which we expect may be of interest to you. However, if you do not want to receive such communications you can tell us by using any of the methods listed below.</li>
                            </ul>
                            <p>While we may sometimes - where it is lawful and with your permission if necessary - share personal information with companies we do business with (e.g. in product development, joint venture arrangements or distribution arrangements), we do not sell personal information for marketing purposes to other organizations or allow such companies to do this.</p>
                            <div className="title text-bold">Personal information of minors</div>
                            <p>Our activities, products and services are not generally aimed at minors and we do not knowingly collect personal information from customers who are minors (those who have not reach the legal age (20 years of age or by marriage) without their parental consent when it is required, or from quasi-incompetent persons and incompetent persons without their legal guardian's consent. If you are a minor, quasi-incompetent or incompetent person and wish to engage in a contractual relationship with us, you must obtain the consent from your parent or legal guardian prior to contacting us or providing us with your personal information except where you are of sui juris or emancipated status. <br /><br />
                                If we learn that we have unintentionally collected personal information from any minor without parental consent when it is required, or from quasi-incompetent person or incompetent person without their legal guardians' consent, we will delete it immediately or continue to process such personal information if we are permitted to rely on other legal grounds apart from consent.</p>
                            <div className="title text-bold">Personal information related to third parties</div>
                            <p>If you provide the personal information of any third party (such as your spouse and children, shareholders, directors, beneficiary, emergency contact, referral, and references) to us, [e.g., their name, family name, email address, and telephone number], you should ensure that you have the authority to do so and to permit us to use the personal information in accordance with this Privacy Policy. You are also responsible for notifying the third party of this Privacy Policy and, if required, obtaining consent from the third party or rely on other legal basis.</p>
                            <div className="title text-bold">How we disclose or transfer your personal information</div>
                            <p>We may disclose or transfer your personal information to the following third parties (including their personnel and agents) who process personal information in accordance with the purposes under this Privacy Policy and to the extent permissible under your consent or law. These third parties may be located in or outside Thailand. You can visit their privacy policies to learn more details on how they process your personal information.</p>
                            <ul>
                                <li><strong>Macquarie Group </strong><br />
                                As Macquarie Securities (Thailand) Limited is part of a Macquarie Group, we may need to transfer your personal information to, or otherwise allow access to such personal information by, other companies within Macquarie Group for the purposes set out above. Please see list of companies and scope of activities within <a href="http://Macquarie.com">Macquarie Group</a></li>
                                <li><strong>Our service providers</strong><br />
                                We may use other companies, agents or contractors to perform services on our behalf or to assist with the provision of products and services to you. We may share your personal information to these service providers, including but not limited to: (a) IT service providers; (b) research agencies; (c) analytics service providers; (d) survey agencies; (e) marketing, advertising media and communications agencies; (f) payment service providers; (g) administrative and operational service providers; (h) banks; (i) insurers; and (j) brokers.
                                <br />
                                In the course of providing these services, the service providers may have access to your personal information. However, we will only provide our service providers with the personal information that is reasonably necessary for them to perform the services, and we will ask them not to use your personal information for any other purposes. We will ensure that all the service providers we work with will keep your personal information secure. </li>
                                <li><strong>Our business partners</strong><br />
                                We may transfer your personal information to persons acting on your behalf or otherwise involved in the provision of the type of product or service you receive from us, including payment recipients, beneficiaries, account nominees, intermediaries (such as third-party securities companies, or asset management companies), custodians, correspondents, agents, vendors, co-brand business partners, market counterparties, issuers of products, or global trade repositories to whom we disclose Personal Information in the course of providing products and services to you and whom you authorize us to disclose your Personal Information to, provided that these data recipients agree to treat your personal information in a manner consistent with this Privacy Policy. </li>
                                <li><strong>Third parties permitted by law </strong><br />
                                In certain circumstances, we may be required to disclose or share your personal information to a third party in order to comply with legal or regulatory obligations. This includes any law enforcement agency, court, regulator, government authority or other third party for which we believe disclosure or transfer is necessary to comply with a legal or regulatory obligation, or otherwise to protect our rights, the rights of any third party's or individuals’ personal safety, or to detect, prevent, or otherwise address fraud, security or safety issues.</li>
                                <li><strong>Professional advisors </strong><br />
                                We may disclose or transfer your personal information to our professional advisors relating to audit, legal, accounting, and tax services who assist in running our business and defending or bringing any legal claims.</li>
                                <li><strong>Third parties as assignees, transferees, or novatees</strong><br />
                                We may assign, transfer, or novate our rights or obligations to a third party, to the extent permitted under the terms and conditions of any contract between you and us. We may disclose or transfer your personal information to assignees, transferees, or novatees, including prospective assignees, transferees, or novatees, provided that these data recipients agree to treat your personal information in a manner consistent with this Privacy Policy. </li>
                                <li><strong>Third parties connected with business transfer </strong><br />
                                We may disclose or transfer your personal information to our business partners, investors, significant shareholders, assignees, prospective assignees, transferees, or prospective transferees in the event of any reorganization, restructuring, merger, acquisition, sale, purchase, joint venture, assignment, dissolution or any similar event involving the transfer or other disposal of all or any portion of our business, assets, or stock. If any of the above events occur, we will request the data recipient to comply with this Privacy Policy to respect your personal information.</li>
                                <li><strong>Other authorized third parties </strong><br />
                                We may disclose or transfer your personal information to any other third parties as authorized by you or as contemplated in the terms and conditions of any specific product or service provided to you.</li>
                            </ul>
                            <div className="title text-bold">International transfers of your Personal Information</div>
                            <p>We may disclose or transfer your Personal Information to third parties or servers located overseas, and the destination countries may or may not have the same data protection standards as Thailand — this includes locations in the countries listed in the Appendix. We have taken steps and measures to ensure that your personal information is securely transferred, that the data recipients have suitable data protection standards in place in accordance with the relevant local laws, and that the transfer is lawful by relying on the derogations as permitted under the law. Please note that it may be possible for those parties to, in turn, make such information available to other third parties to comply with local law or regulations.</p>
                            <div className="title text-bold">Keeping information accurate and up to date</div>
                            <p>We take reasonable steps to ensure that all information we hold is as accurate as possible. You are able to contact us at any time at our contact details below and ask for its correction if you feel the information we have about you is inaccurate or incomplete.</p>
                            <div className="title text-bold">Keeping information secure</div>
                            <p>We use security procedures and technology to protect the information we hold. Access to and use of personal information within Macquarie Group seeks to prevent misuse or unlawful disclosure of the information. This includes internal policies, auditing, training and monitoring of staff.<br /><br />
                                If other organizations provide support services, we require them to take appropriate technical and organizational measures to secure the privacy of the information provided to them.<br /><br />
                                We aim to ensure that personal information is protected from misuse and loss, and from unauthorized access, modification or disclosure. In doing so, we use appropriate security measures (of premises and systems) and limit internal access to personal information on a need-to know basis.  We have implemented the Data Breach Plan to deal with actual or suspected data breaches. Compliance with the Data Breach Plan is mandatory, and all data breaches must be dealt with in an appropriate and timely manner. In certain cases, this will include notifying the data subject of the breach as soon as possible and notifying the relevant regulator of the breach, in some cases, within 72 hours.</p>
                            <div className="title text-bold">Retention period of your personal information</div>
                            <p>We will retain your personal information for as long as necessary during the period you are a customer or under relationship with us, or for as long as necessary in connection with the purposes set out in this Policy, unless law requires or permits a longer retention period. For example, the retention period pursuant to the anti-money laundering laws for proving and examining in the event of a dispute has a legally prescribed duration of not over 10 years at the time of writing.&nbsp; &nbsp;
                                <br /><br />
                                We may erase, destroy or anonymize the personal information when it is no longer necessary or when the relevant retention period lapses.&nbsp;</p>
                            <div className="title text-bold">Your rights related to Personal Information </div>
                            <p>Subject to the applicable laws and exceptions thereto, you may have the following rights regarding your Personal Information:</p>
                            <ol type="a">
                                <li><strong>Access</strong>: you may have the right to access or request a copy of the personal information we processed in relation to you;/li&gt;
                                </li><li><strong>Data Portability</strong>: you may have the right to obtain personal information we hold about you, in a structured, electronic format, and to transmit this data to another data controller;</li>
                                <li><strong>Objection</strong>: in some circumstances, you may have the right to object to how we process your personal information in certain activities which specified in this Policy;</li>
                                <li><strong>Deletion</strong>: you may have the right to request that we delete, destroy, or de-identify your personal information that we process about you, e.g. if the data is no longer necessary for the purposes of processing; </li>
                                <li><strong>Restriction</strong>: you may have the right to restrict our processing of your personal information if you believe such data to be inaccurate, that our processing is unlawful, or that we no longer need to process this data for a particular purpose; </li>
                                <li><strong>Rectification</strong>: you may have the right to have personal information that is incomplete, inaccurate, misleading, or out-of-date rectified; </li>
                                <li><strong>Consent withdrawal</strong>: you may have the right to withdraw consent that was given to us for the processing of your personal information, unless there are restrictions on the right to withdraw consent as required by the law, or a contract that benefits you; and</li>
                                <li><strong>Lodge a complaint</strong>: you may have the right to lodge a complaint to the competent authority (the Office of the Personal Data Protection Commission) if you believe our processing of your personal information is unlawful or non-compliant with applicable data protection law.</li>
                            </ol>
                            <div className="title text-bold">How you can access or correct your information</div>
                            <p>You can contact us to request access to or correction of your personal information. In normal circumstances we will give you full access or make the requested corrections to your information. However, there may be some legal or administrative reasons to deny these requests. If your request is denied, we will provide you with the reason why (if we can). Where we decide not to make a requested correction to your personal information and you disagree, you may ask us to make a note of your requested correction with the information.</p>
                            <div className="title text-bold">Dealing with Macquarie Group online</div>
                            <p>When you visit Macquarie websites you will generally browse anonymously unless you have logged into a client portal or accessed the website from a personalized communication from Macquarie.<br /><br />
                                For all visitors to our website, we use cookies and other web browser technologies to collect information such as the server your computer is logged on to, your browser type (for example, Internet Explorer, Chrome or Firefox), and your IP address. (An IP address is a number that is assigned to your computer automatically and required for using the Internet). We may also derive the location associated with an IP address.<br /><br />
                                Once you have logged into a Macquarie portal, accessed our website from an e-mail or other personalized communication sent to you, or provided us with personal information by completing a form online, we may be able to identify you and we may combine that with other information in order to provide you with a better online experience. If you would prefer not to be identified, you can delete the cookies and reconfigure the cookie preferences on your internet browser (see below).</p>
                            <div className="title text-bold">Cookies</div>
                            <p>A 'cookie' is a packet of information placed on a user's computer by a website for record keeping purposes. Cookies are generally used on Macquarie Group sites to:</p>
                            <ul>
                                <li>access online services - if you log into our secure areas on our website, we will use cookies to authorize your access and save your preferences</li>
                                <li>manage advertising - when you see one of our ads on a third-party website, cookies are sometimes used to collect anonymous information about the page you visit and the type of software you are using</li>
                                <li>monitor traffic - we use anonymous information to track how people are using the Macquarie site. This may include time of visit, pages visited and some system information about the type of computer you are using</li>
                                <li>monitor your use of our website - if we have identified you as a result of you logging into a Macquarie client portal, accessing our website from a personalized communication device or providing us with personal information by completing a form online, we may use cookies to collect personal information about you.</li>
                            </ul>
                            <p>You can configure your browser to accept all cookies, reject all cookies, or notify you when a cookie is sent. (Each browser is different, so check the Help menu of your browser to learn how to change your cookie preferences).<br /><br />
                                If you disable the use of cookies on your web browser or remove or reject specific cookies from Macquarie Group websites or linked sites, then you may not be able to gain access to all of the content and facilities on those websites.</p>
                            <div className="title text-bold">Changes to this Privacy Policy </div>
                            <p>From time to time, we may change or update this Privacy Policy. We encourage you to read this Privacy Policy carefully and periodically revisit  <a href="https://www.thaidw.com/about/privacypolicy">https://www.thaidw.com/about/privacypolicy</a> to review any changes that may occur in accordance with the terms of this Privacy Policy. We will notify you or obtain your consent again if there are material changes to this Privacy Policy, or if we are required to do so by law.</p>
                            <div className="title text-bold">Contact Us</div>
                            <p>If you have any questions or complaints regarding privacy or information handling, please write to&nbsp;Macquarie Group (<a href="mailto:privacy@macquarie.com">privacy@macquarie.com</a>), Macquarie Securities (Thailand ) Limited (<a href="mailto:compliancebkk@macquarie.com">compliancebkk@macquarie.com</a> or contact us by phone 02 694 7802-3). Please mark communications to the attention of our Privacy Officer. We will respond to let you know who will be handling your matter and when you can expect a further response.<br /><br />
                                If your concerns are not resolved to your satisfaction, you may be able to escalate your complaint to an external dispute resolution scheme or to a regulatory authority. We can also provide details of the appropriate scheme/regulator for you, as these may vary by jurisdiction or product.</p>
                            <div className="title text-bold">Appendix — Disclosure to other countries</div>
                            <p>Personal information may be disclosed to Macquarie Group entities and third parties in jurisdictions including: Australia; Austria; Brazil; Canada; China; Denmark; France; Germany; Hong Kong; India; Indonesia; Ireland; Japan; Luxembourg; Malaysia; Mexico; Netherlands; New Zealand; Norway; Philippines; Singapore; South Africa; South Korea; Spain; Switzerland; Taiwan; Thailand; United Arab Emirates; United Kingdom; United States of America.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
};
export default PrivacyPolicy;