import React, { useState, useEffect } from "react";
import { useContext } from "react";
import { Link } from "react-router-dom";
import API from "../../../utils/apiCalling";
import "./dwCalculators.css";
import { get } from "lodash";
import Select from "react-select";
import { config } from "../../../utils/apiUrl";
import WarrantSearchSideBars from "../../../components/common/Sidebar/WarrantSearchSideBar"
import LiveMatrixSideBars from "../../../components/common/Sidebar/LiveMatrixSideBar"
import DwTermsSideBars from "../../../components/common/Sidebar/DwTermsSideBar"
import { languageText } from "../../../languageText/dwCalculator"
import moment from "moment"
import DatePicker from "react-date-picker";
// import 'react-date-picker/dist/DatePicker.css';
// import 'react-calendar/dist/Calendar.css';
import RangeSliderUnderlyingPriceComponent from "../../../components/common/RangeSliderUnderlyingPriceComponent"
import RangeSliderImpliedVolatilityComponent from "../../../components/common/RangeSliderImpliedVolatilityComponent"
import RangeSliderDateSelectionComponent from "../../../components/common/RangeSliderDateSelectionComponent";
import AdvertiseMentSidebar from "../../../components/common/Sidebar/AdvertiseMentSidebar"

const DwCalculator = () => {

  const api = new API();
  const [currentHour, setCurrentTime] = useState(new Date(Date.now()).getHours())
  const [currentMinute, setCurrentMinute] = useState(new Date(Date.now()).getMinutes())
  const [currentSecond, setCurrentSecond] = useState(new Date(Date.now()).getSeconds())
  const [qid, setQid] = useState(Date.now())
  const [symbolList, setSymbolList] = useState([])
  const [ric, setRic] = useState("");
  const [allMarketData, setAllMarketData] = useState([])
  const [underlyingBidPrice, setUnderlyingBidPrice] = useState()
  const [impliedVolatility, setImpliedVolatility] = useState("")
  const [todayDate, setTodayDate] = useState("")
  const [datenum, setDatenum] = useState(12)
  const [ivnum, setIvnum] = useState(12)
  const [riskFreeRate, setRiskFreeRate] = useState(0.015)
  const [maxUnderlying, setMaxUnderlying] = useState(0)
  const [initial, setinitial] = useState(true)
  const [ivMatrix, setivMatrix] = useState(null)
  const [unPriceProps, setunPriceProps] = useState({ spread: 0.01, udp: 2 })
  const [unpricenum, setunpricenum] = useState(6)
  const [ivMatrixDisp, setivMatrixDisp] = useState(null)
  const [fxRate, setfxRate] = useState(1)
  const [bid, setbid] = useState(null)
  const [delta, setdelta] = useState("")
  const [efgearing, setefgearing] = useState("")
  const [dwpchg, setdwpchg] = useState("")
  const [siteLang , setSiteLang] = useState("")
  const [tableHtml , setTableHtml] = useState("")
  const [ccyDisplay, setCcyDisplay] = useState("inline")

  useEffect(() => {
    window.scrollTo(0, 0);
    var siteLanguage = $.cookie('applang');
    setSiteLang(siteLanguage)
    if(siteLanguage == "eng"){
      document.querySelector("body").classList.add("eng")
    }else{
      document.querySelector("body").classList.remove("eng")
    }
    MatrixComponent()
  }, []);

  useEffect(() => {
    setCurrentTime(new Date(Date.now()).getHours())
    setCurrentMinute(new Date(Date.now()).getMinutes())
    setCurrentSecond(new Date(Date.now()).getSeconds())
  },[todayDate])

  useEffect(() => {
    getDwCalculatorSelect();
  },[]);

  useEffect(() => {
    getMarketData();
  },[ric]);

  useEffect(() => {
    if(ivMatrix !== null) {
      calIVMatrix()
      // updateIvTable()
    }
    return () => {
    }
  }, [ivMatrix])

  useEffect(() => {
    var impliedVolatilityVal = impliedVolatility == "" ? "N/A" : impliedVolatility
    if(underlyingBidPrice>=0 && impliedVolatilityVal && todayDate && allMarketData && underlyingBidPrice !== null && impliedVolatilityVal !== null  && riskFreeRate){
      startMatrix() 
    }
    return () => {
    }
  }, [underlyingBidPrice, impliedVolatility, todayDate])

  const getDwCalculatorSelect = async () => {
    var data = {
      qid,
    };
    var LiveIndexSymbolResult = await api.get(config.homeLiveIndexFuturesSymbol, true,data);
    var liveIndexSymbolRes = get(LiveIndexSymbolResult, "symbols", []);
    var liveIndexSymbolData = liveIndexSymbolRes.map(
      ({ security_code, ric: value }) => ({
        label: `${security_code}`,
        value,
        security_code,
      })
    );
    setSymbolList(liveIndexSymbolData);
    handleChange(liveIndexSymbolData);
  };

  const handleChange = (e) => {
    var queryString = window.location.pathname.split("/");    
    var splitData = queryString[3];
    var ric = ""
    if(splitData != undefined && e.length > 0) {
      var ricData = e.filter((j) => splitData === j.security_code); 
      if(ricData.length > 0){
        var finalRicVal = ricData[0]?.value;        
        setRic(finalRicVal);
      }  
      else{
        setRic(e[0]?.value);
      }
    }else{
      ric = $.cookie('TOOLS_PREF_RIC_TH');
      if(ric == "" || ric === null || ric === undefined){
        setRic(e[0]?.value);
      }else{
        setRic(ric);
      }
    }
  };

  const getMarketData = async () => {
    let data = {
      type: "warrantdata",
      ric: ric,
      qid: qid,
    };
    let marketDataResult = await api.get(
      config.marketTurnOver,
      true,
      data
    );
    setfxRate(Number(marketDataResult[ric]?.underlying_price_adj.replace(/,/g, ""))/Number(marketDataResult[ric]?.underlying_bid_price.replace(/,/g, "")))
    setAllMarketData(marketDataResult[ric])
    setUnderlyingBidPrice(marketDataResult[ric]?.underlying_bid_price?.replace(/,/g, ""))
    setImpliedVolatility(marketDataResult[ric]?.implied_volatility)
    setTodayDate(moment(new Date()).format("DD MMM YY"))
    if(marketDataResult[ric]?.underlying_ticker == "HSI" || marketDataResult[ric]?.underlying_ticker == "S&P500"|| marketDataResult[ric]?.underlying_ric.toString().endsWith("HK")){
      setCcyDisplay("none")
    }else{
      setCcyDisplay("inline")
    }
    var maxValue = 3 * Number(marketDataResult[ric]?.underlying_bid_price?.replace(/,/g, ""));
    if(isNaN(maxValue)){
      setMaxUnderlying(0)
    }else{
      setMaxUnderlying(Number(maxValue.toFixed(2)))
    }
  }

  const startMatrix=()=>{
    initIVMatrix();
    calIVMatrix()
  }

  const initIVMatrix = ()=> {
    var initIV = (impliedVolatility == "N/A" || impliedVolatility == "" || impliedVolatility == "-" || impliedVolatility == null) ? 0 : Number(impliedVolatility)
    var ivMatrixData = ivMatrix
    if (!ivMatrix) {
      setivMatrix([])
      ivMatrixData = []
      for (var i = 0; i < datenum; i++) {
        ivMatrixData[i] = [];
        for (var j = 0; j < ivnum; j++) {
          ivMatrixData[i][j] = "";
        }
      }
    }
    var initUP = (underlyingBidPrice === "N/A" || underlyingBidPrice === "" || underlyingBidPrice === "-" || underlyingBidPrice === null) ? 0 : Number(underlyingBidPrice)
    for (var i = 1; i < ivnum; i++) {
      let iv = (i - ivnum / 2) * -0.25 + initUP;
      if (iv < 0) {
        iv = 0;
      }
      ivMatrixData[0][i] = iv.toFixed(2);
    }
    var d = new Date(new Date().setHours(0,0,0,0))
    var mind = new Date(d.setDate(d.getDate()+(-(datenum) / 2 + 1)))
    var realtoday = new Date();
    var ddiff = 0;
    if(mind < realtoday){
      ddiff = caldaydiff(mind, realtoday);
    }
    for (i = 1; i < datenum; i++) {
      d.setTime(new Date(todayDate));
      ivMatrixData[i][0] = moment(new Date(d.setDate(d.getDate()+(-(datenum) / 2 + i + ddiff)))).format("DD MMM YY")
    }
    setivMatrix(ivMatrixData)
    updateIvTable(ivMatrixData)
    // initIVMatrixDisp();
  }

  const caldaydiff = (first, second) => {
    return (second - first) / (1000 * 60 * 60 * 24);
  }

  const calIVMatrix = async () => {
    var ivMatrixData = ivMatrix
    var unprice = underlyingBidPrice;
    var iv = (impliedVolatility == "N/A" || impliedVolatility == "N/" || impliedVolatility == "N" || impliedVolatility == "" || impliedVolatility == "-" || impliedVolatility == null) ? 0 : impliedVolatility
    var exercisePrice = allMarketData?.exercise_price_cal
    var warrantsPerShare = allMarketData?.conv_ratio;
    var lasttradingdate = allMarketData?.last_trading_date;
    var type = allMarketData?.type;
    var result, output, TDays;
    var uprice = underlyingBidPrice
    for (var i = 1; i < datenum; i++) {
        for (var j = 1; j < ivnum; j++) {
          TDays = await _caldateDiffInDays(new Date(ivMatrixData[i][0]), new Date(lasttradingdate))
          if (TDays < 0) {
            ivMatrixData[i][j] = "-";
          }else{
            result = _calDWPriceWithDividen(ivMatrixData[0][j], exercisePrice, riskFreeRate, iv / 100, TDays, warrantsPerShare, type);
            ivMatrixData[i][j] = result.toFixed(2)
          }
        }
    }
    setivMatrix(ivMatrixData)
    updateIvTable(ivMatrixData)
  } 

  const updateIvTable = (data) => {
    if(data){
      for (var i = 0; i < datenum; i++) {
        for (var j = 0; j < ivnum; j++) {
            if(document.querySelector(".matrix_"+i+"_"+j) != null){
              if(data[i][j] == "-0.00" || data[i][j] == "-0.0" || data[i][j] == "-0"){
                document.querySelector(".matrix_"+i+"_"+j).innerHTML = "0.00"
              }else{
                document.querySelector(".matrix_"+i+"_"+j).innerHTML = data[i][j]
              }
            }
        }
      }
    }
  }

  const _caldateDiffInDays = async (a, b) => {
    var _MS_PER_DAY = 1000 * 60 * 60 * 24;
    var utc1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
    var utc2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());
    return Math.floor(((utc2 - utc1) + _MS_PER_DAY) / _MS_PER_DAY);
  }

  const _calDWPriceWithDividen = (S, X, r, sigma, TDays, wps, type ) => {
    S = (Number(S.replace(/,/g, ""))* fxRate).toPrecision(10);
    X = (Number(X)* fxRate).toPrecision(10);
    var c, d1, d2, T, F;
    T = TDays / 365;
    F = S * Math.exp((r - allMarketData?.div_yield) * T);
    d1 = (Math.log(F / X) + (sigma * sigma / 2) * T) / (sigma * Math.sqrt(T));
    d1 = Number(d1).toPrecision(10);
    d2 = d1 - (sigma * Math.sqrt(T));
    d2 = Number(d2).toPrecision(10);
    if (type == "CALL") {
        c = Math.exp(-r * T) * (F * _calCND(d1) - X * _calCND(d2));
    } else if (type == "PUT") {
        c = Math.exp(-r * T) * (X * _calCND(-d2) - F * _calCND(-d1));
    }
    c = Number(c).toPrecision(10);
    c = c ;
    c = Number(c).toPrecision(10);
    return  c / wps;
  }

  const _calCND=(x)=>{
    var a1 = 0.254829592;
    var a2 = 0.284496736;
    var a3 = 1.421413741;
    var a4 = 1.453152027;
    var a5 = 1.061405429;
    var p = 0.3275911;
    var t = 1 / (1 + p * Math.abs(x) / Math.sqrt(2));
    var value = 1 / 2 * (1 + _sign(x) * (1 - (a1 * t - a2 * Math.pow(t, 2) + a3 * Math.pow(t, 3) - a4 * Math.pow(t, 4) + a5 * Math.pow(t, 5)) * Math.exp(-x * x / 2)));
    return value;    
  }

  const _sign=(x)=>{
    return x ? x < 0 ? -1 : 1 : 0;
  }

  const getSelectedValue = (e) => {
    const { value, label, security_code } = e;     
    setRic(e.value); 
    window.history.pushState("", "", `/tools/dwcalculator/${e.security_code}`)  
  };

  const colourStyles = {
    option: (styles, { data, isDisabled, isFocused, isSelected }) => {
      return {
        ...styles,
        backgroundColor: isFocused ? "#007fff" : null,
        color: "#333333",
        color: isFocused ? "#fff" : null,
        border: isFocused ? "1px solid #003eff" : null,
        textAlign: "center",
        fontSize: siteLang == "eng" ? "21px" : "21px",
        padding: siteLang == "eng" ? "3px 10px" : "3px 10px",
        fontFamily: "'DBHeaventLight','DBHeavent'",
        cursor: "pointer",
        lineHeight: "1"
      };
    },
  };

  const MatrixComponent = () => {

    const rows = [];
    for (let i = 0; i < 11; i++) {
        const columns = [];
        for (let j = 0; j < 6; j++) {
            const isActive = i === 5;
            columns.push(
                <td key={`matrix_${j}_${i + 1}`} className={`matrix_${j}_${i + 1} ${isActive ? 'active' : ''}`}>-</td>
            );
        }
        rows.push(
            <tr key={`row_${i}`}>{columns}</tr>
        );
    }
    return <tbody>{rows}</tbody>;
    // setTableHtml(rows)
  };

  function isNumberKey(e){
    const key = e.key;
    if(!/^\d$/.test(key) && !(key === '.' && impliedVolatility.indexOf('.') === -1) && !['Backspace', 'ArrowLeft', 'ArrowRight'].includes(key)){
      e.preventDefault();
    }
  }

  function isNumberKeyBid(e){
    const key = e.key;
    if(!/^\d$/.test(key) && !(key === '.' && underlyingBidPrice.indexOf('.') === -1) && !['Backspace', 'ArrowLeft', 'ArrowRight'].includes(key)){
      e.preventDefault();
    }
  }

  return (
    <>
      <div id="calculator" className="pageobj">
        <div className="breadcrumbbar">
          <div className="container-fluid">
            <ol className="breadcrumb">
              <li>
                <Link to="/home">{siteLang == "eng" ? languageText.firstNavigationEn : languageText.firstNavigation}</Link>
              </li>
              <li className="active">
                {siteLang == "eng" ? languageText.secondNavigationEn : languageText.secondNavigation}
              </li>
            </ol>
          </div>
        </div>
        <div className="stripe-bg" />
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-8">
              <div className="section">
                <div className="page-header">
                  <h1>{siteLang == "eng" ? languageText.pageTitleEn : languageText.pageTitle}</h1>
                </div>
                <p>
                  {siteLang == "eng" ? languageText.pageDescriptionEn : languageText.pageDescription}
                </p>
                <div id="dropdown-calc">
                  <Select
                    placeholder={siteLang == "eng" ? languageText.selectSymbolPlaceholderEn : languageText.selectSymbolPlaceholder}
                    onChange={(e) => getSelectedValue(e)}
                    options={symbolList}
                    styles={colourStyles}
                    value={"เลือกชื่อย่อ DW"}
                  />
                </div>
                <div id="initloadingbox" style={{position: 'relative'}}>
                  <div id="ticker" className="ricbar bgcolor-03">
                    {allMarketData?.ticker}
                  </div>
                  <div id="pstable">
                    <table className="table table-striped">
                      <tbody>
                        <tr>
                          <th>{siteLang=="eng"?languageText.psTableCurrentEn:languageText.psTableCurrent}</th>
                          <td className="left BID">{allMarketData?.BID}</td>
                        </tr>
                        <tr>
                          <th>{siteLang=="eng"?languageText.psTableGearingEn:languageText.psTableGearing}</th>
                          <td className="left effective_gearing">{allMarketData?.effective_gearing}</td>
                        </tr>
                        <tr>
                          <th>{siteLang=="eng"?languageText.psTableSensitivityEn:languageText.psTableSensitivity}</th>
                          <td className="left sensitivity">{allMarketData?.sensitivity}</td>
                        </tr>
                        <tr>
                          <th>{siteLang=="eng"?languageText.psTableLastTradingEn:languageText.psTableLastTrading}</th>
                          <td className="left last_trading_date">{allMarketData?.last_trading_date}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <h3>{siteLang == "eng" ? languageText.inputFieldEn : languageText.inputField}</h3>
                  <div id="wcalbox">
                    <table className="hidden-xs hidden-sm">
                      <tbody>
                        <tr className="wcalbox-row-lbl">
                          <td className="wcalbox-col-lbl">
                            {siteLang=="eng"?languageText.underlyingBidPriceEn:languageText.underlyingBidPrice}<span className="ccy" style={{display: ccyDisplay}}>(THB)</span>
                          </td>
                          <td className="wcol-col-empty" />
                        </tr>
                        <tr className="wcalbox-row-ctr">
                          <td className="wcalbox-col-input">
                            <input 
                              value={underlyingBidPrice == undefined ? 0 : underlyingBidPrice}
                              className="wcalbox-input underlying_bid_price" 
                              type="text" 
                              maxLength={10}
                              onChange={(e) => {
                                setinitial(false);
                                if(e.target.value > maxUnderlying){
                                  setUnderlyingBidPrice(maxUnderlying.toFixed(2))
                                }else{
                                  setUnderlyingBidPrice(e.target.value)
                                }
                              }}
                              onKeyDown={(e) => isNumberKeyBid(e)}
                            />
                          </td>
                          <td className="wcalbox-col-slider">
                            <RangeSliderUnderlyingPriceComponent
                              Value={underlyingBidPrice == undefined ? 0 : underlyingBidPrice}
                              SetValue={(val) => {
                                setinitial(false);
                                setUnderlyingBidPrice(val);
                              }}
                              maxUnderlying={maxUnderlying}
                              step={unPriceProps.spread}
                              min={0}
                            />
                          </td>
                        </tr>
                        <tr className="wcalbox-row-lbl">
                          <td className="wcalbox-col-lbl">{siteLang == "eng" ? languageText.impliedVolatilityEn : languageText.impliedVolatility}(%)</td>
                          <td />
                        </tr>
                        <tr className="wcalbox-row-ctr">
                          <td className="wcalbox-col-input">
                            <input 
                              value={impliedVolatility == undefined ? "" : impliedVolatility} 
                              className="wcalbox-input implied_volatility" 
                              type="text" 
                              name="ivval" 
                              maxLength={6}
                              autoComplete="off" 
                              onChange={(e) => {
                                setinitial(false);
                                if(e.target.value > 150){
                                  setImpliedVolatility(150)
                                }else{
                                  setImpliedVolatility(e.target.value);
                                }
                              }}
                              onKeyDown={(e) => isNumberKey(e)}
                            />
                          </td>
                          <td className="wcalbox-col-slider">
                            <RangeSliderImpliedVolatilityComponent
                              Value={impliedVolatility == "N/A" || impliedVolatility == "N/" || impliedVolatility == "N" || impliedVolatility == "" || impliedVolatility == undefined ? 0 : impliedVolatility}
                              SetValue={(val) => {
                                setinitial(false);
                                setImpliedVolatility(val);
                              }}
                            />
                          </td>
                        </tr>
                        <tr className="wcalbox-row-lbl">
                          <td className="wcalbox-col-lbl">
                            {siteLang == "eng" ? languageText.inputFieldDateEn : languageText.inputFieldDate}
                          </td>
                          <td />
                        </tr>
                        <tr className="wcalbox-row-ctr">
                          <td className="wcalbox-col-input" style={{position: 'relative'}}>
                            <input value={moment(new Date(todayDate)).format("DD MMM YY")} className="wcalbox-input today_date" type="text" name="dateval" readOnly />
                            <DatePicker
                              selected={todayDate}
                              clearIcon={null}
                              format="y-MMM-dd"
                              minDate={moment().toDate()}
                              maxDate={new Date(allMarketData?.last_trading_date)}
                              onChange={(value) => {
                                setinitial(false)
                                setTodayDate(new Date(new Date(new Date(new Date(value).setHours(currentHour)).setMinutes(currentMinute)).setSeconds(currentSecond)))
                              }}
                              onKeyDown={(e) => {
                                e.preventDefault();
                              }}
                              className="wcalbox-input"
                            />
                          </td>
                          <td className="wcalbox-col-slider">
                            <RangeSliderDateSelectionComponent
                              value={todayDate}
                              setValue={(val) => {
                                setinitial(false);
                                setTodayDate(val);
                              }}
                              startMatrix={startMatrix}
                              max={new Date(allMarketData?.last_trading_date == undefined ? new Date() : allMarketData?.last_trading_date).getTime()+86400000}
                              min={+new Date().getTime()}
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div className="visible-xs visible-sm">
                      <div className="row">
                        <div className="wcalbox-row col-xs-12 col-sm-4">
                          <div className="wcalbox-col">
                            {siteLang == "eng" ? languageText.underlyingBidPriceEn : languageText.underlyingBidPrice}
                            <span className="ccy" style={{display: ccyDisplay}}>(THB)</span>
                          </div>
                          <div className="wcalbox-col">
                            <input 
                              value={underlyingBidPrice == undefined ? 0 : underlyingBidPrice}
                              className="wcalbox-input underlying_bid_price" 
                              type="text" 
                              maxLength={10}
                              onChange={(e) => {
                                setinitial(false);
                                if(e.target.value > maxUnderlying){
                                  setUnderlyingBidPrice(maxUnderlying.toFixed(2))
                                }else{
                                  setUnderlyingBidPrice(e.target.value)
                                }
                              }}
                              onKeyDown={(e) => isNumberKeyBid(e)}
                            />
                          </div>
                        </div>
                        <div className="wcalbox-row col-xs-12 col-sm-4">
                          <div className="wcalbox-col">
                            {siteLang == "eng" ? languageText.impliedVolatilityEn : languageText.impliedVolatility}(%)
                          </div>
                          <div className="wcalbox-col">
                            <input 
                              value={impliedVolatility == undefined ? "" : impliedVolatility} 
                              className="wcalbox-input implied_volatility" 
                              type="text" 
                              name="ivval" 
                              maxLength={6} 
                              autoComplete="off" 
                              onChange={(e) => {
                                setinitial(false);
                                if(e.target.value > 150){
                                  setImpliedVolatility(150)
                                }else{
                                  setImpliedVolatility(e.target.value);
                                }
                              }}
                              onKeyDown={(e) => isNumberKey(e)}
                            />
                          </div>
                        </div>
                        <div className="wcalbox-row col-xs-12 col-sm-4">
                          <div className="wcalbox-col">
                            {siteLang == "eng" ? languageText.inputFieldDateEn : languageText.inputFieldDate}
                          </div>
                          <div className="wcalbox-col" style={{position: "relative"}}>
                            <input value={moment(new Date(todayDate)).format("DD MMM YY")} className="wcalbox-input today_date" type="text" name="dateval" readOnly />
                            <DatePicker
                              selected={todayDate}
                              clearIcon={null}
                              format="y-MMM-dd"
                              minDate={moment().toDate()}
                              maxDate={new Date(allMarketData?.last_trading_date)}
                              onChange={(value) => {
                                setinitial(false)
                                setTodayDate(new Date(new Date(new Date(new Date(value).setHours(currentHour)).setMinutes(currentMinute)).setSeconds(currentSecond)))
                              }}
                              onKeyDown={(e) => {
                                e.preventDefault();
                              }}
                              className="wcalbox-input"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <h3>{siteLang == "eng" ? languageText.simulatedResultsEn : languageText.simulatedResults}</h3>
                  <table id="ivtable" className="table table-closed table-striped table-condensed">
                    <thead>
                      <tr>
                        <th rowSpan={2} className="bgcolor-06" style={{verticalAlign: 'middle'}}>
                          {siteLang == "eng" ? languageText.simulatedUnderlyingBidPriceEn : languageText.simulatedUnderlyingBidPrice}
                        </th>
                        <th colSpan={5} className="bgcolor-06">
                          {siteLang == "eng" ? languageText.simulatedDwBidPriceEn : languageText.simulatedDwBidPrice} (THB)
                        </th>
                      </tr>
                      <tr className="row_0">
                        <th className="matrix_1_0 bgcolor-06">-</th>
                        <th className="matrix_2_0 bgcolor-06">-</th>
                        <th className="matrix_3_0 bgcolor-06">-</th>
                        <th className="matrix_4_0 bgcolor-06">-</th>
                        <th className="matrix_5_0 bgcolor-06">-</th>
                      </tr>
                    </thead>
                    {/* <tbody>{tableHtml}</tbody> */}
                    <MatrixComponent />
                  </table>
                  <p className="small">
                    {siteLang == "eng" ? languageText.importantNote1En : languageText.importantNote1}
                    <br />
                    <br />
                    {siteLang == "eng" ? languageText.importantNote2En : languageText.importantNote2}
                  </p>
                  <hr className="hr-hmgbm" />
                  <p className="small">{siteLang == "eng" ? languageText.marketDelayEn : languageText.marketDelay}</p>
                </div>    
              </div>
            </div>
            <div className="hidden-xs hidden-sm col-md-4">
              <WarrantSearchSideBars />
              <LiveMatrixSideBars />
              <DwTermsSideBars />
              <AdvertiseMentSidebar />
            </div>
          </div>
        </div>
      </div>

    </>
  );
};

export default DwCalculator;
