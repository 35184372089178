import React, { useState, useEffect } from "react";
import "./highlight.css";
import { getHighlight } from "../../../utils/Highlight.api";
import { Link } from "react-router-dom";
import axios from "axios"
import { languageText } from "../../../languageText/marketNewsCommon";
import { config } from "../../../utils/apiUrl";

export default function Highlights() {

  const queryParams = new URLSearchParams(window.location.search);
  const id = queryParams.get("id");
  const cat = queryParams.get("cat");
  const [Id, setId] = useState(id);
  const [Cat, setCat] = useState(cat);
  const [result, setResult] = useState();
  const [nav, setNav] = useState();
  const [ric, setRic] = useState("");
  const [image, setImage] = useState("");
  const [displayData, setDisplayData] = useState("none");
  const [chartInfo, setChartInfo] = useState([]);
  const [thaiLong, setThaiLong] = useState("");
  const navPrev = nav !== undefined ? nav.prev : "";
  const navNext = nav !== undefined ? nav.next : "";
  const [tableData1, setTableData1] = useState([]);
  const [tableData2, setTableData2] = useState([]);
  const [tableData3, setTableData3] = useState([]);
  const [tableData4, setTableData4] = useState([]);
  const [tableData5, setTableData5] = useState([]);
  const [siteLang , setSiteLang] = useState("")

  useEffect(() => {
    window.scrollTo(0, 0);
    var siteLanguage = $.cookie('applang');
    setSiteLang(siteLanguage)
    if(siteLanguage == "eng"){
      document.querySelector("body").classList.add("eng")
    }else{
      document.querySelector("body").classList.remove("eng")
    }
    checkCat()
  }, []);
  
  useEffect(() => {
    getHighlightData()
  }, [Id, Cat, siteLang]);

  function getHighlightData() {
    getHighlight(Cat, Id).then(async (res) => {
      const cinfo = res?.data?.newsData[0]?.image;
      var newsDataAll = res?.data?.newsData[0].type.toLocaleUpperCase()
      var newsData2 = res?.data?.newsData2;
      let navObj = res.data.nav[0];
      var thaiLongData = ""
      if(siteLang == "eng"){ 
        thaiLongData = res.data.newsData[0]?.engLong;
      }else{
        thaiLongData = res.data.newsData[0]?.thaiLong;
      }
      if ((Cat == "hsi" || Cat == "singlestock" || Cat == "sp500" || Cat == "todayhighlight")) {
        var thailang = ""
        var tableStr = ""
        if(thaiLongData.split("<br><br>").length > 1){
          thailang = thaiLongData.split("<br><br>", 1)[0];
          thailang += '<br><br>';
          if(siteLang == "eng"){
            tableStr = `<table class="hltbl table table-closed table-striped"><thead><tr><th>ชื่อย่อ DW</th><th>ประเภท</th><th class="th-bd">Bid<br>(THB)</th><th class="th-eg"><span class="ttip" data-target="effectivegearing">Eff.<br>gearing(x)</span></th><th class="th-ss ${(newsDataAll == 'SET50' || newsDataAll == 'HSI' || newsDataAll == 'HSTECH' || newsDataAll == 'HSCEI' || newsDataAll == 'S&P500' || newsDataAll == 'NDX' || newsDataAll == 'DJI') ? 'hide' : 'hidden'}"><span class="ttip" data-target="sensitivity">Sensitivity</span></th><th class="th-si"><span class="ttip" data-target="sensitivityindex">Sensitivity by Index Point</span></th></tr></thead><tbody>`;
          }else{
            tableStr = `<table class="hltbl table table-closed table-striped"><thead><tr><th>ชื่อย่อ DW</th><th>ประเภท</th><th class="th-bd">ราคาเสนอซื้อ<br>(บาท)</th><th class="th-eg"><span class="ttip" data-target="effectivegearing">Eff.<br>gearing(x)</span></th><th class="th-ss  ${(newsDataAll == 'SET50' || newsDataAll == 'HSI' || newsDataAll == 'HSTECH' || newsDataAll == 'HSCEI' || newsDataAll == 'S&P500' || newsDataAll == 'NDX' || newsDataAll == 'DJI') ? 'hide' : 'hidden'}"><span class="ttip" data-target="sensitivity">Sensitivity</span></th><th class="th-si"><span class="ttip" data-target="sensitivityindex">Sensitivity (จุดดัชนี)</span></th></tr></thead><tbody>`;
          }
          
          if(newsData2?.length > 0){
            newsData2.map((news) => {
              tableStr += `<tr class="hltbl-rowtmpl" idx="0"><td class="symbol"><a href="/tools/livematrix/${news?.symbol}">${news?.symbol}</a></td><td class="type">${news?.type}</td><td class="col_bd">${news?.BID != null ? news?.BID : "-"}</td><td class="col_eg">${news?.effective_gearing != null ? news?.effective_gearing : "-"}</td><td class="col_sstvt  ${(newsDataAll == 'SET50' || newsDataAll == 'HSI' || newsDataAll == 'HSTECH' || newsDataAll == 'HSCEI' || newsDataAll == 'S&P500' || newsDataAll == 'NDX' || newsDataAll == 'DJI') ? 'hide' : 'hidden'}">${news?.sensitivity != null ? news?.sensitivity : "-"}</td>
                <td class="col_sstvti">${(news?.sensitivity != null && news?.sensitivity != undefined && news?.sensitivity !='N/A' && Number(news?.sensitivity) != 0) ? (1.0 / Number(news?.sensitivity_cal)).toFixed(2) : "N/A"}</td></tr>`;
            })
          }else{
            tableStr += `<tr class="emptymsg" idx="0"><td class="symbol" colspan="6">No data</td></tr>`;
          }
          tableStr += `</tbody></table>`;
          thailang += tableStr;
          let allstr = thaiLongData.split("<br><br>", -1);
          let count = 1;
          allstr.map(() => {
            if (count <= (allstr.length-1)) {
              thailang += "<br><br>" + allstr[count];
              count++;
            }
          });
        }
        else{
          thailang = thaiLongData;
        }
      } else {
        thailang = thaiLongData;
      }
      
      setThaiLong(thailang);
      setResult(res.data.newsData[0]);
      setNav(navObj);
      setRic(res?.data?.newsData[0]?.chartinfolist?.ric);
      setChartInfo(cinfo);
      if (cinfo) {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const imgId = urlParams.get('id');
        const category = urlParams.get('cat');
        // const url = `https://t12a.trkd-hs.com/apimqth/photo?${category == 'todayhighlight' ? 'dailyid' : category}=${imgId}`
        const url = `${config.advImgData}?${category == 'todayhighlight' ? 'dailyid' : category}=${imgId}`
        try {
          const response = await axios
            .get(url)
          const { data: imageSrc } = response;
          if (imageSrc) {
            setImage(<img src={imageSrc} alt="heighlight_img" className="topImage" />);
          }
        } catch (err) {
          console.log(err)
        }
      }
    });
  }

  const getTopTradedData = async () => {
    // const url = `https://t12a.trkd-hs.com/apimqth/TopTradedJSON?qid=${Date.now()}`
    const url = `${config.topTradedJSON}?qid=${Date.now()}`
    try {
      const response = await axios.get(url)
      if(response){
        setTableData1(response.data.topindicesMqOnly)
        setTableData2(response.data.topdwMqOnly)
        setTableData3(response.data.tophsiMqOnly)
        setTableData4(response.data.topsp500MqOnly)
        setTableData5(response.data.tophstechMqOnly)
      }
    } catch (err) {
      console.log(err)
    }
  } 

  const checkCat = () => {
    if( cat == "dailycommentary"){
      getTopTradedData()
      setDisplayData("block")
    }else{
      setDisplayData("none")
    }
  }

  function renderText() {
    // if (cat == 'dailycommentary') {
    //   return <Link id="pachor" to="/marketnews/dailycommentary">{siteLang == "eng" ? languageText.dwMarketCommentaryNavigationEn : languageText.dwMarketCommentaryNavigation}</Link>;
    // } 
    // if (cat == 'overnightmarkets') {
    //   return <Link id="pachor" to="/marketnews/overnightmarkets"> {siteLang == "eng" ? languageText.overNightMarketNavigationEn : languageText.overNightMarketNavigation}</Link>;
    // }
    // else if (cat == 'sp500') {
    //   return <Link id="pachor" to="/marketnews/dailysp500"> {siteLang == "eng" ? languageText.dailySp500NavigationEn : languageText.dailySp500Navigation}</Link>;
    // }
    if (cat == 'hsi') {
      return <Link id="pachor" to="/marketnews/dailyhsi"> {siteLang == "eng" ? languageText.dailyHsiNavigationEn : languageText.dailyHsiNavigation}</Link>;
    }
    else if (cat == 'todayhighlight') {
      return <Link id="pachor" to="/marketnews/todayhighlight"> {siteLang == "eng" ? languageText.todayHighlightNavigationEn : languageText.todayHighlightNavigation}</Link>;
    }
    return <Link id="pachor" to="/marketnews/dailysinglestock"> {siteLang == "eng" ? languageText.dailySingleStockNavigationEn : languageText.dailySingleStockNavigation}</Link>;
  }

  const openFacebook = () => {
    window.open("https://www.facebook.com/login.php?skip_api_login=1&api_key=966242223397117&signed_next=1&next=https%3A%2F%2Fwww.facebook.com%2Fshare.php%3Fu%3Dhttps%253A%252F%252Ft1www5.trkd-hs.com%252Fmqmy_aws%252Fmarketnews%252Fhighlight%253Fcat%253Dovernightmarkets%2526id%253D5217%26p%255Bsummary%255D%3DEscalating%2Btensions%2Bin%2Bthe%2BMiddle%2BEast&cancel_url=https%3A%2F%2Fwww.facebook.com%2Fdialog%2Fclose_window%2F%3Fapp_id%3D966242223397117%26connect%3D0%23_%3D_&display=popup&locale=en_GB", 'share', 'width=560,height=650');
  }

  return (
    <div id="highlight" className="pageobj">
      <div className="breadcrumbbar">
        <div className="container-fluid">
          <ol className="breadcrumb">
            <li>
              <Link to="/home?dwnews">{siteLang == "eng" ? languageText.firstNavigationEn : languageText.firstNavigation}</Link>
            </li>
            <li>{renderText()}</li>
            <li 
              className="active" 
              id="breadcrumb-label" 
              dangerouslySetInnerHTML={{__html: result != undefined ? (siteLang == "eng" ? result.en_title : result.thai_title) : "" }} />
          </ol>
        </div>
      </div>
      <div className="stripe-bg" />
      <div className="container-fluid">
        <div className="section">
          <div className="page-header">
            <h1 id="page-title" dangerouslySetInnerHTML={{
              __html: result != undefined ? (siteLang == "eng") ? result.en_title : result.thai_title : "",
            }}>

            </h1>
            <div className="btn-area">
              <div className="fbSharebtn" onClick={openFacebook} >
                <i className="fa fa-facebook" aria-hidden="true" />
                Share on Facebook
              </div>
            </div>
          </div>
          <div id="highlight-chart" ></div>
          <div id="highlight-content">
            {image}
            <p dangerouslySetInnerHTML={{ __html: thaiLong != undefined ? thaiLong : "",}}/>
          </div>
          <div id="toptradeddw" className="section" style={{display: displayData}}>                        
            <div className="row">
              <div id="topindices" className="col-sm-6">
                <h2 className="small">SET50 DW </h2>
                <table className="table table-striped table-closed">
                  <thead>
                    <tr>
                      <th>{siteLang == "eng" ? "Symbol" : "ชื่อย่อ"}</th>
                      <th>{siteLang == "eng" ? "Bid (THB)" : "ราคาเสนอซื้อ (บาท)"}</th>
                      <th>{siteLang == "eng" ? "Change (%)" : "เปลี่ยนแปลง (%)"}</th>
                      <th>{siteLang == "eng" ? "Volume ('000)" : "ปริมาณการซื้อขาย ('000)"}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tableData1 && tableData1.map(function(data, i) {
                      return (i < 5) ?
                      <tr className="tt_rowtmpl" key={i}>
                        <td className="ticker val"><Link to={`/tools/livematrix/${data.ticker}`}>{data.ticker}</Link></td>
                        <td className="bid val">{data.bid}</td>
                        <td className={data.pct_dis > 0 ? "pct_dis val up" : data.pct_dis < 0 ? "pct_dis val down" : "pct_dis val"}>
                          {data.pct_dis > 0 ? "+"+data.pct_dis.toFixed(1) : data.pct_dis < 0 ? data.pct_dis.toFixed(1) : data.pct_dis}
                        </td>
                        <td className="vol val">{data.vol.toLocaleString()}</td>
                      </tr> : null
                    })}
                    {tableData1 && tableData1.length < 1 && <tr className="emptymsg"><td colSpan="4">No data</td></tr>}
                  </tbody>
                </table>
              </div>
              <div id="topdw" className="col-sm-6">
                <h2 className="small">Single Stock DW</h2>
                <table className="table table-striped table-closed">
                  <thead>
                    <tr>
                    <th>{siteLang == "eng" ? "Symbol" : "ชื่อย่อ"}</th>
                      <th>{siteLang == "eng" ? "Bid (THB)" : "ราคาเสนอซื้อ (บาท)"}</th>
                      <th>{siteLang == "eng" ? "Change (%)" : "เปลี่ยนแปลง (%)"}</th>
                      <th>{siteLang == "eng" ? "Volume ('000)" : "ปริมาณการซื้อขาย ('000)"}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tableData2 && tableData2.map(function(data, i) {
                      return (i < 5) ?
                      <tr className="tt_rowtmpl" key={i}>
                        <td className="ticker val"><Link to={`/tools/livematrix/${data.ticker}`}>{data.ticker}</Link></td>
                        <td className="bid val">{data.bid}</td>
                        <td className={data.pct_dis > 0 ? "pct_dis val up" : data.pct_dis < 0 ? "pct_dis val down" : "pct_dis val"}>
                          {data.pct_dis > 0 ? "+"+data.pct_dis.toFixed(1) : data.pct_dis < 0 ? data.pct_dis.toFixed(1) : data.pct_dis}
                        </td>
                        <td className="vol val">{data.vol.toLocaleString()}</td>
                      </tr> : null
                    })}
                    {tableData2 && tableData2.length < 1 && <tr className="emptymsg"><td colSpan="4">No data</td></tr>}
                  </tbody>
                </table>
              </div>
              <div id="tophsi" className="col-sm-6">
                <h2 className="small">HSI DW</h2>
                <table className="table table-striped table-closed">
                  <thead>
                    <tr>
                    <th>{siteLang == "eng" ? "Symbol" : "ชื่อย่อ"}</th>
                      <th>{siteLang == "eng" ? "Bid (THB)" : "ราคาเสนอซื้อ (บาท)"}</th>
                      <th>{siteLang == "eng" ? "Change (%)" : "เปลี่ยนแปลง (%)"}</th>
                      <th>{siteLang == "eng" ? "Volume ('000)" : "ปริมาณการซื้อขาย ('000)"}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tableData3 && tableData3.map(function(data, i) {
                      return (i < 5) ?
                      <tr className="tt_rowtmpl" key={i}>
                        <td className="ticker val"><Link to={`/tools/livematrix/${data.ticker}`}>{data.ticker}</Link></td>
                        <td className="bid val">{data.bid}</td>
                        <td className={data.pct_dis > 0 ? "pct_dis val up" : data.pct_dis < 0 ? "pct_dis val down" : "pct_dis val"}>
                          {data.pct_dis > 0 ? "+"+data.pct_dis.toFixed(1) : data.pct_dis < 0 ? data.pct_dis.toFixed(1) : data.pct_dis}
                        </td>
                        <td className="vol val">{data.vol.toLocaleString()}</td>
                      </tr> : null
                    })}
                    {tableData3 && tableData3.length < 1 && <tr className="emptymsg"><td colSpan="4">No data</td></tr>}
                  </tbody>
                </table>
              </div>    
              <div id="topsp500" className="col-sm-6">
                <h2 className="small">S&amp;P500 DW</h2>
                <table className="table table-striped table-closed">
                  <thead>
                    <tr>
                    <th>{siteLang == "eng" ? "Symbol" : "ชื่อย่อ"}</th>
                      <th>{siteLang == "eng" ? "Bid (THB)" : "ราคาเสนอซื้อ (บาท)"}</th>
                      <th>{siteLang == "eng" ? "Change (%)" : "เปลี่ยนแปลง (%)"}</th>
                      <th>{siteLang == "eng" ? "Volume ('000)" : "ปริมาณการซื้อขาย ('000)"}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tableData4 && tableData4.map(function(data, i) {
                      return (i < 5) ?
                      <tr className="tt_rowtmpl" key={i}>
                        <td className="ticker val"><Link to={`/tools/livematrix/${data.ticker}`}>{data.ticker}</Link></td>
                        <td className="bid val">{data.bid}</td>
                        <td className={data.pct_dis > 0 ? "pct_dis val up" : data.pct_dis < 0 ? "pct_dis val down" : "pct_dis val"}>
                          {data.pct_dis > 0 ? "+"+data.pct_dis.toFixed(1) : data.pct_dis < 0 ? data.pct_dis.toFixed(1) : data.pct_dis}
                        </td>
                        <td className="vol val">{data.vol.toLocaleString()}</td>
                      </tr> : null
                    })}
                    {tableData4 && tableData4.length < 1 && <tr className="emptymsg"><td colSpan="4">No data</td></tr>}
                  </tbody>
                </table>
              </div>    
              <div id="tophstech" className="col-sm-6">
                <h2 className="small">HSTECH DW</h2>
                <table className="table table-striped table-closed">
                  <thead>
                    <tr>
                    <th>{siteLang == "eng" ? "Symbol" : "ชื่อย่อ"}</th>
                      <th>{siteLang == "eng" ? "Bid (THB)" : "ราคาเสนอซื้อ (บาท)"}</th>
                      <th>{siteLang == "eng" ? "Change (%)" : "เปลี่ยนแปลง (%)"}</th>
                      <th>{siteLang == "eng" ? "Volume ('000)" : "ปริมาณการซื้อขาย ('000)"}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tableData5 && tableData5.map(function(data, i) {
                      return (i < 5) ?
                      <tr className="tt_rowtmpl" key={i}>
                        <td className="ticker val"><Link to={`/tools/livematrix/${data.ticker}`}>{data.ticker}</Link></td>
                        <td className="bid val">{data.bid}</td>
                        <td className={data.pct_dis > 0 ? "pct_dis val up" : data.pct_dis < 0 ? "pct_dis val down" : "pct_dis val"}>
                          {data.pct_dis > 0 ? "+"+data.pct_dis.toFixed(1) : data.pct_dis < 0 ? data.pct_dis.toFixed(1) : data.pct_dis}
                        </td>
                        <td className="vol val">{data.vol.toLocaleString()}</td>
                      </tr> : null
                    })}
                    {tableData5 && tableData5.length < 1 && <tr className="emptymsg"><td colSpan="4">No data</td></tr>}
                  </tbody>
                </table>
              </div>    
            </div>
            <div className="section-btn-wapper" style={{textAlign: "right"}}>
              <Link to="/marketdata/toptradewarrants" style={{minWidth: '190px'}} type="button" className="btn btn-primary">{siteLang == "eng" ? "View more" : "เพิ่มเติม คลิก"}</Link>
            </div>         
          </div>
          <div className="pnbar">
            <div className="pnbar-prev">
              <Link
                id="prev-achor"
                to={"/marketnews/highlight?cat=" + Cat + "&id=" + navPrev}
                className={(nav?.prev === -1 || nav == undefined) ? "disabled" : ""}
              >
                &lt; Previous day
              </Link>
            </div>
            <div className="pnbar-vl"></div>
            <div className="pnbar-next">

              <Link
                id="next-achor"
                to={"/marketnews/highlight?cat=" + Cat + "&id=" + navNext}
                className={(nav?.next === -1 || nav == undefined) ? "disabled" : ""}
              >
                Next day &gt;
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

