import React, { useState, useEffect } from "react";
import "../../common.css";
import "../../investorAcademy.css";
import { Link, useLocation, useHistory } from "react-router-dom";
import ChildInvestorAcademyCommon from "../../../../../components/common/InvestorAcademyCommon/ChildInvestorAcademyCommon";
import { languageText } from "../../../../../languageText/InvestorAcademy/UsefulWarrantTerms/impliedVolatilities"
import { languageTextMain } from "../../../../../languageText/InvestorAcademy/index"

const ImpliedVolatilities = (props) => {
  
  const location = useLocation();
  const history = useHistory();
  const [siteLang , setSiteLang] = useState("")
  const [display , setDisplay] = useState("none")
  const [imgDataVal , setImgDataVal] = useState("")

  // Site Language Change Start //
  useEffect(() => {
    window.scrollTo(0, 0);
    var siteLanguage = $.cookie('applang');
    setSiteLang(siteLanguage)
    if(siteLanguage == "eng"){
      document.querySelector("body").classList.add("eng")
    }else{
      document.querySelector("body").classList.remove("eng")
    }
  }, []);
  // Site Language Change End //

  // View Chart Image Popup Open And Close Start //
  const openPopUp = (imgData) => {
    if(display == "none"){
      setImgDataVal(imgData)
      setDisplay("block")
    }else{
      setImgDataVal("")
      setDisplay("none")
    }
  }
  // View Chart Image Popup Open And Close End //

  return (
    <div id="investor_academy">
      <div className="imgbg" />
      <div className="mainbg" />
      <div id="main">
        <div id="maintable">
          <div style={{ display: "table-row" }}>
            <div id="leftbar" className="hidden-xs" />
            <div id="maincontainer">
              {/* Top Title Section Start */}
              <div id="mainnav">
                <table>
                  <tbody>
                    <tr>
                      <td width="20%" style={{textAlign:"left", visibility: "hidden"}} />
                      <td width="60%" style={{ textAlign: "center" }}>
                        <div id="pagetitlediv">
                          <div id="ptitle">
                            {siteLang == "eng" ? languageText.pageTitleEn : languageText.pageTitle}
                          </div>
                          <div id="pname">
                            {siteLang == "eng" ? languageText.pageNameEn : languageText.pageName}
                          </div>
                        </div>
                      </td>
                      <td width="20%" style={{textAlign:"right", visibility: "hidden"}} />
                    </tr>
                  </tbody>
                </table>
              </div>
              {/* Top Title Section End */}
              {/* Main Content Section Start */}
              <div id="maincontent" className="preminum">
                <div id="mainscroll">
                  <div id="mainscrollcontent">
                    <style dangerouslySetInnerHTML={{__html: ".imgholder img{ max-width: 680px;}.contentbox table{margin: 0 auto;}.contentbox table thead th{background-color: #FFD400;}.contentbox table tbody td,.contentbox table thead th{height:2em;padding:0 1em;color:#000000;}.contentbox table tbody  tr:nth-child(even) td {background-color: #FFF4D2}.contentbox table tbody  tr:nth-child(odd) td {background-color: #FFFF80}.contentbox table tbody  tr.redtxt td {color:#FE3200}.contentbox table thead th{text-align:left;}.contentbox table tfoot td.disclaimer{padding-top: 0.5em;color: #FFFFFF;font-size: 0.8em; }.listholder{margin: 0 auto;font-size:14px; }body.eng .listholder{font-family: 'MCQGlobal_A_Rg';}.listholder ul li{line-height: 1.48em}" }} />
                    <div className="contentbox">
                      <p>
                        {siteLang == "eng" ? languageText.pageDescriptionEn : languageText.pageDescription}
                        <br /><br />
                        {siteLang == "eng" ? languageText.pageDescription1En : languageText.pageDescription1}
                        <br /><br />
                        {siteLang == "eng" ? languageText.pageDescription2En : languageText.pageDescription2}
                      </p>
                      <div className="listholder" style={{maxWidth: '480px'}}>
                        <ul>
                          <li>{siteLang == "eng" ? languageText.listHolder1En : languageText.listHolder1}</li>
                          <li>{siteLang == "eng" ? languageText.listHolder2En : languageText.listHolder2}</li>
                          <li>{siteLang == "eng" ? languageText.listHolder3En : languageText.listHolder3}</li>
                        </ul>
                      </div>
                      <div className="imgholder">
                        <img style={{maxWidth: '300px'}} src={siteLang == "eng" ? languageText.imgUrlEn : languageText.imgUrl} />
                      </div>
                      <p>{siteLang == "eng" ? languageText.pageDescription3En : languageText.pageDescription3}</p>
                      <div className="imgholder">
                        <img src={siteLang == "eng" ? languageText.imgUrl1En : languageText.imgUrl1} />
                        <div className="visible-xs">
                          <button onClick={() => openPopUp(siteLang == "eng" ? languageText.imgUrl1En : languageText.imgUrl1)}>View table</button>
                        </div>
                      </div>
                      <p>
                        {siteLang == "eng" ? languageText.pageDescription4En : languageText.pageDescription4}
                        <br /><br />
                        {siteLang == "eng" ? languageText.pageDescription5En : languageText.pageDescription5}
                      </p>
                      <div className="imgholder">
                        <img src={siteLang == "eng" ? languageText.imgUrl2En : languageText.imgUrl2} />
                        <div className="visible-xs">
                          <button onClick={() => openPopUp(siteLang == "eng" ? languageText.imgUrl2En : languageText.imgUrl2)}>View table</button>
                        </div>
                      </div>
                      <p>
                        {siteLang == "eng" ? languageText.pageDescription6En : languageText.pageDescription6}
                        <br /><br />
                        {siteLang == "eng" ? languageText.pageDescription7En : languageText.pageDescription7}
                      </p>
                      <div className="listholder" style={{maxWidth: '774px'}}>
                        <ul>
                          <li>{siteLang == "eng" ? languageText.list1Holder1En : languageText.list1Holder1}</li>
                          <li>{siteLang == "eng" ? languageText.list1Holder2En : languageText.list1Holder2} </li>
                          <li>{siteLang == "eng" ? languageText.list1Holder3En : languageText.list1Holder3}</li>
                        </ul>
                      </div>
                      <div className="imgholder">
                        <img src={siteLang == "eng" ? languageText.imgUrl3En : languageText.imgUrl3} />
                        <div className="visible-xs">
                          <button onClick={() => openPopUp(siteLang == "eng" ? languageText.imgUrl3En : languageText.imgUrl3)}>View table</button>
                        </div>
                      </div>
                      <p>
                        {siteLang == "eng" ? languageText.pageDescription8En : languageText.pageDescription8}
                        <br /><br />
                        {siteLang == "eng" ? languageText.pageDescription9En : languageText.pageDescription9}
                        <br /><br />
                        {siteLang == "eng" ? languageText.pageDescription10En : languageText.pageDescription10}
                      </p>
                      <div className="listholder" style={{maxWidth: '612px'}}>
                        <ul>
                          <li>{siteLang == "eng" ? languageText.list2Holder1En : languageText.list2Holder1}</li> 
                          <li>{siteLang == "eng" ? languageText.list2Holder2En : languageText.list2Holder2}</li> 
                          <li>{siteLang == "eng" ? languageText.list2Holder3En : languageText.list2Holder3}</li> 
                        </ul>
                      </div>
                      <div className="tipholder">
                        <div className="tipbox">
                          <h2>{siteLang == "eng" ? languageText.tipHolderHeadingEn : languageText.tipHolderHeading}</h2>
                          <ul>
                            <li>
                              <p>
                                {siteLang == "eng" ? languageText.tipHolderParagraph1En : languageText.tipHolderParagraph1}
                                <a href="/tools/dwsearch">{siteLang == "eng" ? languageText.tipHolderLinkEn : languageText.tipHolderLink}</a> 
                                {siteLang == "eng" ? languageText.tipHolderParagraph12En : languageText.tipHolderParagraph12} 
                                <a href="/tools/dwterms">{siteLang == "eng" ? languageText.tipHolderLink2En : languageText.tipHolderLink2}</a>
                                {siteLang == "eng" ? "page" : ""}
                              </p>
                            </li>
                            <li>
                              <p>
                                {siteLang == "eng" ? languageText.tipHolderParagraph2En : languageText.tipHolderParagraph2}
                                <a href="/tools/moneyflow">{siteLang == "eng" ? languageText.tipHolderLink3En : languageText.tipHolderLink3}</a>
                              </p>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Main Content Section End */}
            </div>
            <div id="rightbar" className="hidden-xs" />
          </div>
        </div>
      </div>
      {/* Bottom Left Right Links and Copyright Section Start */}
      <div id="bottom">
        <div className="hidden-xs hidden-xxs" id="copyright">
          {siteLang == "eng" ? languageTextMain.copyRightEn : languageTextMain.copyRight}
        </div>
        <div className="leftUrl">
          <table className="hasprev">
            <tbody>
              <tr>
                <td width="33px">
                  <Link to="/eduhtml/effectivegearing">
                    <div className="prev left" />
                  </Link>
                </td>
                <td>
                  <div className="prevlbl left">
                    <div className="prevname">
                      <Link to="/eduhtml/effectivegearing">
                        {siteLang == "eng" ? languageTextMain.effectiveGearingEn : languageTextMain.effectiveGearing}
                      </Link>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="rightUrl">
          <table className="hasnext">
            <tbody>
              <tr>
                <td>
                  <div className="nextlbl right">
                    <div className="nextname">
                      <Link to="/eduhtml/howtoselectawarrant">
                        {siteLang == "eng" ? languageTextMain.howtoSelectaWarrantEn : languageTextMain.howtoSelectaWarrant}
                      </Link>
                    </div>
                  </div>
                </td>
                <td width="33px">
                  <Link to="/eduhtml/howtoselectawarrant">
                    <div className="next right" />
                  </Link>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      {/* Bottom Left Right Links and Copyright Section End */}
      {/* Right Dots Menu Component Section Start */}
      <div id="dotbar" className="hidden-sm hidden-xs">
        <ChildInvestorAcademyCommon Location={location} Hist={history} />
      </div>
      {/* Right Dots Menu Component Section End  */}
      {/* View Chart Popup In Mobile Start  */}
      <div id="imgpopup" style={{display: display}}>
        <div id="closebtn2" onClick={openPopUp} />
        <div className="imgHold">
          <img src={imgDataVal} className="landscape" />
        </div>
      </div>
      {/* View Chart Popup In Mobile End  */}
    </div>
  );
};
export default ImpliedVolatilities;
