export const languageText ={

    // Page Top Heading And Description Start //
    pageTitle: "ตัวแปรเกี่ยวกับ DW ที่ควรรู้",
    pageTitleEn: "Useful DW terms",

    pageDescription: "ในการลงทุน DW มีตัวแปรหลายตัวที่เกี่ยวข้อง ซึ่งนักลงทุนควรเข้าใจก่อนลงทุน เพราะเป็นส่วนสำคัญที่จะช่วยให้นักลงทุนประสบความสำเร็จในการลงทุนใน DW ได้ สามารถคลิกหัวข้อเพื่ออ่านข้อมูลเพิ่มเติม",
    pageDescriptionEn: "There are a number of different terms that are often used when investing in DW and it's important to understand what these mean if you're going to trade this important market instrument successfully. Please click on each of the tiles below for more details.",
    // Page Top Heading And Description End //

    // Hexa Box Content Start //
    delta: "เดลต้า (Delta)",
    deltaEn: "Delta",

    exerciseRatio: "DW ต่อหุ้น ",
    exerciseRatio1: " (DWPS)",
    exerciseRatioEn: "DW per share (DWPS)",
    exerciseRatioMobileEn: "Warrants Per Share",

    dWSensitivity: "DW Sensitivity",
    dWSensitivityEn: "DW ",
    dWSensitivity1En: " Sensitivity",
    dWSensitivityMobileEn: "Warrant Sensitivity",

    breakEvenPrice: "จุดคุ้มทุน ",
    breakEvenPriceEn: "Breakeven ",
    breakEvenPrice1: " (Breakeven Price)",
    breakEvenPrice1En: " Price",

    premium: "Premium หรือ ",
    premiumEn: "Premium ",
    premium1: " Time Value",

    gearing: "อัตราทด ",
    gearingEn: "Gearing ",
    gearing1: " (Gearing)",

    effectiveGearing: "Effective ",
    effectiveGearingEn: "Effective ",
    effectiveGearing1: " Gearing",
    effectiveGearing1En: " Gearing",

    impliedVolatility: "ความผันผวนแฝง ",
    impliedVolatilityEn: "Implied ",
    impliedVolatility1: " (Implied Volatility)",
    impliedVolatility1En: " Volatility",
    // Hexa Box Content End //
}