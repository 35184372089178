export const languageText = {
    labelTextUnsubscribeTH: "คุณต้องการยกเลิกรับข่าวสารหรือไม่?",
    labelTextIfUnsubscribeTH: "หากคุณยกเลิกการสมัคร",
    labelReceivingourTH: "คุณจะหยุดรับจดหมายข่าวรายวันจากเรา",
    labelUnSubscribeButtonTH: "ยกเลิกรับอีเมลข่าวสาร",


    labelUnSubscribeSuccesfullTH: "ยกเลิกรับข่าวสารสำเร็จแล้ว",
    labelYouWillTH: "คุณจะไม่ได้รับจดหมายข่าวรายวันจากเราอีกต่อไป",
    labelCloseWindowTH: "คุณสามารถปิดหน้าต่างนี้ได้เลย",
    labelCloseButtonTH: "ออกจากหน้านี้"
}