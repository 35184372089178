import React, { useState, useEffect,Fragment } from 'react';
import SharePrices from './SharePrices';
import "../../common.css";
import "../../investorAcademy.css";


const SharePrice = (props) => {
    return ( 
        <Fragment> 
            <SharePrices/>
        </Fragment> 
     );
}


export default SharePrice;