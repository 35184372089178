import React from "react";
import { Modal, ModalBody } from "reactstrap";

function SpeakModal({ open, siteLang, open1, value1, value2, value3, msg, resmsg1, seminarReg }) {
  return (
    <>
      {resmsg1 ? (
        <Modal
          style={{ position: "relative" }}
          centered
          isOpen={resmsg1}
          className="modal-lg mt-5 success-speak subscribe activity"
        >
          <ModalBody>
            <h4>You answer was already submitted</h4>
          </ModalBody>
        </Modal>
      ) : msg ? (
        <Modal
          style={{ position: "relative" }}
          centered
          isOpen={msg}
          className="modal-lg mt-5 success-speak subscribe activity"
        >
          <ModalBody>
            <h4>Your answer was successfully submitted.</h4>
          </ModalBody>
        </Modal>
      ) : value3 ? (
        <Modal
          style={{ position: "relative" }}
          centered
          isOpen={value3}
          className="modal-lg mt-5 success-speak subscribe activity"
        >
          <ModalBody>
            <h4>Invalid email</h4>
          </ModalBody>
        </Modal>
      ) : value1 ? (
        <Modal
          style={{ position: "relative" }}
          centered
          isOpen={value1}
          className="modal-lg mt-5 success-speak subscribe activity"
        >
          <ModalBody>
            <h4>กรุณากรอกข้อมูลให้ครบถ้วน</h4>
          </ModalBody>
        </Modal>
      ) : value2 ? (
        <Modal
          style={{ position: "relative" }}
          centered
          isOpen={value2}
          className="modal-lg mt-5 success-speak subscribe activity"
        >
          <ModalBody>
            <h4>เบอร์โทรศัพท์มือถือไม่ถูกต้อง ( 10 หลัก )</h4>
          </ModalBody>
        </Modal>
      ) : open ? (
        <Modal
          style={{ position: "relative" }}
          centered
          isOpen={open}
          className="modal-lg mt-5 success-speak subscribe activity"
        >
          <ModalBody>
            <h4>
              {siteLang == "eng" ? "You are successfully registered as a member. Please check your log in information from your email account." 
              : "คุณได้ทำการสมัครสมาชิกเรียบร้อยแล้ว กรุณาตรวจสอบข้อมูลผู้ใช้และรหัสผ่านได้จากอีเมล์ที่ท่านใช้ในการสมัคร"}
            </h4>
          </ModalBody>
        </Modal>
      ) : (
        <Modal
          style={{ position: "relative" }}
          centered
          isOpen={open1}
          className="modal-lg mt-5 success-speak subscribe activity"
        >
          <ModalBody>
            <h4>
              {seminarReg == "duplicate" ? 'You have already registered this seminar.' : seminarReg == "waiting" ? 'We apologize that the seminar is FULL. We will contact you if there are any further seats available.' : seminarReg == "succeed" ? "Your seminar registration was successful. Thank you" : "Fail to register. Please try again."}
            </h4>
          </ModalBody>
        </Modal>
      )}
    </>
  );
}
export default SpeakModal;
