
import React, { useState, useEffect, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { get } from 'lodash';
import ReactTooltip from "react-tooltip"; 
// import { path } from 'react-financial-charts';


const  ChildInvestorAcademyCommon = ({Location,Hist}) => {

    const pathName = get(Location,"pathname","")
    const [siteLang , setSiteLang] = useState("")

    useEffect(() => {
        var siteLanguage = $.cookie('applang');
        setSiteLang(siteLanguage)
        if(siteLanguage == "eng"){
          document.querySelector("body").classList.add("eng")
        }else{
          document.querySelector("body").classList.remove("eng")
        }
    }, []);

    return ( 
        <Fragment>
        <div className="homebtn dbtn">
            <Link to="/eduhtml"></Link>
        </div>

           {/* Why Trade Warrant ALL Pages */}

        <div className={pathName === "/eduhtml/gearingeffect" || pathName === "/eduhtml/limitedloss"
        || pathName === "/eduhtml/porfitfrombullorbearmarket" || pathName === "/eduhtml/lowercapitaloutlay"
        || pathName === "/eduhtml/releasingsharecapital" || pathName === "/eduhtml/easeoftrade"
        || pathName === "/eduhtml/nomargincall" ? 
        "dotbtn d1 dbtn selected" 
        :
        "dotbtn d1 dbtn"}
            data-tip
            data-for={"why-trade-warrants"}
        >

            <Link to="/eduhtml/whytradewarrants"></Link>
        </div>
        <ReactTooltip
            id={"why-trade-warrants"}
            delayHide={100}
            place="left"
            type="warning"
            effect="solid"
        >
            <div className="bg-tooltip">
                <p className="tooltip-content" style={{marginBottom: "0px"}}>{siteLang == "eng" ? "Why Trade DW?" : "ทำไมต้องลงทุนใน DW?"}</p>
            </div>
        </ReactTooltip>


        {/* What Are Warrant ALL Pages */}


        <div className={pathName === "/eduhtml/callvsput"  || pathName ===  "/eduhtml/howacallwarrantworks"
            || pathName ===  "/eduhtml/howaputwarrantworks"
            ? 
            "dotbtn d2 dbtn selected" 
            :
            "dotbtn d2 dbtn"}
            data-tip
            data-for={"what-are-warrants"} 
        >

            <Link to="/eduhtml/whatarewarrants"></Link>
        </div>
        <ReactTooltip
            id={"what-are-warrants"}
            delayHide={100}
            place="left"
            type="warning"
            effect="solid"
        >
            <div className="bg-tooltip">
                <p className="tooltip-content" style={{marginBottom: "0px"}}>{siteLang == "eng" ? "What are DW?" : "DW คืออะไร?"}</p>
            </div>
        </ReactTooltip>

            {/* How are Warrants priced ALL Pages */}

        <div className={pathName === "/eduhtml/howarewarrantsnamed" ?
            "dotbtn d3 dbtn selected" 
            :
            "dotbtn d3 dbtn"}
            data-tip
            data-for={"How-are-Warrants-priced"}
        >

            
            <Link to="/eduhtml/howarewarrantsnamed"></Link>
        </div>
        <ReactTooltip
            id={"How-are-Warrants-priced"}
            delayHide={100}
            place="left"
            type="warning"
            effect="solid"
        >
            <div className="bg-tooltip">
                <p className="tooltip-content" style={{marginBottom: "0px"}}>{siteLang == "eng" ? "How are DW named?" : "สัญลักษณ์ของ DW เป็นอย่างไร"}</p>
            </div>
        </ReactTooltip>

 {/* Understanding Warrant Structure ALL Pages */}

        <div className={ pathName ==="/eduhtml/shareprice" || pathName ==="/eduhtml/exerciseprice" 
        || pathName ==="/eduhtml/expirydate" || pathName === "/eduhtml/volatility" || pathName === "/eduhtml/interestrates"
        || pathName === "/eduhtml/intrinsicvalueandtimevalue" || pathName === "/eduhtml/timedecay" || pathName === "/eduhtml/moneyness"
        || pathName === "/eduhtml/trackingwarrantspricechangelastprice" || pathName === "/eduhtml/calculatingdwdailychange"    ?
            "dotbtn d4 dbtn selected" 
            :
            "dotbtn d4 dbtn"}
            data-tip
            data-for={"Understanding-the-Warrant-Structure"}
        >
             <Link to="/eduhtml/howarewarrantspriced"></Link>
        </div>
        <ReactTooltip
            id={"Understanding-the-Warrant-Structure"}
            delayHide={100}
            place="left"
            type="warning"
            effect="solid"
        >
            <div className="bg-tooltip">
                <p className="tooltip-content" style={{marginBottom: "0px"}}>{siteLang == "eng" ? "How are DW priced?" : "ราคา DW คำนวณอย่างไร"}</p>
            </div>
        </ReactTooltip>

{/* Monitoring price changes ALL Pages */}

        <div className={pathName ==="/eduhtml/delta"
            || pathName === "/eduhtml/exerciseratio" || pathName ==="/eduhtml/warrantsensitivity" || pathName === "/eduhtml/breakevenprice"
            || pathName === "/eduhtml/premium" || pathName === "/eduhtml/gearing" || pathName === "/eduhtml/effectivegearing" || pathName === "/eduhtml/impliedvolatility"
            ?
            "dotbtn d5 dbtn selected" 
            :
            "dotbtn d5 dbtn"}
            data-tip
            data-for={"Monitoring-price-changes"}
        >
            <Link to="/eduhtml/usefulwarrantterms"></Link>
        </div>
        <ReactTooltip
            id={"Monitoring-price-changes"}
            delayHide={100}
            place="left"
            type="warning"
            effect="solid"
        >
            <div className="bg-tooltip">
                <p className="tooltip-content" style={{marginBottom: "0px"}}>{siteLang == "eng" ? "Useful DW terms" : "ตัวแปรเกี่ยวกับ DW ที่ควรรู้"}</p>
            </div>
        </ReactTooltip>

        {/* Useful Warrant terms ALL Pages */}

        <div className={ pathName ===  "/eduhtml/fourviewonunderlyingshareorindex"
            || pathName === "/eduhtml/fourtimeframe" || pathName === "/eduhtml/fourgearinglevel" || pathName === "/eduhtml/fourimpliedvolatility"
            || pathName === "/eduhtml/qualityofamarketmaker" || pathName === "/eduhtml/riskmatrix" || pathName === "/eduhtml/tipsandtools"
            ? 
            "dotbtn d6 dbtn selected" 
            :
            "dotbtn d6 dbtn"} 
            data-tip
            data-for={"useful-warrant-terms"}
        >
            <Link to="/eduhtml/howtoselectawarrant"></Link>
        </div>
        <ReactTooltip
            id={"useful-warrant-terms"}
            delayHide={100}
            place="left"
            type="warning"
            effect="solid"
        >
            <div className="bg-tooltip">
                <p className="tooltip-content" style={{marginBottom: "0px"}}>{siteLang == "eng" ? "How to select a DW?" : "วิธีการเลือก DW"}</p>
            </div>
        </ReactTooltip>

        {/* How to select a Warrant ALL Pages */}
        <div className={
               pathName === "/eduhtml/warrantsatexpirylasttradingday"

            || pathName ===  "/eduhtml/cashsettlementamountforsinglesharewarrantsatexpiry" 

            || pathName === "/eduhtml/singlesharecallwarrantexample"

            || pathName === "/eduhtml/singleshareputwarrantexample"

            ?
            "dotbtn d7 dbtn selected" 
            :
            "dotbtn d7 dbtn"} 
            data-tip
            data-for={"select-warrant"}
        >
            <Link to="/eduhtml/singlesharecallsandputsatexpiry"></Link>
        </div>
        <ReactTooltip
            id={"select-warrant"}
            delayHide={100}
            place="left"
            type="warning"
            effect="solid"
        >
            <div className="bg-tooltip">
                <p className="tooltip-content" style={{marginBottom: "0px"}}>{siteLang == "eng" ? "DW at expiry" : "DW ณ วันครบกำหนดอายุ"}</p>
            </div>
        </ReactTooltip>

        {/* Single share calls and puts at expiry ALL Pages */}

        <div className={pathName === "/eduhtml/marketrisk" || pathName ==="/eduhtml/riskgearing"
            || pathName ==="/eduhtml/limitedlife" || pathName ==="/eduhtml/creditrisk"
            || pathName ==="/eduhtml/takeovers"
            ?
            "dotbtn d8 dbtn selected" 
            :
            "dotbtn d8 dbtn"} 
            data-tip
            data-for={"single-share-call"}
        >
            <Link to="/eduhtml/risk"></Link>
        </div>
        <ReactTooltip
            id={"single-share-call"}
            delayHide={100}
            place="left"
            type="warning"
            effect="solid"
        >
            <div className="bg-tooltip">
                <p className="tooltip-content" style={{marginBottom: "0px"}}>{siteLang == "eng" ? "Risk" : "ความเสี่ยง"}</p>
            </div>
        </ReactTooltip>

{/* Index calls and puts at expiry ALL Pages */}

        <div className={pathName === "/eduhtml/howtocalculatecashsettlementamount" || pathName === "/eduhtml/liquidityvstradedvolume" 
            || pathName === "/eduhtml/howdomarketmakershedge" 
            ?
            "dotbtn d9 dbtn selected" 
            :
            "dotbtn d9 dbtn"} 
            data-tip
            data-for={"index-calls"}
        >
            <Link to="/eduhtml/marketmakers"></Link>
        </div>
        <ReactTooltip
            id={"index-calls"}
            delayHide={100}
            place="left"
            type="warning"
            effect="solid"
        >
            <div className="bg-tooltip">
                <p className="tooltip-content" style={{marginBottom: "0px"}}>{siteLang == "eng" ? "Market Makers and Liquidity" : "สภาพคล่องและการดูแลสภาพคล่อง"}</p>
            </div>
        </ReactTooltip>

             {/* Risk ALL Pages */}
             
        </Fragment>
     );
}
 
export default ChildInvestorAcademyCommon;