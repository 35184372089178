import React, { useState } from 'react';
import RangeSlider from 'react-bootstrap-range-slider';
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';


 const RangeSliderImpliedVolatilityComponent = (props) => {

  const {Value, SetValue} = props;

  return (
    <RangeSlider 
      step={0.1} 
      tooltip={"off"} 
      variant={"warning"}
      value={Value} max={150} min={0}
      onChange={changeEvent =>{
        SetValue(changeEvent.target.value)
      }}
    />
  );
 

};

export default RangeSliderImpliedVolatilityComponent;