export const languageText ={

    pageTitle: "วิธีการเลือก DW",
    pageTitleEn: "How to select a DW?",

    pageName: "ระยะเวลาลงทุน",
    pageNameEn: "Timeframe",

    pageDescription: "ในการเลือก DW เราแนะนำให้นักลงทุนทำตาม 4 ขั้นตอนนี้",
    pageDescriptionEn: "When choosing a DW, we suggest that investors consider the following 4 steps:",

    imgUrl: "/img/education/img/fourstep2_th.jpg",
    imgUrlEn: "/img/education/img/fourstep2.jpg",
    
    pageDescription1: "ในขั้นตอนที่สอง ให้คิดถึงระยะเวลาที่คุณคาดหวังที่จะถือครอง DW เอาไว้ ซึ่งคุณต้องพิจารณาถึงความสามารถในการยอมรับความเสี่ยงของคุณ ตัวอย่างเช่น ถ้าคุณยอมรับความเสี่ยงได้ต่ำ และต้องการถือครอง DW เป็นเวลาประมาณ 1 เดือน คุณจะต้องเลือก DW ที่มีอายุ 2 เดือนหรือมากกว่า เพื่อให้คุณมีเวลาเผื่อไว้ ถ้าคุณยอมรับความเสี่ยงได้สูง คุณอาจจะเลือก DW ที่มีอายุสั้นกว่า เพื่อให้ได้กำไรจาก leverage ที่สูงกว่า",
    pageDescription1En: "In the second step, think of how long you expect to be holding on to your DW position. You also need to consider your risk tolerance. For example, if you are someone with a low risk tolerance and intend to hold on to your positions for a month, you might select a DW with expiries of 2 months or more to give yourself some buffer. If you are someone with a high risk tolerance, you might select a shorter dated DW to benefit from the increased leverage.",

    imgUrl1: "/img/education/img/timeframe.png",
    imgUrl1En: "/img/education/img/timeframe.png",
    
    pageDescription2: "คำเตือนสำหรับการลงทุนใน DW ที่มีอายุสั้น DW ที่ใกล้จะครบกำหนดอายุ (เช่น เหลือเวลา 1 เดือนหรือน้อยกว่า) มูลค่าจะลดลงในอัตราที่เร็วขึ้น (ข้อมูลเพิ่มเติมเกี่ยวกับ Time Decay",
    pageDescription2En: "A word of caution however on shorter dated DW, a DW that is approaching expiry (say with only 1 month or less to expiry) will decay at a high and increasing rate (for more information on time decay",

    pageDescriptionLink: " คลิกที่นี่ ",
    pageDescriptionLinkEn: " click here ",

    pageDescription3: ") ดังนั้น หากมุมมองของคุณถูกต้อง คุณก็จะมีโอกาสได้รับผลตอบแทนสูงๆจากการลงทุนใน DW เหล่านี้ แต่ในขณะเดียวกัน คุณก็กำลังเผชิญกับความเสี่ยงที่ค่อนข้างสูงจากการที่ราคา DW จะลดลงอย่างรวดเร็วเช่นกัน ด้วยเหตุนี้ คุณจะต้องติดตามการลงทุนอย่างใกล้ชิดเมื่อลงทุนใน DW ที่มีอายุสั้น",
    pageDescription3En: "). So, while you can often earn a higher return on these DW if your view is right, you are also taking on a higher risk as the DW price will quickly decay in value. You must be very nimble when trading short dated DW.",
}