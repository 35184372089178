import React, { useState, useEffect,Fragment } from 'react';
import MarketMakers from './MarketMakers';
import "../common.css";
import "../investorAcademy.css" 


const MarketMaker = (props) => {
    return ( 
        <Fragment> 
        <MarketMakers/>
        </Fragment> 
     );
}


export default MarketMaker;