export const languageText ={

    pageTitle: "DW ณ วันครบกำหนดอายุ",
    pageTitleEn: "DW at expiry",

    pageName: "วันซื้อขายสุดท้าย",
    pageNameEn: "Last trading day",

    pageDescription: 'วันซื้อขายสุดท้ายของ DW คือ 4 วันทำการก่อนวันครบกำหนดอายุ DW จะหยุดทำการซื้อขาย 4 วันจนกระทั่งถึงวันครบกำหนดอายุ ถ้านักลงทุนไม่ได้ขาย DW ออกก่อนวันซื้อขายวันสุดท้าย ผู้ออก DW จะทำการใช้สิทธิโดยอัตโนมัติ หาก DW นั้นมีมูลค่าคงเหลือ ณ วันครบกำหนดอายุ<br><br> คุณสามารถตรวจสอบวันซื้อขายสุดท้ายของ DW ได้จากหน้า <a href="/tools/dwterms">ข้อมูล DW</a>.',
    pageDescriptionEn: 'The <b>last trading day</b> for all DW in Thailand is <b>4 trading days prior</b> to the expiry date. This is the last day that DW will trade on the exchange, it is also the closing price on this day that will determine the value of the DW at expiry. If an investor does not sell their holding prior to this date, the issuer will automatically exercise the DW (if there is any value remaining)<br><br> You can look up a warrant’s last trading day via the warrant terms page, <a href="/tools/dwterms">click here</a>.',

    imgUrl: "/img/education/img/calendar_th.png",
    imgUrlEn: "/img/education/img/calendar.png",
    
}