export const languageText ={
    
    firstNavigation: "รอบรู้เรื่อง DW ",
    firstNavigationEn: "DW education ",
    secondNavigation: " DW Tips ",
    secondNavigationEn: " DW Tips ",

    pageTitle: "DW Tips",
    pageTitleEn: "DW Tips",

    allIndexTips: "Tips เทรด DW บนหุ้นและดัชนีต่างประเทศทั้งหมด",
    allIndexTipsEn: "See all foreign stock and index DW tips",
    allSetTips: "Tips เทรด DW บนหุ้นไทยและดัชนี SET50 ทั้งหมด",
    allSetTipsEn: "See all Thai single stock DW and SET50 DW tips",
    allTopicTips: "วิเคราะห์หุ้นร้อนประเด็นแรง",
    allTopicTipsEn: "Hot topic Hot issue review",

    allTipsClick: "คลิก",
    allTipsClickEn: "Click",

    marketHeading: "อัพเดทข่าวสาร DW",
    marketHeadingEn: "Market news",

    marketDailysinglestock: "อัพเดทข่าวสารหุ้นรายตัว",
    marketDailysinglestockEn: "Single stock update",
    marketDailysp500: "อัพเดทตลาด S&P500",
    marketDailysp500En: "S&P 500 index market news",
    marketDailyhsi: "วิเคราะห์ตลาดฮั่งเส็ง",
    marketDailyhsiEn: "HSI index market news",
    marketTodayhighlight: "ทิศทางตลาดต่างประเทศ",
    marketTodayhighlightEn: "Foreign index Update",


    // Dw Tipssup Page Content
    tipssupAllIndexTips: " Tips เทรด DW บนหุ้นและดัชนีต่างประเทศ",
    tipssupAllIndexTipsEn: " Foreign stock and index DW tips",
    tipssupAllSetTips: " Tips เทรด DW บนหุ้นไทยและดัชนี SET50",
    tipssupAllSetTipsEn: " Thai single stock DW and SET50 DW tips",
    tipssupAllTopicTips: " วิเคราะห์หุ้นร้อนประเด็นแรง",
    tipssupAllTopicTipsEn: " Hot topic Hot issue review",

    marketDelay: "ข้อมูลตลาดทั้งหมดล่าช้าประมาณ 15 นาที",
    marketDelayEn: "All market data is delayed 15 mins.",
}