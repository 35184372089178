export const languageText ={

    pageTitle: "ราคา DW คำนวณอย่างไร",
    pageTitleEn: "How are DW priced?",

    pageName: "ความผันผวน",
    pageNameEn: "Volatility",

    pageDescription: "ความผันผวนสามารถกำหนดให้เป็น “ตัววัดความไม่แน่นอนเกี่ยวกับผลตอบแทนในอนาคตที่อ้างอิงจากหุ้น” ความไม่แน่นอนของผลตอบแทนจากหุ้นเพิ่มขึ้นเท่าไร ก็จะเพิ่มความผันผวนของราคาหุ้นอ้างอิงมากขึ้นเท่านั้น ความผันผวนของราคาหุ้นอ้างอิงที่สูงขึ้น ก็จะเป็นความเสี่ยงที่เพิ่มขึ้น และราคาที่สูงขึ้นของ DW ทั้ง Call และ Put ความผันผวนแฝงอาจมีสาเหตุมาจากอุปสงค์และอุปทาน (Demand-Supply) ของ DW ได้ อุปสงค์ที่สูงขึ้นจะทำให้ค่าความผันผวนของ DW สูงขึ้นและทำให้ราคา DW สูงขึ้นตามไปด้วย",
    pageDescriptionEn: "Volatility can be defined as “a measure of uncertainty about the future returns provided by a stock”. The more uncertain the returns of a stock, the more volatile the share price will be. The more volatile the share price, the higher the risk and the higher the price of the DW, for both calls and puts. Implied volatility can be affected by the demand and supply for the DW. A high demand can lead to higher implied volatility levels of the DW and hence a higher price and vice versa.",

    imgUrl: "/img/education/img/vo.png",
    imgUrlEn: "/img/education/img/vo.png",

    pageDescription1: "รายละเอียดเพิ่มเติมเกี่ยวกับความผันผวนแฝง,",
    pageDescription1En: "For a more detailed description of implied volatility,",

    pageDescriptionLink: " คลิกที่นี่ ",
    pageDescriptionLinkEn: " click here ",
}