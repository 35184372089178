export const languageText ={

    pageTitle: "ตัวแปรเกี่ยวกับ DW ที่ควรรู้",
    pageTitleEn: "Useful DW terms",

    pageName: "ความผันผวนแฝง (Implied Volatility)",
    pageNameEn: "Implied Volatility",

    pageDescription: "เป็นตัวแปรหลักในการคำนวณราคา DW ซึ่งเป็นระดับของความผันผวนที่ได้มาจากราคาตลาดของ DW ในขณะนั้นๆ และแสดงออกมาเป็นเปอร์เซ็นต์",
    pageDescriptionEn: "Implied volatility is a key factor affecting a DW's pricing. It is the level of volatility that is implied by the current market price of the DW, and represented as a percentage.",

    pageDescription1: "Implied Volatility ใช้ในการเปรียบเทียบราคา DW ที่มีเงื่อนไขคล้ายกัน หาก DW มีค่า Implied volatility สูง แสดงว่า DW นั้นยิ่งแพง (กำหนดให้ปัจจัยอื่นๆคงที่)",
    pageDescription1En: 'Implied volatility can be used to compare the relative price of similar DW. The higher the level, the more expensive the DW (all other pricing factors held constant).',

    pageDescription2: "Implied Volatility เปลี่ยนแปลงได้จาก:",
    pageDescription2En: 'Implied volatility is influenced by:',

    listHolder1: "อุปสงค์และอุปทาน (Demand-Supply) ของ DW",
    listHolder1En: 'Demand and supply forces',
    listHolder2: "ความผันผวนของราคาหุ้นอ้างอิง",
    listHolder2En: 'The level of volatility in market prices generally',
    listHolder3: "ตลาด Exchange Trade Option (ETO) และตราสารอนุพันธ์นอกตลาด (OTC)",
    listHolder3En: 'Exchange traded options (ETO) and over the counter (OTC) markets',

    imgUrl: "/img/education/img/impliedvolatility.png",
    imgUrlEn: "/img/education/img/impliedvolatility.png",

    pageDescription3: "สมมติว่าการเคลื่อนไหวของราคา DW A ในช่วงเวลาทำการซื้อขาย เป็นดังนี้",
    pageDescription3En: 'Supposed you observe the price movement of Call DW (A) on the market to be as such:',

    imgUrl1: "/img/education/img/table_iv1_th.png",
    imgUrl1En: "/img/education/img/table_iv1.png",

    pageDescription4: "DW ที่เคลื่อนไหวแบบช่วงต่อช่วง (tick for tick) ตามราคาหุ้นอ้างอิง โดยราคาจะเพิ่มขึ้น 1 ช่วงราคาขั้นต่ำที่ 0.01 บาท พร้อมๆกับราคาของหุ้นอ้างอิงที่เพิ่มขึ้น 1 ช่วงราคา (0.25 บาท) สังเกตว่า ราคา DW เท่ากับ 1.23 บาท ที่ราคาหุ้นอ้างอิงเท่ากับ 32.25 บาท",
    pageDescription4En: 'The DW is moving tick-for-tick with the underlying share price, moving up one ‘tick’ (THB 0.01) for every one tick (THB 0.25) in the underlying share. You noted that the price of the DW was THB 1.23 when the underlying was trading at THB 32.25',

    pageDescription5: "Lในเวลาต่อมา..",
    pageDescription5En: 'Later in the day however you noticed the below price movements...',

    imgUrl2: "/img/education/img/table_iv2_th.png",
    imgUrl2En: "/img/education/img/table_iv2.png",

    pageDescription6: "สังเกตว่า ราคา DW เพิ่มขึ้นเป็น1.24 บาท ทั้งที่ราคาหุ้นอ้างอิงยังอยู่ที่ 32.25 บาท นี่ก็เป็นเพราะว่า Implied Volatility เพิ่มขึ้นจาก 70% เป็น 71% นั่นเอง",
    pageDescription6En: 'You notice that the price of the DW had increased to THB 1.26 even though the underlying share had remained at THB 32.75, this was attributed to the rise in implied volatility from 70% to 71%',

    pageDescription7: "ปัจจัยที่ทำให้ Implied Volatility เพิ่มสูงขึ้น",
    pageDescription7En: 'What may have caused the implied volatility to increase?',

    list1Holder1: "การเพิ่มอุปสงค์ (Demand) / ปริมาณการซื้อ ของ DW",
    list1Holder1En: 'An increase in demand/buy flows for the DW, or',
    list1Holder2: "เหตุการณ์ที่ให้เกิดความไม่แน่นอนในตลาด การหลีกเลี่ยงความเสี่ยง (Risk Aversion) หรือมีการคาดการณ์ว่าความผันผวนในตลาดจะสุงขึ้นในอนาคต",
    list1Holder2En: 'Market related event that has caused an increase in uncertainly, risk aversion and hence a forecast higher level of volatility in the market, or',
    list1Holder3: "การเพิ่มค่าความผันผวนของออปชั่นที่มีลักษณะเดียวกัน ในตลาด ETO หรือ OTC",
    list1Holder3En: 'An increase in the implied volatility level of a similar option in the ETO or OTC market.',

    imgUrl3: "/img/education/img/table_iv2_th.png",
    imgUrl3En: "/img/education/img/table_iv3.png",

    pageDescription8: "แม้ว่า Implied Volatility จะสูงถึง 71% คุณจะสังเกตเห็นว่า DW ยังคงเคลื่อนไหวแบบช่วงต่อช่วง เมื่อหุ้นอ้างอิงเปลี่ยนแปลงไปตลอดวัน แม้ว่าจะมีระดับ Implied Volatility ที่สูงกว่า โดยราคา DW เปลี่ยนแปลงเป็น 1.28 บาท ในขณะที่ราคาหุ้นอ้างอิงปรับตัวขึ้นเป็น 33.25 บาท",
    pageDescription8En: 'Following this, with the DW now trading at a higher implied volatility of 71%, you notice that the DW continues to move tick-for-tick with the underlying throughout the day at this higher level, moving to THB 1.28 as the stock ticked up to THB 33.25.',

    pageDescription9: "ต่อมาคุณอาจจะเห็นว่า ราคา DW ปรับตัวลดลงเป็น 1.27 บาท แม้ว่าหุ้นอ้างอิงจะยังคงอยุ่ที่ 33.25 บาท เหมือนเดิม และ Implied Volatility อยู่ในระดับเท่ากับการซื้อขาย DW ในช่วงเช้า",
    pageDescription9En: 'Shortly after however, you notice that the DW dropped back down to THB 1.27 even though the stock has stayed put at THB 33.25',

    pageDescription10: "ปัจจัยที่ทำให้ Implied Volatility ลดลง",
    pageDescription10En: 'What may have caused the implied volatility to decrease?',

    list2Holder1: "การเพิ่มอุปทาน (Supply) / ปริมาณการขาย ของ DW",
    list2Holder1En: 'A decrease in demand/buy flows for the DW, or',
    list2Holder2: "เหตุการณ์ในตลาดก่อให้เกิดความแน่นอนมากขึ้น ความสามารถในการรับความเสี่ยงเพิ่มขึ้น และ การคาดการณ์ว่าความผันผวนของตลาดจะลดลงในอนาคต",
    list2Holder2En: 'A market related event that has caused a decrease in uncertainly, risk aversion and hence a forecast higher level of volatility in the market, or',
    list2Holder3: "การลดระดับความผันผวนในออปชั่นที่คล้ายกันในตลาด ETO หรือ OTC",
    list2Holder3En: 'A decrease in the implied volatility level of a similar option in the ETO or OTC market.',

    tipHolderHeading: "เคล็ดลับ",
    tipHolderHeadingEn: "Helpful Tips",
    tipHolderParagraph1: "นักลงทุนสามารถหาค่า Implied Volatility ของ DW ทั้งหมด ได้จากหน้า",
    tipHolderParagraph1En: "You can view the implied volatility for all DW via the",
    tipHolderLink: " ค้นหา DW ",
    tipHolderLinkEn: " DW Search tool ",
    tipHolderParagraph12: " และ",
    tipHolderParagraph12En: " and the",
    tipHolderLink2: " ข้อมูล DW ",
    tipHolderLink2En: " DW terms ",
    tipHolderParagraph2: "นักลงทุนสามารถติดตามปริมาณการซื้อขายของ DW",
    tipHolderParagraph2En: "Investors can monitor the buy/sell flows of a DW at the following",
    tipHolderLink3: " ได้ที่นี่",
    tipHolderLink3En: " link",

}