import React, { useEffect, useState} from 'react';
import {Carousel} from 'react-responsive-carousel';
import { config } from "../../../utils/apiUrl";
import API from "../../../utils/apiCalling";
import { get } from "lodash";


const DailyMarketCarousel = ({setDmaData}) => {
    
    const api = new API();
    const [marketAnalysis, setMarketAnalysis] = useState([]);
    const [siteLang , setSiteLang] = useState("")
    const itemsPerRow = 3; // Number of items per row
    const itemsPerTable = 3; // Number of items per table
    const tableRows = [];
    const [dmaPopUp, setDmaPopUp] = useState(false);
    const [popupVideoUrl, setPopupVideoUrl] = useState("");

    useEffect(() => {
      window.scrollTo(0, 0);
      var siteLanguage = $.cookie('applang');
      setSiteLang(siteLanguage)
      if(siteLanguage === "eng"){
        document.querySelector("body").classList.add("eng")
      }else{
        document.querySelector("body").classList.remove("eng")
      }
      DailyMarketAnalysisJSON();
    }, []);

    const DailyMarketAnalysisJSON = async () => {
      var items = [];
      let data = await api.get(config.dailyMarketAnalysisJSON, true, {
        qid: Date.now(),
      });
      let allData = get(data, "dma_list", []);
      if(allData?.length > 0) {
        for (let i = 0; i < allData.length; i++) {
          let advImgData = await api.get(config.advImgData, true, {
            dmaid: allData[i].id,
          });
          items.push({
            imgSrc: advImgData,
            id: allData[i].id,
            en_caption: allData[i].en_caption,
            en_title: allData[i].en_title,
            image: allData[i].image,
            link: allData[i].link,
            thai_caption: allData[i].thai_caption,
            thai_title: allData[i].thai_title,
          });
        }
      }
      setMarketAnalysis(items)
      setDmaData(items)
    }

    const handleVideoItemClick = (data) => {
      setPopupVideoUrl(data)
      setDmaPopUp(true)
      document.querySelector("body").style.overflow = "hidden"
    }

    const closePopUp = () => {
      setDmaPopUp(false)
      setPopupVideoUrl("")
      document.querySelector("body").style.overflow = "auto"
    }
     
    for (let i = 0; i < marketAnalysis.length; i += itemsPerTable) {
        const tableItems = [];
        for (let j = i; j < i + itemsPerTable && j < marketAnalysis.length; j += itemsPerRow) {
          const rowItems = [];
          for (let k = j; k < j + itemsPerRow && k < marketAnalysis.length; k++) {
            const speaker = marketAnalysis[k];
            rowItems.push(
              <td key={speaker.en_title}>
                <div className="videorack-item" link={speaker.link} onClick={() => handleVideoItemClick(speaker.link)}>
                  {speaker.image == "true" ? <img style={{ width: "100%" }} src={speaker.imgSrc} /> : <img style={{ width: "100%" }} src="" />}
                  <div className="videorack-item-txt">
                    {siteLang == "eng" ? speaker.en_title: speaker.thai_title}
                  </div>
                </div>
              </td>
            );
          }
          while (rowItems.length < itemsPerRow) {
            rowItems.push(
              <td>
                <div className="videorack-item">
                  <img style={{ width: "100%" }} src="" />
                  <div className="videorack-item-txt"></div>
                </div>
              </td>
            );
          }
          tableItems.push(
            <tr key={j}>
                {rowItems}
            </tr>
          );
        }
        tableRows.push(
            <div key={i} className="slide-tab">
              <table className={`videorack-page ${i > 0 ? 'slide-tab' : ''}`}>
                <tbody>
                  {tableItems}
                </tbody>
              </table>
            </div>
        );
    }

    return (
      <>
        <Carousel
        showArrows={true}
        emulateTouch
        showStatus={false}
        autoFocus
        infiniteLoop={false}
        showIndicators={false}
        showThumbs={false}
        >
            {tableRows}
        </Carousel>
        {dmaPopUp && <div id="ppboxHTML2" className="overlay">
          <div className="overlay-inner">
            <div style={{ position: "relative" }}>
              <img onClick={closePopUp} className="btn-close" src="/img/bullet/icon-close-white.png" />
              <div className="popupbox-content">
                <div className="ppc" key="video">
                  <div className="hotline-layer">
                    <iframe frameBorder={0} allowFullScreen src={popupVideoUrl} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>}
      </>
    );
};

export default DailyMarketCarousel;