import React, { useState, useEffect } from "react";
import "./activitie.css";
import { Link } from "react-router-dom";
import API from "../../../utils/apiCalling";
import { config } from "../../../utils/apiUrl";
import { languageText } from "../../../languageText/activitie"

const Activities = (props) => {

  const api = new API();
  const [activityData, setActivityData] = useState([]);
  const [firstItem, setFirstItem] = useState([]);
  const [firstRemoveData, setFirstRemoveData] = useState([]);
  const [siteLang , setSiteLang] = useState("")

  useEffect(() => {
    window.scrollTo(0, 0);
    var siteLanguage = $.cookie('applang');
    setSiteLang(siteLanguage)
    if(siteLanguage == "eng"){
      document.querySelector("body").classList.add("eng")
    }else{
      document.querySelector("body").classList.remove("eng")
    }
    getactivityData();
  }, []);

  const getactivityData = async () => {
    let activityData = await api.get(config.actvitie, true, {
      qid: Date.now(),
    });
    setActivityData(activityData?.list);
    let items = [];
    let itemsData = [];
    if(activityData?.list?.length > 0){
      let advImgData = await api.get(config.advImgData, true, {
        activities: activityData?.list[0].id,
      });
      items.push({
        id: activityData?.list[0].id,
        date: activityData?.list[0].date,
        en_title: activityData?.list[0].en_title,
        thai_title: activityData?.list[0].thai_title,
        imgLink: advImgData,
      });
    }
    setFirstItem(items)
    if(activityData?.list?.length > 1){
      for(let i = 1; i < activityData?.list?.length; i++){
        let advImgData1 = await api.get(config.advImgData, true, {
          activities: activityData?.list[i].id,
        });
        itemsData.push({
          id: activityData?.list[i].id,
          date: activityData?.list[i].date,
          en_title: activityData?.list[i].en_title,
          thai_title: activityData?.list[i].thai_title,
          imgLink: advImgData1,
        });
      }
    }
    setFirstRemoveData(itemsData)
  };

  const openPage = (linkData) => {
    window.open(linkData)
  }

  return (
    <div id="activities" className="pageobj">
      <div className="breadcrumbbar">
        <div className="container-fluid">
          <ol className="breadcrumb">
            <li>
              <Link to="/home?dwnews">{siteLang == "eng" ? languageText.firstNavigationEn : languageText.firstNavigation}</Link>
            </li>
            <li className="active">
              {siteLang == "eng" ? languageText.secondNavigationEn : languageText.secondNavigation}
            </li>
          </ol>
        </div>
      </div>
      <div className="stripe-bg" />
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div className="section">
              <div className="page-header">
                <h1>
                  {siteLang == "eng" ? languageText.pageTitleEn : languageText.pageTitle}
                </h1>
              </div>
              <p>{siteLang == "eng" ? languageText.pageDescriptionEn : languageText.pageDescription}</p>
              {activityData?.length <= 0 && <><div className="photo-main">
                  <img src="/img/game/first2.png" alt="Activity" />
                </div>
                <div className="photo-tip">
                  <h2>{siteLang == "eng" ? languageText.hsiFightEn : languageText.hsiFight}</h2>
                  <Link
                    target="_blank"
                    to="/activities/HSIFlight"
                  >
                    <div className="view_btn">
                      {siteLang == "eng" ? languageText.actViewMoreBtnEn : languageText.actViewMoreBtn}
                    </div>
                  </Link>
                </div>
              </>}
              {activityData?.length > 0 && <><div className="photo-main">
                <img src={firstItem && firstItem[0]?.imgLink} alt="Activity" />
              </div>
              <div className="photo-tip">
                <h2>{siteLang == "eng" ? firstItem && firstItem[0]?.en_title : firstItem && firstItem[0]?.thai_title}</h2>
                <Link
                  target="_blank"
                  to={`/marketnews/activitiesdata?id=${firstItem && firstItem[0]?.id}`}
                >
                  <div className="view_btn">
                    {siteLang == "eng" ? languageText.actViewMoreBtnEn : languageText.actViewMoreBtn}
                  </div>
                </Link>
              </div></>}
            </div>

            <div className="section">
              <div className="page-header">
                <h3>More Activities</h3>
              </div>
              <div className="row a_list">
                {firstRemoveData?.length > 0 && firstRemoveData && firstRemoveData.map((data, index) => 
                  <div className="col-sm-4" key={index}>
                    <div className="photo-sub">
                      <img src={data.imgLink == "" ? "image" : data.imgLink} className="desktop" />
                      <img onClick={() => openPage(`/marketnews/activitiesdata?id=${data.id}`)} src={data.imgLink == "" ? "image" : data.imgLink} className="mobile" />
                    </div>
                    <div className="photo-tip">
                      <div className="tip-text">
                        {siteLang == "eng" ? data.en_title : data.thai_title}
                      </div>
                      <Link target="_blank" to={`/marketnews/activitiesdata?id=${data.id}`}>
                        <div className="view_btn">
                          {siteLang == "eng" ? languageText.actViewMoreBtnEn : languageText.actViewMoreBtn}
                        </div>
                      </Link>
                    </div>
                  </div>
                )}
                {activityData?.length > 0 && <div className="col-sm-4 hsiflight">
                  <div className="photo-sub">
                    <img src="/img/game/first2.png" className="desktop" />
                    <img onClick={() => openPage(`/activities/HSIFlight`)} src="/img/game/first2.png" className="mobile" />
                  </div>
                  <div className="photo-tip">
                    <div className="tip-text">
                      {siteLang == "eng" ? languageText.hsiFightEn : languageText.hsiFight}
                    </div>
                    <Link target="_blank" to="/activities/HSIFlight">
                      <div className="view_btn">
                        {siteLang == "eng" ? languageText.actViewMoreBtnEn : languageText.actViewMoreBtn}
                      </div>
                    </Link>
                  </div>
                </div>}
                <div className="col-sm-4">
                  <div className="photo-sub">
                    <img src="/img/game/first.png" className="desktop" />
                    <img onClick={() => openPage(`/activities/HSIDIMSUM`)} src="/img/game/first.png" className="mobile" />
                  </div>
                  <div className="photo-tip">
                    <div className="tip-text">
                      {siteLang == "eng" ? languageText.hsiDimSumEn : languageText.hsiDimSum}
                    </div>
                    <Link target="_blank" to="/activities/HSIDIMSUM">
                      <div className="view_btn">
                        {siteLang == "eng" ? languageText.actViewMoreBtnEn : languageText.actViewMoreBtn}
                      </div>
                    </Link>
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="photo-sub">
                    <img src="/img/game/BrokerIncentive_Web.jpg" className="desktop" />
                    <img onClick={() => openPage(`/activities/dw28freevoucher`)} src="/img/game/BrokerIncentive_Web.jpg" className="mobile" />
                  </div>
                  <div className="photo-tip">
                    <div className="tip-text">
                      {siteLang == "eng" ? languageText.freeVoucherEn : languageText.freeVoucher}
                    </div>
                    <Link target="_blank" to="/activities/dw28freevoucher">
                      <div className="view_btn">
                        {siteLang == "eng" ? languageText.actViewMoreBtnEn : languageText.actViewMoreBtn}
                      </div>
                    </Link>
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="photo-sub">
                    <img src="/img/game/Website_Activity_SuperTrader4_Head_L1.jpg" className="desktop" />
                    <img onClick={() => openPage(`/supertrader/season4`)} src="/img/game/Website_Activity_SuperTrader4_Head_L1.jpg" className="mobile" />
                  </div>
                  <div className="photo-tip">
                    <div className="tip-text">
                      {siteLang == "eng" ? languageText.tradeSeason4En : languageText.tradeSeason4}
                    </div>
                    <Link target="_blank" to="/supertrader/season4">
                      <div className="view_btn">
                        {siteLang == "eng" ? languageText.actViewMoreBtnEn : languageText.actViewMoreBtn}
                      </div>
                    </Link>
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="photo-sub">
                    <img src="/img/game/Website_Activity_SET50Seminar_Head_L2.jpg" className="desktop" />
                    <img onClick={() => openPage(`/education/set50seminar`)} src="/img/game/Website_Activity_SET50Seminar_Head_L2.jpg" className="mobile" />
                  </div>
                  <div className="photo-tip">
                    <div className="tip-text">
                      {siteLang == "eng" ? languageText.set50TradeEn : languageText.set50Trade}
                    </div>
                    <Link target="_blank" to="/education/set50seminar">
                      <div className="view_btn">
                        {siteLang == "eng" ? languageText.actViewMoreBtnEn : languageText.actViewMoreBtn}
                      </div>
                    </Link>
                  </div>
                </div>
                <div className="col-sm-4">
                  <div className="photo-sub">
                    <img src="/img/game/superTrader3.jpg" className="desktop" />
                    <img onClick={() => openPage(`/supertrader/season3`)} src="/img/game/superTrader3.jpg" className="mobile" />
                  </div>
                  <div className="photo-tip">
                    <div className="tip-text">
                      {siteLang == "eng" ? languageText.tradeSeason3En : languageText.tradeSeason3}
                    </div>
                    <Link target="_blank" to="/supertrader/season3">
                      <div className="view_btn">
                        {siteLang == "eng" ? languageText.actViewMoreBtnEn : languageText.actViewMoreBtn}
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
              <p style={{ marginTop: "30px" }} className="small">
                {siteLang == "eng" ? languageText.marketDelayEn : languageText.marketDelay}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Activities;
