import React, { Fragment } from 'react';
import WhyMacquaries from './WhyMacquaries';
import "./whyMacquarie.css" 


const WhyMacquarie = (props) => {
    return ( 
        <Fragment> 
        <WhyMacquaries/>
        </Fragment> 
     );
}


export default WhyMacquarie;