export const languageText ={

    pageTitle: "ทำไมต้องลงทุนใน DW?",
    pageTitleEn: "Why Trade DW?",

    pageName: "จำกัดขาดทุน",
    pageNameEn: "Limited loss",

    pageDescription: "แม้ว่า Gearing จะเป็นประโยชน์หลักของ DW แต่ Gearing ก็เป็นดาบสองคม ซึ่งหมายถึงนักลงทุนมีโอกาสขาดทุนในอัตราที่สูงกว่าหากมุมมองการลงทุนนั้นผิด อย่างไรก็ตาม ข้อแตกต่างของ DW กับสินค้าอื่นๆที่ให้ leverage เหมือนกันก็คือ ความเสี่ยงของคุณจะถูกจำกัดอยู่ที่เงินลงทุนเริ่มต้น ซึ่งหมายถึงคุณสามารถเพิ่มโอกาสในการรับผลตอบแทนที่สูงขึ้นพร้อมๆกับการจำกัดขาดทุน",
    pageDescriptionEn: "Gearing is one of the biggest benefits of DW but gearing is a double-edged sword, as it also exposes investors to greater potential losses in percentage terms if their view is wrong. However, the difference between DW and many other forms of leverage is that your risk is limited to the initial payment, meaning you can increase your exposure while limiting your capital at risk",

    pageDescription1: "จากตัวอย่างสมมติ คุณสามารถทำกำไรได้จากการลงทุนในหุ้นอ้างอิงที่ 250 บาท โดยใช้เงินเพียง 50 บาท ในการซื้อ DW ซึ่งเป็นการจำกัดความเสี่ยงในการขาดทุนสูงสุดที่ 50 บาท ในกรณีเดียวกัน ถ้าคุณซื้อขายหุ้นโดยตรงผ่านบัญชีมาร์จิ้น ความเสี่ยงของคุณจะเท่ากับ 250 บาท",
    pageDescription1En: "Look at our hypothetical example here. You can achieve an increased effective exposure of THB 250 by buying THB 50 worth of DW, which limits your total capital at risk to THB 50. Were you to achieve the same exposure by buying the share directly or through a margin loan, you would be exposed to the full THB 250.",

    imgUrl: "/img/education/img/chart_limitedloss_th.png",
    imgUrlEn: "/img/education/img/chart_limitedloss.png",
}