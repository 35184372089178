export const languageText ={
    
    firstNavigation: "สถิติ DW ",
    firstNavigationEn: "DW statistics ",
    secondNavigation: " Top traded stock",
    secondNavigationEn: " Top traded stock",

    pageTitle: "Top traded stock",
    pageTitleEn: "Top traded stock",
    pageDescription: "ตารางจัดอันดับหลักทรัพย์อ้างอิงของ DW ที่มีมูลค่าซื้อขายสูงสุดในตลาด และ DW28 บนหลักทรัพย์อ้างอิงดังกล่าวที่มีสภาพคล่องสูงสุด",
    pageDescriptionEn: "Most actively traded underlying shares by value and corresponding DW28 with highest liquidity.",

    callTab: "DW ที่เกี่ยวข้อง",
    callTabEn: "Call DW",
    putTab: "Put DW",
    putTabEn: "Put DW",

    aboveTable: "ตารางที่แสดงข้างต้นเป็นการจัดเรียงอันดับมูลค่าซื้อขายสูงสุดในตลาดของวัน ของหลักทรัพย์อ้างอิง DW ที่แมคควอรีมีการออก DW บนหลักทรัพย์ดังกล่าว",
    aboveTableEn: "The above table is the ranking of the day’s most actively traded shares (by value) over which Macquarie issues DW",
    marketDelay: "ข้อมูลตลาดทั้งหมดล่าช้าประมาณ 15 นาที",
    marketDelayEn: "All market data is delayed 15 mins.",
}