import React, { useEffect, useState } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import InvestorAcademyCommon from "../../../../components/common/InvestorAcademyCommon";
import "../common.css";
import "../investorAcademy.css";
import { languageText } from "../../../../languageText/InvestorAcademy/SingleShareAndExpiry/index"
import { languageTextMain } from "../../../../languageText/InvestorAcademy/index"

const SingleShareExpiries = (props) => {
  
  const location = useLocation();
  const history = useHistory();
  const [siteLang , setSiteLang] = useState("")

  // Site Language Change Start //
  useEffect(() => {
    window.scrollTo(0, 0);
    var siteLanguage = $.cookie('applang');
    setSiteLang(siteLanguage)
    if(siteLanguage == "eng"){
      document.querySelector("body").classList.add("eng")
    }else{
      document.querySelector("body").classList.remove("eng")
    }
  }, []);
  // Site Language Change End //

  return (
    <div id="investor_academy">
      <div className="imgbg" />
      <div className="mainbg" />
      <div id="main">
        <div id="maintable">
          <div style={{ display: "table-row" }}>
            <div id="leftbar" className="hidden-xs" />
            <div id="maincontainer">
              {/* Top Title Section Start */}
              <div id="mainnav">
                <table>
                  <tbody>
                    <tr>
                      <td width="20%" style={{textAlign:"left", visibility: "hidden"}} />
                      <td width="60%" style={{ textAlign: "center" }}>
                        <div id="pagetitlediv">
                          <div id="ptitle">
                            <img height="56px" src="/img/education/img/07.png" alt="Investor" />
                          </div>
                          <div id="pname">
                            {siteLang == "eng" ? languageText.pageTitleEn : languageText.pageTitle}
                          </div>
                        </div>
                      </td>
                      <td width="20%" style={{textAlign:"right", visibility: "hidden"}} />
                    </tr>
                  </tbody>
                </table>
              </div>
              {/* Top Title Section End */}
              {/* Main Content Section Start */}
              <div id="maincontent">
                <div id="mainscroll">
                  <div id="mainscrollcontent">
                    <style
                      dangerouslySetInnerHTML={{__html:" \n .hexrow.odd{ \n margin-left: 73px; \n } \n ",}}
                    />
                    <div className="contentbox">
                      <p className="showtile">
                        {siteLang == "eng" ? languageText.pageDescriptionEn : languageText.pageDescription}
                      </p>
                      {/* Desktop Hexa Link Start */}
                      <div className="hexmenu hidden-xxs hidden-xs">
                        <div className="imgmask">
                          <img className="hexbg" src="/img/topic/single_share_calls_n_puts_at_expiry.png" />
                          {siteLang == "eng" ? <div className="hexarea" style={{top: '15%', left: '8%', right: '72%', bottom: '60%'}}>
                            <div className="topictxt">
                              <Link to="/eduhtml/warrantsatexpirylasttradingday">
                                {languageText.lastTradingDateEn}
                              </Link>
                            </div>
                          </div> 
                          : 
                          <div className="hexarea" style={{top: '15%', left: '-5%', right: '60%', bottom: '60%'}}>
                            <div className="topictxt">
                              <Link to="/eduhtml/warrantsatexpirylasttradingday">
                                {languageText.lastTradingDate}
                              </Link>
                            </div>
                          </div>
                          }
                          {siteLang == "eng" ? <div className="hexarea" style={{top: '59%', left: '10%', right: '42%', bottom: '20%'}}>
                            <div className="topictxt">
                              <Link to="/eduhtml/cashsettlementamountforsinglesharewarrantsatexpiry">
                                {languageText.settlementValueEn}
                                <br />
                                {languageText.settlementValue1En}
                              </Link>
                            </div>
                          </div>
                          :
                          <div className="hexarea" style={{top: '59%', left: '20%', right: '52%', bottom: '20%'}}>
                            <div className="topictxt">
                              <Link to="/eduhtml/cashsettlementamountforsinglesharewarrantsatexpiry">
                                {languageText.settlementValue}
                                <br />
                                {languageText.settlementValue1}
                              </Link>
                            </div>
                          </div>
                          }
                          {siteLang == "eng" ? <div className="hexarea" style={{top: '65%', left: '45%', right: '10%', bottom: '40%'}}>
                            <div className="topictxt">
                              <Link to="/eduhtml/singlesharecallwarrantexample">
                                {languageText.callExampleEn}
                              </Link>
                            </div>
                          </div>
                          :
                          <div className="hexarea" style={{top: '60%', left: '58%', right: '27%', bottom: '40%'}}>
                            <div className="topictxt">
                              <Link to="/eduhtml/singlesharecallwarrantexample">
                                {languageText.callExample}
                              </Link>
                            </div>
                          </div>
                          }
                          {siteLang == "eng" ? <div className="hexarea" style={{top: '25%', left: '72%', right: '5%', bottom: '69%'}}>
                            <div className="topictxt">
                              <Link to="/eduhtml/singleshareputwarrantexample">
                                {languageText.putExampleEn}
                              </Link>
                            </div>
                          </div>
                          :
                          <div className="hexarea" style={{top: '20%', left: '75%', right: '10%', bottom: '69%'}}>
                            <div className="topictxt">
                              <Link to="/eduhtml/singleshareputwarrantexample">
                                {languageText.putExample}
                              </Link>
                            </div>
                          </div>
                          }
                        </div>
                      </div>
                      {/* Desktop Hexa Link End */}
                      {/* Mobile Hexa Link Start */}
                      <div className="mobilemenu visible-xxs visible-xs">
                        <div className="menuitem">
                          <Link to="/eduhtml/warrantsatexpirylasttradingday">
                            <img className="menuarrow" src="/img/mobilemenu/arrow.png" />
                            <img className="menuicon" src="/img/mobilemenu/warrants_at_expiry.png" />
                            <div className="menutitle">
                              {siteLang == "eng" ? languageText.lastTradingDateEn : languageText.lastTradingDate}
                            </div>
                          </Link>
                        </div>
                        <div className="menuitem">
                          <Link to="/eduhtml/cashsettlementamountforsinglesharewarrantsatexpiry">
                            <img className="menuarrow" src="/img/mobilemenu/arrow.png" />
                            <img className="menuicon" src="/img/mobilemenu/formula_to_alculate_value_of_call.png" />
                            <div className="menutitle">
                              {siteLang == "eng" ? languageText.settlementValueEn : languageText.settlementValue}
                              {siteLang == "eng" ? languageText.settlementValue1En : languageText.settlementValue1}
                            </div>
                          </Link>
                        </div>
                        <div className="menuitem">
                          <Link to="/eduhtml/singlesharecallwarrantexample">
                            <img className="menuarrow" src="/img/mobilemenu/arrow.png" />
                            <img className="menuicon" src="/img/mobilemenu/single_stock_call_warrant_example.png" />
                            <div className="menutitle">
                              {siteLang == "eng" ? languageText.callExampleEn : languageText.callExample}
                            </div>
                          </Link>
                        </div>
                        <div className="menuitem">
                          <Link to="/eduhtml/singleshareputwarrantexample">
                            <img className="menuarrow" src="/img/mobilemenu/arrow.png" />
                            <img className="menuicon" src="/img/mobilemenu/single_stock_call_warrant_example.png" />
                            <div className="menutitle">
                              {siteLang == "eng" ? languageText.putExampleEn : languageText.putExample}
                            </div>
                          </Link>
                        </div>
                      </div>
                      {/* Mobile Hexa Link End */}
                    </div>
                  </div>
                </div>
              </div>
              {/* Main Content Section End */}
            </div>
            <div id="rightbar" className="hidden-xs" />
          </div>
        </div>
      </div>
      {/* Bottom Left Right Links and Copyright Section Start */}
      <div id="bottom">
        <div className="hidden-xs hidden-xxs" id="copyright">
          {siteLang == "eng" ? languageTextMain.copyRightEn : languageTextMain.copyRight}
        </div>
        <div className="leftUrl">
          <table className="hasprev">
            <tbody>
              <tr>
                <td width="33px">
                  <Link to="/eduhtml/tipsandtools">
                    <div className="prev left" />
                  </Link>
                </td>
                <td>
                  <div className="prevlbl left">
                    <div className="prevname">
                      <Link to="/eduhtml/tipsandtools">
                        {siteLang == "eng" ? languageTextMain.tipsAndToolsEn : languageTextMain.tipsAndTools}
                      </Link>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="rightUrl">
          <table className="hasnext">
            <tbody>
              <tr>
                <td>
                  <div className="nextlbl right">
                    <div className="nextname">
                      <Link to="/eduhtml/warrantsatexpirylasttradingday">
                        {siteLang == "eng" ? languageTextMain.warrantsAtExpiryLastTradingDayEn : languageTextMain.warrantsAtExpiryLastTradingDay}
                      </Link>
                    </div>
                  </div>
                </td>
                <td width="33px">
                  <Link to="/eduhtml/warrantsatexpirylasttradingday">
                    <div className="next right" />
                  </Link>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      {/* Bottom Left Right Links and Copyright Section End */}
      {/* Right Dots Menu Component Section Start */}
      <div id="dotbar" className="hidden-sm hidden-xs">
        <InvestorAcademyCommon Location={location} Hist={history} />
      </div>
      {/* Right Dots Menu Component Section End  */}
    </div>
  );
};
export default SingleShareExpiries;
