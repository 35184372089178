import React, { useState, useEffect,Fragment } from 'react';
import ImpliedVolatilities from './ImpliedVolatilities';
import "../../common.css";
import "../../investorAcademy.css";


const ImpliedVolatility = (props) => {
    return ( 
        <Fragment> 
            <ImpliedVolatilities/>
        </Fragment> 
     );
}


export default ImpliedVolatility;