import React, { useState, useEffect } from "react";
import "../../common.css";
import "../../investorAcademy.css";
import { Link, useLocation, useHistory } from "react-router-dom";
import ChildInvestorAcademyCommon from "../../../../../components/common/InvestorAcademyCommon/ChildInvestorAcademyCommon";
import { languageText } from "../../../../../languageText/InvestorAcademy/UsefulWarrantTerms/exerciseRatios"
import { languageTextMain } from "../../../../../languageText/InvestorAcademy/index"

const ExerciseRatios = (props) => {
  
  const location = useLocation();
  const history = useHistory();
  const [siteLang , setSiteLang] = useState("")
  const [display , setDisplay] = useState("none")

  // Site Language Change Start //
  useEffect(() => {
    window.scrollTo(0, 0);
    var siteLanguage = $.cookie('applang');
    setSiteLang(siteLanguage)
    if(siteLanguage == "eng"){
      document.querySelector("body").classList.add("eng")
    }else{
      document.querySelector("body").classList.remove("eng")
    }
  }, []);
  // Site Language Change End //

  // View Chart Image Popup Open And Close Start //
  const openPopUp = () => {
    if(display == "none"){
      setDisplay("block")
    }else{
      setDisplay("none")
    }
  }
  // View Chart Image Popup Open And Close End //

  return (
    <div id="investor_academy">
      <div className="imgbg" />
      <div className="mainbg" />
      <div id="main">
        <div id="maintable">
          <div style={{ display: "table-row" }}>
            <div id="leftbar" className="hidden-xs" />
            <div id="maincontainer">
              {/* Top Title Section Start */}
              <div id="mainnav">
                <table>
                  <tbody>
                    <tr>
                      <td width="20%" style={{textAlign:"left", visibility: "hidden"}} />
                      <td width="60%" style={{ textAlign: "center" }}>
                        <div id="pagetitlediv">
                          <div id="ptitle">
                            {siteLang == "eng" ? languageText.pageTitleEn : languageText.pageTitle}
                          </div>
                          <div id="pname">
                            {siteLang == "eng" ? languageText.pageNameEn : languageText.pageName}
                          </div>
                        </div>
                      </td>
                      <td width="20%" style={{textAlign:"right", visibility: "hidden"}} />
                    </tr>
                  </tbody>
                </table>
              </div>
              {/* Top Title Section End */}
              {/* Main Content Section Start */}
              <div id="maincontent" className="excerciseRatio">
                <div id="mainscroll">
                  <div id="mainscrollcontent">
                    <style dangerouslySetInnerHTML={{__html: " .contentbox table{ margin: 0 auto; } .contentbox table thead th{ background-color: #FFD400; } .contentbox table tbody td, .contentbox table thead th{ height:3em; padding:0 1em; color:#000000; } .contentbox table tbody  tr:nth-child(even) td {background-color: #FFF4D2} .contentbox table tbody  tr:nth-child(odd) td {background-color: #FFFF80} .contentbox table thead th{ text-align:left; } .contentbox table tbody td.col1{ background-color: #FFF4D2; text-align:left; padding-left: 1em; } .contentbox table tbody td.col2{ background-color: #FFFF80; text-align:center; } .contentbox table tbody td.col3{ background-color: #FFD400; text-align:center:} .contentbox table tfoot td.disclaimer{ padding-top: 0.5em; color: #FFFFFF; font-size: 0.8em; } .nowrap{ white-space: nowrap; }" }} />
                    <div className="contentbox">
                      <p>{siteLang == "eng" ? languageText.pageDescriptionEn : languageText.pageDescription}</p>
                      <table className="ctable">
                        <thead>
                          <tr>
                            <th style={{width: '50%', textAlign: 'center'}}>
                              {siteLang == "eng" ? languageText.ctableTrTh1En : languageText.ctableTrTh1}
                            </th>
                            {siteLang == "eng" ?
                              <th style={{width: '50%', textAlign: 'center'}}>
                                {languageText.ctableTrTh2En}
                              </th>
                              :
                              <th style={{width: '50%', textAlign: 'center'}}>
                                {languageText.ctableTrTh2}
                                <br />
                                {languageText.ctableTrTh21}
                              </th>
                            }
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td style={{textAlign: 'center', backgroundColor: '#FFF4D2'}}>
                              <div>{siteLang == "eng" ? languageText.ctableTrTd1En : languageText.ctableTrTd1}</div>
                              <div>{siteLang == "eng" ? languageText.ctableTrTd12En : languageText.ctableTrTd12}</div>
                            </td>
                            <td style={{textAlign: 'center', backgroundColor: '#FFF4D2'}}>
                              <div>{siteLang == "eng" ? languageText.ctableTrTd2En : languageText.ctableTrTd2}</div>
                              <div style={{height: '18px'}} />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <p>
                        {siteLang == "eng" ? languageText.pageDescription1En : languageText.pageDescription1}
                        <br />
                        <br />
                        {siteLang == "eng" ? languageText.pageDescription2En : languageText.pageDescription2}
                      </p>
                      <div className="imgholder">
                        <img style={{maxWidth: '689px'}} src={siteLang == "eng" ? languageText.imgUrlEn : languageText.imgUrl} />
                        <div className="visible-xs">
                          <button onClick={openPopUp}>
                            {siteLang == "eng" ? "View table" : "View table"}
                          </button>
                        </div>
                      </div>
                      <p>  
                        {siteLang == "eng" ? languageText.pageDescription3En : languageText.pageDescription3}
                        <br /><br />
                        {siteLang == "eng" ? languageText.pageDescription4En : languageText.pageDescription4}
                        <br /><br />
                        {siteLang == "eng" ? languageText.pageDescription5En : languageText.pageDescription5}
                        <br /><br />
                        {siteLang == "eng" ? languageText.pageDescription6En : languageText.pageDescription6}
                        <br /><br />
                        {siteLang == "eng" ? languageText.pageDescription7En : languageText.pageDescription7}
                        {siteLang == "eng" ? <br /> : ""}
                        {siteLang == "eng" ? <br /> : ""}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {/* Main Content Section End */}
            </div>
            <div id="rightbar" className="hidden-xs" />
          </div>
        </div>
      </div>
      {/* Bottom Left Right Links and Copyright Section Start */}
      <div id="bottom">
        <div className="hidden-xs hidden-xxs" id="copyright">
          {siteLang == "eng" ? languageTextMain.copyRightEn : languageTextMain.copyRight}
        </div>
        <div className="leftUrl">
          <table className="hasprev">
            <tbody>
              <tr>
                <td width="33px">
                  <Link to="/eduhtml/delta">
                    <div className="prev left" />
                  </Link>
                </td>
                <td>
                  <div className="prevlbl left">
                    <div className="prevname">
                      <Link to="/eduhtml/delta">
                        {siteLang == "eng" ? languageTextMain.deltaEn : languageTextMain.delta}
                      </Link>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="rightUrl">
          <table className="hasnext">
            <tbody>
              <tr>
                <td>
                  <div className="nextlbl right">
                    <div className="nextname">
                      <Link to="/eduhtml/warrantsensitivity">
                        {siteLang == "eng" ? languageTextMain.dWSensitivityEn : languageTextMain.dWSensitivity}
                      </Link>
                    </div>
                  </div>
                </td>
                <td width="33px">
                  <Link to="/eduhtml/warrantsensitivity">
                    <div className="next right" />
                  </Link>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      {/* Bottom Left Right Links and Copyright Section End */}
      {/* Right Dots Menu Component Section Start */}
      <div id="dotbar" className="hidden-sm hidden-xs">
        <ChildInvestorAcademyCommon Location={location} Hist={history} />
      </div>
      {/* Right Dots Menu Component Section End  */}
      {/* View Chart Popup In Mobile Start  */}
      <div id="imgpopup" style={{display: display}}>
        <div id="closebtn2" onClick={openPopUp} />
        <div className="imgHold">
          <img src={siteLang == "eng" ? languageText.imgUrlEn : languageText.imgUrl} className="landscape" />
        </div>
      </div>
      {/* View Chart Popup In Mobile End  */}
    </div>
  );
};
export default ExerciseRatios;
