import React, { useState, useEffect } from "react";
import "../../common.css";
import "../../investorAcademy.css";
import { Link, useLocation, useHistory } from "react-router-dom";
import ChildInvestorAcademyCommon from "../../../../../components/common/InvestorAcademyCommon/ChildInvestorAcademyCommon";
import { languageText } from "../../../../../languageText/InvestorAcademy/UsefulWarrantTerms/effectiveGearings"
import { languageTextMain } from "../../../../../languageText/InvestorAcademy/index"

const EffectiveGearings = (props) => {
  
  const location = useLocation();
  const history = useHistory();
  const [siteLang , setSiteLang] = useState("")

  // Site Language Change Start //
  useEffect(() => {
    window.scrollTo(0, 0);
    var siteLanguage = $.cookie('applang');
    setSiteLang(siteLanguage)
    if(siteLanguage == "eng"){
      document.querySelector("body").classList.add("eng")
    }else{
      document.querySelector("body").classList.remove("eng")
    }
  }, []);
  // Site Language Change End //

  return (
    <div id="investor_academy">
      <div className="imgbg" />
      <div className="mainbg" />
      <div id="main">
        <div id="maintable">
          <div style={{ display: "table-row" }}>
            <div id="leftbar" className="hidden-xs" />
            <div id="maincontainer">
              {/* Top Title Section Start */}
              <div id="mainnav">
                <table>
                  <tbody>
                    <tr>
                      <td width="20%" style={{textAlign:"left", visibility: "hidden"}} />
                      <td width="60%" style={{ textAlign: "center" }}>
                        <div id="pagetitlediv">
                          <div id="ptitle">
                            {siteLang == "eng" ? languageText.pageTitleEn : languageText.pageTitle}
                          </div>
                          <div id="pname">
                            {siteLang == "eng" ? languageText.pageNameEn : languageText.pageName}
                          </div>
                        </div>
                      </td>
                      <td width="20%" style={{textAlign:"right", visibility: "hidden"}} />
                    </tr>
                  </tbody>
                </table>
              </div>
              {/* Top Title Section End */}
              {/* Main Content Section Start */}
              <div id="maincontent" className="preminum">
                <div id="mainscroll">
                  <div id="mainscrollcontent">
                    <style dangerouslySetInnerHTML={{__html: ".formulaholder p{ font-size:1.2em !important; }.formulabox{background-color: #363636;font-size: 14px;line-height: 1.42857143;}.formulaholder p span.leqt{color:#FFDA44;}.edulist p{text-align: left; }.edulist li { background-image: url('nswCH.png');background-position: -5px;background-repeat: no-repeat;padding-left: 7px;}.contentbox ol{font-size:14px}.edulist .ttl{font-size:16px !important;}.edulist .formulaholder p{font-size:1em !important;}" }} />
                    <div className="contentbox">
                      <p>
                        {siteLang == "eng" ? languageText.pageDescriptionEn : languageText.pageDescription}
                      </p>
                      <div className="formulaholder" style={{marginLeft: '46px'}}>
                        <div className="formulabox">
                          <p><span className="leqt">Effective Gearing</span> = Gearing x Delta</p>
                        </div>
                      </div>
                      <p>
                        {siteLang == "eng" ? languageText.pageDescription1En : languageText.pageDescription1}
                      </p>
                      <ol className="edulist">
                        <li>
                          <p className="ttl"><b>{siteLang == "eng" ? languageText.edulistFirstTitleEn : languageText.edulistFirstTitle} </b></p>
                          <p>{siteLang == "eng" ? languageText.edulistFirstParagraphEn : languageText.edulistFirstParagraph} </p>
                          <div className="formulaholder">
                            <div className="formulabox">
                              <p>
                                <span className="leqt">{siteLang == "eng" ? languageText.edulistFirstFormulaLeftEn : languageText.edulistFirstFormulaLeft}</span> 
                                {siteLang == "eng" ? languageText.edulistFirstFormulaRightEn : languageText.edulistFirstFormulaRight}
                              </p>
                            </div>
                          </div>
                          <p>{siteLang == "eng" ? languageText.edulistFirstParagraph2En : languageText.edulistFirstParagraph2}</p>
                        </li>
                        <li>
                          <p className="ttl"><b>{siteLang == "eng" ? languageText.edulistSecondTitleEn : languageText.edulistSecondTitle}</b></p>
                          <p>
                            {siteLang == "eng" ? languageText.edulistSecondParagraphEn : languageText.edulistSecondParagraph}
                            <br />
                            <br />
                            {siteLang == "eng" ? languageText.edulistSecondParagraph2En : languageText.edulistSecondParagraph2}
                          </p>
                          <div className="formulaholder">
                            <div className="formulabox">
                              <p>
                                <span className="leqt">{siteLang == "eng" ? languageText.edulistSecondFormulaLeftEn : languageText.edulistSecondFormulaLeft}</span> 
                                {siteLang == "eng" ? languageText.edulistSecondFormulaRightEn : languageText.edulistSecondFormulaRight}
                              </p>
                            </div>
                          </div>
                          <p>{siteLang == "eng" ? languageText.edulistSecondParagraph3En : languageText.edulistSecondParagraph3}</p>
                          <div className="formulaholder">
                            <div className="formulabox">
                              <p>
                                <span className="leqt">{siteLang == "eng" ? languageText.edulistSecondFormula2LeftEn : languageText.edulistSecondFormula2Left}</span> 
                                {siteLang == "eng" ? languageText.edulistSecondFormula2RightEn : languageText.edulistSecondFormula2Right}
                              </p>
                            </div>
                          </div>
                        </li>
                      </ol>
                      <div className="tipholder">
                        <div className="tipbox">
                          <h2>{siteLang == "eng" ? languageText.tipHolderHeadingEn : languageText.tipHolderHeading}</h2>
                          <ul>
                            <li>
                              <p>
                                {siteLang == "eng" ? languageText.tipHolderParagraph1En : languageText.tipHolderParagraph1}
                                <a href="/tools/dwsearch"> {siteLang == "eng" ? languageText.tipHolderLinkEn : languageText.tipHolderLink} </a> 
                                {siteLang == "eng" ? languageText.tipHolderParagraph2En : languageText.tipHolderParagraph2}
                                <a href="/tools/dwterms"> {siteLang == "eng" ? languageText.tipHolderLink2En : languageText.tipHolderLink2} </a>
                                {siteLang == "eng" ? "page." : ""}
                              </p>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Main Content Section End */}
            </div>
            <div id="rightbar" className="hidden-xs" />
          </div>
        </div>
      </div>
      {/* Bottom Left Right Links and Copyright Section Start */}
      <div id="bottom">
        <div className="hidden-xs hidden-xxs" id="copyright">
          {siteLang == "eng" ? languageTextMain.copyRightEn : languageTextMain.copyRight}
        </div>
        <div className="leftUrl">
          <table className="hasprev">
            <tbody>
              <tr>
                <td width="33px">
                  <Link to="/eduhtml/gearing">
                    <div className="prev left" />
                  </Link>
                </td>
                <td>
                  <div className="prevlbl left">
                    <div className="prevname">
                      <Link to="/eduhtml/gearing">
                        {siteLang == "eng" ? languageTextMain.gearingEn : languageTextMain.gearing}
                      </Link>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="rightUrl">
          <table className="hasnext">
            <tbody>
              <tr>
                <td>
                  <div className="nextlbl right">
                    <div className="nextname">
                      <Link to="/eduhtml/impliedvolatility">
                        {siteLang == "eng" ? languageTextMain.impliedVolatilityEn : languageTextMain.impliedVolatility}
                      </Link>
                    </div>
                  </div>
                </td>
                <td width="33px">
                  <Link to="/eduhtml/impliedvolatility">
                    <div className="next right" />
                  </Link>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      {/* Bottom Left Right Links and Copyright Section End */}
      {/* Right Dots Menu Component Section Start */}
      <div id="dotbar" className="hidden-sm hidden-xs">
        <ChildInvestorAcademyCommon Location={location} Hist={history} />
      </div>
      {/* Right Dots Menu Component Section End  */}
    </div>
  );
};
export default EffectiveGearings;
