import React, { Fragment } from 'react';
import TrackingWarrant from './TrackingWarrant';
import "../../common.css";
import "../../investorAcademy.css";


const TrackingWarrants = (props) => {
    return ( 
        <Fragment> 
            <TrackingWarrant/>
        </Fragment> 
     );
}


export default TrackingWarrants;