import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faL, faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import "./search.css";
import API from "../../utils/apiCalling";
import { useHistory } from "react-router-dom"
import { searchApi } from "../../utils/searchApi";
import { config } from "../../utils/apiUrl";

const SearchCom = () => {

    const history = useHistory()
    const [searchKey, setsearchKey] = useState("")
    const [keyVal, setKeyVal] = useState("")
    const [loading, setloading] = useState(false)
    const [searchData, setSearchData] = useState([])
    const [siteLang, setSiteLang] = useState("")
    const [apiSIteLang, setApiSIteLang] = useState("")
    const api = new API();

    useEffect(() => {
        window.scrollTo(0, 0);
        var siteLanguage = $.cookie('applang');
        setSiteLang(siteLanguage)
        if(siteLanguage == "eng"){
            document.querySelector("body").classList.add("eng")
            setApiSIteLang("en")
        }else{
            document.querySelector("body").classList.remove("eng")
            setApiSIteLang("th")
        }
        var urlData = new URLSearchParams(window.location.search)
        setsearchKey(urlData.get("key"))
        setKeyVal(urlData.get("key"))
        if(searchKey){
            getSearchData()
        }
    }, [searchKey])

    const getSearchData = async() => {
        try {
            setloading(true)
            const responseData =  await api.get(config.pageSearch, true, { 
                key: searchKey, 
                lang: apiSIteLang 
            })
            if(responseData){
                setSearchData(responseData)
            }
            else{
                setSearchData([])
            }
            setloading(false)
        } catch (error) {
            console.log(error)   
        }
    }

    const goSearch = () =>{
        history.push("/search?key="+keyVal)
    }


    return (
    <div id="pagesearch">
        <div className="pagesearch-top">
            <div className="container-fluid">
                <div className="psearchbox input-group">
                    <input 
                    name="keywords" 
                    type="text" 
                    className="form-control" 
                    placeholder="Search Macquarie" 
                    value={keyVal}
                    onChange={(e)=>{
                        setKeyVal(e.target.value)
                    }}/>
                    <div className="input-group-btn">
                        <button className="btn btn-default" type="submit" onClick={goSearch}>
                            <FontAwesomeIcon
                                icon={faMagnifyingGlass}
                            />
                        </button>
                    </div>
                </div>
            </div>
        </div>
        <div className="pagesearch-body">
            {loading && <div className="loading">loading ...</div>}
            {searchData.length > 0 && <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="search-resultbox"  style={{position: 'relative'}}>
                            {searchData.map((item, index) => (
                                <div className="search-result resulttmpl" index={index} key={index}>
                                    <a href={item.page_url} className="search-result-title" dangerouslySetInnerHTML={{__html: item.page_title}}></a>
                                    <div className="search-result-dsply" dangerouslySetInnerHTML={{__html: item.page_dsply}}></div>
                                    <a className="search-result-achor" href={item.page_url}>{item.page_url}</a>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>}
            {searchData.length == 0 && !loading && <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="search-resultbox" style={{position: 'relative'}}><div className="nomatch">Your search did not match any documents</div></div>
                    </div>
                </div>
            </div>}
        </div>
    </div>
  );
};
export default SearchCom;
