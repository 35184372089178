export const languageText ={

    pageTitle: "ราคา DW คำนวณอย่างไร",
    pageTitleEn: "How are DW priced?",

    pageName: "ราคาหุ้นอ้างอิง",
    pageNameEn: "Stock Price",

    pageDescription: "ราคาหุ้นอ้างอิงเป็นหนึ่งในตัวแปรที่มีความสำคัญที่สุดในการกำหนดราคา DW",
    pageDescriptionEn: "The underlying share price - is one of the most important determinants of the DW price. As the share price increases, the value of the call DW will increase and the put DW price will fall (the same works in reverse). To see how the share price affects the DW price please refer to the",

    pageDescription1: "หากราคาหุ้นอ้างอิงปรับตัวเพิ่มสูงขึ้น ราคา Call DW จะเพิ่มขึ้นและราคา Put DW จะลดลง (และจะเป็นอย่างนี้ในทางตรงกันข้าม) หากต้องการดูว่าราคาหุ้นมีผลต่อ DW อย่างไร ลองกลับไปดูที่",
    pageDescription1En: "",

    pageDescriptionLink: " หน้าก่อนหน้า",
    pageDescriptionLinkEn: " previous example",

    imgUrl: "/img/education/img/sp.png",
    imgUrlEn: "/img/education/img/sp.png",
}