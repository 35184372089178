import React, { useState, useEffect,Fragment } from 'react';
import NoMarginCalls from './NoMarginCalls';
import "../../common.css";
import "../../investorAcademy.css";


const NoMarginCall = (props) => {
    return ( 
        <Fragment> 
        <NoMarginCalls/>
        </Fragment> 
     );
}


export default NoMarginCall;