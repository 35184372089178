import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./common.css";
import "./investorAcademy.css";
import LangContext from "../../../components/common/context/lang-context";
import { useContext } from "react";

// import seminar_img from "../../../assets/images/education/button-static.png"

const InvestorAcademies = (props) => {
  const langContext = useContext(LangContext);
  const [siteLang , setSiteLang] = useState("")

  useEffect(() => {
    window.scrollTo(0, 0);
    var siteLanguage = $.cookie('applang');
    setSiteLang(siteLanguage)
    if(siteLanguage == "eng"){
      document.querySelector("body").classList.add("eng")
    }else{
      document.querySelector("body").classList.remove("eng")
    }
  }, []);

  return (
    <div id="investor_academy">
      <div className="imgbg" />
      <div id="main">
        <div id="home">
          <table id="hometable" style={{ width: "100%", height: "100%", tableLayout: "fixed" }}>
            <tbody>
              <tr>
                <td style={{ textAlign: "center" }}>
                  <div id="nine">
                    <div id="n1" className="ninep">
                      <div>
                        <Link to="/eduhtml/whytradewarrants"></Link>{" "}
                      </div>
                    </div>
                    <div id="n2" className="ninep">
                      <div>
                        <Link to="/eduhtml/whatarewarrants"></Link>{" "}
                      </div>
                    </div>
                    <div id="n3" className="ninep">
                      <div>
                        <Link to="/eduhtml/howarewarrantsnamed"></Link>{" "}
                      </div>
                    </div>
                    <div id="n4" className="ninep">
                      <div>
                        <Link to="/eduhtml/howarewarrantspriced"></Link>{" "}
                      </div>
                    </div>
                    <div id="n5" className="ninep">
                      <div>
                        <Link to="/eduhtml/usefulwarrantterms"></Link>{" "}
                      </div>
                    </div>
                    <div id="n6" className="ninep">
                      <div>
                        <Link to="/eduhtml/howtoselectawarrant"></Link>{" "}
                      </div>
                    </div>
                    <div id="n7" className="ninep">
                      <div>
                        <Link to="/eduhtml/singlesharecallsandputsatexpiry"></Link>{" "}
                      </div>
                    </div>
                    <div id="n8" className="ninep">
                      <div>
                        <Link to="/eduhtml/risk"></Link>{" "}
                      </div>
                    </div>
                    <div id="n9" className="ninep">
                      <div>
                        <Link to="/eduhtml/marketmakers"></Link>{" "}
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};
export default InvestorAcademies;
