import React, { useEffect, useState } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import InvestorAcademyCommon from "../../../../components/common/InvestorAcademyCommon";
// import LangContext from "../../../../components/common/context/lang-context";
// import { useContext } from "react";
import "../common.css";
import "../investorAcademy.css";
import { languageText } from "../../../../languageText/InvestorAcademy/WhyTradeWarrants/index"
import { languageTextMain } from "../../../../languageText/InvestorAcademy/index"

const WhyTradeWarrants = (props) => {

  // const langContext = useContext(LangContext);
  const location = useLocation();
  const history = useHistory();
  const [siteLang , setSiteLang] = useState("")

  useEffect(() => {
    window.scrollTo(0, 0);
    var siteLanguage = $.cookie('applang');
    setSiteLang(siteLanguage)
    if(siteLanguage == "eng"){
      document.querySelector("body").classList.add("eng")
    }else{
      document.querySelector("body").classList.remove("eng")
    }
  }, []);

  return ( 
    <div id="investor_academy">
      <div className="imgbg" />
      <div className="mainbg" />
      <div id="main">
        <div id="maintable">
          <div style={{ display: "table-row" }}>
            <div id="leftbar" className="hidden-xs" />
            <div id="maincontainer">
              <div id="mainnav">
                <table>
                  <tbody>
                    <tr>
                      <td width="20%" style={{textAlign:"left", visibility: "hidden"}} />
                      <td width="60%" style={{ textAlign: "center" }}>
                        <div id="pagetitlediv">
                          <div id="ptitle">
                            <img height="56px" src="/img/education/img/01.png" alt="Investor" />
                          </div>
                          <div id="pname">
                            {siteLang == "eng" ? languageText.pageTitleEn : languageText.pageTitle}
                          </div>
                        </div>
                      </td>
                      <td width="20%" style={{textAlign:"right", visibility: "hidden"}} />
                    </tr>
                  </tbody>
                </table>
              </div>
              <div id="maincontent">
                <div id="mainscroll">
                  <div id="mainscrollcontent">
                    <style
                      dangerouslySetInnerHTML={{__html:" \n .hexrow.odd{ \n margin-left: 73px; \n } \n ",}}
                    />
                    <div className="contentbox">
                      <p className="showtile">
                        {siteLang == "eng" ? languageText.pageDescriptionEn : languageText.pageDescription}
                      </p>
                      <div className="hexmenu hidden-xxs hidden-xs">
                        <div className="imgmask">
                          <img className="hexbg maxW490" src="/img/topic/y_trade_wrnt.png" />
                          <div className="hexarea" style={siteLang == "eng" ? {top: '19.22%', left: '18%', right: '51%', bottom: '89%'} : {top: '18%', left: '18%', right: '51%', bottom: '89%'}}>
                            <div className="topictxt">
                              <Link to="/eduhtml/gearingeffect">
                                {siteLang == "eng" ? languageText.gearingEffectEn : languageText.gearingEffect}
                              </Link>
                            </div>
                          </div>
                          <div className="hexarea" style={{top: '13%', left: '51%', right: '18%', bottom: '69%'}}>
                            <div className="topictxt">
                              <Link to="/eduhtml/limitedloss">
                                {siteLang == "eng" ? languageText.limitedLossEn : languageText.limitedLoss}
                              </Link>
                            </div>
                          </div>
                          <div className="hexarea" style={{top: '41%', left: '2%', right: '68%', bottom: '40%'}}>
                            <div className="topictxt">
                              <Link to="/eduhtml/porfitfrombullorbearmarket">
                                {siteLang == "eng" ? languageText.porfitFromBullorBearMarketEn : languageText.porfitFromBullorBearMarket}
                              </Link>
                            </div>
                          </div>
                          <div className="hexarea" style={{top: '41%', left: '35%', right: '35%', bottom: '40%'}}>
                            <div className="topictxt">
                              <Link to="/eduhtml/nomargincall">
                                {siteLang == "eng" ? languageText.noMarginCallEn : languageText.noMarginCall}
                              </Link>
                            </div>
                          </div>
                          <div className="hexarea" style={{top: '41%', left: '68%', right: '2%', bottom: '40%'}}>
                            <div className="topictxt">
                              <Link to="/eduhtml/lowercapitaloutlay">
                                {siteLang == "eng" ? languageText.lowerCapitalOutlayEn : languageText.lowerCapitalOutlay}
                              </Link>
                            </div>
                          </div>
                          <div className="hexarea" style={{top: '70%', left: '18%', right: '51%', bottom: '11%'}}>
                            <div className="topictxt">
                              <Link to="/eduhtml/releasingsharecapital">
                                {siteLang == "eng" ? languageText.releasingShareCapitalEn : languageText.releasingShareCapital}
                              </Link>
                            </div>
                          </div>
                          <div className="hexarea" style={{top: '70%', left: '51%', right: '18%', bottom: '11%'}}>
                            <div className="topictxt">
                              <Link to="/eduhtml/easeoftrade">
                                {siteLang == "eng" ? languageText.easeofTradeEn : languageText.easeofTrade}
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="mobilemenu visible-xxs visible-xs">
                        <div className="menuitem">
                          <Link to="/eduhtml/gearingeffect">
                            <img className="menuarrow" src="/img/mobilemenu/arrow.png" />
                            <img className="menuicon" src="/img/mobilemenu/effective_gearing.png" />
                            <div className="menutitle">
                                {siteLang == "eng" ? languageText.gearingEffectEn : languageText.gearingEffect}
                            </div>
                          </Link>
                        </div>
                        <div className="menuitem">
                          <Link to="/eduhtml/limitedloss">
                            <img className="menuarrow" src="/img/mobilemenu/arrow.png" />
                            <img className="menuicon" src="/img/mobilemenu/limited_loss.png" />
                            <div className="menutitle">
                              {siteLang == "eng" ? languageText.limitedLossEn : languageText.limitedLoss}
                            </div>
                          </Link>
                        </div>
                        <div className="menuitem">
                          <Link to="/eduhtml/porfitfrombullorbearmarket">
                            <img className="menuarrow" src="/img/mobilemenu/arrow.png" />
                            <img className="menuicon" src="/img/mobilemenu/sensitivity.png" />
                            <div className="menutitle twoline">
                              {siteLang == "eng" ? languageText.porfitFromBullorBearMarketEn : languageText.porfitFromBullorBearMarket}
                            </div>
                          </Link>
                        </div>
                        <div className="menuitem">
                          <Link to="/eduhtml/nomargincall">
                            <img className="menuarrow" src="/img/mobilemenu/arrow.png" />
                            <img className="menuicon" src="/img/mobilemenu/ability_to_go_long_or_short.png" />
                            <div className="menutitle">
                              {siteLang == "eng" ? languageText.noMarginCallEn : languageText.noMarginCall}
                            </div>
                          </Link>
                        </div>
                        <div className="menuitem">
                          <Link to="/eduhtml/lowercapitaloutlay">
                            <img className="menuarrow" src="/img/mobilemenu/arrow.png" />
                            <img className="menuicon" src="/img/mobilemenu/lower_capital_outlay.png" />
                            <div className="menutitle twoline">
                              {siteLang == "eng" ? languageText.lowerCapitalOutlayEn : languageText.lowerCapitalOutlay}
                            </div>
                          </Link>
                        </div>
                        <div className="menuitem">
                          <Link to="/eduhtml/releasingsharecapital">
                            <img className="menuarrow" src="/img/mobilemenu/arrow.png" />
                            <img className="menuicon" src="/img/mobilemenu/releasing_share_capital.png" />
                            <div className="menutitle twoline">
                              {siteLang == "eng" ? languageText.releasingShareCapitalEn : languageText.releasingShareCapital}
                            </div>
                          </Link>
                        </div>
                        <div className="menuitem">
                          <Link to="/eduhtml/easeoftrade">
                            <img className="menuarrow" src="/img/mobilemenu/arrow.png" />
                            <img className="menuicon" src="/img/mobilemenu/ease_of_trade.png" />
                            <div className="menutitle"> 
                              {siteLang == "eng" ? languageText.easeofTradeEn : languageText.easeofTrade}
                            </div>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div id="rightbar" className="hidden-xs" />
          </div>
        </div>
      </div>

      <div id="bottom">
        <div className="hidden-xs hidden-xxs" id="copyright">
          {siteLang == "eng" ? languageTextMain.copyRightEn : languageTextMain.copyRight}
        </div>
        <div className="leftUrl">
          <table className="hasprev">
            <tbody>
              <tr>
                <td width="33px">
                  <Link to="/eduhtml">
                    <div className="prev left" />
                  </Link>
                </td>
                <td>
                  <div className="prevlbl left">
                    <div className="prevname">
                      <Link to="/eduhtml">
                        {siteLang == "eng" ? languageTextMain.homeLinkEn : languageTextMain.homeLink}
                      </Link>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="rightUrl">
          <table className="hasnext">
            <tbody>
              <tr>
                <td>
                  <div className="nextlbl right">
                    <div className="nextname">
                      <Link to="/eduhtml/gearingeffect">
                        {siteLang == "eng" ? languageTextMain.gearingEffectEn : languageTextMain.gearingEffect}
                      </Link>
                    </div>
                  </div>
                </td>
                <td width="33px">
                  <Link to="/eduhtml/gearingeffect">
                    <div className="next right" />
                  </Link>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div id="dotbar" className="hidden-sm hidden-xs">
        <InvestorAcademyCommon Location={location} Hist={history} />
      </div>
    </div>
  );
};
export default WhyTradeWarrants;
