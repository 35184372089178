import React, { useState, useEffect, Fragment } from 'react';
import { Link } from 'react-router-dom';
import { get } from 'lodash';
import ReactTooltip from "react-tooltip";

const  InvestorAcademyCommon = ({Location,Hist}) => {

    const pathName = get(Location,"pathname","")
    const [siteLang , setSiteLang] = useState("")

    useEffect(() => {
        var siteLanguage = $.cookie('applang');
        setSiteLang(siteLanguage)
        if(siteLanguage == "eng"){
          document.querySelector("body").classList.add("eng")
        }else{
          document.querySelector("body").classList.remove("eng")
        }
    }, []);

    return ( 
        <Fragment>
            <div className="homebtn dbtn">
                <Link to="/eduhtml"></Link>
            </div>
            <div 
                className={pathName === "/eduhtml/whytradewarrants" ? "dotbtn d1 dbtn selected" :"dotbtn d1 dbtn"}
                data-tip
                data-for={"why-trade-warrants"}
            >
                <Link to="/eduhtml/whytradewarrants"></Link>
            </div>
            <ReactTooltip
                id={"why-trade-warrants"}
                delayHide={100}
                place="left"
                type="warning"
                effect="solid"
            >
                <div className="bg-tooltip">
                    <p className="tooltip-content" style={{marginBottom: "0px"}}>{siteLang == "eng" ? "Why Trade DW?" : "ทำไมต้องลงทุนใน DW?"}</p>
                </div>
            </ReactTooltip>
            <div 
                className={pathName === "/eduhtml/whatarewarrants" ? "dotbtn d2 dbtn selected" :"dotbtn d2 dbtn"} 
                data-tip
                data-for={"what-are-warrants"}
            >
                <Link to="/eduhtml/whatarewarrants"></Link>
            </div>
            <ReactTooltip
                id={"what-are-warrants"}
                delayHide={100}
                place="left"
                type="warning"
                effect="solid"
            >
                <div className="bg-tooltip">
                    <p className="tooltip-content" style={{marginBottom: "0px"}}>{siteLang == "eng" ? "What are DW?" : "DW คืออะไร?"}</p>
                </div>
            </ReactTooltip>
            <div 
                className={pathName === "/eduhtml/howarewarrantsnamed" ? "dotbtn d4 dbtn selected" :"dotbtn d4 dbtn"}
                data-tip
                data-for={"How-are-Warrants-priced"}
            >
                <Link to="/eduhtml/howarewarrantsnamed"></Link>
            </div>
            <ReactTooltip
                id={"How-are-Warrants-priced"}
                delayHide={100}
                place="left"
                type="warning"
                effect="solid"
            >
                <div className="bg-tooltip">
                    <p className="tooltip-content" style={{marginBottom: "0px"}}>{siteLang == "eng" ? "How are DW named?" : "สัญลักษณ์ของ DW เป็นอย่างไร"}</p>
                </div>
            </ReactTooltip>
            <div 
                className={pathName === "/eduhtml/howarewarrantspriced" ? "dotbtn d7 dbtn selected" :"dotbtn d7 dbtn"} 
                data-tip
                data-for={"Monitoring-price-changes"}
            >
                <Link to="/eduhtml/howarewarrantspriced"></Link>
            </div>
            <ReactTooltip
                id={"Monitoring-price-changes"}
                delayHide={100}
                place="left"
                type="warning"
                effect="solid"
            >
                <div className="bg-tooltip">
                    <p className="tooltip-content" style={{marginBottom: "0px"}}>{siteLang == "eng" ? "How are DW priced?" : "ราคา DW คำนวณอย่างไร"}</p>
                </div>
            </ReactTooltip>
            <div 
                className={pathName === "/eduhtml/usefulwarrantterms" ? "dotbtn d3 dbtn selected" :"dotbtn d3 dbtn"} 
                data-tip
                data-for={"select-warrant"}
            >
                <Link to="/eduhtml/usefulwarrantterms"></Link>
            </div>
            <ReactTooltip
                id={"select-warrant"}
                delayHide={100}
                place="left"
                type="warning"
                effect="solid"
            >
                <div className="bg-tooltip">
                    <p className="tooltip-content" style={{marginBottom: "0px"}}>{siteLang == "eng" ? "Useful DW terms" : "ตัวแปรเกี่ยวกับ DW ที่ควรรู้"}</p>
                </div>
            </ReactTooltip>
            <div 
                className={pathName === "/eduhtml/howtoselectawarrant" ? "dotbtn d9 dbtn selected" :"dotbtn d9 dbtn"} 
                data-tip
                data-for={"single-share-call"}
            >
                <Link to="/eduhtml/howtoselectawarrant"></Link>
            </div>
            <ReactTooltip
                id={"single-share-call"}
                delayHide={100}
                place="left"
                type="warning"
                effect="solid"
            >
                <div className="bg-tooltip">
                    <p className="tooltip-content" style={{marginBottom: "0px"}}>{siteLang == "eng" ? "How to select a DW?" : "วิธีการเลือก DW"}</p>
                </div>
            </ReactTooltip>
            <div 
                className={pathName === "/eduhtml/singlesharecallsandputsatexpiry" ? "dotbtn d5 dbtn selected" :"dotbtn d5 dbtn"} 
                data-tip
                data-for={"index-calls"}
            >
                <Link to="/eduhtml/singlesharecallsandputsatexpiry"></Link> 
            </div>
            <ReactTooltip
                id={"index-calls"}
                delayHide={100}
                place="left"
                type="warning"
                effect="solid"
            >
                <div className="bg-tooltip">
                    <p className="tooltip-content" style={{marginBottom: "0px"}}>{siteLang == "eng" ? "DW at expiry" : "DW ณ วันครบกำหนดอายุ"}</p>
                </div>
            </ReactTooltip>
            <div 
                className={pathName === "/eduhtml/risk" ? "dotbtn d8 dbtn selected" :"dotbtn d8 dbtn"} 
                data-tip
                data-for={"risks"}
            >
                <Link to="/eduhtml/risk"></Link>
            </div>
            <ReactTooltip
                id={"risks"}
                delayHide={100}
                place="left"
                type="warning"
                effect="solid"
            >
                <div className="bg-tooltip">
                    <p className="tooltip-content" style={{marginBottom: "0px"}}>{siteLang == "eng" ? "Risk" : "ความเสี่ยง"}</p>
                </div>
            </ReactTooltip>
            <div 
                className={pathName === "/eduhtml/marketmakers" ? "dotbtn d6 dbtn selected" :"dotbtn d6 dbtn"} 
                data-tip
                data-for={"market-marker"}
            >
                <Link to="/eduhtml/marketmakers"></Link>
            </div>
            <ReactTooltip
                id={"market-marker"}
                delayHide={100}
                place="left"
                type="warning"
                effect="solid"
            >
                <div className="bg-tooltip">
                    <p className="tooltip-content" style={{marginBottom: "0px"}}>{siteLang == "eng" ? "Market Makers and Liquidity" : "สภาพคล่องและการดูแลสภาพคล่อง"}</p>
                </div>
            </ReactTooltip>
        </Fragment>
     );
}
 
export default InvestorAcademyCommon;