export const languageText ={

    pageTitle: "ตัวแปรเกี่ยวกับ DW ที่ควรรู้",
    pageTitleEn: "Useful DW terms",

    pageName: "เดลต้า (Delta)",
    pageNameEn: "Delta",

    pageDescription: "เดลต้า (Delta) แสดงค่าการเปลี่ยนแปลงโดยประมาณของราคา DW เมื่อราคาหลักทรัพย์อ้างอิงเปลี่ยนแปลง",
    pageDescriptionEn: "Delta shows the approximate change in the DW price for a small change in the underlying share (or asset) price.",

    formulaholderHeading: "Delta = ",
    formulaholderHeadingEn: "Delta = ",
    formulaholderTdFirst: " ราคา DW ที่เปลี่ยนแปลงไป",
    formulaholderTdFirstEn: " Change in DW price",
    formulaholderTdSecond: " ราคาหุ้นอ้างอิงที่เปลี่ยนแปลงไป",
    formulaholderTdSecondEn: " Change in Underlying share DW price",

    pageDescription1: "ทั้งนี้ ค่า Delta 50% หมายความว่า ราคา DW จะเปลี่ยนแปลงไปประมาณ 0.50 บาท เมื่อราคาหุ้นอ้างอิงเปลี่ยนแปลงไป 1 บาท Delta ของ Put DW จะมีค่าเป็นลบ เนื่องจาก Put DW เคลื่อนไหวในทิศทางตรงข้ามกับหุ้นอ้างอิง",
    pageDescription1En: "Therefore, a delta of 50% means that the DW should move approximately THB 0.50 for each THB 1.00 move in the underlying asset. Deltas for puts are negative since they move inversely with the underlying price.",

    ruletableThead: "หลักเกณฑ์โดยทั่วไป",
    ruletableTheadEn: "General rule of thumb",

    pageDescription2: "Delta จะมีค่าตั้งแต่ 0 ถึง 100% สำหรับ Call DW และตั้งแต่ 0 ถึง -100% สำหรับ Put DW",
    pageDescription2En: "Deltas range from 0 to 100% for calls and 0 to -100% for puts",

    tipholderh2: "เคล็ดลับในการใช้ Delta",
    tipholderh2En: "Helpful tips on how to use Delta",

    tipholderParagraph1: "Delta สามารถใช้เป็นตัวบ่งชี้ความน่าจะเป็นที่ DW จะหมดอายุด้วยสถานะ In-the-money ตัวอย่างเช่น DW ที่มี Delta เท่ากับ 20% จะมีความน่าจะเป็น 20%ที่จะหมดอายุด้วยสถานะ In-the-money และมีความน่าจะเป็น 80% ที่จะหมดอายุด้วยสถานะ Out-of-the-money",
    tipholderParagraph1En: "Delta can also be viewed an indicator of the probability that a DW will expire in the money (ie. with value). For example a DW with a delta of 20% has a 20% probability that it will expire with value, and 80% chance that it will expire worthless.",

    tipholderParagraph2: "Delta สามารถใช้ในการคำนวณหา Sensitivity ได้ ซึ่งเป็นการประมาณราคาหุ้นอ้างอิงที่ต้องเปลี่ยนแปลง เพื่อให้ DW เปลี่ยนแปลง 1 ช่วงราคา คุณจะต้องพิจารณาจำนวน",
    tipholderParagraph2En: "When using delta to estimate how much a DW price will move for a particular price change in the underlying, you must take into consideration the",
    tipholderParagraph2Link1: " DW ต่อหน่วย ",
    tipholderParagraph2Link1En: " DW per share ",
    tipholderParagraph21: "เพื่อใช้ในการประมาณค่า Delta ต่อ DW 1 หน่วย ลองดู",
    tipholderParagraph21En: "number to estimate the “delta per DW”. See an",
    tipholderParagraph2Link2: " ตัวอย่าง ",
    tipholderParagraph2Link2En: " example ",
    tipholderParagraph22: "ในการใช้ Delta และ DW ต่อหน่วยในการคำนวณ Sensitivity",
    tipholderParagraph22En: "of how you can use delta and DW per share to calculate a DW’s sensitivity.",

    tipholderParagraph3: "Delta ใช้เตือนเมื่อ DW นั้นมีความเสี่ยงสูง โดยถ้า Delta สูงกว่า 80% (Deep in-the-money) หรือต่ำกว่า 20% (Deep out-of-the-money) ผู้ออกมักจะวาง bid-offer กว้างๆ เพื่อลดความต้องการซื้อ DW เหล่านั้น โดยเฉพาะ Deep Out-of-the-money DW จะจัดว่ามีความเสี่ยงสูงมากเป็นพิเศษ",
    tipholderParagraph3En: "Use caution when choosing DW that have deltas either above 80% (termed deep in the money) or below 20% (termed deep out of the money) as issuers will often widen the offer spread for these DW to discourage further buying in these DW. Deep OTM DW in particular, are risky for investors to buy.",

    imgUrl: "/img/education/img/calculatedwdailychangefalse_th.png",
    imgUrlEn: "/img/education/img/calculatedwdailychangefalse.png",
}