import React, { useState } from "react";
import "./style.css";
import { languageText } from "../../languageText/unsubscribe";
import { config } from "../../utils/apiUrl";
import { AXIOS_CLIENT } from "../../utils/AXIOS_CLIENT";
import UNSUBSCRIBE_IMG_1 from "../../assets/images/unsubscribe/unsubscribe_1.png";
import UNSUBSCRIBE_IMG_2 from "../../assets/images/unsubscribe/unsubscribe_2.png";
import EARTH_IMG from "../../assets/images/unsubscribe/earth.png";

const UnSubscribe = () => {
  const [email, setEmail] = useState();
  const [unsubscribeStatus, setUnSubscribeStatus] = useState(false);
  const {
    labelTextUnsubscribeTH,
    labelTextIfUnsubscribeTH,
    labelUnSubscribeButtonTH,
    labelReceivingourTH,
    labelUnSubscribeSuccesfullTH,
    labelYouWillTH,
    labelCloseWindowTH,
    labelCloseButtonTH,
  } = languageText;

  const handleSubscribe = async () => {
    if(email == "" || email == undefined){
      alert("Please enter email address")
      return false
    }
    const data = {
      email: email,
    };
    let response = await AXIOS_CLIENT.post(config.unSubscribeNewsLetter+"?email="+email);
    if (response) {
      if (response.data == 1) {
        setUnSubscribeStatus(true);
        window.scrollTo(0, 0);
      }else{
        alert("Please enter correct email address.")
      }
    }
  };

  const handleCloseSuccess = () => {
    setUnSubscribeStatus(false);
    setEmail();
    window.scrollTo(0, 0);
  };

  return (
    <div className="pageobj" id="unsubscribe">
      {!unsubscribeStatus ? (
        <div className="container-fluid" id="Popups-one">
          <div className="row">
            <div className="unsubscribe_box col-md-12">
              <img
                src={UNSUBSCRIBE_IMG_1}
                style={{
                  marginBottom: "30px",
                  height: "180px",
                  width: "180px",
                }}
              />
              <h3>{labelTextUnsubscribeTH}</h3>
              <p>{labelTextIfUnsubscribeTH}</p>
              <p>{labelReceivingourTH}</p>
              <div style={{ marginTop: "40px", paddingBottom: "100px" }}>
                <input
                  type="email"
                  name="Email"
                  id="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="Email"
                  style={{ padding: "4px", color: "#000" }}
                />
                <br />
                <button
                  className="btn btn-warning Unsubscribe"
                  onClick={handleSubscribe}
                >
                  {labelUnSubscribeButtonTH}
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="container-fluid" id="Popups-two">
          <div className="row">
            <div className="unsubscribe_box col-md-12">
              <img
                src={UNSUBSCRIBE_IMG_2}
                style={{
                  marginBottom: "8px",
                  height: "300px",
                  width: "300px",
                }}
              />
              <h3>{labelUnSubscribeSuccesfullTH}</h3>
              <p>{labelYouWillTH}</p>
              <p>{labelCloseWindowTH}</p>
              <div style={{ marginTop: "40px", paddingBottom: "100px" }}>
                <button
                  id="USClose"
                  className="btn btn-light Close"
                  style={{ color: "#000" }}
                  onClick={handleCloseSuccess}
                >
                  {labelCloseButtonTH}
                </button>
              </div>
              <div className="unsubscribe_box_img">
                <span>
                  <p
                    style={{
                      color: "white",
                      fontSize: "20px",
                      fontWeight: "bold",
                    }}
                  >
                    www.thaidw.com &nbsp;&nbsp;|&nbsp;&nbsp; FB : DW28
                    &nbsp;&nbsp;|&nbsp;&nbsp; Line : @thaidw
                  </p>
                </span>
              </div>
            </div>
          </div>
        </div>
      )}

      <div
        className="container-fluid"
        id="Popups-three"
        style={{ display: "none" }}
      >
        <div className="row">
          <div
            className="unsubscribe_box col-md-12"
            style={{ textAlign: "left" }}
          >
            <h3 style={{ fontSize: "60px" }}>Unsubscribe Successful</h3>
            <p>You will no longer receive the daily newsletter from us</p>
            <p style={{ marginTop: "25px" }}>
              If you have a moment, do let us know why you unsubscribed:
            </p>

            <p>
              <input type="radio" />
              Receive too many emails
            </p>
            <p>
              <input type="radio" />
              Not interested in structured warrants
            </p>
            <p>
              <input type="radio" />
              Not interested in the content of these emails
            </p>
            <p>
              <input type="radio" />I already subscribe to your Telegram and/or
              Facebook channels
            </p>
            <p>
              <input type="radio" />
              Other
            </p>
            <div style={{ marginTop: "40px", paddingBottom: "100px" }}>
              <button className="btn btn-primary Submit">Submit</button>
            </div>
            <div
              className="unsubscribe_box_img"
              style={{ textAlign: "center" }}
            >
              <span>
                <img
                  src={EARTH_IMG}
                  style={{ height: "80px", width: "80px" }}
                />
                <a
                  target="_black"
                  className="sharebtn-fb btn"
                  type="button"
                  href="https://www.facebook.com/MalaysiaWarrants/"
                  style={{ height: "50px", width: "50px" }}
                ></a>
                <a
                  target="_black"
                  className="sharebtn-tl btn"
                  type="button"
                  href="https://web.telegram.org/#/im?p=@Malaysiawarrants"
                  style={{ height: "50px", width: "50px", marginLeft: "10px" }}
                ></a>
              </span>
            </div>
          </div>
        </div>
      </div>

      <div
        className="container-fluid"
        id="Popups-four"
        style={{ display: "none" }}
      >
        <div className="row">
          <div className="unsubscribe_box col-md-12">
            <img
              src={UNSUBSCRIBE_IMG_2}
              style={{ marginBottom: "8px", height: "300px", width: "300px" }}
            />
            <h3>Thank you for your feedback</h3>
            <p>You may close this window now.</p>
            <div style={{ marginTop: "40px", paddingBottom: "100px" }}>
              <button className="btn btn-light" style={{ color: "#000" }}>
                Close
              </button>
            </div>
            <div className="unsubscribe_box_img">
              <span>
                <img
                  src={EARTH_IMG}
                  style={{ height: "80px", width: "80px" }}
                />
                <a
                  target="_black"
                  className="sharebtn-fb btn"
                  type="button"
                  href="https://www.facebook.com/MalaysiaWarrants/"
                  style={{ height: "50px", width: "50px" }}
                ></a>
                <a
                  target="_black"
                  className="sharebtn-tl btn"
                  type="button"
                  href="https://web.telegram.org/#/im?p=@Malaysiawarrants"
                  style={{ height: "50px", width: "50px", marginLeft: "10px" }}
                ></a>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UnSubscribe;
