import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { languageText } from "../../../languageText/common"
import QlinkAchor from "../../../assets/images/tips/qlink-achor.png";

const DwVideoSideBars = (props) => {

    const [siteLang , setSiteLang] = useState("")

    useEffect(() => {
        var siteLanguage = $.cookie('applang');
        setSiteLang(siteLanguage)
        if(siteLanguage == "eng"){
          document.querySelector("body").classList.add("eng")
        }else{
          document.querySelector("body").classList.remove("eng")
        }
    }, [])

    return(
        <div className="section qlink-section">
            <div className="page-header">
                <h2 className="small">{siteLang == "eng" ? languageText.dwVideoTitleEn : languageText.dwVideoTitle}</h2>
            </div>
            <p className="section-p">
                {siteLang == "eng" ? languageText.dwVideoDescriptionEn : languageText.dwVideoDescription}
            </p>
            <div className="section-qlink-img section-qlink-dwvideo">
                <Link
                    to="/education/dwvideo"
                    className="section-qlink-achor"
                >
                    <img src={QlinkAchor} alt="qlick" />
                </Link>
            </div>
        </div>
    )
};
export default DwVideoSideBars;