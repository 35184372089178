export const languageText ={
    
    firstNavigation: "รอบรู้เรื่อง DW ",
    firstNavigationEn: "DW education ",
    secondNavigation: " Index DW",
    secondNavigationEn: " Index DW",

    pageTitle: "Index DW",
    pageTitleEn: "Index DW",
    pageDescription: "บทความสรุปเกี่ยวกับ index DW",
    pageDescriptionEn: "What is an index DW and why is it so popular?",

    leadingIssuerTitle: "DW28 หนึ่งในผู้นำบน SET50",
    leadingIssuerTitleEn: "One of the leading issuers in SET50",
    leadingIssuerDescription: "แมคควอรี DW28 เป็นผู้ออกชั้นนำบน SET50 DW โดยมีส่วนแบ่งตลาด (Market share) 34% ของปริมาณซื้อขายของปี 2559 เป็นผลมาจากปัจจัยสามอย่างนี้",
    leadingIssuerDescriptionEn: "DW28 is one of the top issuers in SET50DW, with more than 34% of the traded volume for the year ended 2016. We believe this is due to three main factors:",
    leadingIssuer1Li: "ระบบดูและสภาพคล่องชั้นเยี่ยม ที่สามารถทำให้คุณซื้อหรือขาย DW ได้ง่าย",
    leadingIssuer1LiEn: "Our State of the art market making system is able to provide greater liquidity.",
    leadingIssuer2Li: "มี DW ที่หลากหลายเพื่อตอบโจทย์ความต้องการของนักลงทุน",
    leadingIssuer2LiEn: "We provide wider choice of DW investors.",
    leadingIssuer3Li: "เครื่องมือ “ตารางราคา” รูปแบบ Live ที่ดีที่สุดในประเทศไทย",
    leadingIssuer3LiEn: "Our live “Price Matrix” tool is the best in Thailand",

    liveMatrixTitle: "ตารางราคา",
    liveMatrixTitleEn: "Live Matrix",
    liveMatrixDescription: "ตารางราคา DW ของ แมคควอรี DW28 มีความถูกต้องแม่นยำมากที่สุดในประทศไทย เนื่องจากเป็นตารางราคาที่เป็น real time ส่งตรงจากระบบผู้ดูแลสภาพคล่อง<br><br>ตารางราคาของผู้ออกรายอื่นๆ จะแสดงตารางราคาและจุดเปลี่ยนราคาโดยประมาณเท่านั้น ขณะที่ตารางราคาของ SET50 DW28 จะบอกจุดที่แน่นอน ที่ทำให้ราคา DW เปลี่ยนแปลง ซึ่งเป็นจุดเด่นและตัวช่วยที่สำคัญในการซื้อขายของนักลงทุน<br><br>ค้นหาตารางราคา DW ของคุณ&nbsp; <a class='linkbtn' href='/tools/livematrix'>คลิกที่นี่</a>",
    liveMatrixDescriptionEn: 'Macquarie’s live “Price Matrix” is the most accurate in the Thailand market, because it is the only matrix which has a direct “live” link to our market making system.<br><br>Unlike other issuers, who only show an ‘approximate’ price change, Macquarie’s matrix will show the exact level in the SET50 index where the warrant price will change.<br>Providing a trading edge for investors.<br><br>Find your DW live price matrix&nbsp; <a class="linkbtn" href="/tools/livematrix">Click here</a>',

    whatSet50Title: "SET50 DW คืออะไร?",
    whatSet50TitleEn: "What is a SET50 DW?",
    whatSet50Description: 'SET50 DW คือ ใบสำคัญแสดงสิทธิอนุพันธ์ (DW) ที่ช่วยให้นักลงทุนสามารถลงทุนโดยวิเคราะห์แนวโน้มความเคลื่อนไหวของดัชนี SET50 ได้ ซึ่งให้โอกาสในการรับผลตอบแทนเสมือนลงทุนในตลาดหลักทรัพย์แห่งประเทศไทย ทั้งนี้ดัชนี SET50 นั้นประกอบด้วยหลักทรัพย์จดทะเบียนไทยที่มีมูลค่าหลักทรัพย์สูงสุด 50 อันดับแรก<br><br>ในระหว่างวัน SET50 DW จะเคลื่อนไหวเปรียบเทียบกับสัญญาซื้อขายล่วงหน้า (SET50 Futures) โดยนักลงทุนสามารถตรวจสอบรุ่นที่อ้างอิงได้ที่หน้าตารางราคา<br><br>&nbsp; <a class="linkbtn" href="/tools/dwranking/SET50">คลิกที่นี่</a>  &nbsp;เพื่อดู SET50 DW ที่ออกโดย แมคควอรี DW28',
    whatSet50DescriptionEn: 'A SET50 DW is a Derivative Warrant (DW) that allows investors to take a view on the SET50 index. The SET50 is comprised of the top 50 listed companies listed in Thailand, giving investors exposure to the movements in the market as a whole.<br><br>During each trading day, the SET50 DW price will move along with the SET50 Futures. The relevant SET50 Futures is highlighted at the top of Macquarie’s Price Matrix.<br><br>&nbsp; <a class="linkbtn" href="/tools/dwranking/SET50">Click here</a>  &nbsp;to view the most popular SET50 DW currently listed by Macquarie',
    whatSet50DescriptionMob: '<p> SET50 DW คือ ใบสำคัญแสดงสิทธิอนุพันธ์ (DW) ที่ช่วยให้นักลงทุนสามารถลงทุนโดยวิเคราะห์แนวโน้มความเคลื่อนไหวของดัชนี SET50 ได้ ซึ่งให้โอกาสในการรับผลตอบแทนเสมือนลงทุนในตลาดหลักทรัพย์แห่งประเทศไทย ทั้งนี้ดัชนี SET50 นั้นประกอบด้วยหลักทรัพย์จดทะเบียนไทยที่มีมูลค่าหลักทรัพย์สูงสุด 50 อันดับแรก<br><br>ในระหว่างวัน SET50 DW จะเคลื่อนไหวเปรียบเทียบกับสัญญาซื้อขายล่วงหน้า (SET50 Futures) โดยนักลงทุนสามารถตรวจสอบรุ่นที่อ้างอิงได้ที่หน้าตารางราคา<br><br> </p> <a href="/tools/dwranking/SET50">คลิกที่นี่</a>',
    whatSet50DescriptionMobEn: '<p> A SET50 DW is a Derivative Warrant (DW) that allows investors to take a view on the SET50 index. The SET50 is comprised of the top 50 listed companies listed in Thailand, giving investors exposure to the movements in the market as a whole.<br><br>During each trading day, the SET50 DW price will move along with the SET50 Futures. The relevant SET50 Futures is highlighted at the top of Macquarie’s Price Matrix.<br><br> </p> <a href="/tools/dwranking/SET50">Click here</a>',
    
    whyInvestTitle: "ทำไมต้องลงทุนใน SET50 DW?",
    whyInvestTitleEn: "Why invest in SET50 DWs?",
    whyInvest1Li: "SET50 DW ช่วยให้นักลงทุนใช้การวิเคราะห์แนวโน้มความเคลื่อนไหวของตลาด (Market view) โดยไม่ต้องเลือกวิเคราะห์หุ้นเป็นรายตัว",
    whyInvest1LiEn: "Allows investors to take a view on the market rather than having to choose a particular stock.",
    whyInvest2Li: "SET50 DW เป็นผลิตภัณฑ์ทางการเงินที่มีอัตราทด (Gearing) และต่างจากการซื้อขายสัญญาซื้อขายล่วงหน้า (SET50 Index Futures) ตรงที่ไม่ต้องมีการวางหลักประกัน",
    whyInvest2LiEn: "Provide gearing with no margin calls, unlike SET index futures.",
    whyInvest3Li: "DW ช่วยให้นักลงทุนสามารถทำกำไรได้ทั้งตลาดขาขึ้นและขาลง โดยการซื้อ Call SET50 DW เมื่อมองว่าตลาดมีแนวโน้มจะปรับตัวขึ้น และซื้อ Put SET50 DW เมื่อมองว่าตลาดมีแนวโน้มจะปรับตัวลง",
    whyInvest3LiEn: "Enable you to profit from both a rise and fall in the market. If you think the market is going up, buy a call. Think it is falling, buy a put.",
    whyInvest4Li: "แมคควอรีมุ่งเน้นการให้บริการแก่นักลงทุนในด้านการสร้างสภาพคล่องที่สูงและวาง Bid และ Offer ชิด เพื่อทำให้นักลงทุนสามารถเทรด DW28 ได้ง่ายขึ้น",
    whyInvest4LiEn: "Macquarie aim to always provide high liquidity and tight spreads for investors to easily enter and exit their DW28 trades.",

    howTradeTitle: "เริ่มต้นเทรด SET50 DW ต้องทำยังไง?",
    howTradeTitleEn: "How to trade SET50 DW?",
    howTradeDescription: 'SET50 DW เป็นหลักทรัพย์จดทะเบียนในตลาดหลักทรัพย์ ดังนั้น สามารถซื้อขายบนกระดานผ่านโบรกเกอร์ของนักลงทุนได้เสมือนการซื้อขายหุ้น ยกตัวอย่างเช่น หากนักลงทุนมีมุมมองว่าดัชนี SET50 จะปรับตัวเพิ่มขึ้น และต้องการเพิ่มโอกาสในการรับผลตอบแทนที่สูงขึ้น นักลงทุนสามารถซื้อ Call SET50 DW<br><br>จากตัวอย่างข้างต้น นักลงทุนซื้อ Call DW “S5028C1406A” ในวันที่ 2 เมษายน และขายในวันที่ 29 เมษายน',
    howTradeDescriptionEn: 'SET50 DW are listed on the Stock Exchange of Thailand, so you can buy and sell them through your broker, just like shares. For example, if you have a bullish view on the SET50 Index and want to take a leveraged position, you can buy a SET50 DW Call.<br><br>In the below hypothetical example, an investor buys the call DW S5028C1406A on 2 April and sells at a profit on 29 April.',
    howTradeDescriptionRight: 'เห็นได้ว่านักลงทุนที่ลงทุนใน SET50 DW ซึ่งได้รับประโยชน์จากอัตราทด จะได้รับผลตอบแทนสูงถึง 24.7% ในขณะที่ดัชนี SET50 Futures ปรับตัวเพิ่มขึ้นเพียง 3.4% เท่านั้น อย่างไรก็ดี อัตราทดนั้นจะทำงานในทิศทางตรงกันข้ามในกรณีที่ตลาดปรับตัวลดลง ด้วยเช่นกัน',
    howTradeDescriptionRightEn: 'Due to the benefits of gearing, the DW increases by 24.7% with only a 3.4% move in the SET50 Futures. Do note however that leverage works both ways and price movements on the downside will also be leveraged.',

    learnMoreTitle: "เรียนรู้ DW เพิ่มเติม",
    learnMoreTitleEn: "LEARN MORE",
    learnMoreButton: "คลิกที่นี่",
    learnMoreButtonEn: "Click here",
    
    marketDelay: "ข้อมูลตลาดทั้งหมดล่าช้าประมาณ 15 นาที",
    marketDelayEn: "All market data is delayed 15 mins.",
}