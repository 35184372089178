export const languageText ={

    pageTitle: "ตัวแปรเกี่ยวกับ DW ที่ควรรู้",
    pageTitleEn: "Useful DW terms",

    pageName: "Premium หรือ Time Value",
    pageNameEn: "Premium",

    pageDescription: "มูลค่าเวลา (Time Value) คือต้นทุนในการซื้อหุ้นอ้างอิงจากการใช้สิทธิ DW ส่วนที่เกินมาจากต้นทุนในการซื้อหุ้นอ้างอิงโดยตรง มูลค่าเวลาจะลดลงจนเหลือ 0 ณ วันครบกำหนดอายุ",
    pageDescriptionEn: 'Time Value is the amount by which the cost of acquiring the underlying asset by exercising the DW exceeds the cost of buying the underlying asset directly. Time Value will decay to equal zero at expiry.',

    exampleBoxHeading: "ตัวอย่างของ Call DW:",
    exampleBoxHeadingEn: "Example of a Call DW:",
    exampleBoxExercisePrice: "Exercise Price:",
    exampleBoxExercisePriceEn: "Exercise Price:",
    exampleBoxExercisePriceResult: "32.00 บาท",
    exampleBoxExercisePriceResultEn: "THB 32.00",
    exampleBoxDwPrice: "DW Price:",
    exampleBoxDwPriceEn: "DW Price:",
    exampleBoxDwPriceResult: "1.20 บาท",
    exampleBoxDwPriceResultEn: "THB 1.20",
    exampleBoxDwps: "DWPS:",
    exampleBoxDwpsEn: "DWPS:",
    exampleBoxDwpsResult: "4",
    exampleBoxDwpsResultEn: "4",
    exampleBoxUnderlying: "ราคาหุ้นอ้างอิง:",
    exampleBoxUnderlyingEn: "Underlying Price:",
    exampleBoxUnderlyingResult: "34.25 บาท",
    exampleBoxUnderlyingResultEn: "THB 34.25",

    formulaHolderLeft: "ต้นทุนในการซื้อหุ้นจากการซื้อ DW ",
    formulaHolderLeftEn: "Cost of buying the share via DW ",
    formulaHolderRightFirst: " (ราคา DW x DWPS) + ราคาใช้สิทธิ",
    formulaHolderRightFirstEn: " (CW Price x DWPS) + Exercise Price",
    formulaHolderRightSecond: " (1.20 บาท x 4) + 32 บาท",
    formulaHolderRightSecondEn: " (THB 1.20 x 4) + THB 32.00",
    formulaHolderRightThird: " 36.80 บาท (จุดคุ้มทุน ณ วันครบกำหนดอายุ)",
    formulaHolderRightThirdEn: " THB 36.80",

    formulaHolderLeft1: "ต้นทุนในการซื้อหุ้นโดยตรง ",
    formulaHolderLeft1En: "Cost of buying the share directly ",
    formulaHolderRight1First: " ราคาหุ้น",
    formulaHolderRight1FirstEn: " Share Price",
    formulaHolderRight1Second: " 34.25 บาท",
    formulaHolderRight1SecondEn: " THB 34.25",

    formulaHolderLeft2: "ส่วนต่าง ",
    formulaHolderLeft2En: "Difference ",
    formulaHolderRight2First: " 36.80 บาท – 34.25 บาท",
    formulaHolderRight2FirstEn: " THB 36.80 - THB 34.25",
    formulaHolderRight2Second: " มุลค่าเวลา 2.55 บาท (",
    formulaHolderRight2SecondEn: " THB 2.55 Time Value (",
    formulaHolderRight22Second: " of ของราคาหุ้นอ้างอิง)",
    formulaHolderRight22SecondEn: " of Share Price)",

    tipHolderHeading: "เคล็ดลับในการใช้ค่า Premium",
    tipHolderHeadingEn: "Helpful tips on how to use Premium",
    tipHolderParagraph1: "การมีมูลค่าเวลาสูงๆ ไม่ได้หมายความว่า DW นั้นแพงกว่ารุ่นอื่น โดยทั่วไป DW ที่มีอายุคงเหลือมาก จะมีมูลค่าเวลาสูงกว่า DW ที่มีอายุคงเหลือน้อย และยังมี gearing สูงกว่าอีกด้วย",
    tipHolderParagraph1En: "Having a higher Time Value level does not mean that one DW is necessarily more ‘expensive’ than another. Time Value will be higher for DW that are longer dated and also for DW with higher gearing levels.",
    tipHolderParagraph2: "มูลค่าเวลาไม่ได้เป็นตัวชี้วัดที่นำมาใช้เพื่อเปรียบเทียบความสัมพันธ์ของราคา DW รุ่นหนึ่งกับรุ่นอื่นๆ ถ้าต้องการเปรียบเทียบ DW ที่เงื่อนไขเหมือนกัน เพื่อดูว่ารุ่นใดราคาถูกกว่า คุณควรจะพิจารณาจาก",
    tipHolderParagraph2En: "Time Value is not the best indicator to use to compare the relative price of one DW over another. When comparing similar DW, to determine which is cheaper, you should use",
    tipHolderParagraph2Link: " ค่าความผันผวนแฝง (Implied Volatility)",
    tipHolderParagraph2LinkEn: " implied volatility",
    tipHolderParagraph3: "มูลค่าเวลาเป็นประโยชน์ในการใช้ประเมินอัตรา Time Decay ตลอดช่วงอายุของ DW ตัวอย่างเช่น ถ้า DW มีมูลค่าเวลาเท่ากับ 0.20 บาท และมีอายุคงเหลือ 2 เดือน คุณสามารถตั้งสมมติฐานได้ว่า (ถ้ากำหนดให้ตัวแปรอื่นๆคงที่) DW จะลดลงประมาณ 0.10 บาทต่อเดือน ในช่วง 2 เดือนข้างหน้า อย่างไรก็ตาม การลดลงจะไม่ได้เป็นเส้นตรง แต่จะเร็วขึ้นเมื่อใกล้ครบกำหนดอายุ คุณสามารถอ่านรายละเอียดของ",
    tipHolderParagraph3En: "Time Value is most useful for estimating the amount of time decay that a DW will experience over its life. Say for example, a DW has a premium amount of MYR0.20 and it has 2 months left to expiry. You can then assume that (assuming all other factors are constant) the DW will decay in approx. THB 0.10 per month over the next 2 months. This however will not happen in a straight line and will speed up as it approaches expiry,",
    tipHolderParagraph3Link: " Time Decay ",
    tipHolderParagraph3LinkEn: " click here ",
    tipHolderParagraph32: "ได้โดยคลิกที่นี่",
    tipHolderParagraph32En: "to read more about time decay.",
    tipHolderParagraph4: "นักลงทุนสามารถหามูลค่าเวลาของ DW ทั้งหมดได้จากหน้า",
    tipHolderParagraph4En: "You can view the time value for all DW via the",
    tipHolderParagraph4Link: " ค้นหา DW  ",
    tipHolderParagraph4LinkEn: " DW search tool ",
    tipHolderParagraph42: "และ",
    tipHolderParagraph42En: "and the",
    tipHolderParagraph42Link: " ข้อมูล DW ",
    tipHolderParagraph42LinkEn: " DW Terms ",

}