import React, { useState, useEffect,Fragment } from 'react';
import Premiums from './Premiums';
import "../../common.css";
import "../../investorAcademy.css";


const Premium = (props) => {
    return ( 
        <Fragment> 
            <Premiums/>
        </Fragment> 
     );
}


export default Premium;