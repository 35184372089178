import React, { Fragment } from 'react'
import SeaSon3 from './SeaSon3'
import "./season3.css";

const Season3 = () => {
  return (
    <Fragment>
      <SeaSon3/>
    </Fragment>
  )
}

export default Season3