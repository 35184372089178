import React, { Fragment } from 'react';
import DwCompare from './DwCompare';
import "./warrantComparison.css" 


const DwCompares = (props) => {
    return ( 
        <Fragment>
            <DwCompare/>
        </Fragment> 
     );
}
 

export default DwCompares;