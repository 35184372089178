import React, { useState, useEffect,Fragment } from 'react';
import SelectingWarrants from './SelectingWarrants';
import "../../common.css";
import "../../investorAcademy.css";


const SelectingWarrant = (props) => {
    return ( 
        <Fragment> 
            <SelectingWarrants/>
        </Fragment> 
     );
}


export default SelectingWarrant;