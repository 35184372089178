import React, { useState, useEffect } from "react";
import "./warrantComparison.css";
import Select from "react-select";
import { get, isArray } from "lodash";
import API from "../../../utils/apiCalling";
import { config } from "../../../utils/apiUrl";
import { Link } from "react-router-dom";
import tableConfig from "./comparisonConfig";
import ReactTooltip from "react-tooltip";
import WarrantComparsionModal from "../../../components/common/WarrantComparsionModal";
import { faSortDown } from "@fortawesome/free-solid-svg-icons";
import { faSortUp } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import LoaderSpin from "../../../components/common/LoaderSpin";
import LiveMatrixSideBars from "../../../components/common/Sidebar/LiveMatrixSideBar"
import WarrantSearchSideBars from "../../../components/common/Sidebar/WarrantSearchSideBar"
import DwTermsSideBars from "../../../components/common/Sidebar/DwTermsSideBar"
import AdvertiseMentSidebar from "../../../components/common/Sidebar/AdvertiseMentSidebar"
import tableConfigEn from "./comparisonConfigEn"
import { languageText } from "../../../languageText/dwCompare";

const DwCompare = (props) => {

  const api = new API();
  const [symbolList, setSymbolList] = useState([]);
  const [compareList, setCompareList] = useState([]);
  const [newRic, setNewRic] = useState("");
  const [ricValue, setRicValue] = useState();
  const [liveMatrixSelectedName, setLiveMatrixSelectedName] = useState("");
  const [qid, setQid] = useState(Date.now());
  const [open, setOpen] = useState(false);
  const [collapse, setCollapse] = useState(false);
  const [url, setUrl] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [cloneArr,setCloneArr] = useState([])
  const [load,setLoad] = useState(false)
  const [boxVisibility, setBoxVisibility] = useState("hide");
  const [siteLang , setSiteLang] = useState("")

  useEffect(() => {
    window.scrollTo(0, 0)
    var siteLanguage = $.cookie('applang');
    setSiteLang(siteLanguage)
    if(siteLanguage == "eng"){
      document.querySelector("body").classList.add("eng")
    }else{
      document.querySelector("body").classList.remove("eng")
    }
    getWarrantComparisonsSelect();
  }, [])

  useEffect(() => {
    if (ricValue) {
      getComparisonsData();
    }
  }, [ricValue]);
  
  const showNewBox = () => {
      if(boxVisibility === "hide"){
        setBoxVisibility("show")
      }else if(boxVisibility === "show"){
        setBoxVisibility("hide")
      }else{
        setBoxVisibility("hide")
      }
  }

  const getWarrantComparisonsSelect = async () => {
    let data = {
      mode: 2,
      qid,
    };
    let warrantComparisonsResult = await api.get(
      config.dwRanking,
      true,
      data
    );
    let comparisonsHLListRes = get(warrantComparisonsResult, "hllist", []);
    let comparisonsListRes = get(warrantComparisonsResult, "list", []);
    const liveIndexSymbolData = comparisonsListRes.map(
      ({
        underlying_ticker,
        underlying_name,
        underlying_ticker: value,
        underlying_ric,
      }) => ({
        label: `${underlying_ticker == "9988" ? underlying_name = "ALIBABA (9988)" 
                : underlying_ticker == "0700" ? underlying_name = "TENCENT (0700)"
                : underlying_ticker == "700" ? underlying_name = "TENCENT (0700)"
                : underlying_ticker == "3690" ? underlying_name = "MEITUAN (3690)"
                : underlying_ticker == "1810" ? underlying_name = "XIAOMI (1810)"
                : underlying_ticker == "2333" ? underlying_name = "GREAT WALL MOTOR (2333)"
                : underlying_ticker == "9618" ? underlying_name = "JD.COM (9618)"
                : underlying_ticker == "0175" ? underlying_name = "GEELY (0175)"
                : underlying_ticker == "175" ? underlying_name = "GEELY (0175)"
                : underlying_ticker == "1024" ? underlying_name = "KUAISHOU TECHNOLOGY (1024)"
                : underlying_ticker == "1211" ? underlying_name = "BYD (1211)"
                : underlying_ticker == "2318" ? underlying_name = "PING AN (2318)"
                : underlying_ticker == "2382" ? underlying_name = "SUNNY OPTICAL TECH (2382)"
                : underlying_ticker == "9626" ? underlying_name = "BILIBILI (9626)"
                : underlying_ticker == "9888" ? underlying_name = "BAIDU (9888)"
                : underlying_ticker == "6690" ? underlying_name = "HAIER SMART HOME (6690)"
                : underlying_ticker == "1398" ? underlying_name = "ICBC (1398)"
                : underlying_ticker == "0005" ? underlying_name = "HSBC (0005)"
                : underlying_ticker == "005" ? underlying_name = "HSBC (0005)"
                : underlying_ticker == "05" ? underlying_name = "HSBC (0005)"
                : underlying_ticker == "5" ? underlying_name = "HSBC (0005)"
                : underlying_ticker == "1299" ? underlying_name = "AIA (1299)"
                : underlying_ticker == "1919" ? underlying_name = "COSCO SHIPPING (1919)"
                : underlying_ticker == "2269" ? underlying_name = "WUXI BIOLOGICS (2269)"
                : underlying_ticker == "0941" ? underlying_name = "CHINA MOBILE (0941)"
                : underlying_ticker == "941" ? underlying_name = "CHINA MOBILE (0941)"
                : underlying_ticker == "0388" ? underlying_name = "HKEX (0388)"
                : underlying_ticker == "388" ? underlying_name = "HKEX (0388)"
                : underlying_ticker == "1772" ? underlying_name = "GANFEN LITHIUM (1772)"
                : underlying_ticker == "2899" ? underlying_name = "ZIJIN (2899)"
                : underlying_ticker == "6862" ? underlying_name = "HAIDILAO (6862)"
                : underlying_ticker == "293" ? underlying_name = "CATHAY (293)"
                : underlying_ticker == "0883" ? underlying_name = "CNOOC (0883)"
                : underlying_ticker == "883" ? underlying_name = "CNOOC (0883)"
                : underlying_ticker == "0992" ? underlying_name = "LENOVO (0992)"
                : underlying_ticker == "992" ? underlying_name = "LENOVO (0992)"
                : underlying_ticker == "241" ? underlying_name = "ALIHEALTH (241)"
                : underlying_ticker}`,
        value,
        underlying_ticker,
        underlying_ric,
      })
    );
    setSymbolList(liveIndexSymbolData);
  };

  const getComparisonsData = async (e, index) => {
    setIsLoading(true);
    setLoad(true)
    let data = {
      type: "u",
      ric: ricValue,
      qid:qid
    };

    let LiveMatrixResult = await api.get(config.warrantComparison, true, data);
    let comparisonsHLListRes = get(LiveMatrixResult, "comparison", []);
    let tempArr =
      comparisonsHLListRes.length > 0 &&
      comparisonsHLListRes
        .splice(1, comparisonsHLListRes.length)
        .map((item) => ({ ...item, checked: false }));
    setIsLoading(false);
    setLoad(false)
    //setCollapse(tempArr.length > 0);
    setCollapse(true)
    setCompareList(tempArr);
    setCloneArr([])
  };

  const getSelectedValue = (e) => {
    const { value, label, underlying_ric } = e;
    setNewRic(underlying_ric);
    setRicValue(underlying_ric);
    setLiveMatrixSelectedName(label);
    setCompareList([]);
    setCollapse(false);
    //getComparisonsData();
    
  };

  const onClickPopHandle = (url, value) => {
    setOpen(value);
    setUrl(url);
  };

  const renderCount = () => {
    let count = 0;
    Array.isArray(compareList) &&
      compareList.forEach((element) => {
        if (element.checked) {
          count += 1;
        }
      });
    return count;
  };

  const changeCheck = (selectedIndex,value) => {
    var status = compareList[selectedIndex].checked
    if (compareList.filter(({ checked }) => checked).length < 5) {
      let tempArr = [...compareList];
      var status = tempArr[selectedIndex].checked
      tempArr[selectedIndex].checked = !tempArr[selectedIndex].checked;
      var tempArr1=!status ? [...cloneArr,tempArr[selectedIndex]] : cloneArr.filter((d)=>d.ticker != value)
      setCloneArr(tempArr1)
    } 
    else {
      if(compareList[selectedIndex].checked === true){
        let tempArr = [...compareList];
      var status = tempArr[selectedIndex].checked
      tempArr[selectedIndex].checked = !tempArr[selectedIndex].checked;
      var tempArr1=!status ? [...cloneArr,tempArr[selectedIndex]] : cloneArr.filter((d)=>d.ticker != value)
      setCloneArr(tempArr1)
      }else{
        alert("You can only select up to five options");
      }
    }
  };

  const WarrantComparisonTable = (props) => {
    const getComparisonRow = () => {
      if (props.config != null) {
        // for each table field/column
        if ("fields" in props.config) {
          const rows = props.config["fields"].map((field, fieldIndex) => {
            const columns = [];

            // header
            const tooltipID = field.className + "-tooltip";
            const columnHeader = (
              <th key={field.name}>
                <span data-tip data-for={tooltipID} className="ttip">
                  {field.name}
                </span>
                <ReactTooltip
                  id={tooltipID}
                  delayHide={100}
                  place="right"
                  type="warning"
                  effect="solid"
                >
                  {field.tooltip()}
                </ReactTooltip>
              </th>
            );
            columns.push(columnHeader);

            // data
            const warrantdata = props.warrantdata;
            if (warrantdata != null && warrantdata.length > 0) {
              warrantdata.forEach((warrant, dataIndex) => {
                columns.push(field.columnData(warrant, dataIndex));
              });
            }
            const row = (
              <tr key={field.className} className={field.className}>
                {columns}
              </tr>
            );
            return row;
          });
          return rows;
        }
      }

      return null;
    };

    return (
      <div id="comparetbl">
        <table className="table table-striped">
          <tbody>{getComparisonRow()}</tbody>
        </table>
      </div>
    );
  };

  const getSelectedWarrantData = () => {
    let warrantDataSelected = [];
          Array.isArray(cloneArr) &&
          cloneArr
            .filter(({ checked }) => checked)
            .forEach((ticker) => {
              const warrantdata = cloneArr.find((data) => {
                return data.ticker;
              });
          if (typeof warrantdata != "undefined") {
            warrantdata["label"] = liveMatrixSelectedName;
            warrantDataSelected.push(ticker);
          }
        });
    return warrantDataSelected;
  };

  const colourStyles = {
    control: (provided) => ({
      ...provided,
      display:"flex",
      alignItems:"center",
      justifyContent:"center",
      textAlign: 'center', // Center-align the text input
    }),
    input:(provided)=>({
      ...provided,
      width:"100%",
      display:"flex "
    }),
    singleValue: (provided) => ({
      ...provided,
      textAlign: 'center', // Center-align the selected value
    }),
    option: (styles, { isFocused }) => {
      return {
        ...styles,
        backgroundColor: isFocused ? "#007fff" : null,
        color: "#333333",
        color: isFocused ? "#fff" : null,
        textAlign: "center",
        fontSize: siteLang == "eng" ? "14px" : "21px",
        zIndex: "1000",
        padding: "3px 10px",
        fontFamily:
          siteLang == "eng"
            ? "'MCQGlobal_A_Lt','MCQGlobal_A_Bd','Roboto', Arial, sans-serif"
            : "'DBHeaventLight','DBHeavent'",
        cursor: "pointer",
      };
    },
  };

  const WarrantSummary = () => {
    let panelCollapseClass = "panel-collapse collapse";
    let faTriangle = faSortUp;
    let className = "result-sort-up";
    if (!collapse) {
      panelCollapseClass += " in";
      faTriangle = faSortDown;
      className = "result-sort-down";
    }
    const disable = () => {
      if (compareList.length === 0) {
        return <div className="disabled-area"></div>;
      }
      return <></>;
    };

    const clickCollapse = () => {
      if (collapse && compareList.length > 0) {
        setCollapse(false);
      }
      else setCollapse(true)
    };

    return (
      <div className={`panel-group ${!collapse ? "outer" : "in"}`} id="accordion">
        <div className="panel panel-default">
          <div className="panel-heading">
            {disable()}
            <div onClick={clickCollapse}>
              <h4 id="collapse_title2" className="panel-title text-center">
              {siteLang == "eng" ? languageText.selectDwEn : languageText.selectDw}:  <span className="bold">{renderCount()}</span>
                <span className="arrow-icon">
                  <FontAwesomeIcon
                    icon={faTriangle}
                    onClick={clickCollapse}
                    className={className}
                  />
                </span>
              </h4>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const customFilter = (option, searchText) => {
    if(option.data.label.toLowerCase().startsWith(searchText.toLowerCase())) {
      return true;
    }else{
      return false;
    }
  }

  return (
    <>
      <div id="dwcompare" className="pageobj">
        <div className="breadcrumbbar">
          <div className="container-fluid">
            <ol className="breadcrumb">
              <li>
                <Link to="/home">{siteLang == "eng" ? languageText.firstNavigationEn : languageText.firstNavigation}</Link>
              </li>
              <li className="active">{siteLang == "eng" ? languageText.secondNavigationEn : languageText.secondNavigation}</li>
            </ol>
          </div>
        </div>
        <div className="stripe-bg" />
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-8 sm-w-100">
              <div className="section">
                <div className="page-header">
                  <h1>{siteLang == "eng" ? languageText.pageTitleEn : languageText.pageTitle}</h1>
                </div>
                <p>{siteLang == "eng" ? languageText.pageDescriptionEn : languageText.pageDescription}</p>
                <p className="small">{siteLang == "eng" ? languageText.pageDescription1En : languageText.pageDescription1}</p>
                <Select
                  className="lmcombobox select_align_center"
                  placeholder={"Select underlying"} 
                  onChange={(e) => getSelectedValue(e)}
                  options={symbolList}
                  styles={colourStyles}
                  isSearchable={true}
                  filterOption={customFilter}
                />
                <WarrantSummary />
                {collapse &&
                <div className="table-outer">
                  <table className="table table-striped">
                    <tbody>
                      <tr>
                        <th className="text-center">{siteLang == "eng" ? languageText.outerTableThSymbolEn : languageText.outerTableThSymbol}</th>
                        <th className="text-center">{siteLang == "eng" ? languageText.outerTableThExercisePriceEn : languageText.outerTableThExercisePrice}</th>
                        <th className="text-center">{siteLang == "eng" ? languageText.outerTableThEffectiveGearingEn : languageText.outerTableThEffectiveGearing}</th>
                        <th className="text-center">{siteLang == "eng" ? languageText.outerTableThSensitivityEn : languageText.outerTableThSensitivity}</th>
                      </tr>
                      {load &&  <div className="loader-div warrentComp_Loader">
                        <LoaderSpin isLoading={load} isVisible />
                      </div>}
                      {Array.isArray(compareList) &&
                        compareList.map(
                          ({ticker, ric, exercise_price, sensitivity, checked, effective_gearing}, index) => (
                            <tr className="data-row" key={`#-${index}`}>
                              <td>
                                <div className="checkbox">
                                  <label>
                                    <span>
                                      <input
                                        className="float-left position-unset"
                                        type="checkbox"
                                        checked={checked}
                                        onClick={() => {changeCheck(index,ticker,ric)}}
                                        onChange={e => {}}
                                      />
                                    </span>
                                    <Link
                                      to={`/tools/livematrix/${ticker}`}
                                      className="color-hyperlink"
                                    >
                                      {ticker}
                                    </Link>
                                  </label>
                                </div>
                              </td>
                              <td className="textCenter">{exercise_price}</td>
                              <td className="textCenter">{effective_gearing}</td>
                              <td className="textCenter">{sensitivity}</td>
                            </tr>
                          )
                        )}
                    </tbody>
                  </table>
                </div>}
                <div id="orientationcontainer" className="show-xs-portrait">
                  <img src="/img/bullet/rotate.png" />
                  <span style={{ marginLeft: "10px" }}>
                    Rotate to view more
                  </span>
                </div>
                {siteLang == "eng" ?
                  <WarrantComparisonTable
                    config={tableConfigEn}
                    warrantdata={getSelectedWarrantData()}
                  /> :
                  <WarrantComparisonTable
                    config={tableConfig}
                    warrantdata={getSelectedWarrantData()}
                  />
                }
                <p className="hidden-xs hidden-sm small">
                  {siteLang == "eng" ? languageText.importantNoteEn : languageText.importantNote}
                </p>
                <hr className="hr-hmgbm" />
                <p className="small">
                  {siteLang == "eng" ? languageText.marketDelayEn : languageText.marketDelay}
                </p>
                <div className="panel-group visible-xs-block visible-sm-block" id="defination_comparision">
                  <div className="panel panel-default">
                    <div className="panel-heading">
                      <button type="button" onClick={() => showNewBox()}>
                        <span className="glyphicon glyphicon glyphicon-question-sign pull-right" />
                        <div id="collapse_title3" className="panel-title text-center color-primary">
                          <span>Definition &amp; disclaimer</span>
                          <img src="/img/bullet/question.png" alt="icon image" />
                        </div>
                      </button>
                    </div>
                    <div
                      id="collapse_dwcompare3"
                      className={`${boxVisibility}`}
                      aria-expanded="true"
                    >
                      <table className="table table-striped">
                        <tbody>
                          <tr>
                            <td>{siteLang == "eng" ? languageText.symbolTitleEn : languageText.symbolTitle}</td>
                            <td dangerouslySetInnerHTML={{__html: siteLang == "eng" ? languageText.symbolTooltipEn : languageText.symbolTooltip}} />
                          </tr>
                          <tr>
                            <td>{siteLang == "eng" ? languageText.issuerTitleEn : languageText.issuerTitle}</td>
                            <td dangerouslySetInnerHTML={{__html: siteLang == "eng" ? languageText.issuerTooltipEn : languageText.issuerTooltip}} />
                          </tr>
                          <tr>
                            <td>{siteLang == "eng" ? languageText.typeTitleEn : languageText.typeTitle}</td>
                            <td dangerouslySetInnerHTML={{__html: siteLang == "eng" ? languageText.typeTooltipEn : languageText.typeTooltip}} />
                          </tr>
                          <tr>
                            <td>{siteLang == "eng" ? languageText.exercisePriceTitleEn : languageText.exercisePriceTitle}</td>
                            <td dangerouslySetInnerHTML={{__html: siteLang == "eng" ? languageText.exercisePriceTooltipEn : languageText.exercisePriceTooltip}} />
                          </tr>
                          <tr>
                            <td>{siteLang == "eng" ? languageText.lastTradingDateTitleEn : languageText.lastTradingDateTitle}</td>
                            <td dangerouslySetInnerHTML={{__html: siteLang == "eng" ? languageText.lastTradingDateTooltipEn : languageText.lastTradingDateTooltip}} />
                          </tr>
                          <tr>
                            <td>{siteLang == "eng" ? languageText.ratioTitleEn : languageText.ratioTitle}</td>
                            <td dangerouslySetInnerHTML={{__html: siteLang == "eng" ? languageText.ratioTooltipEn : languageText.ratioTooltip}} />
                          </tr>
                          <tr>
                            <td>{siteLang == "eng" ? languageText.lastTitleEn : languageText.lastTitle}</td>
                            <td dangerouslySetInnerHTML={{__html: siteLang == "eng" ? languageText.lastTooltipEn : languageText.lastTooltip}} />
                          </tr>
                          <tr>
                            <td>{siteLang == "eng" ? languageText.bidTitleEn : languageText.bidTitle}</td>
                            <td dangerouslySetInnerHTML={{__html: siteLang == "eng" ? languageText.bidTooltipEn : languageText.bidTooltip}} />
                          </tr>
                          <tr>
                            <td>{siteLang == "eng" ? languageText.askTitleEn : languageText.askTitle}</td>
                            <td dangerouslySetInnerHTML={{__html: siteLang == "eng" ? languageText.askTooltipEn : languageText.askTooltip}} />
                          </tr>
                          <tr>
                            <td>{siteLang == "eng" ? languageText.priorTitleEn : languageText.priorTitle}</td>
                            <td dangerouslySetInnerHTML={{__html: siteLang == "eng" ? languageText.priorTooltipEn : languageText.priorTooltip}} />
                          </tr>
                          <tr>
                            <td>{siteLang == "eng" ? languageText.changeTitleMobEn : languageText.changeTitleMob}</td>
                            <td dangerouslySetInnerHTML={{__html: siteLang == "eng" ? languageText.changeTooltipEn : languageText.changeTooltip}} />
                          </tr>
                          <tr>
                            <td>{siteLang == "eng" ? languageText.moneynessTitleEn : languageText.moneynessTitle}</td>
                            <td dangerouslySetInnerHTML={{__html: siteLang == "eng" ? languageText.moneynessTooltipEn : languageText.moneynessTooltip}} />
                          </tr>
                          <tr>
                            <td>{siteLang == "eng" ? languageText.underlyingBidPriceTitleEn : languageText.underlyingBidPriceTitle}</td>
                            <td dangerouslySetInnerHTML={{__html: siteLang == "eng" ? languageText.underlyingBidPriceTooltipEn : languageText.underlyingBidPriceTooltip}} />
                          </tr>
                          <tr>
                            <td>{siteLang == "eng" ? languageText.volumeTitleEn : languageText.volumeTitle}</td>
                            <td dangerouslySetInnerHTML={{__html: siteLang == "eng" ? languageText.volumeTooltipEn : languageText.volumeTooltip}} />
                          </tr>
                          <tr>
                            <td>{siteLang == "eng" ? languageText.effectiveGearingTitleEn : languageText.effectiveGearingTitle}</td>
                            <td dangerouslySetInnerHTML={{__html: siteLang == "eng" ? languageText.effectiveGearingTooltipEn : languageText.effectiveGearingTooltip}} />
                          </tr>
                          <tr>
                            <td>{siteLang == "eng" ? languageText.sensitivityTitleEn : languageText.sensitivityTitle}</td>
                            <td dangerouslySetInnerHTML={{__html: siteLang == "eng" ? languageText.sensitivityTooltipMobEn : languageText.sensitivityTooltipMob}} />
                          </tr>
                          <tr>
                            <td>{siteLang == "eng" ? languageText.deltaTitleMobEn : languageText.deltaTitleMob}</td>
                            <td dangerouslySetInnerHTML={{__html: siteLang == "eng" ? languageText.deltaTooltipEn : languageText.deltaTooltip}} />
                          </tr>
                          <tr>
                            <td>{siteLang == "eng" ? languageText.deltaPerDwTitleMobEn : languageText.deltaPerDwTitleMob}</td>
                            <td dangerouslySetInnerHTML={{__html: siteLang == "eng" ? languageText.deltaPerDwTooltipEn : languageText.deltaPerDwTooltip}} />
                          </tr>
                          <tr>
                            <td>{siteLang == "eng" ? languageText.premiumTitleMobEn : languageText.premiumTitleMob}</td>
                            <td dangerouslySetInnerHTML={{__html: siteLang == "eng" ? languageText.premiumTooltipEn : languageText.premiumTooltip}} />
                          </tr>
                          <tr>
                            <td>{siteLang == "eng" ? languageText.impliedVolatilityTitleEn : languageText.impliedVolatilityTitle}</td>
                            <td dangerouslySetInnerHTML={{__html: siteLang == "eng" ? languageText.impliedVolatilityTooltipEn : languageText.impliedVolatilityTooltip}} />
                          </tr>
                          <tr>
                            <td>{siteLang == "eng" ? "Theta" : "Theta"}</td>
                            <td dangerouslySetInnerHTML={{__html: siteLang == "eng" ? languageText.timeDecayTooltipEn : languageText.timeDecayTooltip}} />
                          </tr>
                        </tbody>
                      </table>
                      <p className="small">
                        {siteLang == "eng" ? languageText.importantNoteEn : languageText.importantNote}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-4 hidden-sm hidden-xs">
              <LiveMatrixSideBars />
              <WarrantSearchSideBars />
              <DwTermsSideBars />
              <AdvertiseMentSidebar />
            </div>
          </div>
        </div>
      </div>

      <WarrantComparsionModal
        Url={url}
        Open={open}
        OpenModal={() => setOpen(!true)}
      />
    </>
  );
};

export default DwCompare;
