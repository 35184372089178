import React, { useState, useEffect,Fragment } from 'react';
import ExerciseRatios from './ExerciseRatios';
import "../../common.css";
import "../../investorAcademy.css";


const ExerciseRatio = (props) => {
    return ( 
        <Fragment> 
            <ExerciseRatios/>
        </Fragment> 
     );
}


export default ExerciseRatio;