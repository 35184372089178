import React, { useState, useEffect } from "react";
import "../../common.css";
import "../../investorAcademy.css";
import { Link, useLocation, useHistory } from "react-router-dom";
import ChildInvestorAcademyCommon from "../../../../../components/common/InvestorAcademyCommon/ChildInvestorAcademyCommon";
import { languageText } from "../../../../../languageText/InvestorAcademy/UsefulWarrantTerms/dwSensivity"
import { languageTextMain } from "../../../../../languageText/InvestorAcademy/index"

const WarrantSensitivities = (props) => {
  
  const location = useLocation();
  const history = useHistory();
  const [siteLang , setSiteLang] = useState("")

  // Site Language Change Start //
  useEffect(() => {
    window.scrollTo(0, 0);
    var siteLanguage = $.cookie('applang');
    setSiteLang(siteLanguage)
    if(siteLanguage == "eng"){
      document.querySelector("body").classList.add("eng")
    }else{
      document.querySelector("body").classList.remove("eng")
    }
  }, []);
  // Site Language Change End //

  return (
    <div id="investor_academy">
      <div className="imgbg" />
      <div className="mainbg" />
      <div id="main">
        <div id="maintable">
          <div style={{ display: "table-row" }}>
            <div id="leftbar" className="hidden-xs" />
            <div id="maincontainer">
              {/* Top Title Section Start */}
              <div id="mainnav">
                <table>
                  <tbody>
                    <tr>
                      <td width="20%" style={{textAlign:"left", visibility: "hidden"}} />
                      <td width="60%" style={{ textAlign: "center" }}>
                        <div id="pagetitlediv">
                          <div id="ptitle">
                            {siteLang == "eng" ? languageText.pageTitleEn : languageText.pageTitle}
                          </div>
                          <div id="pname">
                            {siteLang == "eng" ? languageText.pageNameEn : languageText.pageName}
                          </div>
                        </div>
                      </td>
                      <td width="20%" style={{textAlign:"right", visibility: "hidden"}} />
                    </tr>
                  </tbody>
                </table>
              </div>
              {/* Top Title Section End */}
              {/* Main Content Section Start */}
              <div id="maincontent" className="warrantSensivity">
                <div id="mainscroll">
                  <div id="mainscrollcontent">
                    <style dangerouslySetInnerHTML={{__html: ".formulabox{font-size: 14px;} .formulabox.nbg{ background-color:transparent !important;}.formulabox{max-width: 600px;margin: 0 auto;}.formulabox .hltxt{color:#FFDA44;}.formulaholder .formulabox .leqt td{color:#FFDA44;}.formulabox table{text-align:left;}.formulabox table .first_row td{font-size: 1.2em;}.formulabox table .second_row td{font-size: 12px;}.formulabox table .third_row td{font-size: 12px;\n}.exampleholder .examplebox .col{float:left;width:50%;min-width:280px;margin:2px 0;}.exampleholder .examplebox .line{clear:both;width:96%;height: 0;padding-top:12px;border-bottom: 2px solid #404040;}.exampleholder .examplebox .col .lbl,.exampleholder .examplebox .col .val{float:left;font-size: 14px; line-height: 1.42857143;}.exampleholder .examplebox .col .lbl{width:126px;color:#8F8F8F;}.fraction{color: white;}.formulaholder{color: white;}.reqt table{ color: white;}" }} />
                    <div className="contentbox">
                      <p>{siteLang == "eng" ? languageText.pageDescriptionEn : languageText.pageDescription}</p>
                      <div className="exampleholder">
                        <div className="examplebox">
                          <h3>{siteLang == "eng" ? languageText.exampleBoxHeadingEn : languageText.exampleBoxHeading}</h3>
                          <div className="col">
                            <div className="lbl">{siteLang == "eng" ? languageText.exampleBoxTypeEn : languageText.exampleBoxType}</div>
                            <div className="val">{siteLang == "eng" ? languageText.exampleBoxTypeResultEn : languageText.exampleBoxTypeResult}</div>
                          </div>
                          <div className="col">
                            <div className="lbl">{siteLang == "eng" ? languageText.exampleBoxDwPriceEn : languageText.exampleBoxDwPrice}</div>
                            <div className="val">{siteLang == "eng" ? languageText.exampleBoxDwPriceResultEn : languageText.exampleBoxDwPriceResult}</div>
                          </div>
                          <div className="col">
                            <div className="lbl">{siteLang == "eng" ? languageText.exampleBoxUnderlyingEn : languageText.exampleBoxUnderlying}</div>
                            <div className="val">{siteLang == "eng" ? languageText.exampleBoxUnderlyingResultEn : languageText.exampleBoxUnderlyingResult}</div>
                          </div>
                          <div className="col">
                            <div className="lbl">{siteLang == "eng" ? languageText.exampleBoxDwpsEn : languageText.exampleBoxDwps}</div>
                            <div className="val">{siteLang == "eng" ? languageText.exampleBoxDwpsResultEn : languageText.exampleBoxDwpsResult}</div>
                          </div>
                          <div className="col">
                            <div className="lbl">{siteLang == "eng" ? languageText.exampleBoxExpiryEn : languageText.exampleBoxExpiry}</div>
                            <div className="val">{siteLang == "eng" ? languageText.exampleBoxExpiryResultEn : languageText.exampleBoxExpiryResult}</div>
                          </div>
                          <div className="col">
                            <div className="lbl">{siteLang == "eng" ? languageText.exampleBoxDeltaEn : languageText.exampleBoxDelta}</div>
                            <div className="val">{siteLang == "eng" ? languageText.exampleBoxDeltaResultEn : languageText.exampleBoxDeltaResult}</div>
                          </div>
                          <div className="line" />
                          <h3 style={{margin: 0, marginTop: '12px'}}>
                            {siteLang == "eng" ? languageText.exampleBoxDeltaPerSwEn : languageText.exampleBoxDeltaPerSw}
                          </h3>
                        </div>
                      </div>
                      <div className="formulaholder">
                        <div className="formulabox">
                          <div className="leqt" style={{marginRight: "3px"}}>
                            <table>
                              <tbody>
                                <tr>
                                  <td className="hltxt" style={{height: '39px'}}>    
                                    {siteLang == "eng" ? languageText.formulaHolderLeftEn : languageText.formulaHolderLeft}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>   
                          <div className="reqt" style={{marginLeft: "3px"}}>
                            <table className="fraction">
                              <tbody>
                                <tr>
                                  <td> {siteLang == "eng" ? languageText.formulaHolderRightFirstEn : languageText.formulaHolderRightFirst}</td>
                                </tr>
                                <tr>
                                  <td className="upper_line"> 
                                    {siteLang == "eng" ? languageText.formulaHolderRightSecondEn : languageText.formulaHolderRightSecond}
                                  </td>
                                </tr>
                              </tbody>  
                            </table>
                          </div>
                        </div>
                      </div>
                      <div className="formulaholder" style={{marginTop: '-20px'}}>
                        <div className="formulabox nbg">
                          <div className="leqt">
                            <table>
                              <tbody>
                                <tr>
                                  <td style={{height: '53px'}}>    
                                    {siteLang == "eng" ? languageText.formulaHolder1LeftEn : languageText.formulaHolder1Left}  
                                  </td> 
                                </tr>
                              </tbody>
                            </table>
                          </div>   
                          <div className="reqt">
                            <table>
                              <tbody>
                                <tr>
                                  <td> = </td>
                                  <td> {siteLang == "eng" ? languageText.formulaHolder1RightFirstEn : languageText.formulaHolder1RightFirst}</td>
                                </tr>
                                <tr>
                                  <td> = </td>
                                  <td> {siteLang == "eng" ? languageText.formulaHolder1RightSecondEn : languageText.formulaHolder1RightSecond} </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      <p>
                        {siteLang == "eng" ? languageText.pageDescription1En : languageText.pageDescription1}
                        <br />
                        <br />
                        {siteLang == "eng" ? languageText.pageDescription2En : languageText.pageDescription2}
                      </p>
                      <div className="exampleholder">
                        <div className="examplebox">
                          <h3>{siteLang == "eng" ? languageText.exampleBox1HeadingEn : languageText.exampleBox1Heading}</h3>
                          <div className="col">
                            <div className="lbl">{siteLang == "eng" ? languageText.exampleBox1UnderlyingEn : languageText.exampleBox1Underlying}</div>
                            <div className="val">{siteLang == "eng" ? languageText.exampleBox1UnderlyingResultEn : languageText.exampleBox1UnderlyingResult}</div>
                          </div>
                          <div className="col">
                            <div className="lbl">{siteLang == "eng" ? languageText.exampleBox1DwpsEn : languageText.exampleBox1Dwps}</div>
                            <div className="val">{siteLang == "eng" ? languageText.exampleBox1DwpsResultEn : languageText.exampleBox1DwpsResult}</div>
                          </div>
                          <div className="col">
                          <div className="lbl">{siteLang == "eng" ? languageText.exampleBox1ExpiryEn : languageText.exampleBox1Expiry}</div>
                          <div className="val">{siteLang == "eng" ? languageText.exampleBox1ExpiryResultEn : languageText.exampleBox1ExpiryResult}</div>
                        </div>
                          <div className="col">
                            <div className="lbl">{siteLang == "eng" ? languageText.exampleBox1DeltaEn : languageText.exampleBox1Delta}</div>
                            <div className="val">{siteLang == "eng" ? languageText.exampleBox1DeltaResultEn : languageText.exampleBox1DeltaResult}</div>
                          </div>
                          <div className="line" />
                          <h3 style={{margin: 0, marginTop: '12px'}}>
                            {siteLang == "eng" ? languageText.exampleBox1DeltaPerSwEn : languageText.exampleBox1DeltaPerSw}
                          </h3>
                        </div>
                      </div>
                      <div className="formulaholder">
                        <div className="formulabox">
                          <div className="leqt">
                            <table>
                              <tbody>
                                <tr>
                                  <td className="hltxt" style={{height: '39px'}}>    
                                    {siteLang == "eng" ? languageText.formulaHolderLeftEn : languageText.formulaHolderLeft}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>   
                          <div className="reqt">
                            <table className="fraction">
                              <tbody>
                                <tr>
                                  <td>
                                    {siteLang == "eng" ? languageText.formulaHolderRightFirstEn : languageText.formulaHolderRightFirst}
                                  </td>
                                </tr>
                                <tr>
                                  <td className="upper_line">
                                    {siteLang == "eng" ? languageText.formulaHolderRightSecondEn : languageText.formulaHolderRightSecond}
                                  </td>
                                </tr>
                              </tbody>  
                            </table>
                          </div>
                        </div>
                      </div>
                      <div className="formulaholder" style={{marginTop: '-20px'}}>
                        <div className="formulabox nbg">
                          <div className="leqt">
                            <table>
                              <tbody>
                                <tr>
                                  <td style={{height: '53px'}}>    
                                  {siteLang == "eng" ? languageText.formulaHolder2LeftEn : languageText.formulaHolder2Left} 
                                  </td> 
                                </tr>
                              </tbody>
                            </table>
                          </div>   
                          <div className="reqt">
                            <table>
                              <tbody>
                                <tr>
                                  <td>= </td>
                                  <td> {siteLang == "eng" ? languageText.formulaHolder2RightFirstEn : languageText.formulaHolder2RightFirst}</td>
                                </tr>
                                <tr>
                                  <td>= </td>
                                  <td> {siteLang == "eng" ? languageText.formulaHolder2RightSecondEn : languageText.formulaHolder2RightSecond}</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      <p>
                        {siteLang == "eng" ? languageText.pageDescription3En : languageText.pageDescription3}
                        {siteLang == "eng" ? <br /> : ""}
                        {siteLang == "eng" ? <br /> : ""}
                      </p>
                      <div className="tipholder">
                        <div className="tipbox">
                          <h2>{siteLang == "eng" ? languageText.tipHolderHeadingEn : languageText.tipHolderHeading}</h2>
                          <ul>
                            <li>
                              <p>
                                {siteLang == "eng" ? languageText.tipHolderParagraph1En : languageText.tipHolderParagraph1} 
                                <Link to="/eduhtml/exerciseratio"> DWPS </Link> 
                                {siteLang == "eng" ? languageText.tipHolderParagraph12En : languageText.tipHolderParagraph12}
                              </p>
                            </li>
                            <li>
                              <p>{siteLang == "eng" ? languageText.tipHolderParagraph2En : languageText.tipHolderParagraph2}</p>
                            </li>
                            {siteLang == "eng" ? "" : <li>
                              <p>
                                {languageText.tipHolderParagraph3}
                                <Link to="/eduhtml/delta"> Delta </Link> 
                                {languageText.tipHolderParagraph32}
                              </p>
                            </li>}
                            <li>
                              <p>
                                {siteLang == "eng" ? languageText.tipHolderParagraph4En : languageText.tipHolderParagraph4}
                                <a href="/tools/dwterms">{siteLang == "eng" ? languageText.tipHolderParagraph4LinkEn : languageText.tipHolderParagraph4Link}</a>
                              </p>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Main Content Section End */}
            </div>
            <div id="rightbar" className="hidden-xs" />
          </div>
        </div>
      </div>
      {/* Bottom Left Right Links and Copyright Section Start */}
      <div id="bottom">
        <div className="hidden-xs hidden-xxs" id="copyright">
          {siteLang == "eng" ? languageTextMain.copyRightEn : languageTextMain.copyRight}
        </div>
        <div className="leftUrl">
          <table className="hasprev">
            <tbody>
              <tr>
                <td width="33px">
                  <Link to="/eduhtml/exerciseratio">
                    <div className="prev left" />
                  </Link>
                </td>
                <td>
                  <div className="prevlbl left">
                    <div className="prevname">
                      <Link to="/eduhtml/exerciseratio">
                        {siteLang == "eng" ? languageTextMain.exerciseRatioEn : languageTextMain.exerciseRatio}
                      </Link>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="rightUrl">
          <table className="hasnext">
            <tbody>
              <tr>
                <td>
                  <div className="nextlbl right">
                    <div className="nextname">
                      <Link to="/eduhtml/breakevenprice">
                        {siteLang == "eng" ? languageTextMain.breakEvenPriceEn : languageTextMain.breakEvenPrice}
                      </Link>
                    </div>
                  </div>
                </td>
                <td width="33px">
                  <Link to="/eduhtml/breakevenprice">
                    <div className="next right" />
                  </Link>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      {/* Bottom Left Right Links and Copyright Section End */}
      {/* Right Dots Menu Component Section Start */}
      <div id="dotbar" className="hidden-sm hidden-xs">
        <ChildInvestorAcademyCommon Location={location} Hist={history} />
      </div>
      {/* Right Dots Menu Component Section End  */}
    </div>
  );
};
export default WarrantSensitivities;
