export const languageText ={

    pageTitle: "ตัวแปรเกี่ยวกับ DW ที่ควรรู้",
    pageTitleEn: "Useful DW terms",

    pageName: "Effective Gearing",
    pageNameEn: "Effective Gearing",

    pageDescription: "Effective Gearing เป็นการผสมระหว่าง gearing และ delta ทำหน้าที่คล้ายๆ Delta แต่ใช้ในการวัดการเปลี่ยนแปลงของราคา DW เป็นเปอร์เซ็นต์",
    pageDescriptionEn: 'A similar indicator to "delta", it combines gearing and delta to express the DW price movement as a percentage.',

    formulaHolderLeft: "Effective Gearing ",
    formulaHolderLeftEn: "Effective Gearing ",
    formulaHolderRightFirst: " = Gearing x Delta",
    formulaHolderRightFirstEn: " = Gearing x Delta",

    pageDescription1: "Effective Gearing นิยมนำมาใช้ใน 2 กรณี คือ",
    pageDescription1En: 'There are two main uses for effective gearing:',

    edulistFirstTitle: "ใช้บอก % การเปลี่ยนแปลงของราคา DW เมื่อราคาหุ้นอ้างอิงเปลี่ยนแปลงไป 1%",
    edulistFirstTitleEn: "Indicate the % change in the price of a DW relative to a 1% change in the underlying",
    edulistFirstParagraph: "ตัวอย่างเช่น DW ที่มี Effective Gearing เท่ากับ 10 เท่า ซึ่งหมายถึง DW จะเปลี่ยนแปลงประมาณ 10% ถ้าหุ้นอ้างอิงเปลี่ยนแปลงไป 1%",
    edulistFirstParagraphEn: "Take for example a DW with an effective gearing of 10x. This means, the DW should move approximately 10% for a 1% movement in the underlying share.",
    edulistFirstFormulaLeft: "หุ้นอ้างอิง 1% x 10 ",
    edulistFirstFormulaLeftEn: "Stock 1% x 10 ",
    edulistFirstFormulaRight: " = DW 10%",
    edulistFirstFormulaRightEn: " = DW 10%",
    edulistFirstParagraph2: "โดยทั่วไป Effective Gearing ที่สูงกว่า หมายถึงโอกาสได้รับผลตอบแทนที่สูงกว่า และความเสี่ยงที่มากกว่าด้วย",
    edulistFirstParagraph2En: "A higher effective gearing generally translates to a higher profit potential and also a higher level of risk.",

    edulistSecondTitle: "ช่วยนักลงทุนในการตัดสินใจว่าจะถือหุ้น หรือ DW",
    edulistSecondTitleEn: "Helps you decide how much to invest",
    edulistSecondParagraph: "Effective Gearing ช่วยให้นักลงทุนตัดสินใจได้ว่าจะถือหุ้น หรือจะถือ DW เพื่อทดแทนการถือหุ้นอ้างอิงก็ได้",
    edulistSecondParagraphEn: "With effective gearing, you can estimate the effective stock exposure in the underlying asset that a DW holder gains by holding the DW.",
    edulistSecondParagraph2: "ตัวอย่างเช่น DW ที่มี Effective Gearing ที่ 10 เท่า หมายความว่าถ้านักลงทุนซื้อ DW มูลค่า 10,000 บาท จะสามารถทดแทนการถือหุ้นอ้างอิงมูลค่า 100,000 บาท",
    edulistSecondParagraph2En: "Take for example a DW with an effective gearing of 10x. This means if an investor buys $10,000 worth of the DW they will have an effective stock exposure of $100,000.",
    edulistSecondFormulaLeft: "$10,000 DW investment x 10 ",
    edulistSecondFormulaLeftEn: "$10,000 DW investment x 10 ",
    edulistSecondFormulaRight: " = $100,000 effective stock exposure",
    edulistSecondFormulaRightEn: " = $100,000 effective stock exposure",
    edulistSecondParagraph3: "ในอีกด้านหนึ่ง หากนักลงทุนซื้อหุ้นอ้างอิงมูลค่า 200,000 บาท และต้องการใช้เงินสดบางจำนวน แต่ไม่อยากลดปริมาณการถือหุ้น นักลงทุนสามารถซื้อ DW มูลค่า 20,000 บาท ที่มี Effective Gearing ที่ 10 เท่า เพื่อทดแทนการถือหุ้นอ้างอิงได้ ดังนั้นนักลงทุนจะใช้เงินลงทุนเพียง 20,000 บาท เท่านั้น",
    edulistSecondParagraph3En: "Similarly, if you are currently holding $200,000 worth of shares and wish to release some of these share capital without losing the stock exposure, you can do so by purchasing $20,000 worth of a DW with an effective gearing of 10x.",
    edulistSecondFormula2Left: "$200,000 effective stock exposure / 10 ",
    edulistSecondFormula2LeftEn: "$200,000 effective stock exposure / 10 ",
    edulistSecondFormula2Right: " = $20,000 DW investment",
    edulistSecondFormula2RightEn: " = $20,000 DW investment",

    tipHolderHeading: "เคล็ดลับในการใช้ Effective Gearing",
    tipHolderHeadingEn: "Helpful Tips on how to use Effective Gearing",
    tipHolderParagraph1: "นักลงทุนสามารถหาค่า Effective Gearing ของ DW ทั้งหมด ได้จากหน้า",
    tipHolderParagraph1En: "You can view the effective gearing for all DW via the",
    tipHolderLink: " ค้นหา DW ",
    tipHolderLinkEn: " DW Search Tool ",
    tipHolderParagraph2: " and the",
    tipHolderParagraph2En: "and the",
    tipHolderLink2: " ข้อมูล DW ",
    tipHolderLink2En: " DW Terms ",

}