import React, { useState, useEffect,Fragment } from 'react';
import LastTradingDays from './LastTradingDays';
import "../../common.css";
import "../../investorAcademy.css";


const LastTradingDay = (props) => {
    return ( 
        <Fragment> 
            <LastTradingDays/>
        </Fragment> 
     );
}


export default LastTradingDay;