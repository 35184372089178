import React, { useState, useEffect,Fragment } from 'react';
import PutExamples from './PutExamples';
import "../../common.css";
import "../../investorAcademy.css";


const PutExample = (props) => {
    return ( 
        <Fragment> 
            <PutExamples/>
        </Fragment> 
     );
}


export default PutExample;