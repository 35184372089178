import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import API from "../../../utils/apiCalling";
import "./seminar.css";
import { config } from "../../../utils/apiUrl";
import SubscribeModalImg from "../../../components/common/subscribeModalImg";
import SpeakModal from "../../../components/common/SpeakModal";
import { languageText } from "../../../languageText/seminar"
import DwVideoSideBars from "../../../components/common/Sidebar/DwVideoSideBar"
import DwEduHtmlSideBars from "../../../components/common/Sidebar/InvestorAcademy"
import IndexDws from "../../../components/common/Sidebar/IndexDw"
import AdvertiseMentSidebar from "../../../components/common/Sidebar/AdvertiseMentSidebar"

const Seminar = (props) => {

  const api = new API();
  const [seminarAllData, setSeminarAllData] = useState({});
  const [selectedSeminar, setSelectedSeminar] = useState([]);
  const [id, setid] = useState([]);
  const [data, setData] = useState([]);
  const [modal, setModal] = useState(false);
  const [openLive, setOpenLive] = useState(false);
  const [openMsg1, setOpenMsg] = useState(false);
  const [displayBlock, setDisplayBlock] = useState("none");
  const [xs, setXs] = useState(true);
  const [siteLang , setSiteLang] = useState("")
  const [seminarReg , setSeminarReg] = useState("")
  const [values, setVlaues] = useState({
    firstName: "",
    tel: "",
    email: "",
  });
  const [loadingIndicator, setLoadingIndicator] = useState(true)

  useEffect(() => {
    window.scrollTo(0, 0);
    var siteLanguage = $.cookie('applang');
    setSiteLang(siteLanguage)
    if(siteLanguage == "eng"){
      document.querySelector("body").classList.add("eng")
    }else{
      document.querySelector("body").classList.remove("eng")
    }
  }, []);

  useEffect(() => {
    var urlData = new URLSearchParams(window.location.search)
    getFastTrack(urlData.get("seminar"));
  }, [siteLang]);
  
  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent);
  }, [displayBlock]);

  const getFastTrack = async (urlId) => {
    setLoadingIndicator(true)
    let seminarData = await api.get(config.seminar, true, {
      qid: Date.now(),
    });
    var allData = []
    if (seminarData?.seminarList?.length > 0) {
      for (let i = 0; i < seminarData?.seminarList.length; i++) {
        let advImgData = await api.get(config.advImgData, true, {
          seminar: seminarData?.seminarList[i].id,
          lang: $.cookie('applang') == "thai" ? "th" : "en",
        });
        allData.push({
          imgSrc: advImgData,
          contact_info: seminarData?.seminarList[i].contact_info,
          date: seminarData?.seminarList[i].date,
          en_content: seminarData?.seminarList[i].en_content,
          en_poster: seminarData?.seminarList[i].en_poster,
          en_time: seminarData?.seminarList[i].en_time,
          en_title: seminarData?.seminarList[i].en_title,
          en_venue: seminarData?.seminarList[i].en_venue,
          id: seminarData?.seminarList[i].id,
          link: seminarData?.seminarList[i].link,
          th_content: seminarData?.seminarList[i].th_content,
          th_poster: seminarData?.seminarList[i].th_poster,
          th_time: seminarData?.seminarList[i].th_time,
          th_title: seminarData?.seminarList[i].th_title,
          th_venue: seminarData?.seminarList[i].th_venue,
        });
      }
    }
    setLoadingIndicator(false)
    setSeminarAllData(allData)
    if(urlId){
      allData.filter((e) => {
        if(e.id == urlId){
          selectedSeminarShow(e)
        }
      })
    }else{
      selectedSeminarShow(allData[0])
    }
  };

  const selectedSeminarShow = (data) => {
    setSelectedSeminar(data)
  }

  const handleChange = (event) => {
    const { name, value } = event.target;
    setVlaues({ ...values, [name]: value });
  };

  const valueSubmit = () => {
    let message = document.getElementById("warning");
    let message1 = document.getElementById("warning1");
    let message2 = document.getElementById("warning2");
    var validRegex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    var phone = /^[0-9]+$/;
    if (!values.firstName) {
      message.innerHTML = `${siteLang == "eng" ? "Name is required. Please fill in." : "ชื่อ - นามสกุล is required. Please fill in."}`;
    }else {
      message.innerHTML = "";
    }
    if (!values.tel) {
      message1.innerHTML = `${
        siteLang == "eng" ? "Tel is required. Please fill in." : " เบอร์โทรศัพท์ is required. Please fill in "
      }`;
    }else if(values.tel.length != 10){
      message1.innerHTML = `${
        siteLang == "eng" ? " * Invalid phone numbers (at least 10 digits)" : "  * หมายเลขโทรศัพท์ไม่ถูกต้อง (อย่างน้อย 10 หลัก) "
      }`;
    }else if(!values.tel.match(phone)){
      message1.innerHTML = `${
        siteLang == "eng" ? " * Invalid phone numbers (at least 10 digits)" : " *หมายเลขโทรศัพท์ไม่ถูกต้อง (อย่างน้อย 10 หลัก) "
      }`;
    }else{
      message1.innerHTML = "";
    }
    if(!values.email){
      message2.innerHTML = `${
        siteLang == "eng" ? "Email is required. Please fill in." : "สอบถามข้อมูล เกี่ยวกับ DW28 is required. Please fill in."
      }`;
    }else if(!values.email.match(validRegex)){
      message2.innerHTML = `${
        siteLang == "eng" ? " *invalid Email" : " *อีเมลไม่ถูกต้อง "
      }`;
    }else{
      message2.innerHTML = "";
    }

    if (values.firstName == "") {
    }else if (values.tel == "") {
    }else if (values.tel.length < 9) {
    }else if (!values.tel.match(phone)) {
    }else if (values.email == "") {
    }else if (!values.email.match(validRegex)) {
    }else {
      const submit = async () => {
        let data = {
          email: values.email,
        };
        let res = await api
          .post(`${config.subscription}${"?p=register"}`, data)
          .then((res) => {
            if(res.message == "Registration succeed"){
              submit1();
            }
          })
          .then((data) => {
            setData(data);
          })
          .catch((error) => console.log(error));
      };
      submit();
      const submit1 = async () => {
        let data1 = {
          firstName: values.firstName,
          tel: values.tel,
          email: values.email,
          seminar: selectedSeminar?.id,
          lang: `${siteLang == "eng" ? "en " : "th"}`,
        };
        let ress = await api
          .post(`${config.seminar1}`, data1)
          .then((res) => {
            setSeminarReg(res.message)
            setOpenMsg(true);
            setModal(false);
            setTimeout(() => setOpenMsg(false), [3000]);
          })
          .catch((error) => console.log(error));
      };
    }
  };

  const handleClick = () => {
    window.open(
      "https://www.facebook.com/login.php?skip_api_login=1&api_key=966242223397117&signed_next=1&next=https%3A%2F%2Fwww.facebook.com%2Fshare.php%3Fu%3Dhttps%253A%252F%252Ft1www5.trkd-hs.com%252Fthaidw_aws%252Feducation%252Fseminar%253Fseminar%253D42%2526lang%253Den%26title%3Dabs%26description%3D%253Cp%253ETest%2BTest%2B%25E0%25B8%2597%25E0%25B8%2594%25E0%25B8%25A5%25E0%25B8%25AD%25E0%25B8%2587%2B%25E0%25B8%2597%25E0%25B8%2594%25E0%25B8%25A5%25E0%25B8%25AD%25E0%25B8%2587Test%2BTest%2B%25E0%25B8%2597%25E0%25B8%2594%25E0%25B8%25A5%25E0%25B8%25AD%25E0%25B8%2587%2B%25E0%25B8%2597%25E0%25B8%2594%25E0%25B8%25A5%25E0%25B8%25AD%25E0%25B8%2587%2BTest%2BTest%2B%25E0%25B8%2597%25E0%25B8%2594%25E0%25B8%25A5%25E0%25B8%25AD%25E0%25B8%2587%2B%25E0%25B8%2597%25E0%25B8%2594%25E0%25B8%25A5%25E0%25B8%25AD%25E0%25B8%2587Test%2BTest%2B%25E0%25B8%2597%25E0%25B8%2594%25E0%25B8%25A5%25E0%25B8%25AD%25E0%25B8%2587%2B%25E0%25B8%2597%25E0%25B8%2594%25E0%25B8%25A5%25E0%25B8%25AD%25E0%25B8%2587Test%2BTest%2B%25E0%25B8%2597%25E0%25B8%2594%25E0%25B8%25A5%25E0%25B8%25AD%25E0%25B8%2587%2B%25E0%25B8%2597%25E0%25B8%2594%25E0%25B8%25A5%25E0%25B8%25AD%25E0%25B8%2587Test%2BTest%2B%25E0%25B8%2597%25E0%25B8%2594%25E0%25B8%25A5%25E0%25B8%25AD%25E0%25B8%2587%2B%25E0%25B8%2597%25E0%25B8%2594%25E0%25B8%25A5%25E0%25B8%25AD%25E0%25B8%2587%253C%252Fp%253E&cancel_url=https%3A%2F%2Fwww.facebook.com%2Fdialog%2Fclose_window%2F%3Fapp_id%3D966242223397117%26connect%3D0%23_%3D_&display=popup&locale=en_GB", 
      'share', 
      'width=560,height=650'
    );
  };

  const listenScrollEvent = () => {
    window.scrollY > 700 && xs === true
      ? setDisplayBlock("block")
      : setDisplayBlock("none");
  };

  const bookNowClick = (data) => {
    var url = data;
    if(url != ""){
      url = url.match(/^http[s]*:\/\//) ? url : 'http://' + url;
      window.open(url, '_blank');
      setModal(false)
    }else{
      setModal(true)
    }
  };

  return (
    <>
      <div id="seminar" className="pageobj">
        <div className="breadcrumbbar">
          <div className="container-fluid">
            <ol className="breadcrumb">
              <li>
                <Link to="/home?dwedu">
                  {siteLang == "eng" ? languageText.firstNavigationEn : languageText.firstNavigation}
                </Link>
              </li>
              <li className="active">
                {siteLang == "eng" ? languageText.secondNavigationEn : languageText.secondNavigation}
              </li>
            </ol>
          </div>
        </div>
        <div className="stripe-bg" />
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-8">
              <div className="section">
                <div className="page-header">
                  <h1>
                    {siteLang == "eng" ? languageText.pageTitleEn : languageText.pageTitle}
                  </h1>
                </div>
                <p>
                  {siteLang == "eng" ? languageText.pageDescriptionEn : languageText.pageDescription}
                </p>
                {console.log(selectedSeminar)}
                {
                    selectedSeminar?(
                    <div id="seminarbox">
                    <div className="seminar_area" style={{ position: "relative" }} >
                      <img src={selectedSeminar?.imgSrc} alt="Seminar First" />
                      <div className="btn-area">
                        <div id="bookBtn" className="bgcolor-06" onClick={() => bookNowClick(selectedSeminar?.link)}>
                          {siteLang == "eng" ? languageText.bookNowBtnEn : languageText.bookNowBtn}
                        </div>
                        <div id="shareBtn" onClick={handleClick}>
                          <i className="fa fa-facebook" aria-hidden="true" />
                          Share on Facebook
                        </div>
                      </div>
                      <p className="seminar_title" style={{ fontWeight: "bold" }} 
                        dangerouslySetInnerHTML={{__html: siteLang == "eng" ? selectedSeminar?.en_title : selectedSeminar?.th_title}} 
                      />
                      <p className="seminar_content"
                        dangerouslySetInnerHTML={{__html: siteLang == "eng" ? selectedSeminar?.en_content : selectedSeminar?.th_content}} 
                      />
                      <p>
                        Date: <span className="seminar_date">{selectedSeminar?.date}</span><br />
                        Time: <span className="seminar_time">{siteLang == "eng" ? selectedSeminar?.en_time : selectedSeminar?.th_time}</span><br />
                        Venue: <span className="seminar_venue">{siteLang == "eng" ? selectedSeminar?.en_venue : selectedSeminar?.th_venue}</span><br />
                        Reserve seat: <span className="contact_info">{selectedSeminar?.contact_info}</span><br />
                      </p>
                      {loadingIndicator && <div className="loadingindicator" />}
                    </div>
                    <div className="bgcolor-03" style={{ padding: "5px 10px" }}>
                      {siteLang == "eng" ? languageText.moreSeminarEn : languageText.moreSeminar}
                    </div>
                    <div className="more_seminars" style={{ position: "relative" }}>
                      <div className="row">
                        {seminarAllData.length > 0 && seminarAllData && seminarAllData.map((data, index) => (
                          <div className="col-sm-4 col-xs-12 sem_imglf" id={`seminar - ${data.id}`} key={index} onClick={() => setSelectedSeminar(data)}>
                            <img src={data.imgSrc} alt="Seminar Image" />
                            <div>{siteLang == "eng" ? data.en_title : data.th_title}</div>
                          </div>
                        )
                        )}
                      </div>
                    </div>
                     </div>
                  ):(
                      <div className="bgcolor-03" style={{ padding: '20px',
                        textAlign: 'center' }}>
                        {siteLang == "eng" ? languageText.noMoreSeminarEn : languageText.noMoreSeminar}
                      </div>
                    )
                }
                <hr className="hr-hmgbm" />
                <p className="small">
                  {siteLang == "eng" ? languageText.marketDelayEn : languageText.marketDelay}
                </p>
              </div>
            </div>
            <div className="hidden-xs hidden-sm col-md-4">
              <DwVideoSideBars />
              <DwEduHtmlSideBars />
              <IndexDws />
              <AdvertiseMentSidebar />
            </div>
          </div>
        </div>
      </div>
      <div id="popup-customized" style={{ display: modal ? "table" : "none" }}>
        <div>
          <div id="book_area">
            <img
              className="btn-close"
              style={{ top: "40px", right: "40px" }}
              src="/img/bullet/icon-close-white.png"
              onClick={() => {
                setModal(false);
              }}
            />
            <div className="page-header">
              <h1>Book Now</h1>
            </div>
            <input
              type="text"
              name="firstName"
              placeholder={siteLang == "eng" ? languageText.formNameFieldEn : languageText.formNameField}
              autoComplete="off"
              onChange={(e) => handleChange(e)}
              value={values.firstName}
            />
            <div id="warning" className="warning"></div>
            <input
              type="text"
              name="tel"
              placeholder={siteLang == "eng" ? languageText.formTelFieldEn : languageText.formTelField}
              maxLength={12}
              autoComplete="off"
              onChange={(e) => handleChange(e)}
              value={values.tel}
            />
            <div id="warning1" className="warning"></div>
            <input
              type="text"
              name="email"
              placeholder={siteLang == "eng" ? languageText.formEmailFieldEn : languageText.formEmailField}
              autoComplete="off"
              onChange={(e) => handleChange(e)}
              value={values.email}
            />
            <div id="warning2" className="warning"></div>
            <p className="small" dangerouslySetInnerHTML={{__html: siteLang == "eng" ? languageText.formDescriptionEn : languageText.formDescription}} />
            <input
              type="button"
              onClick={() => valueSubmit()}
              id="submitBtn"
              defaultValue={
                siteLang == "eng" ? "Agree & Submit" : "ลงทะเบียนเข้าร่วมสัมมนา"
              }
            />
          </div>
        </div>
      </div>
      <SubscribeModalImg Open={openLive} OpenModel={() => setOpenLive(!true)} />
      <div id="popup-customized" className="subscribe" style={{ display: "none" }}>
        <div>
          {openMsg1 ? (
            <h5>
              We apologize that the seminar is FULL. We will contact you if
              there are any further seats available.
            </h5>
          ) : (
            ""
          )}
        </div>
      </div>
      {openMsg1 && <SpeakModal open1={openMsg1} seminarReg={seminarReg} />}
    </>
  );
};
export default Seminar;
