import React, { useState, useEffect } from 'react';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import API from "../../../utils/apiCalling";
import { config } from "../../../utils/apiUrl";
import "./adverTiseMent.css"


export const AdvertiseMentSidebar = (props) => {

  const api = new API();
  const [advImgVal, setAdvImgVal] = useState([])
  const [advVidVal, setAdvVidVal] = useState([])
  const [siteLang , setSiteLang] = useState("")
  const adverTiseMent = {
    autoplay: false,
    infinite: false,
    slidesToShow:1,
    slidesToScroll: 1,
    dots: true,
    arrow: false,
  };

  useEffect(() => {
    var siteLanguage = $.cookie('applang');
    setSiteLang(siteLanguage)
    if(siteLanguage == "eng"){
      document.querySelector("body").classList.add("eng")
    }else{
      document.querySelector("body").classList.remove("eng")
    }
    getAddvertisementData()
  }, [])

  const getAddvertisementData = async () => {
    let addvertisementResult = await api.get(config.addvertisementApi, true,);
    if(addvertisementResult?.videos?.length > 0){
      setAdvVidVal(addvertisementResult?.videos)
    }
    let imageItems = [];
    if(addvertisementResult?.image?.length > 0){
      for (let i = 0; i < addvertisementResult?.image?.length; i++) {
        let advImgData = await api.get(config.advImgData, true, {adv_id:addvertisementResult?.image[i].id});
        imageItems.push({
          imgSrc: advImgData,
          alt_en: addvertisementResult?.image[i].alt_en,
          alt_th: addvertisementResult?.image[i].alt_th,
          id: addvertisementResult?.image[i].id,
          link: addvertisementResult?.image[i].link,
          order: addvertisementResult?.image[i].order,
          redirect_type: addvertisementResult?.image[i].redirect_type,
        })
      }
      setAdvImgVal(imageItems)
    }
  };

  return (
    // <Slider {...adverTiseMent} className="adverTiseMent custom">
    //   {advVidVal && advVidVal.map((data, index) => {
    //     return (
    //       <div className="videoplayer" key={index}>
    //         <div className="videoplayer-container">
    //           <iframe frameBorder={0} allowFullScreen marginWidth={0} marginHeight={0} hspace={0} vspace={0} scrolling="no" src={data.link} />
    //         </div>
    //       </div>
    //     );
    //   })}
    //   {advImgVal && advImgVal.map((data, index) => {
    //     return (
    //       <a target={data.redirect_type == 0 ? "_self" : "_blank"} href={data.link} key={index}>
    //           <img src={data.imgSrc} alt={data.alt_en} />
    //       </a>
    //     );
    //   })}
    // </Slider>

    <div className="videoplayer" style={{height:'300px',marginTop:'15px'}}>
             <div className="videoplayer-container" style={{height: '100%'}}>
               <iframe height='100%' frameBorder={0} allowFullScreen marginWidth={0} marginHeight={0} hspace={0} vspace={0} scrolling="no" src={'https://www.youtube.com/embed/MQfc_MOWuJU?autoplay=1&amp;amp;amp;amp;amp;amp;amp;amp;amp;amp;mute=1'} />
             </div>
           </div>
  );

};

export default AdvertiseMentSidebar;