import React, { useEffect, useState } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import InvestorAcademyCommon from "../../../../components/common/InvestorAcademyCommon";
import "../common.css";
import "../investorAcademy.css";
import { languageText } from "../../../../languageText/InvestorAcademy/HowAreWarrantsPriced/index"
import { languageTextMain } from "../../../../languageText/InvestorAcademy/index"

const HowWarrantsPrices = (props) => {
  
  const location = useLocation();
  const history = useHistory();
  const [siteLang , setSiteLang] = useState("")

  useEffect(() => {
    window.scrollTo(0, 0);
    var siteLanguage = $.cookie('applang');
    setSiteLang(siteLanguage)
    if(siteLanguage == "eng"){
      document.querySelector("body").classList.add("eng")
    }else{
      document.querySelector("body").classList.remove("eng")
    }
  }, []);

  return (
    <div id="investor_academy">
      <div className="imgbg" />
      <div className="mainbg" />
      <div id="main">
        <div id="maintable">
          <div style={{ display: "table-row" }}>
            <div id="leftbar" className="hidden-xs" />
            <div id="maincontainer">
              <div id="mainnav">
                <table>
                  <tbody>
                    <tr>
                      <td width="20%" style={{textAlign:"left", visibility: "hidden"}} />
                      <td width="60%" style={{ textAlign: "center" }}>
                        <div id="pagetitlediv">
                          <div id="ptitle">
                            <img height="56px" src="/img/education/img/04.png" alt="Investor" />
                          </div>
                          <div id="pname">
                            {siteLang == "eng" ? languageText.pageTitleEn : languageText.pageTitle}
                          </div>
                        </div>
                      </td>
                      <td width="20%" style={{textAlign:"right", visibility: "hidden"}} />
                    </tr>
                  </tbody>
                </table>
              </div>
              <div id="maincontent">
                <div id="mainscroll">
                  <div id="mainscrollcontent">
                    <style
                      dangerouslySetInnerHTML={{__html:" \n .hexrow.odd{ \n margin-left: 73px; \n } \n ",}}
                    />
                    <div className="contentbox">
                      <p className="showtile">
                        {siteLang == "eng" ? languageText.pageDescriptionEn : languageText.pageDescription}
                      </p>
                      <div className="hexmenu hidden-xxs hidden-xs">
                        <div className="imgmask">
                          <img className="hexbg" src="/img/topic/risk.png" />
                          <div className="hexarea" style={{top: '15%', left: '3%', right: '67%', bottom: '59%'}}>
                            <div className="topictxt">
                              <Link to="/eduhtml/shareprice">
                                {siteLang == "eng" ? languageText.stockPriceEn : languageText.stockPrice}
                              </Link>
                            </div>
                          </div>
                          <div className="hexarea" style={{top: '15%', left: '34%', right: '34%', bottom: '59%'}}>
                            <div className="topictxt">
                              <Link to="/eduhtml/exerciseprice">
                                {siteLang == "eng" ? languageText.exercisePriceEn : languageText.exercisePrice}
                              </Link>
                            </div>
                          </div>
                          <div className="hexarea" style={{top: '15%', left: '67%', right: '3%', bottom: '59%'}}>
                            <div className="topictxt">
                              <Link to="/eduhtml/expirydate">
                                {siteLang == "eng" ? languageText.expiryDateEn : languageText.expiryDate}
                              </Link>
                            </div>
                          </div>
                          <div className="hexarea" style={{top: '56%', left: '19%', right: '51%', bottom: '17%'}}>
                            <div className="topictxt">
                              <Link to="/eduhtml/volatility">
                                {siteLang == "eng" ? languageText.volatilityEn : languageText.volatility}
                              </Link>
                            </div>
                          </div>
                          <div className="hexarea" style={{top: '56%', left: '51%', right: '19%', bottom: '17%'}}>
                            <div className="topictxt">
                              <Link to="/eduhtml/interestrates">
                                {siteLang == "eng" ? languageText.interestRatesEn : languageText.interestRates}
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="mobilemenu visible-xxs visible-xs" style={{fontFamily: '"MCQGlobal_A_Rg"'}}>
                        <div className="menuitem">
                          <Link to="/eduhtml/shareprice">
                            <img className="menuicon" src="/img/mobilemenu/stock_price.png" />
                            <div className="menutitle">
                                {siteLang == "eng" ? languageText.stockPriceEn : languageText.stockPrice}
                            </div>
                            <img className="menuarrow" src="/img/mobilemenu/arrow.png" />
                          </Link>
                        </div>
                        <div className="menuitem">
                          <Link to="/eduhtml/exerciseprice">
                            <img className="menuicon" src="/img/mobilemenu/exercise_price.png" />
                            <div className="menutitle">
                                {siteLang == "eng" ? languageText.exercisePriceEn : languageText.exercisePrice}
                            </div>
                            <img className="menuarrow" src="/img/mobilemenu/arrow.png" />
                          </Link>
                        </div>
                        <div className="menuitem">
                          <Link to="/eduhtml/expirydate">
                            <img className="menuicon" src="/img/mobilemenu/expiry_date.png" />
                            <div className="menutitle">
                                {siteLang == "eng" ? languageText.expiryDateEn : languageText.expiryDate}
                            </div>
                            <img className="menuarrow" src="/img/mobilemenu/arrow.png" />
                          </Link>
                        </div>
                        <div className="menuitem">
                          <Link to="/eduhtml/volatility">
                            <img className="menuicon" src="/img/mobilemenu/volatility.png" />
                            <div className="menutitle">
                                {siteLang == "eng" ? languageText.volatilityEn : languageText.volatility}
                            </div>
                            <img className="menuarrow" src="/img/mobilemenu/arrow.png" />
                          </Link>
                        </div>
                        <div className="menuitem">
                          <Link to="/eduhtml/interestrates">
                            <img className="menuicon" src="/img/mobilemenu/interest_rates.png" />
                            <div className="menutitle">
                                {siteLang == "eng" ? languageText.interestRatesEn : languageText.interestRates}
                            </div>
                            <img className="menuarrow" src="/img/mobilemenu/arrow.png" />
                          </Link>
                        </div>
                      </div>
                      <div className="tipholder">
                        <div className="tipbox">
                          <h2>{siteLang == "eng" ? languageText.tipHolderTitleEn : languageText.tipHolderTitle}</h2>
                          <p>
                            {siteLang == "eng" ? languageText.tipHolderDescriptionEn : languageText.tipHolderDescription}
                            <br />
                            <br />
                            {siteLang == "eng" ? languageText.tipHolderDescription1En : languageText.tipHolderDescription1}
                            <a href="/tools/dwcalculator">
                              {siteLang == "eng" ? languageText.tipHolderLinkEn : languageText.tipHolderLink}
                            </a>
                            <br />
                            <br />
                            {siteLang == "eng" ? languageText.tipHolderDescription2En : languageText.tipHolderDescription2}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div id="rightbar" className="hidden-xs" />
          </div>
        </div>
      </div>

      <div id="bottom">
        <div className="hidden-xs hidden-xxs" id="copyright">
          {siteLang == "eng" ? languageTextMain.copyRightEn : languageTextMain.copyRight}
        </div>
        <div className="leftUrl">
          <table className="hasprev">
            <tbody>
              <tr>
                <td width="33px">
                  <Link to="/eduhtml/howarewarrantsnamed">
                    <div className="prev left" />
                  </Link>
                </td>
                <td>
                  <div className="prevlbl left">
                    <div className="prevname">
                      <Link to="/eduhtml/howarewarrantsnamed">
                        {siteLang == "eng" ? languageTextMain.howAreWarrantEn : languageTextMain.howAreWarrant}
                      </Link>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="rightUrl">
          <table className="hasnext">
            <tbody>
              <tr>
                <td>
                  <div className="nextlbl right">
                    <div className="nextname">
                      <Link to="/eduhtml/shareprice">
                        {siteLang == "eng" ? languageTextMain.stockPriceEn : languageTextMain.stockPrice}
                      </Link>
                    </div>
                  </div>
                </td>
                <td width="33px">
                  <Link to="/eduhtml/shareprice">
                    <div className="next right" />
                  </Link>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div id="dotbar" className="hidden-sm hidden-xs">
        <InvestorAcademyCommon Location={location} Hist={history} />
      </div>
    </div>
  );
};
export default HowWarrantsPrices;
