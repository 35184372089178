import React  from 'react';
import DwVideoPage from './DwVideo';
import './dwvideo.css';


const DwVideoPages = (props) => {
    return ( 
        <DwVideoPage/>
     );
}
 
export default DwVideoPages;