export const languageText ={

    pageTitle: "วิธีการเลือก DW",
    pageTitleEn: "How to select a DW?",

    pageName: "ความผันผวนแฝง (Implied Volatility)",
    pageNameEn: "Implied Volatility",

    pageDescription: "ในการเลือก DW เราแนะนำให้นักลงทุนทำตาม 4 ขั้นตอนนี้",
    pageDescriptionEn: "When choosing a DW, we suggest that investors consider the following 4 steps:",

    imgUrl: "/img/education/img/fourstep4_th.jpg",
    imgUrlEn: "/img/education/img/fourstep4.jpg",
    
    pageDescription1: "ขั้นตอนสุดท้ายจากรายการ DW ที่คุณเลือกไว้จาก 3 ขั้นตอนแรก คุณควรพิจารณา Implied Volatility ของ DW ที่คุณเลือกไว้ หากกำหนดให้ปัจจัยอื่นคงที่ คุณควรจะเลือก DW ที่มี Implied Volatility ต่ำที่สุด ถ้าคุณมี DW 2 รุ่นที่มีราคาใช้สิทธิ วันครบกำหนดอายุ DWPS และราคาหุ้นอ้างอิงเท่ากัน DW ที่มี Implied Volatility สูงกว่า จะมีราคาแพงกว่า",
    pageDescription1En: "Finally, with the list of DW narrowed from the first three steps, you should look at the implied volatility of the DW in the list. All else constant, you would generally select the DW with the lowest implied volatility. If you have two DW with the same exercise price, expiry date, DW per share, share price, a higher implied volatility in one DW will translate to a higher price.",

    imgUrl1: "/img/education/img/fourstepimpliedvolatility_th.png",
    imgUrl1En: "/img/education/img/fourstepimpliedvolatility_th.png",
    
}