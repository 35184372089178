import React  from 'react';
import DwVideoSpeaker from './DwVideoSpeaker';
import './dwvideoSpeaker.css';


const DwVideoSpeakers = (props) => {
    return ( 
        <DwVideoSpeaker/>
     );
}
 
export default DwVideoSpeakers;