import React, { Fragment } from 'react';
import "./dwRanking.css" 
import DwRanking from "./DwRanking"


const DwRankings = (props) => {
    return ( 
        <Fragment> 
            <DwRanking />
        </Fragment> 
     );
}

export default DwRankings;