import React, { useState, useEffect } from "react";
import "./landing.css";
import { tabName } from "../../utils/constants";
import { Link, useHistory } from "react-router-dom";
import LangContext from "../../components/common/context/lang-context";
import { useContext } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import API from "../../utils/apiCalling";
import { config } from "../../utils/apiUrl";
import { languageText } from "../../languageText/landing";
import {Carousel} from 'react-responsive-carousel';
import { get } from "lodash";

const Landing = () => {

  const history = useHistory()
  const api = new API();
  const langContext = useContext(LangContext);
  const [popupData, setPopupData] = useState([]);
  const [popupDataMobile, setPopupDataMobile] = useState([]);
  const [siteLang , setSiteLang] = useState("")
  const [marketAnalysis, setMarketAnalysis] = useState([]);
  const itemsPerRow = 3; // Number of items per row
  const itemsPerTable = 3; // Number of items per table
  const tableRows = [];
  const xstableRows = [];
  const [popupVideoUrl, setPopupVideoUrl] = useState("");
  const [dmaPopUp, setDmaPopUp] = useState(false);
  const [iframeWidth, setIframeWidth] = useState(280);
  const [videoLink, setideoLink] = useState(
    [
      {img:'/img/landing/Video-01.jpg',link:'https://www.youtube.com/embed/ACW1JiGRYxs?&index=0&rel=0&wmode=opaque&autoplay=1'},
      {img:'/img/landing/Video-02.jpg',link:'https://www.youtube.com/embed/jl_bJ6CHD5k?&index=0&rel=0&wmode=opaque&autoplay=1'},
    ]
  );
  useEffect(() => {
    window.scrollTo(0, 0);
    var siteLanguage = $.cookie('applang');
    setSiteLang(siteLanguage)
    if(siteLanguage == "eng"){
      document.querySelector("body").classList.add("eng")
    }else{
      document.querySelector("body").classList.remove("eng")
    }
    DailyMarketAnalysisJSON();
  }, []);
  const handleVideoItemClick = (data) => {
    console.log(data)
    setPopupVideoUrl(data)
    setDmaPopUp(true)
    document.querySelector("body").style.overflow = "hidden"
  }
  const closePopUp = () => {
    setDmaPopUp(false)
    setPopupVideoUrl("")
    document.querySelector("body").style.overflow = "auto"
  }


  useEffect(() => {
    getHomePopup();
    var dom = document.getElementById('iframeBox');
    setIframeWidth(dom.offsetWidth)
    window.addEventListener('resize',(e)=>{
      var dom = document.getElementById('iframeBox');
      setIframeWidth(dom.offsetWidth)
    })
  }, []);

  const getHomePopup = async () => {
    let homePopupResult = await api.get(config.homeBanner, true);
    let homeMainBanner = homePopupResult.homedata;
    let items = []; 
    let itemss = [];
    if (homeMainBanner?.length > 0) {
      for (let i = 0; i < homeMainBanner.length; i += 2) {
        let advImgData = await api.get(config.advImgData, true, {
          topbanner: homeMainBanner[i].id,
        });
        items.push({
          imgSrc: advImgData,
          link: homeMainBanner[i].link,
        });
      }
      setPopupData(items);
      for (let j = 1; j < homeMainBanner.length; j += 2) {
        let advImgData = await api.get(config.advImgData, true, {
          topbanner: homeMainBanner[j].id,
        });
        itemss.push({
          imgSrc: advImgData,
          link: homeMainBanner[j].link,
        });
      }
      setPopupDataMobile(itemss);
    }
  };
  const DailyMarketAnalysisJSON = async () => {
    var items = [];
    let data = await api.get(config.dailyMarketAnalysisJSON, true, {
      qid: Date.now(),
    });
    let allData = get(data, "dma_list", []);
    if(allData?.length > 0) {
      for (let i = 0; i < allData.length; i++) {
        let advImgData = await api.get(config.advImgData, true, {
          dmaid: allData[i].id,
        });
        items.push({
          imgSrc: advImgData,
          id: allData[i].id,
          en_caption: allData[i].en_caption,
          en_title: allData[i].en_title,
          image: allData[i].image,
          link: allData[i].link,
          thai_caption: allData[i].thai_caption,
          thai_title: allData[i].thai_title,
        });
      }
    }
    setMarketAnalysis(items)
  }
  const settings = {
    autoplay: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
  };
  const settingss = {
    autoplay: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: false,
    arrows: false,
  };
  // for (let i = 0; i < marketAnalysis.length; i += itemsPerTable) {
    var tableItems = [];
  //   for (let j = i; j < i + itemsPerTable && j < marketAnalysis.length; j += itemsPerRow) {
  //     const rowItems = [];
  //     for (let k = j; k < j + itemsPerRow && k < marketAnalysis.length; k++) {
  //       const speaker = marketAnalysis[k];
  //       rowItems.push(
  //         <td key={speaker.en_title}>
  //           <div className="videorack-item" link={speaker.link} onClick={() => handleVideoItemClick(speaker.link)}>
  //             {speaker.image == "true" ? <img style={{ width: "100%" }} src={speaker.imgSrc} /> : <img style={{ width: "100%" }} src="" />}
  //             <div className="videorack-item-txt">
  //               {siteLang == "eng" ? speaker.thai_title : speaker.en_title}
  //             </div>
  //           </div>
  //         </td>
  //       );
  //     }
  //     tableItems.push(
  //       <tr key={j}>
  //           {rowItems}
  //       </tr>
  //     );
  //   }

  var itemPageNum = 3;
  for(var i =0;i<videoLink.length;i+=itemPageNum){
    var tableItems=[]
    for(var j =i;j<i+itemPageNum&&j<videoLink.length;j++){
      let link = videoLink[j].link
      tableItems.push(
            <td  style={{padding: '0px 15px 0px 15px'}}>
                 <div className="videorack-item"  onClick={() => handleVideoItemClick(link)}>
                    <img style={{ width: "100%" }} src={videoLink[j].img} />
                   <div className="videorack-item-txt">
                   </div>
                 </div>
             </td>
      )
    }
    tableRows.push(
      <div  className="slide-tab ">
        <table>
          <tbody>
            <tr>
            {tableItems}
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
  var xstableItems = [];
  for(var i =0;i<videoLink.length;i++){
    let link = videoLink[i].link
    xstableRows.push(
      // <div style={{ width: "1030px", marginRight: "20px" }} className="slide-tab">
      <div  className="slide-tab">
        <table style={ {margin: '0 auto'}}>
          <tbody>
            <tr>
            <td  style={{padding: '0px 15px 0px 15px'}}>
                 <div className="videorack-item"  onClick={() => handleVideoItemClick(link)}>
                    <img style={{ width: "100%" }} src={videoLink[i].img} />
                   <div className="videorack-item-txt">
                   </div>
                 </div>
             </td>
            </tr>
          </tbody>
        </table>
      </div>
    );
  }
  return (
    <>
      <div id="home" className="landing container-fluid">
        {/* <Slider {...settings} className="show-desk">
          {popupData &&
            popupData.map((data, index) => {
              return (
                <div className="gallery-item" key={index}>
                  <a
                    className="homeimg-achor home_banner1"
                    target="_blank"
                    href={data.link}
                  >
                    <img
                      className="hiddn_mob"
                      style={{ width: "100%", marginTop: 45 }}
                      src={data.imgSrc}
                    />
                  </a>
                </div>
              );
            })}
        </Slider>
        <Slider {...settingss} className="show-mob">
          {popupDataMobile &&
            popupDataMobile.map((data, index) => {
              return (
                <div className="gallery-item" key={index}>
                  <a
                    className="homeimg-achor home_banner1"
                    target="_blank"
                    href={data.link}
                  >
                    <img
                      className="hiddn_mob"
                      style={{ width: "100%", marginTop: 70 }}
                      src={data.imgSrc}
                    />
                  </a>
                </div>
              );
            })}
        </Slider> */}

        <div className="banners">
            <div className="row" style={{margin:'0px',padding:'0px',paddingLeft: '30px'}}>
                <div className="col-md-6" >
                <div className="videoplayer-container" style={{ height: '100%' }}>
                <iframe
                title="Market View"
                frameBorder="0"
                allowFullScreen=""
                style={{height:'100%',width:'100%'}}
                src="https://www.youtube.com/embed/MQfc_MOWuJU?&index=0&rel=0&wmode=opaque&autoplay=1"
                ></iframe>
          </div>
                </div>
                <div className="col-md-5">
                    <h3
                    className={siteLang == "eng"?'title1':''}
                    style={{fontSize:siteLang == "eng"?'32px':'44px',marginBottom:'0px',marginTop:'5px',}}
                      dangerouslySetInnerHTML={{
                        __html:
                          siteLang == "eng"
                            ? languageText.bannerTitle
                            : languageText.bannerTitle,
                      }}
                    ></h3>
                    <h3
                    className={siteLang == "eng"?'title2':''}
                    style={{fontSize:'32px',marginTop:'5px',marginBottom:'0px'}}
                      dangerouslySetInnerHTML={{
                        __html:
                          siteLang == "eng"
                            ? languageText.bannerTitlePEn
                            : languageText.bannerTitleP,
                      }}
                    ></h3>
                    <h3
                    className={siteLang == "eng"?'title3':''}
                    style={{fontSize:'32px',marginTop:'5px',marginBottom:'10px'}}
                      dangerouslySetInnerHTML={{
                        __html:
                          siteLang == "eng"
                            ? languageText.bannerTitleP1En
                            : languageText.bannerTitleP1,
                      }}
                    ></h3>
                    <p
                      dangerouslySetInnerHTML={{
                        __html:
                          siteLang == "eng"
                            ? languageText.bannerTitleP2En
                            : languageText.bannerTitleP2,
                      }}
                    ></p>
                </div>
            </div>
        </div>

        <div className="container-fluid">
            <div className="section" style={{paddingTop:'20px'}}>
            <p style={{marginBottom:'20px',fontFamily: 'DBHeaventLight',fontSize: '25px' }}> <b
            
            dangerouslySetInnerHTML={{
              __html:
                siteLang == "eng"
                  ? languageText.TestimonialTitleEn
                  : languageText.TestimonialTitle,
            }}
            ></b></p>
              <Carousel
                showArrows={true}
                emulateTouch
                showStatus={false}
                autoFocus
                infiniteLoop={false}
                showIndicators={false}
                showThumbs={false}
                className="hidden-xs hidden-sm"
                >
                  {tableRows}
                </Carousel>
              <Carousel    
                showArrows={true}
                emulateTouch
                showStatus={false}
                autoFocus
                infiniteLoop={false}
                showIndicators={false}
                showThumbs={false}
                className="hidden-md hidden-lg"
                >
                  {xstableRows}
                </Carousel>
                {dmaPopUp && <div id="ppboxHTML2" className="overlay" style={{zIndex:'999999'}}>
                  <div className="overlay-inner">
                    <div style={{ position: "relative" }}>
                      <img onClick={closePopUp} className="btn-close" src="/img/bullet/icon-close-white.png" />
                      <div className="popupbox-content">
                        <div className="ppc" key="video">
                          <div className="hotline-layer">
                            <iframe frameBorder={0} allowFullScreen src={popupVideoUrl} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div id="text-content" style={{ display: "none" }}>
                    <div className="text_subscribe_registration_success">
                      คุณได้ทำการสมัครสมาชิกเรียบร้อยแล้ว
                      กรุณาตรวจสอบข้อมูลผู้ใช้และรหัสผ่านได้จากอีเมล์ที่ท่านใช้ในการสมัคร
                    </div>
                  </div>
                </div>}
            </div>
        </div>

        {/* <div className="Seminar">
          <div className="banner-wapper">
            <Link to="/education/seminar">
              <img
                className="banner-img"
                src={"/img/landing/03042024_Seminar_SET_Website_landing_Machao-BYD.jpg"}
              />
            </Link>
          </div>
        </div> */}
        
        <div className="container-fluid">
            <div className="section" style={{bpaddingTop:'20px',background: 'white',marginTop:'0px'}}>
            <p  style={{marginBottom:'20px',fontFamily: 'DBHeaventLight',fontSize: '25px'}}> <b
              dangerouslySetInnerHTML={{
                __html:
                  siteLang == "eng"
                    ? languageText.DWTipsTitleEn
                    : languageText.DWTipsTitle,
              }}
            ></b></p>
            <div className="dWTips flex">
                <div style={{marginRight:'15px'}}>
                    <a href="https://thaidw.com/education/individualtip?id=215&type=index">
                        <img src="/img/landing/4.1_Box1.jpg" alt="" />
                    </a>
                </div>
                <div>
                <a href="https://thaidw.com/education/individualtip?id=203&type=index">
                    <img src="/img/landing/4.1_Box2.jpg" alt="" />
                    </a>
                </div>
            </div>
            <div className="dWTips2 flex" style={{marginTop:'15px'}}>
                <div  style={{marginRight:'15px'}}>
                <a href="https://thaidw.com/education/individualtip?id=206&type=index">
                    <img src="/img/landing/4.1_Box3.jpg" alt="" />
                </a>
                    
                </div>
                <div  style={{marginRight:'15px'}}>
                <a href="https://thaidw.com/education/individualtip?id=191&type=index">
                    <img src="/img/landing/4.1_Box4.jpg" alt="" />
                    </a>
                </div>
                <div>
                <a href="https://thaidw.com/education/individualtip?id=205&type=index">
                    <img src="/img/landing/4.1_Box5.jpg" alt="" />
                    </a>
                </div>
            </div>
            </div>
        </div>
        
        <div className="container-fluid">
            <div className="section" style={{background:'unset',padding:'0px 0px 40px 0px'}}>
                <div className="row subscribe">
                    <div className="col-md-6" style={{background: 'white',paddingLeft:'40px',paddingRight:'40px'}}>
                        <h3>รับข่าวสารเกี่ยวกับ DW ฟรี!</h3>
                        <div>
                        <input type="text" id="email" onChange={(e) => setSubscribeEmailVal(e.target.value)} placeholder={'Email address'} autoComplete="off" />
                        </div>
                        <input type="button" id="submitBtn"  className="btn btn-primary" defaultValue={'สมัครรับจดหมายข่าว คลิก'}  style={{marginTop:'15px'}}/>
                        <p>
                        โดยการกด “ตกลง” คุณยืนยันว่าคุณต้องการสมัครรับจดหมายข่าวรายวันของแมคควอรี การสมัครรับจดหมายข่าว คุณยังจะได้รับข่าวประชาสัมพันธ์และการเชิญชวนให้ร่วมงานสัมมนาเกี่ยวกับใบสำคัญแสดงสิทธิอนุพันธ์หรืองานอื่นๆ ที่เกี่ยวข้อง ผู้ดูแลเว็บไซต์อาจเข้าถึงข้อมูลส่วนบุคคลของคุณเพื่อประมวลผลข้อมูลในนามของ Macquarie Securities (Thailand) Limited และบริษัทอื่นๆ ของเครือ Macquarie (“Macquarie”) คุณสามารถอ่านนโยบายความเป็นส่วนตัวของแมคควอรี เกี่ยวกับการรวบรวม ใช้ เปิดเผย เก็บรักษา และการจัดการข้อมูลส่วนบุคคล รวมถึงสิทธิ์ในส่วนที่เกี่ยวข้องกับข้อมูลส่วนบุคคลของคุณได้ที่หน้าเว็บไซต์ https://www.thaidw.com/about/privacypolicy
                        </p>
                    </div>
                    <div className="col-md-3">
                    <div className="holder-line">
                    <div
                      id="line"
                      className=""
                    >
                      <img src="/img/landing/5.1_Line QR.jpg" alt="" style={{width:'100%',height: '383px'}}/>
                    </div>
                  </div>
                    </div>
                    <div className="col-md-3" style={{padding:'0px'}} id='iframeBox'>
                        <iframe
                            src={`https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fmacquariedw28%2F&tabs=timeline&width=${iframeWidth}&height=375&small_header=false&adapt_container_width=true&hide_cover=false&show_facepile=true&appId`}
                            frameBorder="0"
                            scrolling="no"
                            allowtransparency="true"
                            allow="encrypted-media"
                            height="383"
                            className="fbIframe"
                            style={{ border: "none", overflow: "hidden", width: "100%" }}
                        ></iframe>
                    </div>
                </div>
            </div>
        </div>

        <div className="Seminar">
          <div className="banner-wapper">
            <a href="https://thaidw.com/education/individualtip?id=202&type=index">
              <img 
                className="banner-img"
                src={"/img/landing/040324_Website-Brand_No1.jpg"}
              />
            </a>
          </div>
        </div>
        <div className="sharebtn sm-none xs-none visible-md  visible-lg">
          <ul className="list-unstyled">
          <li>
              <a
                target="_black"
                className="sharebtn-fb btn"
                type="button"
                href="https://www.facebook.com/macquariedw28/"
              />
            </li>
            <li>
              <a
                target="_black"
                className="sharebtn-tl btn"
                type="button"
                href="https://line.me/ti/p/%40upt3914e"
              />
            </li>
            <li>
              <a
              target="_black"
                href="mailto:DW28@macquarie.com"
                className="sharebtn-sb btn"
              />
            </li>
            <li>
              <a target="_black" className="sharebtn-hl btn" href="tel:02 694 7777" />
            </li>
            
            
          </ul>
        </div>
      </div>
    </>
  );
};

export default Landing;
