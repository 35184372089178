import React, { useState, useEffect,Fragment } from 'react';
import WhatAreWarrants from './WhatAreWarrants';
import "../common.css";
import "../investorAcademy.css" 


const WhatAreWarrant = (props) => {
    return ( 
        <Fragment> 
        <WhatAreWarrants/>
        </Fragment> 
     );
}


export default WhatAreWarrant;