import React, { Fragment } from 'react';
import Toptradewarrants from './Toptradewarrants';
import "./toptradewarrants.css" 


const Toptradewarrantss = (props) => {
    return ( 
        <Fragment> 
        <Toptradewarrants/>
        </Fragment> 
     );
}

export default Toptradewarrantss;
