export const languageText ={

    pageTitle: "วิธีการเลือก DW",
    pageTitleEn: "How to select a DW?",

    pageName: "อัตราทด (Gearing)",
    pageNameEn: "Gearing Level",

    pageDescription: "ในการเลือก DW เราแนะนำให้นักลงทุนทำตาม 4 ขั้นตอนนี้",
    pageDescriptionEn: "When choosing a warrant, we suggest that investors consider the following 4 steps:",

    imgUrl: "/img/education/img/fourstep3_th.jpg",
    imgUrlEn: "/img/education/img/fourstep3.jpg",
    
    pageDescription1: "อัตราทด (Gearing) มีความสำคัญเช่นกัน เพราะมันใช้บอกโอกาสในการได้รับผลตอบแทนจากการเคลื่อนไหวของหุ้นอ้างอิง ทั้งนี้ Effective Gearing เป็นอัตราส่วนที่เหมาะสมสำหรับการนำมาใช้มากที่สุด หาก Effective Gearing มีค่าเท่ากับ 5 เท่า นั่นหมายความว่า หากราคาหุ้นอ้างอิงเปลี่ยนแปลงไป 1% ราคา DW จะเปลี่ยนแปลงไปได้ประมาณ 5%",
    pageDescription1En: "The gearing level is also important, as it tells you your effective exposure. Effective gearing is the best ratio to use in this respect. An effective gearing of 5 times means a 1% movement in the shares translates into an approximate 5% movement in the DW.",
    
    pageDescription2: "Effective Gearing ที่สูงขึ้น หมายความว่าคุณมีความเสี่ยงเพิ่มขึ้น และมีโอกาสได้รับผลตอบแทนสูงขึ้น อย่างไรก็ตาม ถ้ามุมมองการลงทุนของคุณผิด คุณก็มีโอกาสที่จะขาดทุนสูงขึ้นเช่นกัน ดังนั้น คุณควรตัดสินใจเลือกระดับ Gearing ที่เหมาะสมกับตัวคุณ",
    pageDescription2En: "The higher the effective gearing, the greater your exposure and the greater your potential returns. However, should you view be wrong, your losses will also be higher. Therefore decide the level of gearing your are comfortable with.",

    pageDescriptionLink: " คลิกที่นี่ ",
    pageDescriptionLinkEn: " click here ",

    pageDescription3: "เพื่ออ่านข้อมูลเพิ่มเติมเกี่ยวกับ Effective Gearing",
    pageDescription3En: "for more information on effective gearing.",

    pageDescription4: "ข้อมูลเพิ่มเติมเกี่ยวกับความสัมพันธ์ระหว่างวันครบกำหนดอายุและระดับ Gearing ที่ส่งผลต่อการกำหนดระดับความเสี่ยงของ DW",
    pageDescription4En: "For information on how the expiry date and gearing level interact to determine risk levels for a DW,",

    pageDescriptionLink1: " คลิกที่นี่ ",
    pageDescriptionLink1En: " click here ",

    imgUrl1: "/img/education/img/gearinglevel.png",
    imgUrl1En: "/img/education/img/gearinglevel.png",
    
}