import React, { useState, useEffect,Fragment } from 'react';
import GearingEffects from './GearingEffects';
import "../../common.css";
import "../../investorAcademy.css";


const GearingEffect = (props) => {
    return ( 
        <Fragment> 
        <GearingEffects/>
        </Fragment> 
     );
}


export default GearingEffect;