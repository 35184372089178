import React, { Fragment } from 'react';
import Toptradedstock from './Toptradedstock';
import "./toptradedstock.css" 


const Toptradedstocks = (props) => {
    return ( 
        <Fragment> 
        <Toptradedstock/>
        </Fragment> 
     );
}

export default Toptradedstocks;
