import React, { useState, useEffect,Fragment } from 'react';
import CallExpiryFormulas from './CallExpiryFormulas';
import "../../common.css";
import "../../investorAcademy.css";


const CallExpiryFormula = (props) => {
    return ( 
        <Fragment> 
            <CallExpiryFormulas/>
        </Fragment> 
     );
}


export default CallExpiryFormula;