import React from 'react';
import {Carousel} from 'react-responsive-carousel';
import { speakerList } from "../DwVideo/SpeakerData"

const SpeakerCarousel = ({playListId, activeGsVideo}) => {
    
    const itemsPerRow = 5; // Number of items per row
    const itemsPerTable = 5; // Number of items per table
    const tableRows = [];

    const setActiveData = (data) => {
      activeGsVideo(data)
    }

    var filterData = speakerList.filter((e) => {
      if(playListId != e.playlistId){
        return e
      }
    })

    for (let i = 0; i < filterData.length; i += itemsPerTable) {
        const tableItems = [];
        for (let j = i; j < i + itemsPerTable && j < filterData.length; j += itemsPerRow) {
          const rowItems = [];
          for (let k = j; k < j + itemsPerRow && k < filterData.length; k++) {
            const speaker = filterData[k];
            rowItems.push(
              <td key={speaker.name}>
                <div className="videorack-item" playlistid={speaker.playlistId} index={k} onClick={() => setActiveData(speaker)}>
                  <img style={{ width: "100%" }} src={speaker.image} alt={speaker.name} />
                  <div className="videorack-item-txt">
                    {speaker.text}
                  </div>
                </div>
              </td>
            );
          }
          while (rowItems.length < itemsPerRow) {
            rowItems.push(
              <td>
                <div className="videorack-item videorack-item-hidden">
                </div>
              </td>
            );
          }
          tableItems.push(
            <tr key={j}>
                {rowItems}
            </tr>
          );
        }
        tableRows.push(
            <div key={i} className="slide-tab">
              <table className={`videorack-page ${i > 0 ? 'slide-tab' : ''}`}>
                <tbody>
                  {tableItems}
                </tbody>
              </table>
            </div>
        );
    }

    return (
        <Carousel
        showArrows={true}
        emulateTouch
        showStatus={false}
        autoFocus
        infiniteLoop={false}
        showIndicators={false}
        showThumbs={false}
        >
            {tableRows}
        </Carousel>
    );
};

export default SpeakerCarousel;