import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import API from "../../../utils/apiCalling";
import { get } from "lodash";
import { config } from "../../../utils/apiUrl";
import { languageText } from "../../../languageText/faq"
import DwVideoSideBars from "../../../components/common/Sidebar/DwVideoSideBar"
import AdvertiseMentSidebar from "../../../components/common/Sidebar/AdvertiseMentSidebar"
import DwEduHtmlSideBars from "../../../components/common/Sidebar/InvestorAcademy"
import IndexDws from "../../../components/common/Sidebar/IndexDw"

const FreqAskedQues = (props) => {

  const api = new API();
  const [faq, setfaq] = useState([]);
  const [siteLang , setSiteLang] = useState("")

  useEffect(() => {
    window.scrollTo(0, 0);
    var siteLanguage = $.cookie('applang');
    setSiteLang(siteLanguage)
    if(siteLanguage == "eng"){
      document.querySelector("body").classList.add("eng")
    }else{
      document.querySelector("body").classList.remove("eng")
    }
    getfaq();
  }, []);

  const getfaq = async () => {
    let faq = await api.get(config.marketfaq, true, {
      type: "list",
      qid: Date.now(),
    });
    let faqt = get(faq, "newsList", []);
    const { faqList } = faq;
    setfaq(faqList);
  };

  return (
    <div id="faq" className="pageobj">
      <div className="breadcrumbbar">
        <div className="container-fluid">
          <ol className="breadcrumb">
            <li>
              <Link to="/home?dwedu">
                {siteLang == "eng" ? languageText.firstNavigationEn : languageText.firstNavigation}
              </Link>
            </li>
            <li className="active">
              {siteLang == "eng" ? languageText.secondNavigationEn : languageText.secondNavigation}
            </li>
          </ol>
        </div>
      </div>
      <div className="stripe-bg" />
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12 hidden-sm hidden-xs">
            <img lt="image" style={{ width: "100%" }} src="/img/education/u18433.jpg" />
          </div>
          <div className="col-md-8">
            <div className="section">
              <div id="page-header" className="page-header">
                <h1>{siteLang == "eng" ? languageText.pageTitleEn : languageText.pageTitle}</h1>
              </div>
              <p>
                {siteLang == "eng" ? languageText.pageDescriptionEn : languageText.pageDescription}
              </p>
              <div id="question-header" className="question-header color-primary" >
                {siteLang == "eng" ? languageText.questionHeaderEn : languageText.questionHeader}
              </div>
              <div className="question-list">
                {faq.map(({ id, en_title, thai_title }, index) => {
                  return (
                    <Link
                      to={"/education/innerfaq?id=" + id}
                      activeclass="active"
                      id={index}
                      key={index}
                    >
                      <div id={id}>
                        {siteLang == "eng" ? (
                          <span>{en_title}</span>
                        ) : (
                          <span>{thai_title}</span>
                        )}
                        <img alt="image" src="/img/bullet/qlink-achor.png" />
                      </div>
                    </Link>
                  );
                })}
              </div>
              <hr className="hr-hmgbm" />
              <p className="small">
              {siteLang == "eng" ? languageText.marketDelayEn : languageText.marketDelay}
              </p>
            </div>
          </div>
          <div className="hidden-xs hidden-sm col-md-4">
            <DwVideoSideBars />
            <DwEduHtmlSideBars />
            <IndexDws />
            <AdvertiseMentSidebar />
          </div>
        </div>
      </div>
    </div>
  );
};
export default FreqAskedQues;
