import React, { useState, useEffect,Fragment } from 'react';
import HowPutWarrantWorks from './HowPutWarrantWorks';
import "../../common.css";
import "../../investorAcademy.css";


const HowPutWarrantWork = (props) => {
    return ( 
        <Fragment> 
            <HowPutWarrantWorks/>
        </Fragment> 
     );
}


export default HowPutWarrantWork;