import React, { useEffect, useState } from "react";
import { useContext } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import logo_img from "../../../assets/images/mqlogo_bar.png";
import menu_img from "../../../assets/images/education/menu.png";
import LangContext from "../context/lang-context";
import "./Header.css";

const HeaderMq = () => {

  const location = useLocation();
  const [visible, setvisible] = useState("none");
  const history = useHistory();
  const langContext = useContext(LangContext);
  const [language, setLanguage] = useState("thai");
  const [tradeToggle, setTradeToggle] = useState(false);
  const [areToggle, setAreToggle] = useState(false);
  const [dwPriced, setDwPriced] = useState(false);
  const [dwTerms, setDwTerms] = useState(false);
  const [selectDw, setSelectDw] = useState(false);
  const [dwExpiry, setDwExpiry] = useState(false);
  const [risks, setRisks] = useState(false);
  const [liquidity, setLiquidity] = useState(false);
  const [modal, setModal] = useState(false);
  const [siteLang , setSiteLang] = useState("")

  useEffect(() => {
    var path = location.pathname;
    var siteLanguage = $.cookie('applang');
    setSiteLang(siteLanguage)
    if(siteLanguage == "eng"){
      document.querySelector("body").classList.add("eng")
    }else{
      document.querySelector("body").classList.remove("eng")
    }
    var patharray = path.split("/");
    if (patharray[1] == "eduhtml" && patharray.length == 2) {
      setvisible("none");
    } else {
      setvisible("block");
    }
  }, [location]);


  return (
    <>
      <header>
        <div
          id="top"
          style={{
            position: "fixed",
            display: "table",
            width: "100%",
            backgroundColor: "black",
            borderBottom: "1px solid #575757",
            maxHeight: 50,
            zIndex: 99,
          }}
        >
          <div style={{ float: "left" }}>
            <a href="/home">
              <img style={{ border: "none", height: "60px" }} alt="Macquarie Logo" src={logo_img}
              />
            </a>
          </div>

          {visible == "block" ? <div
            className="hidden-xs"
            style={{ float: "right", position: "relative", right: "15px", top: "17px",}}
            onClick={() => {
              setModal(true);
            }}
          >
            <img
              id="menubtn"
              style={{ cursor: "pointer" }}
              height="20px"
              src={menu_img}
            />
          </div> : ""}

          {visible == "block" ? <div
            className="visible-xs"
            style={{ float: "right", position: "relative", right: "15px", top: "19px",}}
            onClick={() => {
              setModal(true);
            }}
          >
            <img
              id="menubtn"
              style={{ cursor: "pointer" }}
              height="20px"
              src={menu_img}
            />
          </div> : ""}

          <div
            className="hidden-xs hidden-xxs visible-sm visible-md visible-lg"
            style={{
              width: "2px",
              height: "30px",
              float: "left",
              backgroundColor: "#FFFFFF",
              margin: "15px 12px 15px 6px",
            }}
          />
          <div
            className="titlelbl hidden-xs hidden-xxs visible-sm visible-md visible-lg"
            style={{
              height: "60px",
              color: "#FFD843",
              lineHeight: "58px",
              float: "left",
              fontFamily: "MCQGlobal_A_Rg",
              fontSize: 27,
              letterSpacing: "1px",
            }}
          >
            Thailand Warrants Education
          </div>
        </div>
      </header>

      <div
        id="menu"
        style={{
          position: "relative",
          left: 0,
          right: 0,
          minHeight: "100vh",
          opacity: "0.9",
          zIndex: 999999,
          backgroundColor: "black",
          display: modal ? "flex" : "none",
          fontSize: "14px",
          lineHeight: "1.42857143",
          alignItems: "center",
        }}
      >
        <div
          id="closebtn"
          style={{
            cursor: "pointer",
            position: "absolute",
            top: "0px",
            right: "12px",
            width: "58px",
            height: "58px",
            background: "url('/edu/img/btn_close.png')",
          }}
          onClick={() => {
            setModal(false);
          }}
        />

        <table style={{ width: "100%", height: "100%", tableLayout: "fixed" }}>
          <tbody>
            <tr>
              <td>
                <table
                  id="menucontent"
                  style={{
                    width: "100%",
                    tableLayout: "fixed",
                    textAlign: "center",
                    lineHeight: "normal",
                    fontFamily: siteLang == "eng" ? "MCQGlobal_A_Rg" : "'DBHeaventLight','DBHeavent','Roboto', Arial, sans-serif",
                  }}
                >
                  <tbody>
                    <tr>
                      <td
                        style={{
                          color: "#FFD843",
                          
                          lineHeight: "36px",
                          fontSize: "25px",
                          letterSpacing: "1px",
                          paddingBottom: "20px",
                          paddingTop: "80px",
                        }}
                      >
                        Education Topics
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <Link
                          className="accordion-top"
                          onClick={() => {
                            setModal(false);
                            areToggle ? setAreToggle(false) : setAreToggle();
                            tradeToggle
                              ? setTradeToggle(false)
                              : setTradeToggle();
                            dwPriced ? setDwPriced(false) : setDwPriced();
                            dwTerms ? setDwTerms(false) : setDwTerms();
                            selectDw ? setSelectDw(false) : setSelectDw();
                            dwExpiry ? setDwExpiry(false) : setDwExpiry();
                            risks ? setRisks(false) : setRisks();
                            liquidity ? setLiquidity(false) : setLiquidity();
                          }}
                          to="/eduhtml"
                          style={{
                            cursor: "pointer",
                            textAlign: "center",
                            fontSize: "24px",
                            
                            color: "#FFD843",
                          }}
                        >
                          {siteLang == "eng" ? "Home " : "หน้าหลัก "}
                        </Link>
                      </td>
                    </tr>
                    <tr>
                      <td style={{ paddingBottom: "200px" }}>
                        <div
                          className="accordion"
                          style={{
                            cursor: "pointer",
                            textAlign: "center",
                            fontSize: "24px",
                            
                            left: "60px",
                            color: "#FFD843",
                          }}
                          onClick={() => {
                            tradeToggle
                              ? setTradeToggle(false)
                              : setTradeToggle(true);
                            areToggle ? setAreToggle(false) : setAreToggle();
                            dwPriced ? setDwPriced(false) : setDwPriced();
                            dwTerms ? setDwTerms(false) : setDwTerms();
                            selectDw ? setSelectDw(false) : setSelectDw();
                            dwPriced ? setDwPriced(false) : setDwPriced();
                            dwExpiry ? setDwExpiry(false) : setDwExpiry();
                            risks ? setRisks(false) : setRisks();
                            liquidity ? setLiquidity(false) : setLiquidity();
                          }}
                        >
                          {siteLang == "eng" ? "Why Trade DW?" : '"ทำไมต้องลงทุนใน DW?"'}
                        </div>
                        <div
                          className="subtopiclist"
                          id="submenu"
                          style={{ display: tradeToggle ? "block" : "none" }}
                        >
                          <Link
                            to="/eduhtml/whytradewarrants"
                            className="subtopic"
                            onClick={() => {
                              setModal(false);
                              tradeToggle
                                ? setTradeToggle(false)
                                : setTradeToggle();
                            }}
                            style={{
                              cursor: "pointer",
                              textAlign: "center",

                              fontSize: "17px",
                              
                              paddingTop: "4px",
                              display: "block",
                              color: "rgb(51 51 51)",
                            }}
                          >
                            {siteLang == "eng" ? "Introduction" : "บทนำ"}
                          </Link>
                          <Link
                            to="/eduhtml/gearingeffect"
                            className="subtopic"
                            onClick={() => {
                              setModal(false);
                              tradeToggle
                                ? setTradeToggle(false)
                                : setTradeToggle();
                            }}
                            style={{
                              cursor: "pointer",
                              textAlign: "center",
                              fontSize: "17px",
                              
                              paddingTop: "4px",
                            }}
                          >
                            {siteLang == "eng" ? "Gearing Effect" : "อัตราทด (Gearing Effect)"}
                          </Link>
                          <Link
                            className="subtopic"
                            onClick={() => {
                              setModal(false);
                              tradeToggle
                                ? setTradeToggle(false)
                                : setTradeToggle();
                            }}
                            to="/eduhtml/limitedloss"
                            style={{
                              cursor: "pointer",
                              textAlign: "center",
                              fontSize: "17px",
                              
                              left: 50,
                              paddingTop: "2px",
                            }}
                          >
                            {siteLang == "eng" ? "Limited loss" : "จำกัดขาดทุน"}
                          </Link>
                          <Link
                            className="subtopic"
                            onClick={() => {
                              setModal(false);
                              tradeToggle
                                ? setTradeToggle(false)
                                : setTradeToggle();
                            }}
                            to="/eduhtml/porfitfrombullorbearmarket"
                            style={{
                              cursor: "pointer",
                              textAlign: "center",
                              fontSize: "17px",
                              
                              paddingTop: "2px",
                            }}
                          >
                            {siteLang == "eng" ? "Ability to profit from rises and falls" : "ความสามารถในการทำกำไรจากการขาขึ้นและลง"}
                          </Link>
                          <Link
                            className="subtopic"
                            onClick={() => {
                              setModal(false);
                              tradeToggle
                                ? setTradeToggle(false)
                                : setTradeToggle();
                            }}
                            to="/eduhtml/nomargincall"
                            style={{
                              cursor: "pointer",
                              textAlign: "center",
                              fontSize: "17px",
                              
                              paddingTop: "2px",
                            }}
                          >
                            {" "}
                            {siteLang == "eng" ? " No margin call" : " ไม่มีการเรียกหลักประกันเพิ่ม"}
                          </Link>
                          <Link
                            className="subtopic"
                            onClick={() => {
                              setModal(false);
                              tradeToggle
                                ? setTradeToggle(false)
                                : setTradeToggle();
                            }}
                            to="/eduhtml/lowercapitaloutlay"
                            style={{
                              cursor: "pointer",
                              textAlign: "center",
                              fontSize: "17px",
                              
                              paddingTop: "2px",
                            }}
                          >
                            {" "}
                            {siteLang == "eng" ? " Lower capital outlay" : " ใช้เงินลงทุนต่ำกว่า"}
                          </Link>
                          <Link
                            className="subtopic"
                            onClick={() => {
                              setModal(false);
                              tradeToggle
                                ? setTradeToggle(false)
                                : setTradeToggle();
                            }}
                            to="/eduhtml/releasingsharecapital"
                            style={{
                              cursor: "pointer",
                              textAlign: "center",
                              fontSize: "17px",
                              
                              paddingTop: "2px",
                            }}
                          >
                            {" "}
                            {siteLang == "eng" ? " Releasing share capital" : " ลดจำนวนเงินลงทุนในหลักทรัพย์"}
                          </Link>
                          <Link
                            className="subtopic"
                            onClick={() => {
                              setModal(false);
                              tradeToggle
                                ? setTradeToggle(false)
                                : setTradeToggle();
                            }}
                            to="/eduhtml/easeoftrade"
                            style={{
                              cursor: "pointer",
                              textAlign: "center",
                              fontSize: "17px",
                              
                              paddingTop: "2px",
                            }}
                          >
                            {" "}
                            {siteLang == "eng" ? " Ease of trade" : " ซื้อง่ายขายคล่อง"}
                          </Link>
                        </div>
                        <div
                          className="accordion"
                          style={{
                            cursor: "pointer",
                            textAlign: "center",
                            fontSize: "24px",
                            
                            left: "60px",
                            color: "#FFD843",
                            paddingTop: "3px",
                          }}
                          onClick={() => {
                            areToggle
                              ? setAreToggle(false)
                              : setAreToggle(true);
                            tradeToggle
                              ? setTradeToggle(false)
                              : setTradeToggle();
                            dwPriced ? setDwPriced(false) : setDwPriced();
                            dwTerms ? setDwTerms(false) : setDwTerms();
                            selectDw ? setSelectDw(false) : setSelectDw();
                            dwExpiry ? setDwExpiry(false) : setDwExpiry();
                            risks ? setRisks(false) : setRisks();
                            liquidity ? setLiquidity(false) : setLiquidity();
                          }}
                        >
                          {siteLang == "eng" ? " What are DW?" : " DW คืออะไร?"}
                        </div>
                        <div
                          className="subtopiclist"
                          style={{ display: areToggle ? "block" : "none" }}
                        >
                          <Link
                            className="subtopic"
                            onClick={() => {
                              setModal(false);
                              areToggle ? setAreToggle(false) : setAreToggle();
                            }}
                            to="/eduhtml/whatarewarrants"
                            style={{
                              cursor: "pointer",
                              textAlign: "center",
                              fontSize: "17px",
                              
                              paddingTop: "4px",
                            }}
                          >
                            {siteLang == "eng" ? " Introduction" : " บทนำ "}
                          </Link>

                          <Link
                            className="subtopic"
                            onClick={() => {
                              setModal(false);
                              areToggle ? setAreToggle(false) : setAreToggle();
                            }}
                            to="/eduhtml/callvsput"
                            style={{
                              cursor: "pointer",
                              textAlign: "center",
                              fontSize: "17px",
                              
                              paddingTop: "4px",
                            }}
                          >
                            Call vs Put
                          </Link>
                          <Link
                            className="subtopic"
                            onClick={() => {
                              setModal(false);
                              areToggle ? setAreToggle(false) : setAreToggle();
                            }}
                            to="/eduhtml/howacallwarrantworks"
                            style={{
                              cursor: "pointer",
                              textAlign: "center",
                              fontSize: "17px",
                              
                              paddingTop: "2px",
                            }}
                          >
                            {siteLang == "eng" ? " How a call Warrant works?" : " Call DW ทำงานอย่างไร"}
                          </Link>
                          <Link
                            className="subtopic"
                            onClick={() => {
                              setModal(false);
                              areToggle ? setAreToggle(false) : setAreToggle();
                            }}
                            to="/eduhtml/howaputwarrantworks"
                            style={{
                              cursor: "pointer",
                              textAlign: "center",
                              fontSize: "17px",
                              
                              paddingTop: "2px",
                            }}
                          >
                            {siteLang == "eng" ? " How a put Warrant works?" : " Put DW ทำงานอย่างไร"}
                          </Link>
                          <Link
                            className="subtopic"
                            onClick={() => {
                              setModal(false);
                              areToggle ? setAreToggle(false) : setAreToggle();
                            }}
                            to="/eduhtml/howarewarrantsnamed"
                            style={{
                              cursor: "pointer",
                              textAlign: "center",
                              fontSize: "17px",
                              
                              paddingTop: "2px",
                            }}
                          >
                            {siteLang == "eng" ? "How are Warrants named?" : "สัญลักษณ์ของ DW เป็นอย่างไร"}
                          </Link>
                        </div>
                        <div
                          className="accordion"
                          style={{
                            cursor: "pointer",
                            textAlign: "center",
                            fontSize: "24px",
                            
                            left: "60px",
                            color: "#FFD843",
                            paddingTop: "3px",
                          }}
                          onClick={() => {
                            dwPriced ? setDwPriced(false) : setDwPriced(true);
                            tradeToggle
                              ? setTradeToggle(false)
                              : setTradeToggle();
                            areToggle ? setAreToggle(false) : setAreToggle();
                            dwTerms ? setDwTerms(false) : setDwTerms();
                            selectDw ? setSelectDw(false) : setSelectDw();
                            dwExpiry ? setDwExpiry(false) : setDwExpiry();
                            risks ? setRisks(false) : setRisks();
                            liquidity ? setLiquidity(false) : setLiquidity();
                          }}
                        >
                          {siteLang == "eng" ? " How are DW priced?" : " ราคา DW คำนวณอย่างไร?"}
                        </div>
                        <div
                          className="subtopiclist"
                          style={{ display: dwPriced ? "block" : "none" }}
                        >
                          <Link
                            className="subtopic"
                            onClick={() => {
                              setModal(false);
                              dwPriced ? setDwPriced(false) : setDwPriced();
                            }}
                            to="/eduhtml/howarewarrantspriced"
                            style={{
                              cursor: "pointer",
                              textAlign: "center",
                              fontSize: "17px",
                              
                              paddingTop: "4px",
                            }}
                          >
                            {siteLang == "eng" ? " Introduction" : " บทนำ"}
                          </Link>
                          <Link
                            className="subtopic"
                            onClick={() => {
                              setModal(false);
                              dwPriced ? setDwPriced(false) : setDwPriced();
                            }}
                            to="/eduhtml/shareprice"
                            style={{
                              cursor: "pointer",
                              textAlign: "center",
                              fontSize: "17px",
                              
                              paddingTop: "4px",
                            }}
                          >
                            {siteLang == "eng" ? " Stock Price" : " ราคาหุ้นอ้างอิง"}
                          </Link>
                          <Link
                            className="subtopic"
                            onClick={() => {
                              setModal(false);
                              dwPriced ? setDwPriced(false) : setDwPriced();
                            }}
                            to="/eduhtml/exerciseprice"
                            style={{
                              cursor: "pointer",
                              textAlign: "center",
                              fontSize: "17px",
                              
                              paddingTop: "2px",
                            }}
                          >
                            {siteLang == "eng" ? "Exercise Price" : "ราคาใช้สิทธิ"}
                          </Link>
                          <Link
                            className="subtopic"
                            onClick={() => {
                              setModal(false);
                              dwPriced ? setDwPriced(false) : setDwPriced();
                            }}
                            to="/eduhtml/expirydate"
                            style={{
                              cursor: "pointer",
                              textAlign: "center",
                              fontSize: "17px",
                              
                              paddingTop: "2px",
                            }}
                          >
                            {siteLang == "eng" ? "Expiry Date " : "วันครบกำหนดอายุ "}
                          </Link>
                          <Link
                            className="subtopic"
                            onClick={() => {
                              setModal(false);
                              dwPriced ? setDwPriced(false) : setDwPriced();
                            }}
                            to="/eduhtml/volatility"
                            style={{
                              cursor: "pointer",
                              textAlign: "center",
                              fontSize: "17px",
                              
                              paddingTop: "2px",
                            }}
                          >
                            {siteLang == "eng" ? " Volatility" : " ความผันผวน"}
                          </Link>
                          <Link
                            className="subtopic"
                            onClick={() => {
                              setModal(false);
                              dwPriced ? setDwPriced(false) : setDwPriced();
                            }}
                            to="/eduhtml/interestrates"
                            style={{
                              cursor: "pointer",
                              textAlign: "center",
                              fontSize: "17px",
                              
                              paddingTop: "2px",
                            }}
                          >
                            {siteLang == "eng" ? " Interest Rates" : " อัตราดอกเบี้ย"}
                          </Link>
                        </div>
                        <Link
                          className="accordion"
                          onClick={() => {
                            setModal(false);
                            areToggle ? setAreToggle(false) : setAreToggle();
                            tradeToggle
                              ? setTradeToggle(false)
                              : setTradeToggle();
                            dwPriced ? setDwPriced(false) : setDwPriced();
                            dwTerms ? setDwTerms(false) : setDwTerms();
                            selectDw ? setSelectDw(false) : setSelectDw();
                            dwPriced ? setDwPriced(false) : setDwPriced();
                            dwExpiry ? setDwExpiry(false) : setDwExpiry();
                            risks ? setRisks(false) : setRisks();
                            liquidity ? setLiquidity(false) : setLiquidity();
                          }}
                          to="/eduhtml/howarewarrantsnamed"
                          style={{
                            cursor: "pointer",
                            textAlign: "center",
                            fontSize: "24px",
                            
                            left: "60px",
                            color: "#FFD843",
                            paddingTop: "3px",
                          }}
                        >
                          {siteLang == "eng" ? "How are DW named?" : "สัญลักษณ์ของ DW เป็นอย่างไร?"}
                        </Link>
                        <div className="subtopiclist" />
                        <div
                          className="accordion"
                          style={{
                            cursor: "pointer",
                            textAlign: "center",
                            fontSize: "24px",
                            
                            left: "60px",
                            color: "#FFD843",
                            paddingTop: "3px",
                          }}
                          onClick={() => {
                            dwTerms ? setDwTerms(false) : setDwTerms(true);
                            tradeToggle
                              ? setTradeToggle(false)
                              : setTradeToggle();
                            dwPriced ? setDwPriced(false) : setDwPriced();
                            selectDw ? setSelectDw(false) : setSelectDw();
                            dwExpiry ? setDwExpiry(false) : setDwExpiry();
                            risks ? setRisks(false) : setRisks();
                            liquidity ? setLiquidity(false) : setLiquidity();
                            areToggle ? setAreToggle(false) : setAreToggle();
                          }}
                        >
                          {siteLang == "eng" ? "Useful DW terms" : "ตัวแปรเกี่ยวกับ DW ที่ควรรู้"}
                        </div>
                        <div
                          className="subtopiclist"
                          style={{
                            display: dwTerms ? "block" : "none",
                          }}
                        >
                          <Link
                            className="subtopic"
                            onClick={() => {
                              setModal(false);
                              dwTerms ? setDwTerms(false) : setDwTerms();
                            }}
                            to="/eduhtml/usefulwarrantterms"
                            style={{
                              cursor: "pointer",
                              textAlign: "center",
                              fontSize: "17px",
                              
                              paddingTop: "4px",
                            }}
                          >
                            {siteLang == "eng" ? "Introduction" : "บทนำ"}
                          </Link>
                          <Link
                            className="subtopic"
                            onClick={() => {
                              setModal(false);
                              dwTerms ? setDwTerms(false) : setDwTerms();
                            }}
                            to="/eduhtml/delta"
                            style={{
                              cursor: "pointer",
                              textAlign: "center",
                              fontSize: "17px",
                              
                              paddingTop: "4px",
                            }}
                          >
                            {siteLang == "eng" ? " Delta " : "เดลต้า (Delta)"}
                          </Link>
                          <Link
                            className="subtopic"
                            onClick={() => {
                              setModal(false);
                              dwTerms ? setDwTerms(false) : setDwTerms();
                            }}
                            to="/eduhtml/exerciseratio"
                            style={{
                              cursor: "pointer",
                              textAlign: "center",
                              fontSize: "17px",
                              
                              paddingTop: "2px",
                            }}
                          >
                            {siteLang == "eng" ? " DW Per Share(DWPS) " : "DW ต่อหุ้น (DWPS)"}
                          </Link>
                          <Link
                            className="subtopic"
                            onClick={() => {
                              setModal(false);
                              dwTerms ? setDwTerms(false) : setDwTerms();
                            }}
                            to="/eduhtml/warrantsensitivity"
                            style={{
                              cursor: "pointer",
                              textAlign: "center",
                              fontSize: "17px",
                              
                              paddingTop: "2px",
                            }}
                          >
                            DW Sensitivity
                          </Link>
                          <Link
                            className="subtopic"
                            onClick={() => {
                              setModal(false);
                              dwTerms ? setDwTerms(false) : setDwTerms();
                            }}
                            to="/eduhtml/breakevenprice"
                            style={{
                              cursor: "pointer",
                              textAlign: "center",
                              fontSize: "17px",
                              
                              paddingTop: "2px",
                            }}
                          >
                            {siteLang == "eng" ? " Breakeven Price  " : "จุดคุ้มทุน (Breakeven Price)"}
                          </Link>
                          <Link
                            className="subtopic"
                            onClick={() => {
                              setModal(false);
                              dwTerms ? setDwTerms(false) : setDwTerms();
                            }}
                            to="/eduhtml/premium"
                            style={{
                              cursor: "pointer",
                              textAlign: "center",
                              fontSize: "17px",
                              
                              paddingTop: "2px",
                            }}
                          >
                            {siteLang == "eng" ? " Premium  " : "Premium หรือ Time Value"}
                          </Link>
                          <Link
                            className="subtopic"
                            onClick={() => {
                              setModal(false);
                              dwTerms ? setDwTerms(false) : setDwTerms();
                            }}
                            to="/eduhtml/gearing"
                            style={{
                              cursor: "pointer",
                              textAlign: "center",
                              fontSize: "17px",
                              
                              paddingTop: "2px",
                            }}
                          >
                            {siteLang == "eng" ? "  Gearing  " : "อัตราทด (Gearing)"}
                          </Link>
                          <Link
                            className="subtopic"
                            onClick={() => {
                              setModal(false);
                              dwTerms ? setDwTerms(false) : setDwTerms();
                            }}
                            to="/eduhtml/effectivegearing"
                            style={{
                              cursor: "pointer",
                              textAlign: "center",
                              fontSize: "17px",
                              
                              paddingTop: "2px",
                            }}
                          >
                            Effective Gearing
                          </Link>
                          <Link
                            className="subtopic"
                            onClick={() => {
                              setModal(false);
                              dwTerms ? setDwTerms(false) : setDwTerms();
                            }}
                            to="/eduhtml/impliedvolatility"
                            style={{
                              cursor: "pointer",
                              textAlign: "center",
                              fontSize: "17px",
                              
                              paddingTop: "2px",
                            }}
                          >
                            {siteLang == "eng" ? "  Implied Volatility  " : "ความผันผวนแฝง (Implied Volatility)"}
                          </Link>
                        </div>
                        <div
                          className="accordion"
                          style={{
                            cursor: "pointer",
                            textAlign: "center",
                            fontSize: "24px",
                            
                            left: "60px",
                            color: "#FFD843",
                            paddingTop: "3px",
                          }}
                          onClick={() => {
                            selectDw ? setSelectDw(false) : setSelectDw(true);
                            dwTerms ? setDwTerms(false) : setDwTerms();
                            tradeToggle
                              ? setTradeToggle(false)
                              : setTradeToggle();
                            dwPriced ? setDwPriced(false) : setDwPriced();
                            dwExpiry ? setDwExpiry(false) : setDwExpiry();
                            risks ? setRisks(false) : setRisks();
                            liquidity ? setLiquidity(false) : setLiquidity();
                            areToggle ? setAreToggle(false) : setAreToggle();
                          }}
                        >
                          {siteLang == "eng" ? "  How to select a DW?   " : "วิธีการเลือก DW"}
                        </div>
                        <div
                          className="subtopiclist"
                          style={{
                            display: selectDw ? "block" : "none",
                          }}
                        >
                          <Link
                            className="subtopic"
                            onClick={() => {
                              setModal(false);
                              selectDw ? setSelectDw(false) : setSelectDw();
                            }}
                            to="/eduhtml/howtoselectawarrant"
                            style={{
                              cursor: "pointer",
                              textAlign: "center",
                              fontSize: "17px",
                              
                              paddingTop: "4px",
                            }}
                          >
                            {siteLang == "eng" ? "  Introduction   " : "บทนำ"}
                          </Link>
                          <Link
                            className="subtopic"
                            onClick={() => {
                              setModal(false);
                              selectDw ? setSelectDw(false) : setSelectDw();
                            }}
                            to="/eduhtml/ fourviewonunderlyingshareorindex"
                            style={{
                              cursor: "pointer",
                              textAlign: "center",
                              fontSize: "17px",
                              
                              paddingTop: "4px",
                            }}
                          >
                            Selecting a warrant
                          </Link>
                          <Link
                            className="subtopic"
                            onClick={() => {
                              setModal(false);
                              selectDw ? setSelectDw(false) : setSelectDw();
                            }}
                            to="/eduhtml/qualityofamarketmaker"
                            style={{
                              cursor: "pointer",
                              textAlign: "center",
                              fontSize: "17px",
                              
                              paddingTop: "2px",
                            }}
                          >
                            {siteLang == "eng" ? "  Market maker   " : "คุณภาพของผู้ดูแลสภาพคล่อง"}
                          </Link>
                          <Link
                            className="subtopic"
                            onClick={() => {
                              setModal(false);
                              selectDw ? setSelectDw(false) : setSelectDw();
                            }}
                            to="/eduhtml/riskmatrix"
                            style={{
                              cursor: "pointer",
                              textAlign: "center",
                              fontSize: "17px",
                              
                              paddingTop: "2px",
                            }}
                          >
                            {siteLang == "eng" ? " Risk Matrix   " : "Matrix ความเสี่ยง"}
                          </Link>
                          <Link
                            className="subtopic"
                            onClick={() => {
                              setModal(false);
                              selectDw ? setSelectDw(false) : setSelectDw();
                            }}
                            to="/eduhtml/tipsandtools"
                            style={{
                              cursor: "pointer",
                              textAlign: "center",
                              fontSize: "17px",
                              
                              paddingTop: "2px",
                            }}
                          >
                            {siteLang == "eng" ? "  Tips and tools    " : "เคล็ดลับและเครื่องมือ"}
                          </Link>
                        </div>
                        <div
                          className="accordion"
                          style={{
                            cursor: "pointer",
                            textAlign: "center",
                            fontSize: "24px",
                            
                            left: "60px",
                            color: "#FFD843",
                            paddingTop: "3px",
                          }}
                          onClick={() => {
                            dwExpiry ? setDwExpiry(false) : setDwExpiry(true);
                            dwTerms ? setDwTerms(false) : setDwTerms();
                            tradeToggle
                              ? setTradeToggle(false)
                              : setTradeToggle();
                            dwPriced ? setDwPriced(false) : setDwPriced();
                            selectDw ? setSelectDw(false) : setSelectDw();
                            risks ? setRisks(false) : setRisks();
                            liquidity ? setLiquidity(false) : setLiquidity();
                            areToggle ? setAreToggle(false) : setAreToggle();
                          }}
                        >
                          {siteLang == "eng" ? "  DW at expiry   " : "DW ณ วันครบกำหนดอายุ"}
                        </div>
                        <div
                          className="subtopiclist"
                          style={{
                            display: dwExpiry ? "block" : "none",
                          }}
                        >
                          <Link
                            className="subtopic"
                            onClick={() => {
                              setModal(false);
                              dwExpiry ? setDwExpiry(false) : setDwExpiry();
                            }}
                            to="/eduhtml/singlesharecallsandputsatexpiry"
                            style={{
                              cursor: "pointer",
                              textAlign: "center",
                              fontSize: "17px",
                              
                              paddingTop: "4px",
                            }}
                          >
                            {siteLang == "eng" ? "  Introduction   " : "บทนำ"}
                          </Link>
                          <Link
                            className="subtopic"
                            onClick={() => {
                              setModal(false);
                              dwExpiry ? setDwExpiry(false) : setDwExpiry();
                            }}
                            to="/eduhtml/warrantsatexpirylasttradingday"
                            style={{
                              cursor: "pointer",
                              textAlign: "center",
                              fontSize: "17px",
                              
                              paddingTop: "4px",
                            }}
                          >
                            {siteLang == "eng" ? "  Last trading day   " : "วันซื้อขายสุดท้าย"}
                          </Link>
                          <Link
                            className="subtopic"
                            onClick={() => {
                              setModal(false);
                              dwExpiry ? setDwExpiry(false) : setDwExpiry();
                            }}
                            to="/eduhtml/cashsettlementamountforsinglesharewarrantsatexpiry"
                            style={{
                              cursor: "pointer",
                              textAlign: "center",
                              fontSize: "17px",
                              
                              paddingTop: "2px",
                            }}
                          >
                            {siteLang == "eng" ? "  Settlement value for DW  " : "เงินสดส่วนต่างจากการใช้สิทธิ DW"}
                          </Link>
                          <Link
                            className="subtopic"
                            onClick={() => {
                              setModal(false);
                              dwExpiry ? setDwExpiry(false) : setDwExpiry();
                            }}
                            to="/eduhtml/singlesharecallwarrantexample"
                            style={{
                              cursor: "pointer",
                              textAlign: "center",
                              fontSize: "17px",
                              
                              paddingTop: "2px",
                            }}
                          >
                            {siteLang == "eng" ? "Call example  " : "ตัวอย่าง Call DW"}
                          </Link>
                          <Link
                            className="subtopic"
                            onClick={() => {
                              setModal(false);
                              dwExpiry ? setDwExpiry(false) : setDwExpiry();
                            }}
                            to="/eduhtml/singleshareputwarrantexample"
                            style={{
                              cursor: "pointer",
                              textAlign: "center",
                              fontSize: "17px",
                              
                              paddingTop: "2px",
                              paddingBottom: "6px",
                            }}
                          >
                            {siteLang == "eng" ? "Put example  " : "ตัวอย่าง Put DW"}
                          </Link>
                        </div>
                        <div />
                        <div
                          className="accordion"
                          style={{
                            cursor: "pointer",
                            textAlign: "center",
                            fontSize: "24px",
                            
                            left: "60px",
                            color: "#FFD843",
                            paddingTop: "3px",
                          }}
                          onClick={() => {
                            risks ? setRisks(false) : setRisks(true);
                            dwTerms ? setDwTerms(false) : setDwTerms();
                            tradeToggle
                              ? setTradeToggle(false)
                              : setTradeToggle();
                            dwPriced ? setDwPriced(false) : setDwPriced();
                            selectDw ? setSelectDw(false) : setSelectDw();
                            dwExpiry ? setDwExpiry(false) : setDwExpiry();
                            liquidity ? setLiquidity(false) : setLiquidity();
                            areToggle ? setAreToggle(false) : setAreToggle();
                          }}
                        >
                          {siteLang == "eng" ? "Risks  " : "ความเสี่ยง"}
                        </div>
                        <div
                          className="subtopiclist"
                          style={{
                            display: risks ? "block" : "none",
                          }}
                        >
                          <Link
                            className="subtopic"
                            onClick={() => {
                              setModal(false);
                              risks ? setRisks(false) : setRisks();
                            }}
                            to="/eduhtml/risk"
                            style={{
                              cursor: "pointer",
                              textAlign: "center",
                              fontSize: "17px",
                              
                              paddingTop: "4px",
                            }}
                          >
                            {siteLang == "eng" ? "Introduction  " : "บทนำ"}
                          </Link>
                          <Link
                            className="subtopic"
                            onClick={() => {
                              setModal(false);
                              risks ? setRisks(false) : setRisks();
                            }}
                            to="/eduhtml/marketrisk"
                            style={{
                              cursor: "pointer",
                              textAlign: "center",
                              fontSize: "17px",
                              
                              paddingTop: "4px",
                            }}
                          >
                            {siteLang == "eng" ? "Market Risk" : "ความเสี่ยงด้านตลาด (Market Risk)"}
                          </Link>
                          <div
                            className="subtopic"
                            onClick={() => {
                              setModal(false);
                              risks ? setRisks(false) : setRisks();
                            }}
                            to="/eduhtml/riskgearing"
                            style={{
                              cursor: "pointer",
                              textAlign: "center",
                              fontSize: "17px",
                              
                              paddingTop: "2px",
                            }}
                          >
                            {siteLang == "eng" ? "Gearing " : "ความเสี่ยงจาก (Gearing)"}
                          </div>
                          <Link
                            className="subtopic"
                            onClick={() => {
                              setModal(false);
                              risks ? setRisks(false) : setRisks();
                            }}
                            to="/eduhtml/limitedlife"
                            style={{
                              cursor: "pointer",
                              textAlign: "center",
                              fontSize: "17px",
                              
                              paddingTop: "2px",
                            }}
                          >
                            {siteLang == "eng" ? "Limited Life " : "อายุจำกัด (Limited Life)"}
                          </Link>
                          <div
                            className="subtopic"
                            onClick={() => {
                              setModal(false);
                              risks ? setRisks(false) : setRisks();
                            }}
                            to="/eduhtml/creditrisk"
                            style={{
                              cursor: "pointer",
                              textAlign: "center",
                              fontSize: "17px",
                              
                              paddingTop: "2px",
                            }}
                          >
                            Credit Risk
                          </div>
                          <Link
                            className="subtopic"
                            onClick={() => {
                              setModal(false);
                              risks ? setRisks(false) : setRisks();
                            }}
                            to="/eduhtml/takeovers"
                            style={{
                              cursor: "pointer",
                              textAlign: "center",
                              fontSize: "17px",
                              
                              paddingTop: "2px",
                            }}
                          >
                            {siteLang == "eng" ? "Takeovers  " : "การ (Takeover)"}
                          </Link>
                        </div>
                        <div
                          className="accordion"
                          style={{
                            cursor: "pointer",
                            textAlign: "center",
                            fontSize: "24px",
                            
                            left: "60px",
                            color: "#FFD843",
                            paddingTop: "3px",
                          }}
                          onClick={() => {
                            liquidity
                              ? setLiquidity(false)
                              : setLiquidity(true);
                            dwTerms ? setDwTerms(false) : setDwTerms();
                            tradeToggle
                              ? setTradeToggle(false)
                              : setTradeToggle();
                            dwPriced ? setDwPriced(false) : setDwPriced();
                            selectDw ? setSelectDw(false) : setSelectDw();
                            dwExpiry ? setDwExpiry(false) : setDwExpiry();
                            risks ? setRisks(false) : setRisks();
                            areToggle ? setAreToggle(false) : setAreToggle();
                          }}
                        >
                          {siteLang == "eng" ? " Market Making and Liquidity  " : "สภาพคล่องและการดูแลสภาพคล่อง"}
                        </div>
                        <div
                          className="subtopiclist"
                          style={{
                            display: liquidity ? "block" : "none",
                          }}
                        >
                          <Link
                            className="subtopic"
                            onClick={() => {
                              setModal(false);
                              liquidity ? setLiquidity(false) : setLiquidity();
                            }}
                            to="/eduhtml/marketmakers"
                            style={{
                              cursor: "pointer",
                              textAlign: "center",
                              fontSize: "17px",
                              
                              paddingTop: "4px",
                            }}
                          >
                            {siteLang == "eng" ? " Introduction  " : "บทนำ"}
                          </Link>
                          <Link
                            className="subtopic"
                            onClick={() => {
                              setModal(false);
                              liquidity ? setLiquidity(false) : setLiquidity();
                            }}
                            to="/eduhtml/howtocalculatecashsettlementamount"
                            style={{
                              cursor: "pointer",
                              textAlign: "center",
                              fontSize: "17px",
                              
                              paddingTop: "4px",
                            }}
                          >
                            {siteLang == "eng" ? " How does it work? " : "ผู้ดูแลสภาพคล่องบริหารความเสี่ยงอย่างไร"}
                          </Link>
                          <Link
                            className="subtopic"
                            onClick={() => {
                              setModal(false);
                              liquidity ? setLiquidity(false) : setLiquidity();
                            }}
                            to="/eduhtml/liquidityvstradedvolume"
                            style={{
                              cursor: "pointer",
                              textAlign: "center",
                              fontSize: "17px",
                              
                              paddingTop: "4px",
                            }}
                          >
                            {siteLang == "eng" ? "  Liquidity vs Traded Volume " : "วิธีการดูแลสภาพคล่อง"}
                          </Link>
                          <Link
                            className="subtopic"
                            onClick={() => {
                              setModal(false);
                              liquidity ? setLiquidity(false) : setLiquidity();
                            }}
                            to="/eduhtml/howdomarketmakershedge"
                            style={{
                              cursor: "pointer",
                              textAlign: "center",
                              fontSize: "17px",
                              
                              paddingTop: "2px",
                            }}
                          >
                            {siteLang == "eng" ? "  How do Market Makers hedge " : "สภาพคล่องและปริมาณการซื้อขาย"}
                          </Link>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <div
                  id="smalldiv"
                  style={{ textAlign: "center", display: "none" }}
                >
                  <div
                    style={{
                      color: "#FFD843",
                      
                      lineHeight: "36px",
                      fontSize: "25px",
                      letterSpacing: "1px",
                      paddingBottom: "20px",
                    }}
                  >
                    Education Topics
                  </div>
                  <div style={{ paddingBottom: "20px" }}>
                    <select id="small" style={{ backgroundColor: "black" }} />
                  </div>
                  <div
                    id="enter"
                    style={{
                      cursor: "pointer",
                      
                      lineHeight: "36px",
                      fontSize: "20px",
                      letterSpacing: "1px",
                    }}
                  >
                    Enter
                  </div>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default HeaderMq;
