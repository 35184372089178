export const languageText ={

    pageTitle: "ทำไมต้องลงทุนใน DW?",
    pageTitleEn: "Why Trade DW?",

    pageDescription: "สามารถคลิกหัวข้อเพื่ออ่านข้อมูลเพิ่มเติม",
    pageDescriptionEn: "Please click on each of the tiles below for more details",

    // Hexa Box Content Start
    gearingEffect: "อัตราทด (Gearing Effect)",
    gearingEffectEn: "Gearing Effect",

    limitedLoss: "จำกัดขาดทุน",
    limitedLossEn: "Limited loss",

    porfitFromBullorBearMarket: "ความสามารถในการทำกำไรจากการขาขึ้นและลง",
    porfitFromBullorBearMarketEn: "Ability to profit from  rises and falls",

    noMarginCall: "ไม่มีการเรียกหลักประกันเพิ่ม",
    noMarginCallEn: "No margin call",

    lowerCapitalOutlay: "ใช้เงินลงทุนต่ำกว่า",
    lowerCapitalOutlayEn: "Lower capital outlay",

    releasingShareCapital: "ลดจำนวนเงินลงทุนในหลักทรัพย์",
    releasingShareCapitalEn: "Releasing  share capital",

    easeofTrade: "ซื้อง่ายขายคล่อง",
    easeofTradeEn: "Ease of trade",
    // Hexa Box Content End
}