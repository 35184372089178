import React from 'react';
import FreqAskedAns from './FreqAskedAns';
import './freqAskedAns.css';


const FrequentlyAskedAnss = (props) => {
    return ( 
        <FreqAskedAns/>
     );
}
 
export default FrequentlyAskedAnss;