import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import API from "../../../utils/apiCalling";
import "./dwUnderlying.css";
import { get } from "lodash";
import Select from "react-select";
import { config } from "../../../utils/apiUrl";
import LoaderSpin from "../../../components/common/LoaderSpin";
import ReactTooltip from "react-tooltip";
import { languageText } from "../../../languageText/dwUnderlying"
import LiveMatrixSideBars from "../../../components/common/Sidebar/LiveMatrixSideBar"
import WarrantSearchSideBars from "../../../components/common/Sidebar/WarrantSearchSideBar"
import AdvertiseMentSidebar from "../../../components/common/Sidebar/AdvertiseMentSidebar"
import Chart from "./classComponent"

const DwUnderlying = () => {
  
  const api = new API();
  const [allSymbols, setAllSymbols] = useState([]);
  const [callList, setCallList] = useState([]);
  const [putList, setPutList] = useState([]);
  const [tickerVal, setTickerVal] = useState("");
  const [ricValue, setRicValue] = useState("");
  const [siteLang , setSiteLang] = useState("")
  const [htiTime, setHtiTime] = useState("none");
  const [hsiTime, setHsiTime] = useState("block");
  const [disclaimerDefination, setDisclaimerDefination] = useState("");
  const [loadingIndicator, setLoadingIndicator] = useState(true)

  useEffect(() => {
    window.scrollTo(0, 0);
    var siteLanguage = $.cookie('applang');
    setSiteLang(siteLanguage)
    if(siteLanguage == "eng"){
      document.querySelector("body").classList.add("eng")
    }else{
      document.querySelector("body").classList.remove("eng")
    }
    getAllSymbols()
  }, []);

  useEffect(() => {
    handlechange();
    getComparisonsData();
  }, [ricValue]);

  const colourStyles = {
    control: (provided) => ({
      ...provided,
      display:"flex",
      alignItems:"center",
      justifyContent:"center",
      textAlign: 'center', // Center-align the text input
    }),
    input:(provided)=>({
      ...provided,
      width:"100%",
      display:"flex "
    }),
    singleValue: (provided) => ({
      ...provided,
      textAlign: 'center', // Center-align the selected value
    }),
    option: (provided, state) => {
      return {
        backgroundColor: state.isFocused ? "#007fff" : null,
        color: "#333",
        textAlign: "center",
        color: state.isFocused ? "#ffffff" : null,
        fontSize: "21px",
        padding: "3px 14px 3px 5.6px",
        cursor: "pointer",
        borderTop: state.isFocused ? "1px solid #003eff" : null,
        borderBottom: state.isFocused ? "1px solid #003eff" : null,
        fontFamily: "'DBHeaventLight','DBHeavent'",
      };
    },
  };

  const getAllSymbols = async () => {
    let symbolResult = await api.get(
      config.homeLiveIndexFuturesSymbol,
      true,
      { type: "u", qid: Date.now() }
    );
    let symbolResultRes = get(symbolResult, "symbols", []);
    const symbolData = symbolResultRes.map(
      ({ CRT_MNTH, security_code, ric: value }) => ({
        label: `${security_code} ${CRT_MNTH}`,
        value,
        security_code
      })
    );
    setAllSymbols(symbolData)
    if(ricValue == ""){
      var defaultData = symbolResultRes.filter((x)=>{
        return x.ric == 'HSIc1'
      })
      if(defaultData.length>0){
        setRicValue(defaultData[0].ric)
        setTickerVal(defaultData[0].security_code+' '+defaultData[0].CRT_MNTH)
      }else{
        setRicValue(symbolData[0].value)
        setTickerVal(symbolData[0].label)
      }
      
      if("HSI" != "S&P500" && "HSI" != "DJI" && "HSI" != "NDX"){
        setHtiTime("none")
        setHsiTime("block")
      }else{
        setHtiTime("block")
        setHsiTime("none")
      }
    }
    getRicUrl(symbolData);
  };

  const selectChange = (e) => {
    setTickerVal(e.label);
    setRicValue(e.value);
    window.history.pushState("", "", `/tools/underlying/${e.security_code}`);
    if (e.security_code != "S&P500" && e.security_code != "DJI" && e.security_code != "NDX") {
      setHtiTime("none")
      setHsiTime("block")
    }else {
      setHtiTime("block")
      setHsiTime("none")
    }
  };

  const handlechange = (e) => {
    const getdata = async () => {
      const request = await api.get(config.dwRanking, true, {
        mode: 3,
        uric: ricValue,
        qid: Date.now(),
      });
      setCallList(request.calllist)
      setPutList(request.putlist)
      return request;
    };
    getdata();
  };

  const getComparisonsData = async () => {
    setLoadingIndicator(true)
    // var url = `https://t12a.trkd-hs.com/apimqth/ComparisonDataJSON?type=u&ric=${ricValue}&qid=${Date.now()}`
    var url = `${config.warrantComparison}?type=u&ric=${ricValue}&qid=${Date.now()}`
    if(ricValue != "ESc1" && ricValue != "ESc2"){
      if (ricValue == "HSIc1" || ricValue == "HSIc2") {
        // url = "https://t12a.trkd-hs.com/apimqth/LiveIndexJSON?ric=HSI"
        url = `${config.livenewsdata1}?ric=HSI`
      } else if(ricValue == "HHTIc1" || ricValue == "HHTIc2") {
        // url = "https://t12a.trkd-hs.com/apimqth/LiveIndexJSON?ric=HTI"
        url = `${config.livenewsdata1}?ric=HTI`
      }
    }
    let comparisionUrl = await api.get(url);
    setLoadingIndicator(false)
    if (!ricValue.startsWith("HSIc") && !ricValue.startsWith("HHTIc")) {
      var allData = comparisionUrl.comparison;
    } else {
      var allData = comparisionUrl;
    }
    var date = new Date();
    if (!ricValue.startsWith("HSIc") && !ricValue.startsWith("HHTIc")) {
      date = new Date((date.getTime() - (15*60*1000)));
    }
    var hours = date.getHours() + ((new Date().getTimezoneOffset() + 420) / 60);
    var min = date.getMinutes();
    var today = new Date();
    var s = today.getSeconds();
    if(s < 10){
      s = "0" + s;
    }
    if(hours == 16 && min == 31){
      s = "00";
    }
    if(hours < 10){
      hours = "0" + hours;
    }
    if(min < 10){
      min = "0" + min;
    }
    var ampm = "น.";
    var siteLanguagea = $.cookie('applang');
    if (siteLanguagea != "thai") {
      ampm = "";
    }
    document.querySelectorAll(".ticktime").forEach((e) => {
      e.innerHTML = hours + ":" + min + ":" + s + " " + ampm
    })
    if(allData && allData.length > 0){
      var uObj = allData[0];
      // var wObjs = (allData.length > 1) ? allData.slice(1, allData.length) : [];
      let date = new Date();
      // let newmonth = date.getMonth();
      let Month = [];
      let newyear = date.getFullYear();
      // let Year = String(newyear).substr(2, 4);
      if(uObj.ticker === "HSI"){
        uObj.dsply_name = "HSI Futures";
        Month = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"]
      }else{
        uObj.dsply_name = "SPX";
        Month = ["MAR", "MAR", "MAR", "JUN", "JUN", "JUN", "SEP", "SEP", "SEP", "DEC", "DEC", "DEC"]
      }
      document.querySelector("#bidchg").classList.remove("down", "up")
      document.querySelector("#bidpchg").classList.remove("down", "up")
      document.querySelector("#bidval").innerHTML = uObj?.BID
      document.querySelector("#trfprcval").innerHTML = uObj?.TRDPRC_1
      document.querySelector("#bidchg").innerHTML = uObj?.NETCHNG_1
      document.querySelector("#bidchg").classList.add(uObj?.NETCHNG_1.toString() < 0 ? "down" : uObj?.NETCHNG_1.toString() > 0 ? "up" : "")
      document.querySelector("#bidpchg").innerHTML = uObj?.PCTCHNG+"%"
      document.querySelector("#bidpchg").classList.add(uObj?.PCTCHNG.toString() < 0 ? "down" : uObj?.PCTCHNG.toString() > 0 ? "up" : "")
      // document.querySelector("#trdvol").innerHTML = uObj.ACVOL_1_SCALED
    }else if(allData){
      document.querySelector("#bidchg").classList.remove("down", "up")
      document.querySelector("#bidpchg").classList.remove("down", "up")
      document.querySelector("#trfprcval").innerHTML = toThousands(Number(allData?.last))
      document.querySelector("#bidchg").innerHTML = allData?.chng
      document.querySelector("#bidchg").classList.add(allData?.chng.toString() < 0 ? "down" : allData?.chng.toString() > 0 ? "up" : "")
      document.querySelector("#bidpchg").innerHTML = allData?.pchng
      document.querySelector("#bidpchg").classList.add(allData?.pchng.replace('%','') < 0 ? "down" : allData?.pchng.replace('%','') > 0 ? "up" : "")
    }
  };

  function toThousands(num) {
    num = num.toFixed(2);
    num = (num || 0).toString();
    let number = 0,
    floatNum = '',
    intNum = '';
    if (num.indexOf('.') > 0) {
      number = num.indexOf('.');
      floatNum = num.substr(number);
      intNum = num.substring(0, number);
    } else {
      intNum = num;
    }
    let result = [],
    counter = 0;
    intNum = intNum.split('');
    for (let i = intNum.length - 1; i >= 0; i--) {
      counter++;
      result.unshift(intNum[i]);
      if (!(counter % 3) && i != 0) {
        result.unshift(',');
      }
    }
    return result.join('') + floatNum || '';
  }


  const getRicUrl = (data) => {
    let queryString = window.location.pathname.split("/");
    let splitData = queryString[3];
    if(splitData !== undefined){
      let ricData = data?.filter((rk) => splitData == rk.security_code);    
      setRicValue(ricData[0]?.value)
      setTickerVal(ricData[0]?.label)
      if(ricData[0]?.security_code != "S&P500" && ricData[0]?.security_code != "DJI" && ricData[0]?.security_code != "NDX"){
        setHtiTime("none")
        setHsiTime("block")
      }else{
        setHtiTime("block")
        setHsiTime("none")
      }
    }
  };

  const disclaimerDefinationFun = () => {
    if(disclaimerDefination == ""){
      setDisclaimerDefination("in")
    }else{
      setDisclaimerDefination("")
    }
  }

  return (
    <>
      <div id="underlying" className="pageobj">
        <div className="breadcrumbbar">
          <div className="container-fluid">
            <ol className="breadcrumb">
              <li>
                <Link to="/home">
                  {siteLang == "eng" ? languageText.firstNavigationEn : languageText.firstNavigation}
                </Link>
              </li>
              <li className="active"> {siteLang == "eng" ? languageText.secondNavigationEn : languageText.secondNavigation}</li>
            </ol>
          </div>
        </div>
        <div className="stripe-bg" />
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-8">
              <div className="section">
                <div className="page-header">
                  <h1>{siteLang == "eng" ? languageText.pageTitleEn : languageText.pageTitle}</h1>
                </div>
                <p>
                  {siteLang == "eng" ? languageText.pageDescriptionEn : languageText.pageDescription}
                </p>
                <div id="dropdown-underlying">
                  <Select
                    className="select_align_center"
                    placeholder={siteLang == "eng" ? "Select underlying" : "เลือกหรือพิมพ์ชื่อดัชนี"}
                    onChange={(e) => selectChange(e)}
                    options={allSymbols}
                    styles={colourStyles}
                  />
                </div>
                <div id="dwsummary" style={{position: 'relative'}}>
                  <div id="ticker" className="ricbar bgcolor-03">
                    {tickerVal}
                  </div>
                  <table id="pricetbl" className="uppertbl">
                    <colgroup>
                      <col style={{backgroundColor: '#E7E8E8', padding: '3px'}} />
                      <col style={{backgroundColor: '#F5F6F7', padding: '3px', textAlign: 'left'}} />
                      <col style={{backgroundColor: '#F5F6F7', padding: '3px', textAlign: 'right'}} />
                    </colgroup>
                    <tbody>
                      <tr>
                        <td className="uppertbl-col-bid" rowSpan={3}>
                          <div className="bidprice lbl">
                            {siteLang == "eng" ? languageText.lastPriceEn : languageText.lastPrice}
                          </div>
                          <div id="trfprcval" className="bidprice val">
                            -
                          </div>
                          <div className="bidprice lbl HTI_time" style={{display: htiTime}}>
                            {siteLang == "eng" ? languageText.lastBidPriceEn : languageText.lastBidPrice}
                          </div>
                          <div id="bidval" className="bidprice val HTI_time" style={{display: htiTime}}>
                            -
                          </div>
                          <img onClick={getComparisonsData} className="refresh_index" style={{cursor: 'pointer', width: '22px'}} src="/img/bullet/refresh.png" />
                          <span>{siteLang == "eng" ? languageText.liveClickRefreshEn : languageText.liveClickRefresh}</span>
                          <br />
                          <div className="HSI_time" style={{display: hsiTime}}>
                            {siteLang == "eng" ? languageText.priceAsAtEn : languageText.priceAsAt} <span className="ticktime"></span>
                          </div>
                        </td>
                        <td className="uppertbl-col-chglbl">
                          {siteLang == "eng" ? languageText.priceChangeEn : languageText.priceChange}
                        </td>
                        <td id="bidchg" className="uppertbl-col-chgval val">0</td>
                      </tr>
                      <tr>
                        <td className="uppertbl-col-pchglbl">
                          %{siteLang == "eng" ? languageText.priceChangeEn : languageText.priceChange}
                        </td>
                        <td id="bidpchg" className="uppertbl-col-pchgval val">
                          -
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <div className="HTI_time" style={{display: htiTime}}>
                    {siteLang == "eng" ? languageText.htiTimeEn : languageText.htiTime} <span className="ticktime"></span>
                  </div>
                  <div className="HSI_time" style={{display: hsiTime, textAlign: 'right'}}>
                    {siteLang == "eng" ? languageText.hsiTimeEn : languageText.hsiTime}
                  </div>
                  {loadingIndicator && <div className="loadingindicator" />}
                </div>
                <Chart ricValue1={ricValue} />
                <div id="dwtblgroup" style={{position: 'relative'}}>
                  <table id="calltable" className="rktable table table-striped top-table">
                    <thead>
                      <tr>
                        <th className="th-nm" dangerouslySetInnerHTML={{__html: siteLang == "eng" ? languageText.tableCallDwEn : languageText.tableCallDw}} />
                        <th className="th-id" />
                        <th className="th-bd" dangerouslySetInnerHTML={{__html: siteLang == "eng" ? languageText.tableBidEn : languageText.tableBid}} />
                        <th className="th-eg">
                          <span 
                            className="ttip" 
                            data-tip
                            data-for={"UnderTableEffGearing"}
                            dangerouslySetInnerHTML={{__html: siteLang == "eng" ? languageText.tableEffGearingEn : languageText.tableEffGearing}} 
                          />
                          <ReactTooltip
                            id={"UnderTableEffGearing"}
                            delayHide={100}
                            place="right"
                            type="warning"
                            effect="solid"
                            clickable={true}
                          >
                            <div className="bg-tooltip">
                              <p 
                                className="tooltip-content" 
                                dangerouslySetInnerHTML={{__html: siteLang == "eng" ? languageText.tableEffGearingTooltipEn : languageText.tableEffGearingTooltip}}
                              />
                            </div>
                          </ReactTooltip>
                        </th>
                        <th className="th-ss">
                          <span 
                            className="ttip" 
                            data-tip
                            data-for={"UnderTableSensitivity"}
                            dangerouslySetInnerHTML={{__html: siteLang == "eng" ? languageText.tableSensitivityEn : languageText.tableSensitivity}} 
                          />
                          <ReactTooltip
                            id={"UnderTableSensitivity"}
                            delayHide={100}
                            place="right"
                            type="warning"
                            effect="solid"
                            clickable={true}
                          >
                            <div className="bg-tooltip">
                              <p 
                                className="tooltip-content" 
                                dangerouslySetInnerHTML={{__html: siteLang == "eng" ? languageText.tableSensitivityTooltipEn : languageText.tableSensitivityTooltip}}
                              />
                            </div>
                          </ReactTooltip>
                        </th>
                        <th className="th-si">
                          <span 
                            className="ttip" 
                            data-tip
                            data-for={"UnderTableSensitivityIndex"}
                            dangerouslySetInnerHTML={{__html: siteLang == "eng" ? languageText.tableSensitivityIndexEn : languageText.tableSensitivityIndex}} 
                          />
                          <ReactTooltip
                            id={"UnderTableSensitivityIndex"}
                            delayHide={100}
                            place="right"
                            type="warning"
                            effect="solid"
                            clickable={true}
                          >
                            <div className="bg-tooltip">
                              <p 
                                className="tooltip-content" 
                                dangerouslySetInnerHTML={{__html: siteLang == "eng" ? languageText.tableSensitivityIndexTooltipEn : languageText.tableSensitivityIndexTooltip}}
                              />
                            </div>
                          </ReactTooltip>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {callList.length > 0 ? callList.map((data, index) => (
                        <tr className="rowtmpl" key={index}>
                          <td className="col_tkr" style={{fontWeight: 'bold'}}>
                            <Link to={`/tools/livematrix/${data.ticker}`}>{data.ticker}</Link>
                          </td>
                          <td className="col_id">
                            {data.FTCHK && <img src="/img/bullet/fasttrack.png" />}
                            {data.NEAREXP && <img src="/img/bullet/expiry.fw.png" />}
                            {data.SOLDOUT && <img src="/img/bullet/soldout.fw.png" />}
                            {data.CAUTION && <img src="/img/bullet/caution.png" />}
                            {data.LOWTD && <img src="/img/bullet/time.png" />}
                          </td>
                          <td className="col_bd">{data.BID}</td>
                          <td className="col_eg">{data.effective_gearing != null ? data.effective_gearing : "-"}</td>
                          <td className="col_sstvt">{data.sensitivity != null ? data.sensitivity : "-"}</td>
                          <td className="col_sstvti">
                            {data.sensitivity != null || data.sensitivity != undefined ? (Number(data.sensitivity) === 0 ? "-" : (1.0 / Number(data.sensitivity)).toFixed(2)) : "-"}
                          </td>
                        </tr>
                      ))
                      :
                      <tr className="emptymsg">
                        <td colSpan="6">No data</td>
                      </tr>
                      }
                    </tbody>
                  </table>
                  <table id="puttable" className="table table-striped">
                    <thead>
                      <tr>
                        <th className="th-nm" dangerouslySetInnerHTML={{__html: siteLang == "eng" ? languageText.tablePutDwEn : languageText.tablePutDw}} />
                        <th className="th-id" />
                        <th className="th-bd" dangerouslySetInnerHTML={{__html: siteLang == "eng" ? languageText.tableBidEn : languageText.tableBid}} />
                        <th className="th-eg">
                          <span 
                            className="ttip" 
                            data-tip
                            data-for={"UnderTableEffGearing"}
                            dangerouslySetInnerHTML={{__html: siteLang == "eng" ? languageText.tableEffGearingEn : languageText.tableEffGearing}} 
                          />
                        </th>
                        <th className="th-ss">
                          <span 
                            className="ttip" 
                            data-tip
                            data-for={"UnderTableSensitivity"}
                            dangerouslySetInnerHTML={{__html: siteLang == "eng" ? languageText.tableSensitivityEn : languageText.tableSensitivity}} 
                          />
                        </th>
                        <th className="th-si">
                          <span 
                            className="ttip" 
                            data-tip
                            data-for={"UnderTableSensitivityIndex"}
                            dangerouslySetInnerHTML={{__html: siteLang == "eng" ? languageText.tableSensitivityIndexEn : languageText.tableSensitivityIndex}} 
                          />
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {putList.length > 0 ? putList.map((data, index) => (
                        <tr className="rowtmpl" key={index}>
                          <td className="col_tkr" style={{fontWeight: 'bold'}}>
                            <Link to={`/tools/livematrix/${data.ticker}`}>{data.ticker}</Link>
                          </td>
                          <td className="col_id">
                            {data.FTCHK && <img src="/img/bullet/fasttrack.png" />}
                            {data.NEAREXP && <img src="/img/bullet/expiry.fw.png" />}
                            {data.SOLDOUT && <img src="/img/bullet/soldout.fw.png" />}
                            {data.CAUTION && <img src="/img/bullet/caution.png" />}
                            {data.LOWTD && <img src="/img/bullet/time.png" />}
                          </td>
                          <td className="col_bd">{data.BID}</td>
                          <td className="col_eg">{data.effective_gearing != null ? data.effective_gearing : "-"}</td>
                          <td className="col_sstvt">{data.sensitivity != null ? data.sensitivity : "-"}</td>
                          <td className="col_sstvti">
                            {data.sensitivity != null || data.sensitivity != undefined ? (Number(data.sensitivity) === 0 ? "-" : (1.0 / Number(data.sensitivity)).toFixed(2)) : "-"}
                          </td>
                        </tr>
                      ))
                      :
                      <tr className="emptymsg">
                        <td colSpan="6">No data</td>
                      </tr>
                      }
                    </tbody>
                  </table>
                </div>
                <div className="disclaimer hidden-xs hidden-sm">
                  <p className="small" dangerouslySetInnerHTML={{__html: siteLang == "eng" ? languageText.disclaimerEn : languageText.disclaimer}} />
                  <hr className="hr-hmgbm" />
                  <p className="small">{siteLang == "eng" ? languageText.marketDelayEn : languageText.marketDelay}</p>
                </div>
                <div className="panel-group visible-xs-block visible-sm-block" id="defination">
                  <div className="panel panel-default">
                    <div className="panel-heading">
                      <button onClick={disclaimerDefinationFun}>
                        <img src="/img/bullet/question.png" alt="icon image" />
                        <div id="collapse_title3" className="panel-title text-center color-primary">Definition &amp; disclaimer</div>
                      </button>
                    </div>
                    <div id="collapse_dwcompare3" className={`panel-collapse collapse ${disclaimerDefination}`}>
                      <table className="table table-striped">
                        <tbody>
                          <tr>
                            <td dangerouslySetInnerHTML={{__html: siteLang == "eng" ? languageText.defDwSymbolTitleEn : languageText.defDwSymbolTitle}} />
                            <td dangerouslySetInnerHTML={{__html: siteLang == "eng" ? languageText.defDwSymbolParaEn : languageText.defDwSymbolPara}} />
                          </tr>
                          <tr>
                            <td dangerouslySetInnerHTML={{__html: siteLang == "eng" ? languageText.defUnderlyingTitleEn : languageText.defUnderlyingTitle}} />
                            <td dangerouslySetInnerHTML={{__html: siteLang == "eng" ? languageText.defUnderlyingParaEn : languageText.defUnderlyingPara}} />
                          </tr>
                          <tr>
                            <td dangerouslySetInnerHTML={{__html: siteLang == "eng" ? languageText.defTypeTitleEn : languageText.defTypeTitle}} />
                            <td dangerouslySetInnerHTML={{__html: siteLang == "eng" ? languageText.defTypeParaEn : languageText.defTypePara}} />
                          </tr>
                          <tr>
                            <td dangerouslySetInnerHTML={{__html: siteLang == "eng" ? languageText.defIssuerTitleEn : languageText.defIssuerTitle}} />
                            <td dangerouslySetInnerHTML={{__html: siteLang == "eng" ? languageText.defIssuerParaEn : languageText.defIssuerPara}} />
                          </tr>
                          <tr>
                            <td dangerouslySetInnerHTML={{__html: siteLang == "eng" ? languageText.defMostRecentTitleEn : languageText.defMostRecentTitle}} />
                            <td dangerouslySetInnerHTML={{__html: siteLang == "eng" ? languageText.defMostRecentParaEn : languageText.defMostRecentPara}} />
                          </tr>
                          <tr>
                            <td dangerouslySetInnerHTML={{__html: siteLang == "eng" ? languageText.defExpiryTitleEn : languageText.defExpiryTitle}} />
                            <td dangerouslySetInnerHTML={{__html: siteLang == "eng" ? languageText.defExpiryParaEn : languageText.defExpiryPara}} />
                          </tr>
                          <tr>
                            <td dangerouslySetInnerHTML={{__html: siteLang == "eng" ? languageText.defLastTradingTitleEn : languageText.defLastTradingTitle}} />
                            <td dangerouslySetInnerHTML={{__html: siteLang == "eng" ? languageText.defLastTradingParaEn : languageText.defLastTradingPara}} />
                          </tr>
                          <tr>
                            <td dangerouslySetInnerHTML={{__html: siteLang == "eng" ? languageText.defHistoricalTitleEn : languageText.defHistoricalTitle}} />
                            <td dangerouslySetInnerHTML={{__html: siteLang == "eng" ? languageText.defHistoricalParaEn : languageText.defHistoricalPara}} />
                          </tr>
                          <tr>
                            <td dangerouslySetInnerHTML={{__html: siteLang == "eng" ? languageText.defTimeMaturityTitleEn : languageText.defTimeMaturityTitle}} />
                            <td dangerouslySetInnerHTML={{__html: siteLang == "eng" ? languageText.defTimeMaturityParaEn : languageText.defTimeMaturityPara}} />
                          </tr>
                          <tr>
                            <td dangerouslySetInnerHTML={{__html: siteLang == "eng" ? languageText.defRatioTitleEn : languageText.defRatioTitle}} />
                            <td dangerouslySetInnerHTML={{__html: siteLang == "eng" ? languageText.defRatioParaEn : languageText.defRatioPara}} />
                          </tr>
                          <tr>
                            <td dangerouslySetInnerHTML={{__html: siteLang == "eng" ? languageText.defEffectiveTitleEn : languageText.defEffectiveTitle}} />
                            <td dangerouslySetInnerHTML={{__html: siteLang == "eng" ? languageText.defEffectiveParaEn : languageText.defEffectivePara}} />
                          </tr>
                          <tr>
                            <td dangerouslySetInnerHTML={{__html: siteLang == "eng" ? languageText.defDeltaTitleEn : languageText.defDeltaTitle}} />
                            <td dangerouslySetInnerHTML={{__html: siteLang == "eng" ? languageText.defDeltaParaEn : languageText.defDeltaPara}} />
                          </tr>
                          <tr>
                            <td dangerouslySetInnerHTML={{__html: siteLang == "eng" ? languageText.defDeltaDwTitleEn : languageText.defDeltaDwTitle}} />
                            <td dangerouslySetInnerHTML={{__html: siteLang == "eng" ? languageText.defDeltaDwParaEn : languageText.defDeltaDwPara}} />
                          </tr>
                          <tr>
                            <td dangerouslySetInnerHTML={{__html: siteLang == "eng" ? languageText.defSensitivityTitleEn : languageText.defSensitivityTitle}} />
                            <td dangerouslySetInnerHTML={{__html: siteLang == "eng" ? languageText.defSensitivityParaEn : languageText.defSensitivityPara}} />
                          </tr>
                          <tr>
                            <td dangerouslySetInnerHTML={{__html: siteLang == "eng" ? languageText.defPremiumTitleEn : languageText.defPremiumTitle}} />
                            <td dangerouslySetInnerHTML={{__html: siteLang == "eng" ? languageText.defPremiumParaEn : languageText.defPremiumPara}} />
                          </tr>
                          <tr>
                            <td dangerouslySetInnerHTML={{__html: siteLang == "eng" ? languageText.defImpliedVolatilityTitleEn : languageText.defImpliedVolatilityTitle}} />
                            <td dangerouslySetInnerHTML={{__html: siteLang == "eng" ? languageText.defImpliedVolatilityParaEn : languageText.defImpliedVolatilityPara}} />
                          </tr>
                          <tr>
                            <td dangerouslySetInnerHTML={{__html: siteLang == "eng" ? languageText.defThetaTitleEn : languageText.defThetaTitle}} />
                            <td dangerouslySetInnerHTML={{__html: siteLang == "eng" ? languageText.defThetaParaEn : languageText.defThetaPara}} />
                          </tr>
                          <tr>
                            <td dangerouslySetInnerHTML={{__html: siteLang == "eng" ? languageText.defIntrinsicTitleEn : languageText.defIntrinsicTitle}} />
                            <td dangerouslySetInnerHTML={{__html: siteLang == "eng" ? languageText.defIntrinsicParaEn : languageText.defIntrinsicPara}} />
                          </tr>
                          <tr>
                            <td dangerouslySetInnerHTML={{__html: siteLang == "eng" ? languageText.defMoneynessTitleEn : languageText.defMoneynessTitle}} />
                            <td dangerouslySetInnerHTML={{__html: siteLang == "eng" ? languageText.defMoneynessParaEn : languageText.defMoneynessPara}} />
                          </tr>
                        </tbody>
                      </table>
                      <p className="small" dangerouslySetInnerHTML={{__html: siteLang == "eng" ? languageText.disclaimerEn : languageText.disclaimer}} />
                      <hr className="hr-hmgbm" />
                      <p className="small">{siteLang == "eng" ? languageText.marketDelayEn : languageText.marketDelay}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="hidden-xs hidden-sm col-md-4">
              <LiveMatrixSideBars />
              <WarrantSearchSideBars />
              <div className="section qlink-section">
                <div className="page-header">
                  <h2 className="small">{siteLang == "eng" ? languageText.whyDwTitleEn : languageText.whyDwTitle}</h2>
                </div>
                <p>
                  {siteLang == "eng" ? languageText.whyDwParaEn : languageText.whyDwPara}
                </p>
                <div className="section-qlink-img section-qlink-dwcalculator">
                  <Link to="/education/whydw28" className="section-qlink-achor">
                    <img src="/img/bullet/qlink-achor.png" />
                  </Link>
                </div>
              </div>
              <AdvertiseMentSidebar />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DwUnderlying;