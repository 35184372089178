import React, { useState, useEffect,Fragment } from 'react';
import ReleasingShareCapitals from './ReleasingShareCapitals';
import "../../common.css";
import "../../investorAcademy.css";


const ReleasingShareCapital = (props) => {
    return ( 
        <Fragment> 
        <ReleasingShareCapitals/>
        </Fragment> 
     );
}


export default ReleasingShareCapital;