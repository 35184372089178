export const languageText ={

    pageTitle: "ราคา DW คำนวณอย่างไร",
    pageTitleEn: "How are DW priced?",

    pageName: "การติดตามการเปลี่ยนแปลงของราคา DW",
    pageNameEn: "Tracking DW's price change",

    pageDescription: "ในการติดตามการเปลี่ยนแปลงของราคาหลักทรัพย์จดทะเบียน แหล่งข้อมูลต่างๆ เช่น สื่อต่างๆ บริษัทหลักทรัพย์ หรือ สื่อออนไลน์ โดยทั่วไป มักจะเผยแพร่ข้อมูล “การเปลี่ยนแปลงราคาในแต่ละวัน” ของหุ้น / DW โดยใช้ราคาซื้อขายปัจจุบันเปรียบเทียบกับราคาปิด ณ สิ้นวันทำการก่อนหน้า",
    pageDescriptionEn: "In order to track the price changes of listed securities, information sources such as the media, brokers and online providers will typically publish the “daily price change” for shares/DW, consisting of the current traded price versus its closing price the day before.",

    pageDescription1: "อย่างไรก็ตาม วิธีการนี้ไม่ค่อยถูกต้องเมื่อนำมาใช้กับ DW เพราะ DW ไม่ได้ซื้อขายบ่อยเท่าหุ้น ยิ่งไปกว่านั้น “ราคาปิดก่อนหน้า” ของ DW อาจจะเป็นราคาที่เกิดขึ้นเมื่อเช้าวันก่อนหน้า หรือ 2 วันก่อนหน้า หรือผ่านมาหนึ่งสัปดาห์แล้วก็ได้",
    pageDescription1En: "However, with DW, this method is often totally inaccurate as DW do not trade as frequently as shares. Therefore the price that is listed as the DW's “previous close” may actually relate to a trade that happened the previous morning, two days earlier, or even weeks ago.",

    pageDescription2: "เราสามารถแสดงรายละเอียดได้ด้วยกราฟนี้",
    pageDescription2En: "We can illustrate further with a chart:",

    imgUrl: "/img/education/img/trackdwpricechanges_th.png",
    imgUrlEn: "/img/education/img/trackdwpricechanges.png",

    pageDescription3: "กราฟข้างต้นคือตัวอย่างของการใช้ราคาซื้อขายสุดท้ายของหุ้นอ้างอิงและ Call DW คุณจะเห็นว่าตรงส่วนที่วงกลมไว้บนกราฟ แสดงให้เห็นถึงราคา DW ที่ “บันทึก” ไว้ซึ่งไม่เปลี่ยนแปลงแม้ว่าหุ้นอ้างอิงจะมีการเคลื่อนไหว นั่นเป็นเพราะถึงแม้ว่า DW จะมีการเปลี่ยนแปลง Bid-Offer ขึ้นและลงตามราคาหุ้นอ้างอิง แต่ DW นั้นไม่มีการซื้อขาย ดังนั้น ราคาที่แสดงไว้จึงเป็นราคาเดิมที่มาจาก “ราคาซื้อขายสุดท้าย”",
    pageDescription3En: "The above chart is an example drawn by using the last prices of a share and a corresponding call DW. You will notice the circled portions of the chart show that the ‘recorded’ DW price has remained unchanged despite movements in the underlying stock price. This is because during those periods even though the DW’s bid and offer prices have moved up and down with the underlying share price, the warrant did not record a trade, and thus continued displaying the same price under its “last traded price”.",

    pageDescription4: "วิธีที่เหมาะสมที่สุดที่จะใช้ในการคำนวณราคา DW ที่เปลี่ยนแปลงไป คือการใช้ราคาเสนอซื้อ (Bid Price) ในช่วงที่ต้องการเปรียบเทียบ ราคาเสนอซื้อจะแสดงราคาที่คุณสามารถขาย DW และเป็นราคาที่ดีที่สุดของมูลค่า DW ในแต่ละช่วงเวลา ดังนั้น เนื้อหาทั้งหมดในเว็บไซต์ของเรา เราจึงใช้ราคาเสนอซื้อของ DW ในการเปรียบเทียบราคาที่เปลี่ยนแปลงไป และใช้ในการสร้างกราฟราคาย้อนหลังของ DW",
    pageDescription4En: "The most accurate way of calculating a DW price change is to look at a DW's bid price over the period of comparison. The bid price shows the price at which you can sell your DW and is therefore the best representation of its value at any point in time.",

    pageDescription5: "",
    pageDescription5En: "Throughout this website, we will consistently use the DW bid price for price changes and also to generate historical price graphs for DW.",
    
}