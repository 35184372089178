import React, { useState, useEffect,Fragment } from 'react';
import Gearings from './Gearings';
import "../../common.css";
import "../../investorAcademy.css";


const Gearing = (props) => {
    return ( 
        <Fragment> 
            <Gearings/>
        </Fragment> 
     );
}


export default Gearing;