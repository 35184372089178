import React, { useState, useEffect,Fragment } from 'react';
import SingleShareExpiries from './SingleShareExpiries';
import "../common.css";
import "../investorAcademy.css" 


const SingleShareExpiry = (props) => {
    return ( 
        <Fragment> 
        <SingleShareExpiries/>
        </Fragment> 
     );
}


export default SingleShareExpiry;