export const languageText ={

    // Tabs Content
    toolTitle : "เครื่องมือ DW",
    toolTitleEn : "DW tools",
    toolPara : "ใช้เครื่องมือของเรา เพื่อช่วยคุณพิชิตตลาด",
    toolParaEn : "Use our tools to stay on top of your game and be one step ahead.",

    statTitle : "สถิติ DW",
    statTitleEn : "DW statistics",
    statPara : "กราฟและสถิติภายในตลาดที่จะช่วยให้คุณเข้าถึงข้อมูลเชิงลึก",
    statParaEn : "Our customised graphs and charts provide excellent insight into the markets.",

    eduTitle : "รอบรู้เรื่อง DW",
    eduTitleEn : "DW education",
    eduPara : "มือใหม่ DW? เราจะช่วยคุณเพิ่มและพัฒนาความรู้เกี่ยวกับ DW",
    eduParaEn : "New to DW? We will help you get started and grow your knowledge.",

    newsTitle : "อัพเดทตลาดหุ้น",
    newsTitleEn : "Market news",
    newsPara : "ติดตามข่าวสารล่าสุด เพื่อให้คุณพร้อมอยู่เสมอ",
    newsParaEn : "Market News as it breaks. Stay informed and ready.",

    // Warrant Tool Tab Content
    dwSearchTitle: "ค้นหา DW",
    dwSearchTitleEn: "DW search",
    dwSearchBtn: "ค้นหา DW ทั้งหมด",
    dwSearchBtnEn: "View all",

    marketNewsTitle: "อัพเดทตลาดหุ้น",
    marketNewsTitleEn: "Market news",

    fifteenMins: "ราคาล่าช้า 15 นาที",
    fifteenMinsEn: "15 mins delayed",

    dw28Update: "DW28 update",
    dw28UpdateEn: "DW28 update",

    dwtipsTitle: "DW Tips",
    dwtipsTitleEn: "DW Tips",
    dwtipsPara: "A good tips for investor to know before trading DW.",
    dwtipsParaEn: "A good tips for investor to know before trading DW.",
    dwtipsButton: "เพิ่มเติม คลิก",
    dwtipsButtonEn: "View more",

    noLiveMatrix: "ไม่สามารถแสดงตารางราคาได้ เนื่องจาก DW ไม่มีมูลค่า ณ ระดับราคาหลักทรัพย์อ้างอิงปัจจุบันนี้",
    noLiveMatrixEn: "There is no matrix available for this DW, as the DW has no value at current levels in the underlying",
    
    lastViewTitle: "ดูล่าสุด",
    lastViewTitleEn: "Last View",

    liveIndexTitle: "สัญญาซื้อขายล่วงหน้า",
    liveIndexTitleEn: "Live index futures",
    futureTableIndex: "ตลาด",
    futureTableIndexEn: "Index",
    futureTableContract: "สัญญา",
    futureTableContractEn: "Contract",
    futureTableLast: "ราคาล่าสุด",
    futureTableLastEn: "Last",
    futureTableChange: "เปลี่ยนแปลง",
    futureTableChangeEn: "Change",
    futureTableChangePer: "เปลี่ยนแปลง ",
    futureTableChangePerEn: "Change",
    futureClickRefreshFirst: "*กดปุ่ม ",
    futureClickRefreshLast: " เพื่ออัพเดท ราคา Live quote",
    futureClickRefreshEn: "* Click refresh button for live quote",
    futureTablePrice: "ณ ",
    futureTablePriceEn: "Price as at ",

    liveMatrixTitle: "ตารางราคา",
    liveMatrixTitleEn: "Live matrix",
    liveMatrixPara: "เทรดง่ายขึ้นด้วยตารางที่แสดงราคา Bid ของ DW และ อัพเดทช่วงราคา ตลอดทั้งวัน",
    liveMatrixParaEn: "Makes trading DW easy by showing investors where our bid prices will be throughout the day",
    matrixTableUnderlying: "Bid ของหลักทรัพย์อ้างอิง",
    matrixTableUnderlyingEn: "Underlying bid",
    matrixTableDw: "DW bid",
    matrixTableDwEn: "DW bid",
    matrixTableButton: "เพิ่มเติม คลิก",
    matrixTableButtonEn: "View more",

    // Warrant Stat Tab Content
    tradedStockTitle: "Top traded stock",
    tradedStockTitleEn: "Top traded stock",
    tradedStockPara: "ตารางจัดอันดับหลักทรัพย์อ้างอิงของ DW ที่มีมูลค่าซื้อขายสูงสุดในตลาด และ DW28 บนหลักทรัพย์อ้างอิงดังกล่าวที่มีสภาพคล่องสูงสุด",
    tradedStockParaEn: "Most active underlying share by value and DW28 on those underlying share.",
    tradedStockCallBtn: "DW ที่เกี่ยวข้อง",
    tradedStockCallBtnEn: "Call DW",
    tradedStockPutBtn: "Put DW",
    tradedStockPutBtnEn: "Put DW",
    tradedStockViewBtn: "เพิ่มเติม คลิก",
    tradedStockViewBtnEn: "View more",

    tradedDwTitle: "Top traded DW",
    tradedDwTitleEn: "Top traded DW",
    tradedDwPara: "ตารางแสดง 10 อันดับ DW ที่มีปริมาณการซื้อขายสูงสุดในแต่ละวัน",
    tradedDwParaEn: "Shows the top 10 DW for the day, by traded volume.",
    tradedDwTableSymbol: "ชื่อย่อ",
    tradedDwTableSymbolEn: "Symbol",
    tradedDwTableBid: "ราคาเสนอซื้อ (บาท)",
    tradedDwTableBidEn: "Bid",
    tradedDwTableChange: "เปลี่ยนแปลง",
    tradedDwTableChangeEn: "Change",
    tradedDwTableVolume: "ปริมาณการซื้อขาย",
    tradedDwTableVolumeEn: "Volume",
    tradedDwMacquarie: "เฉพาะ DW28",
    tradedDwMacquarieEn: "Macquarie only",

    marketTurnOver: "สรุปมูลค่าตลาด DW วันนี้ และเทียบกับ วันก่อนหน้า คลิกที่นี่",
    marketTurnOverEn: "How active was the DW market today, and how did it compare to previous days? Find out here.",
    turnOverDistribution: "หลักทรัพย์อ้างอิงของ DW ที่มีมูลค่าการซื้อขายสูงสุด คือหลักทรัพย์ใด? คลิกเพื่อดูคำตอบ",
    turnOverDistributionEn: "What are the top traded stocks in DW market? Click here to see.",

    // Warrant Education Tab Content
    dwVideoTitle: "วิดีโอความรู้เรื่อง DW",
    dwVideoTitleEn: "DW video",
    dwVideoPara: "เพิ่มพูนความรู้ DW ง่ายๆด้วย VDO ความรู้และบันทึกงานสัมมนาเกี่ยวกับ DW",
    dwVideoParaEn: "Improve your knowledge of DW and seminar videos.",

    seminarTitle: "สัมมนา",
    seminarTitleEn: "Seminar",
    seminarPara: "เพิ่มความรู้เกี่ยวกับ DW ง่ายๆ ด้วยการร่วมสัมมนาฟรีกับแมคควอรี",
    seminarParaEn: "The best way to improve your knowledge of DWs. Join us for a free seminar!",
    seminarTableDateTitle: "วันที่",
    seminarTableDateTitleEn: "Date",
    seminarTableTimeTitle: "Time",
    seminarTableTimeTitleEn: "Time",
    seminarTableTitleTitle: "Title",
    seminarTableTitleTitleEn: "Title",

    investorTitle: "แหล่งความรู้",
    investorTitleEn: "Investor academy",
    investorPara: "ทุกอย่างที่คุณต้องรู้เกี่ยวกับ DW อยู่ที่นี่…",
    investorParaEn: "Everything you need to know about DW…",

    indexDwTitle: "Index DW",
    indexDwTitleEn: "Index DW",
    indexDwPara: "DW ที่อ้างอิงกับดัชนี คืออะไร ทำไมถึงได้รับความนิยม?",
    indexDwParaEn: "What is an index DW and why is it so popular?",

    faqTitle: "คำถามที่พบบ่อย",
    faqTitleEn: "FAQ",
    faqPara: "คุณมีคำถามเกี่ยวกับ DW ไหม? ค้นหาคำตอบได้ที่นี่...",
    faqParaEn: "Do you have a question about DWs? The answer may be here..",

    eduViewBtn: "เพิ่มเติม คลิก",
    eduViewBtnEn: "View more",

    // Market News Tab Content
    newsViewBtn: "เพิ่มเติม คลิก",
    newsViewBtnEn: "View more",

    hsiIndexTitle: "วิเคราะห์ตลาดฮั่งเส็ง",
    hsiIndexTitleEn: "HSI index market news",

    marketWrapTitle: "สรุปภาพรวมตลาด",
    marketWrapTitleEn: "Market wrap-up",

    dwMarketTitle: "สรุปตลาด DW",
    dwMarketTitleEn: "DW market commentary",

    subscribeTitle: "สมัครจดหมายข่าว",
    subscribeTitleEn: "Subscribe",
    subscribePara: "เกาะติดสถานการณ์ไม่ตกเทรน ด้วยข้อมูลข่าวสารเกี่ยวกับ DW ล่าสุด",
    subscribeParaEn: "Each day in your inbox you will get the inside scoope on what's happening in the DW market.",
    subscribeInputPlaceholder: "สอบถามข้อมูล</br>เกี่ยวกับ DW28",
    subscribeInputPlaceholderEn: "Email",
    subscribeSuccess: "คุณได้ทำการสมัครสมาชิกเรียบร้อยแล้ว กรุณาตรวจสอบข้อมูลผู้ใช้และรหัสผ่านได้จากอีเมล์ที่ท่านใช้ในการสมัคร",
    subscribeSuccessEn: "You are successfully registered as a member. Please check your log in information from your email account.",
    subscribeTerms: "ข้อกำหนดและเงื่อนไข ",
    subscribeTermsEn: "Terms and conditions ",
    subscribeClickHere: "คลิก...",
    subscribeClickHereEn: "Click here…",
    subscribeBtn: "สมัคร",
    subscribeBtnEn: "Agree & Submit",

    activitiesTitle: "กิจกรรม",
    activitiesTitleEn: "Activities",
    activitiesPara: "ติดตามการเคลื่อนไหวและกิจกรรมของแมคควอรีที่นี่",
    activitiesParaEn: "Hear about the latest events and activities from Macquarie.",
}