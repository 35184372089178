import React, { useState, useEffect,Fragment } from 'react';
import LimitedLifes from './LimitedLifes';
import "../../common.css";
import "../../investorAcademy.css";


const LimitedLife = (props) => {
    return ( 
        <Fragment> 
            <LimitedLifes/>
        </Fragment> 
     );
}


export default LimitedLife;