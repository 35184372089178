import React, { useState, useEffect,Fragment } from 'react';
import Deltas from './Deltas';
import "../../common.css";
import "../../investorAcademy.css";


const Delta = (props) => {
    return ( 
        <Fragment> 
            <Deltas/>
        </Fragment> 
     );
}


export default Delta;