import React, { useState, useEffect,Fragment } from 'react';
import SelectingWarrantsSecond from './SelectingWarrants';
import "../../common.css";
import "../../investorAcademy.css";


const SelectingWarrantSecond = (props) => {
    return ( 
        <Fragment> 
            <SelectingWarrantsSecond/>
        </Fragment> 
     );
}


export default SelectingWarrantSecond;