import React, { useState, useEffect,Fragment } from 'react';
import Volatilities from './Volatilities';
import "../../common.css";
import "../../investorAcademy.css";


const Volatility = (props) => {
    return ( 
        <Fragment> 
            <Volatilities/>
        </Fragment> 
     );
}


export default Volatility;