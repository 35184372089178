import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import API from "../../../utils/apiCalling";
import { config } from "../../../utils/apiUrl";
import {languageText} from "../../../languageText/faq"

const FreqAskedAns = (props) => {

  const queryString = window.location.search;
  const urlParams = new URLSearchParams(queryString);
  const questionId = urlParams.get("id");
  const api = new API();
  const [ansFaq, setansFaq] = useState("");
  const [prev, setPrev] = useState(-1);
  const [next, setNext] = useState(-1);
  const [siteLang , setSiteLang] = useState("") 

  useEffect(() => {
    window.scrollTo(0, 0);
    var siteLanguage = $.cookie('applang');
    setSiteLang(siteLanguage)
    if(siteLanguage == "eng"){
      document.querySelector("body").classList.add("eng")
    }else{
      document.querySelector("body").classList.remove("eng")
    }
    getansFaq();
  }, []);

  const getansFaq = async () => {
    let ansFaq = await api.get(config.marketansFaq, true, {
      id: questionId,
      qid: Date.now(),
    });
    const faqData = ansFaq.faqData[0];
    const { prev, next } = ansFaq.nav[0];
    setPrev(prev);
    setNext(next);
    setansFaq(faqData);
  };

  return (
    <div id="individualtip" className="pageobj">
      <div className="breadcrumbbar">
        <div className="container-fluid">
          <ol className="breadcrumb">
            <li>
              <Link to="/home?dwedu">
                {siteLang == "eng" ? languageText.firstNavigationEn : languageText.firstNavigation}
              </Link>
            </li>
            <li>
              <Link id="pachor" to="/education/faq">
                {siteLang == "eng" ? languageText.secondNavigationEn : languageText.secondNavigation}
              </Link>
            </li>
            <li className="active" id="breadcrumb-label">
              {siteLang == "eng" ? `${ansFaq && ansFaq?.en_title}` : `${ansFaq && ansFaq?.thai_title}`}
            </li>
          </ol>
        </div>
      </div>
      <div className="stripe-bg" />
      <div className="container-fluid">
        <div className="section">
          <div className="page-header">
            <h1 id="page-title">
              {siteLang == "eng" ? `${ansFaq && ansFaq?.en_title}` : `${ansFaq && ansFaq?.thai_title}`}
            </h1>
          </div>
          <div id="tip-content">
            <p>
              <div className="topContent" />
              <div
                className="fullContent"
                dangerouslySetInnerHTML={{__html: siteLang == "eng" ? `${ansFaq && ansFaq?.eng_content}` : `${ansFaq && ansFaq?.thai_content}`,}}
              />
            </p>
          </div>
          <div className="pnbar">
            <div className="pnbar-prev">
              <Link
                id="prev-achor"
                className={prev === -1 ? "disabled" : ""}
                to={"/education/innerfaq?id=" + prev}
              >
                &lt; Previous tip
              </Link>
            </div>
            <div className="pnbar-vl" />
            <div className="pnbar-next">
              <Link
                id="next-achor"
                className={next === -1 ? "disabled" : ""}
                to={"/education/innerfaq?id=" + next}
              >
                Next tip &gt;
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default FreqAskedAns;
