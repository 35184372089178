import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./season4.css";

const Season4 = () => {

  const [modal1, setModal1] = useState(true);
  const [modal2, setModal2] = useState(true);
  const [siteLang , setSiteLang] = useState("")

  useEffect(() => {
    window.scrollTo(0, 0);
    var siteLanguage = $.cookie('applang');
    setSiteLang(siteLanguage)
    if(siteLanguage == "eng"){
      document.querySelector("body").classList.add("eng")
    }else{
      document.querySelector("body").classList.remove("eng")
    }
  }, []);

  const closemodal1 = (e) => {
    e.preventDefault();
    modal1 ? setModal1(false) : setModal1(true);
    modal2 ? setModal2(false) : setModal2();
  };

  const closemodal2 = (e) => {
    e.preventDefault();
    modal2 ? setModal2(false) : setModal2(true);
    modal1 ? setModal1(false) : setModal1();
  };

  const handleClick = (Event) => {
    Event.preventDefault();
    window.open(
      "https://www.facebook.com/login.php?skip_api_login=1&api_key=966242223397117&signed_next=1&next=https%3A%2F%2Fwww.facebook.com%2Fshare.php%3Fu%3Dhttps%253A%252F%252Ft1www5.trkd-hs.com%252Fthaidw_aws%252Factivities%252FHSIDIMSUM%253Flang%253Den&cancel_url=https%3A%2F%2Fwww.facebook.com%2Fdialog%2Fclose_window%2F%3Fapp_id%3D966242223397117%26connect%3D0%23_%3D_&display=popup&locale=en_GB", "share", 'width=560,height=650'
    );
  };

  return (
    <div id="supertrader" className="pageobj">
      <div className="breadcrumbbar">
        <div className="container-fluid">
          <ol className="breadcrumb">
            <li>
              <Link to="/home?dwnews">
                {siteLang == "eng" ? "Market news  " : "อัพเดทตลาดหุ้น "}
              </Link>
            </li>
            <li className="active"> Super Trader Season 4</li>
          </ol>
        </div>
      </div>
      <div className="stripe-bg" />
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div className="section">
              <div className="page-header">
                <h1>Super Trader Season 4</h1>
                <div className="btn-area">
                  <div className="fbSharebtn" onClick={handleClick}>
                    <i className="fa fa-facebook" aria-hidden="true" />
                    Share on Facebook
                  </div>
                </div>
              </div>
              <div className="img-holder">
                <img src="/img/game/season_top1.jpg" />
              </div>
              <div className="img-holder">
                <img src="/img/game/season_top2.jpg" />
              </div>
              <div className="img-holder">
                <a
                  id="href-pdf-01"
                  href="https://t1www5.trkd-hs.com/thaidw_aws/pdf/supertrader4_DWterms.pdf"
                  style={{ cursor: "pointer" }}
                ></a>
                <img src="/img/game/season_top3.png" />
              </div>
              <div className="inner_title">Score Board</div>
              <div className="panel-group" id="accordion">
                <div className="panel panel-default">
                  <div className="panel-heading">
                    <Link data-toggle="collapse" data-parent="#accordion">
                      <h4
                        id="collapse_title1"
                        className="panel-title text-center"
                        onClick={closemodal1}
                      >
                        ผู้ชนะรางวัลประจำเดือน
                        <img
                          className="icon-more hidden-xs"
                          src="/img/game/downarrow.png"
                          onClick={closemodal1}
                        />
                      </h4>
                    </Link>
                  </div>
                  <div
                    id="collapse_item1"
                    className="collapse_item panel-collapse collapse in "
                    style={{ display: modal1 ? "block" : "none" }}
                  >
                    <div className="row">
                      <div className="col-xs-12 col-sm-6 inner_col1">
                        <table>
                          <tbody>
                            <tr>
                              <td>
                                <img src="/img/game/supertrader4_leader1.jpg" />
                              </td>
                              <td>
                                <div style={{ marginBottom: "10px" }}>
                                  <p>เมษายน +66.23%</p>
                                </div>
                                <p>
                                  ทีม Sniper Trade
                                  <br />
                                  คุณ พิชญาณ บุปผเวส
                                </p>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div className="col-xs-12 col-sm-6 inner_col2">
                        <p className="text-center">
                          “ตัวที่ทำให้เข้ารอบคือ BLA ซื้อ DW
                          ที่หุ้นแม่อยู่ในแนวรับ
                          <br />
                          โดยที่ถ้าหุ้นแม่ขยับลง 2-3 ช่อง DW ขยับ 1 ช่อง
                          ซึ่งอาจจะ
                          <br />
                          มีโอกาสปรับเพิ่มขึ้นมากกว่าลงต่อ เพื่อจำกัดความเสี่ยง”
                          <br />
                        </p>
                        <div >
                          อ่านเคล็ดลับของผู้ชน&nbsp;&nbsp;
                          <Link
                            target="_blank"
                            to={{
                              pathname:
                                "https://docs.google.com/viewer?url=https://www.thaidw.com/pdf/Website_SPT4_Article-Apr_L2-PDF.PDF",
                            }}
                          >
                            <img
                              style={{ width: "70px" }}
                              src="/img/game/innerbtn.png"
                            />
                          </Link>
                        </div>
                        {/*<div>อ่านเคล็ดลับของผู้ชนะ&nbsp;&nbsp;<a target="_blank" href="https://docs.google.com/viewer?url=https://t1.trkd-hs.com/thaidw/pdf/Website_SPT4_Article-Apr_L2-PDF.PDF" ><img style="width: 70px" src="/thaidw_aws/img/supertrader4/innerbtn.png" ></a></div>*/}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-xs-12 col-sm-6 inner_col1">
                        <table>
                          <tbody>
                            <tr>
                              <td>
                                <img src="/img/game/supertrader4_leader2.jpg" />
                              </td>
                              <td>
                                <div style={{ marginBottom: "10px" }}>
                                  <p>มีนาคม +52.09%</p>
                                </div>
                                <p>
                                  ทีม Sniper Trade
                                  <br />
                                  คุณ ชูชาติ เจริญอุดมผล
                                </p>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div className="col-xs-12 col-sm-6 inner_col2">
                        <p className="text-center">
                          “ผมเทรด DW ของ PTT ฝั่ง Put โดยเลือก Sensitivity
                          <br />
                          มีค่าประมาณ 1 เพื่อให้ DW เปลี่ยนช่องเร็ว
                          และดูแนวโน้มของ PTT
                          <br />
                          หลุดแนวรับ พร้อมจะลงจากนั้นซื้อฝั่งนั้นเป็นหลัก”
                          <br />
                        </p>
                        <div >
                          อ่านเคล็ดลับของผู้ชนะ&nbsp;&nbsp;
                          <Link
                            target="_blank"
                            to={{
                              pathname:
                                "https://docs.google.com/viewer?url=https://www.thaidw.com/pdf/Website_SPT4_Article-Mar_L1-PDF.pdf",
                            }}
                          >
                            <img
                              style={{ width: "70px" }}
                              src="/img/game/innerbtn.png"
                            />
                          </Link>
                        </div>
                        {/*<div>อ่านเคล็ดลับของผู้ชนะ&nbsp;&nbsp;<a target="_blank" href="https://docs.google.com/viewer?url=https://t1.trkd-hs.com/thaidw/pdf/Website_SPT4_Article-Mar_L1-PDF.pdf" ><img style="width: 70px" src="/thaidw_aws/img/supertrader4/innerbtn.png" ></a></div>*/}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-xs-12 col-sm-6 inner_col1">
                        <table>
                          <tbody>
                            <tr>
                              <td>
                                <img src="/img/game/supertrader4_leader3.jpg" />
                              </td>
                              <td>
                                <div style={{ marginBottom: "10px" }}>
                                  <p>กุมภาพันธ์ +33.9%</p>
                                </div>
                                <p>
                                  ทีม Phoenix
                                  <br />
                                  คุณ ณัฐวรรธน์ วงศ์มีชัย
                                </p>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div className="col-xs-12 col-sm-6 inner_col2">
                        <p className="text-center">
                          "ตัวที่ทำให้ชนะคือ MTLS และ SGP <br />
                          เข้าซื้อในจังหวะพักตัวในขาขึ้น หรือ Buy on dip" <br />
                        </p>
                        <div >
                          อ่านเคล็ดลับของผู้ชนะ&nbsp;&nbsp;
                          <Link
                            target="_blank"
                            to={{
                              pathname:
                                "https://docs.google.com/viewer?url=https://www.thaidw.com/pdf/Website_SPT4_Article-Feb_L1-PDF.pdf",
                            }}
                          >
                            <img
                              style={{ width: "70px" }}
                              src="/img/game/innerbtn.png"
                            />
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-xs-12 col-sm-6 inner_col1">
                        <table>
                          <tbody>
                            <tr>
                              <td>
                                <img src="/img/game/supertrader4_leader4.jpg" />
                              </td>
                              <td>
                                <div style={{ marginBottom: "10px" }}>
                                  <p>มกราคม +100.7%</p>
                                </div>
                                <p>
                                  ทีม Sniper Trade
                                  <br />
                                  คุณ วิจิตร เหลาสะอาด
                                </p>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div className="col-xs-12 col-sm-6 inner_col2">
                        <p className="text-center">
                          "ผมเลือก Sensitivity ที่มีค่าใกล้เคียง 1 <br />
                          เพราะแม่ขยับ 1 ช่อง ลูกขยับ 1 ช่อง" <br />
                        </p>
                        <div >
                          อ่านเคล็ดลับของผู้ชนะ&nbsp;&nbsp;
                          <Link
                            target="_blank"
                            to={{
                              pathname:
                                "https://docs.google.com/viewer?url=https://www.thaidw.com/pdf/Website_SPT4_Article-Jan_L1-PDF.pdf",
                            }}
                          >
                            <img
                              style={{ width: "70px" }}
                              src="/img/game/innerbtn.png"
                            />
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-xs-12 col-sm-6 inner_col1">
                        <table>
                          <tbody>
                            <tr>
                              <td>
                                <img src="/img/game/supertrader4_leader5.jpg" />
                              </td>
                              <td>
                                <div style={{ marginBottom: "10px" }}>
                                  <p>ธันวาคม +67.7%</p>
                                </div>
                                <p>
                                  ทีม Ninja Assassin
                                  <br />
                                  คุณ อัญชลี ตปนียทรัพย์
                                </p>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div className="col-xs-12 col-sm-6 inner_col2">
                        <p className="text-center">
                          "DW ที่ชนะการแข่งขันครั้งนี้ <br />
                          โดยหุ้นแม่ที่เลือกคือ MEGA" <br />
                        </p>
                        <div >
                          อ่านเคล็ดลับของผู้ชนะ&nbsp;&nbsp;
                          <Link
                            target="_blank"
                            to={{
                              pathname:
                                "https://docs.google.com/viewer?url=https://www.thaidw.com/pdf/Website_SPT4_Article-Dec_L1-PDF.pdf",
                            }}
                          >
                            <img
                              style={{ width: "70px" }}
                              src="/img/game/innerbtn.png"
                            />
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="row last">
                      <div className="col-xs-12 col-sm-6 inner_col1">
                        <table>
                          <tbody>
                            <tr>
                              <td>
                                <img src="/img/game/supertrader4_leader6.jpg" />
                              </td>
                              <td>
                                <div style={{ marginBottom: "10px" }}>
                                  <p>พฤศจิกายน +27.8%</p>
                                </div>
                                <p>
                                  ทีม Republic
                                  <br />
                                  คุณ ชวัชร์พล วสุกูลพันธุ์
                                </p>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div className="col-xs-12 col-sm-6 inner_col2">
                        <p className="text-center">
                          "สำหรับหุ้นที่ทำให้ชนะในการเทรดครั้งนี้ <br />
                          น่าจะเป็นหุ้น KTC" <br />
                        </p>
                        <div >
                          อ่านเคล็ดลับของผู้ชนะ&nbsp;&nbsp;
                          <Link
                            target="_blank"
                            to={{
                              pathname:
                                "https://docs.google.com/viewer?url=https://www.thaidw.com/pdf/Website_SPT4_Article-Nov_L1-PDF.pdf",
                            }}
                          >
                            <img
                              style={{ width: "70px" }}
                              src="/img/game/innerbtn.png"
                            />
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="panel panel-default">
                  <div className="panel-heading">
                    <a
                      data-toggle="collapse"
                      data-parent="#accordion"
                      
                    >
                      <h4
                        id="collapse_title2"
                        className="panel-title text-center"
                        onClick={closemodal2}
                      >
                        ผู้ชนะรางวัล DW28
                        <img
                          className="icon-more hidden-xs"
                          src="/img/game/downarrow.png"
                          onClick={closemodal2}
                        />
                      </h4>
                    </a>
                  </div>
                  <div
                    id="collapse_item2"
                    className="collapse_item panel-collapse collapse in"
                    style={{ display: modal2 ? "block" : "none" }}
                  >
                    <div className="row">
                      <div className="col-xs-12 col-sm-6 inner_col1">
                        <table>
                          <tbody>
                            <tr>
                              <td>
                                <img src="/img/game/supertrader4_leader7.jpg" />
                              </td>
                              <td>
                                <div style={{ marginBottom: "10px" }}>
                                  <p>ผู้ชนะรางวัลที่ 1 +8.96%</p>
                                </div>
                                <p>
                                  ทีม Sniper Trade
                                  <br />
                                  คุณ วันนิดา เพชรลำพัน
                                </p>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div className="col-xs-12 col-sm-6 inner_col2">
                        <p className="text-center">
                          ““Focus หุ้นเป็นตัวๆ รักษา Mindset ที่ดี
                          <br />
                          Stay in my game”
                          <br />
                        </p>
                        <div >
                          อ่านเคล็ดลับของผู้ชนะ&nbsp;&nbsp;
                          <Link
                            target="_blank"
                            to={{
                              pathname:
                                "https://docs.google.com/viewer?url=https://www.thaidw.com/pdf/Website_SPT4_Article-1stWinner_L1-PDF.pdf",
                            }}
                          >
                            <img
                              style={{ width: "70px" }}
                              src="/img/game/innerbtn.png"
                            />
                          </Link>
                        </div>
                        {/*<div>อ่านเคล็ดลับของผู้ชนะ&nbsp;&nbsp;<a target="_blank" href="https://docs.google.com/viewer?url=https://t1.trkd-hs.com/thaidw/pdf/Website_SPT4_Article-1stWinner_L1-PDF.pdf" ><img style="width: 70px" src="/thaidw_aws/img/supertrader4/innerbtn.png" ></a></div>*/}
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-xs-12 col-sm-6 inner_col1">
                        <table>
                          <tbody>
                            <tr>
                              <td>
                                <img src="/img/game/supertrader4_leader1.jpg" />
                              </td>
                              <td>
                                <div style={{ marginBottom: "10px" }}>
                                  <p>ผู้ชนะรางวัลที่ 2 +7.87%</p>
                                </div>
                                <p>
                                  ทีม Sniper Trade
                                  <br />
                                  คุณ พิชญาณ บุปผเวส
                                </p>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div className="col-xs-12 col-sm-6 inner_col2">
                        <p className="text-center">
                          ““ตัวที่ทำให้เข้ารอบคือ BLA ซื้อ DW
                          ที่หุ้นแม่อยู่ในแนวรับ
                          <br />
                          โดยที่ถ้าหุ้นแม่ขยับลง 2-3 ช่อง DW ขยับ 1 ช่อง
                          ซึ่งอาจจะ
                          <br />
                          มีโอกาสปรับเพิ่มขึ้นมากกว่าลงต่อ เพื่อจำกัดความเสี่ยง”
                          <br />
                        </p>
                        <div >
                          อ่านเคล็ดลับของผู้ชนะ&nbsp;&nbsp;
                          <Link
                            target="_blank"
                            to={{
                              pathname:
                                "https://docs.google.com/viewer?url=https://www.thaidw.com/pdf/Website_SPT4_Article-Apr_L2-PDF.PDF",
                            }}
                          >
                            <img
                              style={{ width: "70px" }}
                              src="/img/game/innerbtn.png"
                            />
                          </Link>
                        </div>
                        {/*<div>อ่านเคล็ดลับของผู้ชนะ&nbsp;&nbsp;<a target="_blank" href="https://docs.google.com/viewer?url=https://t1.trkd-hs.com/thaidw/pdf/Website_SPT4_Article-Apr_L2-PDF.PDF" ><img style="width: 70px" src="/thaidw_aws/img/supertrader4/innerbtn.png" ></a></div>*/}
                      </div>
                    </div>
                    <div className="row last">
                      <div className="col-xs-12 col-sm-6 inner_col1">
                        <table>
                          <tbody>
                            <tr>
                              <td>
                                <img src="/img/game/supertrader4_leader8.jpg" />
                              </td>
                              <td>
                                <div style={{ marginBottom: "10px" }}>
                                  <p>ผู้ชนะรางวัลที่ 3 +6.65%</p>
                                </div>
                                <p>
                                  ทีม Sniper Trade
                                  <br />
                                  คุณ ฮาซันกาแมล สัญญา
                                </p>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div className="col-xs-12 col-sm-6 inner_col2">
                        <p className="text-center">
                          “ผมเทรด DW ของ S50 ทั้ง Put และ call
                          <br />
                          โดยเลือก Sensitivity มีค่าประมาณ 1 หรือน้อยกว่า
                          <br />
                          เพื่อให้ DW เปลี่ยนช่องเร็ว”
                          <br />
                        </p>
                        <div >
                          อ่านเคล็ดลับของผู้ชนะ&nbsp;&nbsp;
                          <Link
                            target="_blank"
                            to={{
                              pathname:
                                "https://docs.google.com/viewer?url=https://www.thaidw.com/pdf/Website_SPT4_Article-3rdWinner_L1-PDF.pdf",
                            }}
                          >
                            <img
                              style={{ width: "70px" }}
                              src="/img/game/innerbtn.png"
                            />
                          </Link>
                        </div>
                        {/*<div>อ่านเคล็ดลับของผู้ชนะ&nbsp;&nbsp;<a target="_blank" href="https://docs.google.com/viewer?url=https://t1.trkd-hs.com/thaidw/pdf/Website_SPT4_Article-3rdWinner_L1-PDF.pdf" ><img style="width: 70px" src="/thaidw_aws/img/supertrader4/innerbtn.png" ></a></div>*/}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="inner_title_bottom" />
              <div className="area_sharing greybg">
                <div className="htxt">ร่วมเกาะติดผลการแข่งขันกันได้ที่</div>
                <div className="row link-row">
                  <div className="col-sm-5 col-xs-12 col_left">
                    <Link
                      className="link-item"
                      target="_blank"
                      to={{ pathname: "https://www.thaidw.com" }}
                    >
                      <img
                        className="link-item-img"
                        src="/img/game/Social-1.png"
                      />
                      <div className="link-item-txt">www.thaidw.com</div>
                    </Link>
                  </div>
                  <div className="col-sm-2 col-xs-12 col_middle">
                    <Link
                      className="link-item"
                      target="_blank"
                      to={{ pathname: "https://line.me/ti/p/%40upt3914e" }}
                    >
                      <img
                        className="link-item-img"
                        src="/img/game/Social2.png"
                      />
                      <div className="link-item-txt">@ThaiDW</div>
                    </Link>
                  </div>
                  <div className="col-sm-5 col-xs-12 col_right">
                    <Link
                      className="link-item"
                      target="_blank"
                      to={{
                        pathname: "https://www.facebook.com/macquariedw28",
                      }}
                    >
                      <img
                        className="link-item-img"
                        src="/img/game/Social3.png"
                      />
                      <div className="link-item-txt">Macquarie DW28-Thaidw</div>
                    </Link>
                  </div>
                  <div className="col-sm-6 col-xs-12 col_left">
                    <a className="link-item" href="http://www.globlex.co.th">
                      <img
                        className="link-item-img"
                        src="/img/game/Social-1.png"
                      />
                      <div className="link-item-txt">www.globlex.co.th</div>
                    </a>
                  </div>
                  <div className="col-sm-6 col-xs-12 col_right">
                    <Link
                      className="link-item"
                      target="_blank"
                      to={{ pathname: "https://www.supertrader.co.th" }}
                    >
                      <img
                        className="link-item-img"
                        src="/img/game/Social-1.png"
                      />
                      <div className="link-item-txt">www.supertrader.co.th</div>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="area_sharing">
                <div className="htxt">สนับสนุนโดย</div>
                <div className="img-holder">
                  <img src="/img/game/Sponsor-1.jpg" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Season4;
