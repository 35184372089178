export const languageText ={
    
    firstNavigation: "เครื่องมือ DW ",
    firstNavigationEn: "DW tools ",
    secondNavigation: " ตารางราคา",
    secondNavigationEn: " Live matrix",

    pageTitle: "ตารางราคา",
    pageTitleEn: "Live matrix",
    pageDescription: "เทรดง่ายขึ้นด้วยตารางที่แสดงราคา Bid ของ DW และ อัพเดทช่วงราคาตลอดทั้งวัน",
    pageDescriptionEn: "Makes trading DW easy by showing investors where our bid prices will be throughout the day",

    selectPlaceholder: "เลือกชื่อย่อ DW",
    selectPlaceholderEn: "Select DW symbol",

    set50Contract: "ราคาหลักทรัพย์อ้างอิงด้านล่างนี้ เคลื่อนไหวเปรียบเทียบกับสัญญาซื้อขายล่วงหน้า ",
    set50ContractEn: "Underlying price below refers to the relevant SET50 Index Futures contract ",
    hsiContract: "ราคาหลักทรัพย์อ้างอิงด้านล่างนี้ เคลื่อนไหวเปรียบเทียบกับสัญญาซื้อขายล่วงหน้าดัชนี ",
    hsiContractEn: "Underlying price below refers to the relevant Index Futures contract ",

    hkssImgLink: "/img/livematrix/HK_SS_PM_TH.jpg",
    hkssImgLinkEn: "/img/livematrix/HK_SS_PM_EN.gif",

    tableIndex: "ตลาด",
    tableIndexEn: "Index",
    tableContract: "สัญญา",
    tableContractEn: "Contract",
    tableLast: "ราคาล่าสุด",
    tableLastEn: "Last",
    tableChange: "เปลี่ยนแปลง",
    tableChangeEn: "Change",
    tableChangePercent: "เปลี่ยนแปลง %",
    tableChangePercentEn: "Change%",

    priceUpdateInfo: "*กดปุ่ม เพื่ออัพเดท ราคา ณ ",
    priceUpdateInfoEn: "* Click refresh button for live quote Price as at ",

    lmtabtblTdFirst: "ตารางราคาวันนี้",
    lmtabtblTdFirstEn: "Today's live price matrix",
    lmtabtblTdLast: "ตารางราคา 5 วันทำการ",
    lmtabtblTdLastEn: "Five day price matrix",

    lastUpdate: "อัพเดทล่าสุด",
    lastUpdateEn: "Last update",

    rotateView: "Rotate to view more",
    rotateViewEn: "Rotate to view more",

    todayTableUnderlyingBid: "Bid ของหลักทรัพย์อ้างอิง",
    todayTableUnderlyingBidEn: "Underlying bid",
    todayTableDwBid: "DW bid",
    todayTableDwBidEn: "DW bid",
    todayTableDwOffer: "DW offer",
    todayTableDwOfferEn: "DW offer",
    todayTableUnderlyingOffer: "Offer ของหลักทรัพย์อ้างอิง",
    todayTableUnderlyingOfferEn: "Underlying offer",
    todayTableDwBidPrice: "ราคาเสนอซื้อล่าสุดของ DW (บาท)",
    todayTableDwBidPriceEn: "DW Bid price (THB)",

    publicHoliday: "วันหยุดราชการ",
    publicHolidayEn: "Public holiday",
    rollOverDay: "Roll-over date*",
    rollOverDayEn: "Roll-over date*",

    indexFuturesInstruction: "*วันที่เปลี่ยนสัญญาซื้อขายล่วงหน้าที่ใช้เปรียบเทียบในตารางราคานี้",
    indexFuturesInstructionEn: "*It is the date that we change the relevant SET50 Index Futures contract referred in the above price matrix.",

    sstableUnderlying: "หลักทรัพย์อ้างอิง",
    sstableUnderlyingEn: "Underlying",
    sstableEffectiveGearing: "Effective gearing(X)",
    sstableEffectiveGearingEn: "Effective gearing(X)",
    sstableType: "ประเภท",
    sstableTypeEn: "Type",
    sstableDelta: "Delta(%)",
    sstableDeltaEn: "Delta(%)",
    sstableStrike: "ราคาใช้สิทธิ",
    sstableStrikeEn: "Strike",
    sstableImpliedVolatility: "Implied volatility(%)",
    sstableImpliedVolatilityEn: "Implied volatility(%)",
    sstableSensitivity: "Sensitivity",
    sstableSensitivityEn: "Sensitivity",
    sstableLastTradingDate: "วันซื้อขายสุดท้าย",
    sstableLastTradingDateEn: "Last trading date",
    sstableSensitivityIndex: "Sensitivity by Index Point",
    sstableSensitivityIndexEn: "Sensitivity by Index Point",

    todayLivePriceMatirx: "ตารางราคาวันนี้",
    todayLivePriceMatirxEn: "Today's live price matrix",
    fiveDayPriceMatirx: "ตารางราคา 5 วันทำการ",
    fiveDayPriceMatirxEn: "Five day price matrix",

    todayItemDescription: 'ตารางราคาด้านบนนี้ เป็นราคา Live จากระบบดูแลสภาพคล่องของแมคควอรีโดยตรง ซึ่งจะแสดงให้เห็นถึงราคาที่ผู้ดูแลสภาพคล่องวางคำสั่งเสนอซื้อ-เสนอขายของ DW ในระดับดัชนีอ้างอิงต่างๆ นักลงทุนยังสามารถดูตารางราคา 5 วันทำการ ที่เป็นค่าโดยประมาณ เพียงคลิกที่แถบตารางราคา 5 วันทำการได้<br><br><strong>ข้อสำคัญ</strong> : ตารางราคาอาจมีการเปลี่ยนแปลงระหว่างวัน ในกรณีที่มีการเปลี่ยนแปลงดังกล่าว นักลงทุนจะสามารถเห็นได้โดยจะต้องทำการ update หน้าตารางราคาทุกครั้ง ทั้งนี้ราคา DW บนกระดานซื้อขายอาจจะแตกต่างจากราคาที่ปรากฎอยู่บนตารางราคาด้านบน โดยราคาและข้อมูลของตารางราคาอาจมีการล่าช้าได้ ในกรณีที่นักลงทุนมีคำถามหรือต้องการความช่วยเหลือสามารติดต่อได้ที่ 02-694-7777',
    todayItemDescriptionEn: 'The above price matrix is a live feed directly from Macquarie’s market making system. It shows where the market maker’s bids and offers will be in the DW, based on various price points in the stock or index. To view an estimate of the price matrix over the coming 5 days, click on the Five day price matrix tab.<br><br><strong>Important notice</strong>: The live matrix may be updated during trading hours, and if so they will be reflected when you refresh the live matrix. The actual prices of DW may differ from the prices displayed above. Information contained in the live matrix  may occasionally be delayed . Please call us at 02-694-7777 if you have any questions or require immediate assistance.',
    fivedayItemDescription: 'ตารางราคา 5 วัน ด้านบนนี้ เป็นราคารับซื้อคืนของผู้ดูแลสภาพคล่องโดยประมาณของ DW ในหุ้นอ้างอิงที่ระดับราคาต่างๆในช่วง 5 วันทำการถัดไป นักลงทุนควรตระหนักว่าราคาอาจมีการเปลี่ยนแปลงในช่วงเวลาต่างๆ อันเนื่องมาจากปัจจัยที่เกี่ยวข้องหลายประการ เช่น การเปลี่ยนแปลงระดับ implied volatility เป็นต้น ดังนั้น ราคาในวันถัดไป จึงเป็นราคาประมาณสำหรับการคาดการณ์แนวโน้มในอนาคตเท่านั้น',
    fivedayItemDescriptionEn: "The above five day price matrix is an estimate of where the market maker's bid price will be in the DW at various bid prices in the stock over the coming five days. Please note, DW pricing can vary over time based on a number of factors including changes in implied volatility. Hence the time series for future dates should be used as a guide only.",
    hsiItemDescription: 'ตารางราคาด้านบนนี้ เป็นราคา Live จากระบบดูแลสภาพคล่องของแมคควอรีโดยตรง ซึ่งแสดงราคาที่ผู้ดูแลสภาพคล่องวางคำสั่งเสนอซื้อ-เสนอขายของ DW ในระดับดัชนีอ้างอิงต่าง ๆ <br><br><strong>ข้อสำคัญ</strong>: ตารางราคาอาจมีการเปลี่ยนแปลงระหว่างวัน ในกรณีที่มีการเปลี่ยนแปลงดังกล่าว นักลงทุนจะสามารถเห็นได้โดยจะต้องทำการ update หน้าตารางราคาทุกครั้ง ทั้งนี้ราคา DW บนกระดานซื้อขายอาจจะแตกต่างจากราคาที่ปรากฎอยู่บนตารางราคาด้านบน โดยราคาและข้อมูลของตารางราคาอาจจะคลาดเคลื่อนในช่วงนอกเวลาทำการของผู้ดูแลสภาพคล่อง และอาจมีการล่าช้าได้ ในกรณีที่นักลงทุนมีคำถามหรือต้องการความช่วยเหลือสามารติดต่อได้ที่ 02-694-7777',
    hsiItemDescriptionEn: 'The above price matrix is a live feed directly from Macquarie’s market making system. It shows where the market maker’s bids and offers will be in the DW, based on various price points in the index. <br><br><strong>Important notice</strong>: The live matrix may be updated during trading hours, and if so they will be reflected when you refresh the live matrix. The actual prices of DW may differ from the prices displayed above. Information contained in the live matrix may not be accurate outside of our market making hours* and may occasionally be delayed. Please call us at 02-694-7777 if you have any questions or require immediate assistance.',
    
    lmatrixTbl1li: 'ราคา DW ที่แสดงไว้เป็นราคาโดยประมาณและเป็นการเปรียบเทียบกับช่วงราคาของหลักทรัพย์อ้างอิง ตามที่ระบุตามช่วงเวลาทำการซื้อขายปกติของตลาดหลักทรัพย์แห่งประเทศไทย แต่ไม่รวมถึงช่วง Pre-Opening รอบเช้าและบ่าย รวมถึงช่วง 5 นาทีแรกหลังเปิดทำการซื้อขาย (ทั้งรอบเช้าและบ่าย) และ Auction ทั้งนี้ราคา DW มิได้เป็นการประกันราคาหรือผลตอบแทนของ DW ที่เสนอขายไม่ว่าปัจจุบันหรืออนาคต แมคควอรีขอสงวนสิทธิ์ในการเปลี่ยนแปลงราคา DW โดยไม่จำเป็นต้องแจ้งล่วงหน้า และสงวนสิทธิ์ในการพิจารณาหลักเกณฑ์และเงื่อนไขในการรับซื้อคืนหรือขายเป็นรายกรณี',
    lmatrixTbl1liEn: 'The DW price is an approximate price in comparison with the mentioned underlying share price range during the normal trading hours set by the Stock Exchange of Thailand. However, this displayed price does not cover the pre-opening period both during the morning and the afternoon sessions and the subsequent 5 minutes after trading resumed in those 2 sessions as well as the auction period. Neither does this displayed price insure against price fluctuations nor guarantee returns on the offered DW whether it is at the present or in the future. Macquarie would like to maintain our rights solely to amend the mentioned DW price without having to inform anyone in advance, together with rights to consider the terms and the conditions on buying back or selling DW on case-by-case basis.',
    lmatrixTbl2li: 'ราคา DW ที่แสดงไว้เป็นราคาโดยประมาณและเป็นการเปรียบเทียบกับช่วงราคาของดัชนีอ้างอิงตามที่ระบุช่วงเวลาทำการของผู้ดูแลสภาพคล่อง แต่ไม่รวมถึงช่วง Pre-Opening รอบเช้าและบ่าย   รวมถึงช่วง 5 นาทีแรกหลังเปิดทำการซื้อขาย (ทั้งรอบเช้าและบ่าย) และ Auction ทั้งนี้ราคา DW มิได้เป็นการประกันราคาหรือผลตอบแทนของ DW ที่เสนอขายไม่ว่าปัจจุบันหรืออนาคต แมคควอรีขอสงวนสิทธิ์ในการเปลี่ยนแปลงราคา DW โดยไม่จำเป็นต้องแจ้งล่วงหน้า และสงวนสิทธิ์ในการพิจารณา หลักเกณฑ์และเงื่อนไขในการรับซื้อคืนหรือขายเป็นรายกรณี',
    lmatrixTbl2liEn: 'The DW price is an approximate price in comparison with the mentioned underlying share price range during the market making hours. However, this displayed price does not cover the pre-opening period both during the morning and the afternoon sessions and the subsequent 5 minutes after trading resumed in those 2 sessions as well as the auction period. Neither does this displayed price insure against price fluctuations nor guarantee returns on the offered DW whether it is at the present or in the future. Macquarie would like to maintain our rights solely to amend the mentioned DW price without having to inform anyone in advance, together with rights to consider the terms and the conditions on buying back or selling DW on case-by-case basis.',
    lmatrixTbl3li: 'ราคาของ DW ที่ซื้อขายในตลาดหลักทรัพย์แห่งประเทศไทย อาจไม่สัมพันธ์กับราคา DW ดังกล่าว อันเนื่องมาจากปัจจัยอื่นๆ เช่น 1) สภาพคล่อง ความผันผวนของราคา DW และราคาหลักทรัพย์อ้างอิง รวมถึงภาวะตลาดที่เกี่ยวข้อง 2) การซื้อขายของนักลงทุนตามอุปสงค์อุปทาน (demand-supply) ในตลาด 3) ความล่าช้าในการแสดงราคา และ 4) สิทธิประโยชน์ต่างๆของหุ้นอ้างอิง และปัจจัยอื่นๆ ที่อยู่นอกเหนือการควบคุมของผู้ออกใบสำคัญแสดงสิทธิอนุพันธ์ ผู้ดูแลสภาพคล่อง ตลอดจนผู้เกี่ยวข้อง หรือเหตุการณ์ที่ไม่อาจคาดหมายได้ นักลงทุนควรศึกษาข้อมูลเกี่ยวข้องอย่างระมัดระวัง และครบถ้วนก่อนการลงทุน',
    lmatrixTbl3liEn: 'The price of the DW that is traded in the Stock Exchange of Thailand may not be related to the mentioned DW price due to the other factors such as 1) the liquidity and  the volatility of the DW price and the underlying price as well as the relevant market conditions 2) the buying and the selling that is driven by the supply and the demand in the market 3) the delay in displaying the price 4) The various rights of the underlying and other factors that are beyond the control of derivative warrant’s issuer, the market maker, the relevant parties or the unexpected situations. Investors should study the relevant information carefully and thoroughly before making investment decisions.',
    lmatrixTbl4li: 'ราคา DW เป็นข้อมูลทั่วไปสำหรับการใช้ประโยชน์ส่วนบุคคลเท่านั้น และมิได้เป็นการบอกให้ซื้อหรือขาย หรือเป็นการชักชวน การเสนอขาย และ/หรือการแนะนำให้ลงทุนในหลักทรัพย์อ้างอิง หรือ DW ไม่ว่าทางตรงหรือทางอ้อมแต่อย่างใด  แมคควอรีไม่มีภาระผูกพัน ไม่รับรองต่อความครบถ้วนและถูกต้องของข้อมูลดังกล่าว หรือต่อการขาดทุนหรือการสูญเสียใดๆที่อาจเกิดขึ้นจากการใช้ข้อมูลใดๆ  ซึ่งหมายรวมแต่ไม่จำกัดถึงการสูญเสียเงินลงทุน หรือการสูญเสียผลกำไร ไม่ว่าทางตรงหรือทางอ้อม  และไม่ว่าทั้งหมดหรือบางส่วน',
    lmatrixTbl4liEn: 'The DW price is provided for your personal use and intended for information purpose only and does not constitute an offer, a solicitation, an advice, a recommendation or an indication to buy or sell the underlying securities or DW. MACQ shall not be liable for the accuracy or completeness of any information or for any loss or damage however caused arising in connection with any information, including without limitation any direct, indirect, special or consequential loss and loss of profits whether in part or in full.',
    lmatrixTbl5li: 'ค่าต่าง ๆ ที่แสดงด้านบน อาทิเช่น Effective Gearing, Implied Volatility (%), Sensitivity by index point, Sensitivity, Time decay (Baht per day) , Time decay (Number of day per tick) เป็นต้น เป็นค่าที่สามารถเปลี่ยนแปลงได้ เพื่อตอบสนองต่อปัจจัยต่าง ๆ เช่น การเปลี่ยนแปลงของราคาสินค้าอ้างอิง ค่าความผันผวน อัตราแลกเปลี่ยน เป็นต้น',
    lmatrixTbl5liEn: 'The values shown above such as Effective Gearing, Implied Volatility (%), Sensitivity by index point, Sensitivity, Time decay (Baht per day), Time decay (Number of day per tick), etc. are variable values subject to change in response to various factors such as underlying price change, volatility, FX, etc.',
    lmatrixTbl1Para: "บริษัทหลักทรัพย์ แมคควอรี (ประเทศไทย) จำกัด (Macquarie Securities (Thailand) Limited: “บริษัทฯ”) มิได้ให้บริการรับฝากเงินและ/หรือดำเนินธุรกิจการธนาคารในประเทศไทยแต่อย่างใด และความรับผิดชอบต่างๆ ของบริษัทฯ มิได้แสดงถึงการบริการรับฝากเงิน/กู้เงิน ที่สัมพันธ์กับ Macquarie Bank Limited (เลขทะเบียนบริษัท ABN 46 008 583 542) และ Macquarie Bank Limited มิได้ให้การรับประกันหรือรับรองความรับผิดชอบของบริษัทฯ แต่อย่างใด",
    lmatrixTbl1ParaEn: "Macquarie Securities (Thailand) (“MACQ”) is not an authorised deposit-taking institution for the purposes of the Banking Act (Cth) 1959, and MACQ’s obligations do not represent deposits or other liabilities of Macquarie Bank Limited ABN 46 008 583 542. Macquarie Bank Limited does not guarantee or otherwise provide assurance in respect of the obligations of MACQ.",
    lmatrixTbl2Para: "*ราคาดัชนีหลักทรัพย์อ้างอิงที่แสดงไว้ในเว็บไซต์ เป็นราคาของ Index Futures เนื่องจาก DW อ้างอิงดัชนี ที่ออกโดยแมคควอรี มีการชำระราคา ณ วันครบกำหนดอายุ โดยใช้ Final Settlement Price ของ Index Futures เป็นดัชนีที่ใช้ชำระราคา",
    lmatrixTbl2ParaEn: "*The underlying price shown on the website is the Index Futures price. All Macquarie Index DWs are settled at expiry using the Final Settlement Price of index Futures.",
    lmatrixTbl3Para: "** เฉพาะในกรณีที่ตลาดหลักทรัพย์แห่งประเทศไทย และตลาดหลักทรัพย์ของหลักทรัพย์อ้างอิง (DW อ้างอิงหุ้นต่างประเทศ) หรือตลาด Futures ของดัชนีหลักทรัพย์อ้างอิง (DW อ้างอิงดัชนีต่างประเทศ) เปิดพร้อมกันตามช่วงเวลาที่ระบุในตารางข้างต้น หรือในกรณีที่หลักทรัพย์อ้างอิงหรือ Futures ของดัชนีหลักทรัพย์อ้างอิงหยุดซื้อขาย ผู้ดูแลสภาพคล่องจะไม่สามารถปฎิบัติหน้าที่ดูแลสภาพคล่องบนหลักทรัพย์อ้างอิงนั้น ๆ ตามช่วงเวลาที่ระบุได้</br>     - การดูแลสภาพคล่อง ณ วันซื้อขายสุดท้ายสำหรับ DW28 ในกรณีที่ DW</br> ก. อ้างอิงดัชนี HSI/HSTECH/HSCEI ผู้ดูแลสภาพคล่องจะรับซื้อคืนตามตารางราคาถึงเวลา 14:59 น. (เวลาประเทศไทย)</br>ข. อ้างอิงดัชนี SET50 ผู้ดูแลสภาพคล่องจะรับซื้อคืนตามตารางราคาถึงเวลา 16:29 น. (เวลาประเทศไทย)</br>      ทั้งนี้เฉพาะกรณีที่ DW มีสถานะเป็น ITM เท่านั้น",
    lmatrixTbl3ParaEn: "“**- Only the case that the Stock Exchange of Thailand and the Stock Exchange of the underlying securities (Foreign single stock DW) or the futures exchange of the underlying index (Foreign index DW) open at the same time during the Market Making Period specified in the table. Or in the event that the underlying stock or the futures of the underlying index is stopped trading, the market makers will not be able to perform liquidity duties on the underlying during that specified period of time.</br>- Marketing Making on the last trading day of DW28 whose underlying is ;</br>     a. HSI/HSTECH/HSCEI index, the market makers will provide bid price according to the price matrix until 14:59 P.M. (Local Thai time).</br>      b. SET50 index, the market makers will maintain bid price according to the price matrix until 16:29 P.M. (Local Thai time).</br>This is only the case when DW is ITM (In The Money).”",
    
    lmatrixTblHsi2li: 'ราคาของ DW ที่ซื้อขายในตลาดหลักทรัพย์แห่งประเทศไทย อาจไม่สัมพันธ์กับราคา DW ดังกล่าว อันเนื่องมาจากปัจจัยอื่นๆ เช่น 1) สภาพคล่องความผันผวนของราคา DW และราคาดัชนีอ้างอิง รวมถึงภาวะตลาดที่เกี่ยวข้อง 2) การซื้อขายของนักลงทุนตามอุปสงค์อุปทาน (demand-supply) ในตลาด 3) ความล่าช้าในการแสดงราคา และ 4) สิทธิประโยชน์ต่างๆของหุ้นอ้างอิง และปัจจัยอื่นๆ ที่อยู่นอกเหนือการควบคุมของผู้ออกใบสำคัญแสดงสิทธิอนุพันธ์ ผู้ดูแลสภาพคล่อง ตลอดจนผู้เกี่ยวข้อง หรือ เหตุการณ์ที่ไม่อาจคาดหมายได้ นักลงทุนควรศึกษาข้อมูลเกี่ยวข้องอย่างระมัดระวัง และครบถ้วนก่อนการลงทุน',
    lmatrixTblHsi2liEn: 'The price of the DW that is traded in the SET may not be correlated to the mentioned DW price due to the other factors such as 1) the liquidity and the volatility of the DW price and the underlying index level as well as the relevant market conditions 2) the buying and the selling that is driven by the supply and the demand in the market 3) the delay in displaying the price 4) the various rights of the underlying and other factors that are beyond the control of the DW issuer, the market maker, the relevant parties or the unexpected situations. Investors should study the relevant information carefully and thoroughly before making investment decisions.',
    lmatrixTblHsi3li: 'ราคา DW เป็นข้อมูลทั่วไปสำหรับการใช้ประโยชน์ส่วนบุคคลเท่านั้น และมิได้เป็นการบอกให้ซื้อหรือขาย หรือเป็นการชักชวน การผูกพัน ไม่รับรองต่อความครบถ้วนและถูกต้องของข้อมูลดังกล่าว หรือต่อการขาดทุนหรือการสูญเสียใดๆที่อาจเกิดขึ้นจากการใช้ข้อมูลใดๆ ซึ่งหมายรวมแต่ไม่จำกัดถึงการสูญเสียเงินลงทุน หรือการสูญเสียผลกำไร ไม่ว่าทางตรงหรือทางอ้อม และไม่ว่าทั้งหมดหรือบางส่วน',
    lmatrixTblHsi3liEn: 'The DW price is provided for your personal use and intended for information purpose only and does not constitute an offer, a solicitation, an advice, a recommendation or an indication to buy or sell the underlying securities or DW. Macquarie shall not be liable for the accuracy or completeness of any information or for any loss or damage however caused arising in connection with any information, including without limitation any direct, indirect, special or consequential loss and loss of profits whether in part or in full.',
    lmatrixTblHsi1Para: "บริษัทหลักทรัพย์ แมคควอรี (ประเทศไทย) จำกัด (Macquarie Securities (Thailand) Limited : “บริษัทฯ”) มิได้ให้บริการรับฝากเงินและ/หรือดำเนินธุรกิจการธนาคารในประเทศไทยแต่อย่างใด และความรับผิดชอบต่างๆของบริษัทฯมิได้แสดงถึงการบริการรับฝากเงิน/กู้เงิน ที่สัมพันธ์กับ Macquarie Bank Limited (เลขทะเบียนบริษัท ABN 46 008 583 542) และ Macquarie Bank Limited มิได้ให้การรับประกันหรือรับรองความรับผิดชอบของบริษัทฯแต่อย่างใด",
    lmatrixTblHsi1ParaEn: "Macquarie Securities (Thailand) Limited ('MACQ') is the issuer of the DW. References to Macquarie include MACQ and Macquarie Bank Limited (ABN 46 008 583 542) (“MBL”). MACQ is not an authorised deposit taking institution for the purposes of the Banking Act 1959 (Commonwealth of Australia), and MACQ's obligations do not represent deposits or other liabilities of MBL. MBL does not guarantee or otherwise provide assurance in respect of the obligations of MACQ.",
    lmatrixTblHsi2Para: "*ราคาดัชนีหลักทรัพย์อ้างอิงที่แสดงไว้ในเว็บไซต์ เป็นราคาของ  HSI Futures เนื่องจาก HSI DW ที่ออกโดยแมคควอรี มีการชำระราคา ณ วันครบกำหนดอายุ โดยใช้ Final Settlement Price ของ HSI Futures เป็นดัชนีที่ใช้ชำระราคา",
    lmatrixTblHsi2ParaEn: "*The underlying price shown on the website is the HSI Futures price. All Macquarie HSI DW are settled at expiry using the Final Settlement Price of HSI Futures.",

    lmatrixTblHscei2Para: "*ราคาดัชนีหลักทรัพย์อ้างอิงที่แสดงไว้ในเว็บไซต์ เป็นราคาของ  HSCEI Futures เนื่องจาก HSCEI DW ที่ออกโดยแมคควอรี มีการชำระราคา ณ วันครบกำหนดอายุ โดยใช้ Final Settlement Price ของ HSCEI Futures เป็นดัชนีที่ใช้ชำระราคา",
    lmatrixTblHscei2ParaEn: "*The underlying price shown on the website is the HSCEI Futures price. All Macquarie HSCEI DW are settled at expiry using the Final Settlement Price of HSCEI Futures.",

    lmatrixTblHstech2Para: "*ราคาดัชนีหลักทรัพย์อ้างอิงที่แสดงไว้ในเว็บไซต์ เป็นราคาของ  HSTECH Futures เนื่องจาก HSTECH DW ที่ออกโดยแมคควอรี มีการชำระราคา ณ วันครบกำหนดอายุ โดยใช้ Final Settlement Price ของ HSTECH เป็นดัชนีที่ใช้ชำระราคา",
    lmatrixTblHstech2ParaEn: "*The underlying price shown on the website is the HSTECH Futures price. All Macquarie HSTECH DW are settled at expiry using the Final Settlement Price of HSTECH Futures.",

    lmatrixTblSp2Para: "*ราคาดัชนีหลักทรัพย์อ้างอิงที่แสดงไว้ในเว็บไซต์เป็นราคาของ SPX Futures เนื่องจาก SPX DW ที่ออกโดยแมคควอรี มีการชำระราคา ณ วันครบกำหนดอายุ โดยใช้ final settlement price ของ SPX Futures เป็นดัชนีที่ใช้ชำระราคา",
    lmatrixTblSp2ParaEn: "*The underlying price shown on the website is SPX Futures price. All Macquarie SPX DW are settled at expiry using the final settlement price of SPX Futures.",

    lmatrixTblDji2Para: "*ราคาดัชนีหลักทรัพย์อ้างอิงที่แสดงไว้ในเว็บไซต์เป็นราคาของ DJI Futures เนื่องจาก DJI DW ที่ออกโดยแมคควอรี มีการชำระราคา ณ วันครบกำหนดอายุ โดยใช้ Final Settlement Price ของ DJI Futures เป็นดัชนีที่ใช้ชำระราคา",
    lmatrixTblDji2ParaEn: "*The underlying price shown on the website is DJI Futures price. All Macquarie DJI DW are settled at expiry using the final settlement price of DJI Futures.",

    lmatrixTblNdx2Para: "*ราคาดัชนีหลักทรัพย์อ้างอิงที่แสดงไว้ในเว็บไซต์เป็นราคาของ NDX Futures เนื่องจาก NDX DW ที่ออกโดยแมคควอรี มีการชำระราคา ณ วันครบกำหนดอายุ โดยใช้ Final Settlement Price ของ NDX Futures เป็นดัชนีที่ใช้ชำระราคา",
    lmatrixTblNdx2ParaEn: "*The underlying price shown on the website is NDX Futures price. All Macquarie NDX DW are settled at expiry using the final settlement price of NDX Futures.",

    disclaimerHeading: "Definition & disclaimer",
    disclaimerHeadingEn: "Definition & disclaimer",

    marketDelay: "ข้อมูลตลาดทั้งหมดล่าช้าประมาณ 15 นาที",
    marketDelayEn: "All market data is delayed 15 mins.",

    relatedDw: "DW ที่เกี่ยวข้อง",
    relatedDwEn: "Related DW",

    lastView: "ดูล่าสุด",
    lastViewEn: "Last View",

    indexFuture: "สัญญาซื้อขายล่วงหน้า",
    indexFutureEn: "Live index futures",
    indexFutureTableMarket: "ตลาด",
    indexFutureTableMarketEn: "Index",
    indexFutureTableContract: "สัญญา",
    indexFutureTableContractEn: "Contract",
    indexFutureTableLast: "ราคาล่าสุด",
    indexFutureTableLastEn: "Last",
    indexFutureTableChange: "เปลี่ยนแปลง",
    indexFutureTableChangeEn: "Change",
    indexFutureTableChangePercent: "เปลี่ยนแปลง %",
    indexFutureTableChangePercentEn: "Change%",

    indexPriceUpdateInfo: "*กดปุ่ม เพื่ออัพเดท ราคา",
    indexPriceUpdateInfoEn: "* Click refresh button for live quote",
    indexPriceUpdateInfoPrice: "ณ",
    indexPriceUpdateInfoPriceEn: "Price as at",

    dbPowerTooltip: 'DB Power Online Limited, “HKEX Information Services Limited, its holding companies and/or any subsidiaries of such holding companies”, China Investment Information Services Limited, third party information providers endeavor to ensure the accuracy and reliability of the information provided but do not guarantee its accuracy or reliability and accept no liability (whether in tort or contract or otherwise) for any loss or damage arising from any inaccuracies or omissions.<br><br>The BMP Service is aimed for investor reference. The BMP only provides basic market information for reference purposes and the investors should consider if they would need more detailed market information for reference or to facilitate their investment decision.<br><img style="width: 160px" src="/img/home/dbpower.png">',
    dbPowerTooltipEn: 'DB Power Online Limited, “HKEX Information Services Limited, its holding companies and/or any subsidiaries of such holding companies”, China Investment Information Services Limited, third party information providers endeavor to ensure the accuracy and reliability of the information provided but do not guarantee its accuracy or reliability and accept no liability (whether in tort or contract or otherwise) for any loss or damage arising from any inaccuracies or omissions.<br><br>The BMP Service is aimed for investor reference. The BMP only provides basic market information for reference purposes and the investors should consider if they would need more detailed market information for reference or to facilitate their investment decision.<br><img style="width: 160px" src="/img/home/dbpower.png">',

    hsiIndexMarket: "วิเคราะห์ตลาดฮั่งเส็ง",
    hsiIndexMarketEn: "HSI index market news",

    livematrixTodayEmpty: "ไม่สามารถแสดงตารางราคาได้ เนื่องจาก DW ไม่มีมูลค่า ณ ระดับราคาหลักทรัพย์อ้างอิงปัจจุบันนี้",
    livematrixTodayEmptyEn: "There is no matrix available for this DW, as the DW has no value at current levels in the underlying",
    livematrixTodayUnavailable: "ในกรณีที่ DW มีมูลค่า 0.01 บาทหรือต่ำกว่า ตารางราคาวันนี้จะไม่แสดงค่า",
    livematrixTodayUnavailableEn: "Today’s Live Price Matrix will not be available if DW is equal or lower than THB 0.01",
    livematrixFivedayEmpty: "ไม่สามารถแสดงตารางราคาได้ เนื่องจาก DW ไม่มีมูลค่า ณ ระดับราคาหลักทรัพย์อ้างอิงปัจจุบันนี้",
    livematrixFivedayEmptyEn: "There is no matrix available for this DW, as the DW has no value at current levels in the underlying",
    livematrixFivedayUnavailable: "ในกรณีที่ DW มีมูลค่า 0.01 บาทหรือต่ำกว่า ตารางราคา 5 วันทำการจะไม่แสดงค่า",
    livematrixFivedayUnavailableEn: "5-days price matrix will not be available if DW is equal or lower than THB0.01",
    livematrixTableLivematrixNoneMq: "ตารางราคาจะแสดงค่า เฉพาะ DW ที่ออก โดย Macquarie เท่านั้น",
    livematrixTableLivematrixNoneMqEn: "The Live matrix is only available for DW issued by Macquarie",
    livematrixTodayNewUnavailable:'DW เข้าใหม่วันนี้ จะแสดงตารางราคาตอน 9:00น.เป็นต้นไป',
    livematrixTodayNewUnavailableEn:'This is a new warrant, the live matrix will be displayed after 9:00 am',


    liveMatrixUnderlyingHead2Th:"ช่วงเวลาทำการของผู้ดูแลสภาพคล่องตามเวลาในประเทศไทย**",
    liveMatrixUnderlyingHead2En:"Market Making Period (Local Thai time)**",
}