export const languageText ={
    
    firstNavigation: "อัพเดทตลาดหุ้น ",
    firstNavigationEn: "Market news ",
    secondNavigation: " กิจกรรม ",
    secondNavigationEn: " Activities ",

    pageTitle: "กิจกรรม",
    pageTitleEn: "Activities",
    pageDescription: "ติดตามการเคลื่อนไหวและกิจกรรมของแมคควอรี",
    pageDescriptionEn: "The latest events and activities from Macquarie.",

    actViewMoreBtn: "เพิ่มเติม คลิก",
    actViewMoreBtnEn: "View more",

    marketDelay: "ข้อมูลตลาดทั้งหมดล่าช้าประมาณ 15 นาที",
    marketDelayEn: "All market data is delayed 15 mins.",

    hsiFight: "เทรด HSI DW ลุ้นรับฟรี! บุฟเฟ่ต์ติ่มซำ!",
    hsiFightEn: "เทรด HSI DW ลุ้นรับฟรี! บุฟเฟ่ต์ติ่มซำ!",

    hsiDimSum: "เทรด HSI DW ลุ้นรับฟรี! บุฟเฟ่ต์ติ่มซำ!",
    hsiDimSumEn: "เทรด HSI DW ลุ้นรับฟรี! บุฟเฟ่ต์ติ่มซำ!",

    freeVoucher: "เทรดง่าย ได้เลย!",
    freeVoucherEn: "เทรดง่าย ได้เลย!",

    tradeSeason4: "SuperTrader Season 4",
    tradeSeason4En: "SuperTrader Season 4",

    set50Trade: "Ready - SET(50) - Trade",
    set50TradeEn: "Ready - SET(50) - Trade",

    tradeSeason3: "SuperTrader Season 3",
    tradeSeason3En: "SuperTrader Season 3",
}