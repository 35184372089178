import React, { useState, useEffect,Fragment } from 'react';
import LowerCapitalOutlays from './LowerCapitalOutlays';
import "../../common.css";
import "../../investorAcademy.css";


const LowerCapitalOutlay = (props) => {
    return ( 
        <Fragment> 
        <LowerCapitalOutlays/>
        </Fragment> 
     );
}


export default LowerCapitalOutlay;