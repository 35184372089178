import React  from 'react';
import DwVideoTestimonial from './DwVideoTestimonial';
import './dwVideoTestimonial.css';


const DwVideoTestimonials = (props) => {
    return ( 
        <DwVideoTestimonial/>
     );
}
 
export default DwVideoTestimonials;