import React, { useState, useEffect } from 'react';
import {Link} from "react-router-dom"
import API from '../../../utils/apiCalling';
import "./toptradedstock.css";
import { config } from '../../../utils/apiUrl';
import moment from 'moment';
import thLocale from 'moment/locale/th'
import enLocale from "moment/locale/en-in"
import { useContext } from 'react';
import LangContext from '../../../components/common/context/lang-context';
import { languageText } from '../../../languageText/topTradedStock';


const Toptradedstock = (props) => {
    const langContext = useContext(LangContext);
    const api = new API();
    const [tradedStock, setTradedStock] = useState([]);
    const [activeBtn1, setActiveBtn1] = useState("active")
    const [activeBtn2, setActiveBtn2] = useState("")
    const [language, setLanguage] = useState('thai');
    const [siteLang , setSiteLang] = useState("")

    const getTradedStock = async () => { 
        let fastTrack = await api.get(config.marketTurnOver, true, {type: "toptradedstock", qid: Date.now() })
        setTradedStock(fastTrack.topList)
    };

    const activeBtnF1 = () => {
      setActiveBtn1("active")
      setActiveBtn2("")
    }

    const activeBtnF2 = () => {
      setActiveBtn1("")
      setActiveBtn2("active")
    }

    useEffect(() => {
      window.scrollTo(0, 0)
      var siteLanguage = $.cookie('applang');
      setSiteLang(siteLanguage)
      if(siteLanguage == "eng"){
        document.querySelector("body").classList.add("eng")
      }else{
        document.querySelector("body").classList.remove("eng")
      }
    }, [])

    useEffect(() => {
      getTradedStock();
    }, [])

    return (
         
      <div id="toptradedstock" className="pageobj">
        <div className="breadcrumbbar">
          <div className="container-fluid">
            <ol className="breadcrumb">
              <li>
                <Link to="/home?dwstat">{siteLang == "eng" ? languageText.firstNavigationEn : languageText.firstNavigation}</Link>
              </li> 
              <li className="active">{siteLang == "eng" ? languageText.secondNavigationEn : languageText.secondNavigation}</li>
            </ol>
          </div>
        </div>
        <div className="stripe-bg" />
        <div className="container-fluid">
          <div className="section">
            <div className="page-header">
              <h1>{siteLang == "eng" ? languageText.pageTitleEn : languageText.pageTitle}</h1>
            </div>
            <p className="section-txt">
              {siteLang == "eng" ? languageText.pageDescriptionEn : languageText.pageDescription}
            </p>
            <div className="tablist">
              <div id="topTabs" className="tablist-left">
                <table>
                  <tbody>
                    <tr>
                      <td className={activeBtn1}>
                        <div className="tablist-item" onClick={activeBtnF1}>
                          <div className="tablist-item-txt">{siteLang == "eng" ? languageText.callTabEn : languageText.callTab}</div>
                        </div>
                      </td>
                      <td className={activeBtn2}>
                        <div className="tablist-item"  onClick={activeBtnF2}>
                          <div className="tablist-item-txt">{siteLang == "eng" ? languageText.putTabEn : languageText.putTab}</div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div id="topTbl" className={`${activeBtn1 == "active" ? "calldw" : "putdw"}`}>
              <table className="table-striped table-responsive">
                <thead>
                  <tr className='hidden-xs'>
                    <th className="bgcolor-04" colSpan={4}>Underlying</th>
                    <th className="col_call bgcolor-06" colSpan={4}>Call DW</th>
                    <th className="col_put bgcolor-06" colSpan={4}>Put DW</th>
                  </tr>
                  <tr className='visible-xs'>
                    <th className="bgcolor-04" colSpan={3}>Underlying</th>
                    <th className="col_call bgcolor-06" colSpan={3}>Call DW</th>
                    <th className="col_put bgcolor-06" colSpan={3}>Put DW</th>
                  </tr>
                  <tr>
                    <th className="bgcolor-04">Underlying</th>
                    <th className="bgcolor-04">Turnover <br className="visible-xs" /><span className="visible-xs">(mil)</span></th>
                    <th className="bgcolor-04 hidden-xs">Last price</th>
                    <th className="bgcolor-04">Change<br className="visible-xs" />(%)</th>
                    <th className="col_call bgcolor-06">Symbol</th>
                    <th className="col_call bgcolor-06">Bid<br className="visible-xs" />(THB)</th>
                    <th className="col_call hidden-xs bgcolor-06">Eff. gearing(x)</th>
                    <th className="col_call bgcolor-06">Sensitivity</th>
                    <th className="col_put bgcolor-06">Symbol</th>
                    <th className="col_put bgcolor-06">Bid<br className="visible-xs" />(THB)</th>
                    <th className="col_put hidden-xs bgcolor-06">Eff. gearing(x)</th>
                    <th className="col_put bgcolor-06">Sensitivity</th>
                  </tr>
                </thead>
                <tbody>
                  {tradedStock && tradedStock.map((data, index) => (
                    <tr className={`"rowtmpl" ${!data.dw_ticker_p ? "hide-putdw" : !data.dw_ticker_c ? "hide-calldw" : ""}`} key={index}>
                      <td className="col_underlying_ticker">{data.underlying_ticker}</td>
                      <td className="col_long col_undelrying_turnover hidden-xs">{data.undelrying_turnover}</td>
                      <td className="col_long col_undelrying_turnover visible-xs">{((Number(data.undelrying_turnover.replaceAll(",", ""))/1000000).toFixed(2)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</td>
                      <td className="hidden-xs col_TRDPRC_1">{data.TRDPRC_1}</td>
                      <td className={`right-b01 col_PCTCHNG ${data.PCTCHNG > 0 ? "up" : "down"}`}>{data.PCTCHNG}</td>
                      <td className="col_call col_dw_ticker" style={{fontWeight: "bold"}}><Link to={`/tools/livematrix/${data.dw_ticker_c}`}>{data.dw_ticker_c}</Link></td>
                      <td className="col_call col_warrant_price">{data.warrant_price_c}</td>
                      <td className="col_call hidden-xs col_eff_gearing">{data.eff_gearing_c}</td>
                      <td className="col_call col_sensitivity">{data.sensitivity_c}</td>
                      <td className="col_put col_dw_ticker"><Link to={`/tools/livematrix/${data.dw_ticker_p}`}>{data.dw_ticker_p}</Link></td>
                      <td className="col_put col_warrant_price">{data.warrant_price_p}</td>
                      <td className="col_put hidden-xs col_eff_gearing">{data.eff_gearing_p}</td>
                      <td className="col_put col_sensitivity">{data.sensitivity_p}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
            <div className="lastupdatebox">
              Last update : <span>{siteLang == "eng" ? moment(new Date()).locale('en', [enLocale]).format('DD MMM YY') : moment(new Date()).locale('th', [thLocale]).format('DD MMM YY')}</span>
            </div>
            <p className="small">
              {siteLang == "eng" ? languageText.aboveTableEn : languageText.aboveTable}
              <br /><br />
              {siteLang == "eng" ? languageText.marketDelayEn : languageText.marketDelay}
            </p>
          </div>
        </div>
      </div>

      );
}

export default Toptradedstock;