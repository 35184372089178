import React  from 'react';
import DwVideoIndPage from './DwVideoInd';
import './dwvideoInd.css';


const DwVideoIndPages = (props) => {
    return ( 
        <DwVideoIndPage/>
     );
}
 
export default DwVideoIndPages;