export const languageText ={
    
    firstNavigation: "อัพเดทตลาดหุ้น ",
    firstNavigationEn: "Market news ",
    secondNavigation: " ภาพกิจกรรม",
    secondNavigationEn: " Photo gallery",

    pageTitle: "ภาพกิจกรรม",
    pageTitleEn: "Photo gallery",
    pageDescription: "ชมภาพบรรยากาศกิจกรรมของแมคควอรี",
    pageDescriptionEn: "Visit our gallery to see all of the happenings at our recent events",

    viewMoreBtn: "เพิ่มเติม คลิก",
    viewMoreBtnEn: "View more",

    marketDelay: "ข้อมูลตลาดทั้งหมดล่าช้าประมาณ 15 นาที",
    marketDelayEn: "All market data is delayed 15 mins.",
}