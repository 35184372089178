import React, { useEffect, useState } from "react";
import "./about.css"

const TermUse = () => {

    const [siteLang , setSiteLang] = useState("")

    useEffect(() => {
        window.scrollTo(0, 0);
        var siteLanguage = $.cookie('applang');
        setSiteLang(siteLanguage)
        if(siteLanguage == "eng"){
          document.querySelector("body").classList.add("eng")
        }else{
          document.querySelector("body").classList.remove("eng")
        }
    }, [])

    return (
        <div id="termsofuse" className="pageobj">
            <div className="breadcrumbbar">
                <div className="container-fluid">
                    <ol className="breadcrumb">
                        <li><a>{siteLang == "eng" ? "Macquarie " : "Macquarie "}</a></li>
                        <li className="active">{siteLang == "eng" ? " Terms of use" : " ข้อกำหนดการใช้งาน"}</li>
                    </ol>
                </div>
            </div>
            <div className="stripe-bg" />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className="section">
                            <div className="page-header">
                                <h1>{siteLang == "eng" ? "Terms of use" : "ข้อกำหนดการใช้งาน"}</h1>
                            </div>
                            <p>Any information or advice contained on this website is general in nature and has been prepared without taking into account your objectives, financial situation or needs. Before acting on any information or advice on this website, you should consider the appropriateness of it (and any relevant product) having regard to your circumstances and, if a current offer document is available, read the offer document before acquiring products named on this website. You should also seek independent financial, legal and taxation advice prior to acquiring any security or other financial, credit or lending product.</p>
                            <p>All applications for credit or lending products are subject to Macquarie Group’s standard credit approval criteria and/or product provider criteria.</p>
                            <p>All securities and financial product or instrument transactions involve risks. Past performance of any product described on this site is not a reliable indication of future performance. This website may contain forward-looking statements, forecasts, estimates and projections. No member of the Macquarie Group represents or warrants that such forward statements will be achieved or will prove to be correct. Actual future results and operations could vary materially from them.</p>
                            <p>Macquarie Group makes no representation or warranty as to the accuracy, completeness, timeliness or reliability of the contents of this website, and other than as required under consumer protection law, Macquarie Group accepts no responsibility for errors or misstatements, negligent or otherwise and under no circumstances will Macquarie Group be liable for any loss or damage whatsoever caused by a user's reliance on information obtained from this website, including from use of tools and calculators.</p>
                            <p>Macquarie Group accepts no obligation to correct or update the information or opinions on this website. The information on this website may be based on assumptions or market conditions and may change without notice.</p>
                            <p>Macquarie Group specifically disclaims any liability (whether based in contract, tort, strict liability or otherwise) for any direct, indirect, incidental or consequential damages arising out of or in any way connected with the access to or use of this website.</p>
                            <p>The Macquarie Group may have interests in the securities and other financial, credit or lending products referred to in this website, including being directors of, or may have or may in the future act in various roles including as underwriter, dealer, broker, lender or financial advisor to their issuers and may receive fees, brokerage or commission for acting in those capacities. Further, the Macquarie Group may act as a market maker or buy or sell those securities and other products as principal or agent.</p>
                            <div className="title text-bold">Jurisdictions</div>
                            <p>Note that many of the products and services offered by members of the Macquarie Group are country or jurisdiction specific. Accessing any product or service via this website is not an offer, inducement or invitation to purchase those products or services.</p>
                            <p>Macquarie Group only carry on banking activities in those countries it is authorised to do so. Macquarie Bank Limited (MBL) is authorised and regulated by the Australian Prudential Regulation Authority (APRA) in Australia to carry out banking business in Australia. MBL, acting through its Seoul Branch, is authorised and regulated by the Financial Services Commission in Korea to carry out banking business in Korea. MBL, acting through its Singapore Branch, is authorised and regulated by the Monetary Authority of Singapore to carry out banking business in Singapore. MBL, acting through its Hong Kong branch, is authorised and regulated by the Hong Kong Monetary Authority to carry on banking business in Hong Kong. MBL London Branch is authorised and regulated by the Financial Conduct Authority and limited regulation by the Prudential Regulation Authority. Details about the extent of our regulation by the Prudential Regulation Authority are available from us on request. Macquarie Bank International Limited (MBIL) is authorised by the Prudential Regulation Authority and regulated by the Financial Conduct Authority and the Prudential Regulation Authority. Both MBL London Branch and MBIL are authorised to carry out banking business in the United Kingdom. No member of the Macquarie Group of companies is registered as a bank in New Zealand under the Reserve Bank of New Zealand Act 1989.</p>
                            <p>Macquarie Bank Limited maintains Representative Offices in the states of Illinois, New York and Texas, but is not authorised to conduct business in the US.</p>
                            <p>Delaware Investments, a member of Macquarie Group, refers to Delaware Management Holdings, Inc. and its subsidiaries. Investment advisory services provided by Delaware Investment Advisers, a series of Delaware Management Business Trust (DMBT). DMBT is registered as an investment adviser with the United States Securities and Exchange Commission.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
};
export default TermUse;