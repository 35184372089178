import React, { useState, useEffect } from 'react'
import "./news.css";
import { Link } from 'react-router-dom';
import '../../../assets/css/swiper.css';
import DatePicker from 'react-date-picker';
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import moment from "moment"
import AdvertiseMentSidebar from "../Sidebar/AdvertiseMentSidebar"
import { languageText } from '../../../languageText/marketNewsCommon';

const banners = [
    "",
    "/img/bg/top-dailysinglestock.png",
    "/img/bg/top-todayhighlight.jpg",
    "/img/bg/top-marketwrap.jpg",
    "/img/bg/top-commentary.jpg",
    "/img/bg/top-dailySP.jpg",
    "/img/bg/top-dailyhsi.png",
]

export default function NewsComp(props) {

    const { param, data, section, breadcrumb, title, subtitle, dataIndex, setSelectDate, dataslider, newsFeeds } = props
    const [myNightMarketWrap, setmYNightMarketWrap] = useState([]);
    const [startDate, setStartDate] = useState(null)
    let currentData = myNightMarketWrap.length == 0 ? data : myNightMarketWrap
    const [siteLang , setSiteLang] = useState("")
    const settings = {
        autoplay: false,
        infinite: false,
        rows: 5,
        slidesToShow: 1,
        slidesToScroll: 1,
        dots: true,
    };

    useEffect(() => {
        window.scrollTo(0, 0)
        var siteLanguage = $.cookie('applang');
        setSiteLang(siteLanguage)
        if(siteLanguage == "eng"){
            document.querySelector("body").classList.add("eng")
        }else{
            document.querySelector("body").classList.remove("eng")
        }
    }, [])

    useEffect(() => {
        currentData = myNightMarketWrap.length == 0 ? data : myNightMarketWrap
        return () => {
        }
    }, [myNightMarketWrap])

    const dateHandler = (date) => {
        setSelectDate(moment(date).format('YYYY-MM-DD'))
        // var myCheck = data.filter((dateData) => {
        //     return new Date(dateData.date).getTime() <= new Date(date).getTime()
        // })
        // setmYNightMarketWrap(myCheck)
    }

    const extractContent = (s) => {
        var span = document.createElement("span");
        span.innerHTML = s;
        return span.textContent || span.innerText;
    }

    return (
        <div id="dailycommentary" className="pageobj">
            <div className="breadcrumbbar">
                <div className="container-fluid">
                    <ol className="breadcrumb">
                        <li>
                            <Link to="/home?dwnews">{section}</Link>
                        </li>
                        <li className="active">{breadcrumb}</li>
                    </ol>
                </div>
            </div>
            <div className="stripe-bg" />
            <div className="container-fluid">
                <div className="topimg hidden-sm hidden-xs">
                    <img src={banners[dataIndex]} />
                </div>
                <div className="row">
                    <div className="col-md-8">
                        <div id="newssection" style={{ position: "relative" }} className="section">
                            <div className="page-header">
                                <h1>{title}</h1>
                            </div>
                            {subtitle && <p className="section-p">
                                {subtitle}
                            </p>}
                            <div id="commentarytbl" className="newstbl">
                                <div className="newstbl-htxt">Latest News</div>
                                <div className="newstbl-content">
                                    <div id="newsswiper">
                                        <Slider {...settings} ref={dataslider}>
                                            {currentData && currentData.map((curValue, curIdx) =>
                                            (
                                                <div key={curIdx} className="newstbl-row rowtmpl">
                                                    <div className="newstbl-col-left">
                                                        <span className="newstbl-col-date">{curValue.date}</span>
                                                    </div>
                                                    <div className="newstbl-col-right">
                                                        <h3
                                                            className="newstbl-col-dt"
                                                            dangerouslySetInnerHTML={{ __html: curValue != undefined ? (siteLang == "eng" ? curValue.en_title : curValue.thai_title) : "" }} />
                                                        <p
                                                            className="newstbl-col-dd"
                                                            style={{ overflowWrap: "break-word" }}
                                                            dangerouslySetInnerHTML={{ __html: curValue != undefined ? (siteLang == "eng" ? curValue.en_short : curValue.thai_short) : "" }} />

                                                        <div className="newstbl-col-rm">
                                                            <Link
                                                                to={`/marketnews/highlight?cat=${param}&id=${curValue.id}`}
                                                                className="btn btn-primary btn-default swiper-no-swiping"
                                                                target="_blank"
                                                            >
                                                                Read more
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>
                                            )
                                            )
                                            }
                                        </Slider>
                                    </div>
                                </div>
                                <div className="newstbl-bttm">
                                    <div
                                        id="datepicker"
                                        className="newstbl-cal-icon input-group date"
                                    >
                                        <input type="hidden" className="form-control" />
                                        <div className="input-group-addon">
                                            <label>
                                                <DatePicker
                                                    selected={startDate}
                                                    onChange={(date) => dateHandler(date)}
                                                    maxDate={new Date()}
                                                    minDate={new Date(Date.parse(`01/01/${(moment(new Date()).year() - 2)}`))}
                                                    value={startDate}
                                                    style={{width:"100px", border: "5px"}}
                                                />
                                                <img src="/img/bullet/icon-cal.png" alt='Calendar Icon' className='cal-icon' />
                                            </label>
                                        </div>
                                    </div>
                                    {/* <ActivePagination currentPage={currentPage} onPageChange={(selectedIndex) => setCurrentPage(selectedIndex)} dataLength={NUM_OF_RECORDS} limit={LIMIT}/> */}
                                </div>
                            </div>
                            <hr className="hr-hmgbm" />
                            <p className="small">{siteLang == "eng" ? languageText.marketDelayEn : languageText.marketDelay}</p>
                        </div>
                    </div>
                    <div className="hidden-xs hidden-sm col-md-4">
                        {newsFeeds.map((item, index) => {
                            if (index !== dataIndex)
                                return (
                                    <div className="section qlink-section" key={index}>
                                        <div className="page-header">
                                            <h2 className="small">{item.name}</h2>
                                        </div>
                                        <p>{item.short}</p>
                                        <div className={`section-qlink-img ${item.imgClass}`}>
                                            <Link
                                                to={item.link}
                                                className="section-qlink-achor"
                                            >
                                                <img src="/img/bullet/qlink-achor.png" />
                                            </Link>
                                        </div>
                                    </div>
                                )
                            }
                        )}
                        <AdvertiseMentSidebar />
                    </div>
                </div>
            </div>
        </div>
    )
}
