const tabName = {
    warrantTools: "Warrant tools",
    warrantStatistics: "Warrant statistics",
    warrantEducation: "Warrant education",
    marketNews: "Market news",
}

const encodeQuery = (data) => {
    let query = ""
    for (let d in data)
        query += encodeURIComponent(d) + '=' +
            encodeURIComponent(data[d]) + '&'
    return query.slice(0, -1)

}

export { tabName, encodeQuery }