export const languageText ={
    
    firstNavigation: "เครื่องมือ DW ",
    firstNavigationEn: "DW tools ",
    secondNavigation: " เปรียบเทียบ DW ",
    secondNavigationEn: " DW comparison ",

    pageTitle: "เปรียบเทียบ DW",
    pageTitleEn: "DW comparison",
    pageDescription: "เปรียบเทียบ DW หลายๆ รุ่น และปัจจัยต่างๆ เพื่อใช้ประกอบในการตัดสินใจ",
    pageDescriptionEn: "Compare DWs and their key factors before you make your decision.",
    pageDescription1: "เปรียบเทียบได้สูงสุดถึง 5 รุ่น DW",
    pageDescription1En: "You may compare up to five DW at time",

    selectDw: "เลือกชื่อย่อ DW",
    selectDwEn: "Select DW",

    outerTableThSymbol: "ชื่อย่อ",
    outerTableThSymbolEn: "Symbol",
    outerTableThExercisePrice: "ราคาใช้สิทธิ",
    outerTableThExercisePriceEn: "Exercise price",
    outerTableThEffectiveGearing: "Effective gearing",
    outerTableThEffectiveGearingEn: "Effective gearing",
    outerTableThSensitivity: "Sensitivity",
    outerTableThSensitivityEn: "Sensitivity",

    importantNote: "*ราคาดัชนีหลักทรัพย์อ้างอิงที่แสดงไว้ในเว็บไซต์ เป็นราคาของ Index Futures เนื่องจาก DW อ้างอิงดัชนี ที่ออกโดยแมคควอรี มีการชำระราคา ณ วันครบกำหนดอายุ โดยใช้ Final Settlement Price ของ Index Futures เป็นดัชนีที่ใช้ชำระราคา",
    importantNoteEn: "*The underlying price shown on the website is the Index Futures price. All Macquarie Index DWs are settled at expiry using the Final Settlement Price of index Futures.",

    marketDelay: "ข้อมูลตลาดทั้งหมดล่าช้าประมาณ 15 นาที",
    marketDelayEn: "All market data is delayed 15 mins.",

    symbolTitle: 'ชื่อย่อ',
    symbolTitleEn: 'Symbol',
    symbolTooltip: '<b>ชื่อย่อ:</b>DWแต่ละรุ่นจะมีชื่อเฉพาะ <a target="_blank" href="/eduhtml/howarewarrantsnamed">คลิกที่นี่</a> เพื่ออ่านคำอธิบายเพิ่มเติม',
    symbolTooltipEn: '<b>Symbol:</b>Each DW has a unique code to identify it, <a target="_blank" href="/eduhtml/howarewarrantsnamed"> click here</a> for a more detailed explanation.',

    issuerTitle: 'ผู้ออก',
    issuerTitleEn: 'Issuer',
    issuerTooltip: '<b>ผู้ออก:</b>คือธนาคารหรือบริษัทหลักทรัพย์ที่เป็นผู้ออกและเสนอขาย DW',
    issuerTooltipEn: '<b>Issuer:</b>The Bank or Securities company that issues the DW.',

    typeTitle: 'ประเภท',
    typeTitleEn: 'Type',
    typeTooltip: '<b>ประเภท:</b>Calls จะเพิ่มมูลค่าต่อเมื่อหุ้นอ้างอิงปรับตัวขึ้น ในขณะที่ Puts จะเพิ่มมูลค่าต่อเมื่อหุ้นอ้างอิงปรับตัวลง',
    typeTooltipEn: '<b>Type:</b>Calls increase in value as share price rises, Puts increase in value as share price falls.',

    exercisePriceTitle: 'ราคาใช้สิทธิ',
    exercisePriceTitleEn: 'Exercise price',
    exercisePriceTooltip: '<b>ราคาใช้สิทธิ:</b>ราคาที่คุณสามารถซื้อ (Call) หรือขาย (Put) หุ้นอ้างอิงหรือดัชนีอ้างอิงได้เมื่อครบกำหนดอายุ',
    exercisePriceTooltipEn: '<b>Exercise price:</b>The price at which you can buy (calls) or sell (puts) the underlying<br> share or index at expiry.',

    lastTradingDateTitle: 'วันซื้อขายสุดท้าย',
    lastTradingDateTitleEn: 'Last trading date',
    lastTradingDateTooltip: '<b>วันซื้อขายสุดท้าย:</b>วันสุดท้ายที่ DW มีการซื้อขายในตลาดหลักทรัพย์ และเป็นวันคำนวณมูลค่า DW เมื่อครบกำหนดอายุ โดยทั่วไปคือวันทำการที่ 3 ก่อนวันครบกำหนดอายุ',
    lastTradingDateTooltipEn: '<b>Last trading date:</b>The day that the DW stops trading on the SET and the expiry value is calculated, always 3 business days prior to the expiry date.',

    ratioTitle: 'Ratio',
    ratioTitleEn: 'Ratio',
    ratioTooltip: '<b>Ratio:</b>อัตราการใช้สิทธิ (สำหรับ DW บนหุ้น) หรือ ตัวคูณดัชนี (สำหรับ DW บนดัชนีหลักทรัพย์)',
    ratioTooltipEn: '<b>Ratio:</b>The conversion ratio (for stock DW) or the index multiplier (for index DW)',

    lastTitle: 'ราคาล่าสุด',
    lastTitleEn: 'Last',
    lastTooltip: '<b>ราคาล่าสุด:</b>The last executed trade price for the DW. Be careful when refering to this as a reference price as DW do not trade as frequently as shares, this trade may have occurred some time ago.',
    lastTooltipEn: '<b>Last:</b>The last executed trade price for the DW. Be careful when refering to this as a reference price as DW do not trade as frequently as shares, this trade may have occurred some time ago.',

    bidTitle: 'ราคาเสนอซื้อ',
    bidTitleEn: 'Bid',
    bidTooltip: '<b>ราคาเสนอซื้อ:</b>The highest current buy price of the DW. This is the value that most accurately reflects the "value" of the DW at any point in time.',
    bidTooltipEn: '<b>Bid:</b>The highest current buy price of the DW. This is the value that most accurately reflects the "value" of the DW at any point in time.',

    askTitle: 'ราคาเสนอขาย',
    askTitleEn: 'Ask',
    askTooltip: '<b>ราคาเสนอขาย:</b>The lowest current sell price for the DW.',
    askTooltipEn: '<b>Ask:</b>The lowest current sell price for the DW.',

    priorTitle: 'ก่อนหน้า',
    priorTitleEn: 'Prior',
    priorTooltip: '<b>ก่อนหน้า:</b>ราคาเสนอซื้อที่ดีที่สุด (Best Bid Price) ณ สิ้นวันทำการก่อนหน้า',
    priorTooltipEn: '<b>Prior:</b>Best bid price at close of previous days trading.',

    changeTitle: 'เปลี่ยนแปลง (THB)',
    changeTitleEn: 'Change (THB)',
    changeTitleMob: 'เปลี่ยนแปลง',
    changeTitleMobEn: 'Change',
    changeTooltip: '<b>เปลี่ยนแปลง:</b>Current bid price for the DW compared to the Prior.',
    changeTooltipEn: '<b>Change:</b>Current bid price for the DW compared to the Prior.',

    moneynessTitle: 'สถานะ',
    moneynessTitleEn: 'Moneyness',
    moneynessTooltip: '<b>สถานะ:</b>ITM (In-the-money) OTM (Out-of-the-money) ATM (At-the-money) <a target="_blank" href="/eduhtml/moneyness">คลิกที่นี่</a> เพื่ออ่านรายละเอียดเพิ่มเติม',
    moneynessTooltipEn: '<b>Moneyness:</b>ITM (in-the-money), OTM (out-of-the-money), ATM (at-the-money). <a target="_blank" href="/eduhtml/moneyness">click here</a> to learn more about moneyness.',

    underlyingBidPriceTitle: 'ราคา bid ของหลักทรัพย์อ้างอิง',
    underlyingBidPriceTitleEn: 'Underlying bid price',
    underlyingBidPriceTooltip: '<b>ราคา bid ของหลักทรัพย์อ้างอิง:</b>ราคาซื้อขายล่าสุดของหุ้นอ้างอิง',
    underlyingBidPriceTooltipEn: '<b>Underlying bid price:</b>The last traded price for the underlying shares.',

    volumeTitle: 'ปริมาณการซื้อขาย',
    volumeTitleEn: 'Volume',
    volumeTooltip: '<b>ปริมาณการซื้อขาย:</b>ปริมาณการซื้อขายทั้งหมดของ DW ในวันนั้นๆ',
    volumeTooltipEn: '<b>Volume:</b>Total traded volume in the DW for the day.',

    effectiveGearingTitle: 'Effective gearing',
    effectiveGearingTitleEn: 'Effective gearing',
    effectiveGearingTooltip: '<b>Effective gearing:</b>ใช้บ่งชี้โอกาสในการรับผลตอบแทนจากการลงทุนใน DW ตัวอย่างเช่น Effective Gearing เท่ากับ 5 เท่า หมายความว่า ถ้าราคาหุ้นอ้างอิงเปลี่ยนแปลงไป 1% ราคา DW จะเปลี่ยนแปลงไปประมาณ 5% <a target="_blank" href="/eduhtml/effectivegearing">คลิกที่นี่</a> เพื่ออ่านรายละเอียดเพิ่มเติม',
    effectiveGearingTooltipEn: '<b>Effective gearing:</b>Indicates your leveraged exposure. An effective gearing of 5X tells you a 1% move in the underlying stock/index results in an approximate 5% move in your DW price. <a target="_blank" href="/eduhtml/effectivegearing">click here</a> to learn more about effective gearing.',

    sensitivityTitle: 'Sensitivity',
    sensitivityTitleEn: 'Sensitivity',
    sensitivityTooltip: '<b>Sensitivity:</b>แสดงให้เห็นว่าราคา DW จะเปลี่ยนแปลงไปกี่ช่วงราคา เมื่อราคาหุ้นอ้างอิงปรับตัวเพิ่มขึ้น 1 ช่วงราคา เช่น ค่า Sensitivity = 1.2 หมายถึง ราคาหุ้นอ้างอิงปรับตัวขึ้น 1 ช่วงราคา ราคาของ DW เปลี่ยนแปลงประมาณ 1.2 ช่วงราคา',
    sensitivityTooltipEn: '<b>Sensitivity:</b> Indicates the number of ticks in the DW moves for a 1 tick move in the underlying. Eg, Sensitivity = 1.2 means underlying stock move 1 tick, your DW will move around 1.2 ticks.',
    sensitivityTooltipMob: 'แสดงให้เห็นว่าราคา DW จะเปลี่ยนแปลงไปกี่ช่วงราคา เมื่อราคาหุ้นอ้างอิงปรับตัวเพิ่มขึ้น 1 ช่วงราคา เช่น ค่า Sensitivity = 1.2 หมายถึง ราคาหุ้นอ้างอิงปรับตัวขึ้น 1 ช่วงราคา ราคาของ DW เปลี่ยนแปลงประมาณ 1.2 ช่วงราคา<br>สำหรับ HSI DW จะใช้เปรียบเทียบเมื่อราคาหลักทรัพย์อ้างอิงปรับตัวเพิ่มขึ้น 100 จุด เช่น Sensitivity = 2.1 หมายถึง สัญญาซื้อขายล่วงหน้า HSI เคลื่อน 100 จุด ราคา DW จะเปลี่ยนแปลงประมาณ 2.1 ช่วงราคา',
    sensitivityTooltipMobEn: 'Indicates the number of ticks in the DW moves for a 1 tick move in the underlying. Eg, Sensitivity = 1.2 means underlying stock move 1 tick, your DW will move around 1.2 ticks.<br>For HSI DW’s sensitivity, it will indicates the number of ticks in the DW moves for 100 futures index point move. Eg. Sensitivity = 2.1 means HSI index futures move 100 futures index point, your DW will move around 2.1 ticks.',

    deltaTitle: 'Delta (%)',
    deltaTitleEn: 'Delta (%)',
    deltaTitleMob: 'Delta',
    deltaTitleMobEn: 'Delta',
    deltaTooltip: '<b>Delta:</b>ช้ประมาณการการเปลี่ยนแปลงของราคา DW เปรียบเทียบกับการเปลี่ยนแปลงราคาหุ้นอ้างอิงหรือดัชนีอ้างอิง Put จะมีค่า Delta เป็นลบ เพราะราคา Put จะเพิ่มขึ้นเมื่อหุ้นอ้างอิงหรือดัชนีอ้างอิงปรับตัวลดลงหรือราคาเปลี่ยนแปลงในทิศทางตรงกันข้าม ทั้งนี้ ค่า Delta ดังกล่าวยังไม่คำนวณรวมกับ DWPS<a target="_blank" href="/eduhtml/delta">คลิกที่นี่</a>เพื่ออ่านข้อมูลเพิ่มเติม',
    deltaTooltipEn: '<b>Delta:</b>Estimates how your DW move in relation to the underlying stock/index movement. Puts have negative deltas because put DW prices increase as the underlying stock/index decreases or move in the opposite direction. Note, this does not take into account DWPS, <a target="_blank" href="/eduhtml/delta">click here</a> to learn more.',

    deltaPerDwTitle: 'Delta ต่อ DW (%)',
    deltaPerDwTitleEn: 'Delta per DW (%)',
    deltaPerDwTitleMob: 'Delta ต่อ DW',
    deltaPerDwTitleMobEn: 'Delta per DW',
    deltaPerDwTooltip: '<b>Delta ต่อ DW:</b>ใช้ดูว่า DW เปลี่ยนแปลงสัมพันธ์กับหุ้นอ้างอิง/ดัชนีอ้างอิงอย่างไร เมื่อพิจารณาควบคู่กับ DWPS แล้ว <a target="_blank" href="/eduhtml/delta">คลิกที่นี่</a> เพื่ออ่านรายละเอียดเพิ่มเติมเกี่ยวกับ Delta',
    deltaPerDwTooltipEn: '<b>Delta per DW:</b>Tells you how your DW move in relation to the underlying stock/index movement after taking into account DWPS. <a target="_blank" href="/eduhtml/delta">click here</a> to learn more about Delta.', 

    premiumTitle: 'Premium (%)',
    premiumTitleEn: 'Premium (%)',
    premiumTitleMob: 'Premium',
    premiumTitleMobEn: 'Premium',
    premiumTooltip: '<b>Premium:</b>เป็นค่าที่แสดงถึง"มูลค่าเวลา" ของ DW โดย DW ที่มี Premium สูงกว่า จะหมายถึง DW ที่มีอายุคงเหลือมากกว่า หรือมี gearing สูงกว่า <a target="_blank" href="/eduhtml/premium">คลิกที่นี่</a> เพื่ออ่านรายละเอียดเพิ่มเติมเกี่ยวกับ Premium',
    premiumTooltipEn: '<b>Premium:</b>Also refered to as the "Time Value of the DW. Note a higher premium could be due to the DW being longer dated or due to the higher gearing. <a target="_blank" href="/eduhtml/premium">click here</a> to learn more about premium.', 

    impliedVolatilityTitle: 'Implied volatility',
    impliedVolatilityTitleEn: 'Implied volatility',
    impliedVolatilityTooltip: '<b>Implied volatility:</b>DW ที่มีความผันผวนสูง จะมีราคาสูงตามไปด้วย Implied Volatility มีความสัมพันธ์กับความผันผวนและความเสี่ยงของหุ้นอ้างอิงหรือดัชนีอ้างอิง<a target="_blank" href="/eduhtml/impliedvolatility">คลิกที่นี่</a> เพื่ออ่านข้อมูลเพิ่มเติม',
    impliedVolatilityTooltipEn: '<b>Implied volatility:</b>The higher the implied volatility, the higher the price of the DW. Relates to the volatility and risk of the underlying share/Index. <a target="_blank" href="/eduhtml/impliedvolatility">click here</a> to learn more about implied volatility.',

    timeDecayTitle: 'Time decay',
    timeDecayTitleEn: 'Time decay',
    timeDecayTooltip: '<b>Theta:</b>อัตราการลดลงของ time Value (ที่เรียกว่า time decay) รายวัน ซึ่งจะแสดงในรูปเปอร์เซ็นต์ (%)  <a a="" target="_blank" href="/eduhtml/timedecay">คลิกที่นี่</a> เพื่ออ่านรายละเอียดเพิ่มเติมเกี่ยวกับ Theta',
    timeDecayTooltipEn: '<b>Theta:</b>The daily time decay of the DW expressed in percentage terms. <a target="_blank" href="/eduhtml/timedecay">click here</a> to learn more about Theta.',
}