import React, { useEffect, useState } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import InvestorAcademyCommon from "../../../../components/common/InvestorAcademyCommon";
import "../common.css";
import "../investorAcademy.css";
import { languageText } from "../../../../languageText/InvestorAcademy/UsefulWarrantTerms/index"
import { languageTextMain } from "../../../../languageText/InvestorAcademy/index"

const UsefulWarrantTerms = (props) => {
 
  const location = useLocation();
  const history = useHistory();
  const [siteLang , setSiteLang] = useState("")

  // Site Language Change Start //
  useEffect(() => {
    window.scrollTo(0, 0);
    var siteLanguage = $.cookie('applang');
    setSiteLang(siteLanguage)
    if(siteLanguage == "eng"){
      document.querySelector("body").classList.add("eng")
    }else{
      document.querySelector("body").classList.remove("eng")
    }
  }, []);
  // Site Language Change End //

  return (
    <div id="investor_academy">
      <div className="imgbg" />
      <div className="mainbg" />
      <div id="main">
        <div id="maintable">
          <div style={{ display: "table-row" }}>
            <div id="leftbar" className="hidden-xs" />
            <div id="maincontainer">
              {/* Top Title Section Start */}
              <div id="mainnav">
                <table>
                  <tbody>
                    <tr>
                      <td width="20%" style={{textAlign:"left", visibility: "hidden"}} />
                      <td width="60%" style={{ textAlign: "center" }}>
                        <div id="pagetitlediv">
                          <div id="ptitle">
                            <img height="56px" src="/img/education/img/05.png" alt="Investor" />
                          </div>
                          <div id="pname">
                            {siteLang == "eng" ? languageText.pageTitleEn : languageText.pageTitle}
                          </div>
                        </div>
                      </td>
                      <td width="20%" style={{textAlign:"right", visibility: "hidden"}} />
                    </tr>
                  </tbody>
                </table>
              </div>
              {/* Top Title Section End */}
              {/* Main Content Section Start */}
              <div id="maincontent">
                <div id="mainscroll">
                  <div id="mainscrollcontent">
                    <style
                      dangerouslySetInnerHTML={{__html:" \n .hexrow.odd{ \n margin-left: 73px; \n } \n ",}}
                    />
                    <div className="contentbox">
                      <p>
                        {siteLang == "eng" ? languageText.pageDescriptionEn : languageText.pageDescription}
                      </p>
                      {/* Desktop Hexa Link Start */}
                      <div className="hexmenu hidden-xxs hidden-xs">
                        <div className="imgmask">
                          <img className="hexbg" src="/img/topic/useful_wrnt_terms.png" />
                          <div className="hexarea" style={{top: '11%', left: '4%', right: '67%', bottom: '71%'}}>
                            <div className="topictxt">
                              <Link to="/eduhtml/delta">
                                {siteLang == "eng" ? languageText.deltaEn : languageText.delta}
                              </Link>
                            </div>
                          </div>
                          <div className="hexarea" style={{top: '11%', left: '35%', right: '36%', bottom: '71%'}}>
                            <div className="topictxt">
                              {siteLang == "eng" ?
                                <Link to="/eduhtml/exerciseratio">
                                  {languageText.exerciseRatioEn}
                                </Link>
                                :
                                <Link to="/eduhtml/exerciseratio">
                                  {languageText.exerciseRatio}
                                  <br />
                                  {languageText.exerciseRatio1}
                                </Link>
                              }
                            </div>
                          </div>
                          <div className="hexarea" style={{top: '11%', left: '66%', right: '5%', bottom: '71%'}}>
                            <div className="topictxt">
                              {siteLang == "eng" ?
                                <Link to="/eduhtml/warrantsensitivity">
                                  {languageText.dWSensitivityEn}
                                  <br />
                                  {languageText.dWSensitivity1En}
                                </Link>
                                :
                                <Link to="/eduhtml/warrantsensitivity">
                                  {languageText.dWSensitivity}
                                </Link>
                              }
                            </div>
                          </div>
                          <div className="hexarea" style={{top: '40%', left: '20%', right: '52%', bottom: '41%'}}>
                            <div className="topictxt">
                              {siteLang == "eng" ?
                                <Link to="/eduhtml/breakevenprice">
                                  {languageText.breakEvenPriceEn}
                                  <br />
                                  {languageText.breakEvenPrice1En}
                                </Link>
                                :
                                <Link to="/eduhtml/breakevenprice">
                                  {languageText.breakEvenPrice}
                                  <br />
                                  {languageText.breakEvenPrice1}
                                </Link>
                              }
                            </div>
                          </div>
                          <div className="hexarea" style={{top: '40%', left: '51%', right: '21%', bottom: '41%'}}>
                            <div className="topictxt">
                              {siteLang == "eng" ?
                                <Link to="/eduhtml/premium">
                                  {languageText.premiumEn}
                                </Link>
                                :
                                <Link to="/eduhtml/premium">
                                  {languageText.premium}
                                  <br />
                                  {languageText.premium1}
                                </Link>
                              }
                            </div>
                          </div>
                          <div className="hexarea" style={{top: '70%', left: '4%', right: '67%', bottom: '11%'}}>
                            <div className="topictxt">
                              {siteLang == "eng" ?
                                <Link to="/eduhtml/gearing">
                                  {languageText.gearingEn}
                                </Link>
                                :
                                <Link to="/eduhtml/gearing">
                                  {languageText.gearing}
                                  <br />
                                  {languageText.gearing1}
                                </Link>
                              }
                            </div>
                          </div>
                          <div className="hexarea" style={{top: '70%', left: '35%', right: '36%', bottom: '11%'}}>
                            <div className="topictxt">
                              {siteLang == "eng" ?
                                <Link to="/eduhtml/effectivegearing">
                                  {languageText.effectiveGearingEn}
                                  <br />
                                  {languageText.effectiveGearing1En}
                                </Link>
                                :
                                <Link to="/eduhtml/effectivegearing">
                                  {languageText.effectiveGearing}
                                  <br />
                                  {languageText.effectiveGearing1}
                                </Link>
                              }
                            </div>
                          </div>
                          <div className="hexarea" style={{top: '70%', left: '66%', right: '5%', bottom: '11%'}}>
                            <div className="topictxt">
                              {siteLang == "eng" ?
                                <Link to="/eduhtml/impliedvolatility">
                                  {languageText.impliedVolatilityEn}
                                  <br />
                                  {languageText.impliedVolatility1En}
                                </Link>
                                :
                                <Link to="/eduhtml/impliedvolatility">
                                  {languageText.impliedVolatility}
                                  <br />
                                  {languageText.impliedVolatility1}
                                </Link>
                              }
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* Desktop Hexa Link End */}
                      {/* Mobile Hexa Link Start */}
                      <div className="mobilemenu visible-xxs visible-xs">
                        <div className="menuitem">
                          <Link to="/eduhtml/delta">
                            <img className="menuarrow" src="/img/mobilemenu/arrow.png" />
                            <img className="menuicon" src="/img/mobilemenu/delta.png" />
                            <div className="menutitle">
                                {siteLang == "eng" ? languageText.deltaEn : languageText.delta}
                            </div>
                          </Link>
                        </div>
                        <div className="menuitem">
                          <Link to="/eduhtml/exerciseratio">
                            <img className="menuarrow" src="/img/mobilemenu/arrow.png" />
                            <img className="menuicon" src="/img/mobilemenu/warrants_per_share.png" />
                            <div className="menutitle">
                              {siteLang == "eng" ?
                                languageText.exerciseRatioMobileEn
                                :
                                languageText.exerciseRatio + languageText.exerciseRatio1
                              }
                            </div>
                          </Link>
                        </div>
                        <div className="menuitem">
                          <Link to="/eduhtml/warrantsensitivity">
                            <img className="menuarrow" src="/img/mobilemenu/arrow.png" />
                            <img className="menuicon" src="/img/mobilemenu/sensitivity.png" />
                            <div className="menutitle">
                              {siteLang == "eng" ? languageText.dWSensitivityMobileEn : languageText.dWSensitivity}
                            </div>
                          </Link>
                        </div>
                        <div className="menuitem">
                          <Link to="/eduhtml/breakevenprice">
                            <img className="menuarrow" src="/img/mobilemenu/arrow.png" />
                            <img className="menuicon" src="/img/mobilemenu/breakeven_price.png" />
                            <div className="menutitle">
                              {siteLang == "eng" ? languageText.breakEvenPriceEn + languageText.breakEvenPrice1En
                                : languageText.breakEvenPrice + languageText.breakEvenPrice1
                              }
                            </div>
                          </Link>
                        </div>
                        <div className="menuitem">
                          <Link to="/eduhtml/premium">
                            <img className="menuarrow" src="/img/mobilemenu/arrow.png" />
                            <img className="menuicon" src="/img/mobilemenu/premium.png" />
                            <div className="menutitle">
                              {siteLang == "eng" ? languageText.premiumEn : languageText.premium + languageText.premium1}
                            </div>
                          </Link>
                        </div>
                        <div className="menuitem">
                          <Link to="/eduhtml/gearing">
                            <img className="menuarrow" src="/img/mobilemenu/arrow.png" />
                            <img className="menuicon" src="/img/mobilemenu/gearing.png" />
                            <div className="menutitle">
                              {siteLang == "eng" ? languageText.gearingEn : languageText.gearing + languageText.gearing1}
                            </div>
                          </Link>
                        </div>
                        <div className="menuitem">
                          <Link to="/eduhtml/effectivegearing">
                            <img className="menuarrow" src="/img/mobilemenu/arrow.png" />
                            <img className="menuicon" src="/img/mobilemenu/effective_gearing.png" />
                            <div className="menutitle">
                              {siteLang == "eng" ? languageText.effectiveGearingEn + languageText.effectiveGearing1En 
                              : languageText.effectiveGearing + languageText.effectiveGearing1
                              }
                            </div>
                          </Link>
                        </div>
                        <div className="menuitem">
                          <Link to="/eduhtml/impliedvolatility">
                            <img className="menuarrow" src="/img/mobilemenu/arrow.png" />
                            <img className="menuicon" src="/img/mobilemenu/implied_volatility.png" />
                            <div className="menutitle">
                              {siteLang == "eng" ? languageText.impliedVolatilityEn + languageText.impliedVolatility1En
                              : languageText.impliedVolatility + languageText.impliedVolatility1 
                              }
                            </div>
                          </Link>
                        </div> 
                      </div>
                      {/* Mobile Hexa Link End */}
                    </div>
                  </div>
                </div>
              </div>
              {/* Main Content Section End */}
            </div>
            <div id="rightbar" className="hidden-xs" />
          </div>
        </div>
      </div>
      {/* Bottom Left Right Links and Copyright Section Start */}
      <div id="bottom">
        <div className="hidden-xs hidden-xxs" id="copyright">
          {siteLang == "eng" ? languageTextMain.copyRightEn : languageTextMain.copyRight}
        </div>
        <div className="leftUrl">
          <table className="hasprev">
            <tbody>
              <tr>
                <td width="33px">
                  <Link to="/eduhtml/calculatingdwdailychange">
                    <div className="prev left" />
                  </Link>
                </td>
                <td>
                  <div className="prevlbl left">
                    <div className="prevname">
                      <Link to="/eduhtml/calculatingdwdailychange">
                        {siteLang == "eng" ? languageTextMain.calculatingDwdailyChangeEn : languageTextMain.calculatingDwdailyChange}
                      </Link>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="rightUrl">
          <table className="hasnext">
            <tbody>
              <tr>
                <td>
                  <div className="nextlbl right">
                    <div className="nextname">
                      <Link to="/eduhtml/delta">
                        {siteLang == "eng" ? languageTextMain.deltaEn : languageTextMain.delta}
                      </Link>
                    </div>
                  </div>
                </td>
                <td width="33px">
                  <Link to="/eduhtml/delta">
                    <div className="next right" />
                  </Link>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      {/* Bottom Left Right Links and Copyright Section End */}
      {/* Right Dots Menu Component Section Start */}
      <div id="dotbar" className="hidden-sm hidden-xs">
        <InvestorAcademyCommon Location={location} Hist={history} />
      </div>
      {/* Right Dots Menu Component Section End  */}
    </div>
  );
};
export default UsefulWarrantTerms;
