import React, { useEffect, useState } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import InvestorAcademyCommon from "../../../../components/common/InvestorAcademyCommon";
import "../common.css";
import "../investorAcademy.css";
import { languageText } from "../../../../languageText/InvestorAcademy/MarketMakers/index"
import { languageTextMain } from "../../../../languageText/InvestorAcademy/index"

const MarketMakers = (props) => {
  
  const location = useLocation();
  const history = useHistory();
  const [siteLang , setSiteLang] = useState("")

  // Site Language Change Start //
  useEffect(() => {
    window.scrollTo(0, 0);
    var siteLanguage = $.cookie('applang');
    setSiteLang(siteLanguage)
    if(siteLanguage == "eng"){
      document.querySelector("body").classList.add("eng")
    }else{
      document.querySelector("body").classList.remove("eng")
    }
  }, []);
  // Site Language Change End //

  return (
    <div id="investor_academy">
      <div className="imgbg" />
      <div className="mainbg" />
      <div id="main">
        <div id="maintable">
          <div style={{ display: "table-row" }}>
            <div id="leftbar" className="hidden-xs" />
            <div id="maincontainer">
              {/* Top Title Section Start */}
              <div id="mainnav">
                <table>
                  <tbody>
                    <tr>
                      <td width="20%" style={{textAlign:"left", visibility: "hidden"}} />
                      <td width="60%" style={{ textAlign: "center" }}>
                        <div id="pagetitlediv">
                          <div id="ptitle">
                            <img height="56px" src="/img/education/img/08.png" alt="Investor" />
                          </div>
                          <div id="pname">
                            {siteLang == "eng" ? languageText.pageTitleEn : languageText.pageTitle}
                          </div>
                        </div>
                      </td>
                      <td width="20%" style={{textAlign:"right", visibility: "hidden"}} />
                    </tr>
                  </tbody>
                </table>
              </div>
              {/* Top Title Section End */}
              {/* Main Content Section Start */}
              <div id="maincontent">
                <div id="mainscroll">
                  <div id="mainscrollcontent">
                    <style dangerouslySetInnerHTML={{__html:" \n .hexrow.odd{ \n margin-left: 73px; \n } \n ",}} />
                    <div className="contentbox">
                      <p className="showtile" dangerouslySetInnerHTML={{__html: siteLang == "eng" ? languageText.pageDescriptionEn : languageText.pageDescription}} />
                      {/* Desktop Hexa Link Start */}
                      <div className="hexmenu hidden-xxs hidden-xs">
                        <div className="imgmask">
                          <img className="hexbg" src="/img/topic/market_markers.png" />
                          <div className="hexarea" style={{top: '28%', left: '3%', right: '70%', bottom: '29%'}}>
                            <div className="topictxt">
                              {siteLang == "eng" ? 
                                <Link to="/eduhtml/howtocalculatecashsettlementamount">
                                  {languageText.howWorkEn}
                                  <br />
                                  {languageText.howWork1En}
                                </Link>
                                :
                                <Link to="/eduhtml/howtocalculatecashsettlementamount">
                                  {languageText.howWork}
                                </Link>
                              }
                            </div>
                          </div> 
                          <div className="hexarea" style={{top: '28%', left: '40%', right: '40%', bottom: '29%'}}>
                            <div className="topictxt">
                              {siteLang == "eng" ? 
                                <Link to="/eduhtml/liquidityvstradedvolume">
                                  {languageText.liquidityTradedEn}
                                  <br />
                                  {languageText.liquidityTraded1En}
                                </Link>
                                :
                                <Link to="/eduhtml/liquidityvstradedvolume">
                                  {languageText.liquidityTraded}
                                </Link>
                              }
                            </div>
                          </div> 
                          <div className="hexarea" style={{top: '28%', left: '68%', right: '4%', bottom: '29%'}}>
                            <div className="topictxt">
                              {siteLang == "eng" ? 
                                <Link to="/eduhtml/howdomarketmakershedge">
                                  {languageText.howDoMarketEn}
                                  <br />
                                  {languageText.howDoMarket1En}
                                </Link>
                                :
                                <Link to="/eduhtml/howdomarketmakershedge">
                                  {languageText.howDoMarket}
                                </Link>
                              }
                            </div>
                          </div> 
                        </div>
                      </div>
                      {/* Desktop Hexa Link End */}
                      {/* Mobile Hexa Link Start */}
                      <div className="mobilemenu visible-xxs visible-xs">
                        <div className="menuitem">
                          <Link to="/eduhtml/howtocalculatecashsettlementamount">
                            <img className="menuarrow" src="/img/mobilemenu/arrow.png" />
                            <img className="menuicon" src="/img/mobilemenu/how_do_market_markers_hedge.png" />
                            <div className="menutitle">
                              {siteLang == "eng" ? languageText.howWorkEn + languageText.howWork1En : languageText.howWork}
                            </div>
                          </Link>
                        </div>
                        <div className="menuitem">
                          <Link to="/eduhtml/liquidityvstradedvolume">
                            <img className="menuarrow" src="/img/mobilemenu/arrow.png" />
                            <img className="menuicon" src="/img/mobilemenu/liquidity_vs_traded.png" />
                            <div className="menutitle">
                              {siteLang == "eng" ? languageText.liquidityTradedEn + languageText.liquidityTraded1En : languageText.liquidityTraded}
                            </div>
                          </Link>
                        </div>
                        <div className="menuitem">
                          <Link to="/eduhtml/howdomarketmakershedge">
                            <img className="menuarrow" src="/img/mobilemenu/arrow.png" />
                            <img className="menuicon" src="/img/mobilemenu/how_do_market_markers_hedge.png" />
                            <div className="menutitle">
                              {siteLang == "eng" ? languageText.howDoMarketEn + languageText.howDoMarket1En : languageText.howDoMarket}
                            </div>
                          </Link>
                        </div>
                      </div>
                      {/* Mobile Hexa Link End */}
                    </div>
                  </div>
                </div>
              </div>
              {/* Main Content Section End */}
            </div>
            <div id="rightbar" className="hidden-xs" />
          </div>
        </div>
      </div>
      {/* Bottom Left Right Links and Copyright Section Start */}
      <div id="bottom">
        <div className="hidden-xs hidden-xxs" id="copyright">
          {siteLang == "eng" ? languageTextMain.copyRightEn : languageTextMain.copyRight}
        </div>
        <div className="leftUrl">
          <table className="hasprev">
            <tbody>
              <tr>
                <td width="33px">
                  <Link to="/eduhtml/takeovers">
                    <div className="prev left" />
                  </Link>
                </td>
                <td>
                  <div className="prevlbl left">
                    <div className="prevname">
                      <Link to="/eduhtml/takeovers">
                        {siteLang == "eng" ? languageTextMain.takeOversEn : languageTextMain.takeOvers}
                      </Link>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="rightUrl">
          <table className="hasnext">
            <tbody>
              <tr>
                <td>
                  <div className="nextlbl right">
                    <div className="nextname">
                      <Link to="/eduhtml/howtocalculatecashsettlementamount">
                        {siteLang == "eng" ? languageTextMain.howToCalculateCashSettlementAmountEn : languageTextMain.howToCalculateCashSettlementAmount}
                      </Link>
                    </div>
                  </div>
                </td>
                <td width="33px">
                  <Link to="/eduhtml/howtocalculatecashsettlementamount">
                    <div className="next right" />
                  </Link>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      {/* Bottom Left Right Links and Copyright Section End */}
      {/* Right Dots Menu Component Section Start */}
      <div id="dotbar" className="hidden-sm hidden-xs">
        <InvestorAcademyCommon Location={location} Hist={history} />
      </div>
      {/* Right Dots Menu Component Section End  */}
    </div>
  );
};
export default MarketMakers;
