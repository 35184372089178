import React, { useState, useEffect } from 'react';
import API from '../../../utils/apiCalling';
import { config } from '../../../utils/apiUrl';
import { get } from 'lodash';
import { Link } from 'react-router-dom';
import moment from 'moment';
import thLocale from 'moment/locale/th'
import enLocale from "moment/locale/en-in"
import ColumnBarChart from '../../../components/Charts/ColumnBarChart';
import { useContext } from 'react';
import LangContext from '../../../components/common/context/lang-context';
import { languageText } from "../../../languageText/warrantMarketTurnover"
import LiveMatrixSideBars from "../../../components/common/Sidebar/LiveMatrixSideBar"
import WarrantSearchSideBars from "../../../components/common/Sidebar/WarrantSearchSideBar"
import WarrantCalculatorSideBars from "../../../components/common/Sidebar/WarrantCalculatorSideBar"
import AdvertiseMentSidebar from "../../../components/common/Sidebar/AdvertiseMentSidebar"

const WarrantMarketTurnOver = (props) => {

  const langContext = useContext(LangContext);
  const [language, setLanguage] = useState('thai');
  const api = new API()
  const date = new Date();
  const [marketTurnOverAll, setMarketTurnOverAll] = useState([])
  const updateDate = marketTurnOverAll && marketTurnOverAll[0] && marketTurnOverAll[0].stats_date_obj
  const [siteLang , setSiteLang] = useState("")

  useEffect(() => {
    window.scrollTo(0, 0)
    var siteLanguage = $.cookie('applang');
    setSiteLang(siteLanguage)
    if(siteLanguage == "eng"){
      document.querySelector("body").classList.add("eng")
    }else{
      document.querySelector("body").classList.remove("eng")
    }
  },[])

  useEffect(() => {
    getMarketTurnOverResult()
  },[])

  const getMarketTurnOverResult = async () => {
    let marketTurnOverResult = await api.get(config.marketTurnOver, true, { type: "dwturnover", qid: Date.now() })
    let marketTurnOverRes = get(marketTurnOverResult, "dwturnover", [])
    setMarketTurnOverAll(marketTurnOverRes)
  }

  return (
    <div id="dwturnover" className="pageobj" >
      <div className="breadcrumbbar">
        <div className="container-fluid">
          <ol className="breadcrumb">
            <li>
              <Link to="/home?dwstat">{siteLang == "eng" ? languageText.firstNavigationEn : languageText.firstNavigation}</Link>
            </li>
            <li className="active">
              {siteLang == "eng" ? languageText.secondNavigationEn : languageText.secondNavigation}
            </li>
          </ol>
        </div>
      </div>
      <div className="stripe-bg" />
      <div className="container-fluid">
        <div className="topimg hidden-sm hidden-xs">
          <img src="/img/bg/top-marketturnover.jpg" alt='banner image' />
        </div>
        <div className="row">
          <div className="col-md-8">
            <div className="section">
              <div className="page-header">
                <h1>{siteLang == "eng" ? languageText.secondNavigationEn : languageText.secondNavigation}</h1>
              </div>
              <p>
                {siteLang == "eng" ? languageText.pageDescriptionEn : languageText.pageDescription}
              </p>
              <div className="tblh">
                <div className="tblh-left">
                  <h2 className="small">{siteLang == "eng" ? languageText.dwMarketTurnoverEn : languageText.dwMarketTurnover}</h2>
                </div>
                <div className="tblh-right">
                  <div className="lastupdatebox hidden-xs">
                    Last update : <span>{siteLang == "eng" ? moment(new Date(date)).locale('en', [enLocale]).format('DD MMM YY') : moment(new Date(date)).locale('th', [thLocale]).clone().add(parseInt(543),'years').format('DD MMM YY')}</span>
                  </div>
                </div>
              </div>
              <div id="totalTbl" className="tbl">
                <table className="table table-striped table-closed">
                  <thead>
                    <tr>
                      <th>{siteLang == "eng" ? languageText.tableDateEn : languageText.tableDate}</th>
                      <th>{siteLang == "eng" ? languageText.tableTotalMarketEn : languageText.tableTotalMarket} (mil THB)</th>
                      <th>{siteLang == "eng" ? languageText.tableTotalDwEn : languageText.tableTotalDw} (mil THB)</th>
                      <th>{siteLang == "eng" ? languageText.tableTotalDwTurnoverEn : languageText.tableTotalDwTurnover}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {marketTurnOverAll && marketTurnOverAll.map((turnOverAllData, turnOverAllIndex) => (
                      <tr className="rowtmpl" key={turnOverAllIndex} idx={turnOverAllIndex}>
                        <td className="stats_date_obj">{turnOverAllData.stats_date_obj}</td>
                        <td className="total">{turnOverAllData.total}</td>
                        <td className="dw">{turnOverAllData.dw}</td>
                        <td className="pct">{turnOverAllData.pct}%</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className='section'>
              <div className="chartbox-market-turn-over">
                <h2 className="small">Total DW turnover(mil THB)</h2>
                <ColumnBarChart 
                  marketTurnOverAll={[...marketTurnOverAll].reverse()} 
                  marketTurnOver={[...marketTurnOverAll].reverse()}
                 />
              </div>
              <hr />
              <p className='small'>{siteLang == "eng" ? languageText.marketDelayEn : languageText.marketDelay}</p>
            </div>
          </div>

          <div className="col-md-4 hidden-xs hidden-sm">
            <LiveMatrixSideBars />
            <WarrantSearchSideBars />
            {/* <WarrantCalculatorSideBars /> */}
            <AdvertiseMentSidebar />
          </div>
        </div>
      </div>
    </div>
  );
}
export default WarrantMarketTurnOver;
