import React, { useState, useEffect,Fragment } from 'react';
import EffectiveGearings from './EffectiveGearings';
import "../../common.css";
import "../../investorAcademy.css";


const EffectiveGearing = (props) => {
    return ( 
        <Fragment> 
            <EffectiveGearings/>
        </Fragment> 
     );
}


export default EffectiveGearing;