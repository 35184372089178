import React from 'react';
import {  Modal } from 'reactstrap';

const SubscribeModalImg = (props) => {

    const { OpenModel, Open, } = props;

    return ( 
      <div className="inner-area">
        <Modal  centered isOpen={Open} className="subsimgModal mt-0 mb-0">
          <img className="btn-close" onClick={OpenModel} src="/img/bullet/icon-close-white.png" />
          <img className="image" src="/img/marketnews/sample.png" />
        </Modal>
      </div> 
     );
}
 
export default SubscribeModalImg;