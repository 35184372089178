import React, { useState, useEffect,Fragment } from 'react';
import HowSelectWarrants from './HowSelectWarrants';
import "../common.css";
import "../investorAcademy.css" 


const HowSelectWarrant = (props) => {
    return ( 
        <Fragment> 
        <HowSelectWarrants/>
        </Fragment> 
     );
}


export default HowSelectWarrant;