import React, { useState, useEffect,Fragment } from 'react';
import HowHedges from './HowHedge';
import "../../common.css";
import "../../investorAcademy.css";


const HowHedge = (props) => {
    return ( 
        <Fragment> 
            <HowHedges/>
        </Fragment> 
     );
}


export default HowHedge;