import React, { useState, useEffect,Fragment } from 'react';
import SelectingWarrantsThird from './SelectingWarrants';
import "../../common.css";
import "../../investorAcademy.css";


const SelectingWarrantThird = (props) => {
    return ( 
        <Fragment> 
            <SelectingWarrantsThird/>
        </Fragment> 
     );
}


export default SelectingWarrantThird;