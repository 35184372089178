import React, { useState, useEffect,Fragment } from 'react';
import HowCallWarrantWorks from './HowCallWarrantWorks';
import "../../common.css";
import "../../investorAcademy.css";


const HowCallWarrantWork = (props) => {
    return ( 
        <Fragment> 
            <HowCallWarrantWorks/>
        </Fragment> 
     );
}


export default HowCallWarrantWork;