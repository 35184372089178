import React, { useState, useEffect } from "react";
import "./photoDetail.css";
import { Link } from "react-router-dom";
import { getGallery, getGallerybyId } from "../../../utils/photoGallrey.api";
import { languageText } from "../../../languageText/photoGallery";
import { config } from '../../../utils/apiUrl'; 
import API from '../../../utils/apiCalling';

const PhotoDetail = (props) => {
  const api = new API()
  const queryParams = new URLSearchParams(window.location.search);
  const id = queryParams.get("album_id");
  const [result, setResult] = useState();
  const [albumResult, setAlbumResult] = useState();
  const qid = Date.now();
  const [imgView, setImgView] = useState("");
  const [activeClass, setActiveClass] = useState(0);
  const [siteLang , setSiteLang] = useState("")
  const [imagePopup , setImagePopup] = useState(false)

  useEffect(() => {
    window.scrollTo(0, 0)
    var siteLanguage = $.cookie('applang');
    setSiteLang(siteLanguage)
    if(siteLanguage == "eng"){
      document.querySelector("body").classList.add("eng")
    }else{
      document.querySelector("body").classList.remove("eng")
    }
    getPhotoGallery();
    getPhotoGalleryById();
  }, []);

  function getPhotoGallery() {
    getGallery(qid).then((res) => {
      var response = res.data.filter((d) => d.id === parseInt(id));
      setResult(response);
    });
  }

  const getPhotoGalleryById = async () => {
      let photoGalleryResult = await api.get(config.photoGallery, true,{album_id: id, qid:qid} )
      // getGallerybyId(id, qid).then((res) => {
      let items = [];
      if (photoGalleryResult?.length > 0) {
        for (let i = 0; i < photoGalleryResult?.length; i++) {
            let advImgData = await api.get(config.advImgData, true, {
                id: photoGalleryResult[i].id,
            });
            items.push({
                id: photoGalleryResult[i].id,
                album_id: photoGalleryResult[i].album_id,
                imgLink: advImgData,
            });
        }
        setImgView(items[0].imgLink);
      }else{
        setImgView("");
      }
      setAlbumResult(items);
    // });
  }

  const imgId = (d, ind) => {
    setImgView(d.imgLink);
    setActiveClass(ind);
  };

  const imgIdMobile = (d) => {
    setImgView(d.imgLink);
    setImagePopup(true)
  };

  const handleCLick = () => {
    setImagePopup(false)
  }

  return (
    <>
      <div id="individualgallery" className="pageobj">
        <div className="breadcrumbbar">
          <div className="container-fluid">
            <ol className="breadcrumb">
              <li>
                <Link to="/home?dwnews">{siteLang == "eng" ? languageText.firstNavigationEn : languageText.firstNavigation} </Link>
              </li>
              <li>
                <Link to="/marketnews/photogallery"> {siteLang == "eng" ? languageText.secondNavigationEn : languageText.secondNavigation} </Link>
              </li>
              {result && <li className="active"> {siteLang == "eng" ? result[0].en_title : result[0].th_title}</li>}
            </ol>
          </div>
        </div>
        <div className="stripe-bg" />
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="section">
                <div className="page-header">
                  {result && <h1>{siteLang == "eng" ? result[0].en_title : result[0].th_title}</h1>}
                </div>

                <div id="photos" style={{ position: "relative" }}>
                  <div id="photo-main">
                    <div className="photo-holder holder-center">
                      {albumResult && albumResult.length > 0 ? (
                        <img
                          src={imgView}
                          alt="Photo Detail"
                        />
                      ) : null}
                    </div>
                  </div>
                  <div id="photo-list" className="row desktop">
                    {albumResult && albumResult.map((d, ind) => (
                        <div className="col-xs-6 col-sm-3 photo-item" key={ind}>
                          <div className="photo-holder holder-center">
                            <img
                              src={d.imgLink}
                              alt="Photo Detail"
                            />
                          </div>
                          <div
                            className={`photo-shadow ${
                              ind === activeClass ? "active" : ""
                            }`}
                            onClick={() => imgId(d, ind)}
                            data={d.id}
                          ></div>
                        </div>
                    ))}
                  </div>
                  <div id="photo-list" className="row mobile">
                    {albumResult && albumResult.map((d, index) => (
                        <div className="col-xs-6 col-sm-3 photo-item" key={index}>
                          <div className="photo-holder holder-center">
                            <img
                              src={d.imgLink}
                              alt="Photo Detail"
                              onClick={() => imgIdMobile(d)}
                            />
                          </div>
                        </div>
                    ))}
                  </div>
                </div>
                <hr style={{ marginTop: "20px" }} className="hr-hmgbm" />
                <p className="small">
                  {siteLang == "eng" ? languageText.marketDelayEn : languageText.marketDelay}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div id="popup-image" style={{display: imagePopup ? "table" : "none"}}>
          <div>
            <div className="inner-area">
              <img className="btn-close" src="/img/bullet/icon-close-white.png" onClick={handleCLick} />
              <img className="image" src={imgView} style={{maxHeight: '768px', maxWidth: '991px'}} />
            </div>
          </div>
        </div>

      </div>
    </>
  );
};
export default PhotoDetail;
