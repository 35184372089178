import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { languageText } from '../../../../languageText/activitieInnerPages'

const Freevoucher = () => {

  const [siteLang , setSiteLang] = useState("")

  useEffect(() => {
    window.scrollTo(0, 0)
    var siteLanguage = $.cookie('applang');
    setSiteLang(siteLanguage)
    if(siteLanguage == "eng"){
      document.querySelector("body").classList.add("eng")
    }else{
      document.querySelector("body").classList.remove("eng")
    }
  }, [])

  const handleClick = (Event) => {
    Event.preventDefault();
    window.open(
      "https://www.facebook.com/login.php?skip_api_login=1&api_key=966242223397117&signed_next=1&next=https%3A%2F%2Fwww.facebook.com%2Fshare.php%3Fu%3Dhttps%253A%252F%252Ft1www5.trkd-hs.com%252Fthaidw_aws%252Factivities%252FHSIDIMSUM%253Flang%253Den&cancel_url=https%3A%2F%2Fwww.facebook.com%2Fdialog%2Fclose_window%2F%3Fapp_id%3D966242223397117%26connect%3D0%23_%3D_&display=popup&locale=en_GB", "share", 'width=560,height=650' 
    );
  } 

  return (
    <div id="supertrader" className="pageobj">
        <div className="breadcrumbbar">
          <div className="container-fluid">
            <ol className="breadcrumb">
              <li>
                <Link to="/home?dwnews">
                  {siteLang == "eng" ? languageText.dw28freevoucherFirstNavigationEn : languageText.dw28freevoucherFirstNavigation}
                </Link>
              </li>
              <li className="active">
                {siteLang == "eng" ? languageText.dw28freevoucherSecondNavigationEn : languageText.dw28freevoucherSecondNavigation}
              </li>
            </ol>
          </div>
        </div>
        <div className="stripe-bg" />
        <div className="container-fluid">
          <div className="section">
            <div className="page-header">
              <h1>{siteLang == "eng" ? languageText.dw28freevoucherPageTitleEn : languageText.dw28freevoucherPageTitle}</h1>
              <div className="btn-area">
                <div className="fbSharebtn" onClick={handleClick} >
                  <i className="fa fa-facebook" aria-hidden="true" />Share on Facebook
                </div>
              </div>
            </div>
            <div className="part top-banner">
              <div className="bg-layer">
                <img src="/img/game/Freevoucher.jpg" />
              </div>
            </div>
          </div>
        </div>
      </div>
  )
}

export default Freevoucher