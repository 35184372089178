import React, { useState } from 'react';
import RangeSlider from 'react-bootstrap-range-slider';
import 'react-bootstrap-range-slider/dist/react-bootstrap-range-slider.css';


export const RangeSliderDateSelectionComponent = (props) => {
  const {value, setValue,max,min} = props;
  const handleDate=(e)=>{
    var dtime = e.target.value
    if(!isNaN(dtime)){
    setValue(new Date(+dtime))
    }
  }
  return (
    <RangeSlider 
      step={86400000} 
      tooltip={"off"} 
      variant={"warning"}
      value={new Date(value).getTime()} 
      max={max} 
      min={min}
      onChange={e=>handleDate(e)}
    />
  );

};

export default RangeSliderDateSelectionComponent;