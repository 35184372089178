import React, { useState, useEffect,Fragment } from 'react';
import Risks from './Risks';
import "../common.css";
import "../investorAcademy.css" 


const Risk = (props) => {
    return ( 
        <Fragment> 
        <Risks/>
        </Fragment> 
     );
}


export default Risk;