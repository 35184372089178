export const testimonialList = [
  {
    th_title: "Your preferred trading partner",
    image: "https://i.ytimg.com/vi/Wi9oqHel8_s/mqdefault.jpg",
    th_text: "“Your preferred trading partner” #GrowYourSuccess",
    playlistId: "PLOkzCdnS95xQCh_DVyhyavIoB02GMNWwV",
    videoId: "Wi9oqHel8_s",
    en_title: "Your preferred trading partner",
    en_text: "“Your preferred trading partner” #GrowYourSuccess",
  },
  {
    th_title: "Anywhere trader",
    image: "https://i.ytimg.com/vi/9-ABKX2X1Bs/mqdefault.jpg",
    th_text: "Anywhere trader #GrowYourSuccess",
    playlistId: "PLOkzCdnS95xQCh_DVyhyavIoB02GMNWwV",
    videoId: "9-ABKX2X1Bs",
    en_title: "Anywhere trader",
    en_text: "Anywhere trader #GrowYourSuccess",
  },
  {
    th_title: "Learning DW with GURU",
    image: "https://i.ytimg.com/vi/GpS09_Mn_hk/mqdefault.jpg",
    th_text: "“Learning DW with GURU” #GrowYourSuccess",
    playlistId: "PLOkzCdnS95xQCh_DVyhyavIoB02GMNWwV",
    videoId: "GpS09_Mn_hk",
    en_title: "Learning DW with GURU",
    en_text: "“Learning DW with GURU” #GrowYourSuccess",
  },
  {
    th_title: "#GrowYourSuccess",
    image: "https://i.ytimg.com/vi/ZECQFez_xP0/mqdefault.jpg",
    th_text: "“Macquarie DW28” #GrowYourSuccess",
    playlistId: "PLOkzCdnS95xQCh_DVyhyavIoB02GMNWwV",
    videoId: "ZECQFez_xP0",
    en_title: "#GrowYourSuccess",
    en_text: "“Macquarie DW28” #GrowYourSuccess",
  }
]