export const languageText ={

    pageTitle: "ทำไมต้องลงทุนใน DW?",
    pageTitleEn: "Why Trade DW?",

    pageName: "อัตราทด (Gearing Effect)",
    pageNameEn: "Gearing effect",

    pageDescription: "DW ช่วยให้นักลงทุนมีโอกาสได้รับผลตอบแทนจากการเคลื่อนไหวของราคาหุ้นอ้างอิงโดยใช้เงินในสัดส่วนที่น้อยกว่าเมื่อเทียบกับการลงทุนในหุ้นอ้างอิง ซึ่งหมายถึงว่า คุณมีโอกาสได้รับผลตอบแทนจากการเคลื่อนไหวของราคาหุ้นอ้างอิงจากเงินลงทุนจำนวนน้อยกว่า ดังนั้นราคา DW จึงเปลี่ยนแปลงในอัตราที่สูงกว่าราคาหุ้นอ้างอิง ทำให้คุณมีโอกาสได้รับอัตราผลตอบแทนในจำนวนที่สูงกว่าการลงทุนในหุ้นอ้างอิงโดยตรง ซึ่งเรียกว่าอัตราทด (Gearing Effect) และทำให้ DW เป็นที่นิยมของนักลงทุน",
    pageDescriptionEn: "A DW enables you to gain exposure to a share for just a fraction of its price, meaning you can get a similar effective exposure for a much smaller capital outlay. Therefore, DW tend to move in greater percentages than the underlying shares, allowing you the potential for higher percentage returns than if you had bought the shares directly. This is known as the gearing effect and the main reason DW are so popular with investors.",

    imgUrl: "/img/education/img/chart_gearingeffect_th.png",
    imgUrlEn: "/img/education/img/chart_gearingeffect.png",
}