export const languageText ={

    pageTitle: "DW คืออะไร?",
    pageTitleEn: "What are DW?",

    pageDescription: "สามารถคลิกหัวข้อเพื่ออ่านข้อมูลเพิ่มเติม",
    pageDescriptionEn: "Please click on each of the tiles below for more details",

    // Hexa Box Content Start
    callVsPut: "Call vs Put",
    callVsPutEn: "Call vs Put",

    howCallWarrant: "Call DW ",
    howCallWarrantEn: "How a Call ",
    howCallWarrant1: " ทำงานอย่างไร",
    howCallWarrant1En: " Warrant works?",
    howCallWarrantMobile: "Call DW ทำงานอย่างไร",
    howCallWarrantMobileEn: "How a Call Warrant works?",

    howPutWarrant: "Put DW ",
    howPutWarrantEn: "How a Put ",
    howPutWarrant1: " ทำงานอย่างไร",
    howPutWarrant1En: " Warrant works?",
    howPutWarrantMobile: "Put DW ทำงานอย่างไร",
    howPutWarrantMobileEn: "How a Put Warrant works?",

    howAreWarrant: "สัญลักษณ์ของ ",
    howAreWarrantEn: "How are ",
    howAreWarrant1: " DW เป็นอย่างไร",
    howAreWarrant1En: " Warrants named?",
    howAreWarrantMobile: "สัญลักษณ์ของ DW เป็นอย่างไร",
    howAreWarrantMobileEn: "How are Warrants named?",
    // Hexa Box Content End
}