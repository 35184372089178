import React, { useState, useEffect,Fragment } from 'react';
import LimitedLosses from './LimitedLosses';
import "../../common.css";
import "../../investorAcademy.css";


const LimitedLoss = (props) => {
    return ( 
        <Fragment> 
        <LimitedLosses/>
        </Fragment> 
     );
}


export default LimitedLoss;