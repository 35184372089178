export const languageText ={

    pageTitle: "ทำไมต้องลงทุนใน DW?",
    pageTitleEn: "Why Trade DW?",

    pageName: "ไม่มีการเรียกหลักประกันเพิ่ม",
    pageNameEn: "No margin call",

    pageDescription: "แม้ว่าหุ้นอ้างอิงจะเปลี่ยนแปลงในทิศทางตรงข้าม แต่นักลงทุนที่ลงทุนใน DW ก็จะไม่ถูกผู้ออกเรียกเงินหลักประกันเพิ่มเติม ดังนั้น ความเสี่ยงสูงสุดของนักลงทุนจึงถูกกำหนดไว้ตั้งแต่เริ่มลงทุน ช่วยให้คุณมีเวลาพักผ่อนมากขึ้นอีกหน่อย.",
    pageDescriptionEn: "Even if the underlying share or index moves adversely, DW investors cannot be asked by the issuer to make margin calls. The maximum exposure to the investor is set up front at the start of the trade. So you can rest a little easier.",

    imgUrl: "/img/education/img/nomargincall.png",
    imgUrlEn: "/img/education/img/nomargincall.png",
}