import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import API from "../../../utils/apiCalling";
import { config } from "../../../utils/apiUrl";
import "./subscribe.css";
import SubscribeModalImg from "../../../components/common/subscribeModalImg";
import SpeakModal from "../../../components/common/SpeakModal";
import validator from "validator";
import { languageText } from "../../../languageText/subscribe"

const Subscribes = (props) => {
  
  const api = new API();
  const [email, setEmail] = useState();
  const [openLive, setOpenLive] = useState(false);
  const [openMsg, setOpenMsg] = useState(false);
  const [emailMsg, setEmailMsg] = useState(false);
  const [siteLang , setSiteLang] = useState("")

  useEffect(() => {
    window.scrollTo(0, 0);
    var siteLanguage = $.cookie('applang');
    setSiteLang(siteLanguage)
    if(siteLanguage == "eng"){
      document.querySelector("body").classList.add("eng")
    }else{
      document.querySelector("body").classList.remove("eng")
    }
  }, []);

  const validateEmail = (e) => {
    var email = e.target.value;
    if (validator.isEmail(email)) {
      setEmail(email);
    } else {
      setEmailMsg(true);
      setEmail();
    }
  };

  function valueSubmit(e) {
    if (!email) {
      setEmailMsg(true);
    } else {
      submit(e);
    }
  }

  const submit = async (e) => {
    e.preventDefault();
    let data = {
      email,
    };
    let res = await api
      .post(`${config.subscription}${"?p=register"}`, data)
      .then((res) => {
        if(res.message == "Registration succeed"){
          setOpenMsg(true);
          setTimeout(() => setOpenMsg(false), [2500]);
        }
        else{
          alert("Please try again after some time.")
        }
      })
      .catch((error) => console.log(error));
  };

  return (
    <>
      <div id="subscribe" className="pageobj">
        <div className="breadcrumbbar">
          <div className="container-fluid">
            <ol className="breadcrumb">
              <li>
                <Link to="/home?dwnews">
                  {siteLang == "eng" ? languageText.firstNavigationEn : languageText.firstNavigation}
                </Link>
              </li>
              <li className="active">
                {siteLang == "eng" ? languageText.secondNavigationEn : languageText.secondNavigation}
              </li>
            </ol>
          </div>
        </div>
        <div className="stripe-bg" />
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-12">
              <div className="section">
                <div className="bg-layer hidden-sm hidden-xs" style={{ height: "1000px" }} >
                  <img src="/img/marketnews/bg-subscribe.jpg" className="hidden-xs" />
                  <img src="/img/marketnews/u2004_a.png" className="visible-xs" />
                </div>
                <div className="content-layer">
                  <div className="page-header" style={{ marginTop: 0 }}>
                    <h1 className="color-primary">
                      {siteLang == "eng" ? languageText.pageTitleEn : languageText.pageTitle}
                    </h1>
                    <div className="title_2">
                      {siteLang == "eng" ? languageText.pageTitle2En : languageText.pageTitle2}
                    </div>
                    <div className="title_3">
                      {siteLang == "eng" ? languageText.pageTitle3En : languageText.pageTitle3}
                    </div>
                    <div className="title_4">
                      {siteLang == "eng" ? languageText.pageTitle4En : languageText.pageTitle4}
                    </div>
                    <div
                      id="view-sample-btn"
                      data-bs-toggle="collapse"
                      href="#popup-image"
                      role="button"
                      aria-expanded="false"
                      aria-controls="popup-image"
                      onClick={() => setOpenLive(true)}
                    >
                      {siteLang == "eng" ? languageText.viewSampleEn : languageText.viewSample}
                    </div>
                  </div>
                  <div className="tips-layer">
                    <div className="row">
                      <div className="col-xs-12 col-sm-4">
                        <div>
                          <div className="tip-icon">
                            <img src="/img/marketnews/subscribe_1.png" />
                          </div>
                          <div className="tip-item">
                            <div className="tip-title">
                              {siteLang == "eng" ? languageText.dailyHighlightTitleEn : languageText.dailyHighlightTitle}
                            </div>
                            {siteLang == "eng" ? languageText.dailyHighlightDesEn : languageText.dailyHighlightDes}
                          </div>
                        </div>
                      </div>
                      <div className="col-xs-12 col-sm-4">
                        <div>
                          <div className="tip-icon">
                            <img src="/img/marketnews/subscribe_2.png" />
                          </div>
                          <div className="tip-item">
                            <div className="tip-title">
                              {siteLang == "eng" ? languageText.dwMarketTitleEn : languageText.dwMarketTitle}
                            </div>
                            {siteLang == "eng" ? languageText.dwMarketDesEn : languageText.dwMarketDes}
                          </div>
                        </div>
                      </div>
                      <div className="col-xs-12 col-sm-4">
                        <div>
                          <div className="tip-icon">
                            <img src="/img/marketnews/subscribe_3.png" />
                          </div>
                          <div className="tip-item">
                            <div className="tip-title">
                              {siteLang == "eng" ? languageText.overnightWrapTitleEn : languageText.overnightWrapTitle}
                            </div>
                            {siteLang == "eng" ? languageText.overnightWrapDesEn : languageText.overnightWrapDes}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="shadow-layer">
                    <div className="signup-layer">
                      <div className="page-header">
                        <h3>{siteLang == "eng" ? languageText.signUpEn : languageText.signUp}</h3>
                      </div>
                      <div className="title_email">{siteLang == "eng" ? languageText.emailAddEn : languageText.emailAdd}</div>
                      <input
                        type="text"
                        id="email"
                        name="email"
                        value={email}
                        onChange={(e) => {
                          validateEmail(e);
                          setEmailMsg(false);
                        }}
                        placeholder={siteLang == "eng" ? languageText.emailPlaceholderEn : languageText.emailPlaceholder}
                      />
                      <div className="warning">
                        {emailMsg && <span>Invalid email</span>}
                      </div>
                      <p className="small">
                        {siteLang == "eng" ? languageText.formTermEn : languageText.formTerm}
                        <a href="https://www.thaidw.com/about/privacypolicy">
                          https://www.thaidw.com/about/privacypolicy
                        </a>
                      </p>
                      <button id="submitBtn" onClick={(e) => valueSubmit(e)}>
                        {siteLang == "eng" ? languageText.submitEn : languageText.submit}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <SubscribeModalImg Open={openLive} OpenModel={() => setOpenLive(!true)} />
      <div id="popup-customized" className="subscribe" style={{ display: "none" }}>
        <div>
          <h5>You have successfully registered for our daily newsletter!</h5>
        </div>
      </div>
      {openMsg && <SpeakModal open={openMsg} siteLang={siteLang} />}
    </>
  );
};
export default Subscribes;
