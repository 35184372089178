export const languageText ={

    pageTitle: "สัญลักษณ์ของ DW เป็นอย่างไร",
    pageTitleEn: "How are DW named?",

    pageName: "สัญลักษณ์ของ DW เป็นอย่างไร",
    pageNameEn: "How are DW named?",

    pageDescription: "DW จดทะเบียนซื้อขายในตลาดหลักทรัพย์แห่งประเทศไทยเช่นเดียวกันกับหุ้น และสามารถซื้อขายผ่านบริษัทหลักทรัพย์ใดก็ได้ DW แต่ละรุ่นจะมีสัญลักษณ์การซื้อขายเฉพาะ ซึ่งสัญลักษณ์เหล่านี้จะแสดงข้อมูลเฉพาะเกี่ยวกับ DW แต่ละรุ่น",
    pageDescriptionEn: "Like shares, DW are listed on the SET and can be bought and sold through any stockbroker. Each DW has an assigned symbol. The symbol contains information about the DW, whereas the symbol is used by most trading platforms to identify each warrant.",

    imgUrl: "/img/education/img/howarewarrantnamed_th.png",
    imgUrlEn: "/img/education/img/howarewarrantnamed.png",
}