export const languageText ={
    
    firstNavigation: "รอบรู้เรื่อง DW ",
    firstNavigationEn: "DW education ",
    secondNavigation: " วิดีโอความรู้เรื่อง DW",
    secondNavigationEn: " DW video",

    pageTitle: "วิดีโอความรู้เรื่อง DW",
    pageTitleEn: "DW video",
    pageDescription: "เพิ่มพูนความรู้ DW ง่ายๆด้วย VDO ความรู้และบันทึกงานสัมมนาเกี่ยวกับ DW",
    pageDescriptionEn: "Improve your knowledge of DW and seminar videos.",

    questionHeader: "Questions you may have",
    questionHeaderEn: "Questions you may have",
    
    marketDelay: "ข้อมูลตลาดทั้งหมดล่าช้าประมาณ 15 นาที",
    marketDelayEn: "All market data is delayed 15 mins.",
}