export const languageText ={

    pageTitle: "ทำไมต้องลงทุนใน DW?",
    pageTitleEn: "Why Trade DW?",

    pageName: "ความสามารถในการทำกำไรจากการขาขึ้นและลง",
    pageNameEn: "Ability to profit form rises and falls",

    pageDescription: "DW สร้างโอกาสที่นักลงทุนจะได้กำไรในช่วงที่หุ้นขึ้นและลง Call DW สามารถเพิ่มประโยชน์จากการทำกำไรเมื่อราคาหุ้นเพิ่มขึ้น ในขณะที่ Put DW สามารถเพิ่มประโยชน์การทำกำไรเมื่อราคาหุ้นลดลง โดย Put DW เป็นหนึ่งในไม่กี่ผลิตภัณฑ์ทางการเงินในประเทศไทย ที่ช่วยให้นักลงทุนสามารถมีสถานะเสมือน Short หุ้น (ทำกำไรเมื่อราคาหุ้นเป็นขาลง)",
    pageDescriptionEn: "DW provide investors the opportunity to profit from both stock price increases and falls. Call DW can boost investors profit potential from stock price rises while put DW enable investors to benefit from sell-downs. Put DW are particularly useful as they are one of the few products in Thailand that allow retail investors to go ‘short’ (i.e. profit from downward moves) in stocks.",

    pageDescription1: "นอกจากนี้ Put DW ยังสามารถใช้ในรูปแบบของการป้องกันความเสี่ยงได้ในช่วงตลาดขาลง (หรือ Hedging) นักลงทุนที่ถือหุ้นอยู่ในพอร์ทและกังวลทิศทางในอนาคตของตลาดว่าจะเป็นขาลง สามารถซื้อ Put DW เพื่อทำกำไรในช่วงที่ราคาหุ้นลดลงได้ ซึ่งจะช่วยชดเชยความเสียหายที่เกิดขึ้น ทั้งนี้นักลงทุนควรเข้าใจความซับซ้อนของการใช้ DW เพื่อป้องกันความเสี่ยง",
    pageDescription1En: "Put DW can also be used as a form of insurance to protect an existing share portfolio against a falling market (a process called “hedging”). An investor with a holding in a particular stock who was nervous about the future direction of the market could purchase a put DW. If the share price falls, the gains made on the DW can be used to offset the losses on their shares.",

    pageDescription2: "",
    pageDescription2En: "This allows the investor to retain share ownership without having full exposure to the downside risks. Index DW are most typically used for hedging. Investors should, however, understand the complexity of utilising DW for hedging or portfolio protection purposes. For example, the value of the DW may not exactly correlate with value of the underlying shares.",

    imgUrl: "/img/education/img/profitfrombull.png",
    imgUrlEn: "/img/education/img/profitfrombull.png",
}