import React  from 'react';
import IndexDwPage from './IndexDW';
import './indexDw.css';


const IndexDwPages = (props) => {
    return ( 
        <IndexDwPage/>
     );
}
 
export default IndexDwPages;