import React, { useEffect, useState } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import ChildInvestorAcademyCommon from "../../../../../components/common/InvestorAcademyCommon/ChildInvestorAcademyCommon";
import "../../common.css";
import "../../investorAcademy.css";
import { languageText } from "../../../../../languageText/InvestorAcademy/SingleShareAndExpiry/putExample"
import { languageTextMain } from "../../../../../languageText/InvestorAcademy/index";

const CallExamples = (props) => {
  
  const location = useLocation();
  const history = useHistory();
  const [siteLang , setSiteLang] = useState("") 
  const [display , setDisplay] = useState("none") 

  // Site Language Change Start //
  useEffect(() => {
    window.scrollTo(0, 0);
    var siteLanguage = $.cookie('applang');
    setSiteLang(siteLanguage)
    if(siteLanguage == "eng"){
      document.querySelector("body").classList.add("eng")
    }else{
      document.querySelector("body").classList.remove("eng")
    }
  }, []);
  // Site Language Change End //

  // View Chart Image Popup Open And Close Start //
  const openPopUp = () => {
    if(display == "none"){
      setDisplay("block")
    }else{
      setDisplay("none")
    }
  }
  // View Chart Image Popup Open And Close End //

  return (
    <div id="investor_academy">
      <div className="imgbg" />
      <div className="mainbg" />
      <div id="main">
        <div id="maintable">
          <div style={{ display: "table-row" }}>
            <div id="leftbar" className="hidden-xs" />
            <div id="maincontainer">
              {/* Top Title Section Start */}
              <div id="mainnav">
                <table>
                  <tbody>
                    <tr>
                      <td width="20%" style={{textAlign:"left", visibility: "hidden"}} />
                      <td width="60%" style={{ textAlign: "center" }}>
                        <div id="pagetitlediv">
                          <div id="ptitle">{siteLang == "eng" ? languageText.pageTitleEn : languageText.pageTitle}</div>
                          <div id="pname">{siteLang == "eng" ? languageText.pageNameEn : languageText.pageName}</div>
                        </div>
                      </td>
                      <td width="20%" style={{textAlign:"right", visibility: "hidden"}} />
                    </tr>
                  </tbody>
                </table>
              </div>
              {/* Top Title Section End */}
              {/* Main Content Section Start */}
              <div id="maincontent">
                <div id="mainscroll">
                  <div id="mainscrollcontent">
                    <style dangerouslySetInnerHTML={{__html: ".formulabox{font-size:14px;}.exampleholder .examplebox .col{float:left;width:100%;margin:2px 0;}.exampleholder .examplebox  .lbl,.exampleholder .examplebox  .val{float:left;font-size: 14px; line-height: 1.42857143;}.exampleholder .examplebox  .lbl{color:#8F8F8F;}.formulaholder p span.leqt{color:#FFDA44;}.imgholder{margin-bottom:16px;}.imgholder img{max-width: 754px;}" }} />
                    <div className="contentbox">
                      <div className="imgholder">
                        <img src={siteLang == "eng" ? languageText.imgUrlEn : languageText.imgUrl} />
                        <div className="visible-xs">
                          <button onClick={openPopUp}>View calendar</button>
                        </div>
                      </div>
                      <div className="exampleholder">
                        <div className="examplebox">
                          <div className="col">
                            <div className="lbl">{siteLang == "eng" ? languageText.exampleHolderFirstLeftEn : languageText.exampleHolderFirstLeft}&nbsp;&nbsp;</div>
                            <div className="val">{siteLang == "eng" ? languageText.exampleHolderFirstRightEn : languageText.exampleHolderFirstRight}</div>
                          </div>
                          <div className="col">
                            <div className="lbl">{siteLang == "eng" ? languageText.exampleHolderSecondLeftEn : languageText.exampleHolderSecondLeft}&nbsp;&nbsp;</div>
                            <div className="val">{siteLang == "eng" ? languageText.exampleHolderSecondRightEn : languageText.exampleHolderSecondRight}</div>
                          </div>
                          <div className="col">
                            <div className="lbl">{siteLang == "eng" ? languageText.exampleHolderThirdLeftEn : languageText.exampleHolderThirdLeft}&nbsp;&nbsp;</div>
                            <div className="val">{siteLang == "eng" ? languageText.exampleHolderThirdRightEn : languageText.exampleHolderThirdRight}{siteLang == "eng" ? <sup>th</sup> : ""}</div>
                          </div>
                          <div className="col">
                            <div className="lbl">{siteLang == "eng" ? languageText.exampleHolderFourthLeftEn : languageText.exampleHolderFourthLeft}&nbsp;&nbsp;</div>
                            <div className="val">{siteLang == "eng" ? languageText.exampleHolderFourthRightEn : languageText.exampleHolderFourthRight}</div>
                          </div>
                        </div>
                      </div>
                      <p>{siteLang == "eng" ? languageText.pageDescriptionEn : languageText.pageDescription}</p>
                      <div className="formulaholder">
                        <div className="formulabox">
                          <div className="leqt">
                            <table cellSpacing={0} cellPadding={0}>
                              <tbody>
                                <tr>
                                  <td style={{height: '39px', color: '#FFF'}}>    
                                    <b>{siteLang == "eng" ? languageText.formulaHolderLeftEn : languageText.formulaHolderLeft} </b>{siteLang == "eng" ? "would be" : ""} :
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>   
                          <div className="reqt">
                            <table className="fraction" cellSpacing={0} cellPadding={0}>
                              <tbody>
                                <tr><td>(<font color="#FFD843">{siteLang == "eng" ? languageText.formulaHolderCenterFirstEn : languageText.formulaHolderCenterFirst}</font>)</td></tr>
                                <tr><td className="upper_line"><font color="#FFD843">{siteLang == "eng" ? languageText.formulaHolderCenterSecondEn : languageText.formulaHolderCenterSecond}</font></td></tr>
                              </tbody>  
                            </table>
                          </div>
                          <div className="reqt">
                            <table cellSpacing={0} cellPadding={0}>
                              <tbody>
                                <tr><td style={{height: '39px', color: '#FFF'}}> = <b>{siteLang == "eng" ? languageText.formulaHolderRightEn : languageText.formulaHolderRight}</b></td></tr>
                              </tbody>  
                            </table>
                          </div>
                        </div>
                      </div>
                      <p>{siteLang == "eng" ? languageText.pageDescription1En : languageText.pageDescription1}</p>
                    </div>
                  </div>
                </div>
              </div>
              {/* Main Content Section End */}
            </div>
            <div id="rightbar" className="hidden-xs" />
          </div>
        </div>
      </div>
      {/* Bottom Left Right Links and Copyright Section Start */}
      <div id="bottom">
        <div className="hidden-xs hidden-xxs" id="copyright">
          {siteLang == "eng" ? languageTextMain.copyRightEn : languageTextMain.copyRight}
        </div>
        <div className="leftUrl">
          <table className="hasprev">
            <tbody>
              <tr>
                <td width="33px">
                  <Link to="/eduhtml/singlesharecallwarrantexample">
                    <div className="prev left" />
                  </Link>
                </td>
                <td>
                  <div className="prevlbl left">
                    <div className="prevname">
                      <Link to="/eduhtml/singlesharecallwarrantexample">
                        {siteLang == "eng" ? languageTextMain.singleShareCallWarrantExampleEn : languageTextMain.singleShareCallWarrantExample}
                      </Link>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="rightUrl">
          <table className="hasnext">
            <tbody>
              <tr>
                <td>
                  <div className="nextlbl right">
                    <div className="nextname">
                      <Link to="/eduhtml/risk">
                        {siteLang == "eng" ? languageTextMain.riskEn : languageTextMain.risk}
                      </Link>
                    </div>
                  </div>
                </td>
                <td width="33px">
                  <Link to="/eduhtml/risk">
                    <div className="next right" />
                  </Link>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      {/* Bottom Left Right Links and Copyright Section End */}
      {/* Right Dots Menu Component Section Start */}
      <div id="dotbar" className="hidden-sm hidden-xs">
        <ChildInvestorAcademyCommon Location={location} Hist={history} />
      </div>
      {/* Right Dots Menu Component Section End  */}
      {/* View Chart Popup In Mobile Start  */}
      <div id="imgpopup" style={{display: display}}>
        <div id="closebtn2" onClick={openPopUp} />
        <div className="imgHold">
          <img src={siteLang == "eng" ? languageText.imgUrlEn : languageText.imgUrl} className="landscape" />
        </div>
      </div>
      {/* View Chart Popup In Mobile End  */}
    </div>
  );
};
export default CallExamples;
