    
import React, { Fragment } from 'react'
import ActivityData from './ActivityData'

const Activitydata = () => {
  return (
    <Fragment><ActivityData/></Fragment>
  )
  }

export  default Activitydata