import React, { useState, useEffect,Fragment } from 'react';
import UsefulWarrantTerms from './UsefulWarrantTerms';
import "../common.css";
import "../investorAcademy.css" 


const UsefulWarrantTerm = (props) => {
    return ( 
        <Fragment> 
        <UsefulWarrantTerms/>
        </Fragment> 
     );
}


export default UsefulWarrantTerm;