import React, { useState, useEffect} from 'react';
import { Speaker } from 'react-bootstrap-icons';
import {Carousel} from 'react-responsive-carousel';
import { educationList } from "./EducationData"

const EducationCarousel = () => {
    
    
    const [siteLang , setSiteLang] = useState("")
    const itemsPerRow = 3; // Number of items per row
    const itemsPerTable = 3; // Number of items per table
    const tableRows = [];
    

    useEffect(() => {
      var siteLanguage = $.cookie('applang');
      setSiteLang(siteLanguage)
      if(siteLanguage == "eng"){
        document.querySelector("body").classList.add("eng")
      }else{
        document.querySelector("body").classList.remove("eng")
      }
    }, []);

    const handleVideoItemClick = (playlistId, index) => {
      window.open(
        `/education/dwvideoind?playlistId=${playlistId}&index=${index}`,
        '_blank'
      );
    };

    for (let i = 0; i < educationList.length; i += itemsPerTable) {
        const tableItems = [];
        for (let j = i; j < i + itemsPerTable && j < educationList.length; j += itemsPerRow) {
          const rowItems = [];
          for (let k = j; k < j + itemsPerRow && k < educationList.length; k++) {
            const speaker = educationList[k];
            rowItems.push(
              <td key={speaker.title}>
                <div className="videorack-item" playlistid={speaker.playlistId} index="0" onClick={() => handleVideoItemClick(speaker.playlistId, "0")}>
                  <img style={{ width: "100%" }} src={speaker.image} />
                  <div className="videorack-item-txt">
                    {siteLang == "eng" ? speaker.en_title : speaker.th_title}
                  </div>
                </div>
              </td>
            );
          }
          tableItems.push(
            <tr key={j}>
                {rowItems}
            </tr>
          );
        }
        tableRows.push(
            <div key={i} style={{ width: "1030px", marginRight: "20px" }} className="slide-tab">
              <table className={`videorack-page ${i > 0 ? 'slide-tab' : ''}`}>
                <tbody>
                  {tableItems}
                </tbody>
              </table>
            </div>
        );
    }

    return (
        <Carousel
        showArrows={true}
        emulateTouch
        showStatus={false}
        autoFocus
        infiniteLoop={false}
        showIndicators={false}
        showThumbs={false}
        >
            {tableRows}
        </Carousel>
    );
};

export default EducationCarousel;