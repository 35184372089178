export const languageText ={
    
    firstNavigation: "รอบรู้เรื่อง DW ",
    firstNavigationEn: "DW education ",
    secondNavigation: " วิดีโอความรู้เรื่อง DW",
    secondNavigationEn: " DW video",
    thirdNavigation: " Education videos",
    thirdNavigationEn: " Education videos",

    pageTitle: "วิดีโอความรู้เรื่อง DW",
    pageTitleEn: "DW video",
    pageDescription: "VDO ง่ายๆ ที่จะช่วยเพิ่มความรู้เกี่ยวกับ DW ให้คุณ",
    pageDescriptionEn: "Improve your knowledge of DW in these videos.",
    
    marketDelay: "ข้อมูลตลาดทั้งหมดล่าช้าประมาณ 15 นาที",
    marketDelayEn: "All market data is delayed 15 mins.",
}