export const languageText ={

    // Page Top Heading And Description Start //
    pageTitle: "ความเสี่ยง",
    pageTitleEn: "Risk",

    pageDescription: "ในที่นี้ เป็นเพียงข้อมูลสรุปความเสี่ยงจากการลงทุนใน DW นักลงทุนควรศึกษาข้อมูลความเสี่ยงอื่นๆที่ระบุไว้ในเอกสารการออกและเสนอขาย หรือร่างหนังสือชี้ชวน และเอกสารเพิ่มเติมที่เกี่ยวข้องของ DW<br><br> DW มีความเสี่ยงเหมือนกับการลงทุนทั่วไป ซึ่งรวมถึง",
    pageDescriptionEn: "This is brief summary only of the risks of investing in DW. Potential investors should refer to the Risk Factors section set out in the Base Listing Document and the relevant Supplemental Listing Document<br><br> Like any investment, DW can also have a downside. These include:",
    // Page Top Heading And Description End //

    // Hexa Box Content Start //
    marketRisk: "ความเสี่ยงด้านตลาด (Market Risk)",
    marketRiskEn: "Market Risk",

    riskGearing: "ความเสี่ยงจาก (Gearing)",
    riskGearingEn: "Gearing",

    limitedLife: "อายุจำกัด",
    limitedLife1: " (Limited Life)",
    limitedLifeEn: "Limited Life",

    creditRisk: "Credit Risk",
    creditRiskEn: "Credit Risk",

    takeOvers: "การควบรวมกิจการ",
    takeOversEn: "Takeovers",
    // Hexa Box Content End //
}