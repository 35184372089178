export const languageText ={

    // Page Top Heading And Description Start //
    pageTitle: "วิธีการเลือก DW",
    pageTitleEn: "How to select a DW?",

    pageDescription: "สามารถคลิกหัวข้อเพื่ออ่านข้อมูลเพิ่มเติม",
    pageDescriptionEn: "Please click on each of the tiles below for more details.",
    // Page Top Heading And Description End //

    // Hexa Box Content Start //
    selectingWarrant: "Selecting a ",
    selectingWarrant1: " warrant",
    selectingWarrantEn: "Selecting a ",
    selectingWarrant1En: " warrant",

    marketMaker: "คุณภาพของผู้ดูแลสภาพคล่อง",
    marketMakerEn: "Market Maker",

    riskMatrix: "Matrix ความเสี่ยง",
    riskMatrixEn: "Risk Matrix",

    tipsTool: "เคล็ดลับและเครื่องมือ",
    tipsToolEn: "Tips and ",
    tipsTool1En: " tools",
    // Hexa Box Content End //
}