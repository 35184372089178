export const languageText ={

    pageTitle: "วิธีการเลือก DW",
    pageTitleEn: "How to select a DW?",

    pageName: "Matrix ความเสี่ยง",
    pageNameEn: "Risk Matrix",

    pageDescription: "",
    pageDescriptionEn: "The chart summarizes how the expiry date of a DW and its gearing level affects the risk level of the DW.",

    imgUrl: "/img/education/img/riskmatrix_th.png",
    imgUrlEn: "/img/education/img/riskmatrix.png",
    
    pageDescription1: "โดยทั่วไป DW ที่มีอายุสั้นจะมีความเสี่ยงสูง เพราะเวลาที่เหลือน้อยทำให้โอกาสในการที่ราคาหุ้นจะไปถึงราคาเป้าหมายที่คุณตั้งไว้เหลือน้อยตามไปด้วย",
    pageDescription1En: "Generally, the <b>shorter dated</b> the DW, <b>the higher the risk</b> of the DW as there is less time remaining to reach your target price.",

    pageDescription2: "โดยทั่วไป DW ที่มี Gearing สูง จะมีความเสี่ยงสูงขึ้น แม้โอกาสได้รับผลตอบแทนจะสูงขึ้น แต่ถ้ามุมมองการลงทุนของคุณผิด คุณก็มีโอกาสที่จะขาดทุนสูงขึ้นเช่นกัน",
    pageDescription2En: 'DW with <b>higher gearing</b> also carry <b>higher risk</b> as they require a larger movement in the underlying price for the DW to have value remaining at expiry, indicated by <b>"out-of-the-money (OTM)"</b> in the matrix.',

    pageDescription3: "DW ที่มีสถานะ Out-of-the-money (OTM) จะมีความเสี่ยงสูง เพราะราคาหุ้นอ้างอิงจะต้องเคลื่อนไหวสูงมาก เพื่อทำให้ DW ที่มีมูลค่าคงเหลือในวันครบกำหนดอายุ",
    pageDescription3En: '',

    pageDescription4: "DW ที่มีอายุคงเหลือมาก และมีสถานะ In-the-money (ITM) ในแผนภูมิ โดยทั่วไปจะมีความเสี่ยงต่ำ DW ที่มีอายุคงเหลือมาก ทำให้มีเวลาที่ราคาหุ้นอ้างอิงอาจจะเคลื่อนไปถึงราคาเป้าหมาย และมีอัตรา Time Decay น้อยกว่า ทั้งนี้ DW ที่มีสถานเป็น ITM จะมีระดับ Gearing ต่ำกว่าด้วย",
    pageDescription4En: 'DW with <b>longer time to expiry</b> and those that are deeper <b>"in-the-money (ITM)"</b> in the matrix are generally regarded as <b>lower risk</b>. DW that have further expiry dates allow more time for stocks to reach the target price and hence less time decay, while ITM DW have lower gearing levels.',

    pageDescription5: "อย่าลืมว่า ความเสี่ยงสูงกว่า ย่อมมีโอกาสได้รับผลตอบแทนสูงกว่าด้วยเสมอ",
    pageDescription5En: 'It should be remembered that the higher the risk typically translates to a higher potential return and vice versa.',
    
}