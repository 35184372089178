export const languageText ={

    pageTitle: "ราคา DW คำนวณอย่างไร",
    pageTitleEn: "How are DW priced?",

    pageDescription: "มูลค่าของ DW กำหนดขึ้นมาจาก 6 ตัวแปร กรุณาคลิกแต่ละหัวข้อ เพื่ออ่านคำอธิบาย",
    pageDescriptionEn: "The value of a DW is determined by six variables. Please click on each of the below for a brief description..",

    // Hexa Box Content Start
    stockPrice: "ราคาหุ้นอ้างอิง",
    stockPriceEn: "Stock Price",

    exercisePrice: "ราคาใช้สิทธิ",
    exercisePriceEn: "Exercise Price",

    expiryDate: "วันครบกำหนดอายุ",
    expiryDateEn: "Expiry Date",

    volatility: "ความผันผวน",
    volatilityEn: "Volatility",

    interestRates: "อัตราดอกเบี้ย",
    interestRatesEn: "Interest Rates",
    // Hexa Box Content End

    tipHolderTitle: "เคล็ดลับ",
    tipHolderTitleEn: "Helpful Tips",

    tipHolderDescription: "เมื่อเลือก DW ที่เหมาะสมกับตัวคุณแล้ว นักลงทุนควรคำนึงถึงปัจจัยที่เกี่ยวข้อง 4 ตัวแรกมากที่สุด เพราะปัจจัยที่เหลือ คือ เงินปันผล และอัตราดอกเบี้ย ไม่ส่งผลกระทบต่อราคาของ DW มากเท่าไหร่ และมีความสำคัญน้อยกว่า",
    tipHolderDescriptionEn: "When choosing an appropriate warrant, investors should be more concerned with the first four factors. Generally, the last two factors – dividends and interest rates – do not have a large effect on the DW price and are less important.",

    tipHolderDescription1: "หากต้องการดูว่า การเปลี่ยนแปลงของตัวแปรต่างๆมีผลต่อราคา DW อย่างไร คุณควรใช้เครื่องมือคำนวณราคา DW ของแมคควอรี",
    tipHolderDescription1En: "To see how changes in the various factors will effect a DW price, you can use the Macquarie's DW calculator,",

    tipHolderLink: " คลิกที่นี่",
    tipHolderLinkEn: " click here",

    tipHolderDescription2: "ผู้ถือ DW จะไม่ได้รับเงินปันผล หรือสิทธิประโยชน์โดยตรง แต่ก็ไม่ได้สูญเสียสิทธิประโยชน์เหล่านี้ เพราะสิทธิต่างๆ (เช่น เงินปันผล การแบ่งหุ้น การอออกหุ้นใหม่ และอื่นๆ) จะถูกคำนวณรวมในการปรับสิทธิของ DW ไว้แล้ว",
    tipHolderDescription2En: "DW holders do not receive dividends or corporate actions directly, however will not be disadvantaged in this respect, as all corporate actions(eg. dividends, share splits, rights issues, etc...) will be accounted for by adjusting the DW terms accordingly.",
}