export const languageText ={

    pageTitle: "DW ณ วันครบกำหนดอายุ",
    pageTitleEn: "DW at expiry",

    pageName: "ตัวอย่าง Call DW",
    pageNameEn: "Call DW Example",

    imgUrl: "/img/education/img/calendar_th.png",
    imgUrlEn: "/img/education/img/calendar.png",

    exampleHolderFirstLeft: "ราคาใช้สิทธิของ Call DW ที่อ้างอิงบนหุ้น ABC:",
    exampleHolderFirstLeftEn: "Exercise Price of ABC Call Warrant:",
    exampleHolderFirstRight: "บาท 1.00",
    exampleHolderFirstRightEn: "THB 1.00",
    exampleHolderSecondLeft: "DWPS:",
    exampleHolderSecondLeftEn: "DWPS:",
    exampleHolderSecondRight: "1",
    exampleHolderSecondRightEn: "1",
    exampleHolderThirdLeft: "วันครบกำหนดอายุ:",
    exampleHolderThirdLeftEn: "Expiry Date:",
    exampleHolderThirdRight: "วันที่ 9",
    exampleHolderThirdRightEn: "9",
    exampleHolderFourthLeft: "ราคาปิดของหุ้น ABC ณ วันทำการซื้อขายสุดท้าย:",
    exampleHolderFourthLeftEn: "Closing Price of ABC Shares on the last trading date:",
    exampleHolderFourthRight: "บาท 1.30",
    exampleHolderFourthRightEn: "THB 1.30",

    pageDescription: 'เนื่องจากราคาปิดของหุ้น ABC ณ วันซื้อขายสุดท้าย สูงกว่าราคาใช้สิทธิของ Call DW ดังนั้น DW หมดอายุโดยมีสถานะเป็น In-the-money',
    pageDescriptionEn: 'Since the closing price of ABC shares is higher than the exercise price of the call, the DW expires ‘in the money’.',

    formulaHolderLeft: "จำนวนเงินสดส่วนต่าง",
    formulaHolderLeftEn: "Cash Settlement Amount",
    formulaHolderCenterFirst: "1.30 บาท - 1.00 บาท",
    formulaHolderCenterFirstEn: "THB 1.30 - THB 1.00",
    formulaHolderCenterSecond: "1",
    formulaHolderCenterSecondEn: "1",
    formulaHolderRight: "0.30 บาท",
    formulaHolderRightEn: "THB 0.30",
    
    pageDescription1: 'ถ้าราคาปิดของหุ้นอ้างอิง ณ วันทำการซื้อขายสุดท้ายเป็น 1.00 บาท (At the money) หรือต่ำกว่า (Out of the money) จะไม่มีเงินสดส่วนต่างเกิดขึ้น',
    pageDescription1En: 'If the closing price of the last trading date was THB 1.00 (‘at the money’) or less (‘out of money’), there will be no cash settlement.',
}