export const languageText ={

    pageTitle: "ราคา DW คำนวณอย่างไร",
    pageTitleEn: "How are DW priced?",

    pageName: "Time Decay",
    pageNameEn: "Time Decay",

    pageDescription: "ในส่วนมูลค่าเวลาของ DW จะค่อยๆลดลงตลอดอายุของ DW ซึ่งเรียกว่า “Time Decay” หรืออาจเรียกอีกอย่างหนึ่งว่า “Theta” เว็บไซต์แมคควอรี มีเครื่องมือในการช่วยประมาณการค่า Theta ของ DW แต่ละตัว ในหน้า DW Term ค่า Theta เป็นค่าไม่คงที่ และจะสูงขึ้นเมื่อ DW ใกล้จะหมดอายุ โดยทั่วไป มูลค่าเวลาจะลดลง 1 ใน 3 ส่วน ใช่วง 2 ใน 3 ส่วนแรกของอายุ DW",
    pageDescriptionEn: "The time value portion of a DW’s price will slowly reduce throughout the life of the DW, This is called ‘time decay’. Another term for time decay is ‘theta’, we provide an estimate for the theta of each DW on the DW terms page. The theta of a DW is not constant and will speed up as the DW approaches its expiry. A general rule of thumb is that the time value will decay 1/3 of its value in the first 2/3's of its life.",

    imgUrl: "/img/education/img/timedecay_th.png",
    imgUrlEn: "/img/education/img/timedecay.png",
}