export const languageText ={

    pageTitle: "ราคา DW คำนวณอย่างไร",
    pageTitleEn: "How are DW priced?",

    pageName: "อัตราดอกเบี้ย",
    pageNameEn: "Interest Rates",

    pageDescription: "อัตราดอกเบี้ยส่งผลกระทบกับราคา DW ผ่านทางต้นทุนทางการเงิน โดยทั่วไป เมื่อคุณซื้อ Call DW ผู้ออกจะต้องบริหารความเสี่ยงโดยการซื้อหุ้นอ้างอิง ซึ่งทำให้มีต้นทุนเกิดขึ้น และต้นทุนเหล่านี้ก็จะสูงขึ้นถ้าอัตราดอกเบี้ยเพิ่มสูงขึ้น และทำให้ราคา DW เพิ่มสูงขึ้นด้วย แต่ในทางกลับกัน จะทำให้ราคา Put DW ลดลง",
    pageDescriptionEn: "Interest rates will affect the price of a DW through financial holding costs. Typically, when you buy a call DW, issuers will hedge their position by buying the underlying share. This requires funding and, as such, when the cost of borrowing goes up as a result of rising interest rates, the price of a call DW will also go up. The inverse is true for a put DW.",

    imgUrl: "/img/education/img/ir.png",
    imgUrlEn: "/img/education/img/ir.png",
}