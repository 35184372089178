import React, { useState, useEffect,Fragment } from 'react';
import ProfitBullBears from './ProfitBullBears';
import "../../common.css";
import "../../investorAcademy.css";


const ProfitBullBear = (props) => {
    return ( 
        <Fragment> 
        <ProfitBullBears/>
        </Fragment> 
     );
}


export default ProfitBullBear;