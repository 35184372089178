export const languageText ={

    pageTitle: "DW คืออะไร?",
    pageTitleEn: "What are DW?",

    pageName: "Put DW ทำงานอย่างไร",
    pageNameEn: "How a Put DW works",

    h2Heading: "ตัวอย่างสมมติของ Put DW",
    h2HeadingEn: "Hypothetical example of a put DW",

    pageDescription: "ถ้าคุณต้องการทำกำไรจากราคาหุ้นที่ที่ปรับตัวลดลงหรือกลัวการขาดทุนจากพอร์ตลงทุนที่มีอยู่จากการปรับฐานระยะสั้น Put DW อาจจะเป็นเครื่องมือที่คุณมองหาอยู่ก็ได้",
    pageDescriptionEn: "If you are wanting to profit from downward price movements or are fearful of losses on your existing portfolio from short-term corrections, put DW may be the tool you are looking for.",

    pageDescription1: "ตัวอย่างด้านล่างนี้ใช้ Put DW ที่อ้างอิงกับหุ้น IVL คุณจะเห็นว่าราคา Put DW ซึ่งแสดงไว้ในเส้นสีฟ้า เคลื่อนไหวในทิศทางตรงกันข้ามกับ ราคาหุ้น IVL ซึ่งแสดงไว้ในเส้นสีแดง",
    pageDescription1En: "In our example below using a put DW over IVL, you can see that the put DW, denoted by the blue line, moves in inverse directions with the IVL share price which is denoted by the red line.",
    
    pageDescription2: "",
    pageDescription2En: "If you have a view that the IVL stock had moved too high and was due to fall, you could purchase a put DW over IVL to profit form your view.",

    imgUrl: "/img/education/img/howaputwarrantworks_th.png",
    imgUrlEn: "/img/education/img/howaputwarrantworks.png",
    
    pageDescription3: "ถ้าคุณมองว่าราคาหุ้น IVL เริ่มสูงเกินไป และกำลังจะปรับตัวลดลง คุณควรจะซื้อ Put DW ที่อ้างอิงหุ้น IVL เพื่อทำกำไรจากมุมมองดังกล่าว",
    pageDescription3En: "In our example, the IVL stock took a 15.3% tumble over one month period. If you had purchased the IVL put DW at THB 0.82 when the share price was at THB 55.50, you could then sell the DW one month later for THB 1.35 when the share price traded at THB 47.00 earning a profit of 64.6%.",

    pageDescription4: "",
    pageDescription4En: 'If you were holding share in IVL, the gains that you made on the IVL put DW could be used to offset the losses you had on your shares, this strategy is called "hedging".',

    pageDescription5: "จากตัวอย่างข้างต้น ราคาหุ้น IVL ลดลง 15.3% ภายในระยะเวลา 1 เดือน ถ้าคุณซื้อ Put DW ที่อ้างอิงกับหุ้น IVL ไว้ที่ราคา 0.82 บาท เมื่อราคาหุ้น IVL เท่ากับ 55.50 บาท คุณสามารถขาย Put DW ที่อ้างอิงกับหุ้น IVL ได้ในหนึ่งเดือนต่อมา ที่ราคา 1.35 บาท ในขณะที่ราคาหุ้น IVL เท่ากับ 47 บาท ซึ่งจะได้กำไรถึง 64.6%",
    pageDescription5En: '',

    pageDescription6: "ถ้าคุณกำลังถือหุ้น IVL ไว้ กำไรจาก Put DW ที่อ้างอิงหุ้น IVL สามารถชดเชยการขาดทุนจากการถือหุ้น IVL นั้นได้ ซึ่งเรียกกลยุทธ์แบบนี้ว่า การป้องกันความเสี่ยง (Hedging)",
    pageDescription6En: '',

    firstTrTd: "หุ้น IVL",
    firstTrTdEn: "IVL Share",
    firstTrTd1: "IVL Put DW",
    firstTrTd1En: "IVL Put DW",

    secondTrTd: "25 เม.ย.",
    secondTrTdEn: "25 April",
    secondTrTd1: "55.50 บาท",
    secondTrTd1En: "THB 55.50",
    secondTrTd2: "0.82 บาท",
    secondTrTd2En: "THB 0.82",

    thirdTrTd: "25 พ.ค.",
    thirdTrTdEn: "25 May",
    thirdTrTd1: "47.00 บาท",
    thirdTrTd1En: "THB 47.00",
    thirdTrTd2: "1.35 บาท",
    thirdTrTd2En: "THB 1.35",

    fourthTrTd: "กำไร",
    fourthTrTdEn: "Profit",
    fourthTrTd1: "-15.3%",
    fourthTrTd1En: "-15.3%",
    fourthTrTd2: "+64.6%",
    fourthTrTd2En: "+64.6%",
}