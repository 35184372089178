export const languageText ={

    pageTitle: "ทำไมต้องลงทุนใน DW?",
    pageTitleEn: "Why Trade DW?",

    pageName: "ซื้อง่ายขายคล่อง",
    pageNameEn: "Ease of trade",

    pageDescription: "DW ซื้อขายในตลาดหลักทรัพย์ (SET) ซึ่งนักลงทุนสามารถซื้อขาย DW ได้เหมือนหุ้นทั่วไป และเนื่องจาก DW มีผู้ดูแลสภาพคล่อง (Market Maker) DW จึงมีสภาพคล่องสูง ซึ่งหมายถึงมีปริมาณซื้อขายบน Bid-Offer เพียงพอที่จะทำให้นักลงทุนเข้าซื้อหรือขายได้ง่าย",
    pageDescriptionEn: "DW are traded on the SET allowing investors to buy and sell DW just like shares. Due to the existence of designated market makers, DW are typically very liquid meaning there should be sufficient volume on the bid and offer for investors to easily enter and exit their trade.",

    imgUrl: "/img/education/img/easeoftrade_th.png",
    imgUrlEn: "/img/education/img/easeoftrade.png",
}