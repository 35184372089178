import React, { useState, useEffect,Fragment } from 'react';
import CallPuts from './CallPuts';
import "../../common.css";
import "../../investorAcademy.css";


const CallPut = (props) => {
    return ( 
        <Fragment> 
            <CallPuts/>
        </Fragment> 
     );
}


export default CallPut;