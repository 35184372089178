export const languageText ={

    pageTitle: "ความเสี่ยง",
    pageTitleEn: "Risk",

    pageName: "ความเสี่ยงจาก (Gearing)",
    pageNameEn: "Gearing",

    pageDescription: '<a href="/eduhtml/gearing">Gearing</a> เป็นสิ่งที่ดึงดูดความสนใจลงทุนอย่างแรกของ DW แต่ก็สามารถเป็นดาบสองคมได้ด้วย<br><br> กล่าวคือ DW จะมีแนวโน้มที่จะแกว่งตัวเพิ่มขึ้นและลดลงในอัตรา (%) ที่สูงกว่าหุ้นอ้างอิง <br><br>',
    pageDescriptionEn: "<a href='/eduhtml/gearing'>Gearing</a>, the primary attraction of DW, can be a double-edged sword.<br><br> A DW will tend to appreciate and depreciate in percentage terms more rapidly than the underlying shares/index futures. <br><br>",

    imgUrl: "/img/education/img/riskgearing.png",
    imgUrlEn: "/img/education/img/riskgearing.png",
}