import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import API from "../../../utils/apiCalling";
import "./whyMacquarie.css";
import { config } from "../../../utils/apiUrl";
import { languageText } from "../../../languageText/whyMacquarie"

const WhyMacquaries = (props) => {

  const api = new API();
  const [fastTrack, setfastTrack] = useState([]);
  const [displayBlock, setDisplayBlock] = useState("none");
  const [displayBlock1, setDisplayBlock1] = useState("block");
  const [xs, setXs] = useState(true);
  const [siteLang , setSiteLang] = useState("")
  
  useEffect(() => {
    window.scrollTo(0, 0);
    var siteLanguage = $.cookie('applang');
    setSiteLang(siteLanguage)
    if(siteLanguage == "eng"){
      document.querySelector("body").classList.add("eng")
    }else{
      document.querySelector("body").classList.remove("eng")
    }
    getFastTrack();
  }, []);

  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent);
  }, [displayBlock]);

  const getFastTrack = async () => {
    let fastTrack = await api.get(config.marketfastTrack, true, {
      qid: Date.now(),
    });
    // let fastTrackt = get(fastTrack, 'newsList', [])
    const { stock } = fastTrack.fastTrack;
    setfastTrack(stock);
  };

  const listenScrollEvent = () => {
    if(window.scrollY > 150 && xs === true){
      setDisplayBlock("block")
      setDisplayBlock1("none")
    }else{
      setDisplayBlock("none")
      setDisplayBlock1("block")
    }
  };

  const handleClose = () => {
    setDisplayBlock("none");
    setDisplayBlock1("block")
    setXs(false);
  };

  return (
    <div id="whydw28" className="pageobj">
      <div id="bar-subscribe" className={`subscribe_area`} style={{ display: `${displayBlock}` }}>
        <img
          className="btn-close"
          src="/img/bullet/icon-close-white.png"
          onClick={handleClose}
        />
        <table>
          <tbody>
            <tr>
              <td>
                <div className="description">
                  {siteLang == "eng" ? languageText.subscribeAreaDescriptionEn : languageText.subscribeAreaDescription}
                </div>
                <Link to="/marketnews/subscribe">
                  <div id="btn-subscribe" className="color-primary">
                    {siteLang == "eng" ? languageText.subscribeAreaButtonEn : languageText.subscribeAreaButton}
                  </div>
                </Link>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="breadcrumbbar">
        <div className="container-fluid">
          <ol className="breadcrumb">
            <li>
              <Link to="/home?dwedu">{siteLang == "eng" ? languageText.firstNavigationEn : languageText.firstNavigation}</Link>
            </li>
            <li className="active">
              {siteLang == "eng" ? languageText.secondNavigationEn : languageText.secondNavigation}
            </li>
          </ol>
        </div>
      </div>
      <div className="stripe-bg"/>
      <div className="container-fluid img_cont_set">
        <div style={{ backgroundColor: "#fff" }}>
          <div id="area_1" className="tablearea img_dire">
            <table className style={{ width: "100%" }}>
              <tbody>
                <tr>
                  <td className="td-text" style={{ paddingRight: "40px", width: "30%", paddingLeft: "0px", }}>
                    <img alt="whydw28" src="/img/whydw28/Text1.png" />
                  </td>
                  <td className="td-video" style={{ width: "70%" }}>
                    <iframe
                      style={{ width: "100%", height: "100%" }}
                      frameBorder={0}
                      src="https://www.youtube.com/embed/2-hCyBBhdMA?autoplay=0&rel=0"
                      allow="autoplay; fullscreen"
                      id="animatedVideo"
                    />
                  </td>
                </tr>
              </tbody>
            </table>
            <div className="subscribe_area" style={{ display: `${displayBlock1}` }}>
              <table>
                <tbody>
                  <tr>
                    <td>
                      <div className="description">
                        {siteLang == "eng" ? languageText.subscribeAreaDescriptionEn : languageText.subscribeAreaDescription}
                      </div>
                      <Link to="/marketnews/subscribe">
                        <div id="btn-subscribe" className="color-primary">
                          {siteLang == "eng" ? languageText.subscribeAreaButtonEn : languageText.subscribeAreaButton}
                        </div>
                      </Link>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div id="area_2" className="tablearea img_dire">
            <table className style={{ width: "100%" }}>
              <tbody>
                <tr>
                  <td className="td-video" style={{ width: "70%" }}>
                    <iframe
                      style={{ width: "100%", height: "100%" }}
                      frameBorder={0}
                      src="https://www.youtube.com/embed/z-Ef4NCbi98?autoplay=0&rel=0"
                      allow="autoplay; fullscreen"
                      id="animatedVideo"
                    />
                  </td>
                  <td className="td-text hidden-xs" style={{ paddingLeft: "40px", paddingRight: "0px" }} >
                    <div className="Text2">
                      DW28
                      <p className="title-2">
                        "เติมไว...
                        <br />
                        ไม่หน่วง"
                      </p>
                      <p>นักลงทุนสามารถซื้อขาย DW28</p>
                      <p>ได้สูงสุดกว่า 1 ล้านหน่วย ภายใน 1 วินาที</p>
                      <p> โดยระบบจะเติมและ Match ให้อัตโนมัติ</p>
                      <p>
                        อยากรู้ว่าเร็วยังไง?{" "}
                        <Link to="/tools/dwsearch?indicator=HOTLIST">
                          คลิกเลย!
                        </Link>
                      </p>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <div id="area_3">
              <table style={{ width: "100%" }}>
                <tbody>
                  <tr>
                    <td className="td-text hidden-xs" style={{ paddingRight: "20px", verticalAlign: "top", paddingLeft: "0px", }} >
                      <div className="inner_title color-primary">
                        <img alt="whydw28" src="/img/whydw28/Text3.png" style={{ maxWidth: "400px" }} />
                      </div>
                      <div className="inner_content" dangerouslySetInnerHTML={{__html: siteLang == "eng" ? languageText.area3LeftContentEn : languageText.area3LeftContent}} /> 
                    </td>
                    <td style={{ width: "50%" }}>
                      <div id="hotlist" style={{ backgroundColor: "#000", marginTop: "0px" }} className="section" >
                        <div className="page-header">
                          <h1 className="small justify" style={{ color: "#FECC0A" }} >
                            <img alt="whydw28" src="/img/whydw28/fasttrack.png" style={{ width: "66px" }} />
                            {siteLang == "eng" ? languageText.area3FastTrackTitleEn : languageText.area3FastTrackTitle}
                          </h1>
                        </div>
                        <p style={{ color: "#fff", margin: "10px 0 10px 0", padding: 0,  }} >
                          {siteLang == "eng" ? languageText.area3FastTrackDescriptionEn : languageText.area3FastTrackDescription}
                          <Link to="/education/whydw28" style={{ color: "red" }} >
                            {siteLang == "eng" ? languageText.area3FastTrackDescriptionLinkEn : languageText.area3FastTrackDescriptionLink}
                          </Link>
                        </p>
                        <div className="hotlist-table">
                          <table id="resulttable-lg" className="table-striped table-fixed table-responsive" >
                            <thead>
                              <tr className="result-hdr2" style={{ height: "55px" }} >
                                <th className="bgcolor-06 text-center">
                                  <span data-target="dwsymbol">
                                    {siteLang == "eng" ? languageText.area3TableSymbolEn : languageText.area3TableSymbol}
                                  </span>
                                </th>
                                <th className="bgcolor-06 text-center">
                                  <span data-target="bid">
                                    {siteLang == "eng" ? languageText.area3TableBidEn : languageText.area3TableBid}
                                    <br />
                                    {siteLang == "eng" ? languageText.area3TableBid1En : languageText.area3TableBid1}
                                  </span>
                                </th>
                                <th className="bgcolor-06 text-center">
                                  <span data-target="effectivegearing">
                                    {siteLang == "eng" ? languageText.area3TableGearingEn : languageText.area3TableGearing}
                                    <br />
                                    (x)
                                  </span>
                                </th>
                                <th className="bgcolor-06 text-center" style={{ border: "none" }} >
                                  <span data-target="sensitivity">
                                    {siteLang == "eng" ? languageText.area3TableSensivityEn : languageText.area3TableSensivity}
                                  </span>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {fastTrack && fastTrack.length > 0 && fastTrack.map(
                                ({ ticker, BID, effective_gearing, sensitivity }, index ) => {
                                  return (
                                    <tr className="hotlist_rowtmpl" key={index}>
                                      <td className="dwSymbol">
                                        <Link to={`/tools/livematrix/${ticker}`}>{ticker}</Link>
                                      </td>
                                      <td className="bidPrice">{BID}</td>
                                      <td className="effectiveGearing"> {effective_gearing} </td>
                                      <td className="sensitivity"> {sensitivity} </td>
                                    </tr>
                                  );
                                }
                              )}
                              {fastTrack && fastTrack.length <= 0 &&
                                <tr className="emptymsg">
                                  <td colSpan="4">No data</td>
                                </tr>
                              }
                            </tbody>
                          </table>
                        </div>
                        <div className="text-right" style={{ width: "100%" }}>
                          <Link
                            id="viewmore"
                            type="button"
                            className="btn btn-primary viewmorebt"
                            style={{ marginTop: "1rem", minWidth: "auto" }}
                            to="/tools/dwsearch?indicator=HOTLIST"
                          >
                            {siteLang == "eng" ? languageText.area3ViewMoreEn : languageText.area3ViewMore}
                          </Link>
                        </div>
                      </div>
                    </td>
                  </tr>
                  <tr className="visible-xs">                                
                    <td className="td-text" style={{textAlign: "center"}}>
                      <div className="inner_title color-primary">
                        <img src="/img/whydw28/Text3.png" style={{maxWidth: '400px'}} />
                      </div>                                        
                      <div className="inner_content" dangerouslySetInnerHTML={{__html: siteLang == "eng" ? languageText.area3LeftContentEn : languageText.area3LeftContent}} /> 
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <table className="tablearea img_dire">
            <tbody>
              <tr>
                <td className="td-text">
                  <div className="inner_title color-primary" dangerouslySetInnerHTML={{__html: siteLang == "eng" ? languageText.stateArtTitleEn : languageText.stateArtTitle}} />
                  <div className="inner_content">
                    {siteLang == "eng" ? languageText.stateArtDescriptionEn : languageText.stateArtDescription}
                  </div>
                </td>
                <td>
                  <img alt="whydw28" src="/img/education/u22329.png" />
                </td>
              </tr>
            </tbody>
          </table>
          <table className="tablearea img_dire img_dire_rirst">
            <tbody>
              <tr>
                <td>
                  <img alt="whydw28" src="/img/education/u22323.png" />
                </td>
                <td className="td-text">
                  <div className="inner_title color-primary">
                    {siteLang == "eng" ? languageText.learnMoreTitleEn : languageText.learnMoreTitle}
                  </div>
                  <div className="inner_content">
                    {siteLang == "eng" ? languageText.learnMoreDescriptionEn : languageText.learnMoreDescription}
                  </div>
                  <Link target="_blank" to="/education/dwvideo">
                    <img src="/img/whydw28/VDO_button.png" style={{ marginTop: "20px", maxWidth: "240px" }} alt="whydw28" />
                  </Link>
                </td>
              </tr>
            </tbody>
          </table>
          <table className="tablearea img_dire">
            <tbody>
              <tr>
                <td className="td-text">
                  <div className="inner_title color-primary">
                    {siteLang == "eng" ? languageText.constantSupportTitleEn : languageText.constantSupportTitle}
                  </div>
                  <div className="inner_content">
                    {siteLang == "eng" ? languageText.constantSupportDescriptionEn : languageText.constantSupportDescription}
                  </div>
                </td>
                <td>
                  <img alt="whydw28" src="/img/education/u22327.png" />
                </td>
              </tr>
            </tbody>
          </table>
          <div style={{ backgroundColor: "#fff", padding: "20px 10px" }}>
            <hr className="hr-hmgbm" />
            <p className="small">
              {siteLang == "eng" ? languageText.marketDelayEn : languageText.marketDelay}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};
export default WhyMacquaries;
