import React, { useState, useEffect,Fragment } from 'react';
import TipsTools from './TipsTools';
import "../../common.css";
import "../../investorAcademy.css";


const TipsTool = (props) => {
    return (  
        <Fragment> 
            <TipsTools/>
        </Fragment> 
     );
}


export default TipsTool;