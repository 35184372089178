export const languageText ={
    
    firstNavigation: "รอบรู้เรื่อง DW ",
    firstNavigationEn: "DW education ",
    secondNavigation: " ทำไมเลือก DW28",
    secondNavigationEn: " Why DW28",

    subscribeAreaDescription: "เกาะติดสถานการณ์ ไม่ตกเทรน ด้วยข้อมูลข่าวสารล่าสุด",
    subscribeAreaDescriptionEn: "Keep up to date with the latest news",
    subscribeAreaButton: "สมัครจดหมายข่าว",
    subscribeAreaButtonEn: "Subscribe",

    area3LeftContent: "แมคควอรี DW28 มุ่งมั่นที่จะให้บริการด้วยมาตราฐานที่สูง เพื่อเป็นประโยชน์กับนักลงทุน อีกทั้งเป็นการตอบโจทย์นักลงทุนที่ต้องการความรวดเร็วในการซื้อขาย และความแม่นยำของการดูแลสภาพคล่องที่ตรงตามตารางราคา จึงเป็นที่มาในการพัฒนาระบบ Market making และเปิดตัว DW28 Fast Track<br><br>โดย DW ที่อยู่ใน DW28 Fast Track จะถูกประกาศผ่านหน้าเว็ปไซต์ ซึ่งนักลงทุนสามารถมั่นใจได้ว่าเมื่อเทรด DW28 Fast Track จะราบรื่น รวดเร็ว สภาพคล่องสูง และซื้อขายได้ง่าย ทั้งนี้ระบบดูแลสภาพคล่องอัตโนมัติจะเปลี่ยนราคาตามตารางราคาด้วยความรวดเร็ว และจับคู่คำสั่งซื้อขายโดยใช้เวลาไม่ถึงวินาที<br><br>นักลงทุนสามารถสัมผัสได้ถึงประสบการณ์ “เร็ว / แรง / เติมไว / ไม่หน่วง” เมื่อซื้อขาย DW28 Fast track!!",
    area3LeftContentEn: "แมคควอรี DW28 มุ่งมั่นที่จะให้บริการด้วยมาตราฐานที่สูง เพื่อเป็นประโยชน์กับนักลงทุน อีกทั้งเป็นการตอบโจทย์นักลงทุนที่ต้องการความรวดเร็วในการซื้อขาย และความแม่นยำของการดูแลสภาพคล่องที่ตรงตามตารางราคา จึงเป็นที่มาในการพัฒนาระบบ Market making และเปิดตัว DW28 Fast Track<br><br>โดย DW ที่อยู่ใน DW28 Fast Track จะถูกประกาศผ่านหน้าเว็ปไซต์ ซึ่งนักลงทุนสามารถมั่นใจได้ว่าเมื่อเทรด DW28 Fast Track จะราบรื่น รวดเร็ว สภาพคล่องสูง และซื้อขายได้ง่าย ทั้งนี้ระบบดูแลสภาพคล่องอัตโนมัติจะเปลี่ยนราคาตามตารางราคาด้วยความรวดเร็ว และจับคู่คำสั่งซื้อขายโดยใช้เวลาไม่ถึงวินาที<br><br>นักลงทุนสามารถสัมผัสได้ถึงประสบการณ์ “เร็ว / แรง / เติมไว / ไม่หน่วง” เมื่อซื้อขาย DW28 Fast track!!",

    area3FastTrackTitle: " Fast track",
    area3FastTrackTitleEn: " Fast track",
    area3FastTrackDescription: "DW ที่เร็ว แรง เติมไว และไม่หน่วง เป็น DW ที่มีสภาพคล่องสูง สามารถทำให้นักลงทุนซื้อ/ขาย DW ได้ภายในเสี้ยววินาที ",
    area3FastTrackDescriptionEn: "DW with high speed refilled. It allows investors to buy / sell millions of DW less than a second. ",
    area3FastTrackDescriptionLink: " เพิ่มเติมคลิก",
    area3FastTrackDescriptionLinkEn: " Learn more click!!",
    
    area3TableSymbol: "DW ชื่อย่อ",
    area3TableSymbolEn: "DW symbol",
    area3TableBid: "DW Bid",
    area3TableBidEn: "DW Bid",
    area3TableBid1: "(บาท)",
    area3TableBid1En: "(THB)",
    area3TableGearing: "Eff. gearing",
    area3TableGearingEn: "Eff. gearing",
    area3TableSensivity: "Sen.",
    area3TableSensivityEn: "Sen.",

    area3ViewMore: "เพิ่มเติม คลิก",
    area3ViewMoreEn: "View more",

    stateArtTitle: "ระบบดูแลสภาพคล่องชั้นเยี่ยม",
    stateArtTitleEn: 'State-of-the-art <span style="white-space: nowrap;">market making</span>',
    stateArtDescription: "หนึ่งในสาเหตุที่ทำให้แมคควอรีเป็นผู้นำตลาด DW ในภูมิภาคเอเชีย คือระบบดูแลสภาพคล่องที่มีคุณภาพ ซึ่งช่วยให้ DW ส่วนใหญ่ของ Macquarie มีการวาง Bid – Offer ชิดกัน (tight spread) และในปริมาณที่สูง ส่งผลให้เกิดสภาพคล่องสูง และช่วยให้นักลงทุนสามารถซื้อขายได้ง่าย",
    stateArtDescriptionEn: "Our consistent pricing has made Macquarie the market leader for DW in Asia. Macquarie often provide large bid and offer volume, tight spreads and liquidity make it easy to enter and exit your trades.",

    learnMoreTitle: "เพิ่มความรู้กับ DW28",
    learnMoreTitleEn: 'Learn more with Macquarie',
    learnMoreDescription: "จุดประสงค์ของเราคือการเห็นคุณประสบความสำเร็จในฐานะนักเทรด DW นี่เป็นเหตุผล ที่เราทุ่มเทเพิ่มความรู้แก่นักลงทุน เพื่อให้คุณเทรด DW ได้ดีกว่าเดิม",
    learnMoreDescriptionEn: "Our aim is to make you a successful DW trader. That’s why we invest a lot of time and effort into educating investors to improve your trading skills.",

    constantSupportTitle: "ทีมงานพร้อมช่วยเหลือ",
    constantSupportTitleEn: 'Constant support',
    constantSupportDescription: "ทีมงานของเรา พร้อมที่จะตอบคำถามของคุณและให้ความช่วยเหลือ ทั้งนี้คุณสามารถติดต่อเราผ่านโทรศัพท์หรืออีเมลล์",
    constantSupportDescriptionEn: "Our local team is available throughout the day to help with your needs. Call us or email your questions, we would love to hear from you.",
    
    marketDelay: "ข้อมูลตลาดทั้งหมดล่าช้าประมาณ 15 นาที",
    marketDelayEn: "All market data is delayed 15 mins.",
}