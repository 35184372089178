import React, { useState, useEffect,Fragment } from 'react';
import CallExamples from './CallExamples';
import "../../common.css";
import "../../investorAcademy.css";


const CallExample = (props) => {
    return ( 
        <Fragment> 
            <CallExamples/>
        </Fragment> 
     );
}


export default CallExample;