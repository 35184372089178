export const languageText ={

    pageTitle: "ราคา DW คำนวณอย่างไร",
    pageTitleEn: "How are DW priced?",

    pageName: "มูลค่าที่แท้จริงและมูลค่าเวลา",
    pageNameEn: "Intrinsic Value & Time Value",

    pageDescription: "ราคา DW ประกอบด้วย :",
    pageDescriptionEn: "A DW's price consists of:",

    pageInnerTitle: "มูลค่าที่แท้จริง (Intrinsic Value)",
    pageInnerTitleEn: "Intrinsic Value",

    pageInnerDescription: "เมื่อราคาใช้สิทธิของ Call DW ต่ำกว่าราคาหุ้นอ้างอิงในปัจจุบัน ฉะนั้น DW รุ่นดังกล่าวมี “มูลค่าที่แท้จริง” จากตัวอย่างด้านล่างจะเห็นได้ว่าราคาใช้สิทธิเท่ากับ 32 บาท ซึ่งต่ำกว่าราคาหุ้นปัจจุบัน 3 บาท ซึ่งมูลค่าดังกล่าวคือ มูลค่าที่แท้จริง ทั้งนี้ Call DW ที่มีราคาใช้สิทธิต่ำกว่าราคาหุ้นปัจจุบันจะมีมูลค่าอย่างต่ำสุดเท่ากับมูลค่าที่แท้จริง",
    pageInnerDescriptionEn: "When the exercise price of a call DW is below the underlying’s current share price, it is said to have “intrinsic value”. Have a look at the example below. In this case the exercise price of THB 32 is at a THB 3 “discount” from the current share price, this is its intrinsic value. All call DW with an exercise price lower than the spot price should always be worth at least the intrinsic value.",

    imgUrl: "/img/education/img/intrinsicvalueandtimevalue_th.png",
    imgUrlEn: "/img/education/img/intrinsicvalueandtimevalue.png",

    pageInnerTitle1: "มูลค่าเวลา (Time Value)",
    pageInnerTitle1En: "Time value",

    pageInnerDescription1: "DW ส่วนใหญ่จะซื้อขายที่มูลค่าสูงกว่ามูลค่าที่แท้จริง ดังตัวอย่างที่เห็นอยู่นี้ DW มีราคาอยู่ที่ 3.20 บาท ซึ่งสูงกว่ามูลค่าที่แท้จริงอยู่ 0.20 บาท มูลค่าส่วนเกินนี้เรียกว่า “มูลค่าเวลา”",
    pageInnerDescription1En: "A DW will usually be worth more than its intrinsic value. As you can see in this example, the DW is priced at THB 3.20, which is THB 0.20 more than its intrinsic value. This remaining value is called “time value”.",
}