import React, { useState, useEffect,Fragment } from 'react';
import RiskMatrixs from './RiskMatrixs';
import "../../common.css";
import "../../investorAcademy.css";


const RiskMatrix = (props) => {
    return (  
        <Fragment> 
            <RiskMatrixs/>
        </Fragment> 
     );
}


export default RiskMatrix;