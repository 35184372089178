export const languageText ={

    pageTitle: "ราคา DW คำนวณอย่างไร",
    pageTitleEn: "How are DW priced?",

    pageName: "การคำนวณ % การเปลี่ยนแปลงของราคา DW ในแต่ละวัน",
    pageNameEn: "Calculating a DW's daily % change",

    pageDescription: "ตัวอย่างด้านล่างนี้ แสดงวิธีการคำนวณการเปลี่ยนแปลงของราคา DW ทั้ง 2 วิธี ในกรณีที่นักลงทุนต้องการทราบว่าราคา DW เปลี่ยนแปลงไปอย่างไร เมื่อเทียบกับราคาปิดวันก่อนหน้า และเปรียบเทียบการเปลี่ยนแปลงของราคาหุ้นอ้างอิงในช่วงเวลาเดียวกัน :",
    pageDescriptionEn: "The example below shows the two different methods for calculating a DW price change. In this case the investor wants to know how the DW price has changed since yesterday's close and to compare this to the stock change for the same period:",

    pageDescription1: "วิธีที่ไม่ควรใช้ในการคำนวณการเปลี่ยนแปลงของราคา DW โดยการใช้ราคาซื้อขายสุดท้าย (Last Traded Price):",
    pageDescription1En: "Unreliable way of calculating daily price change using last traded price:",

    imgUrl: "/img/education/img/calculatedwdailychangefalse_th.png",
    imgUrlEn: "/img/education/img/calculatedwdailychangefalse.png",

    imgUrlMobile: "/img/education/img/calculatedwdailychangefalse_m_th.png",
    imgUrlMobileEn: "/img/education/img/calculatedwdailychangefalse_m.png",
    
    pageDescription2: "ตัวอย่างข้างต้น แสดงถึงวิธีที่นิยมใช้สำหรับผู้จัดทำและเผยแพร่ข้อมูลทั่วไป ซึ่งเป็นวิธีที่ไม่ถูกต้องนัก เนื่องจากราคาซื้อขายสุดท้ายของ DW ซึ่งใช้เป็นราคาปิดนั้น อาจจะเป็นราคาที่เกิดขึ้นในช่วงเช้าวันก่อน และอาจไม่สอดคล้องกับราคาหุ้นอ้างอิงที่เปลี่ยนแปลงไป ซึ่งเป็นราคาซื้อขายสุดท้ายในช่วงปิดตลาด",
    pageDescription2En: "The above shows the method that would be published by most publicly available information sources. In this case it is totally inaccurate as the 'last traded price' for the DW which is used as the 'closing price' actually occurred the previous morning. Therefore it is not relevant to compare this to the stock price change which recorded its last traded at the market close.",

    pageDescription3: "ดังนั้น เพื่อให้การคำนวณการเปลี่ยนแปลงถูกต้องมากขึ้น เราจึงควรใช้ราคาเสนอซื้อ (Bid price) ในช่วงที่จะเปรียบเทียบราคา:",
    pageDescription3En: "More accurate to look at bid prices over period of comparison:",

    imgUrl1: "/img/education/img/calculatedwdailychangetrue_th.png",
    imgUrl1En: "/img/education/img/calculatedwdailychangetrue.png",

    imgUrlMobile1: "/img/education/img/calculatedwdailychangetrue_m_th.png",
    imgUrlMobile1En: "/img/education/img/calculatedwdailychangetrue_m.png",

    pageDescription4: "วิธีที่ถูกต้องที่จะใช้ในการคำนวณการเปลี่ยนแปลงของราคา DW คือเปรียบเทียบจากการเปลี่ยนแปลงราคาเสนอซื้อในช่วงนั้นๆ ดังนั้น จากตัวอย่าง นักลงทุนควรจะดูราคาเสนอซื้อสุดท้ายของ DW ก่อนปิดตลาดในวันก่อนหน้าเปรียบเทียบกับราคาเสนอซื้อปัจจุบัน ซึ่งการใช้วิธีการแบบนี้ ช่วงเวลาที่ใช้เปรียบเทียบทั้งหุ้นและ DW จะเป็นช่วงเวลาเดียวกัน",
    pageDescription4En: "The most accurate way to calculate the price change for a DW is to look at the change in bid prices over the period. So in this example, the investor would look at where the bid price for the DW was just before the market close of the previous day and to compare that with the current bid price. In this way the period of comparison for the shares and DW are the same.",

    pageDescriptionH2: "เวลาปิดทำการซื้อขาย DW",
    pageDescriptionH2En: "Closing times for DW",

    pageDescription5: "อย่าลืมว่าในช่วงระหว่าง Pre-close ของตลาดหลักทรัพย์ ราคา DW และหุ้นอ้างอิงมักจะปรับตัวเพิ่มขึ้นหรือลดลงตามแต่พฤติกรรมการซื้อขายของนักลงทุนในช่วง Pre-close ดังนั้น หากต้องการตรวจสอบราคา DW ที่ถูกต้อง ณ สิ้นวันทำการ (และรวมทั้งราคาหุ้นอ้างอิงที่สัมพันธ์กัน) เราจึงควรใช้ราคาเสนอซื้อสุดท้าย ณ เวลา 16:30 น.",
    pageDescription5En: "Please note that during the call market on the Stock Exchange of Thailand, the prices of the DW and their underlying stocks can often move up or down due to the actions of investors during the auction period. Therefore, when looking for an accurate representation of a DW's price at the close of day (and the corresponding underlying share price) we normally use the last bid prces at 4:30pm.",
}