import React, { useState, useEffect } from 'react';
import API from '../../../utils/apiCalling';
import { config } from '../../../utils/apiUrl';
import { get } from 'lodash';
import { Link } from 'react-router-dom';
import moment from "moment"
import thLocale from 'moment/locale/th'
import enLocale from "moment/locale/en-in"
import { Spinner } from "reactstrap"
import PieChart from './PieChart';
import LangContext from '../../../components/common/context/lang-context';
import { useContext } from 'react';
import { languageText } from '../../../languageText/turnOverDistribution';
import LiveMatrixSideBars from "../../../components/common/Sidebar/LiveMatrixSideBar"
import WarrantSearchSideBars from "../../../components/common/Sidebar/WarrantSearchSideBar"
import WarrantCalculatorSideBars from "../../../components/common/Sidebar/WarrantCalculatorSideBar"
import AdvertiseMentSidebar from "../../../components/common/Sidebar/AdvertiseMentSidebar"

const TurnOverDist = (props) => {

  const api = new API()
  const date = new Date();
  const langContext = useContext(LangContext);
  const [topTradedUnderlyingsList, setTopTradedUnderlyingsList] = useState([])
  const [topTradedUnderlyingsDate, setTopTradedUnderlyingsDate] = useState([])
  const [result, setResult] = useState()
  const [perTotal, setPerTotal] = useState()
  const [selectDate1, setSelectDate1] = useState()
  const [selectDate2, setSelectDate2] = useState()
  const [selectDate3, setSelectDate3] = useState()
  const [selectDate4, setSelectDate4] = useState()
  const [selectDate5, setSelectDate5] = useState()
  const [siteLang , setSiteLang] = useState("")

  useEffect(() => {
    window.scrollTo(0, 0)
    var siteLanguage = $.cookie('applang');
    setSiteLang(siteLanguage)
    if(siteLanguage == "eng"){
      document.querySelector("body").classList.add("eng")
    }else{
      document.querySelector("body").classList.remove("eng")
    }
  }, [])

  useEffect(() => {
    getTurnOverDistributionResult()
  }, [])

  useEffect(() => {
    if (result) {
      var data = parseFloat(result.map((d) => d[`${selectDate1}`])[0]) + parseFloat(result.map((d) => d[`${selectDate1}`])[1]) + parseFloat(result.map((d) => d[`${selectDate1}`])[2]) + parseFloat(result.map((d) => d[`${selectDate1}`])[3]) + parseFloat(result.map((d) => d[`${selectDate1}`])[4])
      setPerTotal(data.toFixed(2))
    }
  },[result])

  const getTurnOverDistributionResult = async () => {
    let turnOverDistributionResult = await api.get(config.turnOverDistribution, true, { type: "turnoverdist", qid: Date.now() })
    let turnOverDistList = get(turnOverDistributionResult, "turnoverdist", [])
    let topTradedUnderlyingsListRes = get(turnOverDistList, 'content', [])
    let topTradedUnderlyingsDateRes = get(turnOverDistList, 'stats_date_list', [])
    let tempArr = topTradedUnderlyingsListRes.map((item) => {
      const filterObj = Object.keys(item)
    })
    var dateArray = turnOverDistributionResult.turnoverdist.stats_date_list
    var contentArray = turnOverDistributionResult.turnoverdist.content
    setSelectDate1(dateArray[0])
    setSelectDate2(dateArray[1])
    setSelectDate3(dateArray[2])
    setSelectDate4(dateArray[3])
    setSelectDate5(dateArray[4])
    var newData = contentArray.slice(0, 5)
    var cost = newData.map((d) => parseFloat(d[dateArray[0]].replace(/,/g, '')))
    const sum = cost.reduce((partialSum, a) => partialSum + a, 0);
    const value = 100 - sum
    var newObject = {
      "underlying_ticker": "Others",
    }
    newObject[`${dateArray[0]}`]= `${value.toFixed(2)}`
    var finalData = [...newData, newObject]
    setResult(finalData)
    setTopTradedUnderlyingsList(topTradedUnderlyingsListRes)
    setTopTradedUnderlyingsDate(topTradedUnderlyingsDateRes)
  }

  return (
    <div id="turnoverdist" className="pageobj">
      <div className="breadcrumbbar">
        <div className="container-fluid">
          <ol className="breadcrumb">
            <li>
              <Link to="/home?dwstat">
                {siteLang == "eng" ? languageText.firstNavigationEn : languageText.firstNavigation}
              </Link>
            </li>
            <li className="active">
              {siteLang == "eng" ? languageText.secondNavigationEn : languageText.secondNavigation}
            </li>
          </ol>
        </div>
      </div>
      <div className="stripe-bg" />
      <div className="container-fluid">
        <div className="topimg hidden-sm hidden-xs">
          <img src="/img/bg/top-turnoverdist.jpg" alt='Banner Image' />
        </div>
        <div className="row">
          <div className="col-md-8 sm-w-100">
            <div className="section">
              <div className="page-header">
                <h1>{siteLang == "eng" ? languageText.pageTitleEn : languageText.pageTitle}</h1>
              </div>
              <p>
                {siteLang == "eng" ? languageText.pageDescriptionEn : languageText.pageDescription}
              </p>
              <div className="tblh">
                <div className="tblh-left">
                  <h2 className="small">
                    {siteLang == "eng" ? languageText.topTradedUnderlyingEn : languageText.topTradedUnderlying}
                  </h2>
                </div>
                {console.log(date)}
                <div className="tblh-right">
                  <div className="lastupdatebox hidden-xs">
                    Last update : <span>{siteLang == "eng" ? moment(new Date(date)).locale('en', [enLocale]).format('DD MMM YY') : moment(new Date(date)).locale('th', [thLocale]).clone().add(parseInt(543),'years').format('DD MMM YY')}</span>
                  </div>
                </div>
              </div>
              <div id="topTbl" style={{ position: 'relative' }}>
                {topTradedUnderlyingsDate.length === 0 && <Spinner />}
                <table className="table table-striped table-closed">
                  <thead>
                    {topTradedUnderlyingsDate.length !== 0 &&
                      <tr>
                        <th>
                          {(siteLang == "eng") ? languageText.tableUnderlyingEn : languageText.tableUnderlying}
                        </th>
                        {topTradedUnderlyingsDate.map((underLyingData, underLyingIndex) => (
                          <th className={`hidden-xs ${"date_"} ${underLyingIndex}`} key={underLyingIndex}>{underLyingData}</th>
                        ))}
                        {topTradedUnderlyingsDate.map((underLyingData, underLyingIndex) => (
                          <th className={`visible-xs ${"date_"} ${underLyingIndex}`} key={underLyingIndex}>{moment(new Date(underLyingData)).format('DD MMM')}</th>
                        ))}
                      </tr>
                    }
                  </thead>
                  <tbody>
                    {topTradedUnderlyingsList && topTradedUnderlyingsList.map((underLyingListData, underLyingListIndex) => (
                      <tr className="rowtmpl" key={underLyingListIndex} idx={underLyingListIndex}>
                        <td><Link className="underlying_ticker" to={`/tools/dwranking/${underLyingListData.underlying_ticker}`}>{underLyingListData.underlying_ticker}</Link></td>
                        <td>{underLyingListData[`${selectDate1}`] || "-"}</td>
                        <td>{underLyingListData[`${selectDate2}`] || "-"}</td>
                        <td>{underLyingListData[`${selectDate3}`] || "-"}</td>
                        <td>{underLyingListData[`${selectDate4}`] || "-"}</td>
                        <td>{underLyingListData[`${selectDate5}`] || "-"}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className='section'>
              <div className="chartbox-turn-over-dist large">
                <h2 className="small"> 
                  {siteLang == "eng" ? languageText.todayUnderlyingEn : languageText.todayUnderlying} - <span>{siteLang == "eng" ? moment(new Date(date)).locale('en', [enLocale]).format('DD MMM YY') : moment(new Date(date)).locale('th', [thLocale]).format('DD MMM YY')}</span>
                </h2>
                {!result && <Spinner />}
                {result &&
                  <div className="chartbox-legend-turn-over-dist hidden-xs">
                    <div className="chartbox-legend-item-turn-over-dist" style={{}}>
                      <span className="chartbox-legend-square-turn-over-dist cbg00" />
                      <span className="chartbox-legend-txt-turn-over-dist lgnm_0">{result && result.map((d) => d.underlying_ticker)[0]}</span>
                    </div>
                    <div className="chartbox-legend-item-turn-over-dist" style={{}}>
                      <span className="chartbox-legend-square-turn-over-dist cbg01" />
                      <span className="chartbox-legend-txt-turn-over-dist lgnm_1">{result && result.map((d) => d.underlying_ticker)[1]}</span>
                    </div>
                    <div className="chartbox-legend-item-turn-over-dist" style={{}}>
                      <span className="chartbox-legend-square-turn-over-dist cbg02" />
                      <span className="chartbox-legend-txt-turn-over-dist lgnm_2">{result && result.map((d) => d.underlying_ticker)[2]}</span>
                    </div>
                    <div className="chartbox-legend-item-turn-over-dist" style={{}}>
                      <span className="chartbox-legend-square-turn-over-dist cbg03" />
                      <span className="chartbox-legend-txt-turn-over-dist lgnm_3">{result && result.map((d) => d.underlying_ticker)[3]}</span>
                    </div>
                    <div className="chartbox-legend-item-turn-over-dist" style={{}}>
                      <span className="chartbox-legend-square-turn-over-dist cbg04" />
                      <span className="chartbox-legend-txt-turn-over-dist lgnm_4">{result && result.map((d) => d.underlying_ticker)[4]}</span>
                    </div>
                    <div className="chartbox-legend-item-turn-over-dist" style={{}}>
                      <span className="chartbox-legend-square-turn-over-dist cbg05" />
                      <span className="chartbox-legend-txt-turn-over-dist lgnm_5">Others</span>
                    </div>
                  </div>
                }
                {result &&
                  <div className="chartbox-legend-turn-over-dist visible-xs">
                    <div className="chartbox-legend-item-turn-over-dist">
                      <span className="chartbox-legend-square-turn-over-dist cbg00" />
                      <span className="chartbox-legend-txt-turn-over-dist lgnm_0">{result && result.map((d) => d.underlying_ticker)[0]}</span>
                    </div>
                    <div className="chartbox-legend-item-turn-over-dist">
                      <span className="chartbox-legend-square-turn-over-dist cbg01" />
                      <span className="chartbox-legend-txt-turn-over-dist lgnm_1">{result && result.map((d) => d.underlying_ticker)[1]}</span>
                    </div>
                    <div className="chartbox-legend-item-turn-over-dist">
                      <span className="chartbox-legend-square-turn-over-dist cbg02" />
                      <span className="chartbox-legend-txt-turn-over-dist lgnm_2">{result && result.map((d) => d.underlying_ticker)[2]}</span>
                    </div>
                    <div className="chartbox-legend-item-turn-over-dist">
                      <span className="chartbox-legend-square-turn-over-dist cbg03" />
                      <span className="chartbox-legend-txt-turn-over-dist lgnm_3">{result && result.map((d) => d.underlying_ticker)[3]}</span>
                    </div>
                    <div className="chartbox-legend-item-turn-over-dist">
                      <span className="chartbox-legend-square-turn-over-dist cbg04" />
                      <span className="chartbox-legend-txt-turn-over-dist lgnm_4">{result && result.map((d) => d.underlying_ticker)[4]}</span>
                    </div>
                    <div className="chartbox-legend-item-turn-over-dist">
                      <span className="chartbox-legend-square-turn-over-dist cbg05" />
                      <span className="chartbox-legend-txt-turn-over-dist lgnm_5">Others</span>
                    </div>
                  </div>
                }
                { result && <PieChart result={result} date={selectDate1} /> }
              </div>
              <hr />
              <p className="small">
                {siteLang == "eng" ? languageText.aboveTableEn : languageText.aboveTable}
                <br />
                <br />
                {siteLang == "eng" ? languageText.marketDelayEn : languageText.marketDelay}
              </p>
            </div>
          </div>

          <div className="col-md-4 hidden-sm hidden-xs">
            <LiveMatrixSideBars />
            <WarrantSearchSideBars />
            {/* <WarrantCalculatorSideBars /> */}
            <AdvertiseMentSidebar /> 
          </div>
        </div>
      </div>
    </div>
  );
}
export default TurnOverDist;
