export const languageText ={

    pageTitle: "ตัวแปรเกี่ยวกับ DW ที่ควรรู้",
    pageTitleEn: "Useful DW terms",

    pageName: "อัตราทด (Gearing)",
    pageNameEn: "Gearing",

    pageDescription: "อัตราทด (Gearing) ใช้บอกจำนวน DW ที่คุณสามารถซื้อได้ด้วยเงินลงทุนจำนวนหนึ่งเมื่อเทียบกับการซื้อหุ้นอ้างอิง เช่น Gearing 10 เท่า หมายความว่าคุณมีโอกาสในการได้รับผลตอบแทนจากการซื้อ DW ได้มากถึง 10 เท่า เมื่อเทียบกับการซื้อหุ้นอ้างอิงโดยตรง",
    pageDescriptionEn: 'Gearing Indicates the increased number of DW you can buy with a certain amount of capital as opposed to buying the underlying share i.e. a gearing level of 10x means that you will be able to buy 10 times as many units of underlying exposure than you could if you purchased the underlying share.',

    formulaHolderLeft: "Gearing ",
    formulaHolderLeftEn: "Gearing ",
    formulaHolderRightFirst: " = ราคาหุ้นอ้างอิง / (ราคา DW x DWPS)",
    formulaHolderRightFirstEn: " = Underlying Price / (DW x DWPS)",

    tipHolderHeading: "เคล็ดลับในการใช้อัตราทด",
    tipHolderHeadingEn: "Helpful Tips on how to use Gearing",
    tipHolderParagraph1: "Gearing เพียงอย่างเดียว อาจไม่ได้มีประโยชน์มากนัก เนื่องจากได้ถูกนำมาคำนวณรวมในค่า Delta ของ DW แล้ว Gearing จึงมักจะแสดงไว้ใน Termsheet และเอกสารอื่นๆที่เกี่ยวข้อง เช่นเดียวกับอัตราส่วนมาตรฐานอื่นๆ แต่โดยตัวของมันเองแล้ว ไม่ค่อยใช้วัดะไรมากนัก",
    tipHolderParagraph1En: "Gearing alone is not a very useful ratio as it does not take into account the responsiveness (delta) of the DW. Gearing is often quoted in termsheets and other documents as standard ratio, but on its own is quite a impractical measure.",
    tipHolderParagraph2: "Effective gearing และ Delta เป็นที่นิยมใช้อ้างอิงสำหรับนักลงทุนมากกว่า",
    tipHolderParagraph2En: "Effective gearing and delta are much more useful reference points for investors",

}