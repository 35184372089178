export const languageText ={

    pageTitle: "ตัวแปรเกี่ยวกับ DW ที่ควรรู้",
    pageTitleEn: "Useful DW terms",

    pageName: "DW Sensitivity",
    pageNameEn: "DW Sensitivity",

    pageDescription: "DW Sensitivity เป็นค่าประมาณการเปลี่ยนแปลงของ DW ต่อการเปลี่ยนแปลงของราคาหลักทรัพย์อ้างอิงที่ 1 ช่วงราคา เราใช้ Delta และ DWPS ในการคำนวน ณ Sensitivity ต่อ DW ทั้งนี้ค่า Sensitivity เป็นค่าประมาณที่ช่วงระดับใด ระดับหนึ่งของราคาหลักทรัพย์อ้างอิงเท่านั้น",
    pageDescriptionEn: 'DW Sensitivity is an estimate of how many tick the DW needs to move for a corresponding one tick movement in the underlying price. We use delta and exercise ratio to calculate the sensitivity of the warrant to the Underlying.',

    exampleBoxHeading: "ตัวอย่าง:",
    exampleBoxHeadingEn: "Example:",
    exampleBoxType: "ประเภท : ",
    exampleBoxTypeEn: "Type:",
    exampleBoxTypeResult: "Call DW",
    exampleBoxTypeResultEn: "Call DW",
    exampleBoxDwPrice: "ราคา DW:",
    exampleBoxDwPriceEn: "DW price:",
    exampleBoxDwPriceResult: "1 บาท",
    exampleBoxDwPriceResultEn: "THB 1",
    exampleBoxUnderlying: "หลักทรัพย์อ้างอิง:",
    exampleBoxUnderlyingEn: "Underlying:",
    exampleBoxUnderlyingResult: "KTB",
    exampleBoxUnderlyingResultEn: "KTB",
    exampleBoxDwps: "DWPS:",
    exampleBoxDwpsEn: "DWPS:",
    exampleBoxDwpsResult: "5",
    exampleBoxDwpsResultEn: "5",
    exampleBoxExpiry: "วันครบกำหนดอายุ:",
    exampleBoxExpiryEn: "Expiry:",
    exampleBoxExpiryResult: "29 ธ.ค.",
    exampleBoxExpiryResultEn: "29 December",
    exampleBoxDelta: "Delta:",
    exampleBoxDeltaEn: "Delta:",
    exampleBoxDeltaResult: "50%",
    exampleBoxDeltaResultEn: "50%",
    exampleBoxDeltaPerSw: "Delta ต่อ DW = 50%/5 = 10%",
    exampleBoxDeltaPerSwEn: "Delta per SW = 50%/5 = 10%",

    formulaHolderLeft: "Sensitivity = ",
    formulaHolderLeftEn: "Sensitivity = ",
    formulaHolderRightFirst: " Delta ต่อ DW x 1 ช่วงราคาของหุ้นอ้างอิง",
    formulaHolderRightFirstEn: " Delta per DW x Minimum tick of Underlying",
    formulaHolderRightSecond: " 1 ช่วงราคาของ DW",
    formulaHolderRightSecondEn: " Minimum tick of DW",

    formulaHolder1Left: "Sensitivity ",
    formulaHolder1LeftEn: "Sensitivity ",
    formulaHolder1RightFirst: " (10% x 0.10) / 0.01",
    formulaHolder1RightFirstEn: " (10% x 0.10) / 0.01",
    formulaHolder1RightSecond: " 1.0",
    formulaHolder1RightSecondEn: " 1.0",

    pageDescription1: "นั่นหมายถึง ถ้า KTB เคลื่อนที่ 1 ช่วงราคา (หรือ 0.10 บาท) จะส่งผลให้ราคา DW จะเปลี่ยนแปลง 1 ช่วงราคา (หรือ 0.01 บาท)",
    pageDescription1En: 'Meaning that each time KTB price moves 1 tick (or 0.10 THB), DW price will move one tick (or 0.01 THB)',

    pageDescription2: "ในกรณีที่ Sensitivity = 1 เท่า หมายถึง DW จะเคลื่อนไหวแบบช่วงต่อช่วง (tick for tick) หรือ เมื่อหุ้นอ้างอิงเปลี่ยนแปลงไป 1 ช่วงราคา DW จะเปลี่ยนแปลงไป 1 ช่วงราคา",
    pageDescription2En: 'In case of sensitivity = 1.0, we say that this warrant moves “tick for tick”, which means its price will move one tick for every tick in the underlying share.',

    exampleBox1Heading: "ตัวอย่าง:",
    exampleBox1HeadingEn: "Example:",
    exampleBox1Underlying: "หลักทรัพย์อ้างอิง:",
    exampleBox1UnderlyingEn: "Underlying:",
    exampleBox1UnderlyingResult: "S50Z18",
    exampleBox1UnderlyingResultEn: "S50Z18",
    exampleBox1Dwps: "DWPS:",
    exampleBox1DwpsEn: "DWPS:",
    exampleBox1DwpsResult: "30",
    exampleBox1DwpsResultEn: "30",
    exampleBox1Expiry: "วันครบกำหนดอายุ:",
    exampleBox1ExpiryEn: "Expiry:",
    exampleBox1ExpiryResult: "28 ธ.ค.",
    exampleBox1ExpiryResultEn: "28 December",
    exampleBox1Delta: "Delta:",
    exampleBox1DeltaEn: "Delta:",
    exampleBox1DeltaResult: "40%",
    exampleBox1DeltaResultEn: "40%",
    exampleBox1DeltaPerSw: "Delta ต่อ DW = 40% / 30 = 1.3%",
    exampleBox1DeltaPerSwEn: "Delta per DW = 40% / 30 = 1.3%",

    formulaHolder2Left: "Sensitivity ",
    formulaHolder2LeftEn: "Sensitivity ",
    formulaHolder2RightFirst: " (1.3% x 1) / 0.01",
    formulaHolder2RightFirstEn: " (1.3% x 1) / 0.01",
    formulaHolder2RightSecond: " 1.3",
    formulaHolder2RightSecondEn: " 1.3",

    pageDescription3: "นั่นหมายถึง ถ้า S50Z18 เคลื่อนที่ 1 ช่วงราคา จะส่งผลให้ราคา DW จะเปลี่ยนแปลง 1.3 ช่วงราคา (หรือ 0.013 บาท) ซึ่งในความเป็นจริง ราคา DW ไม่สามารถปรับตัวขึ้น 0.013 บาท เนื่องจาก 1 ช่วงราคาขั้นต่ำคือ 0.01 บาท ฉะนั้นในกรณีนี้เพื่อให้สอดคล้องกับราคาขั้นต่ำคือ 0.01 บาทของ DW ราคาของ DW จะปรับขึ้น 0.01 บาท เมื่อ S50Z18 ปรับตัวเพิ่มขึ้น 0.08 จุดดัชนี (1/1.3)",
    pageDescription3En: 'Meaning that each time S50Z18 price moves 1 point, DW price will move 1.3 ticks. In reality there is no such 0.013 baht due to the 0.01 minimum tick size of DW. Therefore DW will move 0.01 baht when S50Z18 move 0.08 point (1/1.3)',

    tipHolderHeading: "เคล็ดลับในการใช้ DW Sensitivity",
    tipHolderHeadingEn: "Helpful Tips on how to use DW Sensitivity",
    tipHolderParagraph1: "ระลึกไว้เสมอว่าเราจะใช้",
    tipHolderParagraph1En: "Always remember to take into account the",
    tipHolderParagraph12: "ในการคำนวณหา sensitivity",
    tipHolderParagraph12En: "when looking at sensitivity.",
    tipHolderParagraph2: "DW sensitivity เป็นเพียงอีกวิธีหนึ่งในการหาค่า Delta ต่อ DW ในรูปเงินบาทหรือช่วงราคา มากกว่าการหาค่าเป็นเปอร์เซ็นต์ (%)",
    tipHolderParagraph2En: "DW sensitivity is primarily used by short term day traders and investors interested in the small/short term movements in a warrant. For all other investors, Delta or Effective Gearing are likely to be more useful.",
    tipHolderParagraph3: "DW sensitivity มีความสำคัญกับนักลงทุนระยะสั้น หรือ Day Trader ที่สนใจการเคลื่อนไหวในระยะสั้นๆของ DW แต่สำหรับนักลงทุนทั่วไป",
    tipHolderParagraph32: "มีประโยชน์มากกว่า",
    tipHolderParagraph4: "นักลงทุนสามารถดูค่า DW Sensitivity ของ DW แต่ละรุ่นได้จากหน้า ",
    tipHolderParagraph4En: "You can view the DW sensitivity for each DW via the ",
    tipHolderParagraph4Link: "ข้อมูล DW ",
    tipHolderParagraph4LinkEn: "DW Terms Page.",

}