import React, { useEffect, useState } from 'react'
import "./setseminar.css";
import { Link } from 'react-router-dom';
import { languageText } from "../../../../languageText/activitieInnerPages"

const Setseminar = () => {

  const [siteLang , setSiteLang] = useState("")

  useEffect(() => {
    window.scrollTo(0, 0);
    var siteLanguage = $.cookie('applang');
    setSiteLang(siteLanguage)
    if(siteLanguage == "eng"){
      document.querySelector("body").classList.add("eng")
    }else{
      document.querySelector("body").classList.remove("eng")
    }
  }, []);

  const handleClick = (Event) => {
    Event.preventDefault();
    window.open(
      "https://www.facebook.com/login.php?skip_api_login=1&api_key=966242223397117&signed_next=1&next=https%3A%2F%2Fwww.facebook.com%2Fshare.php%3Fu%3Dhttps%253A%252F%252Ft1www5.trkd-hs.com%252Fthaidw_aws%252Factivities%252FHSIDIMSUM%253Flang%253Den&cancel_url=https%3A%2F%2Fwww.facebook.com%2Fdialog%2Fclose_window%2F%3Fapp_id%3D966242223397117%26connect%3D0%23_%3D_&display=popup&locale=en_GB", 'share', 'width=560,height=650'
    );
  }

  return (
    <div id="set50seminar" className="pageobj">
      <div className="breadcrumbbar">
        <div className="container-fluid">
          <ol className="breadcrumb">
            <li>
              <Link to="/home">
                {siteLang == "eng" ? languageText.setSeminarFirstNavigationEn : languageText.setSeminarFirstNavigation}
              </Link>
            </li>
            <li className="active">
              {siteLang == "eng" ? languageText.setSeminarSecondNavigationEn : languageText.setSeminarSecondNavigation}
            </li>
          </ol>
        </div>
      </div>
      <div className="stripe-bg" />
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div className="section">
              <div className="page-header">
                <h1>{siteLang == "eng" ? languageText.setSeminarPageTitleEn : languageText.setSeminarPageTitle}</h1>
                <div className="btn-area"><div className="fbSharebtn"  onClick={handleClick} ><i className="fa fa-facebook" aria-hidden="true" />Share on Facebook</div></div>
              </div>
              <div className="head-text">
              </div>
              <div style={{background: 'black'}}>
                <div className="block2">
                  <div className="team-leader">
                    <div className="video">
                      <iframe frameBorder={0} allowFullScreen src="https://www.youtube.com/embed/Vf2At3kPeLU?rel=0&autoplay=1&wmode=opaque&mute=1" />                                       
                    </div>
                  </div>
                </div>
                <div className="team-description team-b">
                  <div className="team-b-bg">
                  </div>
                  <div className="col-left">
                    <div>
                      <div className="section-details1" />
                    </div>
                  </div>
                  <div>
                    <div className="section-btn-wapper">
                      <div className="btn-title" />
                      <Link to={{pathname:"https://docs.google.com/forms/d/e/1FAIpQLScxrM0bozmtYu_53HHGL84oDj1IZisrFnjMhE0_0fDsBseVBQ/viewform"}}  target="_top" type="button" className="btn btn-primary">คลิก</Link>
                    </div>
                  </div>
                </div>
                <div className="team-description team-a">
                  <div className="team-a-bg">
                  </div>
                  <div className="col-right">
                    <div>
                      <div className="section-details1" />
                    </div>
                  </div>
                  <div>
                    <div className="section-btn-wapper">
                      <div className="btn-title" />
                      <Link to={{pathname:"https://docs.google.com/forms/d/e/1FAIpQLSfSLzIAAjFyJShI6wtfpYSV6Wutfv91-bfFfT3enF8ZfPYVKw/viewform"}}  target="_top" type="button" className="btn btn-primary">คลิก</Link>
                    </div>
                  </div>
                </div>
                <div className="separater-extra">
                </div>
                <div className="extra">
                  <div className="extra-bg" />
                  <div className="section-btn-wapper">
                    <div className="btn-title" />
                    <Link to={{pathname:"https://docs.google.com/viewer?url=https://www.thaidw.com/pdf/Website_Activity_SET50_Term_L1-PDF.pdf"}} target="_top" type="button" className="btn btn-primary">คลิก</Link>
                  </div>
                </div>
                <div className="partner">
                  <div className="partner-content">
                    <Link className="btn-left btn-overlay" to={{pathname:"http://www.aecs.com/news/announcement-detail.php?id=27"}} target="_top" ></Link>
                    <Link className="btn-right btn-overlay" to={{pathname:"https://docs.google.com/forms/d/e/1FAIpQLSfvDemC3HykeaZ7RBkmcOb-vA1XSp5B3dVWqCuSyyBM4Spsiw/viewform"}} target="_top" ></Link>
                  </div>
                </div>
              </div>
              <div className="score-title">
                <img src="/img/game/6_Head.png" />
              </div>
              <div className="score-tbl">
                <table>
                  <tbody>
                    <tr className="score-tbl-head1">
                      <td className="bordered" style={{width: '50%'}}>AECS (1 ก.พ. - 30 มี.ค. 61)</td>
                      <td style={{width: '50%'}}>Phillip (1 ก.พ. - 30 มี.ค. 61)</td>
                    </tr>
                    <tr className="score-tbl-head2">
                      <td className="bordered">รางวัลที่1 (เงินรางวัลมูลค่า 50,000 บาท)</td>
                      <td>รางวัลที่1 (เงินรางวัลมูลค่า 50,000 บาท)</td>
                    </tr>
                    <tr className="score-tbl-row">
                      <td className="bordered">คุณ พีระ กุลรัตนญาน</td>
                      <td>คุณ อาคม ชินวรพิทักษ์</td>
                    </tr>
                    <tr className="score-tbl-head2">
                      <td className="bordered">รางวัลที่2 (เงินรางวัลมูลค่า 25,000 บาท)</td>
                      <td>รางวัลที่2 (เงินรางวัลมูลค่า 20,000 บาท)</td>
                    </tr>
                    <tr className="score-tbl-row">
                      <td className="bordered">คุณ พิสิษฐ์ รุจิหงส์นภา</td>
                      <td>คุณ มารุต อินทาหลา</td>
                    </tr>
                    <tr className="score-tbl-head2">
                      <td className="bordered">รางวัลที่3 (เงินรางวัลมูลค่า 10,000 บาท)</td>
                      <td>รางวัลที่3 (เงินรางวัลมูลค่า 10,000 บาท)</td>
                    </tr>
                    <tr className="score-tbl-row">
                      <td className="bordered">คุณ จันทร์ชณันทร์ รุ่งสุวรรณกิจ</td>
                      <td>คุณ ชัยวัฒน์ นพพรเลิศวงศ์</td>
                    </tr>
                    <tr className="score-tbl-head2">
                      <td className="bordered">รางวัลที่4 (เงินรางวัลมูลค่า 5,000 บาท)</td>
                      <td>รางวัลที่4 (เงินรางวัลมูลค่า 5,000 บาท)</td>
                    </tr>
                    <tr className="score-tbl-row">
                      <td className="bordered">คุณ ลิบบุณ แซ่แต้</td>
                      <td>คุณ พลศักดิ์ ปัญญาเลิศทวี</td>
                    </tr>
                    <tr className="score-tbl-head2">
                      <td className="bordered">
                        รางวัลพิเศษสำหรับลูกค้าที่เปิดบัญชีใหม่<br />
                        และมี % Profit เติบโตใน 2 อันดับแรก (เงินรางวัลมูลค่า 5,000 บาท)<br />
                        <div className="txt-light">
                          คุณ ลิบบุณ แซ่แต้<br />
                          คุณ ภูเบศ อมรรัตนติกุล<br />
                        </div>
                      </td>
                      <td>
                        รางวัลพิเศษสำหรับลูกค้าที่เปิดบัญชีใหม่ <br />
                        และมี % Profit เติบโตใน 3 อันดับแรก (เงินรางวัลมูลค่า 5,000 บาท)<br />
                        <div className="txt-light">
                          คุณ ลิบบุณ แซ่แต้<br />
                          คุณ ธนานันต์ ภัทรวุฒิพงศ์<br />
                          คุณ ศราวุธ ศักดิ์สูงเกียรติ<br />
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="score-title">
                <img src="/img/game/6_Head.png" />
              </div>        
              <div className="score-tbl">
                <table>
                  <tbody>
                    <tr className="score-tbl-head1">
                      <td className="bordered" style={{width: '50%'}}>UOB Kay Hian (1 ธ.ค. - 31 ม.ค. 61)</td>
                      <td style={{width: '50%'}}>KTBST (27 พ.ย. - 31 ม.ค. 61)</td>
                    </tr>
                    <tr className="score-tbl-head2">
                      <td className="bordered">รางวัลที่1 (เงินรางวัลมูลค่า 50,000 บาท)</td>
                      <td>รางวัลที่1 (เงินรางวัลมูลค่า 50,000 บาท)</td>
                    </tr>
                    <tr className="score-tbl-row">
                      <td className="bordered">คุณ ยุกตะนัน กุลจิตติปฐมพร &nbsp;&nbsp; NAV 18.95%</td>
                      <td>คุณ Jirarij Parichartnon &nbsp;&nbsp; NAV 9.43%</td>
                    </tr>
                    <tr className="score-tbl-head2">
                      <td className="bordered">รางวัลที่2 (เงินรางวัลมูลค่า 30,000 บาท)</td>
                      <td>รางวัลที่2 (เงินรางวัลมูลค่า 30,000 บาท)</td>
                    </tr>
                    <tr className="score-tbl-row">
                      <td className="bordered">คุณ สิริภากร พรจิตโกวิท &nbsp;&nbsp; NAV 14.08%</td>
                      <td>คุณ สธนพัทธ์ ไชยสวัสดิ์ &nbsp;&nbsp; NAV 3.78%</td>
                    </tr>
                    <tr className="score-tbl-head2">
                      <td className="bordered">รางวัลที่3 (เงินรางวัลมูลค่า 10,000 บาท)</td>
                      <td>รางวัลที่3 (เงินรางวัลมูลค่า 10,000 บาท)</td>
                    </tr>
                    <tr className="score-tbl-row">
                      <td className="bordered">คุณ ธนภัทร วุฒิจรัส &nbsp;&nbsp; NAV 9.49%</td>
                      <td>คุณ นคร ภาณุสิทธิกร &nbsp;&nbsp; NAV 2.99%</td>
                    </tr>
                    <tr className="score-tbl-head2">
                      <td className="bordered">รางวัลที่4 (เงินรางวัลมูลค่า 5,000 บาท)</td>
                      <td rowSpan={4}>
                        รางวัลพิเศษสำหรับนักลงทุนที่เปิดบัญชีใหม่<br />
                        (บัตรรับประทาน Afternoon tea พร้อม SPA โรงแรม Peninsula)<br />
                        <div className="txt-light">
                          คุณ Jiraroj Parichartnon &nbsp;&nbsp; NAV 9.43%
                        </div>
                      </td>
                    </tr>
                    <tr className="score-tbl-row">
                      <td className="bordered">คุณ ถิร คังคะโน &nbsp;&nbsp; NAV 3.65%</td>
                    </tr>
                    <tr className="score-tbl-head2">
                      <td className="bordered">รางวัลที่5 (เงินรางวัลมูลค่า 5,000 บาท)</td>
                    </tr>
                    <tr className="score-tbl-row last">
                      <td className="bordered">คุณ กตัญญู ลาภมหานนท์ &nbsp;&nbsp; NAV 3.64%</td>
                    </tr>
                  </tbody>
                </table>
              </div>        
              <div className="sponsor-btn-title" />
              <div className="sponsors5">
                <div className="sponsor_img51" />
                <div className="sponsor_img52" />
                <div className="sponsor_img53" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Setseminar