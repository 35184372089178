import React, { useState, useEffect, useRef } from "react";
import "./hsiFlight.css";
import API from "../../../../utils/apiCalling";
import { config } from "../../../../utils/apiUrl";
import SpeakModal from "../../../../components/common/SpeakModal";
import { languageText } from "../../../../languageText/activitieInnerPages";
import { Link } from "react-router-dom";

const HsifLight = (props) => { 

  const api = new API();
  const [value1, setOpen1] = useState(false);
  const [value2, setOpen2] = useState(false);
  const [value3, setOpen3] = useState(false);
  const [resmsg, setresMsg] = useState(false);
  const [resmsg1, setMsg1] = useState(false);
  const inputFile = useRef(null);
  const [siteLang , setSiteLang] = useState("")
  const [values, setVlaues] = useState({
    name: "",
    tel: "",
    mail: "",
    answer: "",
  });

  useEffect(() => {
    window.scrollTo(0, 0)
    var siteLanguage = $.cookie('applang');
    setSiteLang(siteLanguage)
    if(siteLanguage == "eng"){
      document.querySelector("body").classList.add("eng")
    }else{
      document.querySelector("body").classList.remove("eng")
    }
  }, [])

  const handleChange = (event) => {
    const { name, value } = event.target;
    setVlaues({ ...values, [name]: value });
  };

  const onButtonClick = () => {
    var file = document.getElementById("input_file").click();
  };

  const onChange = () => {
    var file1 = document.querySelector("#input_file").files[0].name;
    document.querySelector("[name=File]").value = file1;
  };

  const handleClick = (Event) => {
    Event.preventDefault();
    window.open(
      "https://www.facebook.com/login.php?skip_api_login=1&api_key=966242223397117&signed_next=1&next=https%3A%2F%2Fwww.facebook.com%2Fshare.php%3Fu%3Dhttps%253A%252F%252Ft1www5.trkd-hs.com%252Fthaidw_aws%252Factivities%252FHSIFlight%253Flang%253Den&cancel_url=https%3A%2F%2Fwww.facebook.com%2Fdialog%2Fclose_window%2F%3Fapp_id%3D966242223397117%26connect%3D0%23_%3D_&display=popup&locale=en_GB", "share", 'width=560,height=650'
    );
  };

  const handleSubmit = () => {
    var phone = /^[0-9]+$/;
    var validRegex =
      /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    var allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i;
    if (
      values.name === "" ||
      values.tel === "" ||
      values.mail === "" ||
      values.answer === ""
    ) {
      setOpen1(true);
      setOpen2(false);
      setOpen3(false);
      setTimeout(() => setOpen1(false), [3000]);
      return false
    }
    if (document.querySelector("[name=File]").value == "") {
      setOpen1(true);
      setOpen2(false);
      setOpen3(false);
      setTimeout(() => setOpen1(false), [3000]);
      return false
    }
    if (!document.querySelector("[name=File]").value.match(allowedExtensions)) {
      setOpen1(true);
      setOpen2(false);
      setOpen3(false);
      setTimeout(() => setOpen1(false), [3000]);
      return false
    }
    if (!(values.tel === "") && !values.tel.match(phone)) {
      setOpen2(true);
      setOpen1(false);
      setTimeout(() => setOpen2(false), [3000]);
      return false
    }
    if (!(values.tel === "") && values.tel.length < 10) {
      setOpen2(true);
      setOpen1(false);
      setTimeout(() => setOpen2(false), [3000]);
      return false
    }
    if (!(values.tel === "") && values.tel.length > 10) {
      setOpen2(true);
      setOpen1(false);
      setTimeout(() => setOpen2(false), [3000]);
      return false
    }
    if (!(values.mail === "") && !values.mail.match(validRegex)) {
      setOpen3(true);
      setOpen2(false);
      setOpen1(false);
      setTimeout(() => setOpen3(false), [3000]);
      return false
    }

    if (values.name === "") {
    } else if (values.tel === "") {
    } else if (values.answer === "") {
    } else if (document.querySelector("[name=File]").value == "") {
    } else if (
      !document.querySelector("[name=File]").value.match(allowedExtensions)
    ) {
    } else if (!(values.tel === "") && !values.tel.match(phone)) {
    } else if (!(values.tel === "") && values.tel.length < 10) {
    } else if (!(values.tel === "") && values.tel.length > 10) {
    } else if (values.mail == "") {
    } else if (!values.mail.match(validRegex)) {
    } else {
      var array = [];
      var name = document.getElementById("name").value;
      for (let i = 0; i < name.length; i++) {
        var code = name.charCodeAt(i);
        array = array.concat([code]);
      }
      const submit = async () => {
        let data = {
          email: values.mail,
        };
        let res = await api
          .post(`${config.subscription}${"?p=register"}`, data)
          .then((res) => {})
          .then((data) => {
            console.log(data);
          })
          .catch((error) => console.log(error));
      };
      submit();
      const submit1 = async () => {
        let data1 = {
          name: values.name,
          tel: values.tel,
          mail: values.mail,
          answer: values.answer,
          file: "(binary)",
          round: 1,
          names: array,
        };
        let ress = await api
          .post(`${config.gameservlet}`, data1)
          .then((res1) => {
            {
              if (res1.message === "Registration succeed.") {
                setresMsg(true);
                setTimeout(() => setresMsg(false), [3000]);
              }
              if (res1.message === "duplicate.") {
                setMsg1(true);
                setresMsg(false);
                setTimeout(() => setMsg1(false), [3000]);
              }
            }
          })
          .then((data) => {
            console.log(data);
          })
          .catch((error) => console.log(error));
      };
      submit1();
    }
  };

  return (
    <div>
      <div id="supertrader2" className="pageobj">
        <div className="breadcrumbbar">
          <div className="container-fluid">
            <ol className="breadcrumb">
              <li>
                <Link to="/home?dwnews">
                  {siteLang == "eng" ? languageText.hsidimsumFirstNavigationEn : languageText.hsidimsumFirstNavigation}
                </Link>
              </li>
              <li className="active">
                {siteLang == "eng" ? languageText.hsidimsumSecondNavigationEn : languageText.hsidimsumSecondNavigation}
              </li>
            </ol>
          </div>
        </div>
        <div className="stripe-bg" /> 
        <div className="container-fluid">
          <div className="section">
            <div className="page-header">
              <h1>{siteLang == "eng" ? languageText.hsidimsumPageTitleEn : languageText.hsidimsumPageTitle}</h1>
              <div className="btn-area">
                <div className="fbSharebtn" onClick={handleClick}>
                  <i className="fa fa-facebook" aria-hidden="true" />
                  Share on Facebook
                </div>
              </div>
            </div>
            <div className="part top-banner">
              <div className="bg-layer">
                <img src="/img/game/top2.jpg" />
              </div>
            </div>
            <div className="part">
              <div className="from-area">
                <form id="form">
                  <div className="section-header">
                    {siteLang == "eng" ? languageText.hsiQuestionEn : languageText.hsiQuestion}
                  </div>
                  <div className="input-area">
                    <span>{siteLang == "eng" ? languageText.hsiFormNameEn : languageText.hsiFormName}*:</span>
                    <input
                      type="text"
                      id="name"
                      className="txt-input"
                      onChange={(e) => handleChange(e)}
                      autoComplete="off"
                      name="name"
                      value={values.name}
                    />
                  </div>
                  <div className="input-area">
                    <span>{siteLang == "eng" ? languageText.hsiFormTelEn : languageText.hsiFormTel}*:</span>
                    <input
                      type="text"
                      id="tel"
                      className="txt-input"
                      onChange={(e) => handleChange(e)}
                      autoComplete="off"
                      name="tel"
                      value={values.tel}
                    />
                  </div>
                  <div className="input-area">
                    <span>{siteLang == "eng" ? languageText.hsiFormMailEn : languageText.hsiFormMail}*:</span>
                    <input
                      type="text"
                      id="mail"
                      className="txt-input"
                      onChange={(e) => handleChange(e)}
                      autoComplete="off"
                      name="mail"
                      value={values.mail}
                    />
                  </div>
                  <div className="input-area">
                    <span>{siteLang == "eng" ? languageText.hsiFormAnswerEn : languageText.hsiFormAnswer}*:</span>
                    <input
                      type="text"
                      id="answer"
                      onChange={(e) => handleChange(e)}
                      className="txt-input"
                      autoComplete="off"
                      name="answer"
                      value={values.answer}
                    />
                  </div>
                  <div className="input-area">
                    <span>{siteLang == "eng" ? languageText.hsiFormUploadEn : languageText.hsiFormUpload}*:</span>
                    <div className="row">
                      <div className="col-md-9" id="upload">
                        <input
                          id="uploadFile"
                          className="txt-input "
                          onChange={(e) => handleChange(e)}
                          name="File"
                          value={values.File}
                          placeholder="Choose File"
                          disable
                        />
                      </div>
                      <div className="col-md-3">
                        <div onClick={onButtonClick} className="fileUpload btn btn-primary txt-input">
                          <span>Upload</span>
                        </div>
                      </div>
                      <input
                        type="file"
                        name=""
                        id="input_file"
                        style={{ display: "none" }}
                        onChange={onChange}
                      ></input>
                      <input
                        id="uploadBtn"
                        className="upload hide"
                        name="file"
                      />
                    </div>
                  </div>
                  <div className="submit-area">
                    <input
                      type="button"
                      id="submitBtn"
                      className="btn btn-primary"
                      defaultValue={siteLang == "eng" ? languageText.hsiFormSubmitEn : languageText.hsiFormSubmit}
                      onClick={handleSubmit}
                    />
                  </div>
                  <div className="note">*{siteLang == "eng" ? languageText.hsiFormNoteEn : languageText.hsiFormNote}</div>
                </form>
              </div>
            </div>
            <div className="part terms-area">
              <p className="terms">{siteLang == "eng" ? languageText.hsiTermsEn : languageText.hsiTerms}</p>
              <ol>
                <li dangerouslySetInnerHTML={{__html: siteLang == "eng" ? languageText.hsiFlightTerm1En : languageText.hsiFlightTerm1}} />
                <li dangerouslySetInnerHTML={{__html: siteLang == "eng" ? languageText.hsiFlightTerm2En : languageText.hsiFlightTerm2}} />
                <li dangerouslySetInnerHTML={{__html: siteLang == "eng" ? languageText.hsiFlightTerm3En : languageText.hsiFlightTerm3}} />
                <li dangerouslySetInnerHTML={{__html: siteLang == "eng" ? languageText.hsiFlightTerm4En : languageText.hsiFlightTerm4}} />
                <li dangerouslySetInnerHTML={{__html: siteLang == "eng" ? languageText.hsiFlightTerm5En : languageText.hsiFlightTerm5}} />
                <li dangerouslySetInnerHTML={{__html: siteLang == "eng" ? languageText.hsiFlightTerm6En : languageText.hsiFlightTerm6}} />
                <li dangerouslySetInnerHTML={{__html: siteLang == "eng" ? languageText.hsiFlightTerm7En : languageText.hsiFlightTerm7}} />
                <li dangerouslySetInnerHTML={{__html: siteLang == "eng" ? languageText.hsiFlightTerm8En : languageText.hsiFlightTerm8}} />
                <li dangerouslySetInnerHTML={{__html: siteLang == "eng" ? languageText.hsiFlightTerm9En : languageText.hsiFlightTerm9}} />
                <li dangerouslySetInnerHTML={{__html: siteLang == "eng" ? languageText.hsiFlightTerm10En : languageText.hsiFlightTerm10}} />
              </ol>
            </div>
            <div className="part hsi-btn-area">
              <div className="section-header">
                {siteLang == "eng" ? languageText.hsiPartHeadingEn : languageText.hsiPartHeading}
              </div>
              <div className="hsi-btn">
                <Link to="/education/individualtip?id=89">
                  <div className="hsi-link">
                    <img src="/img/game/btn.png" />
                  </div>
                </Link>
                <Link to="/education/individualtip?id=93">
                  <div className="hsi-link">
                    <img src="/img/game/btn2.png" />
                  </div>
                </Link>
                <Link to="/education/individualtip?id=105">
                  <div className="hsi-link">
                    <img src="/img/game/btn3.png" />
                  </div>
                </Link>
              </div>
            </div>
            <div className="part disclaimer-area">
              <div className="disclaimer" dangerouslySetInnerHTML={{__html: siteLang == "eng" ? languageText.hsiDisclaimerEn : languageText.hsiDisclaimer}} />
            </div>
          </div>
        </div>
      </div>
      {value1 && <SpeakModal value1={value1} />}
      {value2 && <SpeakModal value2={value2} />}
      {value3 && <SpeakModal value3={value3} />}
      {resmsg && <SpeakModal msg={resmsg} />}
      {resmsg1 && <SpeakModal resmsg1={resmsg1} />}
    </div>
  );
};
export default HsifLight;
