import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import { languageText } from "../../../languageText/common"

const LiveMatrixSideBars = (props) => {

    const [siteLang , setSiteLang] = useState("")

    useEffect(() => {
        var siteLanguage = $.cookie('applang');
        setSiteLang(siteLanguage)
        if(siteLanguage == "eng"){
          document.querySelector("body").classList.add("eng")
        }else{
          document.querySelector("body").classList.remove("eng")
        }
    }, [])

    return(
        <div className="section qlink-section">
            <div className="page-header">
                <h2 className="small">
                    {siteLang == "eng" ? languageText.sidebarLiveMatrixTitleEn : languageText.sidebarLiveMatrixTitle}
                </h2>
            </div>
            <p className="section-p">
                {siteLang == "eng" ? languageText.sidebarLiveMatrixDescriptionEn : languageText.sidebarLiveMatrixDescription}
            </p>
            <div className="section-qlink-img section-qlink-lmatrix">
                <Link to="/tools/livematrix" className="section-qlink-achor">
                    <img src="/img/qlink-achor.png" alt="indexdw" />
                </Link>
            </div>
        </div>
    )
};
export default LiveMatrixSideBars;