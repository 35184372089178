import React, { useState, useEffect } from 'react';
import "./photoGallery.css";
import API from '../../../utils/apiCalling';
import { config } from '../../../utils/apiUrl'; 
import { get } from 'lodash';
import { Link } from 'react-router-dom';
import { languageText } from "../../../languageText/photoGallery";

const PhotoGalleries = (props) => {

    const api = new API()
    const[load,setLoad] = useState(false)
    const [photoGallery, setPhotoGallery] = useState([])
    const [siteLang , setSiteLang] = useState("")

    useEffect(() => {
        window.scrollTo(0, 0)
        var siteLanguage = $.cookie('applang');
        setSiteLang(siteLanguage)
        if(siteLanguage == "eng"){
        document.querySelector("body").classList.add("eng")
        }else{
        document.querySelector("body").classList.remove("eng")
        }
        getphotoGalleryResult()
    }, [])
    
    const getphotoGalleryResult = async () => {
        let photoGalleryResult = await api.get(config.photoGallery, true,{qid:Date.now()} )
        let items = []; 
        if (photoGalleryResult?.length > 0) {
            for (let i = 0; i < photoGalleryResult.length; i++) {
                let advImgData = await api.get(config.advImgData, true, {
                    album_id: photoGalleryResult[i].id,
                });
                items.push({
                    id: photoGalleryResult[i].id,
                    status: photoGalleryResult[i].status,
                    en_title: photoGalleryResult[i].en_title,
                    th_title: photoGalleryResult[i].th_title,
                    en_description: photoGalleryResult[i].en_description,
                    th_description: photoGalleryResult[i].th_description,
                    last_update_time: photoGalleryResult[i].last_update_time,
                    imgLink: advImgData,
                });
            }
            setPhotoGallery(items);
        }
        setLoad(true)
    }

    return (
        <div id="photogallery" className="pageobj">
            <div className="breadcrumbbar">
                <div className="container-fluid"> 
                <ol className="breadcrumb">
                    <li>
                        <Link to="/home?dwnews">{siteLang == "eng" ? languageText.firstNavigationEn : languageText.firstNavigation} </Link>
                    </li>
                    <li className="active"> {siteLang == "eng" ? languageText.secondNavigationEn : languageText.secondNavigation}</li>
                </ol>
                </div>
            </div>
            <div className="stripe-bg" />
            <div className="container-fluid">
                <div className="section">
                <div className="page-header">
                    <h1>{siteLang == "eng" ? languageText.pageTitleEn : languageText.pageTitle}</h1>
                </div>
                <p>
                    {siteLang == "eng" ? languageText.pageDescriptionEn : languageText.pageDescription}
                </p>
                <div id="albums" style={{ position: "relative" }}>
                    {(load && photoGallery.length === 0)  &&  "No photo gallery album"}
                    {load && photoGallery.map((d, index)=>
                      <div className="albumtmpl" key={index}>
                          <div className="bg-layer">
                              <img src={d.imgLink} />
                          </div>
                          <div className="content-layer">
                            <table>
                                <tbody>
                                    <tr>
                                      <td>
                                          <Link target="_blank" to={`/marketnews/individualgallery?album_id=${d.id}`} >
                                              <h2>{siteLang == "eng" ? d.en_title : d.th_title}</h2>
                                              <div className="view_btn">{siteLang == "eng" ? languageText.viewMoreBtnEn : languageText.viewMoreBtn}</div>
                                          </Link>
                                      </td>
                                    </tr>
                                </tbody>
                            </table>
                          </div>
                      </div>
                    )}
                </div>
                <hr style={{ marginTop: "20px" }} className="hr-hmgbm" />
                <p className="small">{siteLang == "eng" ? languageText.marketDelayEn : languageText.marketDelay}</p>
                </div>
            </div>
        </div>
      );
}
export default PhotoGalleries;


