export const languageText ={
    
    firstNavigation: "สถิติ DW ",
    firstNavigationEn: "DW statistics ",
    secondNavigation: " DW market turnover",
    secondNavigationEn: " DW market turnover",

    pageTitle: "DW market turnover",
    pageTitleEn: "DW market turnover",
    pageDescription: "สรุปมูลค่าตลาด DW วันนี้ และเทียบกับวันก่อนหน้า",
    pageDescriptionEn: "How active was the DW market today, and how did it compare to previous days?",

    dwMarketTurnover: "DW market turnover - Total",
    dwMarketTurnoverEn: "DW market turnover - Total",

    tableDate: "วันที่",
    tableDateEn: "Date",
    tableTotalMarket: "Total market turnover",
    tableTotalMarketEn: "Total market turnover",
    tableTotalDw: "Total DW turnover",
    tableTotalDwEn: "Total DW turnover",
    tableTotalDwTurnover: "Total DW turnover as % of market turnover",
    tableTotalDwTurnoverEn: "Total DW turnover as % of market turnover",
    
    marketDelay: "ข้อมูลตลาดทั้งหมดล่าช้าประมาณ 15 นาที",
    marketDelayEn: "All market data is delayed 15 mins.",
}