import React, { useState, useEffect,Fragment } from 'react';
import WhyTradeWarrants from './WhyTradeWarrants';
import "../common.css";
import "../investorAcademy.css" 


const WhyTradeWarrant = (props) => {
    return ( 
        <Fragment> 
        <WhyTradeWarrants/>
        </Fragment> 
     );
}


export default WhyTradeWarrant;