import React, { Fragment } from 'react';
import Seminar from './Seminar';
import "./seminar.css" 


const Seminars = (props) => {
    return ( 
        <Fragment> 
        <Seminar/>
        </Fragment> 
     );
}

export default Seminars;