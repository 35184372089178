import React, {useEffect, useState} from "react";
import Achor from "../../../../assets/images/bullet/qlink-achor.png";
import DWTips from "../../../../assets/images/tips/top-dwtips.jpg"
import { Link } from "react-router-dom";
import { config } from "../../../../utils/apiUrl"
import API from "../../../../utils/apiCalling";
import Slider from 'react-slick';
import { languageText } from "../../../../languageText/dwTips"
import DwVideoSideBars from "../../../../components/common/Sidebar/DwVideoSideBar"
import AdvertiseMentSidebar from "../../../../components/common/Sidebar/AdvertiseMentSidebar"

const DwTipssUpPages = (props) => {

  const [siteLang , setSiteLang] = useState("")
  const [typeValue, setTypeValue] = useState("")
  const [tableData, setTableData] = useState("")
  const api = new API();
  const { dataslider } = props

  const settings = {
    autoplay: false,
    infinite: false,
    rows: 25,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    var siteLanguage = $.cookie('applang');
    setSiteLang(siteLanguage)
    if(siteLanguage == "eng"){
      document.querySelector("body").classList.add("eng")
    }else{
      document.querySelector("body").classList.remove("eng")
    }
  }, []);

  const getDataUrl = () => {
    let urlData = new URLSearchParams(window.location.search)
    let typeVal =  urlData.get("type")
    setTypeValue(typeVal)
  }

  useEffect(() => {
    getDataUrl()
  }, []);

  const getApiData = async () => {
    if(typeValue != ""){
      let tipTableData = await api.get(config.DWTipsTableServlet, true, {
        type: typeValue,
        qid: Date.now(),
      });
      setTableData(tipTableData.tipsList)
    }
  }

  useEffect(() => {
    getApiData()
  },[typeValue])


  return (
    <div id="dwtipssup" className="pageobj" >
      <div className="breadcrumbbar">
        <div className="container-fluid">
          <ol className="breadcrumb">
            <li>
              <Link to="/home?dwedu">{siteLang == "eng" ? languageText.firstNavigationEn : languageText.firstNavigation}</Link>
            </li>
            <li className="active"> 
              {siteLang == "eng" ? (typeValue == "topic" ? languageText.tipssupAllTopicTipsEn : typeValue == "set" ? languageText.tipssupAllSetTipsEn : languageText.tipssupAllIndexTipsEn) 
              : (typeValue == "topic" ? languageText.tipssupAllTopicTips : typeValue == "set" ? languageText.tipssupAllSetTips : languageText.tipssupAllIndexTips)}
            </li>
          </ol>
        </div>
      </div>
      <div className="stripe-bg" />
      <div className="container-fluid">
        <div className="topimg hidden-sm hidden-xs">
          <img src={DWTips} />
        </div>
        <div className="row">
          <div className="col-md-8">
            <div id="tipssection" style={{ position: "relative" }} className="section">
              <div className="page-header">
                <h1>
                  {siteLang == "eng" ? (typeValue == "topic" ? languageText.tipssupAllTopicTipsEn : typeValue == "set" ? languageText.tipssupAllSetTipsEn : languageText.tipssupAllIndexTipsEn) 
                  : (typeValue == "topic" ? languageText.tipssupAllTopicTips : typeValue == "set" ? languageText.tipssupAllSetTips : languageText.tipssupAllIndexTips)}
                </h1>
              </div>
              <p className="section-p"></p>
              <div id="dwtipstbl" className="newstbl">
                <div className="newstbl-htxt">{siteLang == "eng" ? "All articles" : "All articles"}</div>
                <div className="newstbl-content">
                  <div id="newsswiper">
                    <Slider {...settings} ref={dataslider}>
                      {tableData && tableData.map((d, index) => {
                        return (
                          <div className="newstbl-row rowtmpl" key={index}>
                            <div className="newstbl-col-right">
                              <Link
                                className="newstbl-col-dt"
                                target="_blank"
                                style={{ color: "#333333" }}
                                to={`/education/individualtip?id=${d.id}&type=${typeValue}`}
                                dangerouslySetInnerHTML={{ __html: siteLang == "eng" ? d.en_title : d.thai_title}}
                              />
                              <Link
                                to={`/education/individualtip?id=${d.id}&type=${typeValue}`}
                                target="_blank"
                                className="newstbl-col-rm"
                              >
                                <img src={Achor} alt="icon img" />
                              </Link>
                            </div>
                          </div>
                        )
                      })}
                    </Slider>
                  </div>
                </div>
                <hr className="hr-hmgbm" />
                <p className="small">{siteLang == "eng" ? languageText.marketDelayEn : languageText.marketDelay}</p>
              </div>
            </div>
          </div>

          <div className="hidden-xs hidden-sm col-md-4">
            <DwVideoSideBars />
            <div className="section qlink-section">
              <div className="page-header">
                <h2 className="small">{siteLang == "eng" ? "Investor academy" : "แหล่งความรู้"}</h2>
              </div>
              <p className="section-p">
                {siteLang == "eng" ? "Everything you need to know about DW…" : "ทุกอย่างที่คุณต้องรู้เกี่ยวกับ DW อยู่ที่นี่…"}
              </p>
              <div className="section-qlink-img section-qlink-investoracademy">
                <Link
                  target="_blank"
                  to="/eduhtml"
                  className="section-qlink-achor"
                >
                  <img src={Achor} alt="icon img" />
                </Link>
              </div>
            </div>
            <div className="section qlink-section">
              <div className="page-header">
                <h2 className="small">{siteLang == "eng" ? "Index DW" : "Index DW"}</h2>
              </div>
              <p className="section-p">
                {siteLang == "eng" ? "What is an index DW and why is it so popular?" : "DW ที่อ้างอิงกับดัชนี คืออะไร ทำไมถึงได้รับความนิยม?"}
              </p>
              <div className="section-qlink-img section-qlink-indexdw">
                <Link
                  to="/education/indexdw"
                  className="section-qlink-achor"
                >
                  <img src={Achor} alt="icon img" />
                </Link>
              </div>
            </div>
            <AdvertiseMentSidebar />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DwTipssUpPages;
