import React, { useEffect, useState } from "react";
import { Switch, Route, Redirect, useLocation } from "react-router-dom";
import Footer from "../components/common/layout/Footer";
import Header from "../components/common/layout/Header";
import Helmet from "react-helmet";
import Home from "../pages/Home";
import Landing from "../pages/Landing";

import WarrantSearch from "../pages/WarrantTools/WarrantSearch";
import WarrantLiveMatrix from "../pages/WarrantTools/warrantLive";
import DwRankings from "../pages/WarrantTools/DwRanking";
import DwCompares from "../pages/WarrantTools/DwCompare"
import WarrantTerms from "../pages/WarrantTools/WarrantTerms";
import DwCalculators from "../pages/WarrantTools/DwCalculator"
import DwUnderlyings from "../pages/WarrantTools/DwUnderlying"

import UpdateStockNews from "../pages/WarrantMarket/UpdateStockNews";
import SP500Market from "../pages/WarrantMarket/SP500Market";
import DailHsiMarket from "../pages/WarrantMarket/DailyHsi";
import DailyHighlight from "../pages/WarrantMarket/DailyHighlight";
import DailyCommentryMarket from "../pages/WarrantMarket/DailyCommentryMarket";
import OvernightMarket from "../pages/WarrantMarket/OvernightMarket";
import Highlights from "../pages/WarrantMarket/Highlight/Highlights";
import Subscribe from "../pages/WarrantMarket/Subscribe";
import PhotoGallery from "../pages/WarrantMarket/PhotoGallery";
import PhotoDetail from "../pages/WarrantMarket/PhotoGallery/PhotoDetail";
import Activitie from "../pages/WarrantMarket/Activitie";
import HsifLights from "../pages/WarrantMarket/Activitie/HsifLight";
import Hsidimusms from "../pages/WarrantMarket/Activitie/HSIDIMSUM";
import FreeVoucher from "../pages/WarrantMarket/Activitie/FreeVoucher";
import Season4 from "../pages/WarrantMarket/Activitie/Season4";
import SetSeminar from "../pages/WarrantMarket/Activitie/SetSeminar";
import Season3 from "../pages/WarrantMarket/Activitie/Season3";
import Activitydata from "../pages/WarrantMarket/Activitie/ActivityDatas";

import WhyMacquarie from "../pages/WarrantEducation/WhyMacquarie";
import FrequentlyAskedQues from "../pages/WarrantEducation/FrequentlyAskedQues";
import FrequentlyAskedAnss from "../pages/WarrantEducation/InnerFaq";
import IndexDwPages from "../pages/WarrantEducation/IndexDW";
import Seminars from "../pages/WarrantEducation/Seminar";
import DwTipsPages from "../pages/WarrantEducation/DwTips";
import DwVideoPages from "../pages/WarrantEducation/DwVideo";
import DwTipssUp from "../pages/WarrantEducation/DwTips/DwTipssUp";
import IndividualTips from "../pages/WarrantEducation/DwTips/IndividualTip"
import DwVideoIndPages from "../pages/WarrantEducation/DwVideoInd"
import DwVideoSpeakers from "../pages/WarrantEducation/DwVideoSpeaker"
import DwVideoTestimonials from "../pages/WarrantEducation/DwVideoTestimonial"
// import DwTipSetPages from "../pages/WarrantEducation/DwTips/DwTipssUpSet/DwTipsSet";
// import DwTopicPages from "../pages/WarrantEducation/DwTips/DwTopic";

import InvestorAcademy from "../pages/WarrantEducation/InvestorAcademyWarrant";
import WhyTradeWarrant from "../pages/WarrantEducation/InvestorAcademyWarrant/WhyTradeWarrant";
import GearingEffect from "../pages/WarrantEducation/InvestorAcademyWarrant/WhyTradeWarrant/GearingEffect";
import LimitedLoss from "../pages/WarrantEducation/InvestorAcademyWarrant/WhyTradeWarrant/LimitedLoss";
import ProfitBullBear from "../pages/WarrantEducation/InvestorAcademyWarrant/WhyTradeWarrant/ProfitBullBear";
import NoMarginCall from "../pages/WarrantEducation/InvestorAcademyWarrant/WhyTradeWarrant/NoMarginCall";
import LowerCapitalOutlay from "../pages/WarrantEducation/InvestorAcademyWarrant/WhyTradeWarrant/LowerCapitalOutlay";
import ReleasingShareCapital from "../pages/WarrantEducation/InvestorAcademyWarrant/WhyTradeWarrant/ReleasingShareCapital";
import EaseTrade from "../pages/WarrantEducation/InvestorAcademyWarrant/WhyTradeWarrant/EaseTrade";

import WhatAreWarrant from "../pages/WarrantEducation/InvestorAcademyWarrant/WhatAreWarrant";
import CallPut from "../pages/WarrantEducation/InvestorAcademyWarrant/WhatAreWarrant/CallPut";
import HowCallWarrantWork from "../pages/WarrantEducation/InvestorAcademyWarrant/WhatAreWarrant/HowCallWarrantwork";
import HowPutWarrantWork from "../pages/WarrantEducation/InvestorAcademyWarrant/WhatAreWarrant/HowPutWarrantwork";
import HowWarrantsNamed from "../pages/WarrantEducation/InvestorAcademyWarrant/WhatAreWarrant/HowWarrantsNamed";

import HowWarrantsPriced from "../pages/WarrantEducation/InvestorAcademyWarrant/HowWarrantsPriced";
import SharePrice from "../pages/WarrantEducation/InvestorAcademyWarrant/HowWarrantsPriced/SharePrice";
import ExercisePrice from "../pages/WarrantEducation/InvestorAcademyWarrant/HowWarrantsPriced/ExercisePrice";
import ExpiryDate from "../pages/WarrantEducation/InvestorAcademyWarrant/HowWarrantsPriced/ExpiryDate";
import Volatility from "../pages/WarrantEducation/InvestorAcademyWarrant/HowWarrantsPriced/Volatility";
import InterestRate from "../pages/WarrantEducation/InvestorAcademyWarrant/HowWarrantsPriced/InterestRate";
import IntrinsicValueTimeValue from "../pages/WarrantEducation/InvestorAcademyWarrant/HowWarrantsPriced/IntrinsicValueTimeValue";
import TimeDecay from "../pages/WarrantEducation/InvestorAcademyWarrant/HowWarrantsPriced/TimeDecay";
import Moneynes from "../pages/WarrantEducation/InvestorAcademyWarrant/HowWarrantsPriced/Moneynes";
import TrackingWarrants from "../pages/WarrantEducation/InvestorAcademyWarrant/HowWarrantsPriced/TracingWarrant";
import DailyChanges from "../pages/WarrantEducation/InvestorAcademyWarrant/HowWarrantsPriced/DailyChange";

import UsefulWarrantTerm from "../pages/WarrantEducation/InvestorAcademyWarrant/UsefulWarrantTerm";
import Delta from "../pages/WarrantEducation/InvestorAcademyWarrant/UsefulWarrantTerm/Delta";
import ExerciseRatio from "../pages/WarrantEducation/InvestorAcademyWarrant/UsefulWarrantTerm/ExerciseRatio";
import WarrantSensitivitys from "../pages/WarrantEducation/InvestorAcademyWarrant/UsefulWarrantTerm/WarrantSensitivity";
import BreakevenPrice from "../pages/WarrantEducation/InvestorAcademyWarrant/UsefulWarrantTerm/BreakevenPrice";
import Premium from "../pages/WarrantEducation/InvestorAcademyWarrant/UsefulWarrantTerm/Premium";
import Gearing from "../pages/WarrantEducation/InvestorAcademyWarrant/UsefulWarrantTerm/Gearing";
import EffectiveGearing from "../pages/WarrantEducation/InvestorAcademyWarrant/UsefulWarrantTerm/EffectiveGearing";
import ImpliedVolatility from "../pages/WarrantEducation/InvestorAcademyWarrant/UsefulWarrantTerm/ImpliedVolatility";

import HowSelectWarrant from "../pages/WarrantEducation/InvestorAcademyWarrant/HowSelectWarrant";
import SelectingWarrant from "../pages/WarrantEducation/InvestorAcademyWarrant/HowSelectWarrant/SelectingWarrant";
import SelectingWarrantSecond from "../pages/WarrantEducation/InvestorAcademyWarrant/HowSelectWarrant/SelectingWarrantSecond";
import SelectingWarrantThird from "../pages/WarrantEducation/InvestorAcademyWarrant/HowSelectWarrant/SelectingWarrantThird";
import SelectingWarrantFour from "../pages/WarrantEducation/InvestorAcademyWarrant/HowSelectWarrant/SelectingWarrantFour";
import MakerMarkets from "../pages/WarrantEducation/InvestorAcademyWarrant/HowSelectWarrant/MakerMarkets";
import RiskMatrix from "../pages/WarrantEducation/InvestorAcademyWarrant/HowSelectWarrant/RiskMatrix";
import TipsTool from "../pages/WarrantEducation/InvestorAcademyWarrant/HowSelectWarrant/TipsTool";

import SingleShareExpiry from "../pages/WarrantEducation/InvestorAcademyWarrant/SingleShareExpiry";
import LastTradingDay from "../pages/WarrantEducation/InvestorAcademyWarrant/SingleShareExpiry/LastTradingDay";
import CallExpiryFormula from "../pages/WarrantEducation/InvestorAcademyWarrant/SingleShareExpiry/CallExpiryFormula";
import CallExample from "../pages/WarrantEducation/InvestorAcademyWarrant/SingleShareExpiry/CallExample";
import PutExample from "../pages/WarrantEducation/InvestorAcademyWarrant/SingleShareExpiry/PutExample";

import Risk from "../pages/WarrantEducation/InvestorAcademyWarrant/Risk";
import MarketRisk from "../pages/WarrantEducation/InvestorAcademyWarrant/Risk/MarketRisk";
import RiskGearing from "../pages/WarrantEducation/InvestorAcademyWarrant/Risk/RiskGearing";
import LimitedLife from "../pages/WarrantEducation/InvestorAcademyWarrant/Risk/LimitedLife";
import CreditRisk from "../pages/WarrantEducation/InvestorAcademyWarrant/Risk/CreditRisk";
import TakeOver from "../pages/WarrantEducation/InvestorAcademyWarrant/Risk/TakeOver";

import MarketMaker from "../pages/WarrantEducation/InvestorAcademyWarrant/MarketMaker";
import HowCalculate from "../pages/WarrantEducation/InvestorAcademyWarrant/MarketMaker/HowCalculate";
import LiquidityVolume from "../pages/WarrantEducation/InvestorAcademyWarrant/MarketMaker/LiquidityVolume";
import HowHedge from "../pages/WarrantEducation/InvestorAcademyWarrant/MarketMaker/HowHedge";

import TurnOverDistribution from "../pages/Warrant/TurnOverDistribution";
import MarketTurnOver from "../pages/Warrant/MarketTurnOver";
import Toptradedstocks from "../pages/Warrant/Toptradedstock";
import Toptradewarrants from "../pages/Warrant/Toptradewarrants";
import HeaderMq from "../components/common/layout/HeaderMq";

import Disclaimer from "../pages/About/Disclaimer"
import ContactUs from "../pages/About/ContactUs"
import TermUse from "../pages/About/TermUse"
import PrivacyPolicy from "../pages/About/PrivacyPolicy"
import ImportantInformation from "../pages/About/ImportantInformation"

import UnSubscribe from "../pages/UnSubscribe"
import SearchCom from "../pages/Search/Search"

const UnAuthorisedApp = (props) => {
  const location = useLocation();
  const [header, setHeader] = useState(true);
  const [siteLang , setSiteLang] = useState("")

  useEffect(() => {
    var siteLanguage = $.cookie('applang');
    setSiteLang(siteLanguage)
    if(siteLanguage == "eng"){
      document.querySelector("body").classList.add("eng")
    }else{
      document.querySelector("body").classList.remove("eng")
    }
  }, [])

  useEffect(() => {
    const locationPath = location.pathname;
    const matchedLocationPath = locationPath.match(/eduhtml/);
    if(matchedLocationPath){
      setHeader(false);
    }else{
      setHeader(true);
    }
  },[location])

  function RouteWithTitle({ title, ...props }) {
    return (
      <>
        <Helmet>
          <title>{title} </title>
          {/* <meta property="og:title" content={title} />
          <meta name="description" content={title} /> */}
        </Helmet>
        <Route {...props} />
      </>
    );
  }

  return (
    <>
      {header ? <Header /> : <HeaderMq /> }
      <Switch>
        <RouteWithTitle
          exact
          title={siteLang == "thai" ? "แมคควอรี DW28 | Macquarie" : "แมคควอรี DW28 | Macquarie"}
          path="/home"
          component={(props) => <Home {...props} />}
        />
        <RouteWithTitle
          exact
          title={siteLang == "thai" ? "แมคควอรี DW28 | Macquarie" : "แมคควอรี DW28 | Macquarie"}
          path="/home/:id"
          component={(props) => <Home Location={location} {...props} />}
        />

        <RouteWithTitle
          exact
          title={siteLang == "thai" ? "แมคควอรี DW28 | Macquarie" : "แมคควอรี DW28 | Macquarie"}
          path="/education/landing"
          component={(props) => <Landing Location={location} {...props} />}
        />

        <RouteWithTitle
          exact
          title={siteLang == "thai" ? "ข้อมูลสำคัญ" : "Important information"}
          path="/about/importantinformation"
          component={(props) => <ImportantInformation {...props} />}
        />
        <RouteWithTitle
          exact
          title={siteLang == "thai" ? "นโยบายความเป็นส่วนตัว" : "Privacy policy"}
          path="/about/privacypolicy"
          component={(props) => <PrivacyPolicy {...props} />}
        />
        <RouteWithTitle
          exact
          title={siteLang == "thai" ? "ข้อกำหนดการใช้งาน" : "Terms of use"}
          path="/about/termsofuse"
          component={(props) => <TermUse {...props} />}
        />
        <RouteWithTitle
          exact
          title={siteLang == "thai" ? "ติดต่อเรา" : "Contact us"}
          path="/about/contactus"
          component={(props) => <ContactUs {...props} />}
        />
        <RouteWithTitle
          exact
          title={siteLang == "thai" ? "คำเตือน" : "Disclaimer"}
          path="/about/disclaimer"
          component={(props) => <Disclaimer {...props} />}
        />

        <RouteWithTitle
          exact
          title={siteLang == "thai" ? "ค้นหาข้อมูล DW  | Macquarie" : "ค้นหาข้อมูล DW  | Macquarie"}
          path="/tools/dwsearch"
          component={() => <WarrantSearch />}
        />
        <RouteWithTitle
          exact
          title={siteLang == "thai" ? "ค้นหาข้อมูล DW  | Macquarie" : "ค้นหาข้อมูล DW  | Macquarie"}
          path="/tools/dwsearch/:id"
          component={() => <WarrantSearch />}
        />
        <RouteWithTitle
          exact
          title={siteLang == "thai" ? "ตารางราคา DW | Macquarie" : "ตารางราคา DW | Macquarie"}
          path="/tools/livematrix"
          component={() => <WarrantLiveMatrix />}
        />
        <RouteWithTitle
          exact
          title={siteLang == "thai" ? "ตารางราคา DW | Macquarie" : "ตารางราคา DW | Macquarie"}
          path="/tools/livematrix/:id"
          component={() => <WarrantLiveMatrix />}
        />
        <RouteWithTitle
          exact
          title={siteLang == "thai" ? "ข้อมูลเกี่ยวกับ DW | Macquarie" : "ข้อมูลเกี่ยวกับ DW | Macquarie"}
          path="/tools/dwterms"
          component={() => <WarrantTerms />}
        />
        <RouteWithTitle
          exact
          title={siteLang == "thai" ? "ข้อมูลเกี่ยวกับ DW | Macquarie" : "ข้อมูลเกี่ยวกับ DW | Macquarie"}
          path="/tools/dwterms/:id"
          component={() => <WarrantTerms />}
        />
        <RouteWithTitle
          exact
          title={siteLang == "thai" ? "คำนวณราคา DW | Macquarie" : "คำนวณราคา DW | Macquarie"}
          path="/tools/dwcalculator"
          component={() => <DwCalculators />}
        />
        <RouteWithTitle
          exact
          title={siteLang == "thai" ? "คำนวณราคา DW | Macquarie" : "คำนวณราคา DW | Macquarie"}
          path="/tools/dwcalculator/:id"
          component={() => <DwCalculators />}
        />
        <RouteWithTitle
          exact
          title={siteLang == "thai" ? "DW28 แบ่งตามหลักทรัพย์อ้างอิง | Macquarie" : "DW28 แบ่งตามหลักทรัพย์อ้างอิง | Macquarie"}
          path="/tools/dwranking"
          component={() => <DwRankings />}
        />
        <RouteWithTitle
          exact
          title={siteLang == "thai" ? "DW28 แบ่งตามหลักทรัพย์อ้างอิง | Macquarie" : "DW28 แบ่งตามหลักทรัพย์อ้างอิง | Macquarie"}
          path="/tools/dwranking/:id"
          component={() => <DwRankings />}
        />
        <RouteWithTitle
          exact
          title={siteLang == "thai" ? "เปรียบเทียบ DW | Macquarie" : "เปรียบเทียบ DW | Macquarie"}
          path="/tools/dwcompare"
          component={() => <DwCompares />}
        />
        <RouteWithTitle
          exact
          title={siteLang == "thai" ? "ข้อมูลเกี่ยวกับ DW | Macquarie" : "ข้อมูลเกี่ยวกับ DW | Macquarie"}
          path="/tools/underlying"
          component={() => <DwUnderlyings />}
        />
        <RouteWithTitle
          exact
          title={siteLang == "thai" ? "ข้อมูลเกี่ยวกับ DW | Macquarie" : "ข้อมูลเกี่ยวกับ DW | Macquarie"}
          path="/tools/underlying/:id"
          component={() => <DwUnderlyings />}
        />


        <RouteWithTitle
          title={siteLang == "thai" ? "" : ""}
          exact
          path="/activities/HSIDIMSUM"
          component={(props) => <Hsidimusms {...props} />}
        />
        <RouteWithTitle
          title={siteLang == "thai" ? "เทรดง่าย ได้เลย!" : "เทรดง่าย ได้เลย!"}
          exact
          path="/activities/dw28freevoucher"
          component={(props) => <FreeVoucher {...props} />}
        />
        <RouteWithTitle
          title={siteLang == "thai" ? "Super Trader Season 4" : "Super Trader Season 4"}
          exact
          path="/supertrader/season4"
          component={(props) => <Season4 {...props} />}
        />
        <RouteWithTitle
          title={siteLang == "thai" ? "" : ""}
          exact
          path="/education/set50seminar"
          component={(props) => <SetSeminar {...props} />}
        />
        <RouteWithTitle
          title={siteLang == "thai" ? "Super Trader Season 3" : "Super Trader Season 3"}
          exact
          path="/supertrader/season3"
          component={(props) => <Season3 {...props} />}
        />

        {/* Warrant Education All Pages */}
        <RouteWithTitle
          title={siteLang == "thai" ? "ทำไมถึงเลือก DW28 | Macquarie" : "ทำไมถึงเลือก DW28 | Macquarie"}
          exact
          path="/education/whydw28"
          component={(props) => <WhyMacquarie {...props} />}
        />
        <RouteWithTitle
          title={siteLang == "thai" ? "คำถามที่พบบ่อยเกี่ยวกับ DW | Macquarie" : "คำถามที่พบบ่อยเกี่ยวกับ DW | Macquarie"}
          exact
          path="/education/faq"
          component={(props) => <FrequentlyAskedQues {...props} />}
        />
        <RouteWithTitle
          title={siteLang == "thai" ? "DW Tips" : "DW Tips"}
          exact
          path="/education/innerfaq"
          component={(props) => <FrequentlyAskedAnss {...props} />}
        />
        <RouteWithTitle
          title={siteLang == "thai" ? "บทความสรุป index DW | Macquarie" : "บทความสรุป index DW | Macquarie"}
          exact
          path="/education/indexdw"
          component={(props) => <IndexDwPages {...props} />}
        />
        <RouteWithTitle
          title={siteLang == "thai" ? "DW Tips | Macquarie" : "DW Tips | Macquarie"}
          exact
          path="/education/dwtips"
          component={(props) => <DwTipsPages {...props} />}
        />
        <RouteWithTitle
          title={siteLang == "thai" ? "VDO ความรู้และงานสัมมนา | Macquarie" : "VDO ความรู้และงานสัมมนา | Macquarie"}
          exact
          path="/education/dwvideo"
          component={(props) => <DwVideoPages {...props} />}
        />
        <RouteWithTitle
          title={siteLang == "thai" ? "VDO ความรู้และงานสัมมนา | Macquarie" : "VDO ความรู้และงานสัมมนา | Macquarie"}
          exact
          path="/education/dwvideoind"
          component={(props) => <DwVideoIndPages {...props} />}
        />
        <RouteWithTitle
          title={siteLang == "thai" ? "VDO ความรู้และงานสัมมนา | Macquarie" : "VDO ความรู้และงานสัมมนา | Macquarie"}
          exact
          path="/education/dwvideoind:id"
          component={(props) => <DwVideoIndPages {...props} />}
        />
        <RouteWithTitle
          title={siteLang == "thai" ? "วิดีโอความรู้เรื่อง DW" : "DW video"}
          exact
          path="/education/speaker"
          component={(props) => <DwVideoSpeakers {...props} />}
        />
        <RouteWithTitle
          title={siteLang == "thai" ? "วิดีโอความรู้เรื่อง DW" : "DW video"}
          exact
          path="/education/speaker:id"
          component={(props) => <DwVideoSpeakers {...props} />}
        />
        <RouteWithTitle
          title={siteLang == "thai" ? "วิดีโอความรู้เรื่อง DW" : "DW video"}
          exact
          path="/education/testimonial"
          component={(props) => <DwVideoTestimonials {...props} />}
        />
        <RouteWithTitle
          title={siteLang == "thai" ? "วิดีโอความรู้เรื่อง DW" : "DW video"}
          exact
          path="/education/testimonial:id"
          component={(props) => <DwVideoTestimonials {...props} />}
        />
        <RouteWithTitle
          title={siteLang == "thai" ? "" : ""}
          exact
          path="/education/dwtipssup"
          component={(props) => <DwTipssUp {...props} />}
        />
        <RouteWithTitle
          title={siteLang == "thai" ? "DW Tips" : "DW Tips"}
          exact
          path="/education/individualtip"
          component={(props) => <IndividualTips {...props} />}
        />
        <RouteWithTitle
          title={siteLang == "thai" ? "สัมมนากับแมคควอรี DW28 | Macquarie" : "สัมมนากับแมคควอรี DW28 | Macquarie"}
          exact
          path="/education/seminar"
          component={(props) => <Seminars {...props} />}
        />

        <RouteWithTitle
          title={siteLang == "thai" ? "Macquarie Education" : "Macquarie Education"}
          exact
          path="/eduhtml"
          component={(props) => <InvestorAcademy {...props} />}
        />
        <RouteWithTitle
          title={siteLang == "thai" ? "Macquarie Education" : "Macquarie Education"}
          exact
          path="/eduhtml/whytradewarrants"
          component={(props) => <WhyTradeWarrant {...props} />}
        />
        <RouteWithTitle
          title={siteLang == "thai" ? "Macquarie Education" : "Macquarie Education"}
          exact
          path="/eduhtml/gearingeffect"
          component={(props) => <GearingEffect {...props} />}
        />
        <RouteWithTitle
          title={siteLang == "thai" ? "Macquarie Education" : "Macquarie Education"}
          exact
          path="/eduhtml/limitedloss"
          component={(props) => <LimitedLoss {...props} />}
        />
        <RouteWithTitle
          title={siteLang == "thai" ? "Macquarie Education" : "Macquarie Education"}
          exact
          path="/eduhtml/porfitfrombullorbearmarket"
          component={(props) => <ProfitBullBear {...props} />}
        />
        <RouteWithTitle
          title={siteLang == "thai" ? "Macquarie Education" : "Macquarie Education"}
          exact
          path="/eduhtml/nomargincall"
          component={(props) => <NoMarginCall {...props} />}
        />
        <RouteWithTitle
          title={siteLang == "thai" ? "Macquarie Education" : "Macquarie Education"}
          exact
          path="/eduhtml/lowercapitaloutlay"
          component={(props) => <LowerCapitalOutlay {...props} />}
        />
        <RouteWithTitle
          title={siteLang == "thai" ? "Macquarie Education" : "Macquarie Education"}
          exact
          path="/eduhtml/releasingsharecapital"
          component={(props) => <ReleasingShareCapital {...props} />}
        />
        <RouteWithTitle
          title={siteLang == "thai" ? "Macquarie Education" : "Macquarie Education"}
          exact
          path="/eduhtml/easeoftrade"
          component={(props) => <EaseTrade {...props} />}
        />

        <RouteWithTitle
          title={siteLang == "thai" ? "Macquarie Education" : "Macquarie Education"}
          exact
          path="/eduhtml/whatarewarrants"
          component={(props) => <WhatAreWarrant {...props} />}
        />
        <RouteWithTitle
          title={siteLang == "thai" ? "Macquarie Education" : "Macquarie Education"}
          exact
          path="/eduhtml/callvsput"
          component={(props) => <CallPut {...props} />}
        />
        <RouteWithTitle
          title={siteLang == "thai" ? "Macquarie Education" : "Macquarie Education"}
          exact
          path="/eduhtml/howacallwarrantworks"
          component={(props) => <HowCallWarrantWork {...props} />}
        />
        <RouteWithTitle
          title={siteLang == "thai" ? "Macquarie Education" : "Macquarie Education"}
          exact
          path="/eduhtml/howaputwarrantworks" 
          component={(props) => <HowPutWarrantWork {...props} />}
        />
        <RouteWithTitle
          title={siteLang == "thai" ? "Macquarie Education" : "Macquarie Education"}
          exact
          path="/eduhtml/howarewarrantsnamed"
          component={(props) => <HowWarrantsNamed {...props} />}
        />

        <RouteWithTitle
          title={siteLang == "thai" ? "Macquarie Education" : "Macquarie Education"}
          exact
          path="/eduhtml/howarewarrantspriced"
          component={(props) => <HowWarrantsPriced {...props} />}
        />
        <RouteWithTitle
          title={siteLang == "thai" ? "Macquarie Education" : "Macquarie Education"}
          exact
          path="/eduhtml/shareprice"
          component={(props) => <SharePrice {...props} />}
        />
        <RouteWithTitle
          title={siteLang == "thai" ? "Macquarie Education" : "Macquarie Education"}
          exact
          path="/eduhtml/exerciseprice"
          component={(props) => <ExercisePrice {...props} />}
        />
        <RouteWithTitle
          title={siteLang == "thai" ? "Macquarie Education" : "Macquarie Education"}
          exact
          path="/eduhtml/expirydate"
          component={(props) => <ExpiryDate {...props} />}
        />
        <RouteWithTitle
          title={siteLang == "thai" ? "Macquarie Education" : "Macquarie Education"}
          exact
          path="/eduhtml/volatility"
          component={(props) => <Volatility {...props} />}
        />
        <RouteWithTitle
          title={siteLang == "thai" ? "Macquarie Education" : "Macquarie Education"}
          exact
          path="/eduhtml/interestrates"
          component={(props) => <InterestRate {...props} />}
        />
        <RouteWithTitle
          title={siteLang == "thai" ? "Macquarie Education" : "Macquarie Education"}
          exact
          path="/eduhtml/intrinsicvalueandtimevalue"
          component={(props) => <IntrinsicValueTimeValue {...props} />}
        />
        <RouteWithTitle
          title={siteLang == "thai" ? "Macquarie Education" : "Macquarie Education"}
          exact
          path="/eduhtml/timedecay"
          component={(props) => <TimeDecay {...props} />}
        />
        <RouteWithTitle
          title={siteLang == "thai" ? "Macquarie Education" : "Macquarie Education"}
          exact
          path="/eduhtml/moneyness"
          component={(props) => <Moneynes {...props} />}
        />
        <RouteWithTitle
          title={siteLang == "thai" ? "Macquarie Education" : "Macquarie Education"}
          exact
          path="/eduhtml/trackingwarrantspricechangelastprice"
          component={(props) => <TrackingWarrants {...props} />}
        />
        <RouteWithTitle
          title={siteLang == "thai" ? "Macquarie Education" : "Macquarie Education"}
          exact
          path="/eduhtml/calculatingdwdailychange" 
          component={(props) => <DailyChanges {...props} />}
        />

        <RouteWithTitle
          title={siteLang == "thai" ? "Macquarie Education" : "Macquarie Education"}
          exact
          path="/eduhtml/usefulwarrantterms"
          component={(props) => <UsefulWarrantTerm {...props} />}
        />
        <RouteWithTitle
          title={siteLang == "thai" ? "Macquarie Education" : "Macquarie Education"}
          exact
          path="/eduhtml/delta"
          component={(props) => <Delta {...props} />}
        />
        <RouteWithTitle
          title={siteLang == "thai" ? "Macquarie Education" : "Macquarie Education"}
          exact
          path="/eduhtml/exerciseratio"
          component={(props) => <ExerciseRatio {...props} />}
        />
        <RouteWithTitle
          title={siteLang == "thai" ? "Macquarie Education" : "Macquarie Education"}
          exact
          path="/eduhtml/warrantsensitivity"
          component={(props) => <WarrantSensitivitys {...props} />}
        />
        <RouteWithTitle
          title={siteLang == "thai" ? "Macquarie Education" : "Macquarie Education"}
          exact
          path="/eduhtml/breakevenprice"
          component={(props) => <BreakevenPrice {...props} />}
        />
        <RouteWithTitle
          title={siteLang == "thai" ? "Macquarie Education" : "Macquarie Education"}
          exact
          path="/eduhtml/premium"
          component={(props) => <Premium {...props} />}
        />
        <RouteWithTitle
          title={siteLang == "thai" ? "Macquarie Education" : "Macquarie Education"}
          exact
          path="/eduhtml/gearing"
          component={(props) => <Gearing {...props} />}
        />
        <RouteWithTitle
          title={siteLang == "thai" ? "Macquarie Education" : "Macquarie Education"}
          exact
          path="/eduhtml/effectivegearing"
          component={(props) => <EffectiveGearing {...props} />}
        />
        <RouteWithTitle
          title={siteLang == "thai" ? "Macquarie Education" : "Macquarie Education"}
          exact
          path="/eduhtml/impliedvolatility"
          component={(props) => <ImpliedVolatility {...props} />}
        />

        <RouteWithTitle
          title={siteLang == "thai" ? "Macquarie Education" : "Macquarie Education"}
          exact
          path="/eduhtml/howtoselectawarrant"
          component={(props) => <HowSelectWarrant {...props} />}
        />
        <RouteWithTitle
          title={siteLang == "thai" ? "Macquarie Education" : "Macquarie Education"}
          exact
          path="/eduhtml/fourviewonunderlyingshareorindex"
          component={(props) => <SelectingWarrant {...props} />}
        />
        <RouteWithTitle
          title={siteLang == "thai" ? "Macquarie Education" : "Macquarie Education"}
          exact
          path="/eduhtml/fourtimeframe"
          component={(props) => <SelectingWarrantSecond {...props} />}
        />
        <RouteWithTitle
          title={siteLang == "thai" ? "Macquarie Education" : "Macquarie Education"}
          exact
          path="/eduhtml/fourgearinglevel"
          component={(props) => <SelectingWarrantThird {...props} />}
        />
        <RouteWithTitle
          title={siteLang == "thai" ? "Macquarie Education" : "Macquarie Education"}
          exact
          path="/eduhtml/fourimpliedvolatility"
          component={(props) => <SelectingWarrantFour {...props} />}
        />
        <RouteWithTitle
          title={siteLang == "thai" ? "Macquarie Education" : "Macquarie Education"}
          exact
          path="/eduhtml/qualityofamarketmaker"
          component={(props) => <MakerMarkets {...props} />}
        />
        <RouteWithTitle
          title={siteLang == "thai" ? "Macquarie Education" : "Macquarie Education"}
          exact
          path="/eduhtml/riskmatrix"
          component={(props) => <RiskMatrix {...props} />}
        />
        <RouteWithTitle
          title={siteLang == "thai" ? "Macquarie Education" : "Macquarie Education"}
          exact
          path="/eduhtml/tipsandtools"
          component={(props) => <TipsTool {...props} />}
        />

        <RouteWithTitle
          title={siteLang == "thai" ? "Macquarie Education" : "Macquarie Education"}
          exact
          path="/eduhtml/singlesharecallsandputsatexpiry"
          component={(props) => <SingleShareExpiry {...props} />}
        />
        <RouteWithTitle
          title={siteLang == "thai" ? "Macquarie Education" : "Macquarie Education"}
          exact
          path="/eduhtml/warrantsatexpirylasttradingday"
          component={(props) => <LastTradingDay {...props} />}
        />
        <RouteWithTitle
          title={siteLang == "thai" ? "Macquarie Education" : "Macquarie Education"}
          exact
          path="/eduhtml/cashsettlementamountforsinglesharewarrantsatexpiry"
          component={(props) => <CallExpiryFormula {...props} />}
        />
        <RouteWithTitle
          title={siteLang == "thai" ? "Macquarie Education" : "Macquarie Education"}
          exact
          path="/eduhtml/singlesharecallwarrantexample"
          component={(props) => <CallExample {...props} />}
        />
        <RouteWithTitle
          title={siteLang == "thai" ? "Macquarie Education" : "Macquarie Education"}
          exact
          path="/eduhtml/singleshareputwarrantexample"
          component={(props) => <PutExample {...props} />}
        />

        <RouteWithTitle
          title={siteLang == "thai" ? "Macquarie Education" : "Macquarie Education"}
          exact
          path="/eduhtml/risk"
          component={(props) => <Risk {...props} />}
        />
        <RouteWithTitle
          title={siteLang == "thai" ? "Macquarie Education" : "Macquarie Education"}
          exact
          path="/eduhtml/marketrisk"
          component={(props) => <MarketRisk {...props} />}
        />
        <RouteWithTitle
          title={siteLang == "thai" ? "Macquarie Education" : "Macquarie Education"}
          exact
          path="/eduhtml/riskgearing"
          component={(props) => <RiskGearing {...props} />}
        />
        <RouteWithTitle
          title={siteLang == "thai" ? "Macquarie Education" : "Macquarie Education"}
          exact
          path="/eduhtml/limitedlife"
          component={(props) => <LimitedLife {...props} />}
        />
        <RouteWithTitle
          title={siteLang == "thai" ? "Macquarie Education" : "Macquarie Education"}
          exact
          path="/eduhtml/creditrisk"
          component={(props) => <CreditRisk {...props} />}
        />
        <RouteWithTitle
          title={siteLang == "thai" ? "Macquarie Education" : "Macquarie Education"}
          exact
          path="/eduhtml/takeovers"
          component={(props) => <TakeOver {...props} />}
        />

        <RouteWithTitle
          title={siteLang == "thai" ? "Macquarie Education" : "Macquarie Education"}
          exact
          path="/eduhtml/marketmakers"
          component={(props) => <MarketMaker {...props} />}
        />
        <RouteWithTitle
          title={siteLang == "thai" ? "Macquarie Education" : "Macquarie Education"}
          exact
          path="/eduhtml/howtocalculatecashsettlementamount"
          component={(props) => <HowCalculate {...props} />}
        />
        <RouteWithTitle
          title={siteLang == "thai" ? "Macquarie Education" : "Macquarie Education"}
          exact
          path="/eduhtml/liquidityvstradedvolume"
          component={(props) => <LiquidityVolume {...props} />}
        />
        <RouteWithTitle
          title={siteLang == "thai" ? "Macquarie Education" : "Macquarie Education"}
          exact
          path="/eduhtml/howdomarketmakershedge"
          component={(props) => <HowHedge {...props} />}
        />
        {/* Warrant Education All Pages  */}

        {/* Warrant All Pages */}
        <RouteWithTitle
          title={siteLang == "thai" ? "หลักทรัพย์อ้างอิงของ DW | Macquarie" : "หลักทรัพย์อ้างอิงของ DW | Macquarie"}
          exact
          path="/marketdata/turnoverdist"
          component={(props) => <TurnOverDistribution {...props} />}
        />
        <RouteWithTitle
          title={siteLang == "thai" ? "สรุปมูลค่าตลาด DW | Macquarie" : "สรุปมูลค่าตลาด DW | Macquarie"}
          exact
          path="/marketdata/dwturnover"
          component={(props) => <MarketTurnOver {...props} />}
        />
        <RouteWithTitle
          title={siteLang == "thai" ? "10 อันดับหลักทรัพย์อ้างอิง ที่มีมูลค่าการซื้อขายสูงสุด | Macquarie" : "10 อันดับหลักทรัพย์อ้างอิง ที่มีมูลค่าการซื้อขายสูงสุด | Macquarie"}
          exact
          path="/marketdata/toptradedstock"
          component={(props) => <Toptradedstocks {...props} />}
        />
        <RouteWithTitle
          title={siteLang == "thai" ? "10 อันดับ DW ที่มีปริมาณการซื้อขายสูงสุด | Macquarie" : "10 อันดับ DW ที่มีปริมาณการซื้อขายสูงสุด | Macquarie"}
          exact
          path="/marketdata/toptradewarrants"
          component={(props) => <Toptradewarrants {...props} />}
        />

        {/* Warrant All Pages  */}
        <RouteWithTitle
          exact
          title={siteLang == "thai" ? "" : ""}
          path="/marketnews/dailysinglestock"
          component={() => <UpdateStockNews />}
        />
        {/* <RouteWithTitle
          title={siteLang == "thai" ? "" : ""}
          exact
          path="/marketnews/dailysp500"
          component={(props) => <SP500Market {...props} />}
        /> */}
        <RouteWithTitle
          title={siteLang == "thai" ? "" : ""}
          exact
          path="/marketnews/dailyhsi"
          component={(props) => <DailHsiMarket {...props} />}
        />
        <RouteWithTitle
          title={siteLang == "thai" ? "อัพเดทข่าวสารล่าสุด | Macquarie" : "อัพเดทข่าวสารล่าสุด | Macquarie"}
          exact
          path="/marketnews/todayhighlight"
          component={(props) => <DailyHighlight {...props} />}
        />
        {/* <RouteWithTitle
          title={siteLang == "thai" ? "สรุปภาพรวมตลาด | Macquarie" : "สรุปภาพรวมตลาด | Macquarie"}
          exact
          path="/marketnews/dailycommentary"
          component={(props) => <DailyCommentryMarket {...props} />}
        /> */}
        {/* <RouteWithTitle
          title={siteLang == "thai" ? "ข่าวล่าสุดเกี่ยวกับตลาดหุ้นทั่วโลก | Macquarie" : "ข่าวล่าสุดเกี่ยวกับตลาดหุ้นทั่วโลก | Macquarie"}
          exact
          path="/marketnews/overnightmarkets"
          component={(props) => <OvernightMarket {...props} />}
        /> */}
        <RouteWithTitle
          title={siteLang == "thai" ? "สรุปตลาด DW" : "DW market commentary"}
          exact
          path="/marketnews/highlight"
          component={(props) => <Highlights {...props} />}
        />
        <RouteWithTitle
          exact
          title={siteLang == "thai" ? "สรุปตลาด DW" : "DW market commentary"}
          path="/marketnews/highlight?cat=:id/:id"
          component={() => <Highlights />}
        />
        <RouteWithTitle
          title={siteLang == "thai" ? "จดหมายข่าวจาก DW28 | Macquarie" : "จดหมายข่าวจาก DW28 | Macquarie"}
          exact
          path="/marketnews/subscribe"
          component={(props) => <Subscribe {...props} />}
        />
        <RouteWithTitle
          title={siteLang == "thai" ? "ภาพกิจกรรมของแมคควอรี DW28 | Macquarie" : "ภาพกิจกรรมของแมคควอรี DW28 | Macquarie"}
          exact
          path="/marketnews/photogallery"
          component={(props) => <PhotoGallery {...props} />}
        />
        <RouteWithTitle
          title={siteLang == "thai" ? "ภาพกิจกรรม" : "Photo gallery"}
          exact
          path="/marketnews/individualgallery"
          component={(props) => <PhotoDetail {...props} />}
        />
        <RouteWithTitle
          title={siteLang == "thai" ? "กิจกรรมของแมคควอรี DW28 | Macquarie" : "กิจกรรมของแมคควอรี DW28 | Macquarie"}
          exact
          path="/marketnews/activities"
          component={(props) => <Activitie {...props} />}
        />
        <RouteWithTitle
          title={siteLang == "thai" ? "" : ""}
          exact
          path="/activities/HSIFlight"
          component={(props) => <HsifLights {...props} />}
        />

        <RouteWithTitle
          title={siteLang == "thai" ? "DW Tips" : "DW Tips"}
          exact
          path="/marketnews/activitiesdata"
          component={(props) => <Activitydata {...props} />}
        />

        <RouteWithTitle
          title={siteLang == "thai" ? "ยกเลิกการสมัคร" : "Unsubscribe"}
          exact
          path="/unsubscribe"
          component={(props) => <UnSubscribe {...props} />}
        />

        <RouteWithTitle
          title={siteLang == "thai" ? "Page search" : "Page search"}
          exact
          path="/search"
          component={(props) => <SearchCom {...props} />}
        />
        <RouteWithTitle
          title={siteLang == "thai" ? "Page search" : "Page search"}
          exact
          path="/search/:id"
          component={(props) => <SearchCom {...props} />}
        />

        <Redirect
          to={{
            pathname: "/home",
            state: { from: location },
          }}
        />
      </Switch>
      <Footer />
    </>
  );
};

export default UnAuthorisedApp;
