export const languageText ={

    pageTitle: "วิธีการเลือก DW",
    pageTitleEn: "How to select a DW?",

    pageName: "คุณภาพของผู้ดูแลสภาพคล่อง",
    pageNameEn: "Quality of a Market Maker",

    pageDescription: "คุณภาพและชื่อเสียงของผู้ดูแลสภาพคล่องเป็นอีกหนึ่งปัจจัยสำคัญในการพิจารณาเลือกลงทุนใน DW คุณต้องมั่นใจว่าผุ้ดูแลสภาพคล่องจะคอยวางราคาที่สอดคล้องกับราคาหุ้นอ้างอิงตลอดอายุ DW ซึ่งจะทำให้คุณสามารถซื้อขาย DW ได้ตลอดเวลาในราคายุติธรรม",
    pageDescriptionEn: "The quality and reputation of the market maker is also an important consideration when choosing which DW to trade. You need to be confident that the market maker will be there to provide consistent pricing throughout the life of the DW so that you can enter/exit the trade in a fair and liquid market.",
    
    pageDescription1: "แมคควอรีเป็นผู้นำการออก DW ซึ่งมีส่วนแบ่งตลาดสูงในตลาดสิงคโปร์ มาเลเซีย และฮ่องกง ความสำเร็จนี้เกิดจากความใส่ใจต่อนักลงทุนของเรา ซึ่งสะท้อนออกมาในรูปแบบของคุณภาพการดูแลสภาพคล่องที่ดี",
    pageDescription1En: "Macquarie has a leading market share in the Thailand DW market and much of this success is due to the quality of our market making, something we are very proud of.",

    imgUrl: "/img/education/img/creditrisk1.png",
    imgUrlEn: "/img/education/img/creditrisk1.png",
    
}