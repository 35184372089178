export const languageText ={
    // Menu Heading
    toolHeading : "เครื่องมือ DW",
    toolHeadingEn : "DW tools",
    statHeading : "สถิติ DW",
    statHeadingEn : "DW statistics",
    eduHeading : "รอบรู้เรื่อง DW",
    eduHeadingEn : "DW education",
    newsHeading : "อัพเดทตลาดหุ้น",
    newsHeadingEn : "Market news",

    // Warrant Tool Menu
    dwSearch : "ค้นหา DW",
    dwSearchEn : "DW search",
    liveMatrix : "ตารางราคา",
    liveMatrixEn : "Live matrix",
    foreignIndexChart : "กราฟดัชนีต่างประเทศ",
    foreignIndexChartEn : "Foreign index chart",
    dwTerms : "ข้อมูล DW",
    dwTermsEn : "DW terms",
    dwCalculator : "คำนวณราคา DW",
    dwCalculatorEn : "DW calculator",
    dwComparison : "เปรียบเทียบ DW",
    dwComparisonEn : "DW comparison",
    dwRanking : "DW by Underlying",
    dwRankingEn : "DW by Underlying",

    // Warrant Statitics Menu
    topTradedStock : "Top traded stock",
    topTradedStockEn : "Top traded stock",
    topTradedWarrants : "Top traded DW",
    topTradedWarrantsEn : "Top traded DW",
    dwTurnoverDistribution : "DW turnover distribution",
    dwTurnoverDistributionEn : "DW turnover distribution",
    dwMarketTurnover : "DW market turnover",
    dwMarketTurnoverEn : "DW market turnover",

    // Warrant Education Menu
    Boost:'Boost Your Opportunities',
    dwTips : "DW Tips",
    dwTipsEn : "DW Tips",
    dwVideo : "วิดีโอความรู้เรื่อง DW",
    dwVideoEn : "DW video",
    investorAcademy : "แหล่งความรู้",
    investorAcademyEn : "Investor academy",
    indexDW : "Index DW",
    indexDWEn : "Index DW",
    faq : "คำถามที่พบบ่อย",
    faqEn : "FAQ",
    whyDW28 : "ทำไมเลือก DW28",
    whyDW28En : "Why DW28",
    seminar : "สัมมนา",
    seminarEn : "Seminar",

    // Market News Menu
    singleStockUpdate : "อัพเดทข่าวสารหุ้นรายตัว",
    singleStockUpdateEn : "Single stock update",
    dailySp : "อัพเดทตลาด S&P500",
    dailySpEn : "S&P 500 index market news",
    dailyHsi : "วิเคราะห์ตลาดฮั่งเส็ง",
    dailyHsiEn : "HSI index market news",
    todayHighlight : "ทิศทางตลาดต่างประเทศ",
    todayHighlightEn : "Foreign index Update",
    dailyCommentry : "สรุปตลาด DW",
    dailyCommentryEn : "DW market commentary",
    overnightMarket : "สรุปภาพรวมตลาด",
    overnightMarketEn : "Market wrap-up",
    subscribe : "สมัครจดหมายข่าว",
    subscribeEn : "Subscribe",
    activities : "กิจกรรม",
    activitiesEn : "Activities",
    photoGallery : "ภาพกิจกรรม",
    photoGalleryEn : "Photo gallery",


    // Footer Bottom Menu
    rightsReserved : "© Macquarie Group Limited ABN 94 122 169 279. All rights reserved",
    rightsReservedEn : "© Macquarie Group Limited ABN 94 122 169 279. All rights reserved",

    importantInformation: "ข้อมูลสำคัญ ",
    importantInformationEn: "Important information ",
    privacyPolicy: " นโยบายความเป็นส่วนตัว ",
    privacyPolicyEn: " Privacy policy ",
    termsOfUse: " ข้อกำหนดการใช้งาน ",
    termsOfUseEn: " Terms of use ",
    contactUs: " ติดต่อเรา ",
    contactUsEn: " Contact us ",
    disclaimer: " คำเตือน",
    disclaimerEn: " Disclaimer",
}
