import React, { useState, useEffect,Fragment } from 'react';
import HowCalculates from './HowCalculate';
import "../../common.css";
import "../../investorAcademy.css";


const HowCalculate = (props) => {
    return ( 
        <Fragment> 
            <HowCalculates/>
        </Fragment> 
     );
}


export default HowCalculate;