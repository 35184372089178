import React, { useState, useEffect } from "react";
import "../../common.css";
import "../../investorAcademy.css";
import { Link, useLocation, useHistory } from "react-router-dom";
import ChildInvestorAcademyCommon from "../../../../../components/common/InvestorAcademyCommon/ChildInvestorAcademyCommon";
import { languageText } from "../../../../../languageText/InvestorAcademy/UsefulWarrantTerms/gearings"
import { languageTextMain } from "../../../../../languageText/InvestorAcademy/index"

const Gearings = (props) => {
  
  const location = useLocation();
  const history = useHistory();
  const [siteLang , setSiteLang] = useState("")

  // Site Language Change Start //
  useEffect(() => {
    window.scrollTo(0, 0);
    var siteLanguage = $.cookie('applang');
    setSiteLang(siteLanguage)
    if(siteLanguage == "eng"){
      document.querySelector("body").classList.add("eng")
    }else{
      document.querySelector("body").classList.remove("eng")
    }
  }, []);
  // Site Language Change End //

  return (
    <div id="investor_academy">
      <div className="imgbg" />
      <div className="mainbg" />
      <div id="main">
        <div id="maintable">
          <div style={{ display: "table-row" }}>
            <div id="leftbar" className="hidden-xs" />
            <div id="maincontainer">
              {/* Top Title Section Start */}
              <div id="mainnav">
                <table>
                  <tbody>
                    <tr>
                      <td width="20%" style={{textAlign:"left", visibility: "hidden"}} />
                      <td width="60%" style={{ textAlign: "center" }}>
                        <div id="pagetitlediv">
                          <div id="ptitle">
                            {siteLang == "eng" ? languageText.pageTitleEn : languageText.pageTitle}
                          </div>
                          <div id="pname">
                            {siteLang == "eng" ? languageText.pageNameEn : languageText.pageName}
                          </div>
                        </div>
                      </td>
                      <td width="20%" style={{textAlign:"right", visibility: "hidden"}} />
                    </tr>
                  </tbody>
                </table>
              </div>
              {/* Top Title Section End */}
              {/* Main Content Section Start */}
              <div id="maincontent" className="preminum">
                <div id="mainscroll">
                  <div id="mainscrollcontent">
                  <style dangerouslySetInnerHTML={{__html: ".formulabox {background-color: #363636;font-size: 14px;line-height: 1.42857143; }.formulabox p{font-size:1.2em !important;}.formulabox p span.leqt{ color:#FFDA44;} " }} />
                    <div className="contentbox">
                      <p>
                        {siteLang == "eng" ? languageText.pageDescriptionEn : languageText.pageDescription}
                        <br />
                        <br />
                      </p>
                      <div class="formulaholder">
                        <div class="formulabox">
                          <p>
                            <span class="leqt">{siteLang == "eng" ? languageText.formulaHolderLeftEn : languageText.formulaHolderLeft}</span> 
                            {siteLang == "eng" ? languageText.formulaHolderRightFirstEn : languageText.formulaHolderRightFirst}
                          </p>
                        </div>
                      </div>
                      <div className="tipholder">
                        <div className="tipbox">
                          <h2>{siteLang == "eng" ? languageText.tipHolderHeadingEn : languageText.tipHolderHeading}</h2>
                          <ul>
                            <li>
                              <p>{siteLang == "eng" ? languageText.tipHolderParagraph1En : languageText.tipHolderParagraph1}</p>
                            </li>
                            <li>
                              <p>{siteLang == "eng" ? languageText.tipHolderParagraph2En : languageText.tipHolderParagraph2}</p>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Main Content Section End */}
            </div>
            <div id="rightbar" className="hidden-xs" />
          </div>
        </div>
      </div>
      {/* Bottom Left Right Links and Copyright Section Start */}
      <div id="bottom">
        <div className="hidden-xs hidden-xxs" id="copyright">
          {siteLang == "eng" ? languageTextMain.copyRightEn : languageTextMain.copyRight}
        </div>
        <div className="leftUrl">
          <table className="hasprev">
            <tbody>
              <tr>
                <td width="33px">
                  <Link to="/eduhtml/premium">
                    <div className="prev left" />
                  </Link>
                </td>
                <td>
                  <div className="prevlbl left">
                    <div className="prevname">
                      <Link to="/eduhtml/premium">
                        {siteLang == "eng" ? languageTextMain.premiumEn : languageTextMain.premium}
                      </Link>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="rightUrl">
          <table className="hasnext">
            <tbody>
              <tr>
                <td>
                  <div className="nextlbl right">
                    <div className="nextname">
                      <Link to="/eduhtml/effectivegearing">
                        {siteLang == "eng" ? languageTextMain.effectiveGearingEn : languageTextMain.effectiveGearing}
                      </Link>
                    </div>
                  </div>
                </td>
                <td width="33px">
                  <Link to="/eduhtml/effectivegearing">
                    <div className="next right" />
                  </Link>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      {/* Bottom Left Right Links and Copyright Section End */}
      {/* Right Dots Menu Component Section Start */}
      <div id="dotbar" className="hidden-sm hidden-xs">
        <ChildInvestorAcademyCommon Location={location} Hist={history} />
      </div>
      {/* Right Dots Menu Component Section End  */}
    </div>
  );
};
export default Gearings;
