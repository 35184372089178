export const languageText ={
    
    // All News Page Breadcrumb Start //
    firstNavigation: "อัพเดทตลาดหุ้น ",
    firstNavigationEn: "Market news ",

    dailySingleStockNavigation: " อัพเดทข่าวสารหุ้นรายตัว",
    dailySingleStockNavigationEn: " Single stock update",

    dailySp500Navigation: " อัพเดทตลาด S&P500",
    dailySp500NavigationEn: " S&P 500 index market news",

    dailyHsiNavigation: " วิเคราะห์ตลาดฮั่งเส็ง",
    dailyHsiNavigationEn: " HSI index market news",

    todayHighlightNavigation: " ทิศทางตลาดต่างประเทศ",
    todayHighlightNavigationEn: " Foreign index Update",

    dwMarketCommentaryNavigation: " สรุปตลาด DW",
    dwMarketCommentaryNavigationEn: " DW market commentary",

    overNightMarketNavigation: " สรุปภาพรวมตลาด",
    overNightMarketNavigationEn: " Market wrap-up",
    // All News Page Breadcrumb End //

    // All News Page Title and Description Start //
    dailySingleStockPageTitle: "อัพเดทข่าวสารหุ้นรายตัว", 
    dailySingleStockPageTitleEn: "Single stock update",
    dailySingleStockPageDescription: "ไม่พลาดทุกข่าวสารการลงทุน สดใหม่ส่งตรงถึงคุณทุกเช้า",
    dailySingleStockPageDescriptionEn: "Keep up to date with the latest news, with updates from Macquarie research and more.",

    dailySp500PageTitle: "อัพเดทตลาด S&P500",
    dailySp500PageTitleEn: "S&P 500 index market news",

    dailyHsiPageTitle: "วิเคราะห์ตลาดฮั่งเส็ง",
    dailyHsiPageTitleEn: "HSI index market news",
    dailyHsiPageDescription: "ไม่พลาดทุกข่าวสารการลงทุน สดใหม่ส่งตรงถึงคุณทุกเช้า",
    dailyHsiPageDescriptionEn: "Keep up to date with the latest news, with updates from Macquarie research and more.",

    todayHighlightPageTitle: "ทิศทางตลาดต่างประเทศ",
    todayHighlightPageTitleEn: "Foreign index Update",
    todayHighlightPageDescription: "ไม่พลาดทุกข่าวสารการลงทุน สดใหม่ส่งตรงถึงคุณทุกเช้า",
    todayHighlightPageDescriptionEn: "Keep up to date with the latest news, with updates from Macquarie research and more.",

    dwMarketCommentaryPageTitle: "สรุปตลาด DW",
    dwMarketCommentaryPageTitleEn: "DW market commentary",
    dwMarketCommentaryPageDescription: "เจาะลึกกับสรุปภาพรวม DW ทุกวันช่วง 6 โมงเย็น",
    dwMarketCommentaryPageDescriptionEn: "Our exclusive commentary is updated by 6pm each day.",

    overNightMarketPageTitle: "สรุปภาพรวมตลาด",
    overNightMarketPageTitleEn: "Market wrap-up",
    overNightMarketPageDescription: "ข่าวล่าสุดเกี่ยวกับตลาดหุ้นชั้นนำทั่วโลกที่อาจมีผลต่อการเทรดของคุณ",
    overNightMarketPageDescriptionEn: "Hear about the latest news in Global markets that might affect your trading day.",
    // All News Page Title and Description End //

    // All News Page Sidebar Content Start //
    subscribeSidebarTitle: "สมัครจดหมายข่าว",
    subscribeSidebarTitleEn: "Subscribe",
    subscribeSidebarDescription: "ไม่อยากพลาดข่าวสำคัญ? สมัครจดหมายข่าวของแมคควอรีเลย",
    subscribeSidebarDescriptionEn: "Don't want to miss any important news? Subscribe to our newsletter today!",

    overnightMarketSidebarTitle: "สรุปภาพรวมตลาด",
    overnightMarketSidebarTitleEn: "Market wrap-up",
    overnightMarketSidebarDescription: "ข่าวล่าสุดเกี่ยวกับตลาดหุ้นชั้นนำทั่วโลกที่อาจมีผลต่อการเทรดของคุณ",
    overnightMarketSidebarDescriptionEn: "Hear about the latest news in Global markets that might affect your trading day.",

    dailyCommentarySidebarTitle: "สรุปตลาด DW",
    dailyCommentarySidebarTitleEn: "DW market commentary",
    dailyCommentarySidebarDescription: "เกิดอะไรขึ้นกับตลาด DW ในวันนี้? เจาะลึกกับสรุปภาพรวม DW ทุกวันช่วง 6 โมงเย็น",
    dailyCommentarySidebarDescriptionEn: "What happened on the DW market today? Our exclusive commentary is updated by 6pm each day.",

    foreignIndexUpdateSidebarTitle: "ทิศทางตลาดต่างประเทศ",
    foreignIndexUpdateSidebarTitleEn: "Foreign index Update",
    foreignIndexUpdateSidebarDescription: "อัพเดทข่าวสารล่าสุด ร่วมทั้งบทวิเคราะห์จากแมคควอรีและข่าวสารการลงทุนที่สำคัญอื่นๆ",
    foreignIndexUpdateSidebarDescriptionEn: "Keep up to date with the latest news, with updates from Macquarie research and more",
    // All News Page Sidebar Content End //

    marketDelay: "ข้อมูลตลาดทั้งหมดล่าช้าประมาณ 15 นาที",
    marketDelayEn: "All market data is delayed 15 mins.",
}