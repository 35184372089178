import React, {useEffect, useState} from "react";
import { Link } from "react-router-dom";
import { config } from "../../../../utils/apiUrl"
import API from "../../../../utils/apiCalling";
import axios from "axios"
import { languageText } from "../../../../languageText/dwTips"

const IndividualTip = (props) => {

  const [typeValue, setTypeValue] = useState("")
  const [typeId, setTypeId] = useState("")
  const [tableData, setTableData] = useState("")
  const [navData, setNavData] = useState("")
  const [imgStatus, setImgStatus] = useState(false)
  const [image, setImage] = useState("")
  const api = new API();
  const [siteLang , setSiteLang] = useState("")

  useEffect(() => {
    window.scrollTo(0, 0);
    var siteLanguage = $.cookie('applang');
    setSiteLang(siteLanguage)
    if(siteLanguage == "eng"){
      document.querySelector("body").classList.add("eng")
    }else{
      document.querySelector("body").classList.remove("eng")
    }
  }, []);

  const getDataUrl = () => {
    let urlData = new URLSearchParams(window.location.search)
    let typeVal =  urlData.get("type")
    let typeId =  urlData.get("id")
    setTypeId(typeId)
    setTypeValue(typeVal)
  }

  useEffect(() => {
    getDataUrl()
  }, []);

  const getApiData = async () => {
    if(typeValue != "" && typeId != ""){
      let tipTableData = await api.get(config.TipsDataJSON, true, {
        id: typeId,
        type: typeValue,
        qid: Date.now(),
      });
      setNavData(tipTableData.nav[0])
      if(tipTableData.tipsData != ""){
        setTableData(tipTableData.tipsData[0])
        setImgStatus(tipTableData.tipsData[0].image)
      }
    }
  }

  useEffect(() => {
    getApiData()
  },[typeValue, typeId])

  const getImage = async () => {
    if (imgStatus === true) {
      const url = `${config.advImgData}?tipsid=${typeId}&type=${typeValue}`
      try {
        const response = await axios.get(url)
        const { data: imageSrc } = response;
        if (imageSrc) {
          setImage(<img src={imageSrc} alt="page img" />);
        }
      } catch (err) {
        console.log(err)
      }
    }
  }

  useEffect(() => {
    getImage()
  },[imgStatus])


  return (
    <div id="individualtip" className="pageobj">
        <div className="breadcrumbbar">
          <div className="container-fluid">
            <ol className="breadcrumb">
              <li><Link to="/home">{siteLang == "eng" ? languageText.firstNavigationEn : languageText.firstNavigation} </Link></li>
              <li>
                <Link id="pachor" to={`/education/dwtipssup?type=${typeValue}`}> 
                  {siteLang == "eng" ? (typeValue == "topic" ? languageText.tipssupAllTopicTipsEn : typeValue == "set" ? languageText.tipssupAllSetTipsEn : languageText.tipssupAllIndexTipsEn)
                  : (typeValue == "topic" ? languageText.tipssupAllTopicTips : typeValue == "set" ? languageText.tipssupAllSetTips : languageText.tipssupAllIndexTips)} 
                </Link>
              </li>
              <li className="active" id="breadcrumb-label" dangerouslySetInnerHTML={{ __html: siteLang == "eng" ? tableData && tableData.en_title : tableData && tableData.thai_title}} />
            </ol>
          </div>
        </div>
        <div className="stripe-bg" />
        <div className="container-fluid">
          <div className="section">
            <div className="page-header">
              <h1 id="page-title" dangerouslySetInnerHTML={{ __html: siteLang == "eng" ? tableData && tableData.en_title : tableData && tableData.thai_title}} />
            </div>
            <div id="tip-content">
              <p>
                <div className="topContent">
                  <div className="leftImage">
                    {image}
                  </div>
                </div>
                <div className="fullContent" dangerouslySetInnerHTML={{ __html: siteLang == "eng" ? tableData && tableData.engLong : tableData && tableData.thaiLong}} />
              </p>
            </div>
            <div className="pnbar">
              <div className="pnbar-prev">
                <Link id="prev-achor" className={navData.prev === -1 ? "disabled" : ""} to={`/education/individualtip?type=${typeValue}&id=${navData.prev}`}>&lt; Previous tip</Link>
              </div>
              <div className="pnbar-vl" />
              <div className="pnbar-next">
                <Link id="next-achor" className={navData.next === -1 ? "disabled" : ""} to={`/education/individualtip?type=${typeValue}&id=${navData.next}`}>Next tip &gt;</Link> 
              </div>
            </div>
          </div>
        </div>
    </div>
  );
};

export default IndividualTip;
