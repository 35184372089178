import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom" 
import { languageText } from "../../../languageText/dwVideoInd";
import API from "../../../utils/apiCalling";
import EpisodeCom from "./EpisodeCom"
import DailyMarketCarousel from "./DailyMarketCom"
import SpeakerCarousel from "./GuestSpeakerCom"
import { eduEpisodeData } from "../DwVideo/EducationData"
import { testimonialList } from "../DwVideo/TestimonialData"
import { speakerList } from "../DwVideo/SpeakerData"
import { escape } from "lodash";

const DwVideoIndPage = () => {

  const [siteLang , setSiteLang] = useState("")
  const [playListId, setPlayListId] = useState("")
  const [index, setIndex] = useState("")
  const [activeVideoInfo, setActiveVideoInfo] = useState([])
  const [activeVideoData, setActiveVideoData] = useState([])
  const history = useHistory();
  const [episodeTabActive , setEpisodeTabActive] = useState(true)
  const [speakerTabActive , setSpeakerTabActive] = useState(false)
  const [testimonialTabActive , setTestimonialTabActive] = useState(false)
  const [dailyMarketTabActive , setDailyMarketTabActive] = useState(false)
  const [marketAnalysis, setMarketAnalysis] = useState([]);
  const [dmaPopUp, setDmaPopUp] = useState(false);
  const [popupVideoUrl, setPopupVideoUrl] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
    var siteLanguage = $.cookie('applang');
    setSiteLang(siteLanguage)
    if(siteLanguage == "eng"){
      document.querySelector("body").classList.add("eng")
    }else{
      document.querySelector("body").classList.remove("eng")
    }
    var urlData = new URLSearchParams(window.location.search)
    setPlayListId(urlData.get("playlistId"))
    setIndex(urlData.get("index"))
    var filterData = []
    eduEpisodeData.filter((e) => {
      if(e.playlistId == urlData.get("playlistId")){
        filterData.push(e)
      }
    })
    setActiveVideoInfo(filterData[0])
    setActiveVideoData(filterData)
  }, []);

  const setSelectedData = (data) => {
    if(window.innerWidth > 767){
      window.scrollTo(0, 150);
    }else{
      window.scrollTo(0, 0);
    }
    setActiveVideoInfo(data)
    setPlayListId(data.playlistId)
    setIndex(data.index)
  }

  const setSpeakerPage = (data) => {
    history.push({
      pathname: '/education/speaker',
      search: `?playlistId=${data}&index=0`
    })
  }

  const openTmlPage = (id, index) => {
    history.push({
      pathname: '/education/testimonial',
      search: `?playlistId=${id}&index=${index}`
    })
  }

  const setActiveTab = (data) => {
    if(data == "gs"){
      setEpisodeTabActive(false)
      setSpeakerTabActive(true)
      setTestimonialTabActive(false)
      setDailyMarketTabActive(false)
    }else if(data == "tm"){
      setEpisodeTabActive(false)
      setSpeakerTabActive(false)
      setTestimonialTabActive(true)
      setDailyMarketTabActive(false)
    }else if(data == "dma"){
      setEpisodeTabActive(false)
      setSpeakerTabActive(false)
      setTestimonialTabActive(false)
      setDailyMarketTabActive(true)
    }else{
      setEpisodeTabActive(true)
      setSpeakerTabActive(false)
      setTestimonialTabActive(false)
      setDailyMarketTabActive(false)
    }
  }

  const setDmaData = (data) => {
    setMarketAnalysis(data)
  }

  const setDmaVideoUrl = (data) => {
    setPopupVideoUrl(data)
    setDmaPopUp(true)
    document.querySelector("body").style.overflow = "hidden"
  }

  const closePopUp = () => {
    setDmaPopUp(false)
    setPopupVideoUrl("")
    document.querySelector("body").style.overflow = "auto"
  }

  const openFbPage = () => {
    var url_page = window.location.href.split('?')[0] + '?lang=' + siteLang + "&playlistId=" + playListId + "&index=" + index;
    var url_share = 'http://www.facebook.com/share.php?u=' + escape(url_page); 
    window.open(url_share, 'share', 'width=560,height=650');
  }

  return (
    <>
    <div id="dwvideoind" className="pageobj">
      <div className="breadcrumbbar">
        <div className="container-fluid">
          <ol className="breadcrumb">
            <li>
              <Link to="/home?dwedu">{siteLang == "eng" ? languageText.firstNavigationEn : languageText.firstNavigation}</Link>
            </li>
            <li>
              <Link to="/education/dwvideo">{siteLang == "eng" ? languageText.secondNavigationEn : languageText.secondNavigation}</Link>
            </li>
            <li className="active">{siteLang == "eng" ? languageText.thirdNavigationEn : languageText.thirdNavigation}</li>
          </ol>
        </div>
      </div>
      <div className="stripe-bg" />
      <div className="container-fluid">
        <div className="section">
          <div className="page-header">
            <h1>{siteLang == "eng" ? languageText.pageTitleEn : languageText.pageTitle}</h1>
          </div>
          <p className="section-p">{siteLang == "eng" ? languageText.pageDescriptionEn : languageText.pageDescription}</p>
          <div className="videoplayer">
            <div className="videoplayer-container">
                <iframe frameBorder="0" allowFullScreen src={`https://www.youtube.com/embed/videoseries?list=${playListId}&autoplay=1&index=${index}&rel=0&wmode=opaque`}></iframe>
            </div>
          </div>
          <div className="videoinfo">
            <div className="videoinfo-title">
              {siteLang == "eng" ? activeVideoInfo?.en_title : activeVideoInfo?.th_title}
            </div>
            <p className="videoinfo-p">
              {siteLang == "eng" ? activeVideoInfo?.en_text : activeVideoInfo?.th_text}
            </p>
            <div className="fbSharebtn" onClick={openFbPage}><i className="fa fa-facebook" aria-hidden="true"></i>Share on Facebook</div>
          </div>
          <div className="content hidden-xs">
            <div id="edurack" className="videorack videorack-custom">
              <div className="videorack-title">Episodes</div>
              <div className="videorack-body">
                <div className="videorack-content swiper-container swiper-container-horizontal">
                  <EpisodeCom activeVideoData={activeVideoData} setSelectedData={setSelectedData} />
                </div>
              </div>
            </div>
            <div id="dmarack" className="videorack videorack-custom">
              <div className="videorack-title">Daily Market Analysis</div>
              <div className="videorack-body">
                <DailyMarketCarousel setDmaData={setDmaData} />
              </div>
            </div>
            <div id="tmrack" className="videorack videorack-custom rack-fullwidth">
              <div className="videorack-title">More testimonial videos</div>
              <div className="videorack-body">
                <div className="videorack-content swiper-container swiper-container-horizontal">
                  <table className="videorack-page">
                    <tbody>
                      <tr>
                      {testimonialList && Array.from({ length: 5 }).map((_, index) => {
                          const data = testimonialList[index];
                          if (data) {
                            return (
                              <td key={index}>
                                <div
                                  playlistid={data.playlistId}
                                  index={index}
                                  className="videorack-item"
                                  style={{ display: "inline-block" }}
                                  onClick={() => openTmlPage(data.playlistId, index)}
                                >
                                  <img style={{ width: "100%" }} src={data.image} alt={data.en_title} />
                                  <div className="videorack-item-txt">
                                    {siteLang === "eng" ? data.en_title : data.th_title}
                                  </div>
                                </div>
                              </td>
                            );
                          } else {
                            return <td key={index}><div className="videorack-item videorack-item-hidden" style={{ display: "inline-block" }} /></td>;
                          }
                        })}
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div id="seminarrack" className="videorack videorack-custom">
              <div className="videorack-title">More guest speaker videos</div>
              <div className="videorack-body">
                <div className="videorack-content swiper-container swiper-container-horizontal">
                  <SpeakerCarousel setSpeakerPage={setSpeakerPage} />
                </div>
              </div>
            </div>
          </div>
          <div className="content-xs visible-xs">
            <div id="videoTabs" className="tablist">
              <div>
                <table>
                  <tbody>
                    <tr>
                      <td className={episodeTabActive ? "active" : ""}>
                        <div
                          data-toggle="tab"
                          className="tablist-item"
                          value="edu"
                          onClick={() => setActiveTab("edu")}
                        >
                          <div className="tablist-item-txt">Episodes</div>
                        </div>
                      </td>
                      <td className={speakerTabActive ? "active" : ""}>
                        <div
                          data-toggle="tab"
                          className="tablist-item"
                          value="gs"
                          onClick={() => setActiveTab("gs")}
                        >
                          <div className="tablist-item-txt">Speaker</div>
                        </div>
                      </td>
                      <td className={testimonialTabActive ? "active" : ""}>
                        <div
                          data-toggle="tab"
                          className="tablist-item"
                          value="tm"
                          onClick={() => setActiveTab("tm")}
                        >
                          <div className="tablist-item-txt">Testimonial</div>
                        </div>
                      </td>
                      <td className={dailyMarketTabActive ? "active" : ""}>
                        <div
                          data-toggle="tab"
                          className="tablist-item"
                          value="dma"
                          onClick={() => setActiveTab("dma")}
                        >
                          <div className="tablist-item-txt">
                            Daily Market Analysis
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div id="videotabcontent">
              {episodeTabActive && <div id="edulist" className="videolist">
                {activeVideoData && activeVideoData.map((data, index) => (
                  <div
                    playlistid={data.playlistId}
                    index={data.index}
                    className="videolist-item"
                    key={index}
                    onClick={() => setSelectedData(data)}
                  >
                    <img
                      style={{ width: "100%" }}
                      src={data.image}
                    />
                    <div className="videolist-item-txt">
                      {siteLang == "eng" ? data.en_title : data.th_title}
                    </div>
                  </div>
                ))}
              </div>}
              {speakerTabActive && <div id="gslist" className="videolist">
                {speakerList && speakerList.map((data, index) => (
                  <div
                    playlistid={data.playlistId}
                    index={index}
                    className="videolist-item"
                    key={index}
                    onClick={() => setSpeakerPage(data.playlistId)}
                  >
                    <img
                      style={{ width: "100%" }}
                      src={data.image}
                    />
                    <div className="videolist-item-txt">
                      {data.text}
                    </div>
                  </div>
                ))}
              </div>}
              {testimonialTabActive && <div id="tmlist" className="videolist">
                {testimonialList && testimonialList.map((data, index) => (
                  <div
                    playlistid={data.playlistId}
                    index={index}
                    className="videolist-item"
                    key={index}
                    onClick={() => openTmlPage(data.playlistId, index)}
                  >
                    <img
                      style={{ width: "100%" }}
                      src={data.image}
                    />
                    <div className="videolist-item-txt">
                      {siteLang == "eng" ? data.en_title : data.th_title}
                    </div>
                  </div>
                ))}
              </div>}
              {dailyMarketTabActive && <div id="dmalist" className="videolist">
                {marketAnalysis && marketAnalysis.map((data, index) => (
                  <div
                    dmaid={data.id}
                    className="videolist-item"
                    key={index}
                    onClick={() => setDmaVideoUrl(data.link)}
                  >
                    {data.image == "true" ? <img style={{ width: "100%" }} src={data.imgSrc} /> : <img style={{ width: "100%" }} src="" />}
                    <div className="videolist-item-txt">
                      {siteLang == "eng" ? data.en_title : data.thai_title}
                    </div>
                  </div>
                ))}
              </div>}
            </div>
          </div>
        </div>
      </div>
    </div>
    {dmaPopUp && <div id="ppboxHTML2" className="overlay">
      <div className="overlay-inner">
        <div style={{ position: "relative" }}>
          <img onClick={closePopUp} className="btn-close" src="/img/bullet/icon-close-white.png" />
          <div className="popupbox-content">
            <div className="ppc" key="video">
              <div className="hotline-layer">
                <iframe frameBorder={0} allowFullScreen src={popupVideoUrl} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>}
    </>
  );
};
export default DwVideoIndPage;
