import React, { useState, useEffect } from "react";
import "../../common.css";
import "../../investorAcademy.css";
import { Link, useLocation, useHistory } from "react-router-dom";
import ChildInvestorAcademyCommon from "../../../../../components/common/InvestorAcademyCommon/ChildInvestorAcademyCommon";
// import LangContext from "../../../../../components/common/context/lang-context";
// import { useContext } from "react";
import { languageText } from "../../../../../languageText/InvestorAcademy/HowAreWarrantsPriced/intrinsicValueTimeValues"
import { languageTextMain } from "../../../../../languageText/InvestorAcademy/index"

const IntrinsicValueTimeValues = (props) => {
  
  // const langContext = useContext(LangContext);
  const location = useLocation();
  const history = useHistory();
  const [siteLang , setSiteLang] = useState("")

  useEffect(() => {
    window.scrollTo(0, 0);
    var siteLanguage = $.cookie('applang');
    setSiteLang(siteLanguage)
    if(siteLanguage == "eng"){
      document.querySelector("body").classList.add("eng")
    }else{
      document.querySelector("body").classList.remove("eng")
    }
  }, []);

  return (
    <div id="investor_academy">
      <div className="imgbg" />
      <div className="mainbg" />
      <div id="main">
        <div id="maintable">
          <div style={{ display: "table-row" }}>
            <div id="leftbar" className="hidden-xs" />
            <div id="maincontainer">
              <div id="mainnav">
                <table>
                  <tbody>
                    <tr>
                      <td width="20%" style={{textAlign:"left", visibility: "hidden"}} />
                      <td width="60%" style={{ textAlign: "center" }}>
                        <div id="pagetitlediv">
                          <div id="ptitle">
                            {siteLang == "eng" ? languageText.pageTitleEn : languageText.pageTitle}
                          </div>
                          <div id="pname">
                            {siteLang == "eng" ? languageText.pageNameEn : languageText.pageName}
                          </div>
                        </div>
                      </td>
                      <td width="20%" style={{textAlign:"right", visibility: "hidden"}} />
                    </tr>
                  </tbody>
                </table>
              </div>
              <div id="maincontent">
                <div id="mainscroll">
                  <div id="mainscrollcontent">
                    <style dangerouslySetInnerHTML={{__html: ".imgholder img{ max-width: 359px;  } .contentbox p{ text-align:left !important; } .subtitle{ display: table-row; font-size: 24px; color:#FFDA44; } .subtitle span, h3{ display:table-cell; vertical-align: middle; } .subtitle span{ width: 46px; height: 46px; text-align: center; background:url(\"edu/img/yellow01.jpg\") no-repeat left center; color:#000000; } .subtitle h3{ padding-left:8px; padding-bottom: 8px; }  " }} />
                    <div className="contentbox">
                      <p>
                        {siteLang == "eng" ? languageText.pageDescriptionEn : languageText.pageDescription}
                      </p>
                      <div class="subtitle">
                        <span>1</span>
                        <h3>{siteLang == "eng" ? languageText.pageInnerTitleEn : languageText.pageInnerTitle}</h3>
                      </div>
                      <p>
                        {siteLang == "eng" ? languageText.pageInnerDescriptionEn : languageText.pageInnerDescription}
                      </p>
                      <div className="imgholder">
                          <img src={siteLang == "eng" ? languageText.imgUrlEn : languageText.imgUrl} />
                      </div>
                      <div class="subtitle">
                        <span>2</span>
                        <h3>{siteLang == "eng" ? languageText.pageInnerTitle1En : languageText.pageInnerTitle1}</h3>
                      </div>
                      <p>
                        {siteLang == "eng" ? languageText.pageInnerDescription1En : languageText.pageInnerDescription1}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div id="rightbar" className="hidden-xs" />
          </div>
        </div>
      </div>

      <div id="bottom">
        <div className="hidden-xs hidden-xxs" id="copyright">
          {siteLang == "eng" ? languageTextMain.copyRightEn : languageTextMain.copyRight}
        </div>
        <div className="leftUrl">
          <table className="hasprev">
            <tbody>
              <tr>
                <td width="33px">
                  <Link to="/eduhtml/interestrates">
                    <div className="prev left" />
                  </Link>
                </td>
                <td>
                  <div className="prevlbl left">
                    <div className="prevname">
                      <Link to="/eduhtml/interestrates">
                        {siteLang == "eng" ? languageTextMain.interestRatesEn : languageTextMain.interestRates}
                      </Link>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="rightUrl">
          <table className="hasnext">
            <tbody>
              <tr>
                <td>
                  <div className="nextlbl right">
                    <div className="nextname">
                      <Link to="/eduhtml/timedecay">
                        {siteLang == "eng" ? languageTextMain.timeDecayEn : languageTextMain.timeDecay}
                      </Link>
                    </div>
                  </div>
                </td>
                <td width="33px">
                  <Link to="/eduhtml/timedecay">
                    <div className="next right" />
                  </Link>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div id="dotbar" className="hidden-sm hidden-xs">
        <ChildInvestorAcademyCommon Location={location} Hist={history} />
      </div>

    </div>
  );
};
export default IntrinsicValueTimeValues;
