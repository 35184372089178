import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { config } from "../../../utils/apiUrl";
import API from "../../../utils/apiCalling";
import LangContext from "../../../components/common/context/lang-context";
import { useContext } from "react";
import { languageText } from "../../../languageText/home";

const WarrantStatistics = () => {

  const api = new API();
  const langContext = useContext(LangContext);
  const [mqOnlyCheckBox, setMqOnlyCheckBox] = useState(true)
  const [tradedStockData, settradedStockData] = useState([])
  const [calldwTab, setCalldwTab] = useState("active")
  const [putdwTab, setPutdwTab] = useState("")
  const [topIndices, setTopIndices] = useState([])
  const [topIndicesMq, setTopIndicesMq] = useState([])
  const [topDw, setTopDw] = useState([])
  const [topDwMq, setTopDwMq] = useState([])
  const [siteLang , setSiteLang] = useState("")

  useEffect(() => {
    var siteLanguage = $.cookie('applang');
    setSiteLang(siteLanguage)
    if(siteLanguage == "eng"){
      document.querySelector("body").classList.add("eng")
    }else{
      document.querySelector("body").classList.remove("eng")
    }
    getHomeStatJson()
    getTradedStock()
  }, []);

  const getHomeStatJson = async () => {
    let homeJsonResult = await api.get(
      config.homeJSON,
      true,
      { m: "dwstat", qid: Date.now() }
    );
    var limit = 5;
    var topindices = homeJsonResult.topindices
    if(topindices !== null || topindices !== undefined || topindices != "") {
      topindices = topindices.slice(0, topindices.length > limit ? limit : topindices.length);
    }
    setTopIndices(topindices)
    var topindicesMqOnly = homeJsonResult.topindicesMqOnly
    if(topindicesMqOnly !== null || topindicesMqOnly !== undefined || topindicesMqOnly != "") {
      topindicesMqOnly = topindicesMqOnly.slice(0, topindicesMqOnly.length > limit ? limit : topindicesMqOnly.length);
    }
    setTopIndicesMq(topindicesMqOnly)
    var topdw = homeJsonResult.topdw
    if(topdw !== null || topdw !== undefined || topdw != "") {
      topdw = topdw.slice(0, topdw.length > limit ? limit : topdw.length);
    }
    setTopDw(topdw)
    var topdwMqOnly = homeJsonResult.topdwMqOnly
    if(topdwMqOnly !== null || topdwMqOnly !== undefined || topdwMqOnly != "") {
      topdwMqOnly = topdwMqOnly.slice(0, topdwMqOnly.length > limit ? limit : topdwMqOnly.length);
    }
    setTopDwMq(topdwMqOnly)
  };

  const getTradedStock = async () => {
    let tradedStock = await api.get(
      config.topTradedStock,
      true,
      { type: "toptradedstock", qid: Date.now() }
    );
    var topList = tradedStock.topList;
    var limit = 5;
    if (topList !== null || topList !== undefined || topList != "") {
        topList = topList.slice(0, topList.length > limit ? limit : topList.length);
    }
    settradedStockData(topList)
  };

  const changeCheckBoxVal = () => {
    if(mqOnlyCheckBox === true){
      setMqOnlyCheckBox(false)
    }else{
      setMqOnlyCheckBox(true)
    }
  }

  const tabChangeCall = () => {
    setCalldwTab("active")
    setPutdwTab("")
  }

  const tabChangePut = () => {
    setCalldwTab("")
    setPutdwTab("active")
  }


  return (
    <div>
      <div className="tab-container container-fluid">
        <div id="dwstat" className="tabcontent">
          <div className="frame-lgmd">
            <div className="row">
              <div className="col-md-12 holder-toptradedstock">
                <div id="toptradedstock" className="section">
                  <div className="page-header">
                    <h1 className="small">
                        {siteLang == "eng" ? languageText.tradedStockTitleEn : languageText.tradedStockTitle}
                    </h1>
                  </div>
                  <p>
                    {siteLang == "eng" ? languageText.tradedStockParaEn : languageText.tradedStockPara}
                  </p>
                  <div className="tablist">
                    <div id="topTabs" className="tablist-left">
                      <table>
                        <tbody>
                          <tr>
                            <td className={`calldw ${calldwTab}`} onClick={tabChangeCall}>
                              <div data-toggle="tab" className="tablist-item" value="calldw">
                                <div className="tablist-item-txt">
                                    {siteLang == "eng" ? languageText.tradedStockCallBtnEn : languageText.tradedStockCallBtn}
                                </div>
                              </div>
                            </td>
                            <td className={`putdw ${putdwTab}`} onClick={tabChangePut}>
                              <div data-toggle="tab" className="tablist-item" value="putdw">
                                <div className="tablist-item-txt">
                                    {siteLang == "eng" ? languageText.tradedStockPutBtnEn : languageText.tradedStockPutBtn}
                                </div>
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div id="topTbl" className="calldw">
                    <table className="table-striped table-responsive">
                      <thead>
                        <tr className="hidden-xs">
                          <th className="bgcolor-04" colSpan={4}> Underlying </th>
                          <th className="col_call bgcolor-06" colSpan={4}>{calldwTab == "active" ? "Call DW" : "Put DW"}</th>
                          <th className="col_put bgcolor-06" colSpan={4}>  </th>
                        </tr>
                        <tr className="visible-xs">
                          <th className="bgcolor-04" colSpan={3}> Underlying </th>
                          <th className="col_call bgcolor-06" colSpan={3}>{calldwTab == "active" ? "Call DW" : "Put DW"}</th>
                          <th className="col_put bgcolor-06" colSpan={3}>  </th>
                        </tr>
                        <tr>
                          <th className="bgcolor-04">Underlying</th>
                          <th className="bgcolor-04">
                            Turnover <br className="visible-xs" />
                            <span className="visible-xs">(mil)</span>
                          </th>
                          <th className="bgcolor-04 hidden-xs">Last price</th>
                          <th className="bgcolor-04">
                            Change
                            <br className="visible-xs" />
                            (%)
                          </th>
                          <th className="col_call bgcolor-06">Symbol</th>
                          <th className="col_call bgcolor-06">
                            Bid
                            <br className="visible-xs" />
                            (THB)
                          </th>
                          <th className="col_call hidden-xs bgcolor-06">
                            Eff. gearing(x)
                          </th>
                          <th className="col_call bgcolor-06">Sensitivity</th>
                        </tr>
                      </thead>
                      <tbody>
                        {tradedStockData && tradedStockData.map((data, index) => (
                            <tr className={(!data.dw_ticker_c && calldwTab == "active") ? "tts_rowtmpl hide-calldw" : (!data.dw_ticker_p && putdwTab == "active") ? "tts_rowtmpl hide-putdw" : "tts_rowtmpl"} key={index}>
                                <td className="col_underlying_ticker">
                                    {data.underlying_ticker}
                                </td>
                                <td className="col_long col_undelrying_turnover hidden-xs">
                                    {data.undelrying_turnover}
                                </td>
                                <td className="col_short col_undelrying_turnover visible-xs">
                                  {data?.undelrying_turnover == "-" ? "0.00" : (data?.undelrying_turnover?.replaceAll(",", "")/ 1000000).toFixed(2).replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                </td>
                                <td className="hidden-xs col_TRDPRC_1">
                                    {data.TRDPRC_1}
                                </td>
                                <td className={data.PCTCHNG > 0 ? "right-b01 col_PCTCHNG up" : data.PCTCHNG < 0 ? "right-b01 col_PCTCHNG down" : "right-b01 col_PCTCHNG"}>
                                    {data.PCTCHNG}
                                </td>
                                <td className="col_call col_dw_ticker">
                                    <Link to={`/tools/livematrix/${calldwTab == "active" ? data.dw_ticker_c : data.dw_ticker_p}`}>
                                        {calldwTab == "active" ? data.dw_ticker_c : data.dw_ticker_p}
                                    </Link>
                                </td>
                                <td className="col_call col_warrant_price">
                                    {calldwTab == "active" ? data.warrant_price_c : data.warrant_price_p}
                                </td>
                                <td className="col_call hidden-xs col_eff_gearing">
                                    {calldwTab == "active" ? data.eff_gearing_c : data.eff_gearing_p}
                                </td>
                                <td className="col_call col_sensitivity">
                                    {calldwTab == "active" ? data.sensitivity_c : data.sensitivity_p}
                                </td>
                            </tr>
                        ))}
                        {tradedStockData.length <= 0 && <tr className="emptymsg"><td colSpan="12">No data</td></tr>}
                      </tbody>
                    </table>
                  </div>
                  <div className="section-btn-wapper">
                    <Link
                      to="/marketdata/toptradedstock"
                      style={{ minWidth: "190px" }}
                      type="button"
                      className="btn btn-primary"
                    >
                      {siteLang == "eng" ? languageText.tradedStockViewBtnEn : languageText.tradedStockViewBtn}
                    </Link>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 holder-toptradeddw">
                <div id="toptradeddw" className="section">
                  <div className="page-header">
                    <h1 className="small">Top traded DW</h1>
                  </div>
                  <p>
                    {siteLang == "eng" ? languageText.tradedDwParaEn : languageText.tradedDwPara}
                  </p>
                  <div className="row">
                    <div id="topindices" className="col-sm-6">
                      <h2 className="small">SET50 DW </h2>
                      <table className="table table-striped table-closed">
                        <thead>
                          <tr>
                            <th>{siteLang == "eng" ? languageText.tradedDwTableSymbolEn : languageText.tradedDwTableSymbol}</th>
                            <th>{siteLang == "eng" ? languageText.tradedDwTableBidEn+ " (THB)" : languageText.tradedDwTableBid}</th>
                            <th>{siteLang == "eng" ? languageText.tradedDwTableChangeEn : languageText.tradedDwTableChange} (%)</th>
                            <th>{siteLang == "eng" ? languageText.tradedDwTableVolumeEn : languageText.tradedDwTableVolume} ('000)</th>
                          </tr>
                        </thead>
                        <tbody>
                          {mqOnlyCheckBox && topIndicesMq && topIndicesMq.map((data, index) => (
                            <tr className="tt_rowtmpl" key={index}>
                              <td className="ticker val">
                                <Link to={`/tools/livematrix/${data.ticker}`}>{data.ticker}</Link>
                              </td>
                              <td className="bid val">{data?.bid}</td>
                              <td className={Number(data.pct_dis) > 0 ? "pct_dis val up" : Number(data.pct_dis) < 0 ? "pct_dis val down" : "pct_dis val"}>
                                {(data.pct_dis == 0 || data.pct_dis == "-") ? "-" : (data.pct_dis.includes("-") ? data.pct_dis : "+"+data.pct_dis)}
                              </td>
                              <td className="vol val">{data.vol}</td>
                            </tr>
                          ))}
                          {!mqOnlyCheckBox && topIndices && topIndices.map((data, index) => (
                            <tr className="tt_rowtmpl" key={index}>
                              <td className="ticker val">
                                <Link to={`/tools/livematrix/${data.ticker}`}>{data.ticker}</Link>
                              </td>
                              <td className="bid val">{data?.bid}</td>
                              <td className={Number(data.pct_dis) > 0 ? "pct_dis val up" : Number(data.pct_dis) < 0 ? "pct_dis val down" : "pct_dis val"}>
                                {(data.pct_dis == 0 || data.pct_dis == "-") ? "-" : (data.pct_dis.includes("-") ? data.pct_dis : "+"+data.pct_dis)}
                              </td>
                              <td className="vol val">{data.vol}</td>
                            </tr>
                          ))}
                          {mqOnlyCheckBox && topIndicesMq.length <= 0 && <tr className="emptymsg"><td colSpan="4">No data</td></tr>}
                          {!mqOnlyCheckBox && topIndices.length <= 0 && <tr className="emptymsg"><td colSpan="4">No data</td></tr>}
                        </tbody>
                      </table>
                    </div>
                    <div id="topdw" className="col-sm-6">
                      <h2 className="small">Single Stock DW</h2>
                      <table className="table table-striped table-closed">
                        <thead>
                          <tr>
                            <th>{siteLang == "eng" ? languageText.tradedDwTableSymbolEn : languageText.tradedDwTableSymbol}</th>
                            <th>{siteLang == "eng" ? languageText.tradedDwTableBidEn+ " (THB)" : languageText.tradedDwTableBid}</th>
                            <th>{siteLang == "eng" ? languageText.tradedDwTableChangeEn : languageText.tradedDwTableChange} (%)</th>
                            <th>{siteLang == "eng" ? languageText.tradedDwTableVolumeEn : languageText.tradedDwTableVolume} ('000)</th>
                          </tr>
                        </thead>
                        <tbody>
                          {mqOnlyCheckBox && topDwMq && topDwMq.map((data, index) => (
                            <tr className="tt_rowtmpl" key={index}>
                              <td className="ticker val">
                                <Link to={`/tools/livematrix/${data.ticker}`}>{data.ticker}</Link>
                              </td>
                              <td className="bid val">{data?.bid}</td>
                              <td className={Number(data.pct_dis) > 0 ? "pct_dis val up" : Number(data.pct_dis) < 0 ? "pct_dis val down" : "pct_dis val"}>
                                {(data.pct_dis == 0 || data.pct_dis == "-") ? "-" : (data.pct_dis.includes("-") ? data.pct_dis : "+"+data.pct_dis)}
                              </td>
                              <td className="vol val">{data.vol}</td>
                            </tr>
                          ))}
                          {!mqOnlyCheckBox && topDw && topDw.map((data, index) => (
                            <tr className="tt_rowtmpl" key={index}>
                              <td className="ticker val">
                                <Link to={`/tools/livematrix/${data.ticker}`}>{data.ticker}</Link>
                              </td>
                              <td className="bid val">{data?.bid}</td>
                              <td className={Number(data.pct_dis) > 0 ? "pct_dis val up" : Number(data.pct_dis) < 0 ? "pct_dis val down" : "pct_dis val"}>
                                {(data.pct_dis == 0 || data.pct_dis == "-") ? "-" : (data.pct_dis.includes("-") ? data.pct_dis : "+"+data.pct_dis)}
                              </td>
                              <td className="vol val">{data.vol}</td>
                            </tr>
                          ))}
                          {mqOnlyCheckBox && topDwMq.length <= 0 && <tr className="emptymsg"><td colSpan="4">No data</td></tr>}
                          {!mqOnlyCheckBox && topDw.length <= 0 && <tr className="emptymsg"><td colSpan="4">No data</td></tr>}
                        </tbody>
                      </table>
                      <div className="section-btn-wapper">
                        <Link to="/marketdata/toptradewarrants" style={{ minWidth: "190px" }} type="button" className="btn btn-primary">
                          {siteLang == "eng" ? languageText.tradedStockViewBtnEn : languageText.tradedStockViewBtn}
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="checkbox-mqonly">
                    <input type="checkbox" defaultChecked={mqOnlyCheckBox} onChange={changeCheckBoxVal} />
                    <label>{siteLang == "eng" ? languageText.tradedDwMacquarieEn : languageText.tradedDwMacquarie}</label>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 col-sm-6 col-right">
                <div className="holder-qlink-dwturnover">
                  <div id="qlink-dwturnover" className="section">
                    <div className="page-header">
                      <h1 className="small">DW market turnover</h1>
                    </div>
                    <p>
                        {siteLang == "eng" ? languageText.marketTurnOverEn : languageText.marketTurnOver}
                    </p>
                    <div className="section-qlink section-qlink-dwmarketturnover">
                      <div className="section-qlink-achor hidden-xs">
                        <Link
                          to="/marketdata/dwturnover"
                          type="button"
                          className="btn btn-primary"
                        >
                          {siteLang == "eng" ? languageText.tradedStockViewBtnEn : languageText.tradedStockViewBtn}
                        </Link>
                      </div>
                    </div>
                    <div className="section-btn-wapper visible-xs">
                      <Link
                        to="/marketdata/dwturnover"
                        type="button"
                        className="btn btn-primary"
                      >
                        {siteLang == "eng" ? languageText.tradedStockViewBtnEn : languageText.tradedStockViewBtn}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6 col-sm-6 col-left">
                <div className="holder-qlink-turnoverdist">
                  <div id="qlink-turnoverdist" className="section">
                    <div className="page-header">
                      <h1 className="small">DW turnover distribution</h1>
                    </div>
                    <p>
                        {siteLang == "eng" ? languageText.turnOverDistributionEn : languageText.turnOverDistribution}
                    </p>
                    <div className="section-qlink section-qlink-turnoverdist">
                      <div className="section-qlink-achor hidden-xs">
                        <Link
                          to="/marketdata/turnoverdist"
                          type="button"
                          className="btn btn-primary"
                        >
                          {siteLang == "eng" ? languageText.tradedStockViewBtnEn : languageText.tradedStockViewBtn}
                        </Link>
                      </div>
                    </div>
                    <div className="section-btn-wapper visible-xs">
                      <Link
                        to="/marketdata/turnoverdist"
                        type="button"
                        className="btn btn-primary"
                      >
                        {siteLang == "eng" ? languageText.tradedStockViewBtnEn : languageText.tradedStockViewBtn}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WarrantStatistics;
