import React from 'react';
import {Carousel} from 'react-responsive-carousel';
import { speakerList } from "./SpeakerData"

const SpeakerCarousel = () => {

    const onClickHandle = (playlistId, index) => {
      window.open(
        `/education/speaker?playlistId=${playlistId}&index=${index}`,
        '_blank'
      );
    }

    const itemsPerRow = 3; // Number of items per row
    const itemsPerTable = 6; // Number of items per table
    const tableRows = [];
    for (let i = 0; i < speakerList.length; i += itemsPerTable) {
        const tableItems = [];
        for (let j = i; j < i + itemsPerTable && j < speakerList.length; j += itemsPerRow) {
          const rowItems = [];
          for (let k = j; k < j + itemsPerRow && k < speakerList.length; k++) {
            const speaker = speakerList[k];
            rowItems.push(
              <td key={speaker.name}>
                <div className="videorack-item" index="0" playlistid={speaker.playlistId} hideinfo={speaker.hideInfo.toString()} onClick={() => onClickHandle(speaker.playlistId, 0)}>
                  <img style={{ width: "100%" }} src={speaker.image} alt={speaker.name} />
                  <div className="videorack-item-txt">
                    {speaker.text}
                  </div>
                </div>
              </td>
            );
          }
          tableItems.push(
            <tr key={j}>
                {rowItems}
            </tr>
          );
        }
        tableRows.push(
            <div key={i} style={{ width: "1030px", marginRight: "20px" }} className="slide-tab">
              <table className={`videorack-page ${i > 0 ? 'slide-tab' : ''}`}>
                <tbody>
                  {tableItems}
                </tbody>
              </table>
            </div>
        );
    }

    return (
        <Carousel
        showArrows={true}
        emulateTouch
        showStatus={false}
        autoFocus
        infiniteLoop={false}
        showIndicators={false}
        showThumbs={false}
        >
            {tableRows}
        </Carousel>
    );
};

export default SpeakerCarousel;