export const languageText ={
    
    firstNavigation: "สถิติ DW ",
    firstNavigationEn: "DW statistics ",
    secondNavigation: " DW turnover distribution",
    secondNavigationEn: " DW turnover distribution",

    pageTitle: "DW turnover distribution",
    pageTitleEn: "DW turnover distribution",
    pageDescription: "หลักทรัพย์อ้างอิงของ DW ที่มีมูลค่าการซื้อขายสูงสุดในแต่ละวัน",
    pageDescriptionEn: "The most popular underlyings in the DW market for the day.",

    topTradedUnderlying: "Top traded underlyings",
    topTradedUnderlyingEn: "Top traded underlyings",

    tableUnderlying: "หลักทรัพย์อ้างอิง",
    tableUnderlyingEn: "Underlying",

    todayUnderlying: "Today's underlying",
    todayUnderlyingEn: "Today's underlying",

    aboveTable: "The above tables show the percentage of total DW turnover contributed by each of these underlyings on the listed dates.",
    aboveTableEn: "The above tables show the percentage of total DW turnover contributed by each of these underlyings on the listed dates.",
    marketDelay: "ข้อมูลตลาดทั้งหมดล่าช้าประมาณ 15 นาที",
    marketDelayEn: "All market data is delayed 15 mins.",
}