import React, { useState, useEffect } from "react";
import "../../common.css";
import "../../investorAcademy.css";
import { Link, useLocation, useHistory } from "react-router-dom";
import ChildInvestorAcademyCommon from "../../../../../components/common/InvestorAcademyCommon/ChildInvestorAcademyCommon";
import { languageText } from "../../../../../languageText/InvestorAcademy/HowAreWarrantsPriced/moneyNess"
import { languageTextMain } from "../../../../../languageText/InvestorAcademy/index"

const Moneyness = (props) => {
  
  const location = useLocation();
  const history = useHistory();
  const [siteLang , setSiteLang] = useState("")
  const [display , setDisplay] = useState("none")

  // Site Language Change Start //
  useEffect(() => {
    window.scrollTo(0, 0);
    var siteLanguage = $.cookie('applang');
    setSiteLang(siteLanguage)
    if(siteLanguage == "eng"){
      document.querySelector("body").classList.add("eng")
    }else{
      document.querySelector("body").classList.remove("eng")
    }
  }, []);
  // Site Language Change End //

  // View Chart Image Popup Open And Close Start //
  const openPopUp = () => {
    if(display == "none"){
      setDisplay("block")
    }else{
      setDisplay("none")
    }
  }
  // View Chart Image Popup Open And Close End //

  return (
    <div id="investor_academy">
      <div className="imgbg" />
      <div className="mainbg" />
      <div id="main">
        <div id="maintable">
          <div style={{ display: "table-row" }}>
            <div id="leftbar" className="hidden-xs" />
            <div id="maincontainer">
              <div id="mainnav">
                <table>
                  <tbody>
                    <tr>
                      <td width="20%" style={{textAlign:"left", visibility: "hidden"}} />
                      <td width="60%" style={{ textAlign: "center" }}>
                        <div id="pagetitlediv">
                          <div id="ptitle">
                            {siteLang == "eng" ? languageText.pageTitleEn : languageText.pageTitle}
                          </div>
                          <div id="pname">
                            {siteLang == "eng" ? languageText.pageNameEn : languageText.pageName}
                          </div>
                        </div>
                      </td>
                      <td width="20%" style={{textAlign:"right", visibility: "hidden"}} />
                    </tr>
                  </tbody>
                </table>
              </div>
              <div id="maincontent">
                <div id="mainscroll">
                  <div id="mainscrollcontent">
                    <style dangerouslySetInnerHTML={{__html: "p .hltxt{color: #FFD843;} .imgholder img{ max-width: 804px; } p.disclaimer{ color:red; font-size:0.8em; } .contentbox table{ max-width:500px; width:100%; margin: 0 auto; } .contentbox table tbody td{ height:4em; width:30%; color:#000000; } .contentbox table tbody td.col1{ background-color: #FFF4D2; text-align:left; padding-left: 1em; } .contentbox table tbody td.col2{ background-color: #FFFF80; text-align:center; } .contentbox table tbody td.col3{ background-color: #FFD400; text-align:center; } .contentbox table tfoot td.disclaimer{ padding-top: 0.5em; color: #FFFFFF; font-size: 0.8em; } " }} />
                    <div className="contentbox">
                      <p>
                        {siteLang == "eng" ? languageText.pageDescriptionEn : languageText.pageDescription}
                        <br />
                        <br />
                        {siteLang == "eng" ? languageText.pageDescription1En : languageText.pageDescription1}
                        <Link to="/eduhtml/intrinsicvalueandtimevalue">
                          {siteLang == "eng" ? languageText.pageDescriptionLinkEn : languageText.pageDescriptionLink}
                        </Link>
                        {siteLang == "eng" ? languageText.pageDescription2En : languageText.pageDescription2}
                        <span className="hltxt">
                          <b>{siteLang == "eng" ? languageText.pageDescriptionSpanEn : languageText.pageDescriptionSpan}</b>
                        </span>
                        {siteLang == "eng" ? ". " : ""}
                        {siteLang == "eng" ? languageText.pageDescription3En : languageText.pageDescription3}
                        <span className="hltxt">
                          <b>{siteLang == "eng" ? languageText.pageDescriptionSpan1En : languageText.pageDescriptionSpan1}</b>
                        </span>
                        {siteLang == "eng" ? languageText.pageDescription4En : languageText.pageDescription4}
                        <span className="hltxt">
                          <b>{siteLang == "eng" ? languageText.pageDescriptionSpan2En : languageText.pageDescriptionSpan2}</b>
                        </span>
                        {siteLang == "eng" ? ". " : ""}
                        <br />
                        <br />
                        {siteLang == "eng" ? languageText.pageDescription5En : languageText.pageDescription5}
                        <br />
                        <br />
                        {siteLang == "eng" ? languageText.pageDescription6En : languageText.pageDescription6}
                      </p>
                      <div className="imgholder">
                          <img src={siteLang == "eng" ? languageText.imgUrlEn : languageText.imgUrl} />
                          <div class="visible-xs">
                            <button onClick={openPopUp}>
                              {siteLang == "eng" ? "View table" : "View table"}
                            </button>
                          </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div id="rightbar" className="hidden-xs" />
          </div>
        </div>
      </div>

      <div id="bottom">
        <div className="hidden-xs hidden-xxs" id="copyright">
          {siteLang == "eng" ? languageTextMain.copyRightEn : languageTextMain.copyRight}
        </div>
        <div className="leftUrl">
          <table className="hasprev">
            <tbody>
              <tr>
                <td width="33px">
                  <Link to="/eduhtml/timedecay">
                    <div className="prev left" />
                  </Link>
                </td>
                <td>
                  <div className="prevlbl left">
                    <div className="prevname">
                      <Link to="/eduhtml/timedecay">
                        {siteLang == "eng" ? languageTextMain.timeDecayEn : languageTextMain.timeDecay}
                      </Link>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="rightUrl">
          <table className="hasnext">
            <tbody>
              <tr>
                <td>
                  <div className="nextlbl right">
                    <div className="nextname">
                      <Link to="/eduhtml/trackingwarrantspricechangelastprice">
                        {siteLang == "eng" ? languageTextMain.trackingWarrantEn : languageTextMain.trackingWarrant}
                      </Link>
                    </div>
                  </div>
                </td>
                <td width="33px">
                  <Link to="/eduhtml/trackingwarrantspricechangelastprice">
                    <div className="next right" />
                  </Link>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div id="dotbar" className="hidden-sm hidden-xs">
        <ChildInvestorAcademyCommon Location={location} Hist={history} />
      </div>

      {/* View Chart Popup In Mobile Start  */}
      <div id="imgpopup" style={{display: display}}>
        <div id="closebtn2" onClick={openPopUp} />
        <div className="imgHold">
          <img src={siteLang == "eng" ? languageText.imgUrlEn : languageText.imgUrl} className="landscape" />
        </div>
      </div>
      {/* View Chart Popup In Mobile End  */}

    </div> 
  );
};
export default Moneyness;
