import React, { useEffect } from "react";
import "./season3.css";
import { useState } from "react";
import { Link } from "react-router-dom";
import { languageText } from "../../../../languageText/activitieInnerPages"

const SeaSon3 = () => {

  const [modal1, setModal1] = useState(true);
  const [modal2, setModal2] = useState(true);
  const [modal3, setModal3] = useState(true);
  const [siteLang , setSiteLang] = useState("")

  useEffect(() => {
    window.scrollTo(0, 0);
    var siteLanguage = $.cookie('applang');
    setSiteLang(siteLanguage)
    if(siteLanguage == "eng"){
      document.querySelector("body").classList.add("eng")
    }else{
      document.querySelector("body").classList.remove("eng")
    }
  }, []);

  const closeModal = (e) => {
    e.preventDefault();
    modal1 ? setModal1(false) : setModal1(true);
    modal2 ? setModal2(false) : setModal2(false);
    modal3 ? setModal3(false) : setModal3(false);
  };

  const closeModal2 = (e) => {
    e.preventDefault();
    modal2 ? setModal2(false) : setModal2(true);
    modal1 ? setModal1(false) : setModal1(false);
    modal3 ? setModal3(false) : setModal3(false);
  };

  const closeModal3 = (e) => {
    e.preventDefault();
    modal3 ? setModal3(false) : setModal3(true);
    modal1 ? setModal1(false) : setModal1(false);
    modal2 ? setModal2(false) : setModal2(false);
  };

  const handleClick = (Event) => {
    Event.preventDefault();
    window.open(
      "https://www.facebook.com/login.php?skip_api_login=1&api_key=966242223397117&signed_next=1&next=https%3A%2F%2Fwww.facebook.com%2Fshare.php%3Fu%3Dhttps%253A%252F%252Ft1www5.trkd-hs.com%252Fthaidw_aws%252Factivities%252FHSIFlight%253Flang%253Den&cancel_url=https%3A%2F%2Fwww.facebook.com%2Fdialog%2Fclose_window%2F%3Fapp_id%3D966242223397117%26connect%3D0%23_%3D_&display=popup&locale=en_GB", "share", 'width=560,height=650'
    );
  };

  return (
    <div id="season3" className="pageobj">
      <div className="breadcrumbbar">
        <div className="container-fluid">
          <ol className="breadcrumb">
            <li>
              <Link to="/home?dwnews">
                {siteLang == "eng" ? languageText.season3FirstNavigationEn : languageText.season3FirstNavigation}
              </Link>
            </li>
            <li className="active">
              {siteLang == "eng" ? languageText.season3SecondNavigationEn : languageText.season3SecondNavigation}
            </li>
          </ol>
        </div>
      </div>
      <div className="stripe-bg" />
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-12">
            <div className="section">
              <div className="page-header">
                <h1>{siteLang == "eng" ? languageText.season3PageTitleEn : languageText.season3PageTitle}</h1>
                <div className="btn-area">
                  <div className="fbSharebtn" onClick={handleClick}>
                    <i className="fa fa-facebook" aria-hidden="true" />
                    Share on Facebook
                  </div>
                </div>
              </div>
              <div className="photo-main">
                <img src="/img/game/superseason3.jpg" />
              </div>
              <table id="table_1">
                <tbody>
                  <tr>
                    <td className="td_img">
                      <img src="/img/game/superseason2.png" />
                    </td>
                    <td>
                      <div className="text_style1">รางวัลที่ 1</div>
                      <div className="text_style2 text-bold">
                        200,000&nbsp;บาท
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td className="td_img">
                      <img src="/img/game/superseason_2.png" />
                    </td>
                    <td>
                      <div className="text_style1">รางวัลที่ 2</div>
                      <div className="text_style2 text-bold">100,000 บาท</div>
                    </td>
                  </tr>
                  <tr>
                    <td className="td_img">
                      <img src="/img/game/superseason_3.png" />
                    </td>
                    <td>
                      <div className="text_style1">รางวัลที่ 3</div>
                      <div className="text_style2 text-bold">50,000 บาท</div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div id="area_1">
                <div className="inner_area1">รางวัลพิเศษ</div>
                <table id="table_2">
                  <tbody>
                    <tr>
                      <td>
                        <img src="/img/game/superseason_airplane.png" />
                      </td>
                      <td>
                        <div className="text_style3 text-bold">
                          ตั๋วเครื่องบิน + ที่พัก
                        </div>
                        <div>พร้อมดูงานที่ Macquarie สิงคโปร์</div>
                      </td>
                      <td>
                        <img src="/img/game/superseason_air2.png" />
                      </td>
                      <td>
                        <div>ผู้ชนะ 6 คน จากการแข่งขัน ประจำเดือน</div>
                        <div>
                          <Link
                            to={{
                              pathname:
                                "https://t1www5.trkd-hs.com/thaidw_aws/pdf/DW28SPT3.pdf",
                            }}
                            target="_blank"
                          >
                            ดูรายละเอียดกติกา
                          </Link>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div className="inner_title text-bold">Score Board</div>
              <div className="panel-group" id="accordion">
                <div className="panel panel-default">
                  <div className="panel-heading">
                    <a data-toggle="collapse" data-parent="#accordion">
                      <h4
                        id="collapse_title1"
                        className="panel-title text-center"
                        onClick={closeModal}
                      >
                        โค้ชผู้ชนะการแข่งขัน TEAM COACH BATTLE
                        <img
                          className="hidden-xs"
                          style={{ width: "30px", marginLeft: "5px" }}
                          src="/img/game/season3_downarrow.png"
                          onClick={closeModal}
                        />
                      </h4>
                    </a>
                  </div>
                  <div
                    id="collapse_item1"
                    className="collapse_item panel-collapse collapse in"
                    style={{ display: modal1 ? "block" : "none" }}
                  >
                    <div className="row">
                      <div className="col-xs-12 col-sm-6 inner_col1">
                        <table>
                          <tbody>
                            <tr>
                              <td>
                                <img src="/img/game/season_user1.png" />
                              </td>
                              <td>
                                <div style={{ marginBottom: "10px" }}>
                                  <span className="text_style4 text-bold">
                                    The Winner{" "}
                                  </span>
                                  <span className="text_style5 text-bold">
                                    +90.5%
                                  </span>
                                </div>
                                <div>
                                  โค้ช พีช ทีม Gold Roger
                                  <br />
                                  คุณ ทศพล สิงหวิลาวัณย์
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div className="col-xs-12 col-sm-6 inner_col2">
                        <div className="text-bold">
                          "ตลาดหุ้นก็เหมือนผู้หญิง <br />
                          อารมณ์ไม่แน่นอนและถูกเสมอ" <br />
                          <br />
                        </div>
                        <div>
                          อ่านเคล็ดลับของผู้ชนะ
                          <Link
                            target="_blank"
                            to={{
                              pathname:
                                "https://docs.google.com/viewer?url=https://www.thaidw.com/pdf/SPT_Article_November.pdf",
                            }}
                          >
                            <img
                              style={{ width: "70px" }}
                              src="/img/game/season3_button.png"
                            />
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="panel panel-default">
                  <div className="panel-heading">
                    <Link data-toggle="collapse" data-parent="#accordion">
                      <h4
                        id="collapse_title2"
                        className="panel-title text-center"
                        onClick={closeModal2}
                      >
                        ผู้ชนะรางวัลประจำเดือน
                        <img
                          className="hidden-xs"
                          style={{ width: "30px", marginLeft: "5px" }}
                          src="/img/game/season3_downarrow.png"
                          onClick={closeModal2}
                        />
                      </h4>
                    </Link>
                  </div>
                  <div
                    id="collapse_item2"
                    className="collapse_item panel-collapse collapse in"
                    style={{ display: modal2 ? "block" : "none" }}
                  >
                    <div className="row">
                      <div className="col-xs-12 col-sm-6 inner_col1">
                        <table>
                          <tbody>
                            <tr>
                              <td>
                                <img src="/img/game/season_user2.png" />
                              </td>
                              <td>
                                <div style={{ marginBottom: "10px" }}>
                                  <span className="text_style4 text-bold">
                                    ธันวาคม
                                  </span>{" "}
                                  <span className="text_style5 text-bold">
                                    +13.2%
                                  </span>
                                </div>
                                <div>
                                  ทีม Fire dragon
                                  <br />
                                  คุณ กนกวรรณ เกียรติประวัติงาม
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div className="col-xs-12 col-sm-6 inner_col2">
                        <div className="text-bold">
                          "ในการเลือก DW เลือกจาก DW ที่มีสภาพคล่องดี ราคา DW
                          และราคาหุ้นอ้างอิงเคลื่อนไหวสอดคล้องกัน
                          ในระหว่างวันมีปริมาณเสนอซื้อและเสนอขายเพียงพอ"
                        </div>
                        {/*<div>อ่านเคล็ดลับของผู้ชนะ<a target="_blank" href="/thaidw_aws/pdf/SPT_Article_December.pdf"><img style="width: 70px" src="/thaidw_aws/img/marketnews/oct-button.png"></a></div>*/}
                        <div>
                          อ่านเคล็ดลับของผู้ชนะ
                          <Link
                            target="_blank"
                            to={{
                              pathname:
                                "https://docs.google.com/viewer?url=https://www.thaidw.com/pdf/SPT_Article-February_L1-PDF.pdf",
                            }}
                          >
                            <img
                              style={{ width: "70px" }}
                              src="/img/game/season3_button.png"
                            />
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-xs-12 col-sm-6 inner_col1">
                        <table>
                          <tbody>
                            <tr>
                              <td>
                                <img src="/img/game/season_user3.png" />
                              </td>
                              <td>
                                <div style={{ marginBottom: "10px" }}>
                                  <span className="text_style4 text-bold">
                                    มกราคม
                                  </span>{" "}
                                  <span className="text_style5 text-bold">
                                    +58.6%
                                  </span>
                                </div>
                                <div>
                                  ทีม Wind ranger
                                  <br />
                                  คุณ ชนกูร ศรีณรงค์
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div className="col-xs-12 col-sm-6 inner_col2">
                        <div className="text-bold">
                          "Momentum ของตลาดมีความสำคัญ ค่อนข้างมากในการเล่น DW"
                          <br />
                          <br />
                          <br />
                        </div>
                        {/*<div>อ่านเคล็ดลับของผู้ชนะ<a target="_blank" href="/thaidw_aws/pdf/SPT_Article_December.pdf"><img style="width: 70px" src="/thaidw_aws/img/marketnews/oct-button.png"></a></div>*/}
                        <div>
                          อ่านเคล็ดลับของผู้ชนะ
                          <Link
                            target="_blank"
                            to={{
                              pathname:
                                "https://docs.google.com/viewer?url=https://www.thaidw.com/pdf/SPT_Article_January.pdf",
                            }}
                          >
                            <img
                              style={{ width: "70px" }}
                              src="/img/game/season3_button.png"
                            />
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-xs-12 col-sm-6 inner_col1">
                        <table>
                          <tbody>
                            <tr>
                              <td>
                                <img src="/img/game/season_user4.png" />
                              </td>
                              <td>
                                <div style={{ marginBottom: "10px" }}>
                                  <span className="text_style4 text-bold">
                                    ธันวาคม
                                  </span>{" "}
                                  <span className="text_style5 text-bold">
                                    +49.1%
                                  </span>
                                </div>
                                <div>
                                  ทีม อิสระ
                                  <br />
                                  คุณ มานพ ศรีชู
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div className="col-xs-12 col-sm-6 inner_col2">
                        <div className="text-bold">
                          "การเทรด DW มันคือโอกาส สำหรับผู้ที่ต้องการ <br />
                          ศึกษา เข้าใจ และใช้มันสร้างโอกาสทำกำไร ทั้งใน <br />
                          สภาวะขาขึ้นและขาลงของหุ้นอ้างอิงหรือของตลาด"
                        </div>
                        {/*<div>อ่านเคล็ดลับของผู้ชนะ<a target="_blank" href="/thaidw_aws/pdf/SPT_Article_December.pdf"><img style="width: 70px" src="/thaidw_aws/img/marketnews/oct-button.png"></a></div>*/}
                        <div>
                          อ่านเคล็ดลับของผู้ชนะ
                          <Link
                            target="_blank"
                            to={{
                              pathname:
                                "https://docs.google.com/viewer?url=https://www.thaidw.com/pdf/SPT_Article_December.pdf",
                            }}
                          >
                            <img
                              style={{ width: "70px" }}
                              src="/img/game/season3_button.png"
                            />
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-xs-12 col-sm-6 inner_col1">
                        <table>
                          <tbody>
                            <tr>
                              <td>
                                <img src="/img/game/season_user5.png" />
                              </td>
                              <td>
                                <div style={{ marginBottom: "10px" }}>
                                  <span className="text_style4 text-bold">
                                    พฤศจิกายน
                                  </span>{" "}
                                  <span className="text_style5 text-bold">
                                    +52.3%
                                  </span>
                                </div>
                                <div>
                                  ทีม Earth power
                                  <br />
                                  คุณ ชนัดดา เฉลยฤกษ์
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div className="col-xs-12 col-sm-6 inner_col2">
                        <div className="text-bold">
                          "DW ถือเป็นอีกทางเลือกของการหา Cash Flow <br />
                          ด้วยเงินลงทุนเพียงส่วนหนึ่งของราคาหุ้นอ้างอิง <br />
                          ทำกำไรได้ทั้งขาขึ้นและขาลง ทั้งยังกำหนดอัตรา <br />
                          ผลตอบแทนได้เองจากการเลือก Gearing Effect <br />
                          หยุดความเสี่ยงทันทีเมื่อผิดทาง"
                        </div>
                        {/*<div>อ่านเคล็ดลับของผู้ชนะ<a target="_blank" href="/thaidw_aws/pdf/SPT_Article_October.pdf"><img style="width: 70px" src="/thaidw_aws/img/marketnews/oct-button.png"></a></div>*/}
                        <div>
                          อ่านเคล็ดลับของผู้ชนะ
                          <Link
                            target="_blank"
                            to={{
                              pathname:
                                "https://docs.google.com/viewer?url=https://www.thaidw.com/pdf/SPT_Article-November_L1-PDF.pdf",
                            }}
                          >
                            <img
                              style={{ width: "70px" }}
                              src="/img/game/season3_button.png"
                            />
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-xs-12 col-sm-6 inner_col1">
                        <table>
                          <tbody>
                            <tr>
                              <td>
                                <img src="/img/game/season_user6.png" />
                              </td>
                              <td>
                                <div style={{ marginBottom: "10px" }}>
                                  <span className="text_style4 text-bold">
                                    ตุลาคม
                                  </span>{" "}
                                  <span className="text_style5 text-bold">
                                    +195.8%
                                  </span>
                                </div>
                                <div>
                                  ทีม Earth Power
                                  <br />
                                  คุณ ปิยะกาญจน์ คูใจ
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div className="col-xs-12 col-sm-6 inner_col2">
                        <div className="text-bold">
                          "เก็งกำไรจากสิ่งที่ได้มองเห็นในปัจจุบัน <br />
                          ไม่ใช่จากการคาดการณ์สิ่งที่จะเกิดขึ้นในอนาคต"
                          <br />
                          <br />
                          <br />
                          <br />
                        </div>
                        {/*<div>อ่านเคล็ดลับของผู้ชนะ<a target="_blank" href="/thaidw_aws/pdf/SPT_Article_October.pdf"><img style="width: 70px" src="/thaidw_aws/img/marketnews/oct-button.png"></a></div>*/}
                        <div>
                          อ่านเคล็ดลับของผู้ชนะ
                          <Link
                            target="_blank"
                            to={{
                              pathname:
                                "https://docs.google.com/viewer?url=https://www.thaidw.com/pdf/SPT_Article_October.pdf",
                            }}
                          >
                            <img
                              style={{ width: "70px" }}
                              src="/img/game/season3_button.png"
                            />
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="panel panel-default">
                  <div className="panel-heading">
                    <Link data-toggle="collapse" data-parent="#accordion">
                      <h4
                        id="collapse_title3"
                        className="panel-title text-center"
                        onClick={closeModal3}
                      >
                        ผู้ชนะรางวัล Super Trader
                        <img
                          className="hidden-xs"
                          style={{ width: "30px", marginLeft: "5px" }}
                          src="/img/game/season3_downarrow.png"
                          onClick={closeModal3}
                        />
                      </h4>
                    </Link>
                  </div>
                  <div
                    id="collapse_item3"
                    className="collapse_item panel-collapse collapse in"
                    style={{ display: modal3 ? "block" : "none" }}
                  >
                    <div className="inner_title_light text-bold">
                      ผู้ชนะรางวัล DW ที่ 1
                    </div>
                    <div className="row">
                      <div className="col-xs-12 col-sm-6 inner_col1">
                        <table>
                          <tbody>
                            <tr>
                              <td>
                                <img src="/img/game/season_user7.png" />
                              </td>
                              <td>
                                <div style={{ marginBottom: "10px" }}>
                                  <span className="text_style4 text-bold">
                                    คุณ ปิยะกาญจน์ คูใจ
                                  </span>
                                </div>
                                <div>
                                  ทีม Earth Power
                                  <br />
                                  รับเงินรางวัลมูลค่า 200,000 บาท
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div className="col-xs-12 col-sm-6 inner_col2">
                        <div className="text-bold">
                          เปิดพอร์ต วิเคราะห์หุ้น แบบหมดเปลือก
                          <br />
                          <br />
                          <br />
                        </div>
                        {/*<div>อ่านเคล็ดลับของผู้ชนะ<a target="_blank" href="/thaidw_aws/pdf/SPT_Article_December.pdf"><img style="width: 70px" src="/thaidw_aws/img/marketnews/oct-button.png"></a></div>*/}
                        <div>
                          <Link
                            target="_blank"
                            to={{
                              pathname:
                                "https://docs.google.com/viewer?url=https://www.thaidw.com/pdf/SPT_Article-Winner1_L1-PDF.pdf",
                            }}
                          >
                            <img
                              style={{
                                width: "70px",
                                marginRight: "110px",
                                marginTop: "-50px",
                              }}
                              src="/img/game/season3_button.png"
                            />
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="inner_title_light text-bold">
                      ผู้ชนะรางวัล DW ที่ 2
                    </div>
                    <div className="row">
                      <div className="col-xs-12 col-sm-6 inner_col1">
                        <table>
                          <tbody>
                            <tr>
                              <td>
                                <img src="/img/game/season_user8.png" />
                              </td>
                              <td>
                                <div style={{ marginBottom: "10px" }}>
                                  <span className="text_style4 text-bold">
                                    คุณ มานพ ศรีชู
                                  </span>
                                </div>
                                <div>
                                  ทีม อิสระ
                                  <br />
                                  รับเงินรางวัลมูลค่า 100,000 บาท
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div className="col-xs-12 col-sm-6 inner_col2">
                        <div className="text-bold">
                          เปิดพอร์ต วิเคราะห์หุ้น แบบหมดเปลือก
                          <br />
                          <br />
                          <br />
                        </div>
                        {/*<div>อ่านเคล็ดลับของผู้ชนะ<a target="_blank" href="/thaidw_aws/pdf/SPT_Article_December.pdf"><img style="width: 70px" src="/thaidw_aws/img/marketnews/oct-button.png"></a></div>*/}
                        <div>
                          <Link
                            target="_blank"
                            to={{
                              pathname:
                                "https://docs.google.com/viewer?url=https://www.thaidw.com/pdf/SPT_Article-Winner2_L1-PDF.pdf",
                            }}
                          >
                            <img
                              style={{
                                width: "70px",
                                marginRight: "110px",
                                marginTop: "-50px",
                              }}
                              src="/img/game/season3_button.png"
                            />
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className="inner_title_light text-bold">
                      ผู้ชนะรางวัล DW ที่ 3
                    </div>
                    <div className="row">
                      <div className="col-xs-12 col-sm-6 inner_col1">
                        <table>
                          <tbody>
                            <tr>
                              <td>
                                <img src="/img/game/season_user9.png" />
                              </td>
                              <td>
                                <div style={{ marginBottom: "10px" }}>
                                  <span className="text_style4 text-bold">
                                    คุณ ณัฐวุธ บุญอนันต์
                                  </span>
                                </div>
                                <div>
                                  ทีม Fire Dragon
                                  <br />
                                  รับเงินรางวัลมูลค่า 50,000 บาท
                                </div>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div className="col-xs-12 col-sm-6 inner_col2">
                        <div className="text-bold">
                          เปิดพอร์ต วิเคราะห์หุ้น แบบหมดเปลือก
                          <br />
                          <br />
                          <br />
                        </div>
                        {/*<div>อ่านเคล็ดลับของผู้ชนะ<a target="_blank" href="/thaidw_aws/pdf/SPT_Article_December.pdf"><img style="width: 70px" src="/thaidw_aws/img/marketnews/oct-button.png"></a></div>*/}
                        <div>
                          <Link
                            target="_blank"
                            to={{
                              pathname:
                                "https://docs.google.com/viewer?url=https://www.thaidw.com/pdf/SPT_Article-Winner3_L1-PDF.pdf",
                            }}
                          >
                            <img
                              style={{
                                width: "70px",
                                marginRight: "110px",
                                marginTop: "-50px",
                              }}
                              src="/img/game/season3_button.png"
                            />
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="inner_title text-bold">
                ร่วมเกาะติดผลการแข่งขันได้ที่
              </div>
              <table id="table_3">
                <tbody>
                  <tr>
                    <td>
                      <a target="_blank" href="https://www.thaidw.com">
                        <img
                          className="img_style1"
                          src="/img/game/season3_social.png"
                        />
                        <div className="text_style6">www.thaidw.com</div>
                      </a>
                    </td>
                    <td>
                      <Link
                        target="_blank"
                        to={{ pathname: "https://line.me/ti/p/%40upt3914e" }}
                      >
                        <img
                          className="img_style1"
                          src="/img/game/season_social2.png"
                        />
                        <div className="text_style6">@ThaiDW</div>
                      </Link>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <Link
                        target="_blank"
                        to={{ pathname: "http://www.globlex.co.th" }}
                      >
                        <img
                          className="img_style1"
                          src="/img/game/season3_social.png"
                        />
                        <div className="text_style6">www.globlex.co.th</div>
                      </Link>
                    </td>
                    <td>
                      <Link
                        target="_blank"
                        to={{ pathname: "https://www.supertrader.co.th" }}
                      >
                        <img
                          className="img_style1"
                          src="/img/game/season3_social.png"
                        />
                        <div className="text_style6">www.supertrader.co.th</div>
                      </Link>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div>
                <img src="/img/game/season3_social3.jpg" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SeaSon3;
