import React, { useState, useEffect,Fragment } from 'react';
import RiskGearings from './RiskGearings';
import "../../common.css";
import "../../investorAcademy.css";


const RiskGearing = (props) => {
    return ( 
        <Fragment> 
            <RiskGearings/>
        </Fragment> 
     );
}


export default RiskGearing;