export const educationList = [
    {
      th_title: "Learning DW with GURU",
      image: "https://i.ytimg.com/vi/Scek4gCzbNg/mqdefault.jpg",
      th_text: "",
      playlistId: "PLOkzCdnS95xSQp8yCiVe5MT8bV8cjAWZ5",
      en_title: "Learning DW with GURU",
      en_text: "",
    },
    {
      th_title: "DW คืออะไร",
      image: "https://i.ytimg.com/vi/ggXALQLLlJM/mqdefault.jpg",
      th_text: "",
      playlistId: "PLhgNKvNQ3OFKSv3Aoedsx_Pcmkl5Mhw7D",
      en_title: "What is DW?",
      en_text: "",
    }
  ]

export const eduEpisodeData = [
    {
      index: "0",
      image: "https://i.ytimg.com/vi/Scek4gCzbNg/mqdefault.jpg",
      playlistId: "PLOkzCdnS95xSQp8yCiVe5MT8bV8cjAWZ5",
      th_title: "Learning DW with GURU” ตอนที่ 1.1 “DW คืออะไร",
      th_description: "เริ่มต้นเทรด DW แบบ GURU โดย คุณป้อม วัฒนา",
      th_text: "",
      en_title: "Learning DW with GURU” ตอนที่ 1.1 “DW คืออะไร",
      en_description: "เริ่มต้นเทรด DW แบบ GURU โดย คุณป้อม วัฒนา",
      en_text: "",
    },
    {
      index: "1",
      image: "https://i.ytimg.com/vi/qZowrkLN-kc/mqdefault.jpg",
      playlistId: "PLOkzCdnS95xSQp8yCiVe5MT8bV8cjAWZ5",
      th_title: "Learning DW with GURU” ตอนที่ 1.2 “DW คืออะไร",
      th_description: "เริ่มต้นเทรด DW แบบ GURU โดย คุณป้อม วัฒนา",
      th_text: "",
      en_title: "Learning DW with GURU” ตอนที่ 1.2 “DW คืออะไร",
      en_description: "เริ่มต้นเทรด DW แบบ GURU โดย คุณป้อม วัฒนา",
      en_text: "",
    },
    {
      index: "2",
      image: "https://i.ytimg.com/vi/8hqF98G7JbI/mqdefault.jpg",
      playlistId: "PLOkzCdnS95xSQp8yCiVe5MT8bV8cjAWZ5",
      th_title: "Learning DW with GURU” ตอนที่ 2.1 “วิธีเลือก DW",
      th_description: "เริ่มต้นเทรด DW แบบ GURU โดย คุณป้อม วัฒนา",
      th_text: "",
      en_title: "Learning DW with GURU” ตอนที่ 2.1 “วิธีเลือก DW",
      en_description: "เริ่มต้นเทรด DW แบบ GURU โดย คุณป้อม วัฒนา",
      en_text: "",
    },
    {
      index: "3",
      image: "https://i.ytimg.com/vi/7d_4cGNrMdQ/mqdefault.jpg",
      playlistId: "PLOkzCdnS95xSQp8yCiVe5MT8bV8cjAWZ5",
      th_title: "Learning DW with GURU” ตอนที่ 3.1 “วิธีการใช้ตารางราคา",
      th_description: "เริ่มต้นเทรด DW แบบ GURU โดย คุณป้อม วัฒนา",
      th_text: "",
      en_title: "Learning DW with GURU” ตอนที่ 3.1 “วิธีการใช้ตารางราคา",
      en_description: "เริ่มต้นเทรด DW แบบ GURU โดย คุณป้อม วัฒนา",
      en_text: "",
    },
    {
      index: "4",
      image: "https://i.ytimg.com/vi/CAZt9jsNDu0/mqdefault.jpg",
      playlistId: "PLOkzCdnS95xSQp8yCiVe5MT8bV8cjAWZ5",
      th_title: "Learning DW with GURU” ตอนที่ 3.2 “วิธีการใช้ตารางราคา",
      th_description: "เริ่มต้นเทรด DW แบบ GURU โดย คุณป้อม วัฒนา",
      th_text: "",
      en_title: "Learning DW with GURU” ตอนที่ 3.2 “วิธีการใช้ตารางราคา",
      en_description: "เริ่มต้นเทรด DW แบบ GURU โดย คุณป้อม วัฒนา",
      en_text: "",
    },
    {
      index: "5",
      image: "https://i.ytimg.com/vi/GqGqM1kXmVY/mqdefault.jpg",
      playlistId: "PLOkzCdnS95xSQp8yCiVe5MT8bV8cjAWZ5",
      th_title: "Learning DW with GURU” ตอนที่ 3.3 “วิธีการใช้ตารางราคา",
      th_description: "เริ่มต้นเทรด DW แบบ GURU โดย คุณป้อม วัฒนา",
      th_text: "",
      en_title: "Learning DW with GURU” ตอนที่ 3.3 “วิธีการใช้ตารางราคา",
      en_description: "เริ่มต้นเทรด DW แบบ GURU โดย คุณป้อม วัฒนา",
      en_text: "",
    },
    {
      index: "6",
      image: "https://i.ytimg.com/vi/w3bI3tGOvIk/mqdefault.jpg",
      playlistId: "PLOkzCdnS95xSQp8yCiVe5MT8bV8cjAWZ5",
      th_title: "Learning DW with GURU” ตอนที่ 4 “การประเมินราคาDW",
      th_description: "เริ่มต้นเทรด DW แบบ GURU โดย คุณป้อม วัฒนา",
      th_text: "",
      en_title: "Learning DW with GURU” ตอนที่ 4 “การประเมินราคาDW",
      en_description: "เริ่มต้นเทรด DW แบบ GURU โดย คุณป้อม วัฒนา",
      en_text: "",
    },
    {
      index: "0",
      image: "https://i.ytimg.com/vi/ggXALQLLlJM/mqdefault.jpg",
      playlistId: "PLhgNKvNQ3OFKSv3Aoedsx_Pcmkl5Mhw7D",
      th_title: "EP.01 DW คืออะไร",
      th_description: "เริ่มต้นลงทุนใน DW อย่างง่ายๆ โดย Macquarie DW28",
      th_text: "ในวิดีโอนี้ นักลงทุนจะได้เรียนรู้เกี่ยวกับความรู้เบื้องต้นเกี่ยวกับใบสำคัญแสดงสิทธิอนุพันธ์ (Derivative Warrants : DW ) และประเภทของใบสำคัญแสดงสิทธิอนุพันธ์ ซึ่งได้แก่ สิทธิในการซื้อ (Call DW) และสิทธิในการขาย (Put DW)",
      en_title: "EP.01 What is DW?",
      en_description: "เริ่มต้นลงทุนใน DW อย่างง่ายๆ โดย Macquarie DW28",
      en_text: "In this video, you will learn about the introduction to derivative warrants (DW), the famous investment tools today, and the type of DW which are the right to buy the underlying (Call DW) and right to sell underlying (Put DW)",
    },
    {
      index: "1",
      image: "https://i.ytimg.com/vi/E5aFnwSndxA/mqdefault.jpg",
      playlistId: "PLhgNKvNQ3OFKSv3Aoedsx_Pcmkl5Mhw7D",
      th_title: "EP.02 ทำไมต้องเทรด DW",
      th_description: "เริ่มต้นลงทุนใน DW อย่างง่ายๆ โดย Macquarie DW28",
      th_text: "ในวิดีโอนี้ นักลงทุนจะได้เรียนรู้เกี่ยวกับข้อดี หรือประโยชน์ของใบสำคัญแสดงสิทธิอนุพันธ์ (Derivative Warrants : DW) รวมทั้งทำความรู้จักกับ DW บนหุ้นรายตัว และ DW ที่อ้างอิงดัชนีหลักทรัพย์",
      en_title: "EP.02 Why trade DW?",
      en_description: "เริ่มต้นลงทุนใน DW อย่างง่ายๆ โดย Macquarie DW28",
      en_text: "In this video, you will learn about the advantages of trading DW and getting to know the single stock DW and index DW.",
    },
    {
      index: "2",
      image: "https://i.ytimg.com/vi/rLdrQkoJDPM/mqdefault.jpg",
      playlistId: "PLhgNKvNQ3OFKSv3Aoedsx_Pcmkl5Mhw7D",
      th_title: "EP.03 วิธีการเลือก DW",
      th_description: "เริ่มต้นลงทุนใน DW อย่างง่ายๆ โดย Macquarie DW28",
      th_text: "ในวิดีโอนี้ นักลงทุนจะได้เรียนรู้เกี่ยวกับ 4 ขั้นตอนง่ายๆ ในการเลือกลงทุนใบสำคัญแสดงสิทธิอนุพันธ์ (Derivative Warrants: DW) ซึ่งช่วยให้นักลงทุนปฏิบัติตามได้ง่าย และเลือก DW ได้เหมาะสมกับความต้องการของตนเอง",
      en_title: "EP.03 How to select DW?",
      en_description: "เริ่มต้นลงทุนใน DW อย่างง่ายๆ โดย Macquarie DW28",
      en_text: "In this video, you will learn about the advantages of trading DW and getting to know the single stock DW and index DW.",
    },
    {
      index: "3",
      image: "https://i.ytimg.com/vi/IDSTvBoMPKY/mqdefault.jpg",
      playlistId: "PLhgNKvNQ3OFKSv3Aoedsx_Pcmkl5Mhw7D",
      th_title: "EP.04 การซื้อขาย DW",
      th_description: "เริ่มต้นลงทุนใน DW อย่างง่ายๆ โดย Macquarie DW28",
      th_text: "ในวิดีโอนี้ นักลงทุนจะได้เรียนรู้เกี่ยวกับการซื้อขายใบสำคัญแสดงสิทธิอนุพันธ์ (Derivative Warrants: DW) และการใช้ www.thaidw.com เป็นเครื่องมือประกอบการลงทุนใน DW รวมทั้งการใช้ตารางราคา DW เพื่อให้นักลงทุนวางแผนการลงทุนได้ง่ายขึ้น",
      en_title: "EP.04 How to trade DW?",
      en_description: "เริ่มต้นลงทุนใน DW อย่างง่ายๆ โดย Macquarie DW28",
      en_text: "In this video, you will learn about the advantages of trading DW and getting to know the single stock DW and index DW.",
    },
    {
      index: "4",
      image: "https://i.ytimg.com/vi/OQ00pV9UOdE/mqdefault.jpg",
      playlistId: "PLhgNKvNQ3OFKSv3Aoedsx_Pcmkl5Mhw7D",
      th_title: "EP.05 สภาพคล่องของ DW",
      th_description: "เริ่มต้นลงทุนใน DW อย่างง่ายๆ โดย Macquarie DW28",
      th_text: "ในวิดีโอนี้ นักลงทุนจะได้เรียนรู้เกี่ยวกับสภาพคล่องของใบสำคัญแสดงสิทธิอนุพันธ์ (Derivative warrants : DW) ซึ่งเป็นสิ่งที่นักลงทุนมักเข้าใจผิดบ่อยๆในการลงทุนใน DW สภาพคล่องของ DW นั้นแตกต่างจากสภาพคล่องของ DW อย่างมาก ดังนั้น วิดีโอนี้ จึงรวบรวมสิ่งที่นักลงทุนต้องรู้ รวมถึงวิธีการทำให้นักลงทุนเลือก DW ที่เหมาะกับตัวเองได้ดีขึ้น",
      en_title: "EP.05 DW Liquidity",
      en_description: "เริ่มต้นลงทุนใน DW อย่างง่ายๆ โดย Macquarie DW28",
      en_text: "In this video, you will learn about the advantages of trading DW and getting to know the single stock DW and index DW.",
    }
  ]