import React, { useState, useEffect, useRef } from 'react';
import API from '../../../utils/apiCalling';
import { config } from '../../../utils/apiUrl';
import { get } from 'lodash';
import NewsComp from '../../../components/common/NewsComp';
import { languageText } from "../../../languageText/marketNewsCommon"

const UpdateStockNews = () => {

    const api = new API();
    const [updateStockNews, setupdateStockNews] = useState([]);
    const [selectDate,setSelectDate] = useState("")
    const param = "singlestock"
    const dataslider = useRef()
    const [siteLang , setSiteLang] = useState("")

    useEffect(() => {
        window.scrollTo(0, 0);
        var siteLanguage = $.cookie('applang');
        setSiteLang(siteLanguage)
        if(siteLanguage == "eng"){
            document.querySelector("body").classList.add("eng")
        }else{
            document.querySelector("body").classList.remove("eng")
        }
        getDayHighlight()
    }, [])

    useEffect(()=>{
        if(selectDate !== ""){
            getDayHighlight()
        }
    },[selectDate])

    const getDayHighlight = async () => {
        let updateStockNews = await api.get(config.marketupdateStockNews, true, { sp: param, start: "0",... selectDate && {startdate: selectDate} , qid: Date.now() })
        let updateStockNewst = get(updateStockNews, 'newsList', [])
        setupdateStockNews(updateStockNewst)
        dataslider.current.slickGoTo(0)
    };

    const linkData = [
        {
            name:(siteLang == "eng") ? languageText.subscribeSidebarTitleEn : languageText.subscribeSidebarTitle,
            link:"/marketnews/subscribe",
            short:(siteLang == "eng") ? languageText.subscribeSidebarDescriptionEn : languageText.subscribeSidebarDescription,
            imgClass:"section-qlink-subscribe"
        },
        {
            name:"สรุปภาพรวมตลาด",
            link:"/marketnews/dailysinglestock",
            short:"ข่าวล่าสุดเกี่ยวกับตลาดหุ้นชั้นนำทั่วโลกที่อาจมีผลต่อการเทรดของคุณ",
            imgClass:"section-qlink-dailysinglestock"
        },
        // {
        //     name:(siteLang == "eng") ? languageText.overnightMarketSidebarTitleEn : languageText.overnightMarketSidebarTitle,
        //     link:"/marketnews/overnightmarkets",
        //     short:(siteLang == "eng") ? languageText.overnightMarketSidebarDescriptionEn : languageText.overnightMarketSidebarDescription,
        //     imgClass:"section-qlink-marketwrap"
        // },
        // {
        //     name:(siteLang == "eng") ? languageText.dailyCommentarySidebarTitleEn : languageText.dailyCommentarySidebarTitle,
        //     link:"/marketnews/dailycommentary",
        //     short:(siteLang == "eng") ? languageText.dailyCommentarySidebarDescriptionEn : languageText.dailyCommentarySidebarDescription,
        //     imgClass:"section-qlink-marketcommentary"
        // },
        
    ];

    return (
        <NewsComp 
            param={param}
            data={updateStockNews}
            section={siteLang == "eng" ? languageText.firstNavigationEn : languageText.firstNavigation}
            breadcrumb={siteLang == "eng" ? languageText.dailySingleStockNavigationEn : languageText.dailySingleStockNavigation}
            title={siteLang == "eng" ? languageText.dailySingleStockPageTitleEn : languageText.dailySingleStockPageTitle}
            subtitle={siteLang == "eng" ? languageText.dailySingleStockPageDescriptionEn : languageText.dailySingleStockPageDescription}
            dataIndex={1}
            setSelectDate={setSelectDate}
            dataslider={dataslider}
            newsFeeds={linkData}
        />   
    );
}
export default UpdateStockNews;
