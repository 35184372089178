import React, { useState, useEffect, useRef } from "react";
import API from "../../../utils/apiCalling";
import { config } from "../../../utils/apiUrl";
import { get } from "lodash";
import NewsComp from "../../../components/common/NewsComp";
import { languageText } from "../../../languageText/marketNewsCommon"

const DailHsiMarket = () => {

  const api = new API();
  const [DailHsiMarket, setDailHsiMarket] = useState([]);
  const [selectDate, setSelectDate] = useState("");
  const param = "hsi";
  const dataslider = useRef();
  const [siteLang , setSiteLang] = useState("")

  useEffect(() => {
    window.scrollTo(0, 0);
    var siteLanguage = $.cookie('applang');
    setSiteLang(siteLanguage)
    if(siteLanguage == "eng"){
      document.querySelector("body").classList.add("eng")
    }else{
      document.querySelector("body").classList.remove("eng")
    }
  }, []);

  useEffect(() => {
    getDayHighlight();
  }, [selectDate]);

  useEffect(() => {
    if (selectDate !== "") {
      getDayHighlight();
    }
  }, [selectDate]);

  const getDayHighlight = async () => {
    let DailHsiMarket = await api.get(config.marketDailHsiMarket, true, {
      sp: param,
      start: "0",
      ...(selectDate && { startdate: selectDate }),
      qid: Date.now(),
    });
    let DailHsiMarkett = get(DailHsiMarket, "newsList", []);
    setDailHsiMarket(DailHsiMarkett);
    dataslider.current.slickGoTo(0);
  };

  const linkData = [
    {
      name: siteLang == "eng" ? languageText.subscribeSidebarTitleEn : languageText.subscribeSidebarTitle,
      link: "/marketnews/subscribe",
      short: siteLang == "eng"  ? languageText.subscribeSidebarDescriptionEn : languageText.subscribeSidebarDescription,
      imgClass: "section-qlink-subscribe",
    },
    // {
    //   name: siteLang == "eng" ? languageText.overnightMarketSidebarTitleEn : languageText.overnightMarketSidebarTitle,
    //   link: "/marketnews/overnightmarkets",
    //   short: siteLang == "eng" ? languageText.overnightMarketSidebarDescriptionEn : languageText.overnightMarketSidebarDescription,
    //   imgClass: "section-qlink-marketwrap",
    // },
    // {
    //   name: siteLang == "eng" ? languageText.dailyCommentarySidebarTitleEn : languageText.dailyCommentarySidebarTitle,
    //   link: "/marketnews/dailycommentary",
    //   short: siteLang == "eng" ? languageText.dailyCommentarySidebarDescriptionEn : languageText.dailyCommentarySidebarDescription,
    //   imgClass: "section-qlink-marketcommentary",
    // },
  ];

  return (
    <NewsComp
      param={param}
      data={DailHsiMarket}
      section={siteLang == "eng" ? languageText.firstNavigationEn : languageText.firstNavigation}
      breadcrumb={siteLang == "eng" ? languageText.dailyHsiNavigationEn : languageText.dailyHsiNavigation}
      title={siteLang == "eng" ? languageText.dailyHsiPageTitleEn : languageText.dailyHsiPageTitle}
      subtitle={siteLang == "eng" ? languageText.dailyHsiPageDescriptionEn : languageText.dailyHsiPageDescription}
      dataIndex={6}
      setSelectDate={setSelectDate}
      dataslider={dataslider}
      newsFeeds={linkData}
    />
  );
};
export default DailHsiMarket;
