import React, { useState, useEffect } from "react";
import "../../common.css";
import "../../investorAcademy.css";
import { Link, useLocation, useHistory } from "react-router-dom";
import ChildInvestorAcademyCommon from "../../../../../components/common/InvestorAcademyCommon/ChildInvestorAcademyCommon";
import { languageText } from "../../../../../languageText/InvestorAcademy/UsefulWarrantTerms/premium"
import { languageTextMain } from "../../../../../languageText/InvestorAcademy/index"

const Premiums = (props) => {
  
  const location = useLocation();
  const history = useHistory();
  const [siteLang , setSiteLang] = useState("")

  // Site Language Change Start //
  useEffect(() => {
    window.scrollTo(0, 0);
    var siteLanguage = $.cookie('applang');
    setSiteLang(siteLanguage)
    if(siteLanguage == "eng"){
      document.querySelector("body").classList.add("eng")
    }else{
      document.querySelector("body").classList.remove("eng")
    }
  }, []);
  // Site Language Change End //

  return (
    <div id="investor_academy">
      <div className="imgbg" />
      <div className="mainbg" />
      <div id="main">
        <div id="maintable">
          <div style={{ display: "table-row" }}>
            <div id="leftbar" className="hidden-xs" />
            <div id="maincontainer">
              {/* Top Title Section Start */}
              <div id="mainnav">
                <table>
                  <tbody>
                    <tr>
                      <td width="20%" style={{textAlign:"left", visibility: "hidden"}} />
                      <td width="60%" style={{ textAlign: "center" }}>
                        <div id="pagetitlediv">
                          <div id="ptitle">
                            {siteLang == "eng" ? languageText.pageTitleEn : languageText.pageTitle}
                          </div>
                          <div id="pname">
                            {siteLang == "eng" ? languageText.pageNameEn : languageText.pageName}
                          </div>
                        </div>
                      </td>
                      <td width="20%" style={{textAlign:"right", visibility: "hidden"}} />
                    </tr>
                  </tbody>
                </table>
              </div>
              {/* Top Title Section End */}
              {/* Main Content Section Start */}
              <div id="maincontent" className="preminum">
                <div id="mainscroll">
                  <div id="mainscrollcontent">
                    <style dangerouslySetInnerHTML={{__html: ".imgholder img.img01{max-width: 800px;}.exampleholder .examplebox h3{margin:0;margin-bottom:16px;font-weight: normal;}.exampleholder .examplebox .col{float:left;width:50%;min-width:280px;margin:2px 0;}.exampleholder .examplebox .col .lbl,.exampleholder .examplebox .col .val{float:left;font-size:14px;line-height: 1.42857143;}.exampleholder .examplebox .col .lbl{width:130px;color:#8F8F8F;}.formulaholder{max-width:640px !important;}.formulaholder .formulabox{text-align:left;background-color: #1F1F1F !important;padding: 20px 10px 0 10px !important;}.formulaholder .formulabox:last-child{padding-bottom :20px !important;}.formulaholder .formulabox .leqt td{color:#FFDA44;}.formulaholder .formulabox .redtxt{ color:#FE3200;} .formulabox .leqt td{height: 36px;width:240px;vertical-align: top;text-align:right}.hd0{ height:53px !important;}.sm .formulabox .leqt td,.xs .formulabox .leqt td,.xxs .formulabox .leqt td{text-align: left;}.xs .formulabox .hd0,.xxs .formulabox .hd0{height:36px !important;}" }} />
                    <div className="contentbox">
                      <p>{siteLang == "eng" ? languageText.pageDescriptionEn : languageText.pageDescription}</p>
                      <div className="exampleholder">
                        <div className="examplebox">
                          <h3>{siteLang == "eng" ? languageText.exampleBoxHeadingEn : languageText.exampleBoxHeading}</h3>
                          <div className="col">
                            <div className="lbl">{siteLang == "eng" ? languageText.exampleBoxExercisePriceEn : languageText.exampleBoxExercisePrice}</div>
                            <div className="val">{siteLang == "eng" ? languageText.exampleBoxExercisePriceResultEn : languageText.exampleBoxExercisePriceResult}</div>
                          </div>
                          <div className="col">
                            <div className="lbl">{siteLang == "eng" ? languageText.exampleBoxDwPriceEn : languageText.exampleBoxDwPrice}</div>
                            <div className="val">{siteLang == "eng" ? languageText.exampleBoxDwPriceResultEn : languageText.exampleBoxDwPriceResult}</div>
                          </div>
                          <div className="col">
                            <div className="lbl">{siteLang == "eng" ? languageText.exampleBoxDwpsEn : languageText.exampleBoxDwps}</div>
                            <div className="val">{siteLang == "eng" ? languageText.exampleBoxDwpsResultEn : languageText.exampleBoxDwpsResult}</div>
                          </div>
                          <div className="col">
                            <div className="lbl">{siteLang == "eng" ? languageText.exampleBoxUnderlyingEn : languageText.exampleBoxUnderlying}</div>
                            <div className="val">{siteLang == "eng" ? languageText.exampleBoxUnderlyingResultEn : languageText.exampleBoxUnderlyingResult}</div>
                          </div>
                        </div>
                      </div>
                      <div className="formulaholder">
                        <div className="formulabox">
                          <div className="leqt">
                            <table>
                              <tbody>
                                <tr>
                                  <td className="hd0" style={{position: 'relative', top: '-3px'}}>
                                    {siteLang == "eng" ? languageText.formulaHolderLeftEn : languageText.formulaHolderLeft} 
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div className="reqt">
                            <table style={{color: 'white'}}>
                              <tbody>
                                <tr>
                                  <td>=&nbsp;</td>
                                  <td style={{width: '317px'}}>
                                    {siteLang == "eng" ? languageText.formulaHolderRightFirstEn : languageText.formulaHolderRightFirst} 
                                  </td>
                                </tr>
                                <tr>
                                  <td>=&nbsp;</td>
                                  <td style={{width: '317px'}}>
                                    {siteLang == "eng" ? languageText.formulaHolderRightSecondEn : languageText.formulaHolderRightSecond} 
                                  </td>
                                </tr>
                                <tr>
                                  <td>=&nbsp;</td>
                                  <td>
                                    {siteLang == "eng" ? languageText.formulaHolderRightThirdEn : languageText.formulaHolderRightThird} 
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div className="formulabox">
                          <div className="leqt">
                            <table>
                              <tbody>
                                <tr>
                                  <td style={{position: 'relative', top: '-3px'}}>
                                    {siteLang == "eng" ? languageText.formulaHolderLeft1En : languageText.formulaHolderLeft1}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div className="reqt">
                            <table style={{color: 'white'}}>
                              <tbody>
                                <tr>
                                  <td>=&nbsp;</td>
                                  <td style={{width: '317px'}}>
                                    {siteLang == "eng" ? languageText.formulaHolderRight1FirstEn : languageText.formulaHolderRight1First}
                                  </td>
                                </tr>
                                <tr>
                                  <td>=&nbsp;</td>
                                  <td>
                                    {siteLang == "eng" ? languageText.formulaHolderRight1SecondEn : languageText.formulaHolderRight1Second}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                        <div className="formulabox" style={{paddingBottom: '20px'}}>
                          <div className="leqt">
                            <table>
                              <tbody>
                                <tr>
                                  <td style={{position: 'relative', top: '-3px'}}>
                                    {siteLang == "eng" ? languageText.formulaHolderLeft2En : languageText.formulaHolderLeft2}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div className="reqt">
                            <table style={{color: 'white'}}>
                              <tbody>
                                <tr>
                                  <td>=&nbsp;</td>
                                  <td style={{width: '317px'}}>
                                    {siteLang == "eng" ? languageText.formulaHolderRight2FirstEn : languageText.formulaHolderRight2First}
                                  </td>
                                </tr>
                                <tr>
                                  <td>=&nbsp;</td>
                                  <td>
                                    {siteLang == "eng" ? languageText.formulaHolderRight2SecondEn : languageText.formulaHolderRight2Second}
                                    <span style={{color: siteLang == "eng" ? "#FFDA44" : "#FE3200"}}>7.45%</span> 
                                    {siteLang == "eng" ? languageText.formulaHolderRight22SecondEn : languageText.formulaHolderRight22Second}
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      <div className="tipholder">
                        <div className="tipbox">
                          <h2>{siteLang == "eng" ? languageText.tipHolderHeadingEn : languageText.tipHolderHeading}</h2>
                          <ul>
                            <li>
                              <p>
                                {siteLang == "eng" ? languageText.tipHolderParagraph1En : languageText.tipHolderParagraph1} 
                              </p>
                            </li>
                            <li>
                              <p>
                                {siteLang == "eng" ? languageText.tipHolderParagraph2En : languageText.tipHolderParagraph2}
                                <Link to="/eduhtml/impliedvolatility"> {siteLang == "eng" ? languageText.tipHolderParagraph2LinkEn : languageText.tipHolderParagraph2Link} </Link>
                                {siteLang == "eng" ? "." : ""}
                              </p>
                            </li>
                            <li>
                              <p>
                                {siteLang == "eng" ? languageText.tipHolderParagraph3En : languageText.tipHolderParagraph3}
                                <Link to="/eduhtml/timedecay"> {siteLang == "eng" ? languageText.tipHolderParagraph3LinkEn : languageText.tipHolderParagraph3Link} </Link> 
                                {siteLang == "eng" ? languageText.tipHolderParagraph32En : languageText.tipHolderParagraph32}
                              </p>
                            </li>
                            <li>
                              <p>
                                {siteLang == "eng" ? languageText.tipHolderParagraph4En : languageText.tipHolderParagraph4}
                                <a href="/tools/dwsearch"> {siteLang == "eng" ? languageText.tipHolderParagraph4LinkEn : languageText.tipHolderParagraph4Link} </a> 
                                {siteLang == "eng" ? languageText.tipHolderParagraph42En : languageText.tipHolderParagraph42}
                                <a href="/tools/dwterms"> {siteLang == "eng" ? languageText.tipHolderParagraph42LinkEn : languageText.tipHolderParagraph42Link} </a> 
                                {siteLang == "eng" ? "page." : ""}
                              </p>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Main Content Section End */}
            </div>
            <div id="rightbar" className="hidden-xs" />
          </div>
        </div>
      </div>
      {/* Bottom Left Right Links and Copyright Section Start */}
      <div id="bottom">
        <div className="hidden-xs hidden-xxs" id="copyright">
          {siteLang == "eng" ? languageTextMain.copyRightEn : languageTextMain.copyRight}
        </div>
        <div className="leftUrl">
          <table className="hasprev">
            <tbody>
              <tr>
                <td width="33px">
                  <Link to="/eduhtml/breakevenprice">
                    <div className="prev left" />
                  </Link>
                </td>
                <td>
                  <div className="prevlbl left">
                    <div className="prevname">
                      <Link to="/eduhtml/breakevenprice">
                        {siteLang == "eng" ? languageTextMain.breakEvenPriceEn : languageTextMain.breakEvenPrice}
                      </Link>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="rightUrl">
          <table className="hasnext">
            <tbody>
              <tr>
                <td>
                  <div className="nextlbl right">
                    <div className="nextname">
                      <Link to="/eduhtml/gearing">
                        {siteLang == "eng" ? languageTextMain.gearingEn : languageTextMain.gearing}
                      </Link>
                    </div>
                  </div>
                </td>
                <td width="33px">
                  <Link to="/eduhtml/gearing">
                    <div className="next right" />
                  </Link>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      {/* Bottom Left Right Links and Copyright Section End */}
      {/* Right Dots Menu Component Section Start */}
      <div id="dotbar" className="hidden-sm hidden-xs">
        <ChildInvestorAcademyCommon Location={location} Hist={history} />
      </div>
      {/* Right Dots Menu Component Section End  */}
    </div>
  );
};
export default Premiums;
