import React, { useState, useEffect, useRef, Children } from "react";
import "./warrantSearch.css";
import API from "../../../utils/apiCalling";
import { Link } from "react-router-dom";
import { get } from "lodash";
import Select from "react-select";
import { config } from "../../../utils/apiUrl";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSortDown } from "@fortawesome/free-solid-svg-icons";
import { faSortUp } from "@fortawesome/free-solid-svg-icons";
import { useLocation } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { MultiSelect } from "react-multi-select-component";
import LoaderSpin from "../../../components/common/LoaderSpin";
import { languageText } from "../../../languageText/warrantSearch";

const WarrantSearch = () => {

  const api = new API();
  const [siteLang , setSiteLang] = useState("")
  const scrollableElementRef1 = useRef(null);
  const scrollableElementRef2 = useRef(null);
  const [boxVisibility, setBoxVisibility] = useState("hide");
  const queryType = new URLSearchParams(useLocation().search).get("type");
  const queryExpiry = new URLSearchParams(useLocation().search).get("expiry");
  const [headerColor, setHeaderColor] = useState("none");
  const queryUnderlying = new URLSearchParams(useLocation().search).get("underlying");
  const [expiryDates, setExpiryDates] = useState([]);
  const [underlying, setUnderlying] = useState([]);
  const [MACQ, setMACQ] = useState([]);
  const [warrantSearchData, setWarrantSearchData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [initialised, setinitialised] = useState(false);
  const [undefinedSelected, setUndefinedSelected] = useState(null);
  const [qidSelected, setQidSelected] = useState(Date.now());
  const [open, setOpen] = useState(false);
  const [url, setUrl] = useState("");
  const [disclaimerDefination, setDisclaimerDefination] = useState("");
  const [issuerSelected, setIssuerSelected] = useState([
    { label: "MACQ", value: "MACQ" },
  ]);
  const [maturitySelected, setMaturitySelected] = useState({
    value: "all",
    label: "All maturity",
  });
  const [moneynessPercentSelected, setMoneynessPercentSelected] = useState("all");
  const [moneynessSelected, setMoneynessSelected] = useState({
    value: "all",
    label: "All moneyness",
  });
  const [allSelected, setAllSelected] = useState({
    value: "all",
    label: "All",
  });
  const [underlyingSelected, setUnderlyingSelected] = useState({
    value: "all",
    label: "All underlying",
  });
  const [effectiveGearingSelected, setEffectiveGearingSelected] = useState({
    value: "all",
    label: "All Eff. gearing",
  });
  const [sortBySelected, setSortBySelected] = useState("");
  const [indicatorSelected, setIndicatorSelected] = useState({
    value: "all",
    label: "All indicators",
  });
  const [sortOrderSelected, setSortOrderSelected] = useState("asc");
  const [expirySelected, setExpirySelected] = useState({
    value: "all",
    label: "All expiry",
  });

  useEffect(() => {
    window.scrollTo(0, 0);
    var siteLanguage = $.cookie('applang');
    setSiteLang(siteLanguage)
    if(siteLanguage == "eng"){
      document.querySelector("body").classList.add("eng")
    }else{
      document.querySelector("body").classList.remove("eng")
    }
    var urlData = new URLSearchParams(window.location.search)
    var urlDataVal = urlData.get("indicator")
    if(urlDataVal == "HOTLIST"){
      setIndicatorSelected({ value: "HOTLIST", label: "Fast track" })
    }
    else if(urlDataVal == "Caution"){
      setIndicatorSelected({ value: "Caution", label: "Caution" })
    }
    else if(urlDataVal == "Low_time_decay"){
      setIndicatorSelected({ value: "Low_time_decay", label: "Low time decay" })
    }
    else if(urlDataVal == "APPCHEXPRY"){
      setIndicatorSelected({ value: "APPCHEXPRY", label: "Near Expiry" })
    }
    else if(urlDataVal == "SOLDOUT"){
      setIndicatorSelected({ value: "SOLDOUT", label: "Inventory Sold-out" })
    }
    else if(urlDataVal == "Responsive"){
      setIndicatorSelected({ value: "Responsive", label: "Responsive" })
    }
    else{
      setIndicatorSelected({ value: "all", label: "All indicators" })
    }
  }, []);

  useEffect(() => {
    const handleScroll1 = () => {
      if (scrollableElementRef1.current) {
        const newScrollLeft1 = scrollableElementRef1.current.scrollLeft;
        if (scrollableElementRef2.current) {
          scrollableElementRef2.current.scrollLeft = newScrollLeft1;
        }
      }
    };
    const handleScroll2 = () => {
      if (scrollableElementRef2.current) {
        const newScrollLeft2 = scrollableElementRef2.current.scrollLeft;
        if (scrollableElementRef1.current) {
          scrollableElementRef1.current.scrollLeft = newScrollLeft2;
        }
      }
    };
    if (scrollableElementRef1.current && scrollableElementRef2.current) {
      scrollableElementRef1.current.addEventListener('scroll', handleScroll1);
      scrollableElementRef2.current.addEventListener('scroll', handleScroll2);
    }
    return () => {
      if (scrollableElementRef1.current && scrollableElementRef2.current) {
        scrollableElementRef1.current.removeEventListener('scroll', handleScroll1);
        scrollableElementRef2.current.removeEventListener('scroll', handleScroll2);
      }
    };
  }, []);

  // const showNewBox = () => {
  //   if (boxVisibility === "hide") {
  //     setBoxVisibility("show");
  //   } else if (boxVisibility === "show") {
  //     setBoxVisibility("hide");
  //   } else {
  //     setBoxVisibility("hide");
  //   }
  // };

  const impliedVolalitiyData = (warrantData) => {
    var iv = warrantData.impliedVolalitiy;
    if (
      iv == "0.0" ||
      warrantData.delta.toString().indexOf("<") == 0 ||
      warrantData.delta.toString().indexOf(">") == 0
    ) {
      iv = "-";
    }
    return iv;
  };

  const callPut = [
    { value: "all", label: "Call/Put" },
    { value: "call", label: "Call" },
    { value: "put", label: "Put" },
  ];

  const [typeSelected, setTypeSelected] = useState(
    queryType && queryType !== ""
      ? callPut.filter((el) => {
          return el.value === queryType;
        })[0]
      : { value: "all", label: "Call/Put" }
  );

  const moneyness = [
    { value: "all", label: "All moneyness" },
    { value: "atm", label: "At the money" },
    { value: "itm", label: "In the money" },
    { value: "otm", label: "Out of the money" },
  ];

  const all = [
    { value: "all", label: "All" },
    { value: "lt3", label: "<3%" },
    { value: "btw3t6", label: "3-6%" },
    { value: "btw6t9", label: "6-9%" },
    { value: "mt9", label: ">9%" },
  ];

  const maturity = [
    { value: "all", label: "All maturity" },
    { value: "lt1", label: "< 1 month" },
    { value: "btw1t3", label: "1-3 months" },
    { value: "btw3t6", label: "3-6 months" },
    { value: "btw6t9", label: "6-9 months" },
    { value: "mt9", label: "> 9 months" },
  ];

  const effectiveGearing = [
    { value: "all", label: "All Eff. gearing" },
    { value: "ls5", label: "< 5x" },
    { value: "btw5t10", label: "5-10x" },
    { value: "btw10t15", label: "10-15x" },
    { value: "mt15", label: "> 15x" },
  ];

  const indicators = [
    { value: "all", label: "All indicators" },
    { value: "HOTLIST", label: "Fast track" },
    { value: "Caution", label: "Caution" },
    { value: "Low_time_decay", label: "Low time decay" },
    { value: "APPCHEXPRY", label: "Near Expiry" },
    { value: "SOLDOUT", label: "Inventory Sold-out" },
    { value: "Responsive", label: "Responsive" },
  ];

  const listenScrollEvent = () => {
    window.scrollY > 500 ? setHeaderColor("block") : setHeaderColor("none");
  };

  useEffect(() => {
    getWarrantSearch();
  }, [siteLang]);

  useEffect(() => {
    window.addEventListener("scroll", listenScrollEvent);
  });

  const getWarrantSearch = async () => {
    let WarrantSearchResult = await api.get(config.homeWarrantSearch, true, {
      init: 1,
      qid: Date.now(),
    });

    let expiryDatesRes = get(WarrantSearchResult, "expiryDates", []);
    let underlyingRes = get(WarrantSearchResult, "underlyings", []);

    let issuersRes = get(WarrantSearchResult, "issuers", []);
    underlyingRes=underlyingRes.filter((value)=>{
      return value!='HSI'&&value!='HSTECH'&&value!='DJI'&&value!='NDX'&&value!='S&P500'&&value!='SET50'&&value!='HSCEI'&&value!='N225'
    })
    let tempHkStocks= underlyingRes.filter((value)=>{
      return !isNaN(parseFloat(value))
    })
    let tempThaiStocks= underlyingRes.filter((value)=>{
      return isNaN(parseFloat(value))
    })
    tempThaiStocks = tempThaiStocks.map((underlying_name) => ({
      label:underlying_name,
      value: underlying_name,
    }));
    tempHkStocks = tempHkStocks.map((underlying_name) => ({
      label:
        `${underlying_name}` == "9988"
          ? "ALIBABA (9988)"
          : `${underlying_name}` == "0700"
          ? "TENCENT (0700)"
          : `${underlying_name}` == "700"
          ? "TENCENT (0700)"
          : `${underlying_name}` == "3690"
          ? "MEITUAN (3690)"
          : `${underlying_name}` == "1810"
          ? "XIAOMI (1810)"
          : `${underlying_name}` == "2333"
          ? "GREAT WALL MOTOR (2333)"
          : `${underlying_name}` == "9618"
          ? "JD.COM (9618)"
          : `${underlying_name}` == "0175"
          ? "GEELY (0175)"
          : `${underlying_name}` == "175"
          ? "GEELY (0175)"
          : `${underlying_name}` == "1024"
          ? "KUAISHOU TECHNOLOGY (1024)"
          : `${underlying_name}` == "1211"
          ? "BYD (1211)"
          : `${underlying_name}` == "2318"
          ? "PING AN (2318)"
          : `${underlying_name}` == "2382"
          ? "SUNNY OPTICAL TECH (2382)"
          : `${underlying_name}` == "9626"
          ? "BILIBILI (9626)"
          : `${underlying_name}` == "9888"
          ? "BAIDU (9888)"
          : `${underlying_name}` == "6690"
          ? "HAIER SMART HOME (6690)"
          : `${underlying_name}` == "1398"
          ? "ICBC (1398)"
          : `${underlying_name}` == "0005"
          ? "HSBC (0005)"
          : `${underlying_name}` == "005"
          ? "HSBC (0005)"
          : `${underlying_name}` == "05"
          ? "HSBC (0005)"
          : `${underlying_name}` == "5"
          ? "HSBC (0005)"
          : `${underlying_name}` == "1299"
          ? "AIA (1299)"
          : `${underlying_name}` == "1919"
          ? "COSCO SHIPPING (1919)"
          : `${underlying_name}` == "2269"
          ? "WUXI BIOLOGICS (2269)"
          : `${underlying_name}` == "0941"
          ? "CHINA MOBILE (0941)"
          : `${underlying_name}` == "941"
          ? "CHINA MOBILE (0941)"
          : `${underlying_name}` == "0388"
          ? "HKEX (0388)"
          : `${underlying_name}` == "388"
          ? "HKEX (0388)"
          : `${underlying_name}` == "1772"
          ? "GANFEN LITHIUM (1772)"
          : `${underlying_name}` == "883"
          ? "CNOOC (0883)"
          : `${underlying_name}` == "992"
          ? "LENOVO (0992)"
          : `${underlying_name}` == "2899"
          ? "ZIJIN (2899)"
          : `${underlying_name}` == "6862"
          ? "HAIDILAO (6862)"
          : `${underlying_name}` == "293"
          ? "CATHAY (293)"
          : `${underlying_name}` == "241"
          ? "ALIHEALTH (241)"
          : `${underlying_name}` == "9961"
          ? "TRIP.com (9961)"
          : `${underlying_name}` == "9999"
          ? "NETEASE (9999)"
          : `${underlying_name}` == "3968"
          ? "CHINA MERCHANTS BANK (3968)"
          : `${underlying_name}` == "3988"
          ? "BANK OF CHINA (3988)"
          : underlying_name,
      value: underlying_name,
    }));
    tempHkStocks.sort(function(a, b) {
      return (a.label + '').localeCompare(b.label + '')
    })
    tempThaiStocks.sort(function(a, b) {
      return (a.label + '').localeCompare(b.label + '')
    })
    const tempUnderLying = []
    // tempUnderLying.sort(function(a, b) {
    //   return (a.label + '').localeCompare(b.label + '')
    // })
    tempUnderLying.unshift(
      { value: "all", label: siteLang == "eng" ? "All underlying" : "หุ้นอ้างอิงทั้งหมด" },
      { value: "Allforeignindices", label: siteLang == "eng" ? "All foreign indices and stock" : "ดัชนีหุ้นต่างประเทศและหุ้นรายตัวทั้งหมด" },
      { value: "ThaiSS", label: siteLang == "eng" ? "Thai single stock" : "หุ้นไทย" },
      { value: "Thaiindex", label: siteLang == "eng" ? "Thai index" : "ดัชนีหุ้นไทย"},
      { value: "HKSS", label: siteLang == "eng" ? "Hong Kong single stock" : "หุ้นฮ่องกง" },
      { value: "HKindex", label: siteLang == "eng" ? "Hong Kong index" : "ดัชนีหุ้นฮ่องกง" },
      { value: "USindex", label: siteLang == "eng" ? "US index" : "ดัชนีหุ้นสหรัฐฯ" },
      { value: "HSI", label: 'HSI' },
      // { value: "HSCEI", label: 'HSCEI' },
      { value: "HSTECH", label: 'HSTECH' },
      { value: "DJI", label: 'DJI' },
      { value: "NDX", label: 'NDX' },
      { value: "S&P500", label: 'S&P500' },
      { value: "SET50", label: 'SET50' },
      { value: "N225", label: 'N225' },
    );
    tempUnderLying.push(...tempHkStocks,...tempThaiStocks)
    const tempExpiryDates = expiryDatesRes.map((expiryDate) => ({
      label: expiryDate,
      value: expiryDate,
    }));
    tempExpiryDates.unshift({ value: "all", label: "All expiry" });

    const tempIssues = issuersRes.map((issuer) => ({
      label: issuer,
      value: issuer,
    }));
    var tempIssuesMacq = tempIssues.splice(3, 1);
    tempIssues.unshift(tempIssuesMacq[0])
    var urlWarrantSearch = window.location.pathname.split("/")[3]
    var sund = urlWarrantSearch && urlWarrantSearch !== ""
               ? tempUnderLying.filter((el) => { return el.value.toLowerCase() === urlWarrantSearch.toLowerCase() }) : { value: "all", label: "All underlying" };
    if(sund[0]){
      setUnderlyingSelected(sund[0]);
    }
    const sexp = tempExpiryDates.filter((el) => {
      return el.value === queryExpiry;
    })[0];
    
    setExpiryDates(tempExpiryDates);
    setUnderlying(tempUnderLying);
    setExpirySelected(sexp);
    setMACQ(tempIssues);
    setinitialised(true);
  };

  useEffect(() => {
    if (initialised) {
      getWarrantSearchTable(sortOrderSelected, sortBySelected);
    }
    return () => {};
  }, [initialised, underlying]);

  const getWarrantSearchTable = async (sortOrder, sortBy) => {
    var urlWarrantSearch = window.location.pathname.split("/")[3]
    var underLyingVal;
    if(urlWarrantSearch){
      var setUnderLyingVal = underlying.filter(function (el) {
        if(el.value.toLowerCase() == urlWarrantSearch.toLowerCase()){
          underLyingVal = el
        }
      });
    }
    
    if(underLyingVal !== undefined && underLyingVal !== null && underLyingVal != ""){
      setUnderlyingSelected(underLyingVal)
    }
    setIsLoading(true);
    let data = {
      // undefined: undefinedSelected === null ? "" : undefinedSelected.value,
      // underlying: window.location.pathname.split("/").length == 4 ? urlWarrantSearch : underlyingSelected[0] !== undefined && underlyingSelected[0].value == "all" ? "all" : underlyingSelected[0] ? underlyingSelected[0].value : underlyingSelected.value,
      underlying: underlyingSelected.value,
      type: typeSelected === null ? "all" : typeSelected.value,
      issuer:
        issuerSelected.length === MACQ.length
          ? "all"
          : issuerSelected.map(({ value }) => value).join(";"),
      maturity: maturitySelected.value,
      moneyness: moneynessSelected.value,
      //all Percent AllSet
      // all: allSelected.value,
      // moneynessPercent: moneynessPercentSelected === null ? "all" : moneynessPercentSelected.value,

      moneynessPercent: allSelected.value,
      effectiveGearing: effectiveGearingSelected.value,
      expiry:
        expirySelected === null || expirySelected === undefined
          ? "all"
          : expirySelected.value,
      indicator: indicatorSelected.value,
      sortBy,
      sortOrder,
      qid: qidSelected,
    };
    let WarrantSearchTableResult = await api.get(
      config.homeWarrantSearch,
      true,
      data
    );
    if (WarrantSearchTableResult && WarrantSearchTableResult.data) {
      let warrantSearchDataRes = get(WarrantSearchTableResult, "data", []);
      for(let i =0;i<warrantSearchDataRes.length;i++){
        if(
          warrantSearchDataRes[i].underlyingSymbol== "SET50" ||
          warrantSearchDataRes[i].underlyingSymbol== "HSI" ||
          warrantSearchDataRes[i].underlyingSymbol== "HSTECH" ||
          warrantSearchDataRes[i].underlyingSymbol== "HSCEI" ||
          warrantSearchDataRes[i].underlyingSymbol== "S&P500" ||
          warrantSearchDataRes[i].underlyingSymbol== "NDX" ||
          warrantSearchDataRes[i].underlyingSymbol== "DJI" 
        ){
          if(((warrantSearchDataRes[i].sensitivity != "-" || sen != "N/A") && Number(warrantSearchDataRes[i].sensitivity) != 0)){
            warrantSearchDataRes[i].sbip= (1.0 / Number(warrantSearchDataRes[i].sensitivity_cal)).toFixed(2)
          }else{
            warrantSearchDataRes[i].sbip= '-'
          }
        }else{
          warrantSearchDataRes[i].sbip= '-';
        }
      }
      setWarrantSearchData(warrantSearchDataRes);
      setIsLoading(false);
    }
  };

  const getWarrantSearchTableSubmit = async (sortOrder, sortBy) => {
    setIsLoading(true);
    let data = {
      // undefined: undefinedSelected === null ? "" : undefinedSelected.value,
      underlying: (underlyingSelected[0] !== undefined && underlyingSelected[0]?.value == "all") ? "all" : underlyingSelected[0] ? underlyingSelected[0]?.value : underlyingSelected?.value,
      type: typeSelected === null ? "all" : typeSelected.value,
      issuer:
        issuerSelected.length === MACQ.length
          ? "all"
          : issuerSelected.map(({ value }) => value).join(";"),
      maturity: maturitySelected.value,
      moneyness: moneynessSelected.value,
      //all Percent AllSet
      // all: allSelected.value,
      // moneynessPercent: moneynessPercentSelected === null ? "all" : moneynessPercentSelected.value,

      moneynessPercent: allSelected.value,
      effectiveGearing: effectiveGearingSelected.value,
      expiry:
        expirySelected === null || expirySelected === undefined
          ? "all"
          : expirySelected.value,
      indicator: indicatorSelected.value,
      sortBy,
      sortOrder,
      qid: qidSelected,
    };
    let WarrantSearchTableResult = await api.get(
      config.homeWarrantSearch,
      true,
      data
    );
    if (WarrantSearchTableResult && WarrantSearchTableResult.data) {
      let warrantSearchDataRes = get(WarrantSearchTableResult, "data", []);
      for(let i =0;i<warrantSearchDataRes.length;i++){
        if(
          warrantSearchDataRes[i].underlyingSymbol== "SET50" ||
          warrantSearchDataRes[i].underlyingSymbol== "HSI" ||
          warrantSearchDataRes[i].underlyingSymbol== "HSTECH" ||
          warrantSearchDataRes[i].underlyingSymbol== "HSCEI" ||
          warrantSearchDataRes[i].underlyingSymbol== "S&P500" ||
          warrantSearchDataRes[i].underlyingSymbol== "NDX" ||
          warrantSearchDataRes[i].underlyingSymbol== "DJI" 
        ){
          if(((warrantSearchDataRes[i].sensitivity != "-" || sen != "N/A") && Number(warrantSearchDataRes[i].sensitivity) != 0)){
            warrantSearchDataRes[i].sbip= (1.0 / Number(warrantSearchDataRes[i].sensitivity_cal)).toFixed(2)
          }else{
            warrantSearchDataRes[i].sbip= '-'
          }
        }else{
          warrantSearchDataRes[i].sbip= '-'
        }
      }
      setWarrantSearchData(warrantSearchDataRes);
      setIsLoading(false);
    }
  };
  const sortSbip = (type, sortBy)=>{
    var tableData = [...warrantSearchData];
    if(type=='asc'){
      tableData.sort(function (a, b) {
          return a['sbip'].localeCompare(b['sbip'], 'zh-CN', { numeric: true })
        })
    }else{
      tableData.sort(function (a, b) {
          return b['sbip'].localeCompare(a['sbip'], 'zh-CN', { numeric: true })
      })
    }
    setWarrantSearchData(tableData);
  }
  const sortTable = (type, sortBy) => {
    getWarrantSearchTableSubmit(type, sortBy);
    setSortOrderSelected(type);
    setSortBySelected(sortBy);
  };

  const onClickPopHandle = (url, value) => {
    setOpen(value);
    setUrl(url);
  };

  const setClass = (val) => {
    let className = " col-priceChange val bordered ";
    if (val?.toString().includes("+")) {
      className += "up";
    } else if (val?.toString().includes("-")) {
      className += "down";
    }
    return className;
  };

  const colourStyles = {
    option: (provided, state) => {
      return {
        backgroundColor: state.isFocused ? "#f5f5f5" : null,
        color: "#333",
        fontSize: "14px",
        padding: "5px 20px",
        cursor: "pointer",
        fontFamily: siteLang == "eng" ? "'MCQGlobal_A_Lt','MCQGlobal_A_Bd','Roboto', Arial, sans-serif" : "'DBHeaventLight','DBHeavent','Roboto', Arial, sans-serif",
      };
    },
  };
  
  const colourStyles1 = {
    option: (style, { isSelected, isFocused }) => {
      return {
        ...style,
        backgroundColor: isFocused ? "#ffffff" : null,
        color: "blue",
        fontSize: "14px",
        padding: "5px 20px",
        cursor: "pointer",
        fontFamily: siteLang == "eng" ? "'MCQGlobal_A_Lt','MCQGlobal_A_Bd','Roboto', Arial, sans-serif" : "'DBHeaventLight','DBHeavent','Roboto', Arial, sans-serif",
      };
    },
  };

  const colourStyles2 = {
    option: (provided, state) => {
      return {
        backgroundColor: state.isFocused ? "#007fff" : null,
        color: "#333",
        color: state.isFocused ? "#ffffff" : null,
        fontSize: siteLang == "eng" ? "14px" : "21px",
        padding: "3px 14px 3px 5.6px",
        cursor: "pointer",
        borderTop: state.isFocused ? "1px solid #003eff" : null,
        borderBottom: state.isFocused ? "1px solid #003eff" : null,
        fontFamily: siteLang == "eng" ? "'MCQGlobal_A_Lt','MCQGlobal_A_Bd','Roboto', Arial, sans-serif" : "'DBHeaventLight','DBHeavent','Roboto', Arial, sans-serif",
      };
    },
  };

  const disclaimerDefinationFun = () => {
    if(disclaimerDefination == ""){
      setDisclaimerDefination("in")
    }else{
      setDisclaimerDefination("")
    }
  }

  return ( 
    <>
      <div id="dwsearch" className="pageobj">
        <div className="breadcrumbbar">
          <div className="container-fluid">
            <ol className="breadcrumb">
              <li>
                <Link to="/home">
                  {siteLang == "eng" ? languageText.firstNavigationEn : languageText.firstNavigation}
                </Link>
              </li>
              <li className="active">
                {siteLang == "eng" ? languageText.secondNavigationEn : languageText.secondNavigation}
              </li>
            </ol>
          </div>
        </div>
        <div className="stripe-bg" />
        <div className="container-fluid">
          <div className="section">
            <div className="page-header">
              <h1>
                {siteLang == "eng" ? languageText.pageTitleEn : languageText.pageTitle}
              </h1>
            </div>
            <p className="section-txt">
              {siteLang == "eng" ? languageText.pageDescriptionEn : languageText.pageDescription}
            </p>
            <div className="filter">
              <div className="row">
                <div className="col-sm-4">
                  <div className="filter-item">
                    <Select
                      className="warrant-search_dropdown"
                      placeholder={"All Underlying"}
                      value={underlyingSelected}
                      onChange={setUnderlyingSelected}
                      options={underlying}
                      styles={colourStyles2}
                      overrideStrings={{
                        9988: "ALIBABA (9988)",
                      }}
                    />
                  </div>
                  <div className="filter-item hidden-xs">
                    <Select
                      className="warrant-search_dropdown"
                      placeholder={"All Maturity"}
                      value={maturitySelected}
                      isSearchable={false}
                      onChange={setMaturitySelected}
                      options={maturity}
                      styles={colourStyles}
                    />
                  </div>
                  <div className="filter-item hidden-xs">
                    <Select
                      className="warrant-search_dropdown"
                      placeholder={"All expiry"}
                      value={expirySelected}
                      isSearchable={false}
                      onChange={setExpirySelected}
                      options={expiryDates}
                      styles={colourStyles}
                    />
                  </div>
                </div>

                <div className="col-sm-4">
                  <div className="filter-item">
                    <Select
                      className="warrant-search_dropdown"
                      placeholder={"Call/Put"}
                      value={typeSelected}
                      isSearchable={false}
                      onChange={setTypeSelected}
                      options={callPut}
                      styles={colourStyles}
                    />
                  </div>
                  <div className="filter-item hidden-xs">
                    <Select
                      className="warrant-search_dropdown"
                      placeholder={"All Eff. Gearing"}
                      value={effectiveGearingSelected}
                      isSearchable={false}
                      onChange={setEffectiveGearingSelected}
                      options={effectiveGearing}
                      styles={colourStyles}
                    />
                  </div>
                  <div className="filter-item hidden-xs">
                    <Select
                      className="warrant-search_dropdown"
                      placeholder={"All Indicators"}
                      value={indicatorSelected}
                      isSearchable={false}
                      onChange={setIndicatorSelected}
                      options={indicators}
                      styles={colourStyles}
                    />
                  </div>
                </div>

                <div className="col-sm-4">
                  <div className="filter-item hidden-xs">
                    <table style={{ width: "100%" }}>
                      <tbody>
                        <tr>
                          <td className="filter-item-left">
                            <Select
                              className="warrant-search_dropdown"
                              placeholder={"All Moneyness"}
                              value={moneynessSelected}
                              isSearchable={false}
                              onChange={setMoneynessSelected}
                              options={moneyness}
                              styles={colourStyles}
                            />
                          </td>
                          <td className="filter-item-right">
                            <Select
                              className="warrant-search_dropdown"
                              placeholder={"All"}
                              value={allSelected}
                              isSearchable={false}
                              onChange={setAllSelected}
                              options={all}
                              styles={colourStyles}
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="filter-item">
                    <MultiSelect
                      label="Issuer"
                      style={colourStyles1}
                      labelledBy="Issuer"
                      avoidHighlightFirstOption={false}
                      disableSearch="false"
                      options={MACQ}
                      overrideStrings={{
                        allItemsAreSelected: "All issuers",
                        selectAll: "All issuers",
                        someItemsSelected: "",
                      }}
                      defaultValue={{ label: "Ambank", value: "Ambank" }}
                      value={issuerSelected}
                      onChange={(val) => {
                        if (val.length > 0) {
                          setIssuerSelected(val);
                        } else {
                          setIssuerSelected([
                            { label: "MACQ", value: "MACQ" },
                          ]);
                        }
                      }}
                    />
                  </div>
                  <div className="filter-item-button">
                    <button
                      onClick={() =>
                        getWarrantSearchTableSubmit(sortOrderSelected, '')
                      }
                      type="button"
                      className="btn btn-block btn-primary"
                    >
                      {siteLang == "eng" ? "Submit" : " ค้นหา "}
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="indicator">
              <ul className="indicator-list list-unstyled list-inline">
                <li>
                  <div className="indicator-item small">
                    <span className="icon-hotdw indicator-item-icon" />
                    <span
                      className="ttip"
                      data-tip
                      data-for={"fastTrackIndicate"}
                    >
                      {siteLang == "eng" ? languageText.fastTrackIndicateEn : languageText.fastTrackIndicate}
                    </span>
                  </div>
                  <ReactTooltip
                    id={"fastTrackIndicate"}
                    delayHide={100}
                    place="right"
                    type="warning"
                    effect="solid"
                    clickable={true}
                  >
                    <div className="bg-tooltip">
                      <p className="tooltip-content">
                        <strong>{siteLang == "eng" ? languageText.fastTrackIndicateEn : languageText.fastTrackIndicate}:</strong>
                        {siteLang == "eng" ? languageText.fastTrackIndicateTooltipEn : languageText.fastTrackIndicateTooltip}
                      </p>
                    </div>
                  </ReactTooltip>
                </li>
                <li>
                  <div className="indicator-item small">
                    <span className="icon-caution indicator-item-icon" />
                    <span
                      className="ttip"
                      data-tip
                      data-for={"cautionIndicate"}
                    >
                      {siteLang == "eng" ? languageText.cautionIndicateEn : languageText.cautionIndicate}
                    </span>
                  </div>
                  <ReactTooltip
                    id={"cautionIndicate"}
                    delayHide={100}
                    place="right"
                    type="warning"
                    effect="solid"
                    clickable={true}
                  >
                    <div className="bg-tooltip">
                      <p className="tooltip-content">
                        <strong>{siteLang == "eng" ? languageText.cautionIndicateEn : languageText.cautionIndicate}:</strong>
                        {siteLang == "eng" ? languageText.cautionIndicateTooltipEn : languageText.cautionIndicateTooltip}
                        <a href="https://www.thaidw.com/education/individualtip?id=97">
                          {siteLang == "eng" ? languageText.tooltipClickEn : languageText.tooltipClick}
                        </a>
                      </p>
                    </div>
                  </ReactTooltip>
                </li>
                <li>
                  <div className="indicator-item small">
                    <span
                      className="icon-expiry indicator-item-icon"
                      data-target="nearexpiry"
                    />
                    <span
                      className="ttip"
                      data-tip
                      data-for={"nearExpiryIndicate"}
                    >
                      {siteLang == "eng" ? languageText.nearExpiryIndicateEn : languageText.nearExpiryIndicate}
                    </span>
                  </div>
                  <ReactTooltip
                    id={"nearExpiryIndicate"}
                    delayHide={100}
                    place="right"
                    type="warning"
                    effect="solid"
                    clickable={true}
                  >
                    <div className="bg-tooltip">
                      <p className="tooltip-content">
                        <strong>
                          {siteLang == "eng" ? languageText.nearExpiryIndicateEn : languageText.nearExpiryIndicate}:
                        </strong>
                        {siteLang == "eng" ? languageText.nearExpiryIndicateTooltipEn : languageText.nearExpiryIndicateTooltip}
                      </p>
                    </div>
                  </ReactTooltip>
                </li>
                <li>
                  <div className="indicator-item small">
                    <span className="icon-soldout indicator-item-icon" />
                    <span
                      className="ttip"
                      data-tip
                      data-for={"inventoryIndicate"}
                    >
                      {siteLang == "eng" ? languageText.inventoryIndicateEn : languageText.inventoryIndicate}
                    </span>
                  </div>
                  <ReactTooltip
                    id={"inventoryIndicate"}
                    delayHide={100}
                    place="right"
                    type="warning"
                    effect="solid"
                    clickable={true}
                  >
                    <div className="bg-tooltip">
                      <p className="tooltip-content">
                        <strong>{siteLang == "eng" ? languageText.inventoryIndicateEn : languageText.inventoryIndicate}:</strong>
                        {siteLang == "eng" ? languageText.inventoryIndicateTooltipEn : languageText.inventoryIndicateTooltip}
                      </p>
                    </div>
                  </ReactTooltip>
                </li>
                <li>
                  <div className="indicator-item small">
                    <span className="icon-time indicator-item-icon" />
                    <span
                      className="ttip"
                      data-tip
                      data-for={"lowTimeIndicate"}
                    >
                      {siteLang == "eng" ? languageText.lowTimeIndicateEn : languageText.lowTimeIndicate}
                    </span>
                  </div>
                  <ReactTooltip
                    id={"lowTimeIndicate"}
                    delayHide={100}
                    place="right"
                    type="warning"
                    effect="solid"
                    clickable={true}
                  >
                    <div className="bg-tooltip">
                      <p className="tooltip-content">
                        <strong>{siteLang == "eng" ? languageText.lowTimeIndicateEn : languageText.lowTimeIndicate}:</strong>
                        {siteLang == "eng" ? languageText.lowTimeIndicateTooltipEn : languageText.lowTimeIndicateTooltip}
                      </p>
                    </div>
                  </ReactTooltip>
                </li>
                <li>
                  <div className="indicator-item small">
                    <span className="icon-responsive indicator-item-icon" />
                    <span
                      className="ttip"
                      data-tip
                      data-for={"responsiveIndicate"}
                    >
                      {siteLang == "eng" ? languageText.responsiveIndicateEn : languageText.responsiveIndicate}
                    </span>
                  </div>
                  <ReactTooltip
                    id={"responsiveIndicate"}
                    delayHide={100}
                    place="right"
                    type="warning"
                    effect="solid"
                    clickable={true}
                  >
                    <div className="bg-tooltip">
                      <p className="tooltip-content">
                        <strong>{siteLang == "eng" ? languageText.responsiveIndicateEn : languageText.responsiveIndicate}: </strong>
                        {siteLang == "eng" ? languageText.responsiveIndicateTooltip1En : languageText.responsiveIndicateTooltip1}
                        <a href="https://www.thaidw.com/education/individualtip?id=135&type=set" target="_blank">
                          {siteLang == "eng" ? languageText.tooltipClickHereEn : languageText.tooltipClickHere}
                        </a>
                        {siteLang == "eng" ? languageText.responsiveIndicateTooltip2En : languageText.responsiveIndicateTooltip2}
                      </p>
                    </div>
                  </ReactTooltip>
                </li>
              </ul>
            </div>
            <div id="resulttable" className="result" style={{overflowX: "auto"}} ref={scrollableElementRef1}>
              
              <table id="resulttable-lg">
                <thead>
                  <tr className="result-hdr1">
                    <th className="bgcolor-04" colSpan={5} style={{ minWidth: "399px"}}>
                      GENERAL
                    </th>
                    <th className="bgcolor-05" colSpan={5} style={{ minWidth: "310px"}}>
                      MARKET
                    </th>
                    <th className="bgcolor-06" colSpan={6} style={{ minWidth: "382px"}}>
                      DW
                    </th>
                  </tr>
                  <tr className="result-hdr2">
                    <th className="bgcolor-04 col_sticky width133">
                      <span className="ttip" data-tip data-for={"dwSymbolTable"}>
                        {siteLang == "eng" ? languageText.dwSymbolTableEn : languageText.dwSymbolTable}
                      </span>
                      <ReactTooltip
                        id={"dwSymbolTable"}
                        delayHide={100}
                        place="right"
                        type="warning"
                        effect="solid"
                        clickable={true}
                      >
                        <div className="bg-tooltip">
                          <p className="tooltip-content">
                            <strong>
                              {siteLang == "eng" ? languageText.dwSymbolTableEn : languageText.dwSymbolTable}:
                            </strong>
                            {siteLang == "eng" ? languageText.dwSymbolTableTooltip1En : languageText.dwSymbolTableTooltip1}
                            <Link to="/eduhtml/howarewarrantsnamed" target="_blank">
                              {siteLang == "eng" ? languageText.tooltipClickHereEn : languageText.tooltipClickHere}
                            </Link>
                            {siteLang == "eng" ? languageText.dwSymbolTableTooltip2En : languageText.dwSymbolTableTooltip2}
                          </p>
                        </div>
                      </ReactTooltip>
                    </th>
                    <th className="bgcolor-04 width78" style={{ width: "7.15%" }}>
                      {siteLang == "eng" ? languageText.indicatorTableEn : languageText.indicatorTable}
                    </th>
                    <th className="bgcolor-04 width67" style={{ width: "6.1%" }}>
                      <span className="ttip" data-tip data-for={"exercisepriceTable"}>
                        {siteLang == "eng" ? languageText.exercisepriceTableEn : languageText.exercisepriceTable}
                      </span>
                      <ReactTooltip
                        id={"exercisepriceTable"}
                        delayHide={100}
                        place="right"
                        type="warning"
                        effect="solid"
                        clickable={true}
                      >
                        <div className="bg-tooltip">
                          <p className="tooltip-content">
                            <strong>
                              {siteLang == "eng" ? languageText.exercisepriceTableEn : languageText.exercisepriceTable}:
                            </strong>
                            {siteLang == "eng" ? languageText.exercisepriceTableTooltipEn : languageText.exercisepriceTableTooltip}
                          </p>
                        </div>
                      </ReactTooltip>
                    </th>
                    <th className="bgcolor-04 width67" style={{ width: "6.1%" }}>
                      <span
                        className="ttip"
                        data-tip
                        data-for={"lastTradingDateTable"}
                      >
                        {siteLang == "eng" ? languageText.lastTradingDateTableEn : languageText.lastTradingDateTable}
                      </span>
                      <ReactTooltip
                        id={"lastTradingDateTable"}
                        delayHide={100}
                        place="right"
                        type="warning"
                        effect="solid"
                        clickable={true}
                      >
                        <div className="bg-tooltip">
                          <p className="tooltip-content">
                            <strong>
                              {siteLang == "eng" ? languageText.lastTradingDateTableEn : languageText.lastTradingDateTable}:
                            </strong>
                            {siteLang == "eng" ? languageText.lastTradingDateTableTooltipEn : languageText.lastTradingDateTableTooltip}
                          </p>
                        </div>
                      </ReactTooltip>
                    </th>
                    <th className="bgcolor-04 width56" style={{ width: "5.1%" }}>
                      <span
                        className="ttip"
                        data-tip
                        data-for={"exercise-ratio"}
                      >
                        {siteLang == "eng" ? languageText.issuerTableEn : languageText.issuerTable}
                      </span>
                      <ReactTooltip
                        id={"exercise-ratio"}
                        delayHide={100}
                        place="right"
                        type="warning"
                        effect="solid"
                        clickable={true}
                      >
                        <div className="bg-tooltip">
                          <p className="tooltip-content">
                            <strong>
                              {siteLang == "eng" ? languageText.issuerTableEn : languageText.issuerTable}:
                            </strong>{" "}
                            {siteLang == "eng" ? languageText.issuerTableTooltipEn : languageText.issuerTableTooltip}
                          </p>
                        </div>
                      </ReactTooltip>
                    </th>
                    <th className="bgcolor-05 width67" style={{ width: "6.1%" }}>
                      <span
                        className="ttip"
                        data-tip
                        data-for={"tradedVolumeTable"}
                      >
                        {siteLang == "eng" ? languageText.tradedVolumeTableEn : languageText.tradedVolumeTable}
                        <br />
                        ('000)
                      </span>
                      <ReactTooltip
                        id={"tradedVolumeTable"}
                        delayHide={100}
                        place="right"
                        type="warning"
                        effect="solid"
                        clickable={true}
                      >
                        <div className="bg-tooltip">
                          <p className="tooltip-content">
                            <strong>
                              {siteLang == "eng" ? languageText.tradedVolumeTableEn : languageText.tradedVolumeTable}
                            </strong>
                            {siteLang == "eng" ? languageText.tradedVolumeTableTooltip1En : languageText.tradedVolumeTableTooltip1}
                            <Link
                              to={{ pathname: "/eduhtml/marketmakers" }}
                              target="_blank"
                            >
                              {siteLang == "eng" ? languageText.tooltipClickHereEn : languageText.tooltipClickHere}
                            </Link>
                            {siteLang == "eng" ? languageText.tradedVolumeTableTooltip2En : languageText.tradedVolumeTableTooltip2}
                          </p>
                        </div>
                      </ReactTooltip>
                    </th>
                    <th className="bgcolor-05 width67" style={{ width: "6.1%" }}>
                      <span
                        className="ttip"
                        data-tip
                        data-for={"bidVolumeTable"}
                      >
                        {siteLang == "eng" ? languageText.bidVolumeTableEn : languageText.bidVolumeTable}
                        <br />
                        ('000)
                      </span>
                      <ReactTooltip
                        id={"bidVolumeTable"}
                        delayHide={100}
                        place="right"
                        type="warning"
                        effect="solid"
                        clickable={true}
                      >
                        <div className="bg-tooltip">
                          <p className="tooltip-content">
                            <strong>
                              {siteLang == "eng" ? languageText.bidVolumeTableEn : languageText.bidVolumeTable}
                            </strong>
                            {siteLang == "eng" ? languageText.bidVolumeTableTooltipEn : languageText.bidVolumeTableTooltip}
                          </p>
                        </div>
                      </ReactTooltip>
                    </th>
                    <th className="bgcolor-05 width56" style={{ width: "5.1%" }}>
                      <span
                        className="ttip"
                        data-tip
                        data-for={"bidThbTable"}
                      >
                        {siteLang == "eng" ? languageText.bidThbTable1En : languageText.bidThbTable1}
                        <br />
                        {siteLang == "eng" ? languageText.bidThbTable2En : languageText.bidThbTable2}
                      </span>
                      <ReactTooltip
                        id={"bidThbTable"}
                        delayHide={100}
                        place="right"
                        type="warning"
                        effect="solid"
                        clickable={true}
                      >
                        <div className="bg-tooltip">
                          <p className="tooltip-content">
                            <strong>
                              {siteLang == "eng" ? languageText.bidThbTable1En : languageText.bidThbTable1}:
                            </strong>
                            {siteLang == "eng" ? languageText.bidThbTableTooltipEn : languageText.bidThbTableTooltip}
                          </p>
                        </div>
                      </ReactTooltip>
                    </th>
                    <th className="bgcolor-05 width67" style={{ width: "6.1%" }}>
                      <span
                        className="ttip"
                        data-tip
                        data-for={"askThbTable"}
                      >
                        {siteLang == "eng" ? languageText.askThbTable1En : languageText.askThbTable1}
                        <br />
                        {siteLang == "eng" ? languageText.askThbTable2En : languageText.askThbTable2}
                      </span>
                      <ReactTooltip
                        id={"askThbTable"}
                        delayHide={100}
                        place="right"
                        type="warning"
                        effect="solid"
                        clickable={true}
                      >
                        <div className="bg-tooltip">
                          <p className="tooltip-content">
                            <strong>
                              {siteLang == "eng" ? languageText.askThbTable1En : languageText.askThbTable1}:
                            </strong>
                            {siteLang == "eng" ? languageText.askThbTableTooltipEn : languageText.askThbTableTooltip}
                          </p>
                        </div>
                      </ReactTooltip>
                    </th>
                    <th className="bgcolor-05 width55" style={{ width: "5%" }}>
                      <span
                        className="ttip"
                        data-tip
                        data-for={"priceChangeTable"}
                      >
                        {siteLang == "eng" ? languageText.priceChangeTableEn : languageText.priceChangeTable}
                        <br />
                        (%)
                      </span>
                      <ReactTooltip
                        id={"priceChangeTable"}
                        delayHide={100}
                        place="right"
                        type="warning"
                        effect="solid"
                        clickable={true}
                      >
                        <div className="bg-tooltip">
                          <p className="tooltip-content">
                            <strong>
                              {siteLang == "eng" ? languageText.priceChangeTableEn : languageText.priceChangeTable}:
                            </strong>{" "}
                            {siteLang == "eng" ? languageText.priceChangeTableTooltipEn : languageText.priceChangeTableTooltip}
                            <Link to="/eduhtml/howarewarrantspriced" target="_blank">
                              {siteLang == "eng" ? languageText.tooltipClickHereEn : languageText.tooltipClickHere}
                            </Link>
                          </p>
                        </div>
                      </ReactTooltip>
                    </th>
                    <th className="bgcolor-06 width72" style={{ width: "6.6%" }}>
                      <span
                        className="ttip"
                        data-tip
                        data-for={"moneynessTable"}
                      >
                        {siteLang == "eng" ? languageText.moneynessTableEn : languageText.moneynessTable}
                      </span>
                      <ReactTooltip
                        id={"moneynessTable"}
                        delayHide={100}
                        place="right"
                        type="warning"
                        effect="solid"
                        clickable={true}
                      >
                        <div className="bg-tooltip">
                          <p className="tooltip-content">
                            <strong>
                              {siteLang == "eng" ? languageText.moneynessTableEn : languageText.moneynessTable}:
                            </strong>{" "}
                            {siteLang == "eng" ? languageText.moneynessTableTooltip1En : languageText.moneynessTableTooltip1}
                            <Link to="/eduhtml/moneyness" target="_blank">
                              {siteLang == "eng" ? languageText.tooltipClickHereEn : languageText.tooltipClickHere}
                            </Link>
                            {siteLang == "eng" ? languageText.moneynessTableTooltip2En : languageText.moneynessTableTooltip2}
                          </p>
                        </div>
                      </ReactTooltip>
                    </th>
                    <th className="bgcolor-06 width51" style={{ width: "4.6%" }}>
                      <span
                        className="ttip"
                        data-tip
                        data-for={"effgearingTable"}
                      >
                        Eff. gearing
                        <br />
                        (x)
                      </span>
                      <ReactTooltip
                        id={"effgearingTable"}
                        delayHide={100}
                        place="right"
                        type="warning"
                        effect="solid"
                        clickable={true}
                      >
                        <div className="bg-tooltip">
                          <p className="tooltip-content">
                            <strong>{siteLang == "eng" ? languageText.effgearingTableEn : languageText.effgearingTable}:</strong>
                            {siteLang == "eng" ? languageText.effgearingTableTooltip1En : languageText.effgearingTableTooltip1}
                            <Link to="/eduhtml/effectivegearing" target="_blank">
                              {siteLang == "eng" ? languageText.tooltipClickHereEn : languageText.tooltipClickHere}
                            </Link>
                            {siteLang == "eng" ? languageText.effgearingTableTooltip1En : languageText.effgearingTableTooltip1}
                          </p>
                        </div>
                      </ReactTooltip>
                    </th>
                    <th className="bgcolor-06 width67" style={{ width: "6.1%" }}>
                      <span
                        className="ttip"
                        data-tip
                        data-for={"sensitivityTable"}
                      >
                        {siteLang == "eng" ? languageText.sensitivityTableEn : languageText.sensitivityTable}
                      </span>
                      <ReactTooltip
                        id={"sensitivityTable"}
                        delayHide={100}
                        place="right"
                        type="warning"
                        effect="solid"
                        clickable={true}
                      >
                        <div className="bg-tooltip">
                          <p className="tooltip-content">
                            <strong>{siteLang == "eng" ? languageText.sensitivityTableEn : languageText.sensitivityTable}:</strong>
                            {siteLang == "eng" ? languageText.sensitivityTableTooltipEn : languageText.sensitivityTableTooltip}
                          </p>
                        </div>
                      </ReactTooltip>
                    </th>
                    <th className="bgcolor-06 width67" style={{ width: "6.1%" }}>
                      <span
                        className="ttip"
                        data-tip
                        data-for={"sensitivityTable"}
                      >
                        {siteLang == "eng" ? languageText.sensitivityTableEn : languageText.sensitivityTable} 
                        {siteLang == "eng" ? languageText.sensitivityIndexPointTableEn : languageText.sensitivityIndexPointTable}
                      </span>
                    </th>
                    <th className="bgcolor-06 width56" style={{ width: "5.1%" }}>
                      {siteLang == "eng" ? languageText.timeDecayTableEn : languageText.timeDecayTable}
                    </th>
                    <th className="bgcolor-06 width71" style={{ width: "6.5%" }}>
                      <span
                        className="ttip"
                        data-tip
                        data-for={"impliedVolatilityTable"}
                      >
                        {siteLang == "eng" ? languageText.impliedVolatilityTableEn : languageText.impliedVolatilityTable}
                        <br />
                        (%)
                      </span>
                      <ReactTooltip
                        id={"impliedVolatilityTable"}
                        delayHide={100}
                        place="left"
                        type="warning"
                        effect="solid"
                        clickable={true}
                      >
                        <div className="bg-tooltip">
                          <p className="tooltip-content">
                            <strong>{siteLang == "eng" ? languageText.impliedVolatilityTableEn : languageText.impliedVolatilityTable}:</strong>
                            {siteLang == "eng" ? languageText.impliedVolatilityTableTooltip1En : languageText.impliedVolatilityTableTooltip1}
                            <Link to="/eduhtml/impliedvolatility" target="_blank">
                              {siteLang == "eng" ? languageText.tooltipClickHereEn : languageText.tooltipClickHere}
                            </Link>
                            {siteLang == "eng" ? languageText.impliedVolatilityTableTooltip2En : languageText.impliedVolatilityTableTooltip2}
                          </p>
                        </div>
                      </ReactTooltip>
                    </th>
                  </tr>
                  <tr className="result-sort">
                    <th className="col_sticky color_white width133">
                      <FontAwesomeIcon
                        icon={faSortDown}
                        onClick={() => sortTable("desc", "wcode")}
                        className="result-sort-down"
                      />
                      <FontAwesomeIcon
                        icon={faSortUp}
                        onClick={() => sortTable("asc", "wcode")}
                        className="result-sort-up"
                      />
                    </th>
                    <th className="width78"></th>
                    <th className="width67">
                      <FontAwesomeIcon
                        icon={faSortDown}
                        onClick={() => sortTable("desc", "ep")}
                        className="result-sort-down"
                      />
                      <FontAwesomeIcon
                        icon={faSortUp}
                        onClick={() => sortTable("asc", "ep")}
                        className="result-sort-up"
                      />
                    </th>
                    <th className="width67">
                      <FontAwesomeIcon
                        icon={faSortDown}
                        onClick={() => sortTable("desc", "ldate")}
                        className="result-sort-down"
                      />
                      <FontAwesomeIcon
                        icon={faSortUp}
                        onClick={() => sortTable("asc", "ldate")}
                        className="result-sort-up"
                      />
                    </th>
                    <th className="width56">
                      <FontAwesomeIcon
                        icon={faSortDown}
                        onClick={() => sortTable("desc", "isr")}
                        className="result-sort-down"
                      />
                      <FontAwesomeIcon
                        icon={faSortUp}
                        onClick={() => sortTable("asc", "isr")}
                        className="result-sort-up"
                      />
                    </th>
                    <th className="width67">
                      <FontAwesomeIcon
                        icon={faSortDown}
                        onClick={() => sortTable("desc", "tv")}
                        className="result-sort-down"
                      />
                      <FontAwesomeIcon
                        icon={faSortUp}
                        onClick={() => sortTable("asc", "tv")}
                        className="result-sort-up"
                      />
                    </th>
                    <th className="width67">
                      <FontAwesomeIcon
                        icon={faSortDown}
                        onClick={() => sortTable("desc", "bv")}
                        className="result-sort-down"
                      />
                      <FontAwesomeIcon
                        icon={faSortUp}
                        onClick={() => sortTable("asc", "bv")}
                        className="result-sort-up"
                      />
                    </th>
                    <th className="width56">
                      <FontAwesomeIcon
                        icon={faSortDown}
                        onClick={() => sortTable("desc", "bp")}
                        className="result-sort-down"
                      />
                      <FontAwesomeIcon
                        icon={faSortUp}
                        onClick={() => sortTable("asc", "bp")}
                        className="result-sort-up"
                      />
                    </th>
                    <th className="width67">
                      <FontAwesomeIcon
                        icon={faSortDown}
                        onClick={() => sortTable("desc", "ap")}
                        className="result-sort-down"
                      />
                      <FontAwesomeIcon
                        icon={faSortUp}
                        onClick={() => sortTable("asc", "ap")}
                        className="result-sort-up"
                      />
                    </th>
                    <th className="width55">
                      <FontAwesomeIcon
                        icon={faSortDown}
                        onClick={() => sortTable("desc", "pc")}
                        className="result-sort-down"
                      />
                      <FontAwesomeIcon
                        icon={faSortUp}
                        onClick={() => sortTable("asc", "pc")}
                        className="result-sort-up"
                      />
                    </th>
                    <th className="width72">
                      <FontAwesomeIcon
                        icon={faSortDown}
                        onClick={() => sortTable("desc", "mn")}
                        className="result-sort-down"
                      />
                      <FontAwesomeIcon
                        icon={faSortUp}
                        onClick={() => sortTable("asc", "mn")}
                        className="result-sort-up"
                      />
                    </th>
                    <th className="width51">
                      <FontAwesomeIcon
                        icon={faSortDown}
                        onClick={() => sortTable("desc", "eg")}
                        className="result-sort-down"
                      />
                      <FontAwesomeIcon
                        icon={faSortUp}
                        onClick={() => sortTable("asc", "eg")}
                        className="result-sort-up"
                      />
                    </th>
                    <th className="width67">
                      <FontAwesomeIcon
                        icon={faSortDown}
                        onClick={() => sortTable("desc", "dlt")}
                        className="result-sort-down"
                      />
                      <FontAwesomeIcon
                        icon={faSortUp}
                        onClick={() => sortTable("asc", "dlt")}
                        className="result-sort-up"
                      />
                    </th>
                    <th className="width67">
                    <FontAwesomeIcon
                        icon={faSortDown}
                        onClick={() => sortSbip("desc", "sbip")}
                        className="result-sort-down"
                      />
                      <FontAwesomeIcon
                        icon={faSortUp}
                        onClick={() => sortSbip("asc", "sbip")}
                        className="result-sort-up"
                      />
                    </th>
                    <th className="width56">
                      <FontAwesomeIcon
                        icon={faSortDown}
                        onClick={() => sortTable("desc", "td")}
                        className="result-sort-down"
                      />
                      <FontAwesomeIcon
                        icon={faSortUp}
                        onClick={() => sortTable("asc", "td")}
                        className="result-sort-up"
                      />
                    </th>
                    <th className="width71">
                      <FontAwesomeIcon
                        icon={faSortDown}
                        onClick={() => sortTable("desc", "iv")}
                        className="result-sort-down"
                      />
                      <FontAwesomeIcon
                        icon={faSortUp}
                        onClick={() => sortTable("asc", "iv")}
                        className="result-sort-up"
                      />
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {warrantSearchData.length > 0 && warrantSearchData.map((warrantData, idx) => (
                      <tr key={idx} className={warrantData.issuer == "MACQ" ? "rowtmpl-lg text-bold" : warrantData.issuer == "Macquarie" ? "rowtmpl-lg text-bold" : "rowtmpl-lg"} idx={idx}>

                        <td className="col-dwSymbol width133">
                          <Link to={`/tools/livematrix/${warrantData.dwSymbol}`} target='_black'>
                            {warrantData && warrantData.dwSymbol}
                          </Link>
                        </td>
                        <td className="col-indicator width78">
                          {warrantData && warrantData.HOTLIST === true && (
                            <span className="indicator-item-icon icon-hotdw ttip" />
                          )}
                          {warrantData && warrantData.APPCHEXPRY === true && (
                            <span className="indicator-item-icon icon-expiry ttip" />
                          )}
                          {warrantData && warrantData.SOLDOUT === true && (
                            <span className="indicator-item-icon icon-soldout ttip" />
                          )}
                          {warrantData && warrantData.HIGHLQDY === true && (
                            <span className="indicator-item-icon icon-liquidity ttip" />
                          )}
                          {warrantData && warrantData.Caution === true && (
                            <span className="indicator-item-icon icon-caution ttip" />
                          )}
                          {warrantData && warrantData.Low_time_decay === true && (
                            <span className="indicator-item-icon icon-time ttip" />
                          )}
                          {warrantData &&  warrantData.Responsive === true && (
                            <span className="indicator-item-icon icon-responsive ttip" />
                          )}
                        </td>
                        <td className="col-exercisePrice width67">
                          {warrantData.exercisePrice}
                        </td>
                        <td className="col-ltDate width67">
                          {warrantData && warrantData.ltDate}
                        </td>
                        <td className="col-issuer bordered width56">
                          {warrantData.issuer == "Macquarie" ? "MACQ" : warrantData.issuer}
                        </td>
                        <td className="col-tradeVolume width67">
                          {(warrantData && warrantData.tradeVolume_f) || "-"}
                        </td>
                        <td className="col-bidVolume width67">
                          {(warrantData && warrantData.bidVolume_f) || "-"}
                        </td>
                        <td className="col-bidPrice width56">
                          {(warrantData && warrantData.bidPrice_f) || "-"}
                        </td>
                        <td className="col-askPrice width67">
                          {(warrantData && warrantData.askPrice_f) || "-"}
                        </td>
                        <td className={`col-priceChange val bordered width55 ${warrantData && Number(warrantData.priceChange) > 0 ? "up" : warrantData && Number(warrantData.priceChange) < 0 ? "down" : ""}`}>
                          {warrantData.priceChange
                            ? Number(warrantData.priceChange).toFixed(1) > 0
                              ? "+" + Number(warrantData.priceChange).toFixed(1)
                              : Number(warrantData.priceChange).toFixed(1)
                            : "-"}
                        </td>
                        <td
                          className={
                            warrantData &&
                            warrantData.moneyness_c
                            ?.split(" ").includes("OTM")
                              ? "col-moneyness_c val width72 down"
                              : warrantData.moneyness_c
                                  ?.split(" ")
                                  .includes("ITM")
                              ? "col-moneyness_c val width72 up"
                              : "col-moneyness_c val width72"
                          }
                        >
                          {(warrantData && warrantData.moneyness_c) || "-"}
                        </td>
                        <td className="col-effectiveGearing width51">
                          {warrantData.effectiveGearing !== "-"
                            ? Number(warrantData.effectiveGearing).toFixed(1)
                            : "-"}
                        </td>

                        <td className="col-sensitivity width67">
                          {Number(warrantData.sensitivity).toFixed(2)}
                        </td>
                        {/* {(warrantData && warrantData.underlyingSymbol == "SET50" || warrantData.underlyingSymbol == "HSI" || warrantData.underlyingSymbol == "HSTECH" ||
                         warrantData.underlyingSymbol == "HSCEI" || warrantData.underlyingSymbol == "S&P500" || warrantData.underlyingSymbol == "NDX" || warrantData.underlyingSymbol == "DJI") ?
                         <td className="col-sensitivityByIndex width67">
                            {((warrantData.sensitivity != "-" || sen != "N/A") && Number(warrantData.sensitivity) != 0) ? (1.0 / Number(warrantData.sensitivity_cal)).toFixed(2) : "-"}
                         </td>
                         :
                         <td className="col-sensitivityByIndex width67">
                          {"-"}
                         </td>
                        } */}
                        <td className="col-sensitivityByIndex width67">
                          {warrantData.sbip}
                         </td>
                        <td className="col-timedecayday width56">
                          {Number(warrantData.time_decay).toFixed(1)}
                        </td>
                        <td className="col-impliedVolalitiy width71">
                          {impliedVolalitiyData(warrantData)}
                        </td>
                      </tr>
                    ))
                  }
                  {!isLoading && warrantSearchData.length <= 0 && <tr className="emptymsg">
                    <td colSpan="18">No data</td>
                  </tr>}
                </tbody>
              </table>
              {isLoading && (
                <div className="loader-div">
                  <LoaderSpin isLoading={isLoading} isVisible />
                </div>
              )}
              <div className="result-disclaimer hidden-xs hidden-sm">
                <ol>
                  <li>
                    <p className="small">
                      {siteLang == "eng" ? languageText.priceChange1En : languageText.priceChange1}
                      <Link to="/eduhtml" target="_blank" className="text-lowercase">
                        {siteLang == "eng" ? languageText.tooltipClickHereEn : languageText.tooltipClickHere}
                      </Link>
                    </p>
                  </li>
                  <li>
                    <p className="small">
                      {siteLang == "eng" ? languageText.priceChange2En : languageText.priceChange2}
                    </p>
                  </li>
                </ol>
                <hr />
                <p className="small">
                  {siteLang == "eng" ? languageText.marketDelayEn : languageText.marketDelay}
                </p>
              </div>
            </div>
            <div className="indicatortbl">
              <table className="visible-sm">
                <tbody>
                  <tr>
                    <td>
                      <div className="indicator-item small">
                        <span className="icon-hotdw indicator-item-icon" /> Fast track
                      </div>
                    </td>
                    <td>
                      <div className="indicator-item small">
                        <span className="icon-expiry indicator-item-icon" /> {siteLang == "eng" ? "Near expiry" : "ใกล้หมดอายุ"}
                      </div>
                    </td>
                    <td>
                      <div className="indicator-item small">
                        <span className="icon-caution indicator-item-icon" /> Caution
                      </div>
                    </td>
                    <td>
                      <div className="indicator-item small">
                        <span className="icon-soldout indicator-item-icon" /> Inventory sold-out
                      </div>
                    </td>
                    <td>
                      <div className="indicator-item small">
                        <span className="icon-time indicator-item-icon" /> Low time decay
                      </div>
                    </td>
                    <td>
                      <div className="indicator-item small">
                        <span className="icon-responsive indicator-item-icon" /> Responsive
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table> 
              <table className="indicatortbl visible-xs">
                <tbody>
                  <tr>
                    <td>
                      <div className="indicator-item small">
                        <span className="icon-hotdw indicator-item-icon" /> Fast track
                      </div>
                    </td>
                    <td>
                      <div className="indicator-item small">
                        <span className="icon-expiry indicator-item-icon" /> {siteLang == "eng" ? "Near expiry" : "ใกล้หมดอายุ"}
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="indicator-item small">
                        <span className="icon-caution indicator-item-icon" /> Caution
                      </div>
                    </td>
                    <td>
                      <div className="indicator-item small">
                        <span className="icon-soldout indicator-item-icon" /> Inventory sold-out
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <div className="indicator-item small">
                        <span className="icon-time indicator-item-icon" /> Low time decay
                      </div>
                    </td>             
                    <td>
                      <div className="indicator-item small">
                        <span className="icon-responsive indicator-item-icon" /> Responsive
                      </div>
                    </td>                            
                  </tr>
                </tbody>
              </table>

            </div>
            <div className="panel-group visible-xs-block visible-sm-block" id="defination">
              <div className="panel panel-default">
                <div className="panel-heading" onClick={disclaimerDefinationFun}>
                  <button>
                    <span className="pull-right">
                      <img src="/img/bg/questionMark.png" alt="questionMark" />
                    </span>
                    <div id="collapse_title3" className="panel-title text-center color-primary">
                      {siteLang == "eng" ? languageText.definitionDisclaimerEn : languageText.definitionDisclaimer}
                    </div>
                  </button>
                </div>
                <div id="collapse_dwcompare3" className={`panel-collapse collapse ${disclaimerDefination}`}>
                  <table className="table table-striped">
                    <tbody>
                      <tr>
                        <td>{siteLang == "eng" ? languageText.dwSymbolTableEn : languageText.dwSymbolTable}</td>
                        <td>
                          {siteLang == "eng" ? languageText.dwSymbolTableTooltip1En : languageText.dwSymbolTableTooltip1} 
                          <Link target="_blank" to="/eduhtml/howarewarrantsnamed" className="text-lowercase">
                            {siteLang == "eng" ? languageText.tooltipClickHereEn : languageText.tooltipClickHere}  
                          </Link> 
                          {siteLang == "eng" ? languageText.dwSymbolTableTooltip2En : languageText.dwSymbolTableTooltip2}
                        </td>
                      </tr>
                      <tr>
                        <td>{siteLang == "eng" ? languageText.typeTableEn : languageText.typeTable}</td>
                        <td>{siteLang == "eng" ? languageText.typeTableTooltipEn : languageText.typeTableTooltip}</td>
                      </tr>
                      <tr>
                        <td>{siteLang == "eng" ? languageText.exercisepriceTableEn : languageText.exercisepriceTable}</td>
                        <td>{siteLang == "eng" ? languageText.exercisepriceTableTooltipEn : languageText.exercisepriceTableTooltip}</td>
                      </tr>
                      <tr>
                        <td>{siteLang == "eng" ? languageText.ratioTableEn : languageText.ratioTable}</td>
                        <td>{siteLang == "eng" ? languageText.ratioTableTooltipEn : languageText.ratioTableTooltip}</td>
                      </tr>
                      <tr>
                        <td>{siteLang == "eng" ? languageText.lastTradingDateTableEn : languageText.lastTradingDateTable}</td>
                        <td>{siteLang == "eng" ? languageText.lastTradingDateTableTooltipEn : languageText.lastTradingDateTableTooltip}</td>
                      </tr>
                      <tr>
                        <td>{siteLang == "eng" ? languageText.issuerTableEn : languageText.issuerTable}</td>
                        <td>{siteLang == "eng" ? languageText.issuerTableTooltipEn : languageText.issuerTableTooltip}</td>
                      </tr>
                      <tr>
                        <td>{siteLang == "eng" ? languageText.tradedVolumeTableEn : languageText.tradedVolumeTable}</td>
                        <td>
                          {siteLang == "eng" ? languageText.tradedVolumeTableTooltip1En : languageText.tradedVolumeTableTooltip1}
                          <Link target="_blank" to="/eduhtml/marketmakers" className="text-lowercase">
                            {siteLang == "eng" ? languageText.tooltipClickHereEn : languageText.tooltipClickHere}
                          </Link> 
                          {siteLang == "eng" ? languageText.tradedVolumeTableTooltip2En : languageText.tradedVolumeTableTooltip2}
                        </td>
                      </tr>
                      <tr>
                        <td>{siteLang == "eng" ? languageText.bidVolumeTableEn : languageText.bidVolumeTable}</td>
                        <td>{siteLang == "eng" ? languageText.bidVolumeTableTooltipEn : languageText.bidVolumeTableTooltip}</td>
                      </tr>
                      <tr>
                        <td>{siteLang == "eng" ? languageText.bidThbTable1En : languageText.bidThbTable1}</td>
                        <td>{siteLang == "eng" ? languageText.bidThbTableTooltipEn : languageText.bidThbTableTooltip}</td>
                      </tr>
                      <tr>
                        <td>{siteLang == "eng" ? languageText.askVolumeTableEn : languageText.askVolumeTable}</td>
                        <td>{siteLang == "eng" ? languageText.askVolumeTableTooltipEn : languageText.askVolumeTableTooltip}</td>
                      </tr>
                      <tr>
                        <td>{siteLang == "eng" ? languageText.askThbTable1En : languageText.askThbTable1}</td>
                        <td>{siteLang == "eng" ? languageText.askThbTableTooltipEn : languageText.askThbTableTooltip}</td>
                      </tr>
                      <tr>
                        <td>{siteLang == "eng" ? languageText.priceChangeTableEn : languageText.priceChangeTable}</td>
                        <td>
                          {siteLang == "eng" ? languageText.priceChangeTableTooltipEn : languageText.priceChangeTableTooltip}
                          <Link target="_blank" to="/eduhtml/howarewarrantspriced" className="text-lowercase">
                            {siteLang == "eng" ? languageText.tooltipClickHereEn : languageText.tooltipClickHere}
                          </Link>
                        </td>
                      </tr>
                      <tr>
                        <td>{siteLang == "eng" ? languageText.moneynessTableEn : languageText.moneynessTable}</td>
                        <td>
                          {siteLang == "eng" ? languageText.moneynessTableTooltip1En : languageText.moneynessTableTooltip1} 
                          <Link target="_blank" to="/eduhtml/moneyness" className="text-lowercase">
                            {siteLang == "eng" ? languageText.tooltipClickHereEn : languageText.tooltipClickHere}
                          </Link>
                          {siteLang == "eng" ? languageText.moneynessTableTooltip2En : languageText.moneynessTableTooltip2}
                        </td>
                      </tr>
                      <tr>
                        <td>{siteLang == "eng" ? languageText.effgearingTableEn : languageText.effgearingTable}</td>
                        <td>
                          {siteLang == "eng" ? languageText.effgearingTableTooltip1En : languageText.effgearingTableTooltip1}
                          <Link target="_blank" to="/eduhtml/effectivegearing" className="text-lowercase">
                            {siteLang == "eng" ? languageText.tooltipClickHereEn : languageText.tooltipClickHere}
                          </Link> 
                          {siteLang == "eng" ? languageText.effgearingTableTooltip2En : languageText.effgearingTableTooltip2}
                        </td>
                      </tr>
                      <tr>
                        <td>{siteLang == "eng" ? languageText.sensitivityTableEn : languageText.sensitivityTable}</td>
                        <td>{siteLang == "eng" ? languageText.sensitivityTableTooltipEn : languageText.sensitivityTableTooltip}
                            <br />
                            {siteLang == "eng" ? languageText.sensitivityTableTooltip2En : languageText.sensitivityTableTooltip2}
                        </td>
                      </tr>
                      <tr>
                        <td>{siteLang == "eng" ? languageText.impliedVolatilityTableEn : languageText.impliedVolatilityTable}</td>
                        <td>
                          {siteLang == "eng" ? languageText.impliedVolatilityTableTooltip1En : languageText.impliedVolatilityTableTooltip1}
                          <Link target="_blank" to="/eduhtml/impliedvolatility" className="text-lowercase">
                            {siteLang == "eng" ? languageText.tooltipClickHereEn : languageText.tooltipClickHere}
                          </Link> 
                          {siteLang == "eng" ? languageText.impliedVolatilityTableTooltip2En : languageText.impliedVolatilityTableTooltip2}
                        </td>
                      </tr>
                    </tbody>
                  </table>
                  <ol>
                    <li>
                      <p className="small">
                        {siteLang == "eng" ? languageText.priceChange1En : languageText.priceChange1} 
                        <Link className="text-lowercase" target="_blank" to="/eduhtml">
                          {siteLang == "eng" ? languageText.tooltipClickHereEn : languageText.tooltipClickHere}
                        </Link>.
                      </p>
                    </li>
                    <li>
                      <p className="small">
                        {siteLang == "eng" ? languageText.priceChange2En : languageText.priceChange2}
                      </p>
                    </li>
                  </ol>
                  <hr />
                  <p className="small">
                    {siteLang == "eng" ? languageText.marketDelayEn : languageText.marketDelay}
                  </p> 
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="stickerheader" style={{ display: headerColor }}>
          <div className="container-fluid">
            <div className="section" style={{ paddingTop: 0 }}>
              <div id="stickerresult" className="result" style={{overflowX: "auto",}} ref={scrollableElementRef2}>
                
                <table id="resulttable-lg">
                  
                  <thead>
                    <tr className="result-hdr1">
                      <th className="bgcolor-04" colSpan={5} style={{ minWidth: "399px"}}>
                        GENERAL
                      </th>
                      <th className="bgcolor-05" colSpan={5} style={{ minWidth: "310px"}}>
                        MARKET
                      </th>
                      <th className="bgcolor-06" colSpan={6} style={{ minWidth: "382px"}}>
                        DW
                      </th>
                    </tr>
                    <tr className="result-hdr2">
                      <th className="bgcolor-04 col_sticky width133">
                        <span className="ttip">
                          {siteLang == "eng" ? languageText.dwSymbolTableEn : languageText.dwSymbolTable}
                        </span>
                      </th>
                      <th className="bgcolor-04 width78" style={{ width: "7.15%" }}>
                        {siteLang == "eng" ? languageText.indicatorTableEn : languageText.indicatorTable}
                      </th>
                      <th className="bgcolor-04 width67" style={{ width: "6.1%" }}>
                        <span className="ttip">
                          {siteLang == "eng" ? languageText.exercisepriceTableEn : languageText.exercisepriceTable}
                        </span>
                      </th>
                      <th className="bgcolor-04 width67" style={{ width: "6.1%" }}>
                        <span className="ttip">
                          {siteLang == "eng" ? languageText.lastTradingDateTableEn : languageText.lastTradingDateTable}
                        </span>
                      </th>
                      <th className="bgcolor-04 width56" style={{ width: "5.1%" }}>
                        <span className="ttip">
                          {siteLang == "eng" ? languageText.issuerTableEn : languageText.issuerTable}
                        </span>
                      </th>
                      <th className="bgcolor-05 width67" style={{ width: "6.1%" }}>
                        <span className="ttip">
                          {siteLang == "eng" ? languageText.tradedVolumeTableEn : languageText.tradedVolumeTable}
                          <br />
                          ('000)
                        </span>
                      </th>
                      <th className="bgcolor-05 width67" style={{ width: "6.1%" }}>
                        <span className="ttip">
                          {siteLang == "eng" ? languageText.bidVolumeTableEn : languageText.bidVolumeTable}
                          <br />
                          ('000)
                        </span>
                      </th>
                      <th className="bgcolor-05 width56" style={{ width: "5.1%" }}>
                        <span className="ttip">
                          {siteLang == "eng" ? languageText.bidThbTable1En : languageText.bidThbTable1}
                          <br />
                          {siteLang == "eng" ? languageText.bidThbTable2En : languageText.bidThbTable2}
                        </span>
                      </th>
                      <th className="bgcolor-05 width67" style={{ width: "6.1%" }}>
                        <span className="ttip">
                          {siteLang == "eng" ? languageText.askThbTable1En : languageText.askThbTable1}
                          <br />
                          {siteLang == "eng" ? languageText.askThbTable2En : languageText.askThbTable2}
                        </span>
                      </th>
                      <th className="bgcolor-05 width55" style={{ width: "5%" }}>
                        <span className="ttip">
                          {siteLang == "eng" ? languageText.priceChangeTableEn : languageText.priceChangeTable}
                          <br />
                          (%)
                        </span>
                      </th>
                      <th className="bgcolor-06 width72" style={{ width: "6.6%" }}>
                        <span className="ttip">
                          {siteLang == "eng" ? languageText.moneynessTableEn : languageText.moneynessTable}
                        </span>
                      </th>
                      <th className="bgcolor-06 width51" style={{ width: "4.6%" }}>
                        <span className="ttip">
                          Eff. gearing
                          <br />
                          (x)
                        </span>
                      </th>
                      <th className="bgcolor-06 width67" style={{ width: "6.1%" }}>
                        <span className="ttip">
                          {siteLang == "eng" ? languageText.sensitivityTableEn : languageText.sensitivityTable}
                        </span>
                      </th>
                      <th className="bgcolor-06 width67" style={{ width: "6.1%" }}>
                        <span className="ttip">
                          {siteLang == "eng" ? languageText.sensitivityTableEn : languageText.sensitivityTable} 
                          {siteLang == "eng" ? languageText.sensitivityIndexPointTableEn : languageText.sensitivityIndexPointTable}
                        </span>
                      </th>
                      <th className="bgcolor-06 width56" style={{ width: "5.1%" }}>
                        {siteLang == "eng" ? languageText.timeDecayTableEn : languageText.timeDecayTable}
                      </th>
                      <th className="bgcolor-06 width71" style={{ width: "6.5%" }}>
                        <span className="ttip">
                          {siteLang == "eng" ? languageText.impliedVolatilityTableEn : languageText.impliedVolatilityTable}
                          <br />
                          (%)
                        </span>
                      </th>
                    </tr>
                    <tr className="result-sort">
                      <th className="col_sticky color_white width133">
                        <FontAwesomeIcon
                          icon={faSortDown}
                          onClick={() => sortTable("desc", "wcode")}
                          className="result-sort-down"
                        />
                        <FontAwesomeIcon
                          icon={faSortUp}
                          onClick={() => sortTable("asc", "wcode")}
                          className="result-sort-up"
                        />
                      </th>
                      <th className="width78"></th>
                      <th className="width67">
                        <FontAwesomeIcon
                          icon={faSortDown}
                          onClick={() => sortTable("desc", "ep")}
                          className="result-sort-down"
                        />
                        <FontAwesomeIcon
                          icon={faSortUp}
                          onClick={() => sortTable("asc", "ep")}
                          className="result-sort-up"
                        />
                      </th>
                      <th className="width67">
                        <FontAwesomeIcon
                          icon={faSortDown}
                          onClick={() => sortTable("desc", "ldate")}
                          className="result-sort-down"
                        />
                        <FontAwesomeIcon
                          icon={faSortUp}
                          onClick={() => sortTable("asc", "ldate")}
                          className="result-sort-up"
                        />
                      </th>
                      <th className="width56">
                        <FontAwesomeIcon
                          icon={faSortDown}
                          onClick={() => sortTable("desc", "isr")}
                          className="result-sort-down"
                        />
                        <FontAwesomeIcon
                          icon={faSortUp}
                          onClick={() => sortTable("asc", "isr")}
                          className="result-sort-up"
                        />
                      </th>
                      <th className="width67">
                        <FontAwesomeIcon
                          icon={faSortDown}
                          onClick={() => sortTable("desc", "tv")}
                          className="result-sort-down"
                        />
                        <FontAwesomeIcon
                          icon={faSortUp}
                          onClick={() => sortTable("asc", "tv")}
                          className="result-sort-up"
                        />
                      </th>
                      <th className="width67">
                        <FontAwesomeIcon
                          icon={faSortDown}
                          onClick={() => sortTable("desc", "bv")}
                          className="result-sort-down"
                        />
                        <FontAwesomeIcon
                          icon={faSortUp}
                          onClick={() => sortTable("asc", "bv")}
                          className="result-sort-up"
                        />
                      </th>
                      <th className="width56">
                        <FontAwesomeIcon
                          icon={faSortDown}
                          onClick={() => sortTable("desc", "bp")}
                          className="result-sort-down"
                        />
                        <FontAwesomeIcon
                          icon={faSortUp}
                          onClick={() => sortTable("asc", "bp")}
                          className="result-sort-up"
                        />
                      </th>
                      <th className="width67">
                        <FontAwesomeIcon
                          icon={faSortDown}
                          onClick={() => sortTable("desc", "ap")}
                          className="result-sort-down"
                        />
                        <FontAwesomeIcon
                          icon={faSortUp}
                          onClick={() => sortTable("asc", "ap")}
                          className="result-sort-up"
                        />
                      </th>
                      <th className="width55">
                        <FontAwesomeIcon
                          icon={faSortDown}
                          onClick={() => sortTable("desc", "pc")}
                          className="result-sort-down"
                        />
                        <FontAwesomeIcon
                          icon={faSortUp}
                          onClick={() => sortTable("asc", "pc")}
                          className="result-sort-up"
                        />
                      </th>
                      <th className="width72">
                        <FontAwesomeIcon
                          icon={faSortDown}
                          onClick={() => sortTable("desc", "mn")}
                          className="result-sort-down"
                        />
                        <FontAwesomeIcon
                          icon={faSortUp}
                          onClick={() => sortTable("asc", "mn")}
                          className="result-sort-up"
                        />
                      </th>
                      <th className="width51">
                        <FontAwesomeIcon
                          icon={faSortDown}
                          onClick={() => sortTable("desc", "eg")}
                          className="result-sort-down"
                        />
                        <FontAwesomeIcon
                          icon={faSortUp}
                          onClick={() => sortTable("asc", "eg")}
                          className="result-sort-up"
                        />
                      </th>
                      <th className="width67">
                        <FontAwesomeIcon
                          icon={faSortDown}
                          onClick={() => sortTable("desc", "dlt")}
                          className="result-sort-down"
                        />
                        <FontAwesomeIcon
                          icon={faSortUp}
                          onClick={() => sortTable("asc", "dlt")}
                          className="result-sort-up"
                        />
                      </th>
                      <th className="width67">
                      <FontAwesomeIcon
                          icon={faSortDown}
                          onClick={() => sortSbip("desc", "sbip")}
                          className="result-sort-down"
                        />
                        <FontAwesomeIcon
                          icon={faSortUp}
                          onClick={() => sortSbip("asc", "sbip")}
                          className="result-sort-up"
                        />
                      </th>
                      <th className="width56">
                        <FontAwesomeIcon
                          icon={faSortDown}
                          onClick={() => sortTable("desc", "td")}
                          className="result-sort-down"
                        />
                        <FontAwesomeIcon
                          icon={faSortUp}
                          onClick={() => sortTable("asc", "td")}
                          className="result-sort-up"
                        />
                      </th>
                      <th className="width71">
                        <FontAwesomeIcon
                          icon={faSortDown}
                          onClick={() => sortTable("desc", "iv")}
                          className="result-sort-down"
                        />
                        <FontAwesomeIcon
                          icon={faSortUp}
                          onClick={() => sortTable("asc", "iv")}
                          className="result-sort-up"
                        />
                      </th>
                    </tr>
                  </thead>
                </table>
                {isLoading && (
                  <div className="loader-div">
                    <LoaderSpin isLoading={isLoading} isVisible />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default WarrantSearch;
