import React, { useState, useEffect } from "react";
import API from "../../../utils/apiCalling";
import { config } from "../../../utils/apiUrl";
import { Link } from "react-router-dom";
import LangContext from "../../../components/common/context/lang-context";
import { useContext } from "react";
import { languageText } from "../../../languageText/home";

const WarrantEducation = () => {

  const langContext = useContext(LangContext);
  const api = new API();
  const [seminarData, setSeminarData] = useState([])
  const [faqData, setFaqData] = useState([])
  const [dwTips, setDwTips] = useState([])
  const [tipsImg, setTipsImg] = useState([])
  const [siteLang , setSiteLang] = useState("")

  useEffect(() => {
    var siteLanguage = $.cookie('applang');
    setSiteLang(siteLanguage)
    if(siteLanguage == "eng"){
      document.querySelector("body").classList.add("eng")
    }else{
      document.querySelector("body").classList.remove("eng")
    }
    getHomeEduJson()
  }, []);

  const getHomeEduJson = async () => {
    let homeJsonResult = await api.get(
      config.homeJSON,
      true,
      { m: "dwedu", qid: Date.now() }
    );
    setSeminarData(homeJsonResult.seminar)
    setFaqData(homeJsonResult.faq)
    setDwTips(homeJsonResult.dwTips)
    var tipsImgData = homeJsonResult.tipsImg
    if(tipsImgData?.length > 0){
      getTipsImgData(tipsImgData)
    }
  };

  const getTipsImgData = async (tipsImgData) => {
    var items = []
    let advImgDataVal = await api.get(config.advImgData, true, {
      tipsbanner: tipsImgData[0].img_id,
    });
    items.push({
      img_id: advImgDataVal,
      img_link: tipsImgData[0].img_link,
    });
    setTipsImg(items);
  }

    return ( 
      <div>
        <div className="tab-container container-fluid">
          <div id="dwedu" className="tabcontent">
            <div className="frame-lgmd">
              <div className="row">
                <div className="col-md-6 col-sm-6">
                  <div className="holder-qlink-dwvideo"><div id="qlink-dwvideo" className="section">
                      <div className="page-header">
                        <h1 className="small">{siteLang == "eng" ? languageText.dwVideoTitleEn : languageText.dwVideoTitle}</h1>
                      </div>
                      <p>
                        {siteLang == "eng" ? languageText.dwVideoParaEn : languageText.dwVideoPara}
                      </p>
                      <div className="videoplayer">
                        <div className="videoplayer-container">
                          <iframe frameBorder={0} allowFullScreen src="https://www.youtube.com/embed/videoseries?list=PLhgNKvNQ3OFKSv3Aoedsx_Pcmkl5Mhw7D&autoplay=0&index=0&rel=0&wmode=opaque" data-gtm-yt-inspected-6="true" data-gtm-yt-inspected-16="true" />
                        </div>
                      </div>
                      <div className="section-btn-wapper">
                        <Link to="/education/dwvideo" type="button" className="btn btn-primary">
                          {siteLang == "eng" ? languageText.eduViewBtnEn : languageText.eduViewBtn}
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="holder-seminar">
                    <div id="seminar" className="section">
                      <div className="page-header">
                        <h1 className="small">{siteLang == "eng" ? languageText.seminarTitleEn : languageText.seminarTitle}</h1>
                      </div>
                      <p>{siteLang == "eng" ? languageText.seminarParaEn : languageText.seminarPara}</p>
                      <div className="seminar-table-holder" style={{overflowX: 'hidden', overflowY: 'auto'}}>
                        <table className="table table-striped" style={{width: '100%'}}>
                          <thead>
                            <tr>
                              <th>{siteLang == "eng" ? languageText.seminarTableDateTitleEn : languageText.seminarTableDateTitle}</th>
                              <th>{siteLang == "eng" ? languageText.seminarTableTimeTitleEn : languageText.seminarTableTimeTitle}</th>
                              <th>{siteLang == "eng" ? languageText.seminarTableTitleTitleEn : languageText.seminarTableTitleTitle}</th>
                            </tr>
                          </thead>
                          <tbody>
                            {seminarData && seminarData.map((data, index) => (
                              <tr className="seminartmpl" key={index}>
                                <td className="date">{data.date}</td>
                                <td className="time">{siteLang == "eng" ? data.en_time : data.th_time}</td>
                                <td className="title">
                                  <Link to={`/education/seminar?seminar=${data.id}`}>{siteLang == "eng" ? data.en_title : data.th_title}</Link>
                                </td>
                              </tr>
                            ))}
                            {seminarData?.length <= 0 && <tr className="seminartmpl">
                              <td className="date"></td>
                              <td className="time"></td>
                              <td className="title">
                                <Link to=""></Link>
                              </td>
                            </tr>}
                          </tbody>                       
                        </table>
                      </div>
                      <div className="section-btn-wapper">
                        <Link to="/education/seminar" type="button" className="btn btn-primary">
                          {siteLang == "eng" ? languageText.eduViewBtnEn : languageText.eduViewBtn}
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="holder-qlink-investoracademy"><div id="qlink-investoracademy" className="section">
                      <div className="page-header">
                        <h1 className="small">{siteLang == "eng" ? languageText.investorTitleEn : languageText.investorTitle}</h1>
                      </div>
                      <p>
                        {siteLang == "eng" ? languageText.investorParaEn : languageText.investorPara}
                      </p>
                      <div className="section-qlink section-qlink-investoracademy ">
                        <div className="section-qlink-achor hidden-xs">
                          <Link target="_blank" to="/eduhtml" style={{minWidth: '190px'}} type="button" className="btn btn-primary">
                            {siteLang == "eng" ? languageText.eduViewBtnEn : languageText.eduViewBtn}
                          </Link>
                        </div>
                      </div>
                      <div className="section-btn-wapper visible-xs">
                        <Link target="_blank" to="/eduhtml" style={{minWidth: '190px'}} type="button" className="btn btn-primary">
                          {siteLang == "eng" ? languageText.eduViewBtnEn : languageText.eduViewBtn}
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div id="video-dwedu-lgmd" className="holder-advertisment holder-empty" />
                </div>
                <div className="col-md-6 col-sm-6">
                  <div className="holder-edu-dwtips">
                    <div id="edu-dwtips" className="section">                         
                      <div className="page-header">
                        <h1 className="small">{siteLang == "eng" ? languageText.dwtipsTitleEn : languageText.dwtipsTitle}</h1>
                      </div>
                      <p>
                        {siteLang == "eng" ? languageText.dwtipsParaEn : languageText.dwtipsPara}
                      </p>
                      <table style={{width: '100%'}}>
                        <tbody>
                          {tipsImg.length > 0 && <tr>
                            <td className="leftTipsImg">
                              <Link to={{pathname: tipsImg && tipsImg[0] && tipsImg[0].img_link}} target="_top">
                                <img src={tipsImg && tipsImg[0] && tipsImg[0].img_id} alt="DW Tips" />
                              </Link>
                            </td>
                          </tr>}
                          <tr>
                            <td className="tipscontent">
                              <div className="newstbl-content">
                                {dwTips && dwTips.map((data, index) => (
                                  <div className="newstbl-row rowtmpl" key={index}>
                                    <div className="newstbl-col-right">
                                      <Link to={`/education/individualtip?id=${data.id}`} className="newstbl-col-dt" target="_blank" 
                                            dangerouslySetInnerHTML={{__html: siteLang == "eng" ? data.en_title : data.thai_title}}></Link>
                                      <Link to={`/education/individualtip?id=${data.id}`} target="_blank" className="newstbl-col-rm">
                                        <img src="/img/bullet/qlink-achor.png" alt="Arrow Icon" />
                                      </Link>
                                    </div>
                                  </div> 
                                ))}
                              </div>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                      <div className="section-btn-wapper">
                        <Link to="/education/dwtips" style={{minWidth: '190px'}} type="button" className="btn btn-primary">
                          {siteLang == "eng" ? languageText.eduViewBtnEn : languageText.eduViewBtn}
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="holder-qlink-dwindex"><div id="qlink-dwindex" className="section">
                      <div className="page-header">
                        <h1 className="small">{siteLang == "eng" ? languageText.indexDwTitleEn : languageText.indexDwTitle}</h1>
                      </div>
                      <p>
                        {siteLang == "eng" ? languageText.indexDwParaEn : languageText.indexDwPara}
                      </p>
                      <div className="section-qlink section-qlink-indexdw ">
                        <div className="section-qlink-achor hidden-xs">
                          <Link to="/education/indexdw" type="button" className="btn btn-primary">
                            {siteLang == "eng" ? languageText.eduViewBtnEn : languageText.eduViewBtn}
                          </Link>
                        </div>
                      </div>
                      <div className="section-btn-wapper visible-xs">
                        <Link to="/education/indexdw" type="button" className="btn btn-primary">
                          {siteLang == "eng" ? languageText.eduViewBtnEn : languageText.eduViewBtn}
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="holder-qlink-faq">
                    <div id="qlink-faq" className="section">
                      <div className="page-header">
                        <h1 className="small">{siteLang == "eng" ? languageText.faqTitleEn : languageText.faqTitle}</h1>
                      </div>
                      <p>
                        {siteLang == "eng" ? languageText.faqParaEn : languageText.faqPara}
                      </p>
                      <div id="question-header" className="question-header color-primary">Questions you may have</div>
                      <div className="question-list">
                        {faqData && faqData.map((data, index) => (
                          <div className={`faq${data.order}`} key={index}>
                            <Link to={`/education/innerfaq?id=${data.id}`}>
                              <span className="faq-question">{siteLang == "eng" ? data.en_title : data.thai_title}</span>
                              <img src="/img/bullet/qlink-achor.png" alt="arrow" />
                            </Link>
                          </div>
                        ))}
                      </div>
                      <div className="section-btn-wapper">
                        <Link to="/education/faq" type="button" className="btn btn-primary">
                          {siteLang == "eng" ? languageText.eduViewBtnEn : languageText.eduViewBtn}
                        </Link>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>  
          </div> 
        </div>
      </div>
     );
}

export default WarrantEducation;