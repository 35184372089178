import React, { useState, useEffect } from "react";
import "../../common.css";
import "../../investorAcademy.css";
import { Link, useLocation, useHistory } from "react-router-dom";
import ChildInvestorAcademyCommon from "../../../../../components/common/InvestorAcademyCommon/ChildInvestorAcademyCommon";
import { languageText } from "../../../../../languageText/InvestorAcademy/UsefulWarrantTerms/delta"
import { languageTextMain } from "../../../../../languageText/InvestorAcademy/index"

const Deltas = (props) => {

  const location = useLocation();
  const history = useHistory();
  const [siteLang , setSiteLang] = useState("")

  // Site Language Change Start //
  useEffect(() => {
    window.scrollTo(0, 0);
    var siteLanguage = $.cookie('applang');
    setSiteLang(siteLanguage)
    if(siteLanguage == "eng"){
      document.querySelector("body").classList.add("eng")
    }else{
      document.querySelector("body").classList.remove("eng")
    }
  }, []);
  // Site Language Change End //

  return (
    <div id="investor_academy">
      <div className="imgbg" />
      <div className="mainbg" />
      <div id="main">
        <div id="maintable">
          <div style={{ display: "table-row" }}>
            <div id="leftbar" className="hidden-xs" />
            <div id="maincontainer">
              {/* Top Title Section Start */}
              <div id="mainnav">
                <table>
                  <tbody>
                    <tr>
                      <td width="20%" style={{textAlign:"left", visibility: "hidden"}} />
                      <td width="60%" style={{ textAlign: "center" }}>
                        <div id="pagetitlediv">
                          <div id="ptitle">
                            {siteLang == "eng" ? languageText.pageTitleEn : languageText.pageTitle}
                          </div>
                          <div id="pname">
                            {siteLang == "eng" ? languageText.pageNameEn : languageText.pageName}
                          </div>
                        </div>
                      </td>
                      <td width="20%" style={{textAlign:"right", visibility: "hidden"}} />
                    </tr>
                  </tbody>
                </table>
              </div>
              {/* Top Title Section End */}
              {/* Main Content Section Start */}
              <div id="maincontent">
                <div id="mainscroll">
                  <div id="mainscrollcontent">
                    <style dangerouslySetInnerHTML={{__html: "#maintable .contentbox .ruletable.ctable tr td.col1{padding-left: 1em !important;}.contentbox .formulabox table tr td { padding: 0px !important; font-size: 16px;}#maintable .contentbox ul li{list-style:none; position: relative;}#maintable .contentbox ul li::before { content: ''; position: absolute; left: -15px; top: 7px; width: 4px;  height: 4px; border-radius: 50%; background-color: #fff; }#maintable .contentbox .ruletable.ctable tr td.title{ font-weight: 500; font-size: 15px !important}.formulaholder{font-size: 14px} .formulabox{line-height: 1.42857143;} .contentbox table{ margin: 0 auto; } .contentbox table.ruletable tbody td{ height:3em; width:30%; color:#000000; } .contentbox table .title{ font-size:15px; } .contentbox table.ruletable tbody td.col1{ background-color: #FFF4D2; text-align:left; padding-left: 1em;  } .contentbox table.ruletable tbody td.col2{ background-color: #FFFF80; text-align:center; } .contentbox table.ruletable tbody td.col3{ background-color: #FFD400; text-align:center; } .contentbox table.ruletable tfoot td.disclaimer{ padding-top: 0.5em; color: #FFFFFF; font-size: 0.8em; } .formulabox { background-color: #363636; } .formulabox .hltxt{ color:#FFDA44; } " }} />
                    <div className="contentbox">
                      <p>
                        {siteLang == "eng" ? languageText.pageDescriptionEn : languageText.pageDescription}
                      </p>
                      <div className="formulaholder">
                        <div className="formulabox">
                          <div className="leqt">
                            <table cellSpacing={0} cellPadding={0}>
                              <tbody>
                                <tr>
                                  <td style={{height: '36px'}}>    
                                    <span className="hltxt">{siteLang == "eng" ? languageText.formulaholderHeadingEn : languageText.formulaholderHeading}</span>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>   
                          <div className="reqt">
                            <table className="fraction" cellSpacing={0} cellPadding={0}>
                              <tbody>
                                <tr>
                                  <td>{siteLang == "eng" ? languageText.formulaholderTdFirstEn : languageText.formulaholderTdFirst}</td>
                                </tr>
                                <tr>
                                  <td className="upper_line">{siteLang == "eng" ? languageText.formulaholderTdSecondEn : languageText.formulaholderTdSecond}</td>
                                </tr>
                              </tbody>  
                            </table>
                          </div>
                        </div>
                      </div>
                      <p>
                        {siteLang == "eng" ? languageText.pageDescription1En : languageText.pageDescription1}
                      </p>
                      <table className="ruletable ctable">
                        <thead>
                          <tr>
                            <td className="title" colSpan={3} style={{color: 'white'}}>
                              {siteLang == "eng" ? languageText.ruletableTheadEn : languageText.ruletableThead}
                            </td>
                          </tr>   
                        </thead>     
                        <tbody>
                          <tr>
                            <td />
                            <td className="col2"><b>Call</b></td>
                            <td className="col3"><b>Put</b></td>
                          </tr>
                          <tr>
                            <td className="col1"><b>In-the-money(max)</b></td>
                            <td className="col2"><label>100%</label></td>
                            <td className="col3"><label>-100%</label></td>
                          </tr>
                          <tr>
                            <td className="col1"><b>At-the-money</b></td>
                            <td className="col2"><label>50%</label></td>
                            <td className="col3"><label>-50%</label></td>
                          </tr>
                          <tr>
                            <td className="col1"><b>Out-of-the-money(min)</b></td>
                            <td className="col2"><b>0%</b></td>
                            <td className="col3"><b>0%</b></td>
                          </tr>
                        </tbody>
                      </table>
                      <p>
                        {siteLang == "eng" ? languageText.pageDescription2En : languageText.pageDescription2}
                      </p>
                      <div className="tipholder">
                        <div className="tipbox">
                          <h2>{siteLang == "eng" ? languageText.tipholderh2En : languageText.tipholderh2}</h2>
                          <ul>
                            <li>
                              <p>{siteLang == "eng" ? languageText.tipholderParagraph1En : languageText.tipholderParagraph1}</p>
                            </li>
                            <li>
                              <p>{siteLang == "eng" ? languageText.tipholderParagraph2En : languageText.tipholderParagraph2}  
                                <Link to="/eduhtml/exerciseratio">{siteLang == "eng" ? languageText.tipholderParagraph2Link1En : languageText.tipholderParagraph2Link1} </Link> 
                                {siteLang == "eng" ? languageText.tipholderParagraph21En : languageText.tipholderParagraph21} 
                                <Link to="/eduhtml/warrantsensitivity">{siteLang == "eng" ? languageText.tipholderParagraph2Link2En : languageText.tipholderParagraph2Link2}</Link> 
                                {siteLang == "eng" ? languageText.tipholderParagraph22En : languageText.tipholderParagraph22}
                              </p>
                            </li>
                            <li>
                              <p>{siteLang == "eng" ? languageText.tipholderParagraph3En : languageText.tipholderParagraph3}</p>
                            </li>
                            <li>
                              {siteLang == "eng" ?
                              <p>Macquarie provides delta for <b><font color="#FFDA44">all DW listed on the SET</font></b>, you view these via the <a href="/tools/dwterms"> DW Terms </a> page.</p>
                              :
                              <p>แมคควอรี มีการคำนวณค่า Delta สำหรับ DW ทุกรุ่นที่จะจดทะเบียนซื้อขายในตลาดหลักทรัพย์แห่งประเทศไทย คุณสามารถหาค่า Delta ได้จากหน้า 
                                <a href="/tools/dwterms"> DW terms </a>
                              </p>
                              }
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Main Content Section End */}
            </div>
            <div id="rightbar" className="hidden-xs" />
          </div>
        </div>
      </div>
      {/* Bottom Left Right Links and Copyright Section Start */}
      <div id="bottom">
        <div className="hidden-xs hidden-xxs" id="copyright">
          {siteLang == "eng" ? languageTextMain.copyRightEn : languageTextMain.copyRight}
        </div>
        <div className="leftUrl">
          <table className="hasprev">
            <tbody>
              <tr>
                <td width="33px">
                  <Link to="/eduhtml/usefulwarrantterms">
                    <div className="prev left" />
                  </Link>
                </td>
                <td>
                  <div className="prevlbl left">
                    <div className="prevname">
                      <Link to="/eduhtml/usefulwarrantterms">
                        {siteLang == "eng" ? languageTextMain.usefulWarrantTermsEn : languageTextMain.usefulWarrantTerms}
                      </Link>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="rightUrl">
          <table className="hasnext">
            <tbody>
              <tr>
                <td>
                  <div className="nextlbl right">
                    <div className="nextname">
                      <Link to="/eduhtml/exerciseratio">
                        {siteLang == "eng" ? languageTextMain.exerciseRatioEn : languageTextMain.exerciseRatio}
                      </Link>
                    </div>
                  </div>
                </td>
                <td width="33px">
                  <Link to="/eduhtml/exerciseratio">
                    <div className="next right" />
                  </Link>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      {/* Bottom Left Right Links and Copyright Section End */}
      {/* Right Dots Menu Component Section Start */}
      <div id="dotbar" className="hidden-sm hidden-xs">
        <ChildInvestorAcademyCommon Location={location} Hist={history} />
      </div>
      {/* Right Dots Menu Component Section End  */}
    </div>
  );
};
export default Deltas;
