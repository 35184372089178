export const languageText ={

    // Page Top Heading And Description Start //
    pageTitle: "สภาพคล่องและการดูแลสภาพคล่อง",
    pageTitleEn: "Market Makers and Liquidity",

    pageDescription: "Please click on each of the tiles below for more details.",
    pageDescriptionEn: "Please click on each of the tiles below for more details.",
    // Page Top Heading And Description End //

    // Hexa Box Content Start //
    howWork: "ผู้ดูแลสภาพคล่องบริหารความเสี่ยงอย่างไร",
    howWorkEn: "How does it",
    howWork1En: " work?",

    liquidityTraded: "วิธีการดูแลสภาพคล่อง",
    liquidityTradedEn: "Liquidity vs ",
    liquidityTraded1En: "Traded Volume",

    howDoMarket: "สภาพคล่องและปริมาณการซื้อขาย",
    howDoMarketEn: "How do Market ",
    howDoMarket1En: "Makers hedge",
    // Hexa Box Content End //
}