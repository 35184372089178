export const languageText ={

    pageTitle: "DW ณ วันครบกำหนดอายุ",
    pageTitleEn: "DW at expiry",

    pageName: "เงินสดส่วนต่างจากการใช้สิทธิ DW",
    pageNameEn: "Settlement value for DW",

    pageDescription: 'DW ทั้งหมดที่ซื้อขายใน SET ในปัจจุบันจะส่งมอบเป็นเงินสดส่วนต่าง นักลงทุนไม่สามารถรับชำระราคาเป็นหลักทรัพยือ้างอิงได้ ดังนั้น นักลงทุนที่ถือ DW ที่มีมูลค่า ณ วันซื้อขายสุดท้าย จะได้รับเงินสดส่วนต่างภายใน 5 วันทำการ นับจากวันครบกำหนดอายุ <br /><br /> ในการคำนวณเงินสดส่วนต่างของ DW ที่อ้างอิงกับหุ้นรายตัว จะคำนวณจากราคาปิดของหุ้นอ้างอิง ณ วันซื้อขายสุดท้ายของ DW',
    pageDescriptionEn: 'All DW in Thailand are currently cash settled, investors do not take delivery of the physical shares. Instead, holders of DW with value at expiry will receive payment within 5 business days from the expiry date. <br /><br /> To calculate the settlement value of single stock DW, the <b>closing price</b> of the underlying share <b>on the last trading</b>, is used.',

    pageTitleCall: "Call DW",
    pageTitleCallEn: "Call DW",

    formulaHolderLeft: "มูลค่าคงเหลือ ณ วันครบกำหนดอายุ = ",
    formulaHolderLeftEn: "<b>Value at Expiry</b> = ",
    formulaHolderRightFirst: "(ราคาปิดของหุ้นอ้างอิง ณ วันซื้อขายสุดท้ายของ DW - ราคาใช้สิทธิ)",
    formulaHolderRightFirstEn: "(Closing price of Underlying as of last trading date - Exercise Price)",
    formulaHolderRightSecond: "DWPS",
    formulaHolderRightSecondEn: "DWPS",

    pageTitlePut: "Put DW",
    pageTitlePutEn: "Put DW",

    formulaHolder1Left: "มูลค่าคงเหลือ ณ วันครบกำหนดอายุ = ",
    formulaHolder1LeftEn: "<b>Value at Expiry</b> = ",
    formulaHolder1RightFirst: "(ราคาใช้สิทธิ - ราคาปิดของหุ้นอ้างอิง ณ วันซื้อขายสุดท้ายของ DWิ)",
    formulaHolder1RightFirstEn: "(Exercise Price - Closing price of Underlying as of last trading date)",
    formulaHolder1RightSecond: "DWPS",
    formulaHolder1RightSecondEn: "DWPS",

    imgUrl: "/img/education/img/calendar_th.png",
    imgUrlEn: "/img/education/img/calendar.png",
    
}