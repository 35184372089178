import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import API from '../../../utils/apiCalling';
import { config } from '../../../utils/apiUrl';
import LangContext from "../../../components/common/context/lang-context";
import { useContext } from "react";
import { languageText } from "../../../languageText/home";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import OutsideClickHandler from 'react-outside-click-handler';


const MarketNews = () => {

   const api = new API();
   const langContext = useContext(LangContext);
   const [dailyHsi, setDailyHsi] = useState([])
   const [overNight, setOverNight] = useState()
   const [commenTary, setCommenTary] = useState()
   const [activityData, setActivityData] = useState()
   const [photoGallery, setPhotoGallery] = useState()
   const [subscribeModal, setSubscribeModal] = useState(false)
   const [subscribeEmailVal, setSubscribeEmailVal] = useState("")
   const [emailError, setEmailError] = useState(false)
   const [subscribeSuccess, setSubscribeSuccess] = useState(false)
   const [siteLang , setSiteLang] = useState("")

   useEffect(() => {
      var siteLanguage = $.cookie('applang');
      setSiteLang(siteLanguage)
      if(siteLanguage == "eng"){
         document.querySelector("body").classList.add("eng")
      }else{
         document.querySelector("body").classList.remove("eng")
      }
      getHomeNewsJson()
      getactivityDataHome()
   }, []);
  
   const getHomeNewsJson = async () => {
      let homeJsonResult = await api.get(
        config.homeJSON,
        true,
        { m: "dwnews", qid: Date.now() }
      );
      let items = [];
      if(homeJsonResult?.dailyhsi?.image == 1){
         let advImgData = await api.get(config.advImgData, true, {
            hsi: homeJsonResult.dailyhsi.id,
         });
         items.push({
            imgSrc: advImgData,
            en_short_content: homeJsonResult.dailyhsi.en_short_content,
            thai_short_content: homeJsonResult.dailyhsi.thai_short_content,
            en_title: homeJsonResult.dailyhsi.en_title,
            thai_title: homeJsonResult.dailyhsi.thai_title,
            id: homeJsonResult.dailyhsi.id,
            image: homeJsonResult.dailyhsi.image,
            publish_date: homeJsonResult.dailyhsi.publish_date,
         });
         setDailyHsi(items);
      }else{
         items.push(homeJsonResult.dailyhsi)
         setDailyHsi(items)
      }
      setOverNight(homeJsonResult.overnight)
      setCommenTary(homeJsonResult.commentary)
      let photoGalleryResult = homeJsonResult.albums
      let itemsPhoto = []; 
      if (photoGalleryResult?.length > 0) {
          for (let i = 0; i < photoGalleryResult.length; i++) {
              let advImgData = await api.get(config.advImgData, true, {
                  album_id: photoGalleryResult[i].id,
              });
              itemsPhoto.push({
                  id: photoGalleryResult[i].id,
                  status: photoGalleryResult[i].status,
                  en_title: photoGalleryResult[i].en_title,
                  th_title: photoGalleryResult[i].th_title,
                  en_description: photoGalleryResult[i].en_description,
                  th_description: photoGalleryResult[i].th_description,
                  last_update_time: photoGalleryResult[i].last_update_time,
                  imgLink: advImgData,
              });
          }
          setPhotoGallery(itemsPhoto);
      }
   };

   const getactivityDataHome = async () => {
      let activityData = await api.get(config.actvitie, true, {
        qid: Date.now(),
      });
      let items = [];
      if(activityData?.list?.length > 0){
        let advImgData = await api.get(config.advImgData, true, {
          activities: activityData?.list[0].id,
        });
        items.push({
          id: activityData?.list[0].id,
          date: activityData?.list[0].date,
          en_title: activityData?.list[0].en_title,
          thai_title: activityData?.list[0].thai_title,
          imgLink: advImgData,
        });
      }
      setActivityData(items)
   };

   const settings = {
      autoplay: true,
      infinite: true,
      slidesToShow: 1,
      slidesToScroll: 1,
      dots: true,
   };

   const subscribeClose = () => {
      setSubscribeModal(false)
      document.querySelector("body").classList.remove("noScroll")
   }

   const subscribeOpen = () => {
      setSubscribeModal(true)
      document.querySelector("body").classList.add("noScroll")
   }

   const submitregister = async () => {
      if(subscribeEmailVal == undefined || subscribeEmailVal == null || subscribeEmailVal == ""){
         setEmailError(true);
         return false
      }
      var regex = /^(([^<>()[\]\.,;:\s@\"]+(\.[^<>()[\]\.,;:\s@\"]+)*)|(\".+\"))@(([^<>()[\]\.,;:\s@\"]+\.)+[^<>()[\]\.,;:\s@\"]{2,})$/i;
      if(regex.test(subscribeEmailVal) == false){
         setEmailError(true);
         return false
      }else {
         setEmailError(false);
      }
      var data = {
         email: subscribeEmailVal
      }
      var res = await api.post(`${config.subscription}${"?p=register"}`, data)
      .then((res) => {
         if(res.message == "Registration succeed"){
            setSubscribeSuccess(true)
            setSubscribeEmailVal("")
         }
         else{
            alert(res.message)
         }
      })
      .catch((error) => console.log(error))
   }

   return ( 
      <>
      <div>
         <div className="tab-container container-fluid">
            <div id="dwnews" className="tabcontent">
               <div className="frame-lgmd">
                  <div className="row">
                     <div className="col-md-6 col-sm-6 col-left">
                        <div className="holder-todayhighlight">
                           <div id="todayhighlight" className="section">
                              <div className="page-header">
                                 <h1 className="small">{siteLang == "eng" ? languageText.hsiIndexTitleEn : languageText.hsiIndexTitle}</h1>
                              </div>
                              <div className="news-title" style={{display: dailyHsi?.length > 0 && dailyHsi[0]?.image == 1 ? "none" : ""}}>
                                 {(dailyHsi?.length > 0 && siteLang == "thai" && dailyHsi[0]?.thai_title != "") ? dailyHsi[0]?.thai_title : (dailyHsi?.length > 0 && siteLang == "eng" && dailyHsi[0]?.en_title != "") ? dailyHsi[0]?.en_title : "-"}
                              </div>
                              {dailyHsi?.length > 0 && dailyHsi[0]?.image == 1 && <p className="news-summary newsImage" style={{overflowWrap: 'break-word'}}>
                                 <img width='100%' src={dailyHsi[0]?.imgSrc} alt="Daily HSI" />
                              </p>}
                              {dailyHsi?.length > 0 && dailyHsi[0]?.image != 1 && <p className="news-summary" style={{overflowWrap: 'break-word'}}
                                  dangerouslySetInnerHTML={{__html: (siteLang == "thai" && dailyHsi[0]?.thai_short_content != "") ? dailyHsi[0]?.thai_short_content : (siteLang == "eng" && dailyHsi[0]?.en_short_content != "") ? dailyHsi[0]?.en_short_content : "-"}} >
                              </p>}
                              <div className="section-btn-wapper">
                                 {dailyHsi?.length > 0 && (dailyHsi[0]?.id !== null || dailyHsi[0]?.id !== undefined || dailyHsi[0]?.id != "") ? 
                                    <Link style={{minWidth: '190px'}} to={`/marketnews/highlight?cat=hsi&id=${dailyHsi[0]?.id}`} type="button" className="btn btn-primary">
                                       {siteLang == "eng" ? languageText.newsViewBtnEn : languageText.newsViewBtn}
                                    </Link>
                                 :
                                    <Link style={{minWidth: '190px'}} to="/marketnews/dailyhsi" type="button" className="btn btn-primary">
                                       {siteLang == "eng" ? languageText.newsViewBtn : languageText.newsViewBtnEn}
                                    </Link>
                                 }
                              </div>
                           </div>
                        </div>
                        {/* <div className="holder-marketwrap">
                           <div id="marketwrap" className="section">
                              <div className="page-header">
                                 <h1 className="small">{siteLang == "eng" ? languageText.marketWrapTitleEn : languageText.marketWrapTitle}</h1>
                              </div>
                              <div className="news-title">
                                 {(overNight && siteLang == "thai" && overNight.thai_title != "") ? overNight.thai_title : (overNight && siteLang == "eng" && overNight.en_title) ? overNight.en_title : "-"}
                              </div>
                              <p className="news-summary" style={{overflowWrap: 'break-word'}}
                                 dangerouslySetInnerHTML={{__html: (overNight && siteLang == "thai" && overNight.thai_short_content != "") ? overNight.thai_short_content : (overNight && siteLang == "eng" && overNight.en_short_content != "") ? overNight.en_short_content : "-"}} >
                              </p>
                              <div className="section-btn-wapper">
                                 {overNight && (overNight.id !== null || overNight.id !== undefined || overNight.id != "") ? 
                                    <Link style={{minWidth: '190px'}} to={`/marketnews/highlight?cat=overnightmarkets&id=${overNight.id}`} type="button" className="btn btn-primary">
                                       {siteLang == "eng" ? languageText.newsViewBtnEn : languageText.newsViewBtn}
                                    </Link>
                                 :
                                    <Link style={{minWidth: '190px'}} to="/marketnews/overnightmarkets" type="button" className="btn btn-primary">
                                       {siteLang == "eng" ? languageText.newsViewBtnEn : languageText.newsViewBtn}
                                    </Link>
                                 }
                              </div>
                           </div>
                        </div> */}
                        {/* <div className="holder-marketcommentary">
                           <div id="marketcommentary" className="section">
                              <div className="page-header">
                                 <h1 className="small">{siteLang == "eng" ? languageText.dwMarketTitleEn : languageText.dwMarketTitle}</h1>
                              </div>
                              <div className="news-title">
                                 {(commenTary && siteLang == "thai" && commenTary.thai_title != "") ? commenTary.thai_title : (commenTary && siteLang == "eng" && commenTary.en_title) ? commenTary.en_title : "-"}
                              </div>
                              <p className="news-summary" style={{overflowWrap: 'break-word'}}
                                 dangerouslySetInnerHTML={{__html: (commenTary && siteLang == "thai" && commenTary.thai_short_content != "") ? commenTary.thai_short_content : (commenTary && siteLang == "eng" && commenTary.en_short_content != "") ? commenTary.en_short_content : "-"}} >
                              </p>
                              <div className="section-btn-wapper">
                                 {commenTary && (commenTary.id !== null || commenTary.id !== undefined || commenTary.id != "") ? 
                                    <Link style={{minWidth: '190px'}} to={`/marketnews/highlight?cat=dailycommentary&id=${commenTary.id}`} type="button" className="btn btn-primary">
                                       {siteLang == "eng" ? languageText.newsViewBtnEn : languageText.newsViewBtn}
                                    </Link>
                                 :
                                    <Link style={{minWidth: '190px'}} to="/marketnews/dailycommentary" type="button" className="btn btn-primary">
                                       {siteLang == "eng" ? languageText.newsViewBtnEn : languageText.newsViewBtn}
                                    </Link>
                                 }
                              </div>
                           </div>
                        </div> */}
                     </div>
                     <div className="col-md-6 col-sm-6 col-right">
                        <div className="holder-subscribe">
                           <div id="subscribe" className="section">
                              <div className="page-header">
                                 <h1 className="small">{siteLang == "eng" ? languageText.subscribeTitleEn : languageText.subscribeTitle}</h1>
                              </div>
                              <p className="section-p">
                                 {siteLang == "eng" ? languageText.subscribeParaEn : languageText.subscribePara}
                              </p>
                              <input type="text" id="email" onChange={(e) => setSubscribeEmailVal(e.target.value)} placeholder={siteLang == "eng" ? languageText.subscribeInputPlaceholderEn : languageText.subscribeInputPlaceholder} autoComplete="off" />
                              <div className="warning">{emailError ? "Invalid email" : ""}</div>
                              <div id="text-content" style={{display: 'none'}}>
                                 <div className="text_subscribe_registration_success">
                                    {siteLang == "eng" ? languageText.subscribeSuccessEn : languageText.subscribeSuccess}
                                 </div>
                              </div>
                              <div className="small">{siteLang == "eng" ? languageText.subscribeTermsEn : languageText.subscribeTerms} <span className="ppbtn" onClick={subscribeOpen}>{siteLang == "eng" ? languageText.subscribeClickHereEn : languageText.subscribeClickHere}</span></div>
                              <div className="section-btn-wapper">    
                              <input type="button" id="submitBtn" onClick={submitregister} className="btn btn-primary" defaultValue={siteLang == "eng" ? languageText.subscribeBtnEn : languageText.subscribeBtn} />
                              </div>
                           </div>
                        </div>
                        <div id="video-dwnews-lgmd" className="holder-advertisment holder-empty" />
                        <div className="holder-activity">
                           <div id="activity" className="section">
                              <div className="page-header">
                                 <h1 className="small">{siteLang == "eng" ? languageText.activitiesTitleEn : languageText.activitiesTitle}</h1>
                              </div>
                              <p>
                                 {siteLang == "eng" ? languageText.activitiesParaEn : languageText.activitiesPara}
                              </p>
                              {activityData?.length <= 0 ? 
                                 <>
                                    <img className="activity_img" src="/img/home/home-activities.png" style={{width: '100%'}} />
                                    <div className="section-btn-wapper">
                                       <Link to="/marketnews/activities" style={{minWidth: '190px'}} type="button" className="btn btn-primary activity_link">
                                          {siteLang == "eng" ? languageText.newsViewBtnEn : languageText.newsViewBtn}
                                       </Link>
                                    </div>
                                 </>
                              :
                                 <>
                                    <img className="activity_img" src={activityData && activityData[0]?.imgLink} style={{width: '100%'}} />
                                    <div className="section-btn-wapper">
                                       <Link to={`/marketnews/activitiesdata?id=${activityData && activityData[0]?.id}`} style={{minWidth: '190px'}} type="button" className="btn btn-primary activity_link">
                                          {siteLang == "eng" ? languageText.newsViewBtnEn : languageText.newsViewBtn}
                                       </Link>
                                    </div>
                                 </>
                              }
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="row">
                     <div className="col-md-12">
                        <div className="holder-gallery">
                           <div id="photogallery" className="gallery swiper-container section advertising-banner swiper-container-horizontal">
                              <Slider {...settings} className="photo-slider">
                                 {photoGallery &&
                                    photoGallery.map((data, index) => {
                                    return (
                                       <div className="gallery-item" key={index}>
                                          <div className="gallery-item-wapper">
                                             <div className="gallery-item-overlay"></div>
                                             <div className="gallery-item-body">
                                                <div className="gallery-item-txt">{siteLang == "eng" ? (data.en_title == "" ? "-" : data.en_title) : (data.th_title == "" ? "-" : data.th_title)}</div>
                                                <Link to={`/marketnews/individualgallery?album_id=${data.id}`} type="button" className="hidden-xs btn btn-primary gallery-item-btn">
                                                   {siteLang == "eng" ? languageText.newsViewBtnEn : languageText.newsViewBtn}
                                                </Link>
                                             </div>
                                             <Link to={`/marketnews/individualgallery?album_id=${data.id}`} className="gallery-item-achor visible-xs"></Link>
                                             <img className="gallery-item-img" src={data.imgLink} />
                                          </div>
                                       </div>

                                    );
                                    })}
                              </Slider>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div> 
         </div> 
      </div> 
      <div id="subscribeModal" className="overlay" style={{display: subscribeModal ? 'block' : "none"}}>
         <div className="overlay-inner">
            <div style={{position: 'relative'}}>
               <img onClick={subscribeClose} className="btn-close" src="/img/bullet/icon-close-white.png" alt='Close' />
               <div className="popupbox-content">
                  <div className="ppc" key="subscribe-newsletter">
                  <div className="hotline-layer">
                     <div>{siteLang == "eng" ? "By clicking “Submit”, you confirm that you would like to subscribe to Macquarie’s daily warrant newsletter. By subscribing, you will also automatically receive invitations or marketing material from Macquarie for warrant seminars and other warrant related initiatives. Macquarie website administrators may have access to your personal data for the processing of such data on behalf of Macquarie Securities (Thailand) Ltd and other companies of Macquarie group (“Macquarie”)."
                                    : "นอกจากนี้คุณยังจะได้รับการประชาสัมพันธ์และการเชิญชวนให้ร่วมงานสัมมนาเกี่ยวกับใบสำคัญแสดงสิทธิอนุพันธ์หรืองานอื่นๆที่เกี่ยวข้อง ทางเจ้าหน้าที่เว็บไซต์อาจมีการเข้าถึงข้อมูลส่วนบุคคลสำหรับการดำเนินการดังกล่าวในนามของMacquarie Securities (Thailand) Ltd&และบริษัทอื่นๆ ของ&Macquarie group (“Macquarie”)."}</div>
                  </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
      {subscribeSuccess && <div id="popup-customized"><div>
         <OutsideClickHandler onOutsideClick={() => setSubscribeSuccess(false)}>
            <div>
               <h5>{siteLang == "eng" ? "You are successfully registered as a member. Please check your log in information from your email account." : "คุณได้ทำการสมัครสมาชิกเรียบร้อยแล้ว กรุณาตรวจสอบข้อมูลผู้ใช้และรหัสผ่านได้จากอีเมล์ที่ท่านใช้ในการสมัคร"}</h5>
            </div>
         </OutsideClickHandler>
         </div>
      </div>}
      </>
     );
}

export default MarketNews;
