export const languageText ={

    pageTitle: "DW คืออะไร?",
    pageTitleEn: "What are DW?",

    pageName: "Call DW ทำงานอย่างไร",
    pageNameEn: "How a Call DW works",

    h2Heading: "ตัวอย่างสมมติของ Call DW",
    h2HeadingEn: "Hypothetical example of a call DW",

    pageDescription: "นี่คือตัวอย่างการลงทุนใน Call DW ที่อ้างอิงหุ้น CPF เส้นสีแดงแสดงราคาของหุ้น CPF และเส้นสีฟ้าแสดงราคาของ Call DW ที่อ้างอิงหุ้น CPF",
    pageDescriptionEn: "Here’s a look at an example of an investment in a DW over CPF. The red line represents the CPF mother share while the blue line is the call DW over CPF. As we can see from the graph, the two lines move in tandem with each other – the call DW moves up when the CPF share price goes up, and likewise follows it downward.",

    pageDescription1: "จากกราฟ เราจะเห็นว่าเส้นกราฟทั้งสองเส้นเคลื่อนไหวในทิศทางเดียวกัน - ราคา Call DW จะปรับตัวสูงขึ้นเมื่อราคาหุ้น CPF ปรับตัวสูงขึ้นและปรับตัวลดลงเมื่อราคาหุ้น CPF ปรับตัวลดลง ถ้าคุณมองว่าราคาหุ้น CPF จะปรับตัวสูงขึ้นจากราคา 33 บาท คุณอาจจะซื้อหุ้น CPF เต็มจำนวนที่ราคา 33 บาท เพื่อรับโอกาสในการได้รับผลตอบแทน หรือคุณอาจจะซื้อ Call DW ที่ราคา 1.10 บาท ซึ่งคิดเป็น 3.5% ของราคาหุ้นเท่านั้น",
    pageDescription1En: "Suppose you have a bullish view on CPF with the share price at THB 33. You could either pay the full amount of THB 33 per share to gain exposure or you could buy a DW call for under THB 1.10, less than 3.5 percent of the share price.",

    imgUrl: "/img/education/img/howacallwarrantworks_th.png",
    imgUrlEn: "/img/education/img/howacallwarrantworks.png",
    
    pageDescription2: "สมมติว่ามุมมองที่คุณคาดไว้ถูกต้อง และราคาหุ้น CPF ปรับตัวขึ้นไปที่ 36.75 บาท ตามที่คุณตั้งเป้าหมายไว้ หมายความว่า คุณจะมีกำไร 10.5% จากการซื้อหุ้น อย่างไรก็ตาม ถ้าคุณซื้อ Call DW เอาไว้ คุณจะได้รับผลตอบแทน 69.3% ซึ่งมากกว่า 6 เท่า",
    pageDescription2En: "Assuming your view was right and CPF moved to your target price of THB 36.75, this would translate to a return of 10.5% if you had purchased the shares. However, if you bought the call DW instead, you would have made a 69.3% return, more than 6 times that of the share price move.",

    pageDescription3: "นี่เป็นตัวอย่างที่แสดงให้เห็นผลของ Gearing และประโยชน์ของ DW – การให้อัตราผลตอบแทนที่สูงกว่าจากเงินลงทุนของคุณ",
    pageDescription3En: "This is an example of gearing at work, and the advantage of DW- a greater potential profit as a percentage of your invested capital.",

    firstTrTd: "หุ้น CPF",
    firstTrTdEn: "CPF Share",
    firstTrTd1: "CPF Call DW",
    firstTrTd1En: "CPF Call DW",

    secondTrTd: "4 มกราคม",
    secondTrTdEn: "4 January",
    secondTrTd1: "33.25 บาท",
    secondTrTd1En: "THB 33.25",
    secondTrTd2: "1.13 บาท",
    secondTrTd2En: "THB 1.13",

    thirdTrTd: "3 กุมภาพันธ์",
    thirdTrTdEn: "3 February",
    thirdTrTd1: "36.75 บาท",
    thirdTrTd1En: "THB 36.75",
    thirdTrTd2: "1.63 บาท",
    thirdTrTd2En: "THB 1.63",

    fourthTrTd: "กำไร",
    fourthTrTdEn: "Profit",
    fourthTrTd1: "+10.5%",
    fourthTrTd1En: "+10.5%",
    fourthTrTd2: "+69.3%",
    fourthTrTd2En: "+69.3%",
}