export const languageText ={

    pageTitle: "ราคา DW คำนวณอย่างไร",
    pageTitleEn: "How are DW priced?",

    pageName: "วันครบกำหนดอายุ",
    pageNameEn: "Expiry Date",

    pageDescription: "วันในอนาคตที่ DW จะหมดอายุ เรียกกันว่า “วันครบกำหนดอายุ” สำหรับทั้ง Call DW และ Put DW อายุคงเหลือยิ่งมาก จะทำให้ DW มีมูลค่าสูงขึ้น เพราะว่า DW ที่มีอายุคงเหลือยาวกว่า จะมีโอกาสที่จะมีมูลค่าคงเหลือ ณ วันครบกำหนดอายุมากกว่า",
    pageDescriptionEn: "The future date at which the DW will cease to exist is known as the expiry date. For both calls and puts, the greater the time to expiry, the greater the value of the DW. This is because, the longer a DW has remaining until expiry, the higher the probability of it expiring with value.",

    imgUrl: "/img/education/img/ed.png",
    imgUrlEn: "/img/education/img/ed.png",
}