import React, { useEffect, useState} from 'react';
import {Carousel} from 'react-responsive-carousel';


const EpisodeCom = ({ activeVideoData, setSelectedData }) => {
    
    const [siteLang , setSiteLang] = useState("")
    const itemsPerRow = 4;
    const itemsPerTable = 4;
    const tableRows = [];

    useEffect(() => {
      window.scrollTo(0, 0);
      var siteLanguage = $.cookie('applang');
      setSiteLang(siteLanguage)
      if(siteLanguage === "eng"){
        document.querySelector("body").classList.add("eng")
      }else{
        document.querySelector("body").classList.remove("eng")
      }
    }, []);

    const handleVideoItemClick = (data) => {
      setSelectedData(data)
    }
     
    for (let i = 0; i < activeVideoData.length; i += itemsPerTable) {
        const tableItems = [];
        for (let j = i; j < i + itemsPerTable && j < activeVideoData.length; j += itemsPerRow) {
          const rowItems = [];
          for (let k = j; k < j + itemsPerRow && k < activeVideoData.length; k++) {
            const speaker = activeVideoData[k];
            rowItems.push(
              <td key={speaker.en_title}>
                <div className="videorack-item" playlistid={speaker.playlistId} index={speaker.index} onClick={()=> handleVideoItemClick(speaker)}>
                  <img style={{ width: "100%" }} src={speaker.image} />
                  <div className="videorack-item-txt">
                    {siteLang == "eng" ? speaker.en_ep: speaker.th_ep}
                  </div>
                </div>
              </td>
            );
          }
          while (rowItems.length < itemsPerRow) {
            rowItems.push(
              <td key={`placeholder-${rowItems.length}`}>
                <div className="videorack-item videorack-item-hidden">
                  <img style={{ width: "100%" }} src={activeVideoData[0].image} />
                  <div className="videorack-item-txt">
                    {siteLang === "eng" ? activeVideoData[0].en_ep : activeVideoData[0].th_ep}
                  </div>
                </div>
              </td>
            );
          }
          tableItems.push(
            <tr key={j}>
                {rowItems}
            </tr>
          );
        }
        tableRows.push(
            <div key={i} className="slide-tab">
              <table className={`videorack-page ${i > 0 ? 'slide-tab' : ''}`}>
                <tbody>
                  {tableItems}
                </tbody>
              </table>
            </div>
        );
    }

    return (
      <>
        <Carousel
        showArrows={true}
        emulateTouch
        showStatus={false}
        autoFocus
        infiniteLoop={false}
        showIndicators={false}
        showThumbs={false}
        >
            {tableRows}
        </Carousel>
      </>
    );
};

export default EpisodeCom;