export const speakerList = [
    {
      name: "speaker5-juti-1",
      img: "/img/education/video-Juti-1.jpg",
      image: "/img/education/video-Juti-1.jpg",
      text: 'Ready - SET(50) -Trade ตอน "เริ่มต้นเทรด" โดย อ.ติ',
      spkinfo: "คุณ อุทัยวรรณ ชื่นชม ผู้เขียนหนังสือขายดี 'Trend Line ง่ายจัง'   วิทยากรผู้สอนกราฟเทคนิคให้กับบรรดานักลงทุนของโบรกเกอร์ชื่อดัง มากมาย และคอลัมนิสต์สาวยอดนิยม ประจำกลุ่ม FACEBOOK 'IRS เดี่ยว Technical'",
      contactinfo: "วันเสาร์ที่ 3 กันยายน 2559 <br>เวลา 09:00 - 12:00 น.<br>สถานที่ สมาคมธรรมศาสตร์",
      hideInfo: true,
      playlistId: "PLOkzCdnS95xSSEV8gUBO0eBbNDYixuFfs",
    },
    {
      name: "speaker5-juti-2",
      img: "/img/education/video-Juti-2.jpg",
      image: "/img/education/video-Juti-2.jpg",
      text: 'Ready - SET(50) -Trade ตอน "ติวเข้ม" โดย อ.ติ',
      spkinfo: "คุณ อุทัยวรรณ ชื่นชม ผู้เขียนหนังสือขายดี 'Trend Line ง่ายจัง'   วิทยากรผู้สอนกราฟเทคนิคให้กับบรรดานักลงทุนของโบรกเกอร์ชื่อดัง มากมาย และคอลัมนิสต์สาวยอดนิยม ประจำกลุ่ม FACEBOOK 'IRS เดี่ยว Technical'",
      contactinfo: "วันเสาร์ที่ 3 กันยายน 2559 <br>เวลา 09:00 - 12:00 น.<br>สถานที่ สมาคมธรรมศาสตร์",
      hideInfo: true,
      playlistId: "PLOkzCdnS95xSXsB7H3mo1x7tarUzos1bZ",
    },
    {
      name: "speaker6-Man-1",
      img: "/img/education/video-Man-1.jpg",
      image: "/img/education/video-Man-1.jpg",
      text: 'Ready - SET(50) -Trade ตอน "เริ่มต้นเทรด" โดย อ.แมน',
      spkinfo: "คุณ อุทัยวรรณ ชื่นชม ผู้เขียนหนังสือขายดี 'Trend Line ง่ายจัง'   วิทยากรผู้สอนกราฟเทคนิคให้กับบรรดานักลงทุนของโบรกเกอร์ชื่อดัง มากมาย และคอลัมนิสต์สาวยอดนิยม ประจำกลุ่ม FACEBOOK 'IRS เดี่ยว Technical'",
      contactinfo: "วันเสาร์ที่ 3 กันยายน 2559 <br>เวลา 09:00 - 12:00 น.<br>สถานที่ สมาคมธรรมศาสตร์ ",
      hideInfo: true,
      playlistId: "PLOkzCdnS95xQZx-IinZtntRaOz8ExNKbR",
    },
    {
      name: "speaker6-Man-2",
      img: "/img/education/video-Man-2.jpg",
      image: "/img/education/video-Man-2.jpg",
      text: 'Ready - SET(50) -Trade ตอน "ติวเข้ม" โดย อ.แมน',
      spkinfo: "คุณ อุทัยวรรณ ชื่นชม ผู้เขียนหนังสือขายดี 'Trend Line ง่ายจัง'   วิทยากรผู้สอนกราฟเทคนิคให้กับบรรดานักลงทุนของโบรกเกอร์ชื่อดัง มากมาย และคอลัมนิสต์สาวยอดนิยม ประจำกลุ่ม FACEBOOK 'IRS เดี่ยว Technical'",
      contactinfo: "วันเสาร์ที่ 3 กันยายน 2559 <br>เวลา 09:00 - 12:00 น.<br>สถานที่ สมาคมธรรมศาสตร์ ",
      hideInfo: true,
      playlistId: "PLOkzCdnS95xT8flpr3ZgIMBgdEvievOHJ",
    },
    {
      name: "speaker1-kpom",
      img: "/img/education/dp-kpom.jpg",
      image: "/img/education/video-kpom.jpg",
      text: '"เจาะลึก เคล็ดลับ DW" โดย คุณป้อม วัฒนา',
      spkinfo: "วัฒนา ชื่นจิตรวงษา (ป้อม)<br><b>Full-Time Trader</b><br>เจ้าของFacebook 'Wattana Stock Page'<br>ผู้คร่ำหวอดในวงการตลาดทุน และ DW<br>มากว่า 10 ปี",
      contactinfo: "วันเสาร์ที่ 24 กันยายน 2559<br>เวลา 09:00 - 12:00 น<br>สถานที่ Siam Motors Building (BTS สนามกีฬาแห่งชาติ",
      hideInfo: false,
      playlistId: "PLOkzCdnS95xTS1EqkDqV4IIZdx4W8_N-c",
    },
    {
      name: "speaker2-kvee",
      img: "/img/education/dp-kvee.jpg",
      image: "/img/education/video-kvee.jpg",
      text: 'Super Trader Season 3 โดย โค้ชวี ทีม Gold Roger',
      spkinfo: "โค้ชวี ทีม Gold Roger<br>โครงการ SuperTrader Season 3",
      contactinfo: "วันอาทิตย์ที่ 18 กันยายน 2559 <br>เวลา 09:00 - 12:00 น.<br>สถานที่ สมาคมธรรมศาสตร์",
      hideInfo: false,
      playlistId: "PLOkzCdnS95xR_4lZuCtlvMJoiazn3xgjP",
    },
    {
      name: "speaker3-kgung",
      img: "/img/education/dp-kgung.jpg",
      image: "/img/education/video-kgung.jpg",
      text: 'งานสัมมนา Super Trader Season 3 โดย โค้ชกั้ง ทีม Wind Ranger',
      spkinfo: "โค้ชกั้ง ทีม Wind Ranger<br>โครงการ SuperTrader Season 3",
      contactinfo: "วันอาทิตย์ที่ 18 กันยายน 2559<br>เวลา 13:00 - 17:00 น.<br>สถานที่ สมาคมธรรมศาสตร์",
      hideInfo: false,
      playlistId: "PLOkzCdnS95xTKCgNSigoHsH5Q987--t3p",
    },
    {
      name: "speaker4-kice",
      img: "/img/education/dp-kice.jpg",
      image: "/img/education/video-kice.jpg",
      text: 'งานสัมมนา "เทคนิคเล่นสั้น ฟันกำไร" โดย คุณไอซ์ อุทัยวรรณ',
      spkinfo: "คุณ อุทัยวรรณ ชื่นชม ผู้เขียนหนังสือขายดี 'Trend Line ง่ายจัง'   วิทยากรผู้สอนกราฟเทคนิคให้กับบรรดานักลงทุนของโบรกเกอร์ชื่อดัง มากมาย และคอลัมนิสต์สาวยอดนิยม ประจำกลุ่ม FACEBOOK 'IRS เดี่ยว Technical'",
      contactinfo: "วันเสาร์ที่ 3 กันยายน 2559 <br>เวลา 09:00 - 12:00 น.<br>สถานที่ สมาคมธรรมศาสตร์ ",
      hideInfo: false,
      playlistId: "PLOkzCdnS95xR0dazX8WStrYtrBP0VOhSd",
    }
  ]

export const speakerEpisodeData = [
  {
    playlistId: "PLOkzCdnS95xTS1EqkDqV4IIZdx4W8_N-c",
    index: "0",
    img: "dp-kpom.jpg",
    en_ep: "Ep 1: Prepare trading DW",
    image: "https://i.ytimg.com/vi/lzY5idaKAOg/mqdefault.jpg",
    en_title: "งานสัมมนา 'เจาะลึก เคล็ดลับ DW' โดย คุณป้อม วัฒนา (Ep. 01)",
    en_text: 'รับฟังประสบการณ์ วิธีการเลือก DW และการทำกำไรในภาวะที่ตลาดผันผวน',
    en_spkinfo: "วัฒนา ชื่นจิตรวงษา (ป้อม)<br><b>Full-Time Trader</b><br>เจ้าของFacebook 'Wattana Stock Page'<br>ผู้คร่ำหวอดในวงการตลาดทุน และ DW<br>มากว่า 10 ปี",
    en_contactinfo: "วันเสาร์ที่ 24 กันยายน 2559<br>เวลา 09:00 - 12:00 น<br>สถานที่ Siam Motors Building (BTS สนามกีฬาแห่งชาติ",
    th_title: "งานสัมมนา 'เจาะลึก เคล็ดลับ DW' โดย คุณป้อม วัฒนา (Ep. 01)",
    th_text: 'รับฟังประสบการณ์ วิธีการเลือก DW และการทำกำไรในภาวะที่ตลาดผันผวน',
    th_spkinfo: "วัฒนา ชื่นจิตรวงษา (ป้อม)<br>Full-Time Trader<br>เจ้าของFacebook 'Wattana Stock Page'<br>ผู้คร่ำหวอดในวงการตลาดทุน และ DW มากว่า 10 ปี",
    th_contactinfo: "วันเสาร์ที่ 24 กันยายน 2559<br>เวลา 09:00 - 12:00 น<br>สถานที่ Siam Motors Building (BTS สนามกีฬาแห่งชาติ",
    th_ep: "Ep 1: Prepare trading DW",
  },
  {
    playlistId: "PLOkzCdnS95xTS1EqkDqV4IIZdx4W8_N-c",
    index: "1",
    img: "dp-kpom.jpg",
    en_ep: "Ep 2: DW tools",
    image: "https://i.ytimg.com/vi/HuaqXusMAWk/mqdefault.jpg",
    en_title: "งานสัมมนา 'เจาะลึก เคล็ดลับ DW' โดย คุณป้อม วัฒนา (Ep. 02)",
    en_text: 'รับฟังประสบการณ์ วิธีการเลือก DW และการทำกำไรในภาวะที่ตลาดผันผวน',
    en_spkinfo: "วัฒนา ชื่นจิตรวงษา (ป้อม)<br>Full-Time Trader<br>เจ้าของFacebook 'Wattana Stock Page'<br>ผู้คร่ำหวอดในวงการตลาดทุน และ DW มากว่า 10 ปี",
    en_contactinfo: "วันเสาร์ที่ 24 กันยายน 2559<br>เวลา 09:00 - 12:00 น<br>สถานที่ Siam Motors Building (BTS สนามกีฬาแห่งชาติ",
    th_title: "งานสัมมนา 'เจาะลึก เคล็ดลับ DW' โดย คุณป้อม วัฒนา (Ep. 02)",
    th_text: 'รับฟังประสบการณ์ วิธีการเลือก DW และการทำกำไรในภาวะที่ตลาดผันผวน',
    th_spkinfo: "วัฒนา ชื่นจิตรวงษา (ป้อม)<br>Full-Time Trader<br>เจ้าของFacebook 'Wattana Stock Page'<br>ผู้คร่ำหวอดในวงการตลาดทุน และ DW มากว่า 10 ปี",
    th_contactinfo: "วันเสาร์ที่ 24 กันยายน 2559<br>เวลา 09:00 - 12:00 น<br>สถานที่ Siam Motors Building (BTS สนามกีฬาแห่งชาติ",
    th_ep: "Ep 2: DW tools",
  },
  {
    playlistId: "PLOkzCdnS95xTS1EqkDqV4IIZdx4W8_N-c",
    index: "2",
    img: "dp-kpom.jpg",
    en_ep: "Ep 3: Consider DW Issuer",
    image: "https://i.ytimg.com/vi/lIVY4LncqpE/mqdefault.jpg",
    en_title: "งานสัมมนา 'เจาะลึก เคล็ดลับ DW' โดย คุณป้อม วัฒนา (Ep. 03)",
    en_text: 'รับฟังประสบการณ์ วิธีการเลือก DW และการทำกำไรในภาวะที่ตลาดผันผวน',
    en_spkinfo: "วัฒนา ชื่นจิตรวงษา (ป้อม)<br>Full-Time Trader<br>เจ้าของFacebook 'Wattana Stock Page'<br>ผู้คร่ำหวอดในวงการตลาดทุน และ DW มากว่า 10 ปี",
    en_contactinfo: "วันเสาร์ที่ 24 กันยายน 2559<br>เวลา 09:00 - 12:00 น<br>สถานที่ Siam Motors Building (BTS สนามกีฬาแห่งชาติ",
    th_title: "งานสัมมนา 'เจาะลึก เคล็ดลับ DW' โดย คุณป้อม วัฒนา (Ep. 03)",
    th_text: 'รับฟังประสบการณ์ วิธีการเลือก DW และการทำกำไรในภาวะที่ตลาดผันผวน',
    th_spkinfo: "วัฒนา ชื่นจิตรวงษา (ป้อม)<br>Full-Time Trader<br>เจ้าของFacebook 'Wattana Stock Page'<br>ผู้คร่ำหวอดในวงการตลาดทุน และ DW มากว่า 10 ปี",
    th_contactinfo: "วันเสาร์ที่ 24 กันยายน 2559<br>เวลา 09:00 - 12:00 น<br>สถานที่ Siam Motors Building (BTS สนามกีฬาแห่งชาติ",
    th_ep: "Ep 3: Consider DW Issuer",
  },
  {
    playlistId: "PLOkzCdnS95xTS1EqkDqV4IIZdx4W8_N-c",
    index: "3",
    img: "dp-kpom.jpg",
    en_ep: "Ep 4: Recommendation",
    image: "https://i.ytimg.com/vi/MlVhkmoE3dY/mqdefault.jpg",
    en_title: "งานสัมมนา 'เจาะลึก เคล็ดลับ DW' โดย คุณป้อม วัฒนาn (Ep. 04)",
    en_text: 'รับฟังประสบการณ์ วิธีการเลือก DW และการทำกำไรในภาวะที่ตลาดผันผวน',
    en_spkinfo: "วัฒนา ชื่นจิตรวงษา (ป้อม)<br>Full-Time Trader<br>เจ้าของFacebook 'Wattana Stock Page'<br>ผู้คร่ำหวอดในวงการตลาดทุน และ DW มากว่า 10 ปี",
    en_contactinfo: "วันเสาร์ที่ 24 กันยายน 2559<br>เวลา 09:00 - 12:00 น<br>สถานที่ Siam Motors Building (BTS สนามกีฬาแห่งชาติ",
    th_title: "งานสัมมนา 'เจาะลึก เคล็ดลับ DW' โดย คุณป้อม วัฒนา (Ep. 04)",
    th_text: 'รับฟังประสบการณ์ วิธีการเลือก DW และการทำกำไรในภาวะที่ตลาดผันผวน',
    th_spkinfo: "วัฒนา ชื่นจิตรวงษา (ป้อม)<br>Full-Time Trader<br>เจ้าของFacebook 'Wattana Stock Page'<br>ผู้คร่ำหวอดในวงการตลาดทุน และ DW มากว่า 10 ปี",
    th_contactinfo: "วันเสาร์ที่ 24 กันยายน 2559<br>เวลา 09:00 - 12:00 น<br>สถานที่ Siam Motors Building (BTS สนามกีฬาแห่งชาติ",
    th_ep: "Ep 4: Recommendation",
  },
  {
    playlistId: "PLOkzCdnS95xTS1EqkDqV4IIZdx4W8_N-c",
    index: "4",
    img: "dp-kpom.jpg",
    en_ep: "Ep 5: Misconceptions about DW",
    image: "https://i.ytimg.com/vi/BktPJrh7uMs/mqdefault.jpg",
    en_title: "งานสัมมนา 'เจาะลึก เคล็ดลับ DW' โดย คุณป้อม วัฒนา (Ep. 05)",
    en_text: 'รับฟังประสบการณ์ วิธีการเลือก DW และการทำกำไรในภาวะที่ตลาดผันผวน',
    en_spkinfo: "วัฒนา ชื่นจิตรวงษา (ป้อม)<br>Full-Time Trader<br>เจ้าของFacebook 'Wattana Stock Page'<br>ผู้คร่ำหวอดในวงการตลาดทุน และ DW มากว่า 10 ปี",
    en_contactinfo: "วันเสาร์ที่ 24 กันยายน 2559<br>เวลา 09:00 - 12:00 น<br>สถานที่ Siam Motors Building (BTS สนามกีฬาแห่งชาติ",
    th_title: "งานสัมมนา 'เจาะลึก เคล็ดลับ DW' โดย คุณป้อม วัฒนา (Ep. 05)",
    th_text: 'รับฟังประสบการณ์ วิธีการเลือก DW และการทำกำไรในภาวะที่ตลาดผันผวน',
    th_spkinfo: "วัฒนา ชื่นจิตรวงษา (ป้อม)<br>Full-Time Trader<br>เจ้าของFacebook 'Wattana Stock Page'<br>ผู้คร่ำหวอดในวงการตลาดทุน และ DW มากว่า 10 ปี",
    th_contactinfo: "วันเสาร์ที่ 24 กันยายน 2559<br>เวลา 09:00 - 12:00 น<br>สถานที่ Siam Motors Building (BTS สนามกีฬาแห่งชาติ",
    th_ep: "Ep 5: Misconceptions about DW",
  },
  {
    playlistId: "PLOkzCdnS95xR_4lZuCtlvMJoiazn3xgjP",
    index: "5",
    img: "dp-kvee.jpg",
    en_ep: "Ep 1: Overall",
    image: "https://i.ytimg.com/vi/75WFlwHTjLg/mqdefault.jpg",
    en_title: "งานสัมมนา Super Trader Season 3 โดย โค้ชวี ทีม Gold Roger (Ep. 01)",
    en_text: '',
    en_spkinfo: "โค้ชวี ทีม Gold Roger<br>โครงการ SuperTrader Season 3",
    en_contactinfo: "วันอาทิตย์ที่ 18 กันยายน 2559 <br>เวลา 09:00 - 12:00 น.<br>สถานที่ สมาคมธรรมศาสตร์",
    th_title: "งานสัมมนา Super Trader Season 3 โดย โค้ชวี ทีม Gold Roger (Ep. 01)",
    th_text: '',
    th_spkinfo: "โค้ชวี ทีม Gold Roger<br>โครงการ SuperTrader Season 3",
    th_contactinfo: "วันอาทิตย์ที่ 18 กันยายน 2559 <br>เวลา 09:00 - 12:00 น.<br>สถานที่ สมาคมธรรมศาสตร์",
    th_ep: "Ep 1: Overall",
  },
  {
    playlistId: "PLOkzCdnS95xR_4lZuCtlvMJoiazn3xgjP",
    index: "6",
    img: "dp-kvee.jpg",
    en_ep: "Ep 2: Why DW",
    image: "https://i.ytimg.com/vi/VIG9C87dH24/mqdefault.jpg",
    en_title: "งานสัมมนา Super Trader Season 3 โดย โค้ชวี ทีม Gold Roger (Ep. 02)",
    en_text: '',
    en_spkinfo: "โค้ชวี ทีม Gold Roger<br>โครงการ SuperTrader Season 3",
    en_contactinfo: "วันอาทิตย์ที่ 18 กันยายน 2559 <br>เวลา 09:00 - 12:00 น.<br>สถานที่ สมาคมธรรมศาสตร์",
    th_title: "งานสัมมนา Super Trader Season 3 โดย โค้ชวี ทีม Gold Roger (Ep. 02)",
    th_text: '',
    th_spkinfo: "โค้ชวี ทีม Gold Roger<br>โครงการ SuperTrader Season 3",
    th_contactinfo: "วันอาทิตย์ที่ 18 กันยายน 2559 <br>เวลา 09:00 - 12:00 น.<br>สถานที่ สมาคมธรรมศาสตร์",
    th_ep: "Ep 2: Why DW",
  },
  {
    playlistId: "PLOkzCdnS95xR_4lZuCtlvMJoiazn3xgjP",
    index: "7",
    img: "dp-kvee.jpg",
    en_ep: "Ep 3: How to choose DW",
    image: "https://i.ytimg.com/vi/-HJRBbVAVaw/mqdefault.jpg",
    en_title: "งานสัมมนา Super Trader Season 3 โดย โค้ชวี ทีม Gold Roger (Ep. 03)",
    en_text: '',
    en_spkinfo: "โค้ชวี ทีม Gold Roger<br>โครงการ SuperTrader Season 3",
    en_contactinfo: "วันอาทิตย์ที่ 18 กันยายน 2559 <br>เวลา 09:00 - 12:00 น.<br>สถานที่ สมาคมธรรมศาสตร์",
    th_title: "งานสัมมนา Super Trader Season 3 โดย โค้ชวี ทีม Gold Roger (Ep. 03)",
    th_text: '',
    th_spkinfo: "โค้ชวี ทีม Gold Roger<br>โครงการ SuperTrader Season 3",
    th_contactinfo: "วันอาทิตย์ที่ 18 กันยายน 2559 <br>เวลา 09:00 - 12:00 น.<br>สถานที่ สมาคมธรรมศาสตร์",
    th_ep: "Ep 3: How to choose DW",
  },
  {
    playlistId: "PLOkzCdnS95xR_4lZuCtlvMJoiazn3xgjP",
    index: "8",
    img: "dp-kvee.jpg",
    en_ep: "Ep 4: 5 ways for trading DW",
    image: "https://i.ytimg.com/vi/pHDuNB9caqg/mqdefault.jpg",
    en_title: "งานสัมมนา Super Trader Season 3 โดย โค้ชวี ทีม Gold Roger (Ep. 04)",
    en_text: '',
    en_spkinfo: "โค้ชวี ทีม Gold Roger<br>โครงการ SuperTrader Season 3",
    en_contactinfo: "วันอาทิตย์ที่ 18 กันยายน 2559 <br>เวลา 09:00 - 12:00 น.<br>สถานที่ สมาคมธรรมศาสตร์",
    th_title: "งานสัมมนา Super Trader Season 3 โดย โค้ชวี ทีม Gold Roger (Ep. 04)",
    th_text: '',
    th_spkinfo: "โค้ชวี ทีม Gold Roger<br>โครงการ SuperTrader Season 3",
    th_contactinfo: "วันอาทิตย์ที่ 18 กันยายน 2559 <br>เวลา 09:00 - 12:00 น.<br>สถานที่ สมาคมธรรมศาสตร์",
    th_ep: "Ep 4: 5 ways for trading DW",
  },
  {
    playlistId: "PLOkzCdnS95xTKCgNSigoHsH5Q987--t3p",
    index: "9",
    img: "dp-kgung.jpg",
    en_ep: "Ep 1: Basic of DW",
    image: "https://i.ytimg.com/vi/tjXM0CDoqTQ/mqdefault.jpg",
    en_title: "งานสัมมนา Super Trader Season 3 โดย โค้ชกั้ง ทีม Wind Ranger (Ep. 01)",
    en_text: '',
    en_spkinfo: "โค้ชกั้ง ทีม Wind Ranger<br>โครงการ SuperTrader Season 3",
    en_contactinfo: "วันอาทิตย์ที่ 18 กันยายน 2559<br>เวลา 13:00 - 17:00 น.<br>สถานที่ สมาคมธรรมศาสตร์",
    th_title: "งานสัมมนา Super Trader Season 3 โดย โค้ชกั้ง ทีม Wind Ranger (Ep. 01)",
    th_text: '',
    th_spkinfo: "โค้ชกั้ง ทีม Wind Ranger<br>โครงการ SuperTrader Season 3",
    th_contactinfo: "วันอาทิตย์ที่ 18 กันยายน 2559<br>เวลา 13:00 - 17:00 น.<br>สถานที่ สมาคมธรรมศาสตร์",
    th_ep: "Ep 1: Basic of DW",
  },
  {
    playlistId: "PLOkzCdnS95xTKCgNSigoHsH5Q987--t3p",
    index: "10",
    img: "dp-kgung.jpg",
    en_ep: "Ep 2: SET50 DW",
    image: "https://i.ytimg.com/vi/tBZGIuflYl4/mqdefault.jpg",
    en_title: "งานสัมมนา Super Trader Season 3 โดย โค้ชกั้ง ทีม Wind Ranger (Ep. 02)",
    en_text: '',
    en_spkinfo: "โค้ชกั้ง ทีม Wind Ranger<br>โครงการ SuperTrader Season 3",
    en_contactinfo: "วันอาทิตย์ที่ 18 กันยายน 2559<br>เวลา 13:00 - 17:00 น.<br>สถานที่ สมาคมธรรมศาสตร์",
    th_title: "งานสัมมนา Super Trader Season 3 โดย โค้ชกั้ง ทีม Wind Ranger (Ep. 02)",
    th_text: '',
    th_spkinfo: "โค้ชกั้ง ทีม Wind Ranger<br>โครงการ SuperTrader Season 3",
    th_contactinfo: "วันอาทิตย์ที่ 18 กันยายน 2559<br>เวลา 13:00 - 17:00 น.<br>สถานที่ สมาคมธรรมศาสตร์",
    th_ep: "Ep 2: SET50 DW",
  },
  {
    playlistId: "PLOkzCdnS95xTKCgNSigoHsH5Q987--t3p",
    index: "11",
    img: "dp-kgung.jpg",
    en_ep: "Ep 3: Tools for trading DW",
    image: "https://i.ytimg.com/vi/iQzXMbATMzQ/mqdefault.jpg",
    en_title: "งานสัมมนา Super Trader Season 3 โดย โค้ชกั้ง ทีม Wind Ranger (Ep. 03)",
    en_text: '',
    en_spkinfo: "โค้ชกั้ง ทีม Wind Ranger<br>โครงการ SuperTrader Season 3",
    en_contactinfo: "วันอาทิตย์ที่ 18 กันยายน 2559<br>เวลา 13:00 - 17:00 น.<br>สถานที่ สมาคมธรรมศาสตร์",
    th_title: "งานสัมมนา Super Trader Season 3 โดย โค้ชกั้ง ทีม Wind Ranger (Ep. 03)",
    th_text: '',
    th_spkinfo: "โค้ชกั้ง ทีม Wind Ranger<br>โครงการ SuperTrader Season 3",
    th_contactinfo: "วันอาทิตย์ที่ 18 กันยายน 2559<br>เวลา 13:00 - 17:00 น.<br>สถานที่ สมาคมธรรมศาสตร์",
    th_ep: "Ep 3: Tools for trading DW",
  },
  {
    playlistId: "PLOkzCdnS95xTKCgNSigoHsH5Q987--t3p",
    index: "12",
    img: "dp-kgung.jpg",
    en_ep: "Ep 4: Mind Set",
    image: "https://i.ytimg.com/vi/_EXEiFzkqao/mqdefault.jpg",
    en_title: "งานสัมมนา Super Trader Season 3 โดย โค้ชกั้ง ทีม Wind Ranger (Ep. 04)",
    en_text: '',
    en_spkinfo: "โค้ชกั้ง ทีม Wind Ranger<br>โครงการ SuperTrader Season 3",
    en_contactinfo: "วันอาทิตย์ที่ 18 กันยายน 2559<br>เวลา 13:00 - 17:00 น.<br>สถานที่ สมาคมธรรมศาสตร์",
    th_title: "งานสัมมนา Super Trader Season 3 โดย โค้ชกั้ง ทีม Wind Ranger (Ep. 04)",
    th_text: '',
    th_spkinfo: "โค้ชกั้ง ทีม Wind Ranger<br>โครงการ SuperTrader Season 3",
    th_contactinfo: "วันอาทิตย์ที่ 18 กันยายน 2559<br>เวลา 13:00 - 17:00 น.<br>สถานที่ สมาคมธรรมศาสตร์",
    th_ep: "Ep 4: Mind Set",
  },
  {
    playlistId: "PLOkzCdnS95xTKCgNSigoHsH5Q987--t3p",
    index: "13",
    img: "dp-kgung.jpg",
    en_ep: "Ep 5: Technical Chart",
    image: "https://i.ytimg.com/vi/SV6qs_ZHVCs/mqdefault.jpg",
    en_title: "งานสัมมนา Super Trader Season 3 โดย โค้ชกั้ง ทีม Wind Ranger (Ep. 05)",
    en_text: '',
    en_spkinfo: "โค้ชกั้ง ทีม Wind Ranger<br>โครงการ SuperTrader Season 3",
    en_contactinfo: "วันอาทิตย์ที่ 18 กันยายน 2559<br>เวลา 13:00 - 17:00 น.<br>สถานที่ สมาคมธรรมศาสตร์",
    th_title: "งานสัมมนา Super Trader Season 3 โดย โค้ชกั้ง ทีม Wind Ranger (Ep. 05)",
    th_text: '',
    th_spkinfo: "โค้ชกั้ง ทีม Wind Ranger<br>โครงการ SuperTrader Season 3",
    th_contactinfo: "วันอาทิตย์ที่ 18 กันยายน 2559<br>เวลา 13:00 - 17:00 น.<br>สถานที่ สมาคมธรรมศาสตร์",
    th_ep: "Ep 5: Technical Chart",
  },
  {
    playlistId: "PLOkzCdnS95xTKCgNSigoHsH5Q987--t3p",
    index: "14",
    img: "dp-kgung.jpg",
    en_ep: "Ep 6: How to Swing trade",
    image: "https://i.ytimg.com/vi/pQXmkF1xQXg/mqdefault.jpg",
    en_title: "งานสัมมนา Super Trader Season 3 โดย โค้ชกั้ง ทีม Wind Ranger (Ep. 06)",
    en_text: '',
    en_spkinfo: "โค้ชกั้ง ทีม Wind Ranger<br>โครงการ SuperTrader Season 3",
    en_contactinfo: "วันอาทิตย์ที่ 18 กันยายน 2559<br>เวลา 13:00 - 17:00 น.<br>สถานที่ สมาคมธรรมศาสตร์",
    th_title: "งานสัมมนา Super Trader Season 3 โดย โค้ชกั้ง ทีม Wind Ranger (Ep. 06)",
    th_text: '',
    th_spkinfo: "โค้ชกั้ง ทีม Wind Ranger<br>โครงการ SuperTrader Season 3",
    th_contactinfo: "วันอาทิตย์ที่ 18 กันยายน 2559<br>เวลา 13:00 - 17:00 น.<br>สถานที่ สมาคมธรรมศาสตร์",
    th_ep: "Ep 6: How to Swing trade",
  },
  {
    playlistId: "PLOkzCdnS95xR0dazX8WStrYtrBP0VOhSd",
    index: "15",
    img: "dp-kice.jpg",
    en_ep: "Ep 1: Candlestick",
    image: "https://i.ytimg.com/vi/QOrTdxPeluo/mqdefault.jpg",
    en_title: "งานสัมมนา 'เทคนิคเล่นสั้น ฟันกำไร' โดย คุณไอซ์ อุทัยวรรณ (Ep. 01)",
    en_text: '',
    en_spkinfo: "คุณ อุทัยวรรณ ชื่นชม ผู้เขียนหนังสือขายดี 'Trend Line ง่ายจัง'   วิทยากรผู้สอนกราฟเทคนิคให้กับบรรดานักลงทุนของโบรกเกอร์ชื่อดัง มากมาย และคอลัมนิสต์สาวยอดนิยม ประจำกลุ่ม FACEBOOK 'IRS เดี่ยว Technical'",
    en_contactinfo: "วันเสาร์ที่ 3 กันยายน 2559 <br>เวลา 09:00 - 12:00 น.<br>สถานที่ สมาคมธรรมศาสตร์",
    th_title: "งานสัมมนา 'เทคนิคเล่นสั้น ฟันกำไร' โดย คุณไอซ์ อุทัยวรรณ (Ep. 01)",
    th_text: '',
    th_spkinfo: "คุณ อุทัยวรรณ ชื่นชม ผู้เขียนหนังสือขายดี 'Trend Line ง่ายจัง'   วิทยากรผู้สอนกราฟเทคนิคให้กับบรรดานักลงทุนของโบรกเกอร์ชื่อดัง มากมาย และคอลัมนิสต์สาวยอดนิยม ประจำกลุ่ม FACEBOOK 'IRS เดี่ยว Technical'",
    th_contactinfo: "วันเสาร์ที่ 3 กันยายน 2559 <br>เวลา 09:00 - 12:00 น.<br>สถานที่ สมาคมธรรมศาสตร์",
    th_ep: "Ep 1: Candlestick",
  },
  {
    playlistId: "PLOkzCdnS95xR0dazX8WStrYtrBP0VOhSd",
    index: "16",
    img: "dp-kice.jpg",
    en_ep: "Ep 2: Time frame",
    image: "https://i.ytimg.com/vi/8KC41_-t650/mqdefault.jpg",
    en_title: "งานสัมมนา 'เทคนิคเล่นสั้น ฟันกำไร' โดย คุณไอซ์ อุทัยวรรณ (Ep. 02)",
    en_text: '',
    en_spkinfo: "คุณ อุทัยวรรณ ชื่นชม ผู้เขียนหนังสือขายดี 'Trend Line ง่ายจัง'   วิทยากรผู้สอนกราฟเทคนิคให้กับบรรดานักลงทุนของโบรกเกอร์ชื่อดัง มากมาย และคอลัมนิสต์สาวยอดนิยม ประจำกลุ่ม FACEBOOK 'IRS เดี่ยว Technical'",
    en_contactinfo: "วันเสาร์ที่ 3 กันยายน 2559 <br>เวลา 09:00 - 12:00 น.<br>สถานที่ สมาคมธรรมศาสตร์",
    th_title: "งานสัมมนา 'เทคนิคเล่นสั้น ฟันกำไร' โดย คุณไอซ์ อุทัยวรรณ (Ep. 02)",
    th_text: '',
    th_spkinfo: "คุณ อุทัยวรรณ ชื่นชม ผู้เขียนหนังสือขายดี 'Trend Line ง่ายจัง'   วิทยากรผู้สอนกราฟเทคนิคให้กับบรรดานักลงทุนของโบรกเกอร์ชื่อดัง มากมาย และคอลัมนิสต์สาวยอดนิยม ประจำกลุ่ม FACEBOOK 'IRS เดี่ยว Technical'",
    th_contactinfo: "วันเสาร์ที่ 3 กันยายน 2559 <br>เวลา 09:00 - 12:00 น.<br>สถานที่ สมาคมธรรมศาสตร์",
    th_ep: "Ep 2: Time frame",
  },
  {
    playlistId: "PLOkzCdnS95xR0dazX8WStrYtrBP0VOhSd",
    index: "17",
    img: "dp-kice.jpg",
    en_ep: "Ep 3: Trend line",
    image: "https://i.ytimg.com/vi/DZmJ0QFd5Qc/mqdefault.jpg",
    en_title: "งานสัมมนา 'เทคนิคเล่นสั้น ฟันกำไร' โดย คุณไอซ์ อุทัยวรรณ (Ep. 03)",
    en_text: '',
    en_spkinfo: "คุณ อุทัยวรรณ ชื่นชม ผู้เขียนหนังสือขายดี 'Trend Line ง่ายจัง'   วิทยากรผู้สอนกราฟเทคนิคให้กับบรรดานักลงทุนของโบรกเกอร์ชื่อดัง มากมาย และคอลัมนิสต์สาวยอดนิยม ประจำกลุ่ม FACEBOOK 'IRS เดี่ยว Technical'",
    en_contactinfo: "วันเสาร์ที่ 3 กันยายน 2559 <br>เวลา 09:00 - 12:00 น.<br>สถานที่ สมาคมธรรมศาสตร์",
    th_title: "งานสัมมนา 'เทคนิคเล่นสั้น ฟันกำไร' โดย คุณไอซ์ อุทัยวรรณ (Ep. 03)",
    th_text: '',
    th_spkinfo: "คุณ อุทัยวรรณ ชื่นชม ผู้เขียนหนังสือขายดี 'Trend Line ง่ายจัง'   วิทยากรผู้สอนกราฟเทคนิคให้กับบรรดานักลงทุนของโบรกเกอร์ชื่อดัง มากมาย และคอลัมนิสต์สาวยอดนิยม ประจำกลุ่ม FACEBOOK 'IRS เดี่ยว Technical'",
    th_contactinfo: "วันเสาร์ที่ 3 กันยายน 2559 <br>เวลา 09:00 - 12:00 น.<br>สถานที่ สมาคมธรรมศาสตร์",
    th_ep: "Ep 3: Trend line",
  },
  {
    playlistId: "PLOkzCdnS95xR0dazX8WStrYtrBP0VOhSd",
    index: "18",
    img: "dp-kice.jpg",
    en_ep: "Ep 4: Price pattern",
    image: "https://i.ytimg.com/vi/MzkQ9hFY4Gw/mqdefault.jpg",
    en_title: "งานสัมมนา 'เทคนิคเล่นสั้น ฟันกำไร' โดย คุณไอซ์ อุทัยวรรณ (Ep. 04)",
    en_text: '',
    en_spkinfo: "คุณ อุทัยวรรณ ชื่นชม ผู้เขียนหนังสือขายดี 'Trend Line ง่ายจัง'   วิทยากรผู้สอนกราฟเทคนิคให้กับบรรดานักลงทุนของโบรกเกอร์ชื่อดัง มากมาย และคอลัมนิสต์สาวยอดนิยม ประจำกลุ่ม FACEBOOK 'IRS เดี่ยว Technical'",
    en_contactinfo: "วันเสาร์ที่ 3 กันยายน 2559 <br>เวลา 09:00 - 12:00 น.<br>สถานที่ สมาคมธรรมศาสตร์",
    th_title: "งานสัมมนา 'เทคนิคเล่นสั้น ฟันกำไร' โดย คุณไอซ์ อุทัยวรรณ (Ep. 04)",
    th_text: '',
    th_spkinfo: "คุณ อุทัยวรรณ ชื่นชม ผู้เขียนหนังสือขายดี 'Trend Line ง่ายจัง'   วิทยากรผู้สอนกราฟเทคนิคให้กับบรรดานักลงทุนของโบรกเกอร์ชื่อดัง มากมาย และคอลัมนิสต์สาวยอดนิยม ประจำกลุ่ม FACEBOOK 'IRS เดี่ยว Technical'",
    th_contactinfo: "วันเสาร์ที่ 3 กันยายน 2559 <br>เวลา 09:00 - 12:00 น.<br>สถานที่ สมาคมธรรมศาสตร์",
    th_ep: "Ep 4: Price pattern",
  },
  {
    playlistId: "PLOkzCdnS95xR0dazX8WStrYtrBP0VOhSd",
    index: "19",
    img: "dp-kice.jpg",
    en_ep: "Ep 5: Fibonacci",
    image: "https://i.ytimg.com/vi/reAcNRkjj7k/mqdefault.jpg",
    en_title: "งานสัมมนา 'เทคนิคเล่นสั้น ฟันกำไร' โดย คุณไอซ์ อุทัยวรรณ (Ep. 05)",
    en_text: '',
    en_spkinfo: "คุณ อุทัยวรรณ ชื่นชม ผู้เขียนหนังสือขายดี 'Trend Line ง่ายจัง'   วิทยากรผู้สอนกราฟเทคนิคให้กับบรรดานักลงทุนของโบรกเกอร์ชื่อดัง มากมาย และคอลัมนิสต์สาวยอดนิยม ประจำกลุ่ม FACEBOOK 'IRS เดี่ยว Technical'",
    en_contactinfo: "วันเสาร์ที่ 3 กันยายน 2559 <br>เวลา 09:00 - 12:00 น.<br>สถานที่ สมาคมธรรมศาสตร์",
    th_title: "งานสัมมนา 'เทคนิคเล่นสั้น ฟันกำไร' โดย คุณไอซ์ อุทัยวรรณ (Ep. 05)",
    th_text: '',
    th_spkinfo: "คุณ อุทัยวรรณ ชื่นชม ผู้เขียนหนังสือขายดี 'Trend Line ง่ายจัง'   วิทยากรผู้สอนกราฟเทคนิคให้กับบรรดานักลงทุนของโบรกเกอร์ชื่อดัง มากมาย และคอลัมนิสต์สาวยอดนิยม ประจำกลุ่ม FACEBOOK 'IRS เดี่ยว Technical'",
    th_contactinfo: "วันเสาร์ที่ 3 กันยายน 2559 <br>เวลา 09:00 - 12:00 น.<br>สถานที่ สมาคมธรรมศาสตร์",
    th_ep: "Ep 5: Fibonacci",
  },
  {
    playlistId: "PLOkzCdnS95xR0dazX8WStrYtrBP0VOhSd",
    index: "20",
    img: "dp-kice.jpg",
    en_ep: "Ep 6: Moving average",
    image: "https://i.ytimg.com/vi/qpNqmJBocuI/mqdefault.jpg",
    en_title: "งานสัมมนา 'เทคนิคเล่นสั้น ฟันกำไร' โดย คุณไอซ์ อุทัยวรรณ (Ep. 06)",
    en_text: '',
    en_spkinfo: "คุณ อุทัยวรรณ ชื่นชม ผู้เขียนหนังสือขายดี 'Trend Line ง่ายจัง'   วิทยากรผู้สอนกราฟเทคนิคให้กับบรรดานักลงทุนของโบรกเกอร์ชื่อดัง มากมาย และคอลัมนิสต์สาวยอดนิยม ประจำกลุ่ม FACEBOOK 'IRS เดี่ยว Technical'",
    en_contactinfo: "วันเสาร์ที่ 3 กันยายน 2559 <br>เวลา 09:00 - 12:00 น.<br>สถานที่ สมาคมธรรมศาสตร์",
    th_title: "งานสัมมนา 'เทคนิคเล่นสั้น ฟันกำไร' โดย คุณไอซ์ อุทัยวรรณ (Ep. 06)",
    th_text: '',
    th_spkinfo: "คุณ อุทัยวรรณ ชื่นชม ผู้เขียนหนังสือขายดี 'Trend Line ง่ายจัง'   วิทยากรผู้สอนกราฟเทคนิคให้กับบรรดานักลงทุนของโบรกเกอร์ชื่อดัง มากมาย และคอลัมนิสต์สาวยอดนิยม ประจำกลุ่ม FACEBOOK 'IRS เดี่ยว Technical'",
    th_contactinfo: "วันเสาร์ที่ 3 กันยายน 2559 <br>เวลา 09:00 - 12:00 น.<br>สถานที่ สมาคมธรรมศาสตร์",
    th_ep: "Ep 6: Moving average",
  },
  {
    playlistId: "PLOkzCdnS95xR0dazX8WStrYtrBP0VOhSd",
    index: "21",
    img: "dp-kice.jpg",
    en_ep: "Ep 7: Pivot point",
    image: "https://i.ytimg.com/vi/2tyD0eJV_U8/mqdefault.jpg",
    en_title: "งานสัมมนา 'เทคนิคเล่นสั้น ฟันกำไร' โดย คุณไอซ์ อุทัยวรรณ (Ep. 07)",
    en_text: '',
    en_spkinfo: "คุณ อุทัยวรรณ ชื่นชม ผู้เขียนหนังสือขายดี 'Trend Line ง่ายจัง'   วิทยากรผู้สอนกราฟเทคนิคให้กับบรรดานักลงทุนของโบรกเกอร์ชื่อดัง มากมาย และคอลัมนิสต์สาวยอดนิยม ประจำกลุ่ม FACEBOOK 'IRS เดี่ยว Technical'",
    en_contactinfo: "วันเสาร์ที่ 3 กันยายน 2559 <br>เวลา 09:00 - 12:00 น.<br>สถานที่ สมาคมธรรมศาสตร์",
    th_title: "งานสัมมนา 'เทคนิคเล่นสั้น ฟันกำไร' โดย คุณไอซ์ อุทัยวรรณ (Ep. 07)",
    th_text: '',
    th_spkinfo: "คุณ อุทัยวรรณ ชื่นชม ผู้เขียนหนังสือขายดี 'Trend Line ง่ายจัง'   วิทยากรผู้สอนกราฟเทคนิคให้กับบรรดานักลงทุนของโบรกเกอร์ชื่อดัง มากมาย และคอลัมนิสต์สาวยอดนิยม ประจำกลุ่ม FACEBOOK 'IRS เดี่ยว Technical'",
    th_contactinfo: "วันเสาร์ที่ 3 กันยายน 2559 <br>เวลา 09:00 - 12:00 น.<br>สถานที่ สมาคมธรรมศาสตร์",
    th_ep: "Ep 7: Pivot point",
  },
  {
    playlistId: "PLOkzCdnS95xSSEV8gUBO0eBbNDYixuFfs",
    index: "22",
    img: "video-Juti-1.jpg",
    en_ep: "SET50 Technical ตอน เริ่มต้นเทรด by Juti ตอนที่ 1",
    image: "https://i.ytimg.com/vi/VoJpsvzyc5o/mqdefault.jpg",
    en_title: "SET50 Technical ตอน เริ่มต้นเทรด by Juti ตอนที่ 1 (18/11/17)",
    en_text: '',
    en_spkinfo: "คุณ อุทัยวรรณ ชื่นชม ผู้เขียนหนังสือขายดี 'Trend Line ง่ายจัง'   วิทยากรผู้สอนกราฟเทคนิคให้กับบรรดานักลงทุนของโบรกเกอร์ชื่อดัง มากมาย และคอลัมนิสต์สาวยอดนิยม ประจำกลุ่ม FACEBOOK 'IRS เดี่ยว Technical'",
    en_contactinfo: "วันเสาร์ที่ 3 กันยายน 2559 <br>เวลา 09:00 - 12:00 น.<br>สถานที่ สมาคมธรรมศาสตร์",
    th_title: "SET50 Technical ตอน เริ่มต้นเทรด by Juti ตอนที่ 1 (18/11/17)",
    th_text: '',
    th_spkinfo: "คุณ อุทัยวรรณ ชื่นชม ผู้เขียนหนังสือขายดี 'Trend Line ง่ายจัง'   วิทยากรผู้สอนกราฟเทคนิคให้กับบรรดานักลงทุนของโบรกเกอร์ชื่อดัง มากมาย และคอลัมนิสต์สาวยอดนิยม ประจำกลุ่ม FACEBOOK 'IRS เดี่ยว Technical'",
    th_contactinfo: "วันเสาร์ที่ 3 กันยายน 2559 <br>เวลา 09:00 - 12:00 น.<br>สถานที่ สมาคมธรรมศาสตร์",
    th_ep: "SET50 Technical ตอน เริ่มต้นเทรด by Juti ตอนที่ 1",
  },
  {
    playlistId: "PLOkzCdnS95xSSEV8gUBO0eBbNDYixuFfs",
    index: "23",
    img: "video-Juti-1.jpg",
    en_ep: "SET50 Technical ตอน เริ่มต้นเทรด by Juti ตอนที่ 2",
    image: "https://i.ytimg.com/vi/d4kADr8i3wE/mqdefault.jpg",
    en_title: "SET50 Technical ตอน เริ่มต้นเทรด by Juti ตอนที่ 2 (18/11/17)",
    en_text: '',
    en_spkinfo: "คุณ อุทัยวรรณ ชื่นชม ผู้เขียนหนังสือขายดี 'Trend Line ง่ายจัง'   วิทยากรผู้สอนกราฟเทคนิคให้กับบรรดานักลงทุนของโบรกเกอร์ชื่อดัง มากมาย และคอลัมนิสต์สาวยอดนิยม ประจำกลุ่ม FACEBOOK 'IRS เดี่ยว Technical'",
    en_contactinfo: "วันเสาร์ที่ 3 กันยายน 2559 <br>เวลา 09:00 - 12:00 น.<br>สถานที่ สมาคมธรรมศาสตร์",
    th_title: "SET50 Technical ตอน เริ่มต้นเทรด by Juti ตอนที่ 2 (18/11/17)",
    th_text: '',
    th_spkinfo: "คุณ อุทัยวรรณ ชื่นชม ผู้เขียนหนังสือขายดี 'Trend Line ง่ายจัง'   วิทยากรผู้สอนกราฟเทคนิคให้กับบรรดานักลงทุนของโบรกเกอร์ชื่อดัง มากมาย และคอลัมนิสต์สาวยอดนิยม ประจำกลุ่ม FACEBOOK 'IRS เดี่ยว Technical'",
    th_contactinfo: "วันเสาร์ที่ 3 กันยายน 2559 <br>เวลา 09:00 - 12:00 น.<br>สถานที่ สมาคมธรรมศาสตร์",
    th_ep: "SET50 Technical ตอน เริ่มต้นเทรด by Juti ตอนที่ 2",
  },
  {
    playlistId: "PLOkzCdnS95xSSEV8gUBO0eBbNDYixuFfs",
    index: "24",
    img: "video-Juti-1.jpg",
    en_ep: "SET50 Technical ตอน เริ่มต้นเทรด by Juti ตอนที่ 3",
    image: "https://i.ytimg.com/vi/jd8363J2zHQ/mqdefault.jpg",
    en_title: "SET50 Technical ตอน เริ่มต้นเทรด by Juti ตอนที่ 3 (18/11/17)",
    en_text: '',
    en_spkinfo: "คุณ อุทัยวรรณ ชื่นชม ผู้เขียนหนังสือขายดี 'Trend Line ง่ายจัง'   วิทยากรผู้สอนกราฟเทคนิคให้กับบรรดานักลงทุนของโบรกเกอร์ชื่อดัง มากมาย และคอลัมนิสต์สาวยอดนิยม ประจำกลุ่ม FACEBOOK 'IRS เดี่ยว Technical'",
    en_contactinfo: "วันเสาร์ที่ 3 กันยายน 2559 <br>เวลา 09:00 - 12:00 น.<br>สถานที่ สมาคมธรรมศาสตร์",
    th_title: "SET50 Technical ตอน เริ่มต้นเทรด by Juti ตอนที่ 3 (18/11/17)",
    th_text: '',
    th_spkinfo: "คุณ อุทัยวรรณ ชื่นชม ผู้เขียนหนังสือขายดี 'Trend Line ง่ายจัง'   วิทยากรผู้สอนกราฟเทคนิคให้กับบรรดานักลงทุนของโบรกเกอร์ชื่อดัง มากมาย และคอลัมนิสต์สาวยอดนิยม ประจำกลุ่ม FACEBOOK 'IRS เดี่ยว Technical'",
    th_contactinfo: "วันเสาร์ที่ 3 กันยายน 2559 <br>เวลา 09:00 - 12:00 น.<br>สถานที่ สมาคมธรรมศาสตร์",
    th_ep: "SET50 Technical ตอน เริ่มต้นเทรด by Juti ตอนที่ 3",
  },
  {
    playlistId: "PLOkzCdnS95xSSEV8gUBO0eBbNDYixuFfs",
    index: "25",
    img: "video-Juti-1.jpg",
    en_ep: "SET50 Technical ตอน เริ่มต้นเทรด by Juti ตอนที่ 4",
    image: "https://i.ytimg.com/vi/fEO9hjMWkug/mqdefault.jpg",
    en_title: "SET50 Technical ตอน เริ่มต้นเทรด by Juti ตอนที่ 4 (18/11/17)",
    en_text: '',
    en_spkinfo: "คุณ อุทัยวรรณ ชื่นชม ผู้เขียนหนังสือขายดี 'Trend Line ง่ายจัง'   วิทยากรผู้สอนกราฟเทคนิคให้กับบรรดานักลงทุนของโบรกเกอร์ชื่อดัง มากมาย และคอลัมนิสต์สาวยอดนิยม ประจำกลุ่ม FACEBOOK 'IRS เดี่ยว Technical'",
    en_contactinfo: "วันเสาร์ที่ 3 กันยายน 2559 <br>เวลา 09:00 - 12:00 น.<br>สถานที่ สมาคมธรรมศาสตร์",
    th_title: "SET50 Technical ตอน เริ่มต้นเทรด by Juti ตอนที่ 4 (18/11/17)",
    th_text: '',
    th_spkinfo: "คุณ อุทัยวรรณ ชื่นชม ผู้เขียนหนังสือขายดี 'Trend Line ง่ายจัง'   วิทยากรผู้สอนกราฟเทคนิคให้กับบรรดานักลงทุนของโบรกเกอร์ชื่อดัง มากมาย และคอลัมนิสต์สาวยอดนิยม ประจำกลุ่ม FACEBOOK 'IRS เดี่ยว Technical'",
    th_contactinfo: "วันเสาร์ที่ 3 กันยายน 2559 <br>เวลา 09:00 - 12:00 น.<br>สถานที่ สมาคมธรรมศาสตร์",
    th_ep: "SET50 Technical ตอน เริ่มต้นเทรด by Juti ตอนที่ 4",
  },
  {
    playlistId: "PLOkzCdnS95xSXsB7H3mo1x7tarUzos1bZ",
    index: "26",
    img: "video-Juti-1.jpg",
    en_ep: "SET50 Technical ตอน ติวเข้ม by Juti ตอนที่ 1",
    image: "https://i.ytimg.com/vi/xiVtRYX-z_w/mqdefault.jpg",
    en_title: "SET50 Technical ตอน ติวเข้ม by Juti ตอนที่ 1 (17/12/17)",
    en_text: '',
    en_spkinfo: "คุณ อุทัยวรรณ ชื่นชม ผู้เขียนหนังสือขายดี 'Trend Line ง่ายจัง'   วิทยากรผู้สอนกราฟเทคนิคให้กับบรรดานักลงทุนของโบรกเกอร์ชื่อดัง มากมาย และคอลัมนิสต์สาวยอดนิยม ประจำกลุ่ม FACEBOOK 'IRS เดี่ยว Technical'",
    en_contactinfo: "วันเสาร์ที่ 3 กันยายน 2559 <br>เวลา 09:00 - 12:00 น.<br>สถานที่ สมาคมธรรมศาสตร์",
    th_title: "SET50 Technical ตอน ติวเข้ม by Juti ตอนที่ 1 (17/12/17)",
    th_text: '',
    th_spkinfo: "คุณ อุทัยวรรณ ชื่นชม ผู้เขียนหนังสือขายดี 'Trend Line ง่ายจัง'   วิทยากรผู้สอนกราฟเทคนิคให้กับบรรดานักลงทุนของโบรกเกอร์ชื่อดัง มากมาย และคอลัมนิสต์สาวยอดนิยม ประจำกลุ่ม FACEBOOK 'IRS เดี่ยว Technical'",
    th_contactinfo: "วันเสาร์ที่ 3 กันยายน 2559 <br>เวลา 09:00 - 12:00 น.<br>สถานที่ สมาคมธรรมศาสตร์",
    th_ep: "SET50 Technical ตอน ติวเข้ม by Juti ตอนที่ 1",
  },
  {
    playlistId: "PLOkzCdnS95xSXsB7H3mo1x7tarUzos1bZ",
    index: "27",
    img: "video-Juti-1.jpg",
    en_ep: "SET50 Technical ตอน ติวเข้ม by Juti ตอนที่ 2",
    image: "https://i.ytimg.com/vi/mxPXo9bD8Gw/mqdefault.jpg",
    en_title: "SET50 Technical ตอน ติวเข้ม by Juti ตอนที่ 2 (17/12/17)",
    en_text: '',
    en_spkinfo: "คุณ อุทัยวรรณ ชื่นชม ผู้เขียนหนังสือขายดี 'Trend Line ง่ายจัง'   วิทยากรผู้สอนกราฟเทคนิคให้กับบรรดานักลงทุนของโบรกเกอร์ชื่อดัง มากมาย และคอลัมนิสต์สาวยอดนิยม ประจำกลุ่ม FACEBOOK 'IRS เดี่ยว Technical'",
    en_contactinfo: "วันเสาร์ที่ 3 กันยายน 2559 <br>เวลา 09:00 - 12:00 น.<br>สถานที่ สมาคมธรรมศาสตร์",
    th_title: "SET50 Technical ตอน ติวเข้ม by Juti ตอนที่ 2 (17/12/17)",
    th_text: '',
    th_spkinfo: "คุณ อุทัยวรรณ ชื่นชม ผู้เขียนหนังสือขายดี 'Trend Line ง่ายจัง'   วิทยากรผู้สอนกราฟเทคนิคให้กับบรรดานักลงทุนของโบรกเกอร์ชื่อดัง มากมาย และคอลัมนิสต์สาวยอดนิยม ประจำกลุ่ม FACEBOOK 'IRS เดี่ยว Technical'",
    th_contactinfo: "วันเสาร์ที่ 3 กันยายน 2559 <br>เวลา 09:00 - 12:00 น.<br>สถานที่ สมาคมธรรมศาสตร์",
    th_ep: "SET50 Technical ตอน ติวเข้ม by Juti ตอนที่ 2",
  },
  {
    playlistId: "PLOkzCdnS95xSXsB7H3mo1x7tarUzos1bZ",
    index: "28",
    img: "video-Juti-1.jpg",
    en_ep: "SET50 Technical ตอน ติวเข้ม by Juti ตอนที่ 3",
    image: "https://i.ytimg.com/vi/Q5DAtFQsrFE/mqdefault.jpg",
    en_title: "SET50 Technical ตอน ติวเข้ม by Juti ตอนที่ 3 (17/12/17)",
    en_text: '',
    en_spkinfo: "คุณ อุทัยวรรณ ชื่นชม ผู้เขียนหนังสือขายดี 'Trend Line ง่ายจัง'   วิทยากรผู้สอนกราฟเทคนิคให้กับบรรดานักลงทุนของโบรกเกอร์ชื่อดัง มากมาย และคอลัมนิสต์สาวยอดนิยม ประจำกลุ่ม FACEBOOK 'IRS เดี่ยว Technical'",
    en_contactinfo: "วันเสาร์ที่ 3 กันยายน 2559 <br>เวลา 09:00 - 12:00 น.<br>สถานที่ สมาคมธรรมศาสตร์",
    th_title: "SET50 Technical ตอน ติวเข้ม by Juti ตอนที่ 3 (17/12/17)",
    th_text: '',
    th_spkinfo: "คุณ อุทัยวรรณ ชื่นชม ผู้เขียนหนังสือขายดี 'Trend Line ง่ายจัง'   วิทยากรผู้สอนกราฟเทคนิคให้กับบรรดานักลงทุนของโบรกเกอร์ชื่อดัง มากมาย และคอลัมนิสต์สาวยอดนิยม ประจำกลุ่ม FACEBOOK 'IRS เดี่ยว Technical'",
    th_contactinfo: "วันเสาร์ที่ 3 กันยายน 2559 <br>เวลา 09:00 - 12:00 น.<br>สถานที่ สมาคมธรรมศาสตร์",
    th_ep: "SET50 Technical ตอน ติวเข้ม by Juti ตอนที่ 3",
  },
  {
    playlistId: "PLOkzCdnS95xSXsB7H3mo1x7tarUzos1bZ",
    index: "29",
    img: "video-Juti-1.jpg",
    en_ep: "SET50 Technical ตอน ติวเข้ม by Juti ตอนที่ 4",
    image: "https://i.ytimg.com/vi/vKmvd4yh_Hg/mqdefault.jpg",
    en_title: "SET50 Technical ตอน ติวเข้ม by Juti ตอนที่ 4 (17/12/17)",
    en_text: '',
    en_spkinfo: "คุณ อุทัยวรรณ ชื่นชม ผู้เขียนหนังสือขายดี 'Trend Line ง่ายจัง'   วิทยากรผู้สอนกราฟเทคนิคให้กับบรรดานักลงทุนของโบรกเกอร์ชื่อดัง มากมาย และคอลัมนิสต์สาวยอดนิยม ประจำกลุ่ม FACEBOOK 'IRS เดี่ยว Technical'",
    en_contactinfo: "วันเสาร์ที่ 3 กันยายน 2559 <br>เวลา 09:00 - 12:00 น.<br>สถานที่ สมาคมธรรมศาสตร์",
    th_title: "SET50 Technical ตอน ติวเข้ม by Juti ตอนที่ 4 (17/12/17)",
    th_text: '',
    th_spkinfo: "คุณ อุทัยวรรณ ชื่นชม ผู้เขียนหนังสือขายดี 'Trend Line ง่ายจัง'   วิทยากรผู้สอนกราฟเทคนิคให้กับบรรดานักลงทุนของโบรกเกอร์ชื่อดัง มากมาย และคอลัมนิสต์สาวยอดนิยม ประจำกลุ่ม FACEBOOK 'IRS เดี่ยว Technical'",
    th_contactinfo: "วันเสาร์ที่ 3 กันยายน 2559 <br>เวลา 09:00 - 12:00 น.<br>สถานที่ สมาคมธรรมศาสตร์",
    th_ep: "SET50 Technical ตอน ติวเข้ม by Juti ตอนที่ 4",
  },
  {
    playlistId: "PLOkzCdnS95xSXsB7H3mo1x7tarUzos1bZ",
    index: "30",
    img: "video-Juti-1.jpg",
    en_ep: "SET50 Technical ตอน ติวเข้ม by Juti ตอนที่ 5",
    image: "https://i.ytimg.com/vi/PUzg_6iN0_c/mqdefault.jpg",
    en_title: "SET50 Technical ตอน ติวเข้ม by Juti ตอนที่ 5 (17/12/17)",
    en_text: '',
    en_spkinfo: "คุณ อุทัยวรรณ ชื่นชม ผู้เขียนหนังสือขายดี 'Trend Line ง่ายจัง'   วิทยากรผู้สอนกราฟเทคนิคให้กับบรรดานักลงทุนของโบรกเกอร์ชื่อดัง มากมาย และคอลัมนิสต์สาวยอดนิยม ประจำกลุ่ม FACEBOOK 'IRS เดี่ยว Technical'",
    en_contactinfo: "วันเสาร์ที่ 3 กันยายน 2559 <br>เวลา 09:00 - 12:00 น.<br>สถานที่ สมาคมธรรมศาสตร์",
    th_title: "SET50 Technical ตอน ติวเข้ม by Juti ตอนที่ 5 (17/12/17)",
    th_text: '',
    th_spkinfo: "คุณ อุทัยวรรณ ชื่นชม ผู้เขียนหนังสือขายดี 'Trend Line ง่ายจัง'   วิทยากรผู้สอนกราฟเทคนิคให้กับบรรดานักลงทุนของโบรกเกอร์ชื่อดัง มากมาย และคอลัมนิสต์สาวยอดนิยม ประจำกลุ่ม FACEBOOK 'IRS เดี่ยว Technical'",
    th_contactinfo: "วันเสาร์ที่ 3 กันยายน 2559 <br>เวลา 09:00 - 12:00 น.<br>สถานที่ สมาคมธรรมศาสตร์",
    th_ep: "SET50 Technical ตอน ติวเข้ม by Juti ตอนที่ 5",
  },
  {
    playlistId: "PLOkzCdnS95xQZx-IinZtntRaOz8ExNKbR",
    index: "31",
    img: "video-Man-1.jpg",
    en_ep: "SET50 Technical ตอน เริ่มต้นเทรด by Stock Manday ตอนที่ 1",
    image: "https://i.ytimg.com/vi/Cw0m7bK4DvQ/mqdefault.jpg",
    en_title: "SET50 Technical ตอน เริ่มต้นเทรด by Stock Manday ตอนที่ 1 (18/11/17)",
    en_text: '',
    en_spkinfo: "คุณ อุทัยวรรณ ชื่นชม ผู้เขียนหนังสือขายดี 'Trend Line ง่ายจัง'   วิทยากรผู้สอนกราฟเทคนิคให้กับบรรดานักลงทุนของโบรกเกอร์ชื่อดัง มากมาย และคอลัมนิสต์สาวยอดนิยม ประจำกลุ่ม FACEBOOK 'IRS เดี่ยว Technical'",
    en_contactinfo: "วันเสาร์ที่ 3 กันยายน 2559 <br>เวลา 09:00 - 12:00 น.<br>สถานที่ สมาคมธรรมศาสตร์",
    th_title: "SET50 Technical ตอน เริ่มต้นเทรด by Stock Manday ตอนที่ 1 (18/11/17)",
    th_text: '',
    th_spkinfo: "คุณ อุทัยวรรณ ชื่นชม ผู้เขียนหนังสือขายดี 'Trend Line ง่ายจัง'   วิทยากรผู้สอนกราฟเทคนิคให้กับบรรดานักลงทุนของโบรกเกอร์ชื่อดัง มากมาย และคอลัมนิสต์สาวยอดนิยม ประจำกลุ่ม FACEBOOK 'IRS เดี่ยว Technical'",
    th_contactinfo: "วันเสาร์ที่ 3 กันยายน 2559 <br>เวลา 09:00 - 12:00 น.<br>สถานที่ สมาคมธรรมศาสตร์",
    th_ep: "SET50 Technical ตอน เริ่มต้นเทรด by Stock Manday ตอนที่ 1 (18/11/17)",
  },
  {
    playlistId: "PLOkzCdnS95xQZx-IinZtntRaOz8ExNKbR",
    index: "32",
    img: "video-Man-1.jpg",
    en_ep: "SET50 Technical ตอน เริ่มต้นเทรด by Stock Manday ตอนที่ 2",
    image: "https://i.ytimg.com/vi/T7YP9T5SAy4/mqdefault.jpg",
    en_title: "SET50 Technical ตอน เริ่มต้นเทรด by Stock Manday ตอนที่ 2 (18/11/17)",
    en_text: '',
    en_spkinfo: "คุณ อุทัยวรรณ ชื่นชม ผู้เขียนหนังสือขายดี 'Trend Line ง่ายจัง'   วิทยากรผู้สอนกราฟเทคนิคให้กับบรรดานักลงทุนของโบรกเกอร์ชื่อดัง มากมาย และคอลัมนิสต์สาวยอดนิยม ประจำกลุ่ม FACEBOOK 'IRS เดี่ยว Technical'",
    en_contactinfo: "วันเสาร์ที่ 3 กันยายน 2559 <br>เวลา 09:00 - 12:00 น.<br>สถานที่ สมาคมธรรมศาสตร์",
    th_title: "SET50 Technical ตอน เริ่มต้นเทรด by Stock Manday ตอนที่ 2 (18/11/17)",
    th_text: '',
    th_spkinfo: "คุณ อุทัยวรรณ ชื่นชม ผู้เขียนหนังสือขายดี 'Trend Line ง่ายจัง'   วิทยากรผู้สอนกราฟเทคนิคให้กับบรรดานักลงทุนของโบรกเกอร์ชื่อดัง มากมาย และคอลัมนิสต์สาวยอดนิยม ประจำกลุ่ม FACEBOOK 'IRS เดี่ยว Technical'",
    th_contactinfo: "วันเสาร์ที่ 3 กันยายน 2559 <br>เวลา 09:00 - 12:00 น.<br>สถานที่ สมาคมธรรมศาสตร์",
    th_ep: "SET50 Technical ตอน เริ่มต้นเทรด by Stock Manday ตอนที่ 2",
  },
  {
    playlistId: "PLOkzCdnS95xQZx-IinZtntRaOz8ExNKbR",
    index: "33",
    img: "video-Man-1.jpg",
    en_ep: "SET50 Technical ตอน เริ่มต้นเทรด by Stock Manday ตอนที่ 3",
    image: "https://i.ytimg.com/vi/VjMnogQXNX0/mqdefault.jpg",
    en_title: "SET50 Technical ตอน เริ่มต้นเทรด by Stock Manday ตอนที่ 3 (18/11/17)",
    en_text: '',
    en_spkinfo: "คุณ อุทัยวรรณ ชื่นชม ผู้เขียนหนังสือขายดี 'Trend Line ง่ายจัง'   วิทยากรผู้สอนกราฟเทคนิคให้กับบรรดานักลงทุนของโบรกเกอร์ชื่อดัง มากมาย และคอลัมนิสต์สาวยอดนิยม ประจำกลุ่ม FACEBOOK 'IRS เดี่ยว Technical'",
    en_contactinfo: "วันเสาร์ที่ 3 กันยายน 2559 <br>เวลา 09:00 - 12:00 น.<br>สถานที่ สมาคมธรรมศาสตร์",
    th_title: "SET50 Technical ตอน เริ่มต้นเทรด by Stock Manday ตอนที่ 3 (18/11/17)",
    th_text: '',
    th_spkinfo: "คุณ อุทัยวรรณ ชื่นชม ผู้เขียนหนังสือขายดี 'Trend Line ง่ายจัง'   วิทยากรผู้สอนกราฟเทคนิคให้กับบรรดานักลงทุนของโบรกเกอร์ชื่อดัง มากมาย และคอลัมนิสต์สาวยอดนิยม ประจำกลุ่ม FACEBOOK 'IRS เดี่ยว Technical'",
    th_contactinfo: "วันเสาร์ที่ 3 กันยายน 2559 <br>เวลา 09:00 - 12:00 น.<br>สถานที่ สมาคมธรรมศาสตร์",
    th_ep: "SET50 Technical ตอน เริ่มต้นเทรด by Stock Manday ตอนที่ 3",
  },
  {
    playlistId: "PLOkzCdnS95xQZx-IinZtntRaOz8ExNKbR",
    index: "34",
    img: "video-Man-1.jpg",
    en_ep: "SET50 Technical ตอน เริ่มต้นเทรด by Stock Manday ตอนที่ 4",
    image: "https://i.ytimg.com/vi/GQxRP9U5ZKs/mqdefault.jpg",
    en_title: "SET50 Technical ตอน เริ่มต้นเทรด by Stock Manday ตอนที่ 4 (18/11/17)",
    en_text: '',
    en_spkinfo: "คุณ อุทัยวรรณ ชื่นชม ผู้เขียนหนังสือขายดี 'Trend Line ง่ายจัง'   วิทยากรผู้สอนกราฟเทคนิคให้กับบรรดานักลงทุนของโบรกเกอร์ชื่อดัง มากมาย และคอลัมนิสต์สาวยอดนิยม ประจำกลุ่ม FACEBOOK 'IRS เดี่ยว Technical'",
    en_contactinfo: "วันเสาร์ที่ 3 กันยายน 2559 <br>เวลา 09:00 - 12:00 น.<br>สถานที่ สมาคมธรรมศาสตร์",
    th_title: "SET50 Technical ตอน เริ่มต้นเทรด by Stock Manday ตอนที่ 4 (18/11/17)",
    th_text: '',
    th_spkinfo: "คุณ อุทัยวรรณ ชื่นชม ผู้เขียนหนังสือขายดี 'Trend Line ง่ายจัง'   วิทยากรผู้สอนกราฟเทคนิคให้กับบรรดานักลงทุนของโบรกเกอร์ชื่อดัง มากมาย และคอลัมนิสต์สาวยอดนิยม ประจำกลุ่ม FACEBOOK 'IRS เดี่ยว Technical'",
    th_contactinfo: "วันเสาร์ที่ 3 กันยายน 2559 <br>เวลา 09:00 - 12:00 น.<br>สถานที่ สมาคมธรรมศาสตร์",
    th_ep: "SET50 Technical ตอน เริ่มต้นเทรด by Stock Manday ตอนที่ 4",
  },
  {
    playlistId: "PLOkzCdnS95xT8flpr3ZgIMBgdEvievOHJ",
    index: "35",
    img: "video-Man-2.jpg",
    en_ep: "SET50 Technical ตอน ติวเข้ม by Stock Manday ตอนที่ 1",
    image: "https://i.ytimg.com/vi/DmsHEyeZOhc/mqdefault.jpg",
    en_title: "SET50 Technical ตอน ติวเข้ม by Stock Manday ตอนที่ 1 (17/12/17)",
    en_text: '',
    en_spkinfo: "คุณ อุทัยวรรณ ชื่นชม ผู้เขียนหนังสือขายดี 'Trend Line ง่ายจัง'   วิทยากรผู้สอนกราฟเทคนิคให้กับบรรดานักลงทุนของโบรกเกอร์ชื่อดัง มากมาย และคอลัมนิสต์สาวยอดนิยม ประจำกลุ่ม FACEBOOK 'IRS เดี่ยว Technical'",
    en_contactinfo: "วันเสาร์ที่ 3 กันยายน 2559 <br>เวลา 09:00 - 12:00 น.<br>สถานที่ สมาคมธรรมศาสตร์",
    th_title: "SET50 Technical ตอน ติวเข้ม by Stock Manday ตอนที่ 1 (17/12/17)",
    th_text: '',
    th_spkinfo: "คุณ อุทัยวรรณ ชื่นชม ผู้เขียนหนังสือขายดี 'Trend Line ง่ายจัง'   วิทยากรผู้สอนกราฟเทคนิคให้กับบรรดานักลงทุนของโบรกเกอร์ชื่อดัง มากมาย และคอลัมนิสต์สาวยอดนิยม ประจำกลุ่ม FACEBOOK 'IRS เดี่ยว Technical'",
    th_contactinfo: "วันเสาร์ที่ 3 กันยายน 2559 <br>เวลา 09:00 - 12:00 น.<br>สถานที่ สมาคมธรรมศาสตร์",
    th_ep: "SET50 Technical ตอน ติวเข้ม by Stock Manday ตอนที่ 1",
  },
  {
    playlistId: "PLOkzCdnS95xT8flpr3ZgIMBgdEvievOHJ",
    index: "36",
    img: "video-Man-2.jpg",
    en_ep: "SET50 Technical ตอน ติวเข้ม by Stock Manday ตอนที่ 2",
    image: "https://i.ytimg.com/vi/Lfac77l2_OM/mqdefault.jpg",
    en_title: "SET50 Technical ตอน ติวเข้ม by Stock Manday ตอนที่ 2 (17/12/17)",
    en_text: '',
    en_spkinfo: "คุณ อุทัยวรรณ ชื่นชม ผู้เขียนหนังสือขายดี 'Trend Line ง่ายจัง'   วิทยากรผู้สอนกราฟเทคนิคให้กับบรรดานักลงทุนของโบรกเกอร์ชื่อดัง มากมาย และคอลัมนิสต์สาวยอดนิยม ประจำกลุ่ม FACEBOOK 'IRS เดี่ยว Technical'",
    en_contactinfo: "วันเสาร์ที่ 3 กันยายน 2559 <br>เวลา 09:00 - 12:00 น.<br>สถานที่ สมาคมธรรมศาสตร์",
    th_title: "SET50 Technical ตอน ติวเข้ม by Stock Manday ตอนที่ 2 (17/12/17)",
    th_text: '',
    th_spkinfo: "คุณ อุทัยวรรณ ชื่นชม ผู้เขียนหนังสือขายดี 'Trend Line ง่ายจัง'   วิทยากรผู้สอนกราฟเทคนิคให้กับบรรดานักลงทุนของโบรกเกอร์ชื่อดัง มากมาย และคอลัมนิสต์สาวยอดนิยม ประจำกลุ่ม FACEBOOK 'IRS เดี่ยว Technical'",
    th_contactinfo: "วันเสาร์ที่ 3 กันยายน 2559 <br>เวลา 09:00 - 12:00 น.<br>สถานที่ สมาคมธรรมศาสตร์",
    th_ep: "SET50 Technical ตอน ติวเข้ม by Stock Manday ตอนที่ 2",
  },
  {
    playlistId: "PLOkzCdnS95xT8flpr3ZgIMBgdEvievOHJ",
    index: "37",
    img: "video-Man-2.jpg",
    en_ep: "SET50 Technical ตอน ติวเข้ม by Stock Manday ตอนที่ 3",
    image: "https://i.ytimg.com/vi/yrd0l7X1I9A/mqdefault.jpg",
    en_title: "SET50 Technical ตอน ติวเข้ม by Stock Manday ตอนที่ 3 (17/12/17)",
    en_text: '',
    en_spkinfo: "คุณ อุทัยวรรณ ชื่นชม ผู้เขียนหนังสือขายดี 'Trend Line ง่ายจัง'   วิทยากรผู้สอนกราฟเทคนิคให้กับบรรดานักลงทุนของโบรกเกอร์ชื่อดัง มากมาย และคอลัมนิสต์สาวยอดนิยม ประจำกลุ่ม FACEBOOK 'IRS เดี่ยว Technical'",
    en_contactinfo: "วันเสาร์ที่ 3 กันยายน 2559 <br>เวลา 09:00 - 12:00 น.<br>สถานที่ สมาคมธรรมศาสตร์",
    th_title: "SET50 Technical ตอน ติวเข้ม by Stock Manday ตอนที่ 3 (17/12/17)",
    th_text: '',
    th_spkinfo: "คุณ อุทัยวรรณ ชื่นชม ผู้เขียนหนังสือขายดี 'Trend Line ง่ายจัง'   วิทยากรผู้สอนกราฟเทคนิคให้กับบรรดานักลงทุนของโบรกเกอร์ชื่อดัง มากมาย และคอลัมนิสต์สาวยอดนิยม ประจำกลุ่ม FACEBOOK 'IRS เดี่ยว Technical'",
    th_contactinfo: "วันเสาร์ที่ 3 กันยายน 2559 <br>เวลา 09:00 - 12:00 น.<br>สถานที่ สมาคมธรรมศาสตร์",
    th_ep: "SET50 Technical ตอน ติวเข้ม by Stock Manday ตอนที่ 3",
  },
  {
    playlistId: "PLOkzCdnS95xT8flpr3ZgIMBgdEvievOHJ",
    index: "38",
    img: "video-Man-2.jpg",
    en_ep: "SET50 Technical ตอน ติวเข้ม by Stock Manday ตอนที่ 4",
    image: "https://i.ytimg.com/vi/pKijXScEN7I/mqdefault.jpg",
    en_title: "SET50 Technical ตอน ติวเข้ม by Stock Manday ตอนที่ 4 (17/12/17)",
    en_text: '',
    en_spkinfo: "คุณ อุทัยวรรณ ชื่นชม ผู้เขียนหนังสือขายดี 'Trend Line ง่ายจัง'   วิทยากรผู้สอนกราฟเทคนิคให้กับบรรดานักลงทุนของโบรกเกอร์ชื่อดัง มากมาย และคอลัมนิสต์สาวยอดนิยม ประจำกลุ่ม FACEBOOK 'IRS เดี่ยว Technical'",
    en_contactinfo: "วันเสาร์ที่ 3 กันยายน 2559 <br>เวลา 09:00 - 12:00 น.<br>สถานที่ สมาคมธรรมศาสตร์",
    th_title: "SET50 Technical ตอน ติวเข้ม by Stock Manday ตอนที่ 4 (17/12/17)",
    th_text: '',
    th_spkinfo: "คุณ อุทัยวรรณ ชื่นชม ผู้เขียนหนังสือขายดี 'Trend Line ง่ายจัง'   วิทยากรผู้สอนกราฟเทคนิคให้กับบรรดานักลงทุนของโบรกเกอร์ชื่อดัง มากมาย และคอลัมนิสต์สาวยอดนิยม ประจำกลุ่ม FACEBOOK 'IRS เดี่ยว Technical'",
    th_contactinfo: "วันเสาร์ที่ 3 กันยายน 2559 <br>เวลา 09:00 - 12:00 น.<br>สถานที่ สมาคมธรรมศาสตร์",
    th_ep: "SET50 Technical ตอน ติวเข้ม by Stock Manday ตอนที่ 4",
  },
  {
    playlistId: "PLOkzCdnS95xT8flpr3ZgIMBgdEvievOHJ",
    index: "39",
    img: "video-Man-2.jpg",
    en_ep: "SET50 Technical ตอน ติวเข้ม by Stock Manday ตอนที่ 5",
    image: "https://i.ytimg.com/vi/TD0z3OtCDfU/mqdefault.jpg",
    en_title: "SET50 Technical ตอน ติวเข้ม by Stock Manday ตอนที่ 5 (17/12/17)",
    en_text: '',
    en_spkinfo: "คุณ อุทัยวรรณ ชื่นชม ผู้เขียนหนังสือขายดี 'Trend Line ง่ายจัง'   วิทยากรผู้สอนกราฟเทคนิคให้กับบรรดานักลงทุนของโบรกเกอร์ชื่อดัง มากมาย และคอลัมนิสต์สาวยอดนิยม ประจำกลุ่ม FACEBOOK 'IRS เดี่ยว Technical'",
    en_contactinfo: "วันเสาร์ที่ 3 กันยายน 2559 <br>เวลา 09:00 - 12:00 น.<br>สถานที่ สมาคมธรรมศาสตร์",
    th_title: "SET50 Technical ตอน ติวเข้ม by Stock Manday ตอนที่ 5 (17/12/17)",
    th_text: '',
    th_spkinfo: "คุณ อุทัยวรรณ ชื่นชม ผู้เขียนหนังสือขายดี 'Trend Line ง่ายจัง'   วิทยากรผู้สอนกราฟเทคนิคให้กับบรรดานักลงทุนของโบรกเกอร์ชื่อดัง มากมาย และคอลัมนิสต์สาวยอดนิยม ประจำกลุ่ม FACEBOOK 'IRS เดี่ยว Technical'",
    th_contactinfo: "วันเสาร์ที่ 3 กันยายน 2559 <br>เวลา 09:00 - 12:00 น.<br>สถานที่ สมาคมธรรมศาสตร์",
    th_ep: "SET50 Technical ตอน ติวเข้ม by Stock Manday ตอนที่ 5",
  },
]