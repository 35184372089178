import React, { useState, useEffect,Fragment } from 'react';
import WarrantSensitivities from './WarrantSensitivities';
import "../../common.css";
import "../../investorAcademy.css";


const WarrantSensitivitys = (props) => {
    return ( 
        <Fragment> 
            <WarrantSensitivities/>
        </Fragment> 
     );
}


export default WarrantSensitivitys;