export const languageText ={

    sidebarLiveMatrixTitle: "ตารางราคา",
    sidebarLiveMatrixTitleEn: "Live matrix",
    sidebarLiveMatrixDescription: "เทรดง่ายขึ้นด้วยตารางราคาที่แสดงราคา Bid ของ DW และ อัพเดทช่วงราคาในตารางราคาตลอดทั้งวัน",
    sidebarLiveMatrixDescriptionEn: "Makes trading DW easy by showing investors where our bid prices will be throughout the day",

    sidebarDwSearchTitle: "ค้นหา DW",
    sidebarDwSearchTitleEn: "DW search",
    sidebarDwSearchDescription: "ค้นหาข้อมูล DW ทุกรุ่นในตลาด เพื่อหา DW ที่ใช่ของคุณ",
    sidebarDwSearchDescriptionEn: "Search all DW in the market and find a DW according to your criteria!",

    sidebarCalculatorTitle: "คำนวณราคา DW",
    sidebarCalculatorTitleEn: "DW calculator",
    sidebarCalculatorDescription: "คำนวณราคา DW ในระดับราคาอ้างอิง วันที่ซื้อขาย DW และความผันผวนที่คุณเลือกเอง",
    sidebarCalculatorDescriptionEn: "Estimates your future DW price given a change in underlying price, time or implied volatility",

    sidebarDwTermsTitle: "ข้อมูล DW",
    sidebarDwTermsTitleEn: "DW terms",
    sidebarDwTermsDescription: "ข้อมูลทั้งหมดที่คุณต้องรู้เกี่ยวกับ DW ที่คุณสนใจ รวบรวมอยู่ในหน้านี้",
    sidebarDwTermsDescriptionEn: "Everything you need to know about your selected DW is found here!",

    dwVideoTitle: "วิดีโอความรู้เรื่อง DW",
    dwVideoTitleEn: "DW video",
    dwVideoDescription: "เพิ่มพูนความรู้ DW ง่ายๆด้วย VDO ความยาวเพียง 3 นาที",
    dwVideoDescriptionEn: "Improve your knowledge of DW in these short 3 min videos.",

    sidebarEduHtmlTitle: "แหล่งความรู้",
    sidebarEduHtmlTitleEn: "Investor academy",
    sidebarEduHtmlDescription: "ทุกอย่างที่คุณต้องรู้เกี่ยวกับ DW อยู่ที่นี่…",
    sidebarEduHtmlDescriptionEn: "Everything you need to know about DW…",

    sidebarIndexDwTitle: "Index DW",
    sidebarIndexDwTitleEn: "Index DW",
    sidebarIndexDwDescription: "DW ที่อ้างอิงกับดัชนี คืออะไร ทำไมถึงได้รับความนิยม?",
    sidebarIndexDwDescriptionEn: "What is an index DW and why is it so popular?",
}