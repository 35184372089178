import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom" 
import { languageText } from "../../../languageText/dwVideoTestimonial";
import DailyMarketCarousel from "../DwVideoInd/DailyMarketCom"
import { educationList } from "../DwVideo/EducationData"
import { testimonialList } from "../DwVideo/TestimonialData"
import { speakerList } from "../DwVideo/SpeakerData"

const DwVideoTestimonial = () => {

  const [siteLang , setSiteLang] = useState("")
  const [playListId, setPlayListId] = useState("")
  const [index, setIndex] = useState("")
  const [activeVideoInfo, setActiveVideoInfo] = useState([])
  const history = useHistory();
  const [testimonialTabActive , setTestimonialTabActive] = useState(true)
  const [speakerTabActive , setSpeakerTabActive] = useState(false)
  const [educationTabActive , setEducationTabActive] = useState(false)
  const [dailyMarketTabActive , setDailyMarketTabActive] = useState(false)
  const [marketAnalysis, setMarketAnalysis] = useState([]);
  const [dmaPopUp, setDmaPopUp] = useState(false);
  const [popupVideoUrl, setPopupVideoUrl] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
    var siteLanguage = $.cookie('applang');
    setSiteLang(siteLanguage)
    if(siteLanguage == "eng"){
      document.querySelector("body").classList.add("eng")
    }else{
      document.querySelector("body").classList.remove("eng")
    }
    var urlData = new URLSearchParams(window.location.search)
    setPlayListId(urlData.get("playlistId"))
    setIndex(urlData.get("index"))
    var episodeFilterData = []
    testimonialList.filter((e) => {
      if(e.playlistId == urlData.get("playlistId")){
        episodeFilterData.push(e)
      }
    })
    setActiveVideoInfo(episodeFilterData[urlData.get("index")])
  }, [playListId]);

  const setSelectedData = (data, index) => {
    if(window.innerWidth > 767){
      window.scrollTo(0, 150);
    }else{
      window.scrollTo(0, 0);
    }
    setPlayListId(data.playlistId)
    setIndex(index)
    setActiveVideoInfo(data)
  }

  const openEduPage = (data) => {
    history.push({
      pathname: '/education/dwvideoind',
      search: `?playlistId=${data}&index=0`
    })
  }
 
  const openSpeakerPage = (data) => {
    history.push({
      pathname: '/education/speaker',
      search: `?playlistId=${data}&index=0`
    })
  }

  const setActiveTab = (data) => {
    if(data == "gs"){
      setEducationTabActive(false)
      setSpeakerTabActive(true)
      setTestimonialTabActive(false)
      setDailyMarketTabActive(false)
    }else if(data == "tm"){
      setEducationTabActive(false)
      setSpeakerTabActive(false)
      setTestimonialTabActive(true)
      setDailyMarketTabActive(false)
    }else if(data == "dma"){
      setEducationTabActive(false)
      setSpeakerTabActive(false)
      setTestimonialTabActive(false)
      setDailyMarketTabActive(true)
    }else{
      setEducationTabActive(true)
      setSpeakerTabActive(false)
      setTestimonialTabActive(false)
      setDailyMarketTabActive(false)
    }
  }

  const setDmaVideoUrl = (data) => {
    setPopupVideoUrl(data)
    setDmaPopUp(true)
    document.querySelector("body").style.overflow = "hidden"
  }

  const setDmaData = (data) => {
    setMarketAnalysis(data)
  }

  const closePopUp = () => {
    setDmaPopUp(false)
    setPopupVideoUrl("")
    document.querySelector("body").style.overflow = "auto"
  }

  const openFbPage = () => {
    var url_page = window.location.href.split('?')[0] + '?lang=' + siteLang + "&playlistId=" + playListId + "&index=" + index;
    var url_share = 'http://www.facebook.com/share.php?u=' + escape(url_page); 
    window.open(url_share, 'share', 'width=560,height=650');
  }

  return (
    <>
    <div id="testimonial" className="pageobj">
      <div className="breadcrumbbar">
        <div className="container-fluid">
          <ol className="breadcrumb">
            <li>
              <Link to="/home?dwedu">{siteLang == "eng" ? languageText.firstNavigationEn : languageText.firstNavigation}</Link>
            </li>
            <li>
              <Link to="/education/dwvideo">{siteLang == "eng" ? languageText.secondNavigationEn : languageText.secondNavigation}</Link>
            </li>
            <li className="active">{siteLang == "eng" ? languageText.thirdNavigationEn : languageText.thirdNavigation}</li>
          </ol>
        </div>
      </div>
      <div className="stripe-bg" />
      <div className="container-fluid">
        <div className="section">
          <div className="page-header">
            <h1>{siteLang == "eng" ? languageText.pageTitleEn : languageText.pageTitle}</h1>
          </div>
          <p className="section-p">{siteLang == "eng" ? languageText.pageDescriptionEn : languageText.pageDescription}</p>
          <div className="videoplayer">
            <div className="videoplayer-container">
                <iframe frameBorder="0" allowFullScreen src={`https://www.youtube.com/embed/videoseries?list=${playListId}&autoplay=1&index=${index}&rel=0&wmode=opaque`}></iframe>
            </div>
          </div>
          <div className="videoinfo">
            <div className="videoinfo-title">{siteLang == "eng" ?  activeVideoInfo.en_title : activeVideoInfo.th_title}</div>
            <p className="videoinfo-p">{siteLang == "eng" ?  activeVideoInfo.en_text : activeVideoInfo.th_text}</p>
            <div className="fbSharebtn" onClick={openFbPage}><i className="fa fa-facebook" aria-hidden="true"></i>Share on Facebook</div>
          </div>
          <div className="content hidden-xs">
            <div id="edurack" className="videorack videorack-custom">
              <div className="videorack-title">More testimonial videos</div>
              <div className="videorack-body">
                <div className="videorack-content">
                  <table className="videorack-page hidden-xs">
                    <tbody>
                      <tr>
                      {testimonialList && Array.from({ length: 5 }).map((_, index) => {
                          const data = testimonialList[index];
                          if (data) {
                            return (
                              <td key={index}>
                                <div
                                  playlistid={data.playlistId}
                                  index={index}
                                  className="videorack-item"
                                  onClick={() => setSelectedData(data, index)}
                                >
                                  <img style={{ width: "100%" }} src={data.image} alt={data.en_title} />
                                  <div className="videorack-item-txt">
                                    {siteLang === "eng" ? data.en_title : data.th_title}
                                  </div>
                                </div>
                              </td>
                            );
                          } else {
                            return <td key={index}><div className="videorack-item videorack-item-hidden" style={{ display: "inline-block" }} /></td>;
                          }
                        })}
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div id="dmarack" className="videorack videorack-custom">
              <div className="videorack-title">Daily Market Analysis</div>
              <div className="videorack-body">
                <DailyMarketCarousel setDmaData={setDmaData} />
              </div>
            </div>
            <div className="row">
              <div className="col-md-6">
                <div id="tmrack" className="videorack videorack-custom rack-fullwidth">
                  <div className="videorack-title">More seminar videos</div>
                  <div className="videorack-body">
                    <div className="videorack-content">
                      <table className="videorack-page hidden-xs">
                        <tbody>
                          <tr>
                          {speakerList && Array.from({ length: 4 }).map((_, index) => {
                              const data = speakerList[index];
                              if (data) {
                                return (
                                  <td key={index}>
                                    <div
                                      playlistid={data.playlistId}
                                      index={index}
                                      className="videorack-item"
                                      style={{ display: "inline-block" }}
                                      onClick={() => openSpeakerPage(data.playlistId)}
                                    >
                                      <img style={{ width: "100%" }} src={data.image} alt={data.en_title} />
                                      <div className="videorack-item-txt">
                                        {data.text}
                                      </div>
                                    </div>
                                  </td>
                                );
                              } else {
                                return <td key={index}><div className="videorack-item videorack-item-hidden" style={{ display: "inline-block" }} /></td>;
                              }
                            })}
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div id="eduMoreVideo" className="videorack videorack-custom rack-fullwidth">
                  <div className="videorack-title">More education videos</div>
                  <div className="videorack-body">
                    <div className="videorack-content">
                      <table className="videorack-page hidden-xs">
                        <tbody>
                          <tr>
                          {educationList && Array.from({ length: 4 }).map((_, index) => {
                              const data = educationList[index];
                              if (data) {
                                return (
                                  <td key={index}>
                                    <div
                                      playlistid={data.playlistId}
                                      index="0"
                                      className="videorack-item"
                                      style={{ display: "inline-block" }}
                                      onClick={() => openEduPage(data.playlistId)}
                                    >
                                      <img style={{ width: "100%" }} src={data.image} alt={data.en_title} />
                                      <div className="videorack-item-txt">
                                        {siteLang === "eng" ? data.en_title : data.th_title}
                                      </div>
                                    </div>
                                  </td>
                                );
                              } else {
                                return <td key={index}><div className="videorack-item videorack-item-hidden" style={{ display: "inline-block" }} /></td>;
                              }
                            })}
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="content-xs visible-xs">
            <div id="videoTabs" className="tablist">
              <div>
                <table>
                  <tbody>
                    <tr>
                      <td className={testimonialTabActive ? "active" : ""}>
                        <div
                          data-toggle="tab"
                          className="tablist-item"
                          value="tm"
                          onClick={() => setActiveTab("tm")}
                        >
                          <div className="tablist-item-txt">Testimonial</div>
                        </div>
                      </td>
                      <td className={speakerTabActive ? "active" : ""}> 
                        <div
                          data-toggle="tab"
                          className="tablist-item"
                          value="gs"
                          onClick={() => setActiveTab("gs")}
                        >
                          <div className="tablist-item-txt">Speaker</div>
                        </div>
                      </td>
                      <td className={educationTabActive ? "active" : ""}>
                        <div
                          data-toggle="tab"
                          className="tablist-item"
                          value="edu"
                          onClick={() => setActiveTab("edu")}
                        >
                          <div className="tablist-item-txt">Education</div>
                        </div>
                      </td>
                      <td className={dailyMarketTabActive ? "active" : ""}>
                        <div
                          data-toggle="tab"
                          className="tablist-item"
                          value="dma"
                          onClick={() => setActiveTab("dma")}
                        >
                          <div className="tablist-item-txt">
                            Daily Market Analysis
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div id="videotabcontent">
              {testimonialTabActive && <div id="tmlist" className="videolist">
                {testimonialList && testimonialList.map((data, index)=> (
                  <div
                    playlistid={data.playlistId}
                    index={index}
                    className="videolist-item"
                    key={index}
                    onClick={() => setSelectedData(data, index)}
                  >
                    <img style={{ width: "100%" }} src={data.image} />
                    <div className="videolist-item-txt">
                      {siteLang == "eng" ? data.en_title : data.th_title}
                    </div>
                  </div>
                ))}
              </div>}
              {speakerTabActive && <div id="gslist" className="videolist">
                {speakerList && Array.from({ length: 4 }).map((_, index) => {
                  const data = speakerList[index];
                  return (
                    <div
                      playlistid={data.playlistId}
                      index={index}
                      className="videolist-item"
                      key={index}
                      onClick={() => openSpeakerPage(data.playlistId)}
                    >
                      <img style={{ width: "100%" }} src={data.image} />
                      <div className="videolist-item-txt">
                        {data.text}
                      </div>
                    </div>
                  );
                })}
              </div>}
              {educationTabActive && <div id="edulist" className="videolist">
                {educationList && educationList.map((data, index)=> (
                  <div
                    playlistid={data.playlistId}
                    index="0"
                    className="videolist-item"
                    key={index}
                    onClick={() => openEduPage(data.playlistId)}
                  >
                    <img style={{ width: "100%" }} src={data.image} />
                    <div className="videolist-item-txt">
                      {siteLang == "eng" ? data.en_title : data.th_title}
                    </div>
                  </div>
                ))}
              </div>}
              {dailyMarketTabActive && <div id="dmalist" className="videolist">
                {marketAnalysis && marketAnalysis.map((data, index) => (
                  <div
                    dmaid={data.id}
                    className="videolist-item"
                    key={index}
                    onClick={() => setDmaVideoUrl(data.link)}
                  >
                    {data.image == "true" ? <img style={{ width: "100%" }} src={data.imgSrc} /> : <img style={{ width: "100%" }} src="" />}
                    <div className="videolist-item-txt">
                      {siteLang == "eng" ? data.en_title : data.thai_title}
                    </div>
                  </div>
                ))}
              </div>}
            </div>
          </div>
        </div>
      </div>
    </div>
    {dmaPopUp && <div id="ppboxHTML2" className="overlay">
      <div className="overlay-inner">
        <div style={{ position: "relative" }}>
          <img onClick={closePopUp} className="btn-close" src="/img/bullet/icon-close-white.png" />
          <div className="popupbox-content">
            <div className="ppc" key="video">
              <div className="hotline-layer">
                <iframe frameBorder={0} allowFullScreen src={popupVideoUrl} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>}
    </>
  );
};
export default DwVideoTestimonial;
