import React from 'react'
import DwTipssUpPages from './DwTipssUp'
import "./dwtipssup.css"

const DwTipssUp = () => {
  return (
    <DwTipssUpPages/>
  )
}

export default DwTipssUp