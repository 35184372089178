import React, { useEffect, useState } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import ChildInvestorAcademyCommon from "../../../../../components/common/InvestorAcademyCommon/ChildInvestorAcademyCommon";
import "../../common.css";
import "../../investorAcademy.css";
import { languageText } from "../../../../../languageText/InvestorAcademy/HowSelectWarrant/tipsAndTools"
import { languageTextMain } from "../../../../../languageText/InvestorAcademy/index";

const TipsTools = (props) => {
  
  const location = useLocation();
  const history = useHistory();
  const [siteLang , setSiteLang] = useState("")

  // Site Language Change Start //
  useEffect(() => {
    window.scrollTo(0, 0);
    var siteLanguage = $.cookie('applang');
    setSiteLang(siteLanguage)
    if(siteLanguage == "eng"){
      document.querySelector("body").classList.add("eng")
    }else{
      document.querySelector("body").classList.remove("eng")
    }
  }, []);
  // Site Language Change End //

  return (
    <div id="investor_academy">
      <div className="imgbg" />
      <div className="mainbg" />
      <div id="main">
        <div id="maintable">
          <div style={{ display: "table-row" }}>
            <div id="leftbar" className="hidden-xs" />
            <div id="maincontainer">
              {/* Top Title Section Start */}
              <div id="mainnav">
                <table>
                  <tbody>
                    <tr>
                      <td width="20%" style={{textAlign:"left", visibility: "hidden"}} />
                      <td width="60%" style={{ textAlign: "center" }}>
                        <div id="pagetitlediv">
                          <div id="ptitle">{siteLang == "eng" ? languageText.pageTitleEn : languageText.pageTitle}</div>
                          <div id="pname">{siteLang == "eng" ? languageText.pageNameEn : languageText.pageName}</div>
                        </div>
                      </td>
                      <td width="20%" style={{textAlign:"right", visibility: "hidden"}} />
                    </tr>
                  </tbody>
                </table>
              </div>
              {/* Top Title Section End */}
              {/* Main Content Section Start */}
              <div id="maincontent">
                <div id="mainscroll">
                  <div id="mainscrollcontent">
                    <style dangerouslySetInnerHTML={{__html: ".txtbox{font-size: 14px; max-width:60%;margin:0 auto;padding:1em; background:rgb(56,56,56);background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiA/Pgo8c3ZnIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjEwMCUiIGhlaWdodD0iMTAwJSIgdmlld0JveD0iMCAwIDEgMSIgcHJlc2VydmVBc3BlY3RSYXRpbz0ibm9uZSI+CiAgPGxpbmVhckdyYWRpZW50IGlkPSJncmFkLXVjZ2ctZ2VuZXJhdGVkIiBncmFkaWVudFVuaXRzPSJ1c2VyU3BhY2VPblVzZSIgeDE9IjAlIiB5MT0iMCUiIHgyPSIwJSIgeTI9IjEwMCUiPgogICAgPHN0b3Agb2Zmc2V0PSIwJSIgc3RvcC1jb2xvcj0iIzQ1NDg0ZCIgc3RvcC1vcGFjaXR5PSIxIi8+CiAgICA8c3RvcCBvZmZzZXQ9IjEwMCUiIHN0b3AtY29sb3I9IiMwMDAwMDAiIHN0b3Atb3BhY2l0eT0iMSIvPgogIDwvbGluZWFyR3JhZGllbnQ+CiAgPHJlY3QgeD0iMCIgeT0iMCIgd2lkdGg9IjEiIGhlaWdodD0iMSIgZmlsbD0idXJsKCNncmFkLXVjZ2ctZ2VuZXJhdGVkKSIgLz4KPC9zdmc+); background: -moz-linear-gradient(top,  rgba(56,56,56,1) 0%, rgba(0,0,0,1) 100%); background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(56,56,56,1)), color-stop(100%,rgba(0,0,0,1)));  background: -webkit-linear-gradient(top,  rgba(56,56,56,1) 0%,rgba(0,0,0,1) 100%); background: -o-linear-gradient(top,  rgba(56,56,56,1) 0%,rgba(0,0,0,1) 100%);background: -ms-linear-gradient(top,  rgba(56,56,56,1) 0%,rgba(0,0,0,1) 100%);background: linear-gradient(to bottom,  rgba(56,56,56,1) 0%,rgba(0,0,0,1) 100%);filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#868686', endColorstr='#000000',GradientType=0 );}.txtbox h2,.txtbox p{text-align: left;margin-bottom: 0;margin-top: 0;}" }} />
                    <div className="contentbox">
                      <p>
                        <Link to={{pathname: "https://www.thaidw.com/home"}} target="_top" >www.thaidw.com </Link>
                        {siteLang == "eng" ? languageText.pageDescriptionEn : languageText.pageDescription}
                      </p>
                      <div className="txtbox" style={{maxWidth: "60%"}}>
                        <h2>{siteLang == "eng" ? languageText.searchTxtBoxTitleEn : languageText.searchTxtBoxTitle}</h2>
                        <p>{siteLang == "eng" ? languageText.searchTxtBoxDescriptionEn : languageText.searchTxtBoxDescription} 
                        <a href="/tools/dwsearch">{siteLang == "eng" ? languageText.clickHereEn : languageText.clickHere}</a></p>
                      </div>
                      <div className="txtbox" style={{maxWidth: "60%"}}>
                        <h2>{siteLang == "eng" ? languageText.termsTxtBoxTitleEn : languageText.termsTxtBoxTitle}</h2>
                        <p>{siteLang == "eng" ? languageText.termsTxtBoxDescriptionEn : languageText.termsTxtBoxDescription} 
                        <a href="/tools/dwterms">{siteLang == "eng" ? languageText.clickHereEn : languageText.clickHere}</a></p>
                      </div>
                      {siteLang == "eng" ? "" : <div className="txtbox" style={{maxWidth: "60%"}}>
                          <h2>{languageText.matrixTxtBoxTitle}</h2>
                          <p>{languageText.matrixTxtBoxDescription} 
                          <a href="/tools/livematrix">{languageText.clickHere}</a></p>
                        </div>}
                      {siteLang == "eng" ? 
                        <div className="txtbox" style={{maxWidth: "60%"}}>
                          <h2>{languageText.compareTxtBoxTitleEn}</h2>
                          <p>{languageText.compareTxtBoxDescriptionEn} 
                          <a href="/tools/dwcompare">{languageText.clickHereEn}</a></p>
                        </div>
                        :
                        <div className="txtbox" style={{maxWidth: "60%"}}>
                          <h2>{languageText.calculatorTxtBoxTitle}</h2>
                          <p>{languageText.calculatorTxtBoxDescription} 
                          <a href="/tools/dwcalculator">{languageText.clickHere}</a></p>
                        </div>
                      }
                      {siteLang == "eng" ? 
                        <div className="txtbox" style={{maxWidth: "60%"}}>
                          <h2>{languageText.moneyflowTxtBoxTitleEn}</h2>
                          <p>{languageText.moneyflowTxtBoxDescriptionEn} 
                          <a href="/tools/moneyflow">{languageText.clickHereEn}</a></p>
                        </div>
                        :
                        <div className="txtbox" style={{maxWidth: "60%"}}>
                          <h2>{languageText.compareTxtBoxTitle}</h2>
                          <p>{languageText.compareTxtBoxDescription} 
                          <a href="/tools/dwcompare">{languageText.clickHere}</a></p>
                        </div>
                      }
                      {siteLang == "eng" ? 
                        <div className="txtbox" style={{maxWidth: "60%"}}>
                          <h2>{languageText.calculatorTxtBoxTitleEn}</h2>
                          <p>{languageText.calculatorTxtBoxDescriptionEn} 
                          <a href="/tools/dwcalculator">{languageText.clickHereEn}</a></p>
                        </div>
                        :
                        <div className="txtbox" style={{maxWidth: "60%"}}>
                          <h2>{languageText.moneyflowTxtBoxTitle}</h2>
                          <p>{languageText.moneyflowTxtBoxDescription} 
                          <a href="/tools/moneyflow">{languageText.clickHere}</a></p>
                        </div>
                      }
                    </div>
                  </div>
                </div>
              </div>
              {/* Main Content Section End */}
            </div>
            <div id="rightbar" className="hidden-xs" />
          </div>
        </div>
      </div>
      {/* Bottom Left Right Links and Copyright Section Start */}
      <div id="bottom">
        <div className="hidden-xs hidden-xxs" id="copyright">
          {siteLang == "eng" ? languageTextMain.copyRightEn : languageTextMain.copyRight}
        </div>
        <div className="leftUrl">
          <table className="hasprev">
            <tbody>
              <tr>
                <td width="33px">
                  <Link to="/eduhtml/riskmatrix">
                    <div className="prev left" />
                  </Link>
                </td>
                <td>
                  <div className="prevlbl left">
                    <div className="prevname">
                      <Link to="/eduhtml/riskmatrix">
                        {siteLang == "eng" ? languageTextMain.riskMatrixEn : languageTextMain.riskMatrix}
                      </Link>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="rightUrl">
          <table className="hasnext">
            <tbody>
              <tr>
                <td>
                  <div className="nextlbl right">
                    <div className="nextname">
                      <Link to="/eduhtml/singlesharecallsandputsatexpiry">
                        {siteLang == "eng" ? languageTextMain.singleShareCallsAndPutsAtExpiryEn : languageTextMain.singleShareCallsAndPutsAtExpiry}
                      </Link>
                    </div>
                  </div>
                </td>
                <td width="33px">
                  <Link to="/eduhtml/singlesharecallsandputsatexpiry">
                    <div className="next right" />
                  </Link>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      {/* Bottom Left Right Links and Copyright Section End */}
      {/* Right Dots Menu Component Section Start */}
      <div id="dotbar" className="hidden-sm hidden-xs">
        <ChildInvestorAcademyCommon Location={location} Hist={history} />
      </div>
      {/* Right Dots Menu Component Section End  */}
    </div>
  );
};
export default TipsTools;
