import React, { Fragment } from 'react';
import IntrinsicValueTimeValues from './IntrinsicValueTimeValues';
import "../../common.css";
import "../../investorAcademy.css";


const IntrinsicValueTimeValue = (props) => {
    return ( 
        <Fragment> 
            <IntrinsicValueTimeValues/>
        </Fragment> 
     );
}


export default IntrinsicValueTimeValue;