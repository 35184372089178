export const languageText ={
    bannerTitle:'Boost Your Opportunities',

    bannerTitleP:'ลงทุนดัชนีและหุ้นต่างประเทศผ่านตลาดหุ้นไทย',
    bannerTitlePEn:'Invest in foreign index and stock',

    bannerTitleP1:'คุณบรรพต ธนาเพิ่มสุข นักลงทุน และผู้ร่วมก่อตั้งช่อง TAM-Eig',
    bannerTitleP1En:'on Thai stock exchange',

    bannerTitleP2:'ผมทำงานอยู่ในแวดวงการเงิน การลงทุน เศรษฐกิจมานาน ทำให้ผมสนใจลงทุนต่างประเทศ ผมเลยเลือกลงทุนใน DW อ้างอิงดัชนีและหุ้นต่างประเทศครับ เพราะทำให้ผมเพิ่มโอกาสเกาะกระแสความเคลื่อนไหวของตลาดมากกว่าลงทุนหุ้นไทยอย่างเดียว',
    bannerTitleP2En:'Banphot Thanapermsuk,an TV news anchor,investor and FB page owner Tam Eig i have been working in finance,investment andeconomic news industry forlong time. This grows my interest  in foreign investment. I choose to invest foreign index and stock DW. It has more opportunity to capture market movements than investing only in Thai market',

    TestimonialTitle:'ทำไมต้องเทรดกับ DW28?',
    TestimonialTitleEn:'Why need to trade with Dw28?',

    DWTipsTitle:'เริ่มต้นเทรด DW อ้างอิงหลักทรัพย์ไทยและต่างประเทศ',
    DWTipsTitleEn:'Start trading Dw over Thai and Foreign underlying',


}