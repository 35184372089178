import React, { useState, useEffect,Fragment } from 'react';
import HowWarrantsPrices from './HowWarrantsPrices';
import "../common.css";
import "../investorAcademy.css" 


const HowWarrantsPriced = (props) => {
    return ( 
        <Fragment> 
        <HowWarrantsPrices/>
        </Fragment> 
     );
}


export default HowWarrantsPriced;