export const languageText ={
    
    firstNavigation: "เครื่องมือ DW ",
    firstNavigationEn: "DW tools ",
    secondNavigation: " ค้นหา DW ",
    secondNavigationEn: " DW search ",

    pageTitle: "ค้นหา DW",
    pageTitleEn: "DW search",
    pageDescription: "ค้นหาข้อมูล DW ทุกรุ่นในตลาด เพื่อหา DW ที่ใช่ของคุณ",
    pageDescriptionEn: "Search all DW in the market and find a DW according to your criteria!",

    tooltipClick: " อ่านข้อมูล ",
    tooltipClickEn: " click. ",

    tooltipClickHere: " คลิกที่นี่ ",
    tooltipClickHereEn: " Click here ",

    // Indicator Title and Tooltip
    fastTrackIndicate: "Fast track",
    fastTrackIndicateEn: "Fast track",
    fastTrackIndicateTooltip: " DW ที่เร็ว แรง เติมไว และไม่หน่วง สามารถทำให้นักลงทุนซื้อ/ขาย DW ได้ภายในเสี้ยววินาที",
    fastTrackIndicateTooltipEn: " DW with high speed refilled. It allows investors to buy / sell millions of DW less than a second.",

    cautionIndicate: "Caution",
    cautionIndicateEn: "Caution",
    cautionIndicateTooltip: " เป็น DW ที่มีความเสี่ยงสูง เช่น มีมูลค่าและค่า Sensitivity ต่ำ เป็นต้น เพื่อเป็นการลดความเสี่ยง ทาง   Market Maker อาจจะพิจารณาขยับราคา Offer แต่ยังคงราคา Bid  ไว้ที่ระดับเดิม นักลงทุนสามารถขายคืนได้ตามปกติ คลิก",
    cautionIndicateTooltipEn: " It indicates DWs with high risk ie Low price and Sensitivity. In order to reduce a risk, Market Maker may consider to increase only the offer price but keep Bid at the same level. Therefore investors who previously bought into the DW are not disadvantaged. Find out more",

    nearExpiryIndicate: "ใกล้หมดอายุ",
    nearExpiryIndicateEn: "Near expiry",
    nearExpiryIndicateTooltip: " หมายถึง DW ที่มีอายุคงเหลือน้อยกว่า 1 เดือน ซึ่งมีแนวโน้มว่า DW  รุ่นนั้นจะมีความเสี่ยงสูง  นักลงทุนควรเทรดด้วยความระมัดระวัง",
    nearExpiryIndicateTooltipEn: " Indicates DWs which are less than 1 month to expiry. These DW tend to be very high risk, please trade with caution.",

    inventoryIndicate: "Inventory sold-out",
    inventoryIndicateEn: "Inventory sold-out",
    inventoryIndicateTooltip: " หากชื่อย่อ DW แสดงเครื่องหมาย Inventory sold-out กำกับ   หมายถึง ผู้ออกเสนอขาย DW เป็นจำนวนมากกว่า 95%  ของจำนวนที่ขอจดทะเบียนกับตลาดหลักทรัพย์ฯ ซึ่งหมายความว่า   ผู้ดูแลสภาพคล่องอาจจะมีการวางราคาเสนอขาย (Offer)  ห่างจากราคาเสนอซื้อ (Bid) มากกว่า 1 ช่วงราคา หรืออาจไม่สามารถวางราคาเสนอขายได้ ผู้ที่ถือ DW อยู่แล้วจะไม่เสียประโยชน์ และยังสามารถขาย DW  คืนให้ผู้ดูแลสภาพคล่องที่ราคาเสนอซื้อที่เหมาะสม (fair  bid price) ได้ อย่างไรก็ตาม  นักลงทุนต้องระมัดระวังอย่างมากในการซื้อ DW   รุ่นนี้เนื่องจากราคา DW อาจเพิ่มสูงขึ้นจากการเก็งกำไรได้",
    inventoryIndicateTooltipEn: " Where the DW code has inventory sold-out sign it means that more than 95% of the DW inventory has been sold. This means that the market maker may no longer provide an offer price or have widened their offer spread. Existing holders of the DW are not disadvantaged and they can still sell to the market maker at a fair bid price. However please be very cautious if you are buying this DW. The price may be inflated by speculators.",

    lowTimeIndicate: "Low time decay",
    lowTimeIndicateEn: "Low time decay",
    lowTimeIndicateTooltip: " เมื่อ DW ตัวใดมีการแสดงเครื่องหมาย Low Time Decay  นั้นหมายถึง DW รุ่นนั้นมีอายุยาวและค่า Time decay  ต่อวันต่ำ (โดยคิดเป็นจำนวน 4 วันหรือมากกว่า   ถึงจะปรับตัวลดลง 0.01 บาท /หรือ จำนวน 2 วันหรือมากกว่า   ในกรณีที่หลักทรัพย์อ้างอิงเป็นดัชนี) ทั้งนี้ DW รุ่นดังกล่าวเหมาะสำหรับนักลงทุนที่มีกลยุทธ์ที่ต้องการถือ  DW เป็นระยะยาว",
    lowTimeIndicateTooltipEn: " Where the DW code indicates low time decay the warrant has a longer time to maturity and lower daily time decay (around 4 days or more to decay 0.01 bt. or 2 days or more in case underlying is index) which makes it suitable for where your trading strategy requires a longer holding period.",

    responsiveIndicate: "Responsive",
    responsiveIndicateEn: "Responsive",
    responsiveIndicateTooltip1: " หมายถึง DW  รุ่นที่เคลื่อนไหวตามราคาหลักทรัพย์อ้างอิงได้ดี ",
    responsiveIndicateTooltip1En: " Indicates DWs that are highly responsive to the movement of underlying price ",
    responsiveIndicateTooltip2: " เพื่ออ่านข้อมูลเพิ่มเติม",
    responsiveIndicateTooltip2En: " for more information",

    // Table Title and Tooltip
    dwSymbolTable: "DW ชื่อย่อ",
    dwSymbolTableEn: "DW symbol",
    dwSymbolTableTooltip1: " DWแต่ละรุ่นจะมีชื่อเฉพาะ ",
    dwSymbolTableTooltip1En: " Each DW has a unique code to identify it, ",
    dwSymbolTableTooltip2: " เพื่ออ่านคำอธิบายเพิ่มเติม",
    dwSymbolTableTooltip2En: " for a more detailed explanation.",

    indicatorTable: "Indicators",
    indicatorTableEn: "Indicators",

    typeTable: "ประเภท",
    typeTableEn: "Type",
    typeTableTooltip: " Calls จะเพิ่มมูลค่าต่อเมื่อหุ้นอ้างอิงปรับตัวขึ้น ในขณะที่ Puts จะเพิ่มมูลค่าต่อเมื่อหุ้นอ้างอิงปรับตัวลง",
    typeTableTooltipEn: " Calls increase in value as share price rises, Puts increase in value as share price falls.",

    exercisepriceTable: "ราคาใช้สิทธิ",
    exercisepriceTableEn: "Exercise price",
    exercisepriceTableTooltip: " ราคาที่คุณสามารถซื้อ (Call) หรือขาย (Put) หุ้นอ้างอิงหรือดัชนีอ้างอิงได้เมื่อครบกำหนดอายุ*",
    exercisepriceTableTooltipEn: " The price at which you can buy (calls) or sell (puts) the underlying share or index at expiry.",

    ratioTable: "Ratio",
    ratioTableEn: "Ratio",
    ratioTableTooltip: " อัตราการใช้สิทธิ (สำหรับ DW บนหุ้น) หรือ ตัวคูณดัชนี (สำหรับ DW บนดัชนีหลักทรัพย์)",
    ratioTableTooltipEn: " The conversion ratio (for stock DW) or the index multiplier (for index DW)",

    lastTradingDateTable: "วันซื้อขายสุดท้าย",
    lastTradingDateTableEn: "Last trading date",
    lastTradingDateTableTooltip: " วันสุดท้ายที่ DW มีการซื้อขายในตลาดหลักทรัพย์ และเป็นวันคำนวณมูลค่า DW เมื่อครบกำหนดอายุ โดยทั่วไปคือวันทำการที่ 4 ก่อนวันครบกำหนดอายุ",
    lastTradingDateTableTooltipEn: " The day that the DW stops trading on the SET and the expiry value is calculated, always 4 business days prior to the expiry date.",

    issuerTable: "ผู้ออก",
    issuerTableEn: "Issuer",
    issuerTableTooltip: " คือธนาคารหรือบริษัทหลักทรัพย์ที่เป็นผู้ออกและเสนอขาย DW",
    issuerTableTooltipEn: " The Bank or Securities company that issues the DW.",

    tradedVolumeTable: "ปริมาณการซื้อขาย",
    tradedVolumeTableEn: "Traded volume",
    tradedVolumeTableTooltip1: " ปริมาณการซื้อขายทั้งหมดของ DW ในวันปัจจุบัน ซึ่ง DW ที่ไม่มีการซื้อขายภายในวัน ไม่ได้หมายความว่า DW นั้นไม่มีสภาพคล่อง.",
    tradedVolumeTableTooltip1En: " Total volume traded in the DW for the current day. A DW that has not traded for the day does not necessarily mean that it is not liquid.",
    tradedVolumeTableTooltip2: "เพื่ออ่านรายละเอียดเกี่ยวกับ DW และสภาพคล่องของ DW",
    tradedVolumeTableTooltip2En: "for more info about DW and liquidity.",

    bidVolumeTable: "ปริมาณเสนอซื้อ",
    bidVolumeTableEn: "Bid volume",
    bidVolumeTableTooltip: " คือปริมาณ DW ที่วางไว้ ณ ราคาเสนอซื้อที่สูงที่สุดของ DW",
    bidVolumeTableTooltipEn: " The volume provided at the current highest bid price for the DW.",

    bidThbTable1: "ราคาเสนอซื้อ",
    bidThbTable1En: "Bid",
    bidThbTable2: " (บาท) ",
    bidThbTable2En: " (THB) ",
    bidThbTableTooltip: " The highest current buy price of the DW. This is the value that most accurately reflects the 'value' of the DW at any point in time.",
    bidThbTableTooltipEn: " The highest current buy price of the DW. This is the value that most accurately reflects the 'value' of the DW at any point in time.",

    askVolumeTable: "ปริมาณเสนอขาย",
    askVolumeTableEn: "Ask volume",
    askVolumeTableTooltip: " คือปริมาณ DW ที่วางไว้ ณ ราคาเสนอขายที่ต่ำที่สุดของ DW",
    askVolumeTableTooltipEn: " The volume provided at the current lowest sell price for the DW.",

    askThbTable1: "ราคาเสนอขาย",
    askThbTable1En: "Ask",
    askThbTable2: " (THB) ",
    askThbTable2En: " (THB) ",
    askThbTableTooltip: " The lowest current sell price for the DW.",
    askThbTableTooltipEn: " The lowest current sell price for the DW.",

    priceChangeTable: "ราคาที่เปลี่ยนแปลงไป",
    priceChangeTableEn: "Price change",
    priceChangeTableTooltip: " ราคาเสนอซื้อสุดท้ายของ DW เปรียบเทียบกับราคาเสนอซื้อสุดท้าย ณ สิ้นวันทำการก่อนหน้า โดยราคาเสนอซื้อสุดท้าย ณ สิ้นวันทำการก่อนหน้าคือราคาเสนอซื้อสุดท้าย ณ เวลา 16.30 น. สำหรับข้อมูลเพิ่มเติม คลิกที่นี่",
    priceChangeTableTooltipEn: " Current bid price for the DW compared to the Prior (previous days close). The bid price of the DW at the close of trading 4:30pm will be used for the Prior. For more info",

    moneynessTable: "สถานะ",
    moneynessTableEn: "Moneyness",
    moneynessTableTooltip1: " ITM (In-the-money), OTM (Out-of-the-money), ATM (At-the-money) ",
    moneynessTableTooltip1En: " ITM (In-the-money), OTM (Out-of-the-money), ATM (At-the-money) ",
    moneynessTableTooltip2: " เพื่ออ่านรายละเอียดเพิ่มเติม",
    moneynessTableTooltip2En: " to learn more about moneyness.",

    effgearingTable: "Effective gearing",
    effgearingTableEn: "Effective gearing",
    effgearingTableTooltip1: " ใช้บ่งชี้โอกาสในการรับผลตอบแทนจากการลงทุนใน DW ตัวอย่างเช่น Effective Gearing เท่ากับ 5 เท่า หมายความว่า ถ้าราคาหุ้นอ้างอิงเปลี่ยนแปลงไป 1% ราคา DW จะเปลี่ยนแปลงไปประมาณ 5% ",
    effgearingTableTooltip1En: " indicates your leveraged exposure. An effective gearing of 5X tells you a 1% move in the underlying stock/index results in an approximate 5% move in your DW price. ",
    effgearingTableTooltip2: " เพื่ออ่านรายละเอียดเพิ่มเติม",
    effgearingTableTooltip2En: " to learn more about effective gearing.",

    sensitivityTable: "Sensitivity",
    sensitivityTableEn: "Sensitivity",
    sensitivityTableTooltip: " แสดงให้เห็นว่าราคา DW จะเปลี่ยนแปลงไปกี่ช่วงราคา เมื่อราคาหุ้นอ้างอิงปรับตัวเพิ่มขึ้น 1 ช่วงราคา เช่น ค่า Sensitivity = 1.2 หมายถึง ราคาหุ้นอ้างอิงปรับตัวขึ้น 1 ช่วงราคา ราคาของ DW เปลี่ยนแปลงประมาณ 1.2 ช่วงราคา",
    sensitivityTableTooltipEn: " Indicates the number of ticks in the DW moves for a 1 tick move in the underlying. Eg, Sensitivity = 1.2 means underlying stock move 1 tick, your DW will move around 1.2 ticks.",
    sensitivityTableTooltip2: " สำหรับ HSI DW จะใช้เปรียบเทียบเมื่อราคาหลักทรัพย์อ้างอิงปรับตัวเพิ่มขึ้น 100 จุด เช่น Sensitivity = 2.1 หมายถึง สัญญาซื้อขายล่วงหน้า HSI เคลื่อน 100 จุด ราคา DW จะเปลี่ยนแปลงประมาณ 2.1 ช่วงราคา",
    sensitivityTableTooltip2En: " For HSI DW’s sensitivity, it will indicates the number of ticks in the DW moves for 100 futures index point move. Eg. Sensitivity = 2.1 means HSI index futures move 100 futures index point, your DW will move around 2.1 ticks.",

    sensitivityIndexPointTable: " (จุดดัชนี) ",
    sensitivityIndexPointTableEn: " by Index Point ",

    timeDecayTable: "Time Decay (Days)",
    timeDecayTableEn: "Time Decay (Days)",

    impliedVolatilityTable: "Implied volatility",
    impliedVolatilityTableEn: "Implied volatility",
    impliedVolatilityTableTooltip1: " DW ที่มีความผันผวนสูง จะมีราคาสูงตามไปด้วย Implied  Volatility   มีความสัมพันธ์กับความผันผวนและความเสี่ยงของหุ้นอ้างอิงหรือดัชนีอ้างอิง ",
    impliedVolatilityTableTooltip1En: " The higher the implied volatility, the higher the price of the DW. Relates to the volatility and risk of the underlying share/Index. ",
    impliedVolatilityTableTooltip2: " เพื่ออ่านข้อมูลเพิ่มเติม",
    impliedVolatilityTableTooltip2En: " to learn more about implied volatility.",

    priceChange1: "ราคาที่เปลี่ยนแปลงไป(%) คำนวณโดยใช้ราคาเสนอซื้อล่าสุดของ DW เปรียบเทียบกับราคาเสนอซื้อสุดท้าย ณ สิ้นวันทำการก่อนหน้า โดย ณ สิ้นวันทำการของแต่ละวัน แมคควอรีจะใช้ราคาเสนอซื้อสุดท้ายของ DW และหุ้นอ้างอิง ณ ช่วงเวลา 16.30 น. และใช้ราคานี้แทน “ราคาปิด” ซึ่งจะช่วยให้นักลงทุนสามารถใช้เป็นราคาอ้างอิงเพื่อติดตามการเคลื่อนไหวของราคา DW ได้ถูกต้องแม่นยำมากขึ้น สำหรับข้อมูลเพิ่มเติม",
    priceChange1En: "The Price Change (%) is calculated using the current bid price for the DW compared to the previous days closing bid price. At the end of each trading day, Macqaurie captures the bid price of the DW and the underlying share at the close of trading at 4:30pm and uses this to represent the 'closing price'. This will provide the most accurate reference point for investors to track price movements of the DW. For more info",
    priceChange2: "ราคาใช้สิทธิและ DWPS ในตารางด้านบน ถูกปรับทศนิยมให้เหมาะสมกับความกว้างของตาราง นักลงทุนสามารถคลิกที่ชื่อย่อ DW เพื่ออ่านลักษณะและเงื่อนไขโดยละเอียดก่อนปรับทศนิยมได้",
    priceChange2En: "The exercise prices and DWPS in the above table in some cases have been rounded to fit the page. Please click on the symbol to go to the DW terms page for the full rounding.",
    marketDelay: "ข้อมูลตลาดทั้งหมดล่าช้าประมาณ 15 นาที เว้นแต่ข้อมูลที่มีการบอกเวลากำกับ",
    marketDelayEn: "All market is delayed 15 mins unless otherwise indicated by timestamp",

    definitionDisclaimer: "Definition & disclaimer",
    definitionDisclaimerEn: "Definition & disclaimer",
}