import React, { useState, useEffect,Fragment } from 'react';
import EaseTrades from './EaseTrades';
import "../../common.css";
import "../../investorAcademy.css";


const EaseTrade = (props) => {
    return ( 
        <Fragment> 
        <EaseTrades/>
        </Fragment> 
     );
}


export default EaseTrade;