import React, { Fragment } from 'react'
import Setseminar from './Setseminar'
import "./setseminar.css"; 

const SetSeminar = () => {
  return (
    <Fragment><Setseminar/></Fragment>
  )
}

export default SetSeminar