export const languageText ={

    pageTitle: "วิธีการเลือก DW",
    pageTitleEn: "How to select a DW?",

    pageName: "เคล็ดลับและเครื่องมือ",
    pageNameEn: "Tips and Tools",

    pageDescription: "มีเครื่องมือต่างๆเป็นตัวเลือกที่จะช่วยนักลงทุนในการเลือกลงทุน DW และยังรวมถึงช่วยตัดสินใจว่าควรจะขาย DW เมื่อไหร่ คำอธิบายสั้นๆของเครื่องมือบางส่วนมีดังนี้",
    pageDescriptionEn: "has a selection of very useful tools that will assist in your DW selection process and also in your subsequent decision on when to sell. Here are a brief summary of some of these:",

    clickHere: " คลิกที่นี่",
    clickHereEn: " Click here",
    
    searchTxtBoxTitle: "ค้นหา DW (DW Search)",
    searchTxtBoxTitleEn: "DW search",
    searchTxtBoxDescription: "ช่วยให้คุณค้นหาข้อมุล DW จากเงื่อนไขที่คุณเลือก ซึ่งหน้านี้จะแสดงข้อมูลสำคัญๆ ที่คุณต้องการใช้ในการเลือกลงทุน DW",
    searchTxtBoxDescriptionEn: "allows you to search for DW by specifying your criteria, it will display all of the important info you need to make your decisions,",

    termsTxtBoxTitle: "ข้อมูล DW (DW Terms)",
    termsTxtBoxTitleEn: "DW terms",
    termsTxtBoxDescription: "รวบรวมข้อมูลราคาปัจจุบันของ DW แต่ละตัว คุณสามารถเข้าสู่หน้านี้ได้โดยตรง หรือ คลิกบนสัญลักษณ์ของ DW จากตารางในหน้าค้นหา DW ก็ได้,",
    termsTxtBoxDescriptionEn: "lists all of the current pricing information for a particular DW. Can be accessed directly or via clicking on a DW code in the DW search table,",

    matrixTxtBoxTitle: "ตารางราคา",
    matrixTxtBoxTitleEn: "",
    matrixTxtBoxDescription: "สรุปจุดเปลี่ยนราคาของ DW28 ทั้งหมด เพื่อให้นักลงทุนมีจังหวะในการลงทุนที่เหมาะสม คลิกที่นี่",
    matrixTxtBoxDescriptionEn: "",

    calculatorTxtBoxTitle: "คำนวณราคา DW",
    calculatorTxtBoxTitleEn: "DW calculator",
    calculatorTxtBoxDescription: "ช่วยให้คุณสามารถเปลี่ยนตัวแปรในการคำนวณราคา DW และเห็นผลกระทบที่เกิดขึ้นกับราคา DW นั้น",
    calculatorTxtBoxDescriptionEn: "allows you to change the variables of a DW and see the resultant effect on the DW price",

    compareTxtBoxTitle: "Compare DW",
    compareTxtBoxTitleEn: "Compare DW",
    compareTxtBoxDescription: "ช่วยให้คุณเลือก DW หลายๆตัวเพื่อเปรียบเทียบลักษณะของ DW ต่างๆโดยละเอียด",
    compareTxtBoxDescriptionEn: "allows you to select two DW and compare their details side by side.",

    moneyflowTxtBoxTitle: "Moneyflow",
    moneyflowTxtBoxTitleEn: "Moneyflows",
    moneyflowTxtBoxDescription: "ช่วยให้คุณเห็นว่าหุ้นอ้างอิงใดที่นักลงทุนซื้อหรือขาย DW ที่อ้างอิงบนหุ้นนั้นมากที่สุด เพื่อให้คุณเห็น “กระแส” ของตลาด",
    moneyflowTxtBoxDescriptionEn: "will allow you to see which underlyings other investors have been buying and selling DW over, giving you a feel for the market ‘flows’",
    
}