import React, { Fragment } from 'react';
import "./dwCalculators.css" 
import DwCalculator from "./DwCalculator"


const DwCalculators = (props) => {
    return ( 
        <Fragment> 
            <DwCalculator />
        </Fragment> 
     );
}

export default DwCalculators;