import React from "react"
import Chart from 'react-apexcharts'
import { useHistory } from "react-router-dom";

export default function PieChart({ result,date }) {
    const history = useHistory()
    const sectorName = result.map((d) => d.underlying_ticker)
    const sectorCall = result.map((d) => parseFloat(d[date].replace(/,/g, '')))
    const series = sectorCall
    const options = {
        series: sectorCall,
        labels: sectorName,
        colors: ["#0675C2", "#00A1E0", "#6CC263", "#F7941E", "#FECC0A", "#C61C2D"],
        legend: {
            show: false
        },
        plotOptions: {
            pie: {
                expandOnClick: false,
            }
        },
        chart: {
            type: 'pie',
            events: {
                dataPointSelection: (event, chartContext, config) => {
                    if(config.w.config.labels[config.dataPointIndex] != "Others"){
                        history.push(`/tools/dwranking/${config.w.config.labels[config.dataPointIndex]}`) 
                    }
                }
            }
        },
        stroke: {
            width: ['0']
        },
        tooltip: {
            enabled: true,
            y: {
              formatter: function(val) {
                return val+"%"
              },
            }
        },
    }

    return (
        <>
            <Chart
                options={options}
                series={series}
                type="pie"
                maxwidth="450"
                maxheight="450"
            />
        </>
    )
}
