export const languageText ={

    pageTitle: "ราคา DW คำนวณอย่างไร",
    pageTitleEn: "How are DW priced?",

    pageName: "ราคาใช้สิทธิ",
    pageNameEn: "Exercise Price",

    pageDescription: "คือราคาที่นักลงทุนจะซื้อหุ้นอ้างอิงเมื่อครบกำหนดอายุสำหรับ Call DW (หรือขายหุ้นอ้างอิง สำหรับ Put DW) ดังนั้น ถ้าราคาใช้สิทธิสูงขึ้น Call DW ก็มีโอกาสน้อยที่จะถูกใช้สิทธิ ดังนั้น มูลค่าของ DW ก็จะถูกลง แต่ในทางกลับกัน ในกรณีของ Put DW ถ้าราคาใช้สิทธิสูงขึ้น โอกาสที่ Put DW จะถูกใช้สิทธิก็มีมากขึ้น ดังนั้น มูลค่าของ Put DW ก็จะแพงขึ้นด้วย",
    pageDescriptionEn: "This is the fixed price at which investors are entitled to buy the underlying share on the expiry date for call DW (or sell, in the case of put DW). As such, the higher this price, the less likely the call DW will be exercised and hence, the less value ascribed to the DW. Conversely, in the case of a put DW, the higher the price at which you are able to sell the asset for on a future date, then the more likely the DW is to be exercised and hence, a higher value ascribed to that put.",

    imgUrl: "/img/education/img/ep.png",
    imgUrlEn: "/img/education/img/ep.png",
}