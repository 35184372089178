import React, { useState, useEffect,Fragment } from 'react';
import SelectingWarrantsFour from './SelectingWarrants';
import "../../common.css";
import "../../investorAcademy.css";


const SelectingWarrantFour = (props) => {
    return ( 
        <Fragment> 
            <SelectingWarrantsFour/>
        </Fragment> 
     );
}


export default SelectingWarrantFour;