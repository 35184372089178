import React,{Fragment} from "react";
import "./hsiFlight.css"
import HsifLight from "./HsifLight"

const HsifLights = (props) => {
    return ( 
        <Fragment> 
        <HsifLight/>
        </Fragment> 
     );
}


export default HsifLights;