import React, { useEffect, useState } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import ChildInvestorAcademyCommon from "../../../../../components/common/InvestorAcademyCommon/ChildInvestorAcademyCommon";
import "../../common.css";
import "../../investorAcademy.css";
import { languageText } from "../../../../../languageText/InvestorAcademy/Risk/takeOvers"
import { languageTextMain } from "../../../../../languageText/InvestorAcademy/index";

const TakeOvers = (props) => {
  
  const location = useLocation();
  const history = useHistory();
  const [siteLang , setSiteLang] = useState("")

  // Site Language Change Start //
  useEffect(() => {
    window.scrollTo(0, 0);
    var siteLanguage = $.cookie('applang');
    setSiteLang(siteLanguage)
    if(siteLanguage == "eng"){
      document.querySelector("body").classList.add("eng")
    }else{
      document.querySelector("body").classList.remove("eng")
    }
  }, []);
  // Site Language Change End //

  return (
    <div id="investor_academy">
      <div className="imgbg" />
      <div className="mainbg" />
      <div id="main">
        <div id="maintable">
          <div style={{ display: "table-row" }}>
            <div id="leftbar" className="hidden-xs" />
            <div id="maincontainer">
              {/* Top Title Section Start */}
              <div id="mainnav">
                <table>
                  <tbody>
                    <tr>
                      <td width="20%" style={{textAlign:"left", visibility: "hidden"}} />
                      <td width="60%" style={{ textAlign: "center" }}>
                        <div id="pagetitlediv">
                          <div id="ptitle">{siteLang == "eng" ? languageText.pageTitleEn : languageText.pageTitle}</div>
                          <div id="pname">{siteLang == "eng" ? languageText.pageNameEn : languageText.pageName}</div>
                        </div>
                      </td>
                      <td width="20%" style={{textAlign:"right", visibility: "hidden"}} />
                    </tr>
                  </tbody>
                </table>
              </div>
              {/* Top Title Section End */}
              {/* Main Content Section Start */}
              <div id="maincontent">
                <div id="mainscroll">
                  <div id="mainscrollcontent">
                    <style dangerouslySetInnerHTML={{__html: ".imgholder img{max-width: 313px;} " }} />
                    <div className="contentbox">
                      <p dangerouslySetInnerHTML={{ __html : siteLang == "eng" ? languageText.pageDescriptionEn : languageText.pageDescription}} />
                      <div className="imgholder">
                        <img src={siteLang == "eng" ? languageText.imgUrlEn : languageText.imgUrl} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* Main Content Section End */}
            </div>
            <div id="rightbar" className="hidden-xs" />
          </div>
        </div>
      </div>
      {/* Bottom Left Right Links and Copyright Section Start */}
      <div id="bottom">
        <div className="hidden-xs hidden-xxs" id="copyright">
          {siteLang == "eng" ? languageTextMain.copyRightEn : languageTextMain.copyRight}
        </div>
        <div className="leftUrl">
          <table className="hasprev">
            <tbody>
              <tr>
                <td width="33px">
                  <Link to="/eduhtml/creditrisk">
                    <div className="prev left" />
                  </Link>
                </td>
                <td>
                  <div className="prevlbl left">
                    <div className="prevname">
                      <Link to="/eduhtml/creditrisk">
                        {siteLang == "eng" ? languageTextMain.creditRiskEn : languageTextMain.creditRisk}
                      </Link>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="rightUrl">
          <table className="hasnext">
            <tbody>
              <tr>
                <td>
                  <div className="nextlbl right">
                    <div className="nextname">
                      <Link to="/eduhtml/marketmakers">
                        {siteLang == "eng" ? languageTextMain.marketMakersEn : languageTextMain.marketMakers}
                      </Link>
                    </div>
                  </div>
                </td>
                <td width="33px">
                  <Link to="/eduhtml/marketmakers">
                    <div className="next right" />
                  </Link>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      {/* Bottom Left Right Links and Copyright Section End */}
      {/* Right Dots Menu Component Section Start */}
      <div id="dotbar" className="hidden-sm hidden-xs">
        <ChildInvestorAcademyCommon Location={location} Hist={history} />
      </div>
      {/* Right Dots Menu Component Section End  */}
    </div>
  );
};
export default TakeOvers;
