export const languageText ={

    pageTitle: "ตัวแปรเกี่ยวกับ DW ที่ควรรู้",
    pageTitleEn: "Useful DW terms",

    pageName: "จุดคุ้มทุน (Breakeven Price)",
    pageNameEn: "Breakeven Price",

    pageDescription: "หมายถึงระดับราคา ณ วันครบกำหนดอายุ ที่ทำให้นักลงทุนสามารถมีกำไรจากการลงทุนใน Call DW (หรือ Put DW) ดังนั้นการคำนวณจุดคุ้มทุน จึงมีประโยชน์ต่อเมื่อคุณวางแผนที่จะถือ DW ไว้จนหมดอายุ นอกจากนั้นจุดคุ้มทุนก็เป็นเพียงระดับราคาที่คุณซื้อ DW ไปนั่นเอง",
    pageDescriptionEn: 'Price level at expiry above which the investor will make a profit in the case of a call (put). Calculation of the breakeven price is useful only if you were planning to hold the DW till expiry, otherwise, breakeven is simply the price you bought the DW at.',

    exampleBoxHeading: "ตัวอย่างของ Call DW:",
    exampleBoxHeadingEn: "Example of a Call DW:",
    exampleBoxExercisePrice: "ราคาใช้สิทธิ:",
    exampleBoxExercisePriceEn: "Exercise Price:",
    exampleBoxExercisePriceResult: "32 บาท",
    exampleBoxExercisePriceResultEn: "THB 32.00",
    exampleBoxDwPrice: "ราคา DW :",
    exampleBoxDwPriceEn: "DW Price:",
    exampleBoxDwPriceResult: "1.20 บาท",
    exampleBoxDwPriceResultEn: "THB 1.20",
    exampleBoxDwps: "DWPS:",
    exampleBoxDwpsEn: "DWPS:",
    exampleBoxDwpsResult: "4",
    exampleBoxDwpsResultEn: "4",
    exampleBoxUnderlying: "ราคาหุ้นอ้างอิง:",
    exampleBoxUnderlyingEn: "Underlying Price:",
    exampleBoxUnderlyingResult: "34.25 บาท",
    exampleBoxUnderlyingResultEn: "THB 34.25",

    formulaHolderLeft: "ต้นทุนในการซื้อหุ้นจากการซื้อ DW ",
    formulaHolderLeftEn: "Cost of buying the share via DW ",
    formulaHolderRightFirst: " (ราคา DW x DWPS) + ราคาใช้สิทธิ",
    formulaHolderRightFirstEn: " (CW Price x DWPS) + Exercise Price",
    formulaHolderRightSecond: " (1.20 บาท x 4) + 32 บาท",
    formulaHolderRightSecondEn: " (THB 1.20 x 4) + THB 32.00",
    formulaHolderRightThird: " 36.80 บาท",
    formulaHolderRightThirdEn: " MYR 36.80",

    tipHolderHeading: "เคล็ดลับในการใช้จุดคุ้มทุน",
    tipHolderHeadingEn: "Helpful Tips on how to use breakeven",
    tipHolderParagraph1: "อีกวิธีง่ายๆที่ใช้ในการหาจุดคุ้มทุน คือการดูจากมูลค่าเวลา (Time Value) ซึ่งมูลค่าเวลาแสดงให้เห็นจำนวนหุ้นอ้างอิงที่ต้องเพิ่มขึ้นเพื่อให้ DW นั้นมีมูลค่าที่จุดคุ้มทุน ณ วันครบกำหนดอายุ จากตัวอย่างก่อนหน้า จะเห็นว่า Time Value มีมูลค่าเท่ากับ 4.5% ซึ่งหมายถึงราคาหุ้นต้องเพิ่มขึ้น 4.5% จาก 11.20 บาท เป็น 11.70 บาท เพื่อให้เท่ากับจุดคุ้มทุนของ DW ณ วันครบกำหนดอายุ",
    tipHolderParagraph1En: "Another simple way to calculate breakeven is to look at premium (or time value). Premium represents the amount that the underlying share has to increase by in order for the warrants to be at breakeven at expiry. Find out more about premium in the next page.",
    tipHolderParagraph2: "พึงระลึกไว้ว่า จุดคุ้มทุน คือ ราคาหุ้นอ้างอิง ณ วันที่ DW ครบกำหนดอายุ ซึ่งจะต้องเท่ากับต้นทุนที่คุณซื้อ DW ดังนั้นจุดคุ้มทุนสำหรับช่วงที่ DW ยังไม่หมดอายุควรมีมูลค่าต่ำกว่า",
    tipHolderParagraph2En: "Remember that the breakeven price is the price that the share needs to reach AT EXPIRY for the DW to equal the same price that you paid to purchase the DW. The breakeven price for time periods before expiry will be lower.",
    tipHolderParagraph3: "นักลงทุนสามารถหามูลค่าจุดคุ้มทุนของ DW ทุกรุ่นได้จากหน้า",
    tipHolderParagraph3En: "You can view the breakeven for all warrants via the",
    tipHolderParagraph3Link: " ข้อมูล DW ",
    tipHolderParagraph3LinkEn: " DW Terms ",
    tipHolderParagraph32: "",
    tipHolderParagraph32En: "page.",

}