export const languageText ={

    pageTitle: "ทำไมต้องลงทุนใน DW?",
    pageTitleEn: "Why Trade DW?",

    pageName: "ลดจำนวนเงินลงทุนในหลักทรัพย์",
    pageNameEn: "Releasing share capital",

    pageDescription: "การใช้ DW เพื่อเพิ่มโอกาสในการรับผลตอบแทนจากหุ้นเป็นการลดเงินลงทุนที่จะต้องใช้ในการซื้อหุ้นเต็มจำนวน นักลงทุนอาจจะขายหุ้นที่ถืออยู่และรับโอกาสในการรับผลตอบแทนจากหุ้นนั้นด้วยการซื้อ Call DW ซึ่งใช้เงินเพียงส่วนหนึ่งของราคาหุ้น นักลงทุนจึงยังคงรักษาโอกาสในการรับผลตอบแทนจากราคาหุ้นอ้างอิงพร้อมๆกับการลดจำนวนเงินลงทุนที่จะต้องใช้ในการถือครองหุ้น",
    pageDescriptionEn: "Using DW to gain exposure to stocks can free up the capital that would otherwise be invested into fully paid shares. Alternatively, an investor may sell existing share holdings and gain a similar effective exposure by purchasing call warrants for a fraction of the price. The investor has maintained exposure to share prices while releasing capital from the share holding.",

    imgUrl: "/img/education/img/releasingsharecapital_th.png",
    imgUrlEn: "/img/education/img/releasingsharecapital.png",
}