import React, { Fragment } from 'react';
import InterestRates from './InterestRates';
import "../../common.css";
import "../../investorAcademy.css";


const InterestRate = (props) => {
    return ( 
        <Fragment> 
            <InterestRates/>
        </Fragment> 
     );
}


export default InterestRate;