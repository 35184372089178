import React, { useState, useEffect,Fragment } from 'react';
import Activities from './Activities';
import "./activitie.css" 


const Activitie = (props) => {
    return ( 
        <Fragment> 
        <Activities/>
        </Fragment> 
     );
}


export default Activitie;