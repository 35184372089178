import React, { useState, useEffect,Fragment } from 'react';
import CreditRisks from './CreditRisks';
import "../../common.css";
import "../../investorAcademy.css";


const CreditRisk = (props) => {
    return ( 
        <Fragment> 
            <CreditRisks/>
        </Fragment> 
     );
}


export default CreditRisk;