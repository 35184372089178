export const languageText ={

    pageTitle: "ตัวแปรเกี่ยวกับ DW ที่ควรรู้",
    pageTitleEn: "Useful DW terms",

    pageName: "DW ต่อหุ้น (DWPS)",
    pageNameEn: "DW per share (DPWS)",

    pageDescription: "จำนวน DW ต่อหุ้น (DW per share หรือ DWPS) แสดงถึงจำนวน DW ที่ต้องการใช้เพื่อแลกเป็นหุ้นอ้างอิงเมื่อครบกำหนดอายุ ในทางกลับกัน DWPS เป็นที่นิยมเรียกกันว่า “อัตราการใช้สิทธิ” (Conversion Ratio)",
    pageDescriptionEn: 'The "DW Per Share" number (also known as DWPS) shows the number of DW needed to exchange for one underlying share/index futures at expiry. The inverse of DWPS is known as the "conversion ratio". See below..',

    ctableTrTh1: "DW ต่อหุ้น (DWPS)",
    ctableTrTh1En: "DW Per Share (DWPS)",
    ctableTrTh2: "อัตราการใช้สิทธิ ",
    ctableTrTh21: "(Conversion Ratio)",
    ctableTrTh2En: "Conversion Ratio",
    ctableTrTd1: "4",
    ctableTrTd1En: "4",
    ctableTrTd12: "(4 DW : 1 หุ้นอ้างอิง)",
    ctableTrTd12En: "(4 DW : 1 underlying)",
    ctableTrTd2: "0.25",
    ctableTrTd2En: "0.25",

    pageDescription1: "DWPS เป็นเพียงตัวเลขที่ใช้ทำให้ DW มีราคาถูกลง ดังนั้น ถ้า DW mมี DWPS เท่ากับ 1 มีราคา 1 บาท เราสามารถทำให้ DW นี้มีราคาถูกลง โดยแบ่งออกเป็นหน่วยละ 0.25 บาท ด้วยการใช้ DWPS เท่ากับ 4",
    pageDescription1En: 'The purpose of DWPS number is really just to break down the DW into smaller parts, so that a DW with a price of say THB 1 can be broken down into 4 smaller parts each worth THB 0.25, by using a DWPS of 4.',

    pageDescription2: "เมื่อคำนวณจากค่าตัวแปร หรือ อัตราส่วนต่างๆ เช่น Delta, พรีเมี่ยม และอื่นๆ มักจะต้องใช้จำนวน DWPS เข้าไปรวมคำนวณด้วย อย่างไรก็ตามในเว็บไซต์นี้ เราได้คำนวณค่าเหล่านี้ไว้พร้อมๆกับค่าตัวแปรต่างๆที่คุณต้องการใช้ในการตัดสินใจลงทุน",
    pageDescription2En: 'When calculating many indicators or ratios, such as delta, premium, etc you often need to take into account the DWPS number in the calculator. However, throughout this website, we have already done this for you and provided all the indicators you will need to make your decisions.',

    imgUrl: "/img/education/img/table_dwps_th.png",
    imgUrlEn: "/img/education/img/table_dwps.png",

    pageDescription3: "DW ทั้ง 3 รุ่นในตารางข้างต้น มีราคาใช้สิทธิ วันครบกำหนดอายุ และเงื่อนไขอื่นเหมือนกัน ต่างกันเฉพาะ DWPS เท่านั้น", 
    pageDescription3En: 'All three DW in the table above have the same exercise price, expiry date etc. with DWPS the only difference between them.',

    pageDescription4: "“ราคา DW” และ “Delta ต่อ DW” ได้มาจากการคำนวณด้วยราคาและค่า Delta และหารด้วย DWPS ของแต่ละรุ่น",
    pageDescription4En: 'The "DW price" and the "delta per DW" are derived by taking the price and delta and diving by their respective DWPS.',

    pageDescription5: "สังเกตว่า DW-A มี DWPS เท่ากับ 1 นั่นคือต้องใช้ DW-A 1 หน่วยเพื่อใช้แลกเป็นหุ้นอ้างอิง 1 หุ้น เมื่อครบกำหนดอายุ ดังนั้น ราคาจึงสูงเป็น 4 เท่าของ DW-C ซึ่งมี DWPS เท่ากับ 4",
    pageDescription5En: "Notice that DW-A has an DWPS of 1 and requires 1 DW to exchange for one underlying share at expiry. Therefore, it's price and delta are 4 times that of DW-C which has a DWPS of 4.",

    pageDescription6: "ประเด็นสำคัญที่ต้องเข้าใจคือ DW ที่มี DWPS ต่ำๆ จะมีค่า Delta ต่อ DW สูง ซึ่ง DW เหล่านี้มักจะมีราคาแพงกว่า แต่เคลื่อนไหวในอัตรา % เท่ากัน ซึ่งหมายถึง ถ้าคุณซื้อ DW ตัวใดตัวหนึ่งข้างต้น ผลตอบแทนที่คุณจะได้รับคิดเป็น % จะมีค่าเท่ากัน จากตัวอย่างนี้ DW แต่ละรุ่นจะเคลื่อนไหวประมาณ 2% เมื่อหุ้นอ้างอิงเปลี่ยนแปลงไป 0.25 บาท",
    pageDescription6En: 'The important point to understand is that while warrants with the lower DWPS will have a higher delta per warrant, they are also more expensive and so will move the same in percentage terms. Meaning, if you purchased any of the above warrants, the return you will receive in percentage terms will be exactly the same. In this example, each of the warrants will move approximately 2% for a THB 0.25 move in the underlying.',

    pageDescription7: "ดังนั้น เมื่อ DWPS เป็นส่วนประกอบสำคัญกับการคำนวณอัตราต่างๆ เช่น Delta, Sensitivity, จุดคุ้มทุนเมื่อครบกำหนดอายุ และอื่นๆ นักลงทุนจึงไม่ควรนำ DWPS มาเป็นปัจจัยหลักที่ใช้ในการตัดสินใจของนักลงทุนในการเลือกลงทุนใน DW",
    pageDescription7En: 'So while the DWPS is important for calculating ratios such as delta, sensitivity, breakeven price at expiry etc., it should not be a major factor in the decision making process for most DW investors who are choosing a DW.',


}