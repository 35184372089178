export const languageText ={

    // Page Top Heading And Description Start //
    pageTitle: "DW ณ วันครบกำหนดอายุ",
    pageTitleEn: "DW at expiry",

    pageDescription: "สามารถคลิกหัวข้อเพื่ออ่านข้อมูลเพิ่มเติม",
    pageDescriptionEn: "Please click on each of the tiles below for more details.",
    // Page Top Heading And Description End //

    // Hexa Box Content Start //
    lastTradingDate: "วันซื้อขายสุดท้าย",
    lastTradingDateEn: "Last trading day",

    settlementValue: "เงินสดส่วนต่างจากการใช้สิทธิ",
    settlementValueEn: "Settlement value",
    settlementValue1: " DW",
    settlementValue1En: " for DW",

    callExample: "ตัวอย่าง Call DW",
    callExampleEn: "Call example",

    putExample: "ตัวอย่าง Put DW",
    putExampleEn: "Put example",
    // Hexa Box Content End //
}