import React from 'react'
import IndividualTip from './IndividualTip'
import "./individualTip.css"

const IndividualTips = () => {
  return (
    <IndividualTip/>
  )
}

export default IndividualTips