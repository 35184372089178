export const languageText ={
    
    firstNavigation: "สถิติ DW ",
    firstNavigationEn: "DW statistics ",
    secondNavigation: " Top traded DW",
    secondNavigationEn: " Top traded DW",

    pageTitle: "Top traded DW",
    pageTitleEn: "Top traded DW",
    pageDescription: "ตารางแสดง 10 อันดับ DW ที่มีปริมาณการซื้อขายสูงสุดในแต่ละวัน",
    pageDescriptionEn: "Shows the top 10 DW for the day, by traded volume.",

    callTab: "DW ที่เกี่ยวข้อง",
    callTabEn: "Call DW",
    putTab: "Put DW",
    putTabEn: "Put DW",

    tableSymbol: "ชื่อย่อ",
    tableSymbolEn: "Symbol",
    tableUnderlying: "หลักทรัพย์อ้างอิง",
    tableUnderlyingEn: "Underlying",
    tableIssuer: "ผู้ออก",
    tableIssuerEn: "Issuer",
    tableType: "ประเภท",
    tableTypeEn: "Type",
    tableBid: "ราคาเสนอซื้อ (บาท)",
    tableBidEn: "Bid (THB)",
    tableChange: "เปลี่ยนแปลง (%)",
    tableChangeEn: "Change (%)",
    tableTurnover: "มูลค่าการซื้อขาย ('000 บาท)",
    tableTurnoverEn: "Turnover ('000 THB)",
    tableVolume: "ปริมาณการซื้อขาย ('000)",
    tableVolumeEn: "Volume ('000)",
    tableStrike: "ราคาใช้สิทธิ",
    tableStrikeEn: "Strike",
    tableRatio: "Ratio",
    tableRatioEn: "Ratio",
    tableFirstTrade: "เทรดวันแรก",
    tableFirstTradeEn: "First trade",
    tableLastTrade: "วันซื้อขายสุดท้าย",
    tableLastTradeEn: "Last trade",

    aboveTable: "The above price is the indicative value.",
    aboveTableEn: "The above price is the indicative value.",
    marketDelay: "ข้อมูลตลาดทั้งหมดล่าช้าประมาณ 15 นาที",
    marketDelayEn: "All market data is delayed 15 mins.",

    macquarieOnly: "เฉพาะ DW28",
    macquarieOnlyEn: "Macquarie only",

    topGainer: "Top gainers",
    topGainerEn: "Top gainers",
    topLoser: "Top losers",
    topLoserEn: "Top losers",
}