import {Link} from "react-router-dom"
import { languageText } from "../../../languageText/dwCompare"


function getData(fieldName, data) {
  const fieldNameSplit = fieldName.split("-");
  const fieldNameUse = fieldNameSplit[fieldNameSplit.length - 1];
  if (fieldNameUse in data) {
    return data[fieldNameUse];
  }
  return "noData";
}
  
const tableConfigEn = {
  fields: [
    {
      className: "rw-ticker",
      dataHasTip: "0",
      name: languageText.symbolTitleEn,
      columnData: function (data, index) {
        const value = getData("ric", data);
        const href = "/tools/livematrix/" + data.ticker;
        return (
          <td key={this.dataHasTip + "-" + index}>
            <a href={href}>{data.ticker}</a>
          </td>
        );
      },
      tooltip: function () {
        return (
          <div className="bg-tooltip">
            <p className="tooltip-content" dangerouslySetInnerHTML={{__html: languageText.symbolTooltipEn}} />
          </div>
        );
      },
    },
    {
      className: "rw-issuer",
      dataHasTip: "1",
      name: languageText.issuerTitleEn,
      columnData: function (data, index) {
        // const tickerName = getData("issuer", data);
        const issueCode = getData("issuer", data);
        // const underlying_ricValue = getData("underlying_ric", data);
        // const href =
        //   "/my/tools/underlying/" +
        //   underlying_ricValue;
        return (
          <td key={this.dataHasTip + "-" + index} className={issueCode == "Macquarie" ? "text-bold" : ""}>
            {issueCode}
          </td>
        );
      },
      tooltip: function () {
        return (
          <div className="bg-tooltip">
            <p className="tooltip-content" dangerouslySetInnerHTML={{__html: languageText.issuerTooltipEn}} /> 
          </div>
        );
      },
    },
    {
      className: "rw-type",
      dataHasTip: "2",
      name: languageText.typeTitleEn, 
      columnData: function (data, index) {
        // const issueCode = getData("issuer_code", data);
        // const value = getData(this.className, data);
        return (
          <td key={this.dataHasTip + "-" + index} >
            {data.type}
          </td>
        );
      },
      tooltip: function () {
        return (
          <div className="bg-tooltip">
            <p className="tooltip-content" dangerouslySetInnerHTML={{__html: languageText.typeTooltipEn}} />
          </div>
        );
      },
    },
    
    {
      className: "rw-exercise_price",
      dataHasTip: "3",
      name: languageText.exercisePriceTitleEn,
      columnData: function (data, index) {
        
        // const issueCode = getData("issuer_code", data);
        // const value = getData("ric", data);
        // const href = "/tools/livematrix/#" + value;
        return (
          <td key={this.dataHasTip + "-" + index}>
            {data.exercise_price}
          </td> 
        );
      },
      tooltip: function () {
        return (
          <div className="bg-tooltip">
            <p className="tooltip-content" dangerouslySetInnerHTML={{__html: languageText.exercisePriceTooltipEn}} />
          </div>
        );
      },
    },
    {
      className: "rw-last_trading_date",
      dataHasTip: "4",
      name: languageText.lastTradingDateTitleEn,
      columnData: function (data, index) {
        const value = getData("last_trading_date", data);
        return  <td key={this.dataHasTip + "-" + index}>
                  {value}
                </td>;
      },
      tooltip: function () {
        return (
          <div className="bg-tooltip">
            <p className="tooltip-content" dangerouslySetInnerHTML={{__html: languageText.lastTradingDateTooltipEn}} />
          </div>
        );
      },
    },
    {
      className: "rw-wrnt_per_share",
      dataHasTip: "5",
      name: languageText.ratioTitleEn,
      columnData: function (data, index) {
        const value = getData("wrnt_per_share", data);
        return <td key={this.dataHasTip + "-" + index}>{value}</td>;
      },
      tooltip: function () {
        return (
          <div className="bg-tooltip">
            <p className="tooltip-content" dangerouslySetInnerHTML={{__html: languageText.ratioTooltipEn}} />
          </div>
        );
      },
    },
    {
      className: "rw-TRDPRC_1",
      dataHasTip: "6",
      name: languageText.lastTitleEn,
      columnData: function (data, index) {
        const value = getData("TRDPRC_1", data);
        return <td key={this.dataHasTip + "-" + index}>{value}</td>;
      },
      tooltip: function () {
        return (
          <div className="bg-tooltip">
            <p className="tooltip-content" dangerouslySetInnerHTML={{__html: languageText.lastTooltipEn}} />
          </div>
        );
      },
    },
    {
      className: "rw-BID",
      dataHasTip: "7",
      name: languageText.bidTitleEn,
      columnData: function (data, index) {
        const value = getData("BID", data);
        return <td key={this.dataHasTip + "-" + index}>{value}</td>;
      },
      tooltip: function () {
        return (
          <div className="bg-tooltip">
            <p className="tooltip-content" dangerouslySetInnerHTML={{__html: languageText.bidTooltipEn}} />
          </div>
        );
      },
    },
    {
      className: "rw-ASK",
      dataHasTip: "8",
      name: languageText.askTitleEn,
      columnData: function (data, index) {
        const value = getData("ASK", data);
        return <td key={this.dataHasTip + "-" + index}>{value}</td>;
      },
      tooltip: function () {
        return (
          <div className="bg-tooltip">
            <p className="tooltip-content" dangerouslySetInnerHTML={{__html: languageText.askTooltipEn}} />
          </div>
        );
      },
    },
    {
      className: "rw-HST_CLOSE",
      dataHasTip: "9",
      name: languageText.priorTitleEn,
      columnData: function (data, index) {
        const value = getData("HST_CLOSE", data);
        return <td key={this.dataHasTip + "-" + index}>{value}</td>;
      },
      tooltip: function () {
        return (
          <div className="bg-tooltip">
            <p className="tooltip-content" dangerouslySetInnerHTML={{__html: languageText.priorTooltipEn}} />
          </div>
        );
      },
    },
    {
      className: "rw-NETCHNG_1",
      dataHasTip: "10",
      name: languageText.changeTitleEn,
      columnData: function (data, index) {
        const value = getData("NETCHNG_1", data);
        return <td key={this.dataHasTip + "-" + index}>{value}</td>;
      },
      tooltip: function () {
        return (
          <div className="bg-tooltip">
            <p className="tooltip-content" dangerouslySetInnerHTML={{__html: languageText.changeTooltipEn}} />
          </div>
        );
      },
    },
    {
      className: "rw-moneyness_c",
      dataHasTip: "11",
      name: languageText.moneynessTitleEn,
      columnData: function (data, index) {
        const moneyness = getData("moneyness_c", data);
        // const percent = getData("percent_moneyness", data);
        // let moneynessName = "ATM";
        // if (moneyness === "1") {
        //   moneynessName = "ITM";
        // } else if (moneyness === "2") {
        //   moneynessName = "OTM";
        // }
        // let upDown = "val ";
        // if (moneynessName === "OTM") {
        //   upDown += "down";
        // } else if (moneynessName === "ITM") {
        //   upDown += "up";
        // }
        // const value = percent + "% " + moneynessName;
        return (
          <td key={this.dataHasTip + "-" + index} className={moneyness.includes("ITM") ? "up" : moneyness.includes("OTM") ? "down" : ""}>
            {moneyness}
          </td>
        );
      },
      tooltip: function () {
        return (
          <div className="bg-tooltip">
            <p className="tooltip-content" dangerouslySetInnerHTML={{__html: languageText.moneynessTooltipEn}} />
          </div>
        );
      },
    },
    // {
    //   className: "rw-underlying_price",
    //   dataHasTip: "12",
    //   name: "สถานะ	",
    //   columnData: function (data, index) {
    //     const value = getData(this.className, data);
    //     return <td key={this.dataHasTip + "-" + index}>{value}</td>;
    //   },
    //   tooltip: function () {
    //     return (
    //       <div className="bg-tooltip">
    //         <p className="tooltip-content">
    //           <strong>สถานะ	:</strong> ITM (In-the-money) OTM (Out-of-the-money) ATM (At-the-money) <Link to="/"> คลิกที่นี่ </Link> เพื่ออ่านรายละเอียดเพิ่มเติม
    //         </p>
    //       </div>
    //     );
    //   },
    // },
    {
      className: "rw-underlying_price",
      dataHasTip: "12",
      name: languageText.underlyingBidPriceTitleEn,
      columnData: function (data, index) {
        const value = getData("underlying_price", data);
        return <td key={this.dataHasTip + "-" + index}>{value}</td>;
      },
      tooltip: function () {
        return (
          <div className="bg-tooltip">
            <p className="tooltip-content" dangerouslySetInnerHTML={{__html: languageText.underlyingBidPriceTooltipEn}} />
          </div>
        );
      },
    },
    {
      className: "rw-ACVOL_1",
      dataHasTip: "13",
      name: languageText.volumeTitleEn,
      columnData: function (data, index) {
        const value = getData("ACVOL_1", data);
        // let upDown = "val ";
        // if (value.indexOf("-") > -1) {
        //   upDown += "down";
        // } else if (value.indexOf("+") > -1) {
        //   upDown += "up";
        // }
        return (
          <td key={this.dataHasTip + "-" + index}>
            {value}
          </td>
        );
      },
      tooltip: function () {
        return (
          <div className="bg-tooltip">
            <p className="tooltip-content" dangerouslySetInnerHTML={{__html: languageText.volumeTooltipEn}} />
          </div>
        );
      },
    },
    {
      className: "rw-effective_gearing",
      dataHasTip: "14",
      name: languageText.effectiveGearingTitleEn,
      columnData: function (data, index) {
        const value = getData("effective_gearing", data);
        return <td key={this.dataHasTip + "-" + index}>{value}</td>;
      },
      tooltip: function () {
        return (
          <div className="bg-tooltip">
            <p className="tooltip-content" dangerouslySetInnerHTML={{__html: languageText.effectiveGearingTooltipEn}} />
          </div>
        );
      },
    },
    {
      className: "rw-sensitivity",
      dataHasTip: "15",
      name: languageText.sensitivityTitleEn,
      columnData: function (data, index) {
        const value = getData("sensitivity", data);
        return <td key={this.dataHasTip + "-" + index}>{value}</td>;
      },
      tooltip: function () {
        return (
          <div className="bg-tooltip">
            <p className="tooltip-content" dangerouslySetInnerHTML={{__html: languageText.sensitivityTooltipEn}} />
          </div>
        );
      },
    },
    {
      className: "rw-delta",
      dataHasTip: "16",
      name: languageText.deltaTitleEn,
      columnData: function (data, index) {
        const value = getData("delta", data);
        return <td key={this.dataHasTip + "-" + index}>{value}</td>;
      },
      tooltip: function () {
        return (
          <div className="bg-tooltip">
            <p className="tooltip-content" dangerouslySetInnerHTML={{__html: languageText.deltaTooltipEn}} />
          </div>
        );
      },
    },
    {
      className: "rw-delta_dw",
      dataHasTip: "17",
      name: languageText.deltaPerDwTitleEn,
      columnData: function (data, index) {
        const value = getData("delta_dw", data);
        return <td key={this.dataHasTip + "-" + index}>{value}</td>;
      },
      tooltip: function () {
        return (
          <div className="bg-tooltip">
            <p className="tooltip-content" dangerouslySetInnerHTML={{__html: languageText.deltaPerDwTooltipEn}} />
          </div>
        );
      },
    },
    {
      className: "rw-premium",
      dataHasTip: "18",
      name: languageText.premiumTitleEn,
      columnData: function (data, index) {
        const value = getData("premium", data);
        return <td key={this.dataHasTip + "-" + index}>{value}</td>;
      },
      tooltip: function () {
        return (
          <div className="bg-tooltip">
            <p className="tooltip-content" dangerouslySetInnerHTML={{__html: languageText.premiumTooltipEn}} />
          </div>
        );
      },
    },
    {
      className: "rw-implied_volatility",
      dataHasTip: "19",
      name: languageText.impliedVolatilityTitleEn,
      columnData: function (data, index) {
        const value = getData("implied_volatility", data);
        return <td key={this.dataHasTip + "-" + index}>{value}</td>;
      },
      tooltip: function () {
        return (
          <div className="bg-tooltip">
            <p className="tooltip-content" dangerouslySetInnerHTML={{__html: languageText.impliedVolatilityTooltipEn}} />
          </div>
        );
      },
    },
    {
      className: "rw-theta",
      dataHasTip: "20",
      name: languageText.timeDecayTitleEn,
      columnData: function (data, index) {
        const value = getData("theta", data);
        return <td key={this.dataHasTip + "-" + index}>{value}</td>;
      },
      tooltip: function () {
        return (
          <div className="bg-tooltip">
            <p className="tooltip-content" dangerouslySetInnerHTML={{__html: languageText.timeDecayTooltipEn}} />
          </div>
        );
      },
    },
  ],
};

export default tableConfigEn;
  