import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { languageText } from "../../../languageText/indexDw"

const IndexDwPage = () => {

  const [siteLang , setSiteLang] = useState("")

  useEffect(() => {
    window.scrollTo(0, 0);
    var siteLanguage = $.cookie('applang');
    setSiteLang(siteLanguage)
    if(siteLanguage == "eng"){
      document.querySelector("body").classList.add("eng")
    }else{
      document.querySelector("body").classList.remove("eng")
    }
  }, []);

  return (
    <div id="indexdw" className="pageobj">
      <div className="breadcrumbbar">
        <div className="container-fluid">
          <ol className="breadcrumb">
            <li>
              <Link to="/home?dwedu">{(siteLang == "eng") ? languageText.firstNavigationEn : languageText.firstNavigation}</Link>
            </li>
            <li className="active">{(siteLang == "eng") ? languageText.secondNavigationEn : languageText.secondNavigation}</li>
          </ol>
        </div>
      </div>
      <div className="stripe-bg" />
      <div className="container-fluid">
        <div className="section">
          <div className="page-header">
            <h1>{(siteLang == "eng") ? languageText.pageTitleEn : languageText.pageTitle}</h1>
          </div>
          <p>
            {(siteLang == "eng") ? languageText.pageDescriptionEn : languageText.pageDescription}
          </p>
          <div>
            <table className="tablearea first">
              <tbody>
                <tr>
                  <td className="td-text altarea">
                    <div className="inner_title color-primary">
                      {(siteLang == "eng") ? languageText.leadingIssuerTitleEn : languageText.leadingIssuerTitle}
                    </div>
                    <div>
                      <p>
                        {(siteLang == "eng") ? languageText.leadingIssuerDescriptionEn : languageText.leadingIssuerDescription}
                      </p>
                      <ul>
                        <li>
                          {(siteLang == "eng") ? languageText.leadingIssuer1LiEn : languageText.leadingIssuer1Li}
                        </li>
                        <li>
                          {(siteLang == "eng") ? languageText.leadingIssuer2LiEn : languageText.leadingIssuer2Li}
                        </li>
                        <li>
                          {(siteLang == "eng") ? languageText.leadingIssuer3LiEn : languageText.leadingIssuer3Li}
                        </li>
                      </ul>
                    </div>
                  </td>
                  <td>
                    <img src="/img/education/u17900.jpg" alt="indexdw" />
                  </td>
                </tr>
              </tbody>
            </table>
            <table className="tablearea second">
              <tbody>
                <tr>
                  <td>
                    <img src="/img/education/u17902.jpg" alt="indexdw" />
                  </td>
                  <td className="td-text">
                    <div className="inner_title color-primary">
                      {(siteLang == "eng") ? languageText.liveMatrixTitleEn : languageText.liveMatrixTitle}
                    </div>
                    <div>
                      <p dangerouslySetInnerHTML={{__html: siteLang == "eng" ? languageText.liveMatrixDescriptionEn : languageText.liveMatrixDescription}} />
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <table className="tablearea third">
              <tbody>
                <tr>
                  <td className="td-text altarea">
                    <div className="inner_title color-primary">
                      {(siteLang == "eng") ? languageText.whatSet50TitleEn : languageText.whatSet50Title}
                    </div>
                    <div className="hidden-xs">
                      <p dangerouslySetInnerHTML={{__html: siteLang == "eng" ? languageText.whatSet50DescriptionEn : languageText.whatSet50Description}} />
                    </div>
                    <div className="visible-xs" dangerouslySetInnerHTML={{__html: siteLang == "eng" ? languageText.whatSet50DescriptionMobEn : languageText.whatSet50DescriptionMob}} />
                  </td>
                  <td>
                    <img src="/img/education/u17904.jpg" alt="indexdw" />
                  </td>
                </tr>
              </tbody>
            </table>
            <table className="tablearea fourth">
              <tbody>
                <tr>
                  <td>
                    <img src="/img/education/u17906.jpg" alt="indexdw" />
                  </td>
                  <td className="td-text">
                    <div className="inner_title color-primary">
                      {(siteLang == "eng") ? languageText.whyInvestTitleEn : languageText.whyInvestTitle}
                    </div>
                    <div>
                      <ul>
                        <li>{(siteLang == "eng") ? languageText.whyInvest1LiEn : languageText.whyInvest1Li}</li>
                        <li>{(siteLang == "eng") ? languageText.whyInvest2LiEn : languageText.whyInvest2Li}</li>
                        <li>{(siteLang == "eng") ? languageText.whyInvest3LiEn : languageText.whyInvest3Li}</li>
                        <li>{(siteLang == "eng") ? languageText.whyInvest4LiEn : languageText.whyInvest4Li}</li>
                      </ul>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
            <table className="tablearea fifth altarea">
              <tbody>
                <tr>
                  <td className="td-text">
                    <div className="inner_title color-primary">
                      {(siteLang == "eng") ? languageText.howTradeTitleEn : languageText.howTradeTitle}
                    </div>
                    <div>
                      <p dangerouslySetInnerHTML={{__html: siteLang == "eng" ? languageText.howTradeDescriptionEn : languageText.howTradeDescription}} />
                    </div>
                  </td>
                  <td className="td-text">
                    <table className="setdw-table table table-striped-inverse">
                      <thead>
                        <tr>
                          <th />
                          <th>SET50 Index Futures</th>
                          <th>S5028C1406A</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <td>22 Apr</td>
                          <td>950.0</td>
                          <td>0.81</td>
                        </tr>
                        <tr>
                          <td>29 Apr</td>
                          <td>982.0</td>
                          <td>1.01</td>
                        </tr>
                        <tr>
                          <td />
                          <td className="text-bold">3.4%</td>
                          <td className="text-bold">24.7%</td>
                        </tr>
                      </tbody>
                    </table>
                    <p dangerouslySetInnerHTML={{__html: siteLang == "eng" ? languageText.howTradeDescriptionRightEn : languageText.howTradeDescriptionRight}} />
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="learnmore">
            <img className="learnmore-img hidden-xs" src="/img/education/u17959.jpg" alt="indexdw" />
            <img className="learnmore-img visible-xs" src="/img/education/u17888.png" alt="indexdw" />
            <div className="learnmore-wrapper">
              <div className="learmore-title">
                {(siteLang == "eng") ? languageText.learnMoreTitleEn : languageText.learnMoreTitle}
              </div>
              <Link target="__blank" to="/eduhtml">
                {(siteLang == "eng") ? languageText.learnMoreButtonEn : languageText.learnMoreButton}
              </Link>
            </div>
          </div>

          <hr className="hr-hmgbm" />
          <p className="small">
            {(siteLang == "eng") ? languageText.marketDelayEn : languageText.marketDelay}
          </p>
        </div>
      </div>
    </div>
  );
};
export default IndexDwPage;
