import React, { useEffect, useState } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import InvestorAcademyCommon from "../../../../components/common/InvestorAcademyCommon";
// import LangContext from "../../../../components/common/context/lang-context";
// import { useContext } from "react";
import "../common.css";
import "../investorAcademy.css";
import { languageText } from "../../../../languageText/InvestorAcademy/WhatAreWarrants/index"
import { languageTextMain } from "../../../../languageText/InvestorAcademy/index" 

const WhatAreWarrants = (props) => {
  
  // const langContext = useContext(LangContext);
  const location = useLocation();
  const history = useHistory();
  const [siteLang , setSiteLang] = useState("")

  useEffect(() => {
    window.scrollTo(0, 0);
    var siteLanguage = $.cookie('applang');
    setSiteLang(siteLanguage)
    if(siteLanguage == "eng"){
      document.querySelector("body").classList.add("eng")
    }else{
      document.querySelector("body").classList.remove("eng")
    }
  }, []);

  return (
    <div id="investor_academy">
      <div className="imgbg" />
      <div className="mainbg" />
      <div id="main">
        <div id="maintable">
          <div style={{ display: "table-row" }}>
            <div id="leftbar" className="hidden-xs" />
            <div id="maincontainer">
              <div id="mainnav">
                <table>
                  <tbody>
                    <tr>
                      <td width="20%" style={{textAlign:"left", visibility: "hidden"}} />
                      <td width="60%" style={{ textAlign: "center" }}>
                        <div id="pagetitlediv">
                          <div id="ptitle">
                            <img height="56px" src="/img/education/img/02.png" alt="Investor" />
                          </div>
                          <div id="pname">
                            {siteLang == "eng" ? languageText.pageTitleEn : languageText.pageTitle}
                          </div>
                        </div>
                      </td>
                      <td width="20%" style={{textAlign:"right", visibility: "hidden"}} />
                    </tr>
                  </tbody>
                </table>
              </div>
              <div id="maincontent">
                <div id="mainscroll">
                  <div id="mainscrollcontent">
                    <style
                      dangerouslySetInnerHTML={{__html:" \n .hexrow.odd{ \n margin-left: 73px; \n } \n ",}}
                    />
                    <div className="contentbox">
                      <p className="showtile">
                        {siteLang == "eng" ? languageText.pageDescriptionEn : languageText.pageDescription}
                      </p>
                      <div className="hexmenu hidden-xxs hidden-xs">
                        <div className="imgmask">
                          <img className="hexbg" src="/img/topic/what_are_wrnt.png" />
                          <div className="hexarea" style={{top: '15%', left: '3%', right: '68%', bottom: '59%'}}>
                            <div className="topictxt">
                              <Link to="/eduhtml/callvsput">
                                {siteLang == "eng" ? languageText.callVsPutEn : languageText.callVsPut}
                              </Link>
                            </div>
                          </div>
                          <div className="hexarea" style={{top: '15%', left: '68%', right: '1%', bottom: '59%'}}>
                            <div className="topictxt">
                              <Link to="/eduhtml/howacallwarrantworks">
                                {siteLang == "eng" ? languageText.howCallWarrantEn : languageText.howCallWarrant}
                                <br />
                                {siteLang == "eng" ? languageText.howCallWarrant1En : languageText.howCallWarrant1}
                              </Link>
                            </div>
                          </div>
                          <div className="hexarea" style={{top: '56%', left: '19%', right: '51%', bottom: '18%'}}>
                            <div className="topictxt">
                              <Link to="/eduhtml/howaputwarrantworks">
                                {siteLang == "eng" ? languageText.howPutWarrantEn : languageText.howPutWarrant}
                                <br />
                                {siteLang == "eng" ? languageText.howPutWarrant1En : languageText.howPutWarrant1}
                              </Link>
                            </div>
                          </div>
                          <div className="hexarea" style={{top: '56%', left: '52%', right: '19%', bottom: '18%'}}>
                            <div className="topictxt">
                              <Link to="/eduhtml/howarewarrantsnamed">
                                {siteLang == "eng" ? languageText.howAreWarrantEn : languageText.howAreWarrant}
                                <br />
                                {siteLang == "eng" ? languageText.howAreWarrant1En : languageText.howAreWarrant1}
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="mobilemenu visible-xxs visible-xs">
                        <div className="menuitem">
                          <img className="menuarrow" src="/img/mobilemenu/arrow.png" />
                          <img className="menuicon" src="/img/mobilemenu/call_vs_put.png" />
                          <div className="menutitle">
                            <Link to="/eduhtml/callvsput">
                              {siteLang == "eng" ? languageText.callVsPutEn : languageText.callVsPut}
                            </Link>
                          </div>
                        </div>
                        <div className="menuitem">
                          <img className="menuarrow" src="/img/mobilemenu/arrow.png" />
                          <img className="menuicon" src="/img/mobilemenu/how_a_call_warrant_works.png" />
                          <div className="menutitle twoline">
                            <Link to="/eduhtml/howacallwarrantworks">
                              {siteLang == "eng" ? languageText.howCallWarrantMobileEn : languageText.howCallWarrantMobile}
                            </Link>
                          </div>
                        </div>
                        <div className="menuitem">
                          <img className="menuarrow" src="/img/mobilemenu/arrow.png" />
                          <img className="menuicon" src="/img/mobilemenu/how_a_put_warrant_works.png" />
                          <div className="menutitle twoline">
                            <Link to="/eduhtml/howaputwarrantworks">
                              {siteLang == "eng" ? languageText.howPutWarrantMobileEn : languageText.howPutWarrantMobile}
                            </Link>
                          </div>
                        </div>
                        <div className="menuitem">
                          <img className="menuarrow" src="/img/mobilemenu/arrow.png" />
                          <img className="menuicon" style={{maxHeight: '46px'}} src="/img/mobilemenu/how_are_warrants_named.png" />
                          <div className="menutitle twoline">
                            <Link to="/eduhtml/howarewarrantsnamed">
                              {siteLang == "eng" ? languageText.howAreWarrantMobileEn : languageText.howAreWarrantMobile}
                            </Link>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div id="rightbar" className="hidden-xs" />
          </div>
        </div>
      </div>

      <div id="bottom">
        <div className="hidden-xs hidden-xxs" id="copyright">
          {siteLang == "eng" ? languageTextMain.copyRightEn : languageTextMain.copyRight}
        </div>
        <div className="leftUrl">
          <table className="hasprev">
            <tbody>
              <tr>
                <td width="33px">
                  <Link to="/eduhtml/easeoftrade">
                    <div className="prev left" />
                  </Link>
                </td>
                <td>
                  <div className="prevlbl left">
                    <div className="prevname">
                      <Link to="/eduhtml/easeoftrade">
                        {siteLang == "eng" ? languageTextMain.easeOfTradeEn : languageTextMain.easeOfTrade}
                      </Link>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="rightUrl">
          <table className="hasnext">
            <tbody>
              <tr>
                <td>
                  <div className="nextlbl right">
                    <div className="nextname">
                      <Link to="/eduhtml/callvsput">
                        {siteLang == "eng" ? languageTextMain.callPutEn : languageTextMain.callPut}
                      </Link>
                    </div>
                  </div>
                </td>
                <td width="33px">
                  <Link to="/eduhtml/callvsput">
                    <div className="next right" />
                  </Link>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div id="dotbar" className="hidden-sm hidden-xs">
        <InvestorAcademyCommon Location={location} Hist={history} />
      </div>
    </div>
  );
};
export default WhatAreWarrants;
