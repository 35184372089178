export const languageText ={

    pageTitle: "ความเสี่ยง",
    pageTitleEn: "Risk",

    pageName: "Credit Risk",
    pageNameEn: "Credit Risk",

    pageDescription: 'ผู้ถือ DW มีความเสี่ยงด้าน Credit Risk จากผู้ออก DW <br><br> ในกรณี DW ของแมคควอรีนั้น จะได้รับการค้ำประกันการชำระหนี้จาก Macquarie Financial Holdings Pty Limited <a href="/about/aboutMac">คลิกที่นี่</a>',
    pageDescriptionEn: "A holder of a DW will be exposed to the credit risk of the issuer.<br><br> You can view Macquarie Group's most recent credit rating on our information page, <a href='/about/aboutMac'>click here</a>.",

    imgUrl: "/img/education/img/creditrisk11.png",
    imgUrlEn: "/img/education/img/creditrisk11.png",
}