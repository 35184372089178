import React  from 'react';
import DwTipsPage from './DwTips';
import './dwTips.css';


const DwTipsPages = (props) => {
    return ( 
        <DwTipsPage/>
     );
}
 
export default DwTipsPages;