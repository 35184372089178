export const languageText ={
    
    firstNavigation: "รอบรู้เรื่อง DW ",
    firstNavigationEn: "DW education ",
    secondNavigation: " คำถามที่พบบ่อย",
    secondNavigationEn: " FAQ",

    pageTitle: "คำถามที่พบบ่อย",
    pageTitleEn: "FAQ",
    pageDescription: "คำถามที่พบบ่อยเกี่ยวกับ DW เพื่อคลายข้อสงสัยของคุณ",
    pageDescriptionEn: "Frequently asked questions about DW",

    questionHeader: "Questions you may have",
    questionHeaderEn: "Questions you may have",
    
    marketDelay: "ข้อมูลตลาดทั้งหมดล่าช้าประมาณ 15 นาที",
    marketDelayEn: "All market data is delayed 15 mins.",
}