import React, { useEffect, useState } from "react";
import "./about.css"

const ContactUs = () => {

    const [siteLang , setSiteLang] = useState("")

    useEffect(() => {
        window.scrollTo(0, 0);
        var siteLanguage = $.cookie('applang');
        setSiteLang(siteLanguage)
        if(siteLanguage == "eng"){
          document.querySelector("body").classList.add("eng")
        }else{
          document.querySelector("body").classList.remove("eng")
        }
    }, [])

    return (
        <div id="contactus" className="pageobj">
            <div className="breadcrumbbar">
                <div className="container-fluid">
                    <ol className="breadcrumb">
                        <li><a>{siteLang == "eng" ? "Macquarie " : "Macquarie "}</a></li>
                        <li className="active">{siteLang == "eng" ? " Contact us" : " ติดต่อเรา"}</li>
                    </ol>
                </div>
            </div>
            <div className="stripe-bg" />
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className="section">
                            <div className="page-header">
                                <h1>{siteLang == "eng" ? "Contact us" : "ติดต่อเรา"}</h1>
                            </div>
                            <div className="row">
                                <div className="col-sm-3 outer_area">
                                    <img src="/img/about/icon-address.png" />
                                    <div className="inner_title">{siteLang == "eng" ? " Address" : " ที่อยู่"}</div>
                                    {siteLang == "eng" 
                                        ? <div>Macquarie Securities (Thailand) Limited<br />28th Floor, CRC Tower, All Seasons Place, 87/2 Wireless Road, Lumpini, Pathumwan, Bangkok, Thailand 10330</div>
                                     :<div>บริษัทหลักทรัพย์ แมคควอรี (ประเทศไทย) จำกัด 87/2 อาคารทาวเวอร์ ออลซีซั่น เพลส ถนนวิทยุ แขวงลุมพินี เขตปทุมวัน กรุงเทพมหานคร 10330</div>    
                                    }
                                    
                                </div>
                                <div className="col-sm-3 outer_area">
                                    <img src="/img/about/icon-hotline.png" />
                                    <div className="inner_title">Hotline</div>
                                    <div className="visible-xs"><a href="tel:02 694 7777">02 694 7777</a></div>
                                    <div className="hidden-xs">02 694 7777</div>
                                </div>
                                <div className="col-sm-3 outer_area">
                                    <img src="/img/about/icon-email.png" />
                                    <div className="inner_title">
                                        {siteLang == "eng" ? "Email" : "สอบถามข้อมูล"}<br />{siteLang == "eng" ? "" : "เกี่ยวกับ DW28"}
                                    </div>
                                    <div><a href="mailto:DW28@macquarie.com">DW28@macquarie.com</a></div>
                                </div>
                                <div className="col-sm-3 outer_area">
                                    <img src="/img/about/Contact_complaint.png" />
                                    <div className="inner_title">
                                        {siteLang == "eng" ? "สอบถามข้อมูลเกี่ยวกับนโยบาย" : "สอบถามข้อมูลเกี่ยวกับนโยบาย"}<br />{siteLang == "eng" ? "ความเป็นส่วนตัว / เรื่องร้องเรียน" : "ความเป็นส่วนตัว / เรื่องร้องเรียน"}
                                    </div>
                                    <div><a href="mailto:compliancebkk@macquarie.com">compliancebkk@macquarie.com</a></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
};
export default ContactUs;