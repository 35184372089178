import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom" 
import { languageText } from "../../../languageText/dwVideo";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SpeakerCarousel from "./GuestSpeakerCom"
import {Carousel} from 'react-responsive-carousel';
import EducationCarousel from "./educationCom"
import DailyMarketCarousel from "./DailyMarketCom"
import { config } from "../../../utils/apiUrl";
import API from "../../../utils/apiCalling";
import { get } from "lodash";
import { educationList } from "./EducationData"
import { testimonialList } from "./TestimonialData"
import { speakerList } from "./SpeakerData"

const DwVideoPage = () => {

  const api = new API();
  const [siteLang , setSiteLang] = useState("")
  const [eduTabActive , setEduTabActive] = useState(true)
  const [speakerTabActive , setSpeakerTabActive] = useState(false)
  const [testimonialTabActive , setTestimonialTabActive] = useState(false)
  const [dailyMarketTabActive , setDailyMarketTabActive] = useState(false)
  const [marketAnalysis, setMarketAnalysis] = useState([]);
  const [dmaPopUp, setDmaPopUp] = useState(false);
  const [popupVideoUrl, setPopupVideoUrl] = useState("");

  const settings = {
    autoplay: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: true,
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    var siteLanguage = $.cookie('applang');
    setSiteLang(siteLanguage)
    if(siteLanguage === "eng"){
      document.querySelector("body").classList.add("eng")
    }else{
      document.querySelector("body").classList.remove("eng")
    }
    DailyMarketAnalysisJSON();
  }, []);

  const DailyMarketAnalysisJSON = async () => {
    var items = [];
    let data = await api.get(config.dailyMarketAnalysisJSON, true, {
      qid: Date.now(),
    });
    let allData = get(data, "dma_list", []);
    if(allData?.length > 0) {
      for (let i = 0; i < allData.length; i++) {
        let advImgData = await api.get(config.advImgData, true, {
          dmaid: allData[i].id,
        });
        items.push({
          imgSrc: advImgData,
          id: allData[i].id,
          en_caption: allData[i].en_caption,
          en_title: allData[i].en_title,
          image: allData[i].image,
          link: allData[i].link,
          thai_caption: allData[i].thai_caption,
          thai_title: allData[i].thai_title,
        });
      }
    }
    setMarketAnalysis(items)
  }

  const setActiveTab = (data) => {
    if(data == "gs"){
      setEduTabActive(false)
      setSpeakerTabActive(true)
      setTestimonialTabActive(false)
      setDailyMarketTabActive(false)
    }else if(data == "tm"){
      setEduTabActive(false)
      setSpeakerTabActive(false)
      setTestimonialTabActive(true)
      setDailyMarketTabActive(false)
    }else if(data == "dma"){
      setEduTabActive(false)
      setSpeakerTabActive(false)
      setTestimonialTabActive(false)
      setDailyMarketTabActive(true)
    }else{
      setEduTabActive(true)
      setSpeakerTabActive(false)
      setTestimonialTabActive(false)
      setDailyMarketTabActive(false)
    }
  }

  const openTmlPage = (id, index) => {
    window.open(
      `/education/testimonial?playlistId=${id}&index=${index}`,
      '_blank'
    );
  }

  const openEdupage = (playlistId) => {
    window.open(
      `/education/dwvideoind?playlistId=${playlistId}&index=0`,
      '_blank'
    );
  };

  const openSpeakerpage = (playlistId) => {
    window.open(
      `/education/speaker?playlistId=${playlistId}&index=0`,
      '_blank'
    );
  };

  const openDmaVideo = (data) => {
    setPopupVideoUrl(data)
    setDmaPopUp(true)
    document.querySelector("body").style.overflow = "hidden"
  }

  const closePopUp = () => {
    setDmaPopUp(false)
    setPopupVideoUrl("")
    document.querySelector("body").style.overflow = "auto"
  }

  const slidesClickPageOpen = (dataPage, playlistId) => {
    var path = dataPage === "education" ? "/education/dwvideoind?playlistId=" : "/education/speaker?playlistId=";
    window.open(
      window.origin + path + playlistId + "&index=0",
      '_blank'
    );
  }

  return (
    <>
    <div id="dwvideo" className="pageobj">
      <div className="breadcrumbbar">
        <div className="container-fluid">
          <ol className="breadcrumb">
            <li>
              <Link to="/home?dwedu">{siteLang == "eng" ? languageText.firstNavigationEn : languageText.firstNavigation}</Link>
            </li>
            <li className="active">{siteLang == "eng" ? languageText.secondNavigationEn : languageText.secondNavigation}</li>
          </ol>
        </div>
      </div>
      <div className="stripe-bg" />
      <div className="container-fluid">
        <div className="section">
          <div className="page-header">
            <h1>{siteLang == "eng" ? languageText.pageTitleEn : languageText.pageTitle}</h1>
          </div>
          <p className="section-p">{siteLang == "eng" ? languageText.pageDescriptionEn : languageText.pageDescription}</p>
          <div id="videogallery">
            <Slider {...settings} >
              <div className="gallery-item" onClick={() => slidesClickPageOpen("education", "PLOkzCdnS95xSQp8yCiVe5MT8bV8cjAWZ5")}>
                <img style={{width: "100%"}} src="/img/education/DWvideo_Hero-Banner.jpg" />
              </div>
              <div className="gallery-item" onClick={() => slidesClickPageOpen("speaker", "PLOkzCdnS95xTS1EqkDqV4IIZdx4W8_N-c")}>
                <img style={{width: "100%"}} src="/img/education/video-banner-kpom.png" />
              </div>
              <div className="gallery-item" onClick={() => slidesClickPageOpen("speaker", "PLOkzCdnS95xR_4lZuCtlvMJoiazn3xgjP")}>
                <img style={{width: "100%"}} src="/img/education/video-banner-kvee.png" />
              </div>
              <div className="gallery-item" onClick={() => slidesClickPageOpen("speaker", "PLOkzCdnS95xTKCgNSigoHsH5Q987--t3p")}>
                <img style={{width: "100%"}} src="/img/education/video-banner-kgung.png" />
              </div>
              <div className="gallery-item" onClick={() => slidesClickPageOpen("speaker", "PLOkzCdnS95xR0dazX8WStrYtrBP0VOhSd")}>
                <img style={{width: "100%"}} src="/img/education/video-banner-kice.png" />
              </div>
            </Slider>
          </div>
          <div className="content hidden-xs">
            <div id="edurack" className="videorack videorack-custom">
              <div className="videorack-title">Education</div>
              <div className="videorack-body">
                <div className="videorack-content swiper-container swiper-container-horizontal">
                  <EducationCarousel />
                </div>
              </div>
            </div>
            <div id="dmarack" className="videorack videorack-custom">
              <div className="videorack-title">Daily Market Analysis</div>
              <div className="videorack-body">
                <DailyMarketCarousel />
              </div>
            </div>
            <div id="gsrack" className="videorack videorack-custom">
              <div className="videorack-title">Guest Speaker</div>
              <div className="videorack-body">
                <div className="videorack-content swiper-container swiper-container-horizontal">
                  <SpeakerCarousel />
                </div>
              </div>
            </div>
            <div id="testimonialrack" className="videorack videorack-custom">
              <div className="videorack-title">Testimonial</div>
              <div className="videorack-body">
                <div className="videorack-content swiper-container swiper-container-horizontal">
                <Carousel
                  showArrows={true}
                  emulateTouch
                  showStatus={false}
                  autoFocus
                  infiniteLoop={false}
                  showIndicators={false}
                  showThumbs={false}
                >
                  <table className="videorack-page">
                    <tbody>
                      <tr>
                        {testimonialList && testimonialList.map((data, index) => (
                          <td index={index} key={data.en_title}>
                            <div
                              playlistid={data.playlistId}
                              index={index}
                              className="videorack-item"
                              style={{ display: "inline-block" }}
                              onClick={() => openTmlPage(data.playlistId, index)}
                            >
                              <img style={{ width: "100%" }} src={data.image} alt={data.en_title} />
                              <div className="videorack-item-txt">
                                {siteLang == "eng" ? data.en_title : data.th_title}
                              </div>
                            </div>
                          </td>
                        ))}
                      </tr>
                    </tbody>
                  </table>
                </Carousel>
                </div>
              </div>
            </div>
          </div>
          <div className="content-xs visible-xs">
            <div id="videoTabs" className="tablist">
              <div>
                <table>
                  <tbody>
                    <tr>
                      <td className={eduTabActive ? "active" : ""}>
                        <div className="tablist-item" value="edu" onClick={() => setActiveTab("edu")}>
                          <div className="tablist-item-txt">Education</div>
                        </div>
                      </td>
                      <td className={speakerTabActive ? "active" : ""}>
                        <div className="tablist-item" value="gs" onClick={() => setActiveTab("gs")}>
                          <div className="tablist-item-txt">Speaker</div>
                        </div>
                      </td>
                      <td className={testimonialTabActive ? "active" : ""}>
                        <div className="tablist-item" value="tm" onClick={() => setActiveTab("tm")}>
                          <div className="tablist-item-txt">Testimonial</div>
                        </div>
                      </td>
                      <td className={dailyMarketTabActive ? "active" : ""}>
                        <div className="tablist-item" value="dma" onClick={() => setActiveTab("dma")}>
                          <div className="tablist-item-txt">Daily Market Analysis</div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div id="videotabcontent">
              {eduTabActive && <div id="edulist" className="videolist">
                {educationList && educationList.map((data, index) => (
                  <div 
                    playlistid={data.playlistId} 
                    index="0" 
                    className="videolist-item" 
                    key={index}
                    onClick={() => openEdupage(data.playlistId)}
                  >
                    <img
                      style={{ width: "100%" }}
                      src={data.image}
                    />
                    <div className="videolist-item-txt">
                      {siteLang == "eng" ? data.en_title : data.th_title}
                    </div>
                  </div>
                ))}
              </div>}
              {speakerTabActive && <div id="gslist" className="videolist">
                {speakerList && speakerList.map((data, index) => (
                  <div 
                    playlistid={data.playlistId} 
                    index={index} 
                    className="videolist-item" 
                    key={index}
                    onClick={() => openSpeakerpage(data.playlistId)}
                  >
                    <img
                      style={{ width: "100%" }}
                      src={data.image}
                    />
                    <div className="videolist-item-txt">
                      {data.text}
                    </div>
                  </div>
                ))}
              </div>}
              {testimonialTabActive && <div id="tmlist" className="videolist">
                {testimonialList && testimonialList.map((data, index) => (
                  <div 
                    playlistid={data.playlistId} 
                    index={index} 
                    className="videolist-item" 
                    key={index}
                    onClick={() => openTmlPage(data.playlistId, index)}
                  >
                    <img
                      style={{ width: "100%" }}
                      src={data.image}
                    />
                    <div className="videolist-item-txt">
                      {siteLang == "eng" ? data.en_title : data.th_title}
                    </div>
                  </div>
                ))}
              </div>}
              {dailyMarketTabActive && <div id="dmalist" className="videolist">
                {marketAnalysis && marketAnalysis.map((data, index) => (
                  <div dmaid={data.id} className="videolist-item" key={index} onClick={() => openDmaVideo(data.link)}>
                    {data.image == "true" ? <img style={{ width: "100%" }} src={data.imgSrc} />
                    :
                    <img style={{ width: "100%" }} src="" />}
                    <div className="videolist-item-txt">
                      {siteLang == "eng" ? data.en_title : data.thai_title}
                    </div>
                  </div>
                ))}
              </div>}
            </div>
          </div>
          <hr className="hr-hmgbm" />
          <p className="small">{siteLang == "eng" ? "All market data is delayed 15 mins." : "ข้อมูลตลาดทั้งหมดล่าช้าประมาณ 15 นาที"} </p>
        </div>
      </div>
    </div>
    {dmaPopUp && <div id="ppboxHTML2" className="overlay">
      <div className="overlay-inner">
        <div style={{ position: "relative" }}>
          <img onClick={closePopUp} className="btn-close" src="/img/bullet/icon-close-white.png" />
          <div className="popupbox-content">
            <div className="ppc" key="video">
              <div className="hotline-layer">
                <iframe frameBorder={0} allowFullScreen src={popupVideoUrl} />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="text-content" style={{ display: "none" }}>
        <div className="text_subscribe_registration_success">
          คุณได้ทำการสมัครสมาชิกเรียบร้อยแล้ว
          กรุณาตรวจสอบข้อมูลผู้ใช้และรหัสผ่านได้จากอีเมล์ที่ท่านใช้ในการสมัคร
        </div>
      </div>
    </div>}
    </>
  );
};
export default DwVideoPage;
