import React, { useEffect, useState } from "react";
import { Link, useLocation, useHistory } from "react-router-dom";
import ChildInvestorAcademyCommon from "../../../../../components/common/InvestorAcademyCommon/ChildInvestorAcademyCommon";
import "../../common.css";
import "../../investorAcademy.css";
import { languageText } from "../../../../../languageText/InvestorAcademy/MarketMakers/liquidityVolume"
import { languageTextMain } from "../../../../../languageText/InvestorAcademy/index";

const LiquidityVolumes = (props) => {
  
  const location = useLocation();
  const history = useHistory();
  const [siteLang , setSiteLang] = useState("")
  const [display , setDisplay] = useState("none")
  const [imgVal , setImgVal] = useState("")

  // Site Language Change Start //
  useEffect(() => {
    window.scrollTo(0, 0);
    var siteLanguage = $.cookie('applang');
    setSiteLang(siteLanguage)
    if(siteLanguage == "eng"){
      document.querySelector("body").classList.add("eng")
    }else{
      document.querySelector("body").classList.remove("eng")
    }
  }, []);
  // Site Language Change End //

  // View Chart Image Popup Open And Close Start //
  const openPopUp = (imgData) => {
    if(display == "none"){
      setImgVal(imgData)
      setDisplay("block")
    }else{
      setImgVal("")
      setDisplay("none")
    }
  }
  // View Chart Image Popup Open And Close End //

  return (
    <div id="investor_academy">
      <div className="imgbg" />
      <div className="mainbg" />
      <div id="main">
        <div id="maintable">
          <div style={{ display: "table-row" }}>
            <div id="leftbar" className="hidden-xs" />
            <div id="maincontainer">
              {/* Top Title Section Start */}
              <div id="mainnav">
                <table>
                  <tbody>
                    <tr>
                      <td width="20%" style={{textAlign:"left", visibility: "hidden"}} />
                      <td width="60%" style={{ textAlign: "center" }}>
                        <div id="pagetitlediv">
                          <div id="ptitle">{siteLang == "eng" ? languageText.pageTitleEn : languageText.pageTitle}</div>
                          <div id="pname">{siteLang == "eng" ? languageText.pageNameEn : languageText.pageName}</div>
                        </div>
                      </td>
                      <td width="20%" style={{textAlign:"right", visibility: "hidden"}} />
                    </tr>
                  </tbody>
                </table>
              </div>
              {/* Top Title Section End */}
              {/* Main Content Section Start */}
              <div id="maincontent">
                <div id="mainscroll">
                  <div id="mainscrollcontent">
                    <style dangerouslySetInnerHTML={{__html: ".contentbox table{margin: 0 auto;}.contentbox table thead th{padding:1em;  }.contentbox table tbody td,.contentbox table tbody th{padding:0.6em;color:#000000;text-align:center;}.contentbox table tbody td{background-color: #FFF4D2;}.contentbox table tbody th{background-color: #FFD400;}.contentbox .imgholder h3{font-size:16px;text-align: center;color:#FFDA44;}body.eng .contentbox .imgholder h3{font-family: 'MCQGlobal_A_Rg';line-height: 1.1;}" }} />
                    <div className="contentbox">
                      <p dangerouslySetInnerHTML={{ __html : siteLang == "eng" ? languageText.pageDescriptionEn : languageText.pageDescription}} />
                      <div className="imgholder">
                        <h3>{siteLang == "eng" ? languageText.pageDwATitleEn : languageText.pageDwATitle}</h3>
                        <img src={siteLang == "eng" ? languageText.imgUrlEn : languageText.imgUrl} />
                        <div class="visible-xs">
                          <button onClick={() => openPopUp(siteLang == "eng" ? languageText.imgUrlEn : languageText.imgUrl)}>View table</button>
                        </div>
                      </div>
                      <div className="imgholder">
                        <h3>{siteLang == "eng" ? languageText.pageDwBTitleEn : languageText.pageDwBTitle}</h3>
                        <img src={siteLang == "eng" ? languageText.imgUrl1En : languageText.imgUrl1} />
                        <div class="visible-xs">
                          <button onClick={() => openPopUp(siteLang == "eng" ? languageText.imgUrl1En : languageText.imgUrl1)}>View table</button>
                        </div>
                      </div>
                      <p dangerouslySetInnerHTML={{ __html : siteLang == "eng" ? languageText.pageDescription1En : languageText.pageDescription1}} />
                    </div>
                  </div>
                </div>
              </div>
              {/* Main Content Section End */}
            </div>
            <div id="rightbar" className="hidden-xs" />
          </div>
        </div>
      </div>
      {/* Bottom Left Right Links and Copyright Section Start */}
      <div id="bottom">
        <div className="hidden-xs hidden-xxs" id="copyright">
          {siteLang == "eng" ? languageTextMain.copyRightEn : languageTextMain.copyRight}
        </div>
        <div className="leftUrl">
          <table className="hasprev">
            <tbody>
              <tr>
                <td width="33px">
                  <Link to="/eduhtml/howtocalculatecashsettlementamount">
                    <div className="prev left" />
                  </Link>
                </td>
                <td>
                  <div className="prevlbl left">
                    <div className="prevname">
                      <Link to="/eduhtml/howtocalculatecashsettlementamount">
                        {siteLang == "eng" ? languageTextMain.howToCalculateCashSettlementAmountEn : languageTextMain.howToCalculateCashSettlementAmount}
                      </Link>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div className="rightUrl">
          <table className="hasnext">
            <tbody>
              <tr>
                <td>
                  <div className="nextlbl right">
                    <div className="nextname">
                      <Link to="/eduhtml/howdomarketmakershedge" dangerouslySetInnerHTML={{__html: siteLang == "eng" ? languageTextMain.howDoMarketMakersHedgeEn : languageTextMain.howDoMarketMakersHedge}} />
                    </div>
                  </div>
                </td>
                <td width="33px">
                  <Link to="/eduhtml/howdomarketmakershedge">
                    <div className="next right" />
                  </Link>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
      {/* Bottom Left Right Links and Copyright Section End */}
      {/* Right Dots Menu Component Section Start */}
      <div id="dotbar" className="hidden-sm hidden-xs">
        <ChildInvestorAcademyCommon Location={location} Hist={history} />
      </div>
      {/* Right Dots Menu Component Section End  */}
      {/* View Chart Popup In Mobile Start  */}
      <div id="imgpopup" style={{display: display}}>
        <div id="closebtn2" onClick={openPopUp} />
        <div className="imgHold">
          <img src={imgVal} className="landscape" />
        </div>
      </div>
      {/* View Chart Popup In Mobile End  */}
    </div>
  );
};
export default LiquidityVolumes;
