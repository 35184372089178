import React, { useEffect, useState } from 'react';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import Timeline from "highcharts/modules/timeline.js"
import { get } from 'lodash'


Timeline(Highcharts);
const ColumnBarChart = (props) => {
    
    var UNDEFINED;


    const { marketTurnOverAll, marketTurnOver } = props;

    const arrTurnOverDate = marketTurnOverAll && marketTurnOverAll.map((data, idx) => (
        data && data.stats_date_obj && data.stats_date_obj.slice(0, 6)
    ))

    const arrTurnOverIndex = marketTurnOver && marketTurnOver.map((data, idx) => (
        data && parseFloat(data.pct)
    ))
    const arrTurnOverStock = marketTurnOverAll && marketTurnOverAll.map((data, idx) => (
        data && Number(Number((data.dw).replace(',', "")).toFixed(2))
    ))

    const options = {
        chart: {
            type: 'column',
        },
        title: {
            text: ''
        },
        subtitle: {
            text: ''
        },
        // lang: {
        //     thousandsSep: ','
        // },
        xAxis: {
            categories: arrTurnOverDate,
            tickColor: '#F7941E',
            title: {
                style: {
                    color: '#F7941E',
                    useHTML: false
                }
            },
            labels: {
                style: {
                    color: '#F7941E',
                    fontSize: '12px',
                    fontFamily: "'MCQGlobal_A_Lt','MCQGlobal_A_Bd','Roboto', Arial, sans-serif"
                }
            },       
            crosshair: false
        },
        legend: {
            enabled: false
        },
        yAxis: {
            min: "0",
            tickInterval: 200,
            title: false,
            labels: {
                formatter: function () {
                    return Highcharts.numberFormat(this.value, -1, UNDEFINED, ',');
                },
                style: {
                    color: '#000',
                    fontSize: '12px',
                    fontFamily: "'MCQGlobal_A_Lt','MCQGlobal_A_Bd','Roboto', Arial, sans-serif"
                }
            }
        },

        tooltip: {
            headerFormat: '{point.key:}:',
            pointFormat: '{point.y:.2f}' + '',
            footerFormat: '',
        },

        plotOptions: {
            column: {
                pointPadding: 0,
                borderWidth: 0,
                groupPadding: 0.1,
            },
        },
        credits: false,
        series: [{
            name: '',
            color: '#F7941E',
            data: arrTurnOverStock,
            states: {hover: {enabled: false}}
        },
    ]
    }

    return (
        <HighchartsReact
            highcharts={Highcharts}
            options={options}
        />
    );
}
export default ColumnBarChart;
