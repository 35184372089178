export const languageText ={

    pageTitle: "ความเสี่ยง",
    pageTitleEn: "Risk",

    pageName: "ความเสี่ยงด้านตลาด (Market Risk)",
    pageNameEn: "Market Risk",

    pageDescription: 'หาก<a href="/eduhtml/howarewarrantspriced">ตัวแปร</a>ในการคำนวณราคา DW มีการเคลื่อนไหวในทิศทางตรงกันข้าม เช่น ราคาหุ้นอ้างอิง อายุคงเหลือ ความผันผวน อัตราดอกเบี้ย และเงินปันผลที่คาดว่าจะได้รับ จะมีผลกระทบทางลบต่อราคา DW ด้วย',
    pageDescriptionEn: "An adverse movement in any of a DW's <a href='/eduhtml/howarewarrantspriced'>pricing variables</a> - share price, time to expiry, volatility, interest rate and expected dividends - can have a negative impact on the DW price.",

    imgUrl: "/img/education/img/marketrisk.png",
    imgUrlEn: "/img/education/img/marketrisk.png",
    
    pageDescription1: 'เมื่อซื้อ DW หรือผลิตภัณฑ์การลงทุนอื่นๆ ที่อ้างอิงกับการเคลื่อนไหวของตลาด นักลงทุนจะมีความเสี่ยงจากการที่ราคาหุ้น หรือ ตลาดโดยรวม มีโอกาสที่จะเคลื่อนไหวในทิศทางตรงกันข้ามกัน',
    pageDescription1En: 'When buying a DW or any other investment product that is linked to the movements of a market, an investor is exposed to the risk that the share price, index or market as a whole moves adversely against them.',
}