export const getDateString = (dateObj) => {
  // output YYYY-MM-DD
  if (dateObj instanceof Date) {
    const year = dateObj.getFullYear();
    const month = dateObj.getMonth() + 1;
    const monthString = month.toString().padStart(2, "0");
    const day = dateObj.getDate();
    const dayString = day.toString().padStart(2, "0");
    const dateString = year + "-" + monthString + "-" + dayString;
    return dateString;
  }
  return "";
}


export function printFormattedDate(dateStr, printTime, lang) {
  if (typeof dateStr === 'object') {
    return "-"
  }
  return (lang === "thai") ? printToThaiFormattedDate(dateStr, printTime) : dateStr;
}

export function printToThaiFormattedDate(dateStr, printTime) {
  printTime = printTime || false;
  if (dateStr && dateStr != "-") {
    var ldate = new Date(dateStr);
    if (ldate) {
      var mTh = ['ม.ค.', 'ก.พ.', 'มี.ค.', 'เม.ย.', 'พ.ค.', 'มิ.ย.', 'ก.ค.', 'ส.ค.', 'ก.ย.', 'ต.ค.', 'พ.ย.', 'ธ.ค.'];
      var d = ldate.getDate();
      d = (d > 9) ? "" + d : "0" + d;
      var m = mTh[ldate.getMonth()];
      var y = "" + (ldate.getFullYear() + 543);
      y = y.slice(Math.max(y.length - 2, 1));
      var result = d + " " + m + " " + y;
      if (printTime && dateStr) {
        var split = dateStr.split(" ");
        var time = (split.length > 3) ? split[split.length - 1] : "";
        result = result + " " + time;
      }
      return result;
    } else {
      return "-";
    }
  } else {
    return "-"
  }
}
