export const languageText ={

    pageTitle: "ความเสี่ยง",
    pageTitleEn: "Risk",

    pageName: "การควบรวมกิจการ",
    pageNameEn: "Takeovers",

    pageDescription: 'หากหุ้นอ้างอิงจะควบรวมกิจการหรืออาจจะมีการควบรวมกิจการ DW ที่อ้างอิงกับหุ้นอ้างอิงดังกล่าว มีความเสี่ยงที่จะสูญเสียมูลค่าเวลาทั้งหมดไป ตัวอย่างเช่น นักลงทุนซื้อ DW ที่มีราคาใช้สิทธิเท่ากับ 10 บาท ในขณะที่หุ้นอ้างอิงมีราคาเท่ากับ 9 บาท สถานะของ DW จะเป็น OTM ซึ่งหมายความว่าราคาที่ซื้อขายอยู่นั้นเป็น<a href="/eduhtml/timedecay">มูลค่าเวลา</a> (Time Value) เท่านั้น หากมีการประกาศควบรวมกิจการราคา 9.50 บาท จะทำให้วันครบกำหนดอายุของ DW เลื่อนเข้ามาเป็นวันปัจจุบันทันที ซึ่งราคาดังกล่าวต่ำกว่าราคาใช้สิทธิ และจะทำให้ DW หมดอายุโดยไม่มีมูลค่าใดๆ<br><br> กรณีที่ Call DW มีการชำระเงินสดส่วนต่าง ในสถานการณ์ที่ DW มีการควบรวมกิจการ ราคาที่ใช้ในการควบรวมกิจการต้องสูงกว่าราคาใช้สิทธิ',
    pageDescriptionEn: "Following a takeover announcement, there is a risk that DW holders can lose their total time premium. For example, say an investor bought a call DW with an exercise price of THB 10 when the shares were trading at THB 9, the DW is out-of-the-money and therefore all of the price is represented by time value (<a href='/eduhtml/timedecay'> click here</a> for more info on time value). In the event of a takeover at THB 9.50 the DW expiry would be brought forward to current day and as the takeover price is below the exercise the DW would expire worthless.<br><br> In order for DW to have value in a takeover situation, the take over price would need to be above the exercise price.",

    imgUrl: "/img/education/img/takeovers.png",
    imgUrlEn: "/img/education/img/takeovers.png",
}