import React, { useState, useEffect, useRef } from "react";
import API from "../../../utils/apiCalling";
import { config } from "../../../utils/apiUrl";
import { get } from "lodash";
import NewsComp from "../../../components/common/NewsComp";
import { languageText } from "../../../languageText/marketNewsCommon"

const DailyHighlights = () => {

  const api = new API();
  const [dailyHighlight, setDailyHighlight] = useState([]);
  const [selectDate, setSelectDate] = useState("");
  const param = "todayhighlight";
  const dataslider = useRef();
  const [siteLang , setSiteLang] = useState("")

  useEffect(() => {
    window.scrollTo(0, 0);
    var siteLanguage = $.cookie('applang');
    setSiteLang(siteLanguage)
    if(siteLanguage == "eng"){
      document.querySelector("body").classList.add("eng")
    }else{
      document.querySelector("body").classList.remove("eng")
    }
    getDayHighlight();
  }, []);

  useEffect(() => {
    if (selectDate !== "") {
      getDayHighlight();
    }
  }, [selectDate]);

  const getDayHighlight = async () => {
    let dailyHighlight = await api.get(config.marketDailyHighLight, true, {
      sp: param,
      start: "0",
      ...(selectDate && { startdate: selectDate }),
      qid: Date.now(),
    });
    let dailyHighlightt = get(dailyHighlight, "newsList", []);
    setDailyHighlight(dailyHighlightt);
    dataslider.current.slickGoTo(0);
  };

  const linkData = [
    {
      name: siteLang == "eng" ? languageText.subscribeSidebarTitleEn : languageText.subscribeSidebarTitle,
      link: "/marketnews/subscribe",
      short: siteLang == "eng" ? languageText.subscribeSidebarDescriptionEn : languageText.subscribeSidebarDescription,
      imgClass: "section-qlink-subscribe",
    },
    // {
    //   name: siteLang == "eng" ? languageText.overnightMarketSidebarTitleEn : languageText.overnightMarketSidebarTitle,
    //   link: "/marketnews/overnightmarkets",
    //   short: siteLang == "eng" ? languageText.overnightMarketSidebarDescriptionEn : languageText.overnightMarketSidebarDescription,
    //   imgClass: "section-qlink-marketwrap",
    // },
    // {
    //   name: siteLang == "eng" ? "Market wrap-up" : "สรุปภาพรวมตลาด",
    //   link: "/marketnews/dailycommentary",
    //   short: "เกิดอะไรขึ้นกับตลาด DW ในวันนี้? เจาะลึกกับสรุปภาพรวม DW ทุกวันช่วง 6 โมงเย็น",
    //   imgClass: "section-qlink-marketcommentary",
    // },
    // {
    //   name: siteLang == "eng" ? languageText.dailyCommentarySidebarTitleEn : languageText.dailyCommentarySidebarTitle,
    //   link: "/marketnews/dailycommentary",
    //   short: siteLang == "eng" ? languageText.dailyCommentarySidebarDescriptionEn : languageText.dailyCommentarySidebarDescription,
    //   imgClass: "section-qlink-marketcommentary",
    // },
  ];

  return (
    <NewsComp
      param={param}
      data={dailyHighlight}
      section={(siteLang == "eng") ? languageText.firstNavigationEn : languageText.firstNavigation}
      breadcrumb={(siteLang == "eng") ? languageText.todayHighlightNavigationEn : languageText.todayHighlightNavigation}
      title={(siteLang == "eng") ? languageText.todayHighlightPageTitleEn : languageText.todayHighlightPageTitle}
      subtitle={(siteLang == "eng") ? languageText.todayHighlightPageDescriptionEn : languageText.todayHighlightPageDescription}
      dataIndex={2}
      setSelectDate={setSelectDate}
      dataslider={dataslider}
      newsFeeds={linkData}
    />
  );
};
export default DailyHighlights;
