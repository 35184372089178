export const languageText ={

    pageTitle: "ความเสี่ยง",
    pageTitleEn: "Risk",

    pageName: "อายุจำกัด (Limited Life)",
    pageNameEn: "Limited Life",

    pageDescription: 'สิ่งที่ต่างจากหุ้นคือ DW มีวันครบกำหนดอายุ และมีอายุจำกัด <br><br> หากราคาหุ้นอ้างอิงน้อยกว่าราคาใช้สิทธิของ Call DW (หรือสูงกว่าราคาใช้สิทธิของ Put DW) DW จะหมดอายุโดยไม่มีมูลค่า (Out of the money) นักลงทุนจะต้องระมัดระวัง และพึงระลึกว่า DW เป็นสินทรัพย์ที่สามารถลดมูลค่าได้',
    pageDescriptionEn: "Unlike shares, DW have an expiry date and therefore a limited life.<br><br> If the share price is below the exercise price for call DW (or above the exercise price for put DW upon expiry), the DW will expire worthless. Investors must be aware that DW are decaying assets.",

    imgUrl: "/img/education/img/limitedlife_th.png",
    imgUrlEn: "/img/education/img/limitedlife.png",
}