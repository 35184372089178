export const languageText ={
    
    firstNavigation: "เครื่องมือ DW ",
    firstNavigationEn: "DW tools ",
    secondNavigation: " ข้อมูล DW ",
    secondNavigationEn: " DW terms ",

    pageTitle: "ข้อมูล DW",
    pageTitleEn: "DW terms",
    pageDescription: "ข้อมูลทั้งหมดที่คุณต้องรู้เกี่ยวกับ DW ที่คุณสนใจ รวบรวมอยู่ในหน้านี้",
    pageDescriptionEn: "Everything you need to know about your selected DW is found here!",

    selectSymbolPlaceholder: "เลือกชื่อย่อ DW",
    selectSymbolPlaceholderEn: "Select DW symbol",

    // tooltipClick: " อ่านข้อมูล ",
    // tooltipClickEn: " click. ",

    bidPrice: "ราคาเสนอซื้อ",
    bidPriceEn: "Bid price",
    change: "เปลี่ยนแปลง",
    changeEn: "Change",

    dwBidPrice: "Bid ของ DW(บาท)",
    dwBidPriceEn: "DW Bid price(THB)",
    underlyingPrice: "Bid ของหลักทรัพย์อ้างอิง",
    underlyingPriceEn: "Underlying price",

    prior: "ก่อนหน้า",
    priorEn: "Prior",
    lastTrade: "วันซื้อขายสุดท้าย",
    lastTradeEn: "Last trade",
    open: "เปิด",
    openEn: "Open",
    tradedVolume: "ปริมาณการซื้อขาย ('000)",
    tradedVolumeEn: "Traded volume ('000)",
    high: "สูงสุด",
    highEn: "High",
    tradedValueTHB: "Traded value ('000 บาท)",
    tradedValueTHBEn: "Traded value ('000 THB)",
    low: "ต่ำสุด",
    lowEn: "Low",
    underlyingBidPrice: "ราคา bid ของหลักทรัพย์อ้างอิง",
    underlyingBidPriceEn: "Underlying bid price",

    // Indicator Title and Tooltip
    tooltipClickHere: " คลิกที่นี่ ",
    tooltipClickHereEn: " Click here ",

    underlyingTitle: "หลักทรัพย์อ้างอิง",
    underlyingTitleEn: "Underlying",
    underlyingTitleTooltip: " หลักทรัพย์หรือดัชนีหลักทรัพย์อ้างอิงของ DW",
    underlyingTitleTooltipEn: " The stock or index over which the DW is listed.",

    effectiveGearingTitle: "Effective gearing",
    effectiveGearingTitleEn: "Effective gearing",
    effectiveGearingTitleTooltip1: " ใช้บ่งชี้โอกาสในการรับผลตอบแทนจากการลงทุนใน DW ตัวอย่างเช่น Effective Gearing เท่ากับ 5 เท่า หมายความว่า ถ้าราคาหุ้นอ้างอิงเปลี่ยนแปลงไป 1% ราคา DW จะเปลี่ยนแปลงไปประมาณ 5%",
    effectiveGearingTitleTooltip1En: " Indicates your leveraged exposure. An effective gearing of 5X tells you a 1% move in the underlying stock/index results in an approximate 5% move in your DW price.",
    effectiveGearingTitleTooltip2: " เพื่ออ่านรายละเอียดเพิ่มเติม",
    effectiveGearingTitleTooltip2En: " to learn more about effective gearing.",

    historicalTitle: "Historical Volatility",
    historicalTitleEn: "Historical Volatility",
    historicalTitleTooltip: " ค่าความผันผวนย้อนหลัง 90 วัน ของหุ้นอ้างอิง ซึ่งค่าดังกล่าวอาจมีผลกับค่า Volatility และราคาของ DW ได้",
    historicalTitleTooltipEn: " 90 day Historical Volatility of the Underlying. This parameter can impact the Implied Volatility and Price of DW.",
    
    deltaTitle: "Delta",
    deltaTitleEn: "Delta",
    deltaTitleTooltip1: " ช้ประมาณการการเปลี่ยนแปลงของราคา DW เปรียบเทียบกับการเปลี่ยนแปลงราคาหุ้นอ้างอิงหรือดัชนีอ้างอิง Put จะมีค่า Delta เป็นลบ เพราะราคา Put จะเพิ่มขึ้นเมื่อหุ้นอ้างอิงหรือดัชนีอ้างอิงปรับตัวลดลงหรือราคาเปลี่ยนแปลงในทิศทางตรงกันข้าม ทั้งนี้ ค่า Delta ดังกล่าวยังไม่คำนวณรวมกับ DWPS",
    deltaTitleTooltip1En: " Estimates how your DW move in relation to the underlying stock/index movement. Puts have negative deltas because put DW prices increase as the underlying stock/index decreases or move in the opposite direction. Note, this does not take into account DWPS,",
    deltaTitleTooltip2: " เพื่ออ่านข้อมูลเพิ่มเติม",
    deltaTitleTooltip2En: " to learn more.",

    typeTitle: "ประเภท",
    typeTitleEn: "Type",
    typeTitleTooltip: " Calls จะเพิ่มมูลค่าต่อเมื่อหุ้นอ้างอิงปรับตัวขึ้น ในขณะที่ Puts จะเพิ่มมูลค่าต่อเมื่อหุ้นอ้างอิงปรับตัวลง",
    typeTitleTooltipEn: " Calls increase in value as share price rises, Puts increase in value as share price falls.",

    deltaPerDwTitle: "Delta ต่อ DW",
    deltaPerDwTitleEn: "Delta per DW",
    deltaPerDwTitleTooltip1: " ใช้ดูว่า DW เปลี่ยนแปลงสัมพันธ์กับหุ้นอ้างอิง/ดัชนีอ้างอิงอย่างไร เมื่อพิจารณาควบคู่กับ DWPS แล้ว",
    deltaPerDwTitleTooltip1En: " Tells you how your DW move in relation to the underlying stock/index movement after taking into account DWPS.",
    deltaPerDwTitleTooltip2: " เพื่ออ่านรายละเอียดเพิ่มเติมเกี่ยวกับ Delta",
    deltaPerDwTitleTooltip2En: " to learn more about Delta.",

    issuerTitle: "ผู้ออก",
    issuerTitleEn: "Issuer",
    issuerTitleTooltip: " คือธนาคารหรือบริษัทหลักทรัพย์ที่เป็นผู้ออกและเสนอขาย DW",
    issuerTitleTooltipEn: " The Bank or Securities company that issues the DW.",

    sensitivityTitle: "Sensitivity",
    sensitivityTitleEn: "Sensitivity",
    sensitivityTitleTooltip: " แสดงให้เห็นว่าราคา DW จะเปลี่ยนแปลงไปกี่ช่วงราคา เมื่อราคาหุ้นอ้างอิงปรับตัวเพิ่มขึ้น 1 ช่วงราคา เช่น ค่า Sensitivity = 1.2 หมายถึง ราคาหุ้นอ้างอิงปรับตัวขึ้น 1 ช่วงราคา ราคาของ DW เปลี่ยนแปลงประมาณ 1.2 ช่วงราคา",
    sensitivityTitleTooltipEn: " Indicates the number of ticks in the DW moves for a 1 tick move in the underlying. Eg, Sensitivity = 1.2 means underlying stock move 1 tick, your DW will move around 1.2 ticks.",

    sensitivityTitleTooltip1: " แสดงให้เห็นว่าราคา DW จะเปลี่ยนแปลงไปกี่ช่วงราคา เมื่อราคาหุ้นอ้างอิงปรับตัวเพิ่มขึ้น 1 ช่วงราคา เช่น ค่า Sensitivity = 1.2 หมายถึง ราคาหุ้นอ้างอิงปรับตัวขึ้น 1 ช่วงราคา ราคาของ DW เปลี่ยนแปลงประมาณ 1.2 ช่วงราคา สำหรับ HSI DW จะใช้เปรียบเทียบเมื่อราคาหลักทรัพย์อ้างอิงปรับตัวเพิ่มขึ้น 100 จุด เช่น Sensitivity = 2.1 หมายถึง สัญญาซื้อขายล่วงหน้า HSI เคลื่อน 100 จุด ราคา DW จะเปลี่ยนแปลงประมาณ 2.1 ช่วงราคา",
    sensitivityTitleTooltip1En: " 	Indicates the number of ticks in the DW moves for a 1 tick move in the underlying. Eg, Sensitivity = 1.2 means underlying stock move 1 tick, your DW will move around 1.2 ticks. For HSI DW’s sensitivity, it will indicates the number of ticks in the DW moves for 100 futures index point move. Eg. Sensitivity = 2.1 means HSI index futures move 100 futures index point, your DW will move around 2.1 ticks.",

    mostRecentTitle: "วันที่มีการซื้อขายล่าสุด",
    mostRecentTitleEn: "Most recent trade date",
    mostRecentTitleTooltip1: " วันล่าสุดที่ DW มีการซื้อขาย ทั้งนี้ DW ไม่ได้มีการซื้อขายทุกวัน",
    mostRecentTitleTooltip1En: " The last date that the DW recorded a trade. Note: DW do not trade every day, this has implications when calculating price changes.",
    mostRecentTitleTooltip2: " for more info.",
    mostRecentTitleTooltip2En: " for more info.",

    premiumTitle: "Premium",
    premiumTitleEn: "Premium",
    premiumTitleTooltip1: " เป็นค่าที่แสดงถึง 'มูลค่าเวลา' ของ DW โดย DW ที่มี Premium สูงกว่า จะหมายถึง DW ที่มีอายุคงเหลือมากกว่า หรือมี gearing สูงกว่า",
    premiumTitleTooltip1En: " Also refered to as the 'Time Value of the DW. Note a higher premium could be due to the DW being longer dated or due to the higher gearing.",
    premiumTitleTooltip2: " เพื่ออ่านรายละเอียดเพิ่มเติมเกี่ยวกับ Premium",
    premiumTitleTooltip2En: " to learn more about premium.",

    expiryTitle: "วันครบกำหนดอายุ",
    expiryTitleEn: "Expiry",
    expiryTitleTooltip: " วันที่ DW หมดอายุหรือวันใช้สิทธิอัตโนมัติ",
    expiryTitleTooltipEn: " The date at which the DW will expire.",

    impliedVolatilityTitle: "Implied volatility",
    impliedVolatilityTitleEn: "Implied volatility",
    impliedVolatilityTitleTooltip1: " DW ที่มีความผันผวนสูง จะมีราคาสูงตามไปด้วย Implied Volatility มีความสัมพันธ์กับความผันผวนและความเสี่ยงของหุ้นอ้างอิงหรือดัชนีอ้างอิง",
    impliedVolatilityTitleTooltip1En: " The higher the implied volatility, the higher the price of the DW. Relates to the volatility and risk of the underlying share/Index.",
    impliedVolatilityTitleTooltip2: " เพื่ออ่านข้อมูลเพิ่มเติม",
    impliedVolatilityTitleTooltip2En: " to learn more about implied volatility.",

    lastTradingTitle: "วันซื้อขายสุดท้าย",
    lastTradingTitleEn: "Last trade date",
    lastTradingTitleTooltip: " วันสุดท้ายที่ DW มีการซื้อขายในตลาดหลักทรัพย์ และเป็นวันคำนวณมูลค่า DW เมื่อครบกำหนดอายุ โดยทั่วไปคือวันทำการที่ 4 ก่อนวันครบกำหนดอายุ",
    lastTradingTitleTooltipEn: " The day that the DW stops trading on the SET and the expiry value is calculated, always 4 business days prior to the expiry date.",

    thetaTitle: "Theta",
    thetaTitleEn: "Theta",
    thetaTitleTooltip1: " อัตราการลดลงของ time Value (ที่เรียกว่า time decay) รายวัน ซึ่งจะแสดงในรูปเปอร์เซ็นต์ (%)",
    thetaTitleTooltip1En: " The daily time decay of the DW expressed in percentage terms.",
    thetaTitleTooltip2: " เพื่ออ่านรายละเอียดเพิ่มเติมเกี่ยวกับ Theta",
    thetaTitleTooltip2En: " to learn more about Theta.",

    timeMaturityTitle: "อายุของตราสาร",
    timeMaturityTitleEn: "Time to maturity",
    timeMaturityTitleTooltip: " จำนวนวันคงเหลือจนกระทั่งถึงวันครบกำหนดอายุ",
    timeMaturityTitleTooltipEn: " The number of days left until the last trading date of the DW.",

    intrinsicTitle: "มูลค่าที่แท้จริง",
    intrinsicTitleEn: "Intrinsic value per DW",
    intrinsicTitleTooltip1: " คือส่วนต่างระหว่างราคาใช้สิทธิ และราคาหุ้นอ้างอิงในปัจจุบัน โดย Call DW จะมีมูลค่าที่แท้จริงเมื่อราคาใช้สิทธิต่ำกว่าราคาหุ้นอ้างอิง และ Put DW จะมีมูลค่าที่แท้จริงเมื่อราคาใช้สิทธิสูงกว่าราคาหุ้นอ้างอิง",
    intrinsicTitleTooltip1En: " This is the difference between the exercise price and the current share price. For call DW, if the exercise price is below the current share price it has intrinsic value, vice versa for puts. Please",
    intrinsicTitleTooltip2: "",
    intrinsicTitleTooltip2En: " for more information on intrinsic value.",

    ratioTitle: "Ratio",
    ratioTitleEn: "Ratio",
    ratioTitleTooltip: " อัตราการใช้สิทธิ (สำหรับ DW บนหุ้น) หรือ ตัวคูณดัชนี (สำหรับ DW บนดัชนีหลักทรัพย์)",
    ratioTitleTooltipEn: " The conversion ratio (for stock DW) or the index multiplier (for index DW)",

    moneynessTitle: "สถานะ",
    moneynessTitleEn: "Moneyness",
    moneynessTitleTooltip1: " ITM (In-the-money) OTM (Out-of-the-money) ATM (At-the-money)",
    moneynessTitleTooltip1En: " ITM (in-the-money), OTM (out-of-the-money), ATM (at-the-money).",
    moneynessTitleTooltip2: " เพื่ออ่านรายละเอียดเพิ่มเติม",
    moneynessTitleTooltip2En: " to learn more about moneyness.",

    // Important Note
    impliedVolatilityNote1: "ค่า Implied Volatility : ",
    impliedVolatilityNote1En: "Implied Volatility : ",
    impliedVolatilityNote2: " โดยคำนวณจากแนวทางที่ชมรมวาณิชธนกิจกำหนด",
    impliedVolatilityNote2En: " calculation based on IB club’s guideline",

    dwPriceNote: 'ราคา DW ที่แสดงใน ก่อนหน้า, เปิด, สูงสุด และ ต่ำสุด ใช้ "ราคาเสนอซื้อ (ราคาเสนอซื้อล่าสุด)" ของ DW ไม่ใช่ "ราคาซื้อขาย (Traded Price)" รวมทั้งกราฟ ระหว่างวัน ก็ใช้ราคาเสนอซื้อของ DW เพื่อสร้างราคา DW ย้อนหลังด้วยเช่นกัน เนื่องจากนี่เป็นวิธีที่แม่นยำมากกว่าในการที่จะแสดงให้เห็นถึงการเปลี่ยนแปลง มูลค่าของ DW ในแต่ละช่วงเวลา DW ไม่ได้มีการซื้อขายบ่อยเหมือนหุ้น และในบางครั้งอาจไม่มีการซื้อขายในบางช่วงเวลา ในขณะที่ราคาเสนอซื้อ-เสนอขายมี การเคลื่อนไหวอย่างต่อเนื่อง ดังนั้น การใช้ราคาเสนอซื้อจึงเป็นตัวบ่งชี้มูลค่าของ DW ที่ดีที่สุด อ่านรายละเอียดเพิ่มเติม',
    dwPriceNoteEn: 'The DW price displayed in prior, Open, High and low is based on the "bid price for the DW rather than the "traded price". the intraday graph also uses the DW bid price to generate the DW price history. We do this to provide a more accurate representation of the DW value at each particular point in the time and a more accurate reflection of DW price changes. DW do not trade as frequently as shares and sometimes may not record a trade for a long period of time though the bid/offer prices may continue to move, therefore using the bid price is usually the best indicator of DW value. For a more detail description',

    underlyingPriceNote: "*ราคาดัชนีหลักทรัพย์อ้างอิงที่แสดงไว้ในเว็บไซต์ เป็นราคาของ Index Futures เนื่องจาก DW อ้างอิงดัชนี ที่ออกโดยแมคควอรี มีการชำระราคา ณ วันครบกำหนดอายุ โดยใช้ Final Settlement Price ของ Index Futures เป็นดัชนีที่ใช้ชำระราคา",
    underlyingPriceNoteEn: "*The underlying price shown on the website is the Index Futures price. All Macquarie Index DWs are settled at expiry using the Final Settlement Price of index Futures.",

    marketDelay: "ข้อมูลตลาดทั้งหมดล่าช้าประมาณ 15 นาที",
    marketDelayEn: "All market data is delayed 15 mins.",


    // check data
    definitionDisclaimer: "Definition & disclaimer",
    definitionDisclaimerEn: "Definition & disclaimer",

    dwSymbolTable: "DW ชื่อย่อ",
    dwSymbolTableEn: "DW symbol",
    dwSymbolTableTooltip1: " DWแต่ละรุ่นจะมีชื่อเฉพาะ ",
    dwSymbolTableTooltip1En: " Each DW has a unique code to identify it, ",
    dwSymbolTableTooltip2: " เพื่ออ่านคำอธิบายเพิ่มเติม",
    dwSymbolTableTooltip2En: " for a more detailed explanation.",

    expiryTable: "วันครบกำหนดอายุ",
    expiryTableEn: "Expiry",
    expiryTableTooltip: " วันที่ DW หมดอายุหรือวันใช้สิทธิอัตโนมัติ",
    expiryTableTooltipEn: " The date at which the DW will expire.",
}