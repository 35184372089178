export const languageText ={
    
    firstNavigation: "อัพเดทตลาดหุ้น ",
    firstNavigationEn: "Market news ",
    secondNavigation: " สมัครจดหมายข่าว",
    secondNavigationEn: " Subscribe",

    pageTitle: "เกาะติดสถานการณ์ ไม่ตกเทรน",
    pageTitleEn: "KEEP UP TO DATE",
    pageTitle2: "ด้วยข้อมูลข่าวสารล่าสุด",
    pageTitle2En: "with the latest news",
    pageTitle3: "สมัครรับจดหมายข่าวจากแมคควอรี",
    pageTitle3En: "Sign up for Macquarie’s daily newsletter",
    pageTitle4: "ส่งตรงถึงคุณทุกวัน ให้คุณไม่พลาดข่าวสดใหม่เกี่ยวกับตลาด DW",
    pageTitle4En: "Each day in your inbox you will get the inside scoop on what’s happening in the DW market.",

    viewSample: "อ่านเพิ่มเติม",
    viewSampleEn: "View sample",

    dailyHighlightTitle: "ข่าวเด่นวันนี้",
    dailyHighlightTitleEn: "Daily highlight",
    dailyHighlightDes: "อัพเดทข่าวสารล่าสุดที่เกิดขึ้นในตลาด ทั้งในเรื่องของบทวิเคราะห์หลักทรัพย์จากแมคควอรี ข่าวการลงทุนที่สำคัญๆ และรวมทั้งอัพเดทภาพรวมเศรษฐกิจและข่าวประกาศต่างๆ",
    dailyHighlightDesEn: "Including Macquarie Research updates, technical analysis from industry experts and updates on the latest events moving the markets.",
    
    dwMarketTitle: "สรุปตลาด DW",
    dwMarketTitleEn: "DW market commentary",
    dwMarketDes: "ไม่พลาดทุกความเคลื่อนไหวในตลาด DW รวมทั้งติดตาม DW ที่นักลงทุนส่วนใหญ่ซื้อขาย",
    dwMarketDesEn: "Keeps you in touch with all the action from the DW market. Hear about what other investors are buying and selling.",

    overnightWrapTitle: "ภาพรวมตลาดวานนี้",
    overnightWrapTitleEn: "Overnight wrap",
    overnightWrapDes: "สรุปภาวะการเคลื่อนไหวของตลาดหุ้นชั้นนำของโลกอย่าง อเมริกาและยุโรป และปัจจัยที่อาจจะกระทบตลาดไทย",
    overnightWrapDesEn: "Highlights the major news events from the US and European markets. What is going to affect the market today?",

    signUp: "สมัครเลย",
    signUpEn: "Sign up now",

    emailAdd: "Email address",
    emailAddEn: "Email address",
    emailPlaceholder: "อีเมล",
    emailPlaceholderEn: "Email",

    formTerm: "โดยการกด “ตกลง” คุณยืนยันว่าคุณต้องการสมัครรับจดหมายข่าวรายวันของแมคควอรี การสมัครรับจดหมายข่าว คุณยังจะได้รับข่าวประชาสัมพันธ์และการเชิญชวนให้ร่วมงานสัมมนาเกี่ยวกับใบสำคัญแสดงสิทธิอนุพันธ์หรืองานอื่นๆ ที่เกี่ยวข้อง ผู้ดูแลเว็บไซต์อาจเข้าถึงข้อมูลส่วนบุคคลของคุณเพื่อประมวลผลข้อมูลในนามของ Macquarie Securities (Thailand) Limited และบริษัทอื่นๆ ของเครือ Macquarie (“Macquarie”) คุณสามารถอ่านนโยบายความเป็นส่วนตัวของแมคควอรี เกี่ยวกับการรวบรวม ใช้ เปิดเผย เก็บรักษา และการจัดการข้อมูลส่วนบุคคล รวมถึงสิทธิ์ในส่วนที่เกี่ยวข้องกับข้อมูลส่วนบุคคลของคุณได้ที่หน้าเว็บไซต์ ",
    formTermEn: "By clicking “Submit”, you confirm that you would like to subscribe to Macquarie’s daily warrant newsletter. By subscribing, you will also automatically receive invitations or marketing material from Macquarie for warrant seminars and other warrant related initiatives. Macquarie website administrators may have access to your personal data for the processing of such data on behalf of Macquarie Securities (Thailand) ltd and other companies of Macquarie group (“Macquarie”). You may view Macquarie’s privacy notice with regard to the collection, use, disclosure, retention and management of personal data including your rights in respect to your personal data at ",

    submit: "สมัคร",
    submitEn: "Agree & Submit",

}