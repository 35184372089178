import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Toptips from "../../../assets/images/tips/top-dwtips.jpg";
import ImgTip from "../../../assets/images/tips/hsi.jpg";
import SetImg from "../../../assets/images/tips/set.jpg";
import TopicImg from "../../../assets/images/tips/topic.jpg";
import QlinkAchor from "../../../assets/images/tips/qlink-achor.png";
import { languageText } from "../../../languageText/dwTips"
import AdvertiseMentSidebar from "../../../components/common/Sidebar/AdvertiseMentSidebar"
import DwVideoSideBars from "../../../components/common/Sidebar/DwVideoSideBar"

const DwTipsPage = () => {

  const [siteLang , setSiteLang] = useState("")

  useEffect(() => {
    window.scrollTo(0, 0);
    var siteLanguage = $.cookie('applang');
    setSiteLang(siteLanguage)
    if(siteLanguage == "eng"){
      document.querySelector("body").classList.add("eng")
    }else{
      document.querySelector("body").classList.remove("eng")
    }
  }, []);

  return (
    <div id="dwtips" className="pageobj">
      <div className="breadcrumbbar">
        <div className="container-fluid">
          <ol className="breadcrumb">
            <li>
              <Link to="/home?dwedu">{siteLang == "eng" ? languageText.firstNavigationEn : languageText.firstNavigation}</Link>
            </li>
            <li className="active">{siteLang == "eng" ? languageText.secondNavigationEn : languageText.secondNavigation}</li>
          </ol>
        </div>
      </div>
      <div className="stripe-bg" />
      <div className="container-fluid">
        <div className="topimg hidden-sm hidden-xs">
          <img src={Toptips} alt="top tips" />
        </div>
        <div className="row">
          <div className="col-md-8">
            <div id="tipssection" style={{ position: "relative" }} className="section">
              <div className="page-header">
                <h1>{siteLang == "eng" ? languageText.pageTitleEn : languageText.pageTitle}</h1>
              </div>
              <div className="tips-table">
                <div className="tips-img">
                  <img src={ImgTip} alt="img tips" />
                </div>
                <div className="tips-text">
                  <p>{siteLang == "eng" ? languageText.allIndexTipsEn : languageText.allIndexTips}</p>
                  <Link
                    className="tips-btn btn btn-primary"
                    to="/education/dwtipssup?type=index"
                  >
                    {siteLang == "eng" ? languageText.allTipsClickEn : languageText.allTipsClick}
                  </Link>
                </div>
              </div>
              <div className="tips-table">
                <div className="tips-img">
                  <img src={SetImg} alt="set tips" />
                </div>
                <div className="tips-text">
                  <p>{siteLang == "eng" ? languageText.allSetTipsEn : languageText.allSetTips}</p>
                  <Link
                    className="tips-btn btn btn-primary"
                    to="/education/dwtipssup?type=set"
                  >
                    {siteLang == "eng" ? languageText.allTipsClickEn : languageText.allTipsClick}
                  </Link>
                </div>
              </div>
              <div className="tips-table">
                <div className="tips-img">
                  <img src={TopicImg} alt="topic" />
                </div>
                <div className="tips-text">
                  <p>{siteLang == "eng" ? languageText.allTopicTipsEn : languageText.allTopicTips}</p>
                  <Link
                    className="tips-btn btn btn-primary"
                    to="/education/dwtipssup?type=topic"
                  >
                    {siteLang == "eng" ? languageText.allTipsClickEn : languageText.allTipsClick}
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="hidden-xs hidden-sm col-md-4">
            <div className="section qlink-section">
              <div className="page-header">
                <h2 className="small"> {siteLang == "eng" ? languageText.marketHeadingEn : languageText.marketHeading}</h2>
              </div>
              <div className="newstbl-row rowtmpl">
                <div className="newstbl-col-right">
                  <Link
                    className="newstbl-col-dt"
                    target="_blank"
                    style={{ color: "#333333" }}
                    to="/marketnews/dailysinglestock"
                  >
                    {siteLang == "eng" ? languageText.marketDailysinglestockEn : languageText.marketDailysinglestock}
                  </Link>
                  <Link
                    to="/marketnews/dailysinglestock"
                    target="_blank"
                    className="newstbl-col-rm"
                  >
                    <img src={QlinkAchor} alt="qlick" />
                  </Link>
                </div>
              </div>
              {/* <div className="newstbl-row rowtmpl">
                <div className="newstbl-col-right">
                  <Link
                    className="newstbl-col-dt"
                    target="_blank"
                    style={{ color: "#333333" }}
                    to="/marketnews/dailysp500"
                  >
                    {siteLang == "eng" ? languageText.marketDailysp500En : languageText.marketDailysp500}
                  </Link>
                  <Link
                    to="/marketnews/dailysp500"
                    target="_blank"
                    className="newstbl-col-rm"
                  >
                    <img src={QlinkAchor} alt="qlick" />
                  </Link>
                </div>
              </div> */}
              <div className="newstbl-row rowtmpl">
                <div className="newstbl-col-right">
                  <Link
                    className="newstbl-col-dt"
                    target="_blank"
                    style={{ color: "#333333" }}
                    to="/marketnews/dailyhsi"
                  >
                    {siteLang == "eng" ? languageText.marketDailyhsiEn : languageText.marketDailyhsi}
                  </Link>
                  <Link
                    to="/marketnews/dailyhsi"
                    target="_blank"
                    className="newstbl-col-rm"
                  >
                    <img src={QlinkAchor} alt="qlick" />
                  </Link>
                </div>
              </div>
              <div className="newstbl-row rowtmpl">
                <div className="newstbl-col-right">
                  <Link
                    className="newstbl-col-dt"
                    target="_blank"
                    style={{ color: "#333333" }}
                    to="/marketnews/todayhighlight"
                  >
                    {siteLang == "eng" ? languageText.marketTodayhighlightEn : languageText.marketTodayhighlight}
                  </Link>
                  <Link
                    to="/marketnews/todayhighlight"
                    target="_blank"
                    className="newstbl-col-rm"
                  >
                    <img src={QlinkAchor} alt="qlick" />
                  </Link>
                </div>
              </div>
            </div>
            <DwVideoSideBars />
            <AdvertiseMentSidebar />
          </div>
        </div>
      </div>
    </div>
  );
};
export default DwTipsPage;
