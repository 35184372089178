export const languageText ={

    pageTitle: "ราคา DW คำนวณอย่างไร",
    pageTitleEn: "How are DW priced?",

    pageName: "สถานะของ DW (Moneyness)",
    pageNameEn: "Moneyness",

    pageDescription: "สถานะของ DW (Moneyness) หมายถึงความสัมพันธ์ระหว่างราคาหุ้นอ้างอิงกับราคาใช้สิทธิ",
    pageDescriptionEn: "Moneyness refers to the relationship between the exercise price and the current share price.",

    pageDescription1: "Call DW ใน",
    pageDescription1En: "The call DW in the",

    pageDescriptionLink: " ตัวอย่างที่แล้ว ",
    pageDescriptionLinkEn: " previous example ",

    pageDescription2: "มีมูลค่าที่แท้จริง (Intrinsic Value) เพราะราคาใช้สิทธิต่ำกว่าราคาหุ้นปัจจุบัน (ถ้าเป็น Put จะตรงข้ามกัน) DW ที่มีมูลค่าแท้จริงจะเรียกว่ามีสถานะเป็น",
    pageDescription2En: "has intrinsic value because the exercise price is below the current share price (reverse for puts). DW with intrinsic value are termed",

    pageDescriptionSpan: " In-the-money หรือ ITM ",
    pageDescriptionSpanEn: " in-the-money (or ITM)",

    pageDescription3: "เมื่อราคาใช้สิทธิ Call DW สูงกว่าราคาหุ้นในปัจจุบัน จะเรียกว่ามีสถานะเป็น",
    pageDescription3En: "When the exercise price is above the current share price for a call, it is termed",

    pageDescriptionSpan1: " Out-of-the-money หรือ OTM ",
    pageDescriptionSpan1En: " out-of the money (or OTM) ",

    pageDescription4: "และเมื่อราคาใช้สิทธิเท่ากับราคาหุ้นปัจจุบัน จะเรียกว่ามีสถานะเป็น",
    pageDescription4En: "and when the exercise price and current share price are equal, it is termed",

    pageDescriptionSpan2: " At-the-money หรือ ATM ",
    pageDescriptionSpan2En: " at- the-money (or ATM)",

    pageDescription5: "การเปลี่ยนแปลงราคาหุ้นหมายถึงว่า สถานะของ DW แต่ละตัวจะสามารถเปลี่ยนแปลงได้บ่อยครั้งตลอดช่วงอายุของ DW ดังนั้น DW ที่มีสถานเป็น In-the-money จะสามารถเปลี่ยนสถานมาเป็น At-the-money หรือ Out-of-the-money ได้อย่างรวดเร็ว",
    pageDescription5En: "Changing share prices mean that the moneyness of a particular DW can often change several times throughout its life, in-the-money DW can thus quickly become at-the-money or out-of- the-money, or vice versa.",

    pageDescription6: "ไม่มีสถานะของ DW สถานะใดที่เหมาะสมกับนักลงทุนทั้งหมด แต่ละสถานะมีทั้งข้อดีและข้อเสีย และเหมาะกับนักลงทุนต่างๆกัน และ/หรือ เหมาะกับวิธีการซื้อขายต่างกัน โดยสรุปลักษณะสำคัญต่างๆได้ดังตารางด้านล่าง",
    pageDescription6En: "There is no particular moneyness that is favoured by all investors, each has their own advantages and disadvantages and are suited to different investors and/or different trades. The main characteristics are summarised in the below table.",

    imgUrl: "/img/education/img/moneyness_th.png",
    imgUrlEn: "/img/education/img/moneyness.png",
}