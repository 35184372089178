import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import UnAuthorisedApp from "./UnAuthorisedApp";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import LangContext from "../components/common/context/lang-context";
import { useState } from "react";

const App = () => {

  const [lang, setLang] = useState('thai');
  const setLanguage = () => {
    if(lang == 'thai'){
      setLang('eng');
      document.body.classList.remove("thai")
      document.body.classList.add("eng")
    }else{
      setLang('thai');
      document.body.classList.remove("eng")
      document.body.classList.add("thai")
    }
  }
  return (
    <React.Fragment>
      <LangContext.Provider value={{ lang: lang, setLanguage: setLanguage }}>
        <link
          href="https://fonts.googleapis.com/css?family=Roboto:100,300,400"
          rel="stylesheet"
        />
        <link
          href="https://maxcdn.bootstrapcdn.com/font-awesome/4.6.3/css/font-awesome.min.css"
          rel="stylesheet"
        />

        <ToastContainer />
        <Router>
          <UnAuthorisedApp />
        </Router>
      </LangContext.Provider>
    </React.Fragment>
  );
};

export default App;
