export const languageText ={

    pageTitle: "วิธีการเลือก DW",
    pageTitleEn: "How to select a DW?",

    pageName: "มุมมองบนหุ้นอ้างอิง",
    pageNameEn: "View on Underlying Share or Index",

    pageDescription: "ในการเลือก DW เราแนะนำให้นักลงทุนทำตาม 4 ขั้นตอนนี้",
    pageDescriptionEn: "To choose a warrant, we suggest that investors consider the following 4 steps:",

    imgUrl: "/img/education/img/fourstep1_th.jpg",
    imgUrlEn: "/img/education/img/fourstep1.jpg",
    
    pageDescription1: "ในการเลือกลงทุนใน DW สิ่งที่ต้องนึกถึงเป็นอย่างแรกคือลักษณะและผลตอบแทนที่ผ่านมาของหุ้นอ้างอิงตัวนั้น จากนั้นจึงกำหนดมุมมองที่มีต่อหุ้นอ้างอิงตัวนั้นๆ ถ้าคุณมองว่าหุ้นจะขึ้น ก็ควรซื้อ Call DW ถ้าคุณมองว่าหุ้นจะลง ก็ควรซื้อ Put DW",
    pageDescription1En: "To choose a DW you should first look at the nature and performance of the underlying shares and take a view. If you think the share price is going to go up, buy a call DW. If you are bearish, buy a put DW.",

    pageDescription2: "ในขั้นตอนแรกนี้ คุณควรมีราคาเป้าหมายในใจของคุณ ซึ่งจะทำให้คุณเลือก DW ได้ตรงความต้องการมากขึ้น",
    pageDescription2En: "In this first step, you also need to have a target price for the stock in mind. This will help you to narrow your selection of the DW.",

    imgUrl1: "/img/education/img/moneyin.png",
    imgUrl1En: "/img/education/img/moneyin.png",
    
}